import { useState, useEffect } from "react";
import {
  Card,
  Button,
  Select,
  message,
  Form,
  Spin,
  Tooltip,
  Row,
  Col,
  Typography,
  Tabs,
  Alert,
} from "antd";
import {
  InfoCircleOutlined,
  UploadOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import {
  vendordocumentsProfile,
  departmentList,
  InvoiceDocuments,
} from "../../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import GaclSideBar from "../../../../layout/GaclSidebar";
import { toast } from "react-toastify";
import MultipleFileUpload from "./MultipleFileUpload/MultipleFileUpload";

const { TabPane } = Tabs;
const { Option } = Select;
const { Title, Text } = Typography;

const THEME_COLOR = "#FF902E";

const VendorDocumentUpload = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState([]);
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("new");
  const [submitloading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();

  // account types options
  const accountTypeOptions = [
    { value: "administrative_expenses", label: "Administrative Expenses" },
    { value: "auditing_expenses", label: "Auditing Expenses" },
    {
      value: "employees_medical_insurance",
      label: "Employees Medical Insurance",
    },
    {
      value: "employees_visas_permits_ids",
      label: "Employees Visa's, Permits & ID's",
    },
    {
      value: "expenses_recover_singhania_clinic",
      label: "Expenses to Recover From Singhania Clinic",
    },
    { value: "license_and_permits", label: "License and Permits" },
    {
      value: "marketing_advertising_expenses",
      label: "Marketing & Advertising Expenses",
    },
    {
      value: "offshore",
      label: "Offshore",
    },
    {
      value: "freezone",
      label: "Freezone",
    },
    {
      value: "office_rent",
      label: "Office Rent",
    },

    {
      value: "online_platform_website_expenses",
      label: "Online Platform/Website Expenses",
    },
    { value: "other_expenses", label: "Other Expenses" },
    { value: "postage", label: "Postage" },
    {
      value: "software_infrastructure_expenses",
      label: "Software Infrastructure Expenses",
    },
    { value: "telephone_expenses", label: "Telephone Expenses" },
    { value: "training_costs", label: "Training Costs" },
    { value: "vendor_outsourcee_expense", label: "Vendor Outsourcee Expense" },
  ];

  // fetching departments list
  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      setError([]);
      try {
        const response = await axiosInstance.get(departmentList);
        setDepartments(response.data);
      } catch (error) {
        console.error("Department fetch error:", error);

        if (error.response?.data) {
          const errorData = error.response.data;
          const errorMessages = Object.entries(errorData).map(
            ([key, value]) => ({
              field: key,
              message: Array.isArray(value) ? value.join(", ") : value,
            })
          );
          setError(errorMessages);
        } else {
          setError([
            {
              field: "departments",
              message: "Failed to fetch departments",
            },
          ]);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  const handleSubmit = async (values) => {
    try {
      setSubmitLoading(true);
      setError([]);

      if (!values.documents || values.documents.length === 0) {
        setError([
          {
            field: "documents",
            message: "Please select at least one document",
          },
        ]);
        return;
      }

      const formData = new FormData();
      formData.append("account_type", values.expenseHead);
      formData.append("department", values.department);

      if (activeTab === "new" && id) {
        formData.append("vendor_request", id);
      } else {
        formData.append("is_existing_vendor", true);
      }

      //  API call to Intitate profile setup
      const vendorResponse = await axiosInstance.post(
        vendordocumentsProfile,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const initiatedProfileId = vendorResponse.data.id;

      // Upload each document
      const uploadPromises = values.documents.map((file) => {
        const documentFormData = new FormData();
        documentFormData.append("initiated_profile", initiatedProfileId);
        documentFormData.append("document", file.originFileObj);

        return axiosInstance.post(InvoiceDocuments, documentFormData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      });

      await Promise.all(uploadPromises);

      toast.success("Documents uploaded successfully!");
      form.resetFields();
      setError([]);
    } catch (error) {
      console.error("Upload error:", error);

      if (error.response?.data) {
        const errorData = error.response.data;
        const errorMessages = Object.entries(errorData).map(([key, value]) => ({
          field: key,
          message: Array.isArray(value) ? value.join(", ") : value,
        }));
        setError(errorMessages);
      } else {
        setError([{ field: "general", message: "Failed to upload documents" }]);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const renderErrors = (errors) => {
    if (!errors) return null;

    return errors.map((error, index) => (
      <Alert
        key={index}
        description={<Text style={{ color: "#ff4d4f" }}>{error.message}</Text>}
        type="error"
        showIcon
        closable
        style={{
          marginBottom: 16,
          backgroundColor: "transparent",
          border: "none",
          padding: "0px",
        }}
      />
    ));
  };

  const handleGoToVendors = () => {
    navigate("/all-vendors");
  };

  const showError = activeTab === "new" && !id;
  const renderForm = () => (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Form.Item
        name="documents"
        rules={[
          {
            validator: async (_, value) => {
              if (!value || value.length === 0) {
                return Promise.reject("Please upload at least one document");
              }
              return Promise.resolve();
            },
          },
        ]}
        validateTrigger={["onSubmit"]}
      >
        <MultipleFileUpload showError={showError} />
      </Form.Item>

      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item
            label={
              <Text strong>
                Account Type
                <Tooltip title="Select the expense account head">
                  <InfoCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
              </Text>
            }
            name="expenseHead"
            rules={[{ required: true, message: "Please select expense head" }]}
          >
            <Select
              placeholder="Select expense head"
              showSearch
              optionFilterProp="children"
              size="large"
              disabled={showError}
              style={{
                width: "100%",
              }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
            >
              {accountTypeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={
              <Text strong>
                Department
                <Tooltip title="Select your department">
                  <InfoCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
              </Text>
            }
            name="department"
            rules={[{ required: true, message: "Please select department" }]}
          >
            <Select
              placeholder="Select department"
              showSearch
              optionFilterProp="children"
              size="large"
              loading={loading}
              disabled={showError}
              style={{ width: "100%" }}
            >
              {departments.map((dept) => (
                <Option key={dept.id} value={dept.id}>
                  {dept.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {showError && (
        <div
          style={{ marginBottom: 24, display: "flex", flexDirection: "column" }}
        >
          <span
            style={{
              marginBottom: 16,
              borderRadius: 6,
              padding: "12px 24px",
              color: "red",
            }}
          >
            Please select a vendor before uploading documents
          </span>
          <Button
            type="primary"
            onClick={handleGoToVendors}
            size="large"
            style={{
              height: 45,
              borderRadius: 6,
              backgroundColor: THEME_COLOR,
              borderColor: THEME_COLOR,
              width: "fit-content",
              padding: "0 32px",
            }}
          >
            Go to Vendors List
          </Button>
        </div>
      )}

      {error && <div style={{ marginBottom: 24 }}>{renderErrors(error)}</div>}

      {!showError && (
        <Row justify="start" style={{ marginTop: 32 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={submitloading || showError}
            size="large"
            icon={<UploadOutlined />}
            style={{
              padding: "0 40px",
              height: 45,
              borderRadius: 6,
              fontWeight: 500,
              backgroundColor: THEME_COLOR,
              borderColor: THEME_COLOR,
            }}
          >
            Submit
          </Button>
        </Row>
      )}
    </Form>
  );

  return (
    <>
      <GaclSideBar />
      <div
        style={{
          padding: 24,
          minHeight: "100vh",
          marginTop: 60,
        }}
        className="page-wrapper"
      >
        <Row>
          <Col xs={24}>
            <Card
              bordered={false}
              style={{
                borderRadius: 15,
              }}
            >
              <Row
                justify="space-between"
                align="middle"
                style={{ marginBottom: 24 }}
              >
                <Title level={4} style={{ margin: 0 }}>
                  <FileTextOutlined style={{ color: THEME_COLOR }} /> Upload
                  Documents
                </Title>
              </Row>

              <Alert
                message={`Upload Documents ${
                  activeTab === "new" ? "- New Vendor" : "- Existing Vendor"
                }`}
                type="info"
                showIcon
                style={{
                  marginBottom: 24,
                  borderColor: THEME_COLOR,
                  backgroundColor: "#fff7e6",
                }}
              />

              <Tabs
                activeKey={activeTab}
                onChange={setActiveTab}
                type="card"
                style={{ marginBottom: 24 }}
              >
                <TabPane tab="New Vendor" key="new">
                  {renderForm()}
                </TabPane>
                <TabPane tab="Existing Vendor" key="existing">
                  {renderForm()}
                </TabPane>
              </Tabs>
            </Card>
          </Col>
        </Row>

        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default VendorDocumentUpload;
