import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, Form, Input, Button, Spin, Typography, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  endDocument,
  endDocumentField,
  employeeDetails,
  assignedJob,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Text, Title } = Typography;

const EndFields = () => {
  const { id, jobId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [endDocumentDetails, setEndDocumentDetails] = useState(null);
  const [endFields, setEndFields] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  const fetchEndDocumentDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${endDocument}${id}/`);
      setEndDocumentDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching end document details:", error);
      toast.error("Failed to fetch end document details");
      setLoading(false);
    }
  }, [id]);

  const fetchEndFields = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${endDocumentField}?end_document=${id}`
      );
      setEndFields(response.data);
    } catch (error) {
      console.error("Error fetching end fields:", error);
      toast.error("Failed to fetch end fields");
    }
  }, [id]);

  useEffect(() => {
    fetchEndDocumentDetails();
    fetchEndFields();
  }, [fetchEndDocumentDetails, fetchEndFields]);

  const onFinish = async (values) => {
    setSubmitting(true);
    const toastId = toast.loading("Processing...");

    try {
      const payload = {
        end_document: id,
        assignee: employeeId,
        end_fields: values.end_fields,
      };
      await axiosInstance.post(endDocumentField, payload);
      if (jobId) {
        await axiosInstance.patch(`${assignedJob}${jobId}/`, {
          status: "Completed",
        });
      }
      toast.success("End fields submitted successfully");
      fetchEndFields();
      form.resetFields();
    } catch (error) {
      console.error("Error submitting end fields:", error);
      toast.error("Failed to submit end fields");
    } finally {
      setSubmitting(false);
      toast.dismiss(toastId);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <Card
              title={`End Fields for: ${endDocumentDetails?.end_name || "N/A"}`}
            >
              <Title level={4}>
                Track: {endDocumentDetails?.track_details?.name || "N/A"}
              </Title>

              <div style={{ marginBottom: "20px" }}>
                <Text strong>Existing End Fields:</Text>
                <List
                  dataSource={endFields}
                  renderItem={(item) => (
                    <List.Item>{item.field_name}</List.Item>
                  )}
                />
              </div>

              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.List name="end_fields">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          required={false}
                          key={field.key}
                          label={index === 0 ? "Add New End Fields" : ""}
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Please input end field or delete this field.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              style={{ width: "60%" }}
                              placeholder="Enter End Field"
                            />
                          </Form.Item>
                          <Button
                            type="link"
                            onClick={() => remove(field.name)}
                          >
                            Delete
                          </Button>
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add End Field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    Submit End Fields
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default EndFields;
