import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { Table, Tabs, Typography, Tag } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  separation,
  employeeList,
  employeeDetails,
  handover,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import Breadcrumbs from "../../../../components/Breadcrumbs";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const getStatusColor = (status) => {
  switch (status) {
    case "Submission":
      return "orange";
    case "Reviewed":
      return "cyan";
    case "Handover":
      return "blue";
    case "Handover Approval":
      return "purple";
    case "Approved":
      return "green";
    case "Rejected":
      return "red";
    default:
      return "default";
  }
};

const ViewModal = React.memo(
  ({
    show,
    onHide,
    resignation,
    employees = [],
    onSubmit,
    activeTabKey,
    setActiveTabKey,
    isEditing,
    setIsEditing,
    handleEditClick,
    handleSuccess,
    handoverData,
    refreshTable,
  }) => {
    const [comments, setComments] = useState("");

    const handleModalClose = () => {
      setComments("");
      setIsEditing(false);
      onHide();
    };

    const handleAction = async (type) => {
      try {
        const payload = {
          status: type === "approve" ? "Reviewed" : "Rejected",
          is_reviewed: type === "approve",
          comments: comments.trim(),
        };

        await axiosInstance.patch(`${separation}${resignation.id}/`, payload);

        toast.success(
          `Resignation ${
            type === "approve" ? "approved" : "rejected"
          } successfully`
        );
        handleModalClose();
        refreshTable();
      } catch (error) {
        console.error("Error updating resignation:", error);
        toast.error("Error updating resignation");
      }
    };

    return (
      <Modal
        show={show}
        onHide={handleModalClose}
        size="xl"
        centered
        onExited={() => setComments("")}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Resignation Details
            {resignation && (
              <Tag
                color={getStatusColor(resignation.status)}
                style={{ marginLeft: "10px" }}
              >
                {resignation.status}
              </Tag>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resignation && (
            <Tabs
              activeKey={activeTabKey}
              onChange={(key) => setActiveTabKey(key)}
              style={{ marginTop: -15 }}
            >
              <TabPane tab="Resignation Letter" key="1">
                <Typography>
                  <Title level={4}>
                    {resignation.user_details?.employee_name}'s Resignation
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      gap: "24px",
                      marginBottom: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Paragraph style={{ margin: 0 }}>
                      <strong>Department:</strong>{" "}
                      {
                        resignation.user_details.employment_details[0]
                          .department_detail.name
                      }
                    </Paragraph>
                    <Paragraph style={{ margin: 0 }}>
                      <strong>Designation:</strong>{" "}
                      {
                        resignation.user_details.employment_details[0]
                          .designation_detail.name
                      }
                    </Paragraph>
                    <Paragraph style={{ margin: 0 }}>
                      <strong>Submitted Date:</strong>{" "}
                      {moment(resignation.created_at).format("DD-MM-YYYY")}
                    </Paragraph>
                    <Paragraph style={{ margin: 0 }}>
                      <strong>Last Working Date:</strong>{" "}
                      {moment(resignation.last_working_date).format(
                        "DD-MM-YYYY"
                      )}
                    </Paragraph>
                  </div>
                  <Title level={5}>Resignation Letter:</Title>
                  <Paragraph style={{ whiteSpace: "pre-line" }}>
                    {resignation.resignation}
                  </Paragraph>
                  {resignation.signature && (
                    <>
                      <Title level={5}>Signature:</Title>
                      <div
                        style={{
                          maxWidth: "200px",
                          border: "1px solid #f0f0f0",
                          padding: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          src={resignation.signature}
                          alt="Signature"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </>
                  )}
                  <Form.Group className="mt-4">
                    <Form.Label>
                      <strong>Employee Taking Over</strong>
                      {resignation.handover_date && (
                        <small className="text-muted ml-2">
                          (Handover Date:{" "}
                          {moment(resignation.handover_date).format(
                            "DD-MM-YYYY"
                          )}
                          )
                        </small>
                      )}
                    </Form.Label>
                    <div className="border rounded p-3">
                      <Paragraph>
                        <strong>Name:</strong>{" "}
                        {resignation.handover_details?.employee_name}
                      </Paragraph>
                      <Paragraph>
                        <strong>Department:</strong>{" "}
                        {
                          resignation.handover_details?.employment_details[0]
                            ?.department_detail?.name
                        }
                      </Paragraph>
                      <Paragraph>
                        <strong>Designation:</strong>{" "}
                        {
                          resignation.handover_details?.employment_details[0]
                            ?.designation_detail?.name
                        }
                      </Paragraph>
                      {resignation.is_approved && (
                        <Paragraph>
                          <strong>Approved Date:</strong>{" "}
                          {moment(resignation.approved_date).format(
                            "DD-MM-YYYY"
                          )}
                        </Paragraph>
                      )}
                    </div>
                  </Form.Group>
                </Typography>

                {/* Add comments textarea always visible */}
                <Form.Group className="mt-4">
                  <Form.Label>
                    <strong>Comments (If Any)</strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                    placeholder="Enter your comments here"
                  />
                </Form.Group>

                {resignation.comments && (
                  <Paragraph className="mt-2">
                    <strong>Previous Comments:</strong> {resignation.comments}
                  </Paragraph>
                )}
              </TabPane>
            </Tabs>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModalClose}>
            Close
          </Button>
          {resignation?.status === "Submission" && (
            <>
              <Button variant="danger" onClick={() => handleAction("reject")}>
                Reject
              </Button>
              <Button variant="success" onClick={() => handleAction("approve")}>
                Approve
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
);

const ViewResignationLetterRS = () => {
  const [resignations, setResignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedResignation, setSelectedResignation] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [departmentID, setDepartmentID] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
  });
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [handoverData, setHandoverData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const fetchDepartmentID = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        const deptId = response.data.employment_details[0].department_detail.id;
        setDepartmentID(deptId);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };
    fetchDepartmentID();
  }, []);

  const fetchResignations = useCallback(
    async (page = pagination.current, pageSize = pagination.pageSize) => {
      if (!departmentID) return;

      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `${separation}?page=${page}&page_size=${pageSize}&is_tl=true&is_forward=true`
        );
        console.log("Fetched resignations:", response.data); // Debug log
        setResignations(response.data.results);
        setPagination((prev) => ({
          ...prev,
          current: page,
          pageSize: pageSize,
          total: response.data.count,
        }));
      } catch (error) {
        console.error("Error fetching resignations:", error);
        toast.error("Error fetching resignations");
      } finally {
        setLoading(false);
      }
    },
    [departmentID]
  );

  useEffect(() => {
    if (departmentID) {
      fetchResignations(1, pagination.pageSize);
      fetchEmployees();
    }
  }, [departmentID, fetchResignations, pagination.pageSize]);

  const handleEditClick = () => {
    if (!handoverData) {
      toast.error("No handover data available to edit");
      return;
    }
    setIsEditing(true);
  };

  const handleSuccess = async () => {
    try {
      const response = await axiosInstance.get(
        `${handover}?separation=${selectedResignation.id}`
      );
      setHandoverData(response.data.results[0]);
      await refreshData();
      setIsEditing(false);
      toast.success("Handover form updated successfully");
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data");
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get(employeeList);
      const activeEmployees = response.data.filter(
        (emp) => emp.employment_details[0]?.employment_status === "Active"
      );
      setEmployees(activeEmployees);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Error fetching employees list");
    }
  };

  const handleView = (record) => {
    setSelectedResignation(record);
    setActiveTabKey("1");
    setViewModalVisible(true);
  };

  const handleViewHandover = (record) => {
    setSelectedResignation(record);
    setActiveTabKey("2");
    setViewModalVisible(true);
  };

  const handleModalClose = useCallback(async () => {
    setViewModalVisible(false);
    setIsEditing(false);
    try {
      await fetchResignations(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error refreshing resignation data:", error);
      toast.error("Failed to refresh resignation data");
    }
  }, [pagination.current, pagination.pageSize, fetchResignations]);

  const handleSubmitReview = useCallback(() => {
    setViewModalVisible(true);
  }, []);

  const fetchHandoverData = useCallback(async (separationId) => {
    try {
      const response = await axiosInstance.get(
        `${handover}?separation=${separationId}`
      );
      setHandoverData(response.data.results[0]);
    } catch (error) {
      console.error("Error fetching handover data:", error);
      toast.error("Failed to fetch handover data");
    }
  }, []);

  useEffect(() => {
    if (selectedResignation) {
      fetchHandoverData(selectedResignation.id);
    }
  }, [selectedResignation, fetchHandoverData]);

  const refreshData = async () => {
    try {
      const response = await axiosInstance.get(separation);
      setResignations(response.data.results);
      const updatedResignation = response.data.results.find(
        (r) => r.id === selectedResignation.id
      );
      setSelectedResignation(updatedResignation);
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data");
    }
  };

  const handleTableChange = useCallback(
    (newPagination, filters, sorter) => {
      console.log("Pagination changed:", newPagination); // Debug log
      fetchResignations(newPagination.current, newPagination.pageSize);
    },
    [fetchResignations]
  );

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
      responsive: ["md"],
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
      responsive: ["lg"],
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      title: "Submitted Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD-MM-YYYY"),
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <Button type="primary" onClick={() => handleView(record)}>
            View
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Review Resignations"
          title="Dashboard"
          subtitle="Resignations"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={resignations}
                loading={loading || !departmentID}
                rowKey="id"
                pagination={{
                  ...pagination,
                  showTotal: (total) => `Total ${total} items`,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  responsive: true,
                  position: ["bottom", "right"],
                  onChange: (page, pageSize) => {
                    handleTableChange({ current: page, pageSize });
                  },
                  onShowSizeChange: (current, size) => {
                    handleTableChange({ current: 1, pageSize: size });
                  },
                }}
                onChange={handleTableChange}
                scroll={{ x: true }}
              />
            </div>
          </div>
        </div>
        <ViewModal
          show={viewModalVisible}
          onHide={handleModalClose}
          resignation={selectedResignation}
          employees={employees}
          onSubmit={handleSubmitReview}
          activeTabKey={activeTabKey}
          setActiveTabKey={setActiveTabKey}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleEditClick={handleEditClick}
          handleSuccess={handleSuccess}
          handoverData={handoverData}
          refreshTable={fetchResignations}
        />
      </div>
      <style jsx>{`
        .responsive-content {
          width: 100%;
          max-width: 100%;
          overflow-x: hidden;
          padding: 16px;
        }
        @media (max-width: 768px) {
          .responsive-content {
            padding: 12px;
          }
        }
        @media (max-width: 576px) {
          .responsive-content {
            padding: 8px;
          }
        }
        .table-responsive {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
      `}</style>
    </div>
  );
};

export default ViewResignationLetterRS;
