import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Row, Col } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  pdcaassigntask,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import EmployeePDCADoPhase from "./EmployeePDCADoPhase";
import DocumentViewModal from "./DocumentViewModal";

function EmployeeCheckPhase({ taskId }) {
  const [show, setShow] = useState(false);
  const [showDoc, setShowDoc] = useState(false);
  const [showDocId, setShowDocId] = useState(null);
  const [employeeID, setEmployeeID] = useState();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleCloseDoc = () => setShowDocId(null);
  const handleShowDoc = (task) => setShowDocId(task);

  console.log("Selected Task ID:", taskId);

  const fetchTasks = () => {
    axiosInstance
      .get(`${pdcaassigntask}?pdca_form=${taskId}&user=${employeeID}`)
      .then((response) => {
        setTasks(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching tasks:", err);
        setError(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setEmployeeID(response.data.employment_details[0].id);
      })
      .catch((err) => console.error("Error fetching employee details:", err));
  }, []);

  useEffect(() => {
    if (employeeID) {
      fetchTasks();
    }
  }, [employeeID]);

  const refreshTasks = () => {
    fetchTasks();
  };

  return (
    <div className="p-3">
      <div>
        <div>
          <Form>
            <Form.Group className="mb-3" controlId="resignationLetter">
              <Row className="align-items-center">
                <Col>
                  <Button>Task Monitoring</Button>
                </Col>
                <Col className="text-end">
                  <Button onClick={handleShow}>Add Task</Button>
                </Col>
              </Row>
            </Form.Group>
            <div className="table-responsive m-t-15">
              <table className="table table-striped custom-table">
                <thead>
                  <tr>
                    <th className="text-center">Task</th>
                    <th className="text-center">Start Date</th>
                    <th className="text-center">Due Date</th>
                    <th className="text-center">Assignee</th>
                    <th className="text-center">Status</th>
                    <th className="text-center">View Document</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        Loading tasks...
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan="6" className="text-center text-danger">
                        {error}
                      </td>
                    </tr>
                  ) : tasks.results?.length > 0 ? (
                    tasks.results.map((task) => (
                      <tr key={task.id}>
                        <td className="text-center">{task.pdca_task}</td>
                        <td className="text-center">
                          {task.updated_at?.split("T")[0]}
                        </td>
                        <td className="text-center">{task.due_date}</td>
                        <td className="text-center">
                          {task.assignee_details?.employee_name}
                        </td>
                        <td className="text-center">
                          {task.pdca_ed?.length > 0
                            ? "Submitted"
                            : "Not Submitted"}
                        </td>
                        <td className="text-center">
                          <Button
                            variant="secondary"
                            onClick={() => handleShowDoc(task)}
                            className="px-4"
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No tasks found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Form>
          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
              <Modal.Title>Add New Task</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <EmployeePDCADoPhase
                taskId={taskId}
                refreshTasks={refreshTasks}
              />
            </Modal.Body>
          </Modal>

          <DocumentViewModal
            taskId={showDocId}
            show={!!showDocId}
            handleClose={handleCloseDoc}
          />
        </div>
      </div>
    </div>
  );
}

export default EmployeeCheckPhase;
