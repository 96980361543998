import React, { useState, useEffect } from "react";
import { Table, Button } from "antd";
import Header from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import { employeeList } from "../../../../../helpers/endpoints/api_endpoints";

const AppraisalReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); // State to control loading

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        setLoading(true); // Start loading
        const response = await axiosInstance.get(employeeList);

        // Create a supervisor lookup based on employee IDs
        const supervisorLookup = response.data.reduce((acc, employee) => {
          acc[employee.id] = employee.employee_name;
          return acc;
        }, {});

        // Filter and map the data
        const formattedData = response.data
          .filter(
            (employee) => employee.employment_details[0].reporting_to !== null
          ) // Exclude employees with no supervisor
          .map((employee, index) => ({
            key: employee.id,
            employeeName: employee.employee_name,
            supervisorName:
              supervisorLookup[employee.employment_details[0].reporting_to], // Map supervisor ID to name
            departmentDetail:
              employee.employment_details[0].department_detail.name,
          }));

        setData(formattedData);
      } catch (error) {
        console.error("Error fetching employees", error);
      } finally {
        setLoading(false); // Stop loading
      }
    };

    fetchEmployees();
  }, []);

  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
    },
    {
      title: "Supervisor Name",
      dataIndex: "supervisorName",
      key: "supervisorName",
    },
    {
      title: "Department Name",
      dataIndex: "departmentDetail",
      key: "departmentDetail",
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      render: (text, record) => (
        <Button type="primary" onClick={() => handleAction(record.key)}>
          View Report
        </Button>
      ),
    },
  ];

  const handleAction = (key) => {
    // Action logic here
    window.location.href = `/appraisal-report-hr/${key}`;
    console.log(`Action button clicked for key: ${key}`);
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4">
            <div className="col-12">
              <h1 className="text-2xl font-bold">Appraisal Report</h1>
            </div>
          </div>
          <Table columns={columns} dataSource={data} loading={loading} />
        </div>
      </div>
    </>
  );
};

export default AppraisalReport;
