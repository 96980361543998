import React from "react";
import TrackInitializationList from "./TrackInitialization";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const TrackInitialization = () => {
  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <div className="row mb-4">
              <div className="col-12">
                <h1 className="text-2xl font-bold">Track Initialization</h1>
              </div>
            </div>
            <TrackInitializationList />
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default TrackInitialization;
