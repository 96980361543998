import React, { useEffect, useState } from "react";
import { Input, Button, Form, Row, Col, DatePicker, Spin, Select } from "antd";
import { MdOutlineCurrencyRupee } from "react-icons/md";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  jobRequestById,
  employeeList,
  departmentList,
  jobRequest,
} from "../../../../helpers/endpoints/api_endpoints";
import dayjs from "dayjs";

const { Option } = Select;

const JobRequestForm = ({ jobRequestId }) => {
  const [isEditable, setIsEditable] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState();
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [interviewType, setInterviewType] = useState([]);

  // Separate states for each field
  const [requestingPerson, setRequestingPerson] = useState("");
  const [designation, setDesignation] = useState("");
  const [firstRoundDate, setFirstRoundDate] = useState(null);
  const [secondRoundDate, setSecondRoundDate] = useState(null);
  const [firstPanelMembers, setFirstPanelMembers] = useState("");
  const [secondPanelMembers, setSecondPanelMembers] = useState("");
  const [team, setTeam] = useState("");
  const [payScaleMin, setPayScaleMin] = useState("");
  const [payScaleMax, setPayScaleMax] = useState("");
  const [hiringNumber, setHiringNumber] = useState("");
  const [education, setEducation] = useState("");
  const [experienceMin, setExperienceMin] = useState("");
  const [experienceMax, setExperienceMax] = useState("");
  const [employeeType, setEmployeeType] = useState("");
  const [reportTo, setReportTo] = useState("");
  const [positionTitle, setPositionTitle] = useState("");
  const [jobDescription, setJobDescription] = useState("");

  useEffect(() => {
    const fetchAllData = async () => {
      setLoading(true);
      console.log(jobRequestId);
      try {
        console.log("API URL:", jobRequestById(jobRequestId));

        const [empResponse, deptResponse] = await Promise.all([
          axiosInstance.get(employeeList),
          axiosInstance.get(departmentList),
        ]);
        setEmployees(empResponse.data);
        setDepartments(deptResponse.data);

        const response = await axiosInstance.get(jobRequestById(jobRequestId));
        console.log("Fetched all hiring project data:", response.data);

        const matchedData = response.data;
        setInterviewType(matchedData.interview_type);

        if (matchedData) {
          setRequestingPerson(matchedData.requesting_person || "");
          setDesignation(matchedData.designation || "");
          setFirstRoundDate(
            matchedData.first_round_date
              ? dayjs(matchedData.first_round_date)
              : null
          );
          setSecondRoundDate(
            matchedData.second_round_date
              ? dayjs(matchedData.second_round_date)
              : null
          );
          setFirstPanelMembers(matchedData.first_panel_members || "");
          setSecondPanelMembers(matchedData.second_panel_members || "");
          setTeam(matchedData.team || "");
          setPayScaleMin(matchedData.pay_scal_min || "");
          setPayScaleMax(matchedData.pay_scal_max || "");
          setHiringNumber(matchedData.hiring_number || "");
          setEducation(matchedData.education || "");
          setExperienceMin(matchedData.experience_min || "");
          setExperienceMax(matchedData.experience_max || "");
          setEmployeeType(matchedData.employee_type || "");
          setReportTo(matchedData.reporty_to || "");
          setPositionTitle(matchedData.position_title || "");
          setJobDescription(matchedData.job_description || "");

          console.log("Matched data:", matchedData);
          form.setFieldsValue({
            interview_type: matchedData.interview_type,
            shortlist_type: matchedData.shortlist_type,
            requesting_person: matchedData.requesting_person,
            designation: matchedData.designation,
            first_round_date: matchedData.first_round_date
              ? dayjs(matchedData.first_round_date)
              : null,
            second_round_date: matchedData.second_round_date
              ? dayjs(matchedData.second_round_date)
              : null,
            first_panel_members: matchedData.first_panel_members,
            second_panel_members: matchedData.second_panel_members,
            team: matchedData.team,
            pay_scal_min: matchedData.pay_scal_min,
            pay_scal_max: matchedData.pay_scal_max,
            hiring_number: matchedData.hiring_number,
            education: matchedData.education,
            experience_min: matchedData.experience_min,
            experience_max: matchedData.experience_max,
            employee_type: matchedData.employee_type,
            status: matchedData.status,
            reporty_to: matchedData.reporty_to,
            position_title: matchedData.position_title,
            job_description: matchedData.job_description,
          });
          setId(matchedData.id);
          console.log("this is the id value", id);
        } else {
          toast.error("No matching project data found");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch jrf data");
      } finally {
        setLoading(false);
      }
    };
    fetchAllData();
  }, [jobRequestId, form]);

  const handleSubmit = async (values) => {
    try {
      //setLoading(true);

      const data = {
        requesting_person: requestingPerson,
        designation: designation,
        first_round_date: firstRoundDate
          ? dayjs(firstRoundDate).format("YYYY-MM-DD")
          : null,
        second_round_date: secondRoundDate
          ? dayjs(secondRoundDate).format("YYYY-MM-DD")
          : null,
        first_panel_members: firstPanelMembers,
        second_panel_members: secondPanelMembers,
        team: team,
        pay_scal_min: payScaleMin,
        pay_scal_max: payScaleMax,
        hiring_number: hiringNumber,
        education: education,
        experience_min: experienceMin,
        experience_max: experienceMax,
        employee_type: employeeType,
        reporty_to: reportTo,
        position_title: positionTitle,
        job_description: jobDescription,
      };

      if (jobRequestId) {
        console.log("this is the payload", data);
        await axiosInstance.patch(jobRequestById(jobRequestId), data);
        toast.success("JRF updated successfully");
      } else {
        await axiosInstance.post(jobRequest, data);
        toast.success("JRF updated successfully");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      console.error("Error details:", error.response?.data);
      toast.error("Failed to submit JRF");
    } finally {
      //setLoading(false);
      handleSave();
    }
  };

  const handleEditToggle = () => {
    setIsEditable(!isEditable);
  };

  const handleSave = () => {
    setIsEditable(false);
  };

  const handleCancel = () => {
    setIsEditable(false);
  };

  return (
    <div
      style={{
        paddingTop: 30,
        maxWidth: 1000,
        margin: "0 auto",
        fontWeight: "bold",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <h3
          className="py-1 px-4 bg-black text-white"
          style={{ marginBottom: "30px" }}
        >
          Job Request Form (JRF)
        </h3>
        <Button
          onClick={handleEditToggle}
          style={{
            backgroundColor: "orange",
            borderColor: "orange",
            color: "#fff",
          }}
        >
          {isEditable ? "Cancel" : "Edit"}
        </Button>
      </div>

      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "200px",
          }}
        >
          <Spin size="large" />
        </div>
      ) : (
        <Form layout="vertical" form={form} onFinish={handleSubmit}>
          <Row gutter={24}>
            <Col span={6}>
              <Form.Item
                label="First Round Interview Date"
                name="first_round_date"
              >
                <DatePicker
                  disabled={!isEditable}
                  style={{ width: "100%" }}
                  value={firstRoundDate}
                  onChange={(date) => setFirstRoundDate(date)}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                name="first_panel_members"
                label={<strong>Panel Members First Round</strong>}
                rules={[
                  { required: true, message: "Please select panel members" },
                ]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Panel Members"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  disabled={!isEditable}
                  value={firstPanelMembers}
                  onChange={(value) => setFirstPanelMembers(value)}
                >
                  {employees.map((emp) => (
                    <Option key={emp.id} value={emp.id}>
                      {emp.employee_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {interviewType === "Technical Project Round" && (
              <>
                <Col span={6}>
                  <Form.Item
                    label="Second Round Interview Date"
                    name="second_round_date"
                  >
                    <DatePicker
                      disabled={!isEditable}
                      style={{ width: "100%" }}
                      value={secondRoundDate}
                      onChange={(date) => setSecondRoundDate(date)}
                    />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    name="second_panel_members"
                    label={<strong>Panel Members Second Round</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select panel members",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Panel Members"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      disabled={!isEditable}
                      value={secondPanelMembers}
                      onChange={(value) => setSecondPanelMembers(value)}
                    >
                      {employees.map((emp) => (
                        <Option key={emp.id} value={emp.id}>
                          {emp.employee_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={12}>
              <Form.Item
                name="requesting_person"
                label={<strong>Name of person requesting</strong>}
                rules={[
                  {
                    required: true,
                    message: "Please select requesting person",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Employee"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  disabled={!isEditable}
                  value={requestingPerson}
                  onChange={(value) => setRequestingPerson(value)}
                >
                  {employees.map((emp) => (
                    <Option key={emp.id} value={emp.id}>
                      {emp.employee_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="HabotConnect Position Title"
                name="position_title"
              >
                <Input
                  disabled={!isEditable}
                  value={positionTitle}
                  onChange={(e) => setPositionTitle(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Portal Designation" name="designation">
                <Input
                  disabled={!isEditable}
                  value={designation}
                  onChange={(e) => setDesignation(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="team"
                label={<strong>Team</strong>}
                rules={[{ required: true, message: "Please select team" }]}
              >
                <Select
                  placeholder="Select Team"
                  disabled={!isEditable}
                  value={team}
                  onChange={(value) => setTeam(value)}
                >
                  {departments.map((dept) => (
                    <Option key={dept.id} value={dept.id}>
                      {dept.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                name="reporty_to"
                label={<strong>Reports to</strong>}
                rules={[
                  { required: true, message: "Please select reporting person" },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select Reporting Person"
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option?.children
                      ?.toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  disabled={!isEditable}
                  value={reportTo}
                  onChange={(value) => setReportTo(value)}
                >
                  {employees.map((emp) => (
                    <Option key={emp.id} value={emp.id}>
                      {emp.employee_name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label={`Pay Scale`} name="payScale">
                <Input.Group
                  compact
                  style={{ display: "flex", alignItems: "center", gap: "8px" }}
                >
                  <Form.Item
                    name="pay_scal_min"
                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <Input
                      addonBefore="₹"
                      placeholder="20000"
                      disabled={!isEditable}
                      value={payScaleMin}
                      onChange={(e) => setPayScaleMin(e.target.value)}
                    />
                  </Form.Item>
                  <Form.Item
                    name="pay_scal_max"
                    style={{ marginBottom: 0, flex: 1 }}
                  >
                    <Input
                      placeholder="30000"
                      disabled={!isEditable}
                      value={payScaleMax}
                      onChange={(e) => setPayScaleMax(e.target.value)}
                    />
                  </Form.Item>
                  <span style={{ whiteSpace: "nowrap", lineHeight: "32px" }}>
                    per month
                  </span>
                </Input.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Number of Hires" name="hiring_number">
                <Input
                  disabled={!isEditable}
                  value={hiringNumber}
                  onChange={(e) => setHiringNumber(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label="Education" name="education">
                <Input.TextArea
                  disabled={!isEditable}
                  rows={3}
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item
                label="Things You Will Require Them to Know During the Interviews"
                name="job_description"
              >
                <Input.TextArea
                  disabled={!isEditable}
                  rows={4}
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Minimum Experience" name="experience_min">
                <Input
                  disabled={!isEditable}
                  value={experienceMin}
                  onChange={(e) => setExperienceMin(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Maximum Experience" name="experience_max">
                <Input
                  disabled={!isEditable}
                  value={experienceMax}
                  onChange={(e) => setExperienceMax(e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label="Employment Type" name="employee_type">
                <Input
                  disabled={!isEditable}
                  value={employeeType}
                  onChange={(e) => setEmployeeType(e.target.value)}
                />
              </Form.Item>
            </Col>
          </Row>
          {isEditable && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                onClick={handleSubmit}
                style={{
                  backgroundColor: "orange",
                  color: "#fff",
                  marginRight: 10,
                }}
              >
                Save
              </Button>
              <Button
                onClick={handleCancel}
                style={{ backgroundColor: "gray", color: "#fff" }}
              >
                Close
              </Button>
            </div>
          )}
        </Form>
      )}
    </div>
  );
};

export default JobRequestForm;
