import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../layout/Header";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  employeeDetails,
  getMonthAppraisal,
  companyAveragebyWeek,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Move the weeks array outside of the component to avoid the dependency issue
const weeks = Array.from({ length: 5 }, (_, i) => `Week ${i + 1}`);

const AppraisalTable = () => {
  const currentYear = new Date().getFullYear();
  const [activeYear, setActiveYear] = useState(currentYear);
  const [activeMonthButton, setActiveMonthButton] = useState(
    new Date().getUTCMonth() + 1
  );
  const [employeeID, setEmployeeID] = useState(null);
  const [appraisalData, setAppraisalData] = useState([]);
  const [companyAverages, setCompanyAverages] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Only show loader for initial employee details fetch
    setIsLoading(true);
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setEmployeeID(response.data.employment_details[0].id);
      })
      .catch((error) => {
        console.log("Error fetching employee details", error);
      })
      .finally(() => setIsLoading(false));
  }, []);

  const fetchAppraisalData = useCallback(() => {
    if (!employeeID) return;

    const monthName = new Date(Date.UTC(2020, activeMonthButton - 1))
      .toLocaleString("default", { month: "long" })
      .toLowerCase();

    // Only show loader when we expect data
    setIsLoading(true);
    axiosInstance
      .get(getMonthAppraisal(employeeID, activeYear, monthName))
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const topFiveAppraisalData = response.data.slice(0, 5);
          setAppraisalData(topFiveAppraisalData);
        } else {
          setAppraisalData([]); // Clear data if none exists
          setIsLoading(false); // Don't keep loading if no data
        }
      })
      .catch((error) => {
        console.log("Error fetching appraisal data", error);
        toast.error("Failed to fetch appraisal data.");
        setIsLoading(false);
      });

    // Company averages fetch
    weeks.forEach((week, index) => {
      axiosInstance
        .get(companyAveragebyWeek(index + 1, activeYear, monthName))
        .then((response) => {
          const average = response.data?.company_average || 0;
          setCompanyAverages((prev) => ({
            ...prev,
            [week]: average,
          }));
        })
        .catch((error) => {
          if (
            error.response?.data?.message ===
            "No data found for the given week, month, and year."
          ) {
            setCompanyAverages((prev) => ({
              ...prev,
              [week]: 0,
            }));
          }
        })
        .finally(() => setIsLoading(false));
    });
  }, [employeeID, activeYear, activeMonthButton]);

  useEffect(() => {
    fetchAppraisalData();
  }, [fetchAppraisalData]);

  const handleMonthClick = (monthNumber) => {
    setActiveMonthButton(monthNumber);
  };

  // Create a lookup for the appraisal data by week number
  const appraisalDataByWeek = appraisalData.reduce((acc, curr) => {
    acc[curr.week] = curr;
    return acc;
  }, {});

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          {isLoading && (
            <div className="loader-container">
              <div className="loader"></div>
            </div>
          )}
          <div className="row mb-1">
            <div className="col-12 d-flex justify-content-between">
              <h1 className="text-2xl font-bold">My Appraisal Report</h1>
              <select
                value={activeYear}
                onChange={(e) => setActiveYear(Number(e.target.value))}
                className="form-select"
                style={{ width: "150px" }}
              >
                {Array.from({ length: 6 }, (_, i) => currentYear + 1 - i).map(
                  (year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  )
                )}
              </select>
            </div>
          </div>
          <div className="row mb-0">
            <div className="col-12">
              <div className="tabs mb-1">
                {Array.from({ length: 12 }, (_, i) => i + 1).map(
                  (monthNumber) => (
                    <div
                      key={monthNumber}
                      className={`tab-item ${
                        activeMonthButton === monthNumber ? "active" : ""
                      }`}
                      onClick={() => handleMonthClick(monthNumber)}
                    >
                      {new Date(Date.UTC(2020, monthNumber - 1)).toLocaleString(
                        "default",
                        { month: "long" }
                      )}
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  {weeks.map((week) => (
                    <th key={week}>{week}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="text-wrap">
                    Percentage or number of the Actions in DCDF data movement in
                    the team track that are being automated
                  </td>
                  {weeks.map((week) => (
                    <td key={week}>
                      {appraisalDataByWeek[week]?.option1 || 0}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="text-wrap">
                    Percentage of own tasks [Automated] DCDF tracks covering
                    their own area [100% automation track for all of the
                    person's job]
                  </td>
                  {weeks.map((week) => (
                    <td key={week}>
                      {appraisalDataByWeek[week]?.option2 || 0}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="text-wrap">
                    Percentage of the friction points covered in dataflow
                    actions
                  </td>
                  {weeks.map((week) => (
                    <td key={week}>
                      {appraisalDataByWeek[week]?.option3 || 0}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className="text-wrap">
                    Number of stakeholder specifications met in own team area
                  </td>
                  {weeks.map((week) => (
                    <td key={week}>
                      {appraisalDataByWeek[week]?.option4 || 0}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    style={{
                      background: "linear-gradient(90deg, #dff0d8, #c8e6c9)",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Total
                  </td>
                  {weeks.map((week) => (
                    <td
                      key={week}
                      style={{
                        background: "linear-gradient(90deg, #dff0d8, #c8e6c9)",
                        fontWeight: "bold",
                      }}
                    >
                      {(
                        parseFloat(appraisalDataByWeek[week]?.option1 || 0) +
                        parseFloat(appraisalDataByWeek[week]?.option2 || 0) +
                        parseFloat(appraisalDataByWeek[week]?.option3 || 0) +
                        parseFloat(appraisalDataByWeek[week]?.option4 || 0)
                      ).toFixed(2)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    style={{
                      background: "linear-gradient(90deg, #dff0d8, #c8e6c9)",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Average
                  </td>
                  {weeks.map((week) => (
                    <td
                      key={week}
                      style={{
                        background: "linear-gradient(90deg, #dff0d8, #c8e6c9)",
                        fontWeight: "bold",
                      }}
                    >
                      {(
                        (parseFloat(appraisalDataByWeek[week]?.option1 || 0) +
                          parseFloat(appraisalDataByWeek[week]?.option2 || 0) +
                          parseFloat(appraisalDataByWeek[week]?.option3 || 0) +
                          parseFloat(appraisalDataByWeek[week]?.option4 || 0)) /
                        4
                      ).toFixed(2)}
                    </td>
                  ))}
                </tr>
                <tr>
                  <td
                    style={{
                      background: "linear-gradient(90deg, #ffe0b2, #ffcc80)",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Company Average
                  </td>
                  {weeks.map((week) => (
                    <td
                      key={week}
                      style={{
                        background: "linear-gradient(90deg, #ffe0b2, #ffcc80)",
                        fontWeight: "bold",
                      }}
                    >
                      {companyAverages[week] || 0}
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="mt-1 p-4 bg-gray-50 rounded-lg shadow-sm">
          <h3 className="text-lg font-semibold text-gray-800 mb-1">Comments</h3>
          <ul className="list-disc list-inside space-y-2">
            {weeks.map((week) => (
              <li key={week} className="pl-2">
                <span className="text-sm font-medium text-gray-700">
                  {week}:
                </span>
                <span className="text-sm text-gray-600 ml-2">
                  {appraisalDataByWeek[week]?.comments
                    ? appraisalDataByWeek[week].comments
                    : "No comments"}
                </span>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <style jsx>{`
        .tabs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          border-bottom: 2px solid #ddd;
          overflow-x: auto;
        }
        .tab-item {
          cursor: pointer;
          padding: 10px 15px;
          color: gray;
          white-space: nowrap;
        }
        .tab-item.active {
          color: #007bff;
          font-weight: bold;
          border-bottom: 3px solid #007bff;
        }
        .loader-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: rgba(255, 255, 255, 0.8);
          z-index: 1000;
        }
        .loader {
          width: 48px;
          height: 48px;
          border: 5px solid #fff;
          border-bottom-color: #ff3d00;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }
        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      `}</style>
    </>
  );
};

export default AppraisalTable;
