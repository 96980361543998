import React from "react";
import { Form, Input, Select, Button, Row, Col } from "antd";

const { Option } = Select;

const ViewDetailsModal = () => {
  return (
    <Form layout="vertical" style={{ maxWidth: 800, margin: "auto" }}>
      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Name">
            <Input placeholder="Autofilled" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="WhatsApp Number">
            <Input placeholder="Autofilled" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Email ID">
            <Input placeholder="Autofilled" disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Resume">
            <Input placeholder="Autofilled" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Interview Time">
            <Input placeholder="Autofilled" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Google Meet Link">
            <Button type="primary" danger>
              Join
            </Button>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Total Assessment Score">
            <Input placeholder="Autofilled" disabled />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Panel Members">
            <Select placeholder="Select">
              <Option value="">Select</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item label="Interview Status">
            <Select placeholder="Select">
              <Option value="">Select</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Notes & Remarks">
        <Input.TextArea rows={3} />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }}>
        <Button type="primary" danger>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ViewDetailsModal;
