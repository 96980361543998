import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { axiosInstance } from "../helpers/axios/axios";
import { trackInitialization } from "../helpers/endpoints/api_endpoints";

const TrackContext = createContext();

export const TrackProvider = ({ children }) => {
  const [tracks, setTracks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchTracks = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(trackInitialization);
      setTracks(response.data);
    } catch (error) {
      console.error("Error fetching tracks:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchTracks();
  }, [fetchTracks]);

  const updateTracks = useCallback((newTracksOrUpdater) => {
    setIsLoading(true);
    setTracks((prev) => {
      const newTracks =
        typeof newTracksOrUpdater === "function"
          ? newTracksOrUpdater(prev)
          : newTracksOrUpdater;
      return newTracks;
    });
    setIsLoading(false);
  }, []);

  return (
    <TrackContext.Provider
      value={{ tracks, updateTracks, isLoading, fetchTracks }}
    >
      {children}
    </TrackContext.Provider>
  );
};

export const useTrackContext = () => useContext(TrackContext);
