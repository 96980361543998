import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Modal as AntModal,
  Tabs,
  Form,
  Input,
  Spin,
  Typography,
  Rate,
  Switch,
  DatePicker,
  Row,
  Col,
  Descriptions,
  Tag,
  Divider,
} from "antd";
import { Modal } from "react-bootstrap";
import { EyeOutlined } from "@ant-design/icons";
import Header from "../../../layout/Header";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  employeeQPA,
  QPAform,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";

const { Title, Text, Paragraph } = Typography;
const { TabPane } = Tabs;

const EndDocumentQPA = () => {
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [qpaData, setQPAData] = useState([]);
  const [selectedQPA, setSelectedQPA] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [bossReviewExists, setBossReviewExists] = useState(false);

  const ratingLabels = {
    1: "Watch finances daily & act to cut losses without remorse.",
    2: "Keep 'Thinking'- jobs in-house & outsource all 'Doing' jobs.",
    3: "Reconstruct & standardize complex tasks and processes including outsourcing, into simple elements & then go accomplish them.",
    4: "Continuously look for money-making opportunities for our platform.",
    5: "Keep an open mind & embrace the diversity of people, ideas & beliefs.",
    6: "Encourage & promote passion and accountability.",
    7: "The understanding of Habot's Vision & Values must be very clear to the whole team.",
    8: "Even if you disagree, express it, and commit to Habot's course while you are a HabotTech",
  };

  const exampleLabels = {
    3: "Describe how the Assessee has taken responsibility for his or her own work.",
    4: "A. What superpowers does the Assessee possess? Please list and explain his/her superpowers and abilities",
    5: "B. What challenges does the Assessee face and in which type of situations? Please list and explain where Assessee struggles",
    6: "What specific superpowers should the Assesssee be helped out with or provided with?",
    7: "What are 2 specific contributions made by the Assessee to advance towards becoming a 'high-performing engine'?",
    8: "What stands out about the assessee",
    9: "What are the assessee's strengths?",
    10: "Comments on superpowers as mentioned above",
    11: "What can the Assessee do to develop and grow?",
  };

  const selfLabels = {
    3: "Describe how you have taken responsibility for your own work.",
    4: "A. What superpowers do you possess? Please list and explain your superpowers and abilities.",
    5: "B. What challenges do you face and in which type of situations?",
    6: "What challenges would you like your supervisor to help you with?",
    7: "What are 2 specific contributions made by you to advance towards becoming a 'high-performing engine'?",
    8: "What excites you about your work?",
    9: "What are your superpowers and What are your challenges?",
    10: "What are your aspirations and which are being met by your work?",
    11: "What can you do to develop and grow?",
  };
  // Fetch QPA list
  useEffect(() => {
    const fetchCurrentEmployee = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setCurrentEmployee(response.data);
        // After getting employee details, fetch their QPA
        fetchQPAData(response.data.employment_details[0].id);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };
    fetchCurrentEmployee();
  }, []);

  const fetchQPAData = async (employeeId, page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${employeeQPA}?supervisor=${employeeId}&is_done=true&page=${page}&page_size=${pageSize}`
      );
      // Filter completed QPAs
      const completedQPAs = response.data.results;
      setQPAData(completedQPAs);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching QPA data:", error);
      toast.error("Failed to fetch QPA data");
    } finally {
      setLoading(false);
    }
  };

  // Fetch form data when tab changes
  const handleTabChange = async (nominationId) => {
    try {
      setModalLoading(true);
      const response = await axiosInstance.get(
        `${QPAform}?nomination_qpa=${nominationId}`
      );
      if (response.data.results && response.data.results.length > 0) {
        const data = response.data.results[0];

        setFormData(data);
      }
    } catch (error) {
      console.error("Error fetching form data:", error);
      toast.error("Failed to fetch form data");
    } finally {
      setModalLoading(false);
    }
  };

  // Handle form submission
  const handleSubmit = async (values) => {
    try {
      const payload = {
        ...values,
        is_boss_review: true,
      };

      await axiosInstance.patch(`${employeeQPA}${selectedQPA.id}/`, payload);

      // Refresh table data
      await fetchQPAData(
        currentEmployee?.employment_details[0].id,
        pagination.current,
        pagination.pageSize
      );

      // Update selectedQPA with new values
      setSelectedQPA((prev) => ({
        ...prev,
        remarks: values.remarks,
        is_engine: values.is_engine,
        is_boss_review: true,
      }));
      setBossReviewExists(values.is_boss_review || false);
      setIsModalVisible(false);
      toast.success("Final review submitted successfully");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit final review");
    }
  };

  const handleViewDetails = async (record) => {
    try {
      setSelectedQPA(record);
      // Check if boss review exists in the QPA record
      setBossReviewExists(record.is_boss_review || false);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error handling view details:", error);
      toast.error("Failed to load details");
    }
  };

  const columns = [
    {
      title: "Sr.No",
      key: "sr_no",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: "user_details",
      key: "employee_name",
      render: (details) => details?.employee_name,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Department",
      dataIndex: "user_details",
      key: "department",
      render: (details) =>
        details?.employment_details[0]?.department_detail?.name,
    },
    {
      title: "Status",
      key: "status",
      render: () => <span style={{ color: "#52c41a" }}>Completed</span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => window.open(`/end-qpa/${record.id}`, "_blank")}
        >
          View Details
        </Button>
      ),
    },
  ];

  const renderFormData = () => {
    if (!formData) return null;

    const labels = formData.type === "Self" ? selfLabels : exampleLabels;
    return (
      <Card>
        <Row gutter={[0, 24]}>
          {/* Header Section */}
          <Col span={24}>
            <Text type="secondary">
              Created on: {new Date(formData.created_at).toLocaleString()}
            </Text>
          </Col>

          {/* Basic Information Section */}
          <Col span={24}>
            <Card type="inner" title="Basic Information">
              <Row gutter={[24, 16]}>
                <Col xs={24} sm={12}>
                  <Text strong>Nominated By: </Text>
                  <Text>{formData.user_details?.employee_name}</Text>
                </Col>
                <Col xs={24} sm={12}>
                  <Text strong>Department: </Text>
                  <Text>
                    {formData.user_details?.employment_details[0]
                      ?.department_detail?.name || "N/A"}
                  </Text>
                </Col>
                <Col xs={24} sm={12}>
                  <Text strong>Type: </Text>
                  <Text>{formData.type}</Text>
                </Col>
                <Col xs={24} sm={12}>
                  <Text strong>Quarter: </Text>
                  <Text>{formData.quarter}</Text>
                </Col>
                <Col xs={24} sm={12}>
                  <Text strong>Status: </Text>
                  <Tag color={formData.declaration ? "green" : "orange"}>
                    {formData.declaration ? "Completed" : "Pending"}
                  </Tag>
                </Col>
              </Row>
            </Card>
          </Col>

          {/* LP Questions Section - Styled like Assessment Details */}
          <Col span={24}>
            <Card
              type="inner"
              title={
                <Title level={4} style={{ marginBottom: 0 }}>
                  Leadership Principles Assessment
                </Title>
              }
            >
              <div style={styles.assessmentContainer}>
                <div style={styles.assessmentLabel}>
                  <Text strong>
                    What 3 specific instances can you provide of how the
                    Assessee contributed to enhancing the team's performance?
                    Please write actual cases in 50 words each.
                  </Text>
                </div>
                <div style={styles.assessmentContent}>
                  <Paragraph style={styles.contentText}>
                    {formData.instance_item || "N/A"}
                  </Paragraph>
                </div>
                <Divider style={styles.divider} />
              </div>

              <div style={styles.assessmentContainer}>
                <div style={styles.assessmentLabel}>
                  <Text strong>
                    A - Which two specific LPs did the Assessee focus on and
                    excel at during the quarter under review?
                  </Text>
                </div>
                <div style={styles.assessmentContent}>
                  <div style={{ marginBottom: "8px" }}>
                    1. {formData.checkbox_1 || "N/A"}
                  </div>
                  <div>2. {formData.checkbox_2 || "N/A"}</div>
                </div>
                <Divider style={styles.divider} />
              </div>

              <div style={styles.assessmentContainer}>
                <div style={styles.assessmentLabel}>
                  <Text strong>Answer:</Text>
                </div>
                <div style={styles.assessmentContent}>
                  <div style={{ marginBottom: "16px" }}>
                    <Text strong>For LP 1:</Text>
                    <Paragraph style={styles.contentText}>
                      {formData.example_1 || "N/A"}
                    </Paragraph>
                  </div>
                  <div>
                    <Text strong>For LP 2:</Text>
                    <Paragraph style={styles.contentText}>
                      {formData.example_2 || "N/A"}
                    </Paragraph>
                  </div>
                </div>
                <Divider style={styles.divider} />
              </div>
            </Card>
          </Col>

          {/* Ratings Section */}
          <Col span={24}>
            <Card
              type="inner"
              title={
                <div>
                  <Title level={4} style={{ marginBottom: "8px" }}>
                    Ratings
                  </Title>
                  <Text type="secondary" style={{ fontSize: "14px" }}>
                    [5 means the Assesssee fits the principle perfectly and 1
                    means the Assessee does not fit the principle at all]
                  </Text>
                </div>
              }
            >
              <Descriptions
                bordered
                column={{ xs: 1, sm: 1, md: 1 }}
                labelStyle={{
                  whiteSpace: "pre-wrap",
                  padding: "16px",
                  fontWeight: 500,
                }}
                contentStyle={{
                  padding: "16px",
                  whiteSpace: "nowrap",
                }}
              >
                {Object.entries(ratingLabels).map(([key, label]) => (
                  <Descriptions.Item
                    key={key}
                    label={
                      <div
                        style={{
                          fontWeight: 500,
                          maxWidth: "80%",
                        }}
                      >
                        {`${key}. ${label}`}
                      </div>
                    }
                  >
                    <Text>
                      {typeof formData[`rating_${key}`] === "string"
                        ? parseFloat(formData[`rating_${key}`]).toFixed(2)
                        : formData[`rating_${key}`]?.toFixed(2) || "N/A"}
                    </Text>
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Card>
          </Col>

          {/* Examples Section - Keeps new format */}
          <Col span={24}>
            <Card
              type="inner"
              title={
                <Title level={4} style={{ marginBottom: 0 }}>
                  Assessment Details
                </Title>
              }
            >
              {Object.entries(labels).map(([key, label]) => (
                <div key={key} style={styles.assessmentContainer}>
                  <div style={styles.assessmentLabel}>
                    <Text strong>{label}</Text>
                  </div>
                  <div style={styles.assessmentContent}>
                    <Paragraph style={styles.contentText}>
                      {formData[`example_${key}`] || "N/A"}
                    </Paragraph>
                  </div>
                  <Divider style={styles.divider} />
                </div>
              ))}
            </Card>
          </Col>
        </Row>
      </Card>
    );
  };

  const handleTableChange = (newPagination) => {
    fetchQPAData(
      currentEmployee?.employment_details[0].id,
      newPagination.current,
      newPagination.pageSize
    );
  };

  // Add this effect to load first tab data when modal opens
  useEffect(() => {
    if (selectedQPA && selectedQPA.nominations.length > 0) {
      // Get the first nomination's ID
      const firstNominationId = selectedQPA.nominations[0].id;
      // Fetch its data
      handleTabChange(firstNominationId);
    }
  }, [selectedQPA]); // Dependency on selectedQPA

  // Update useEffect to reset form when selectedQPA changes
  useEffect(() => {
    if (selectedQPA) {
      form.setFieldsValue({
        remarks: selectedQPA.remarks || "",
        is_engine: selectedQPA.is_engine || false,
      });
    }
  }, [selectedQPA, form]);

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">QPA Final Evaluation</h3>
              </div>
            </div>
          </div>

          <Card>
            <Table
              loading={loading}
              dataSource={qpaData}
              columns={columns}
              rowKey="id"
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              onChange={handleTableChange}
            />
          </Card>

          <Modal
            show={isModalVisible}
            onHide={() => setIsModalVisible(false)}
            size="xl"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>QPA Form Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {selectedQPA && (
                <Tabs defaultActiveKey="reviews" type="card">
                  <TabPane tab="Reviews" key="reviews">
                    <Tabs
                      defaultActiveKey={selectedQPA.nominations[0]?.id?.toString()}
                      onChange={handleTabChange}
                      type="card"
                    >
                      {selectedQPA.nominations.map((nomination) => (
                        <TabPane
                          tab={
                            nomination.nominee_details?.employee_name ||
                            "Unknown"
                          }
                          key={nomination.id}
                        >
                          {modalLoading ? (
                            <div className="text-center py-5">
                              <Spin size="large" />
                            </div>
                          ) : (
                            renderFormData()
                          )}
                        </TabPane>
                      ))}
                    </Tabs>
                  </TabPane>

                  <TabPane tab="Final Review" key="final">
                    <Card>
                      <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        initialValues={{
                          remarks: selectedQPA?.remarks || "",
                          is_engine: selectedQPA?.is_engine || false,
                        }}
                      >
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item
                              name="remarks"
                              label="Remarks"
                              rules={[
                                {
                                  required: true,
                                  message: "Please enter remarks",
                                },
                              ]}
                            >
                              <Input.TextArea
                                rows={4}
                                disabled={bossReviewExists}
                                placeholder="Enter your remarks here"
                              />
                            </Form.Item>
                          </Col>

                          <Col span={24}>
                            <Form.Item
                              name="is_engine"
                              label="Has the Habotech turned into an Engine?"
                              valuePropName="checked"
                            >
                              <Switch
                                disabled={bossReviewExists}
                                checkedChildren="Yes"
                                unCheckedChildren="No"
                              />
                            </Form.Item>
                          </Col>

                          {bossReviewExists && (
                            <Col span={24}>
                              <div
                                style={{ color: "#ff4d4f", marginBottom: 24 }}
                              >
                                Boss review has already been submitted and
                                cannot be modified.
                              </div>
                            </Col>
                          )}

                          <Col span={24}>
                            <Form.Item className="text-end mb-0">
                              <Button
                                type="primary"
                                htmlType="submit"
                                disabled={bossReviewExists}
                              >
                                Submit Final Evaluation
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    </Card>
                  </TabPane>
                </Tabs>
              )}
            </Modal.Body>
          </Modal>
        </div>
      </div>
    </>
  );
};

const styles = {
  assessmentContainer: {
    padding: "16px 0",
  },
  assessmentLabel: {
    marginBottom: "12px",
  },
  assessmentContent: {
    paddingLeft: "24px",
  },
};

export default EndDocumentQPA;
