import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addPolicy,
  policyAcknowledgement,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaFileAlt } from "react-icons/fa";

import Header from "../../../layout/Header";
import Sidebar from "../../../layout/Sidebar";

const EmployeePolicyDetails = () => {
  const { id } = useParams();
  const [policy, setPolicy] = useState(null);
  const [employeeID, setEmployeeID] = useState(null);
  const [isAcknowledged, setIsAcknowledged] = useState(false);

  useEffect(() => {
    const fetchPolicyDetails = async () => {
      try {
        const response = await axiosInstance.get(`${addPolicy}${id}/`);
        setPolicy(response.data);
        setIsAcknowledged(response.data.is_acknowledged);
        console.log("Policy Details", response.data);
      } catch (error) {
        console.error("Error fetching policy details", error);
        toast.error("Failed to fetch policy details");
      }
    };

    const fetchEmployeeDetails = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setEmployeeID(response.data.employment_details[0].id);
      } catch (error) {
        console.error("Error fetching employee details", error);
        toast.error("Failed to fetch employee details");
      }
    };

    fetchPolicyDetails();
    fetchEmployeeDetails();
  }, [id]);

  const handleViewDocument = () => {
    if (policy && policy.document) {
      window.open(policy.document, "_blank");
    } else {
      toast.error("No document available for this policy");
    }
  };

  const handleAcknowledge = async () => {
    try {
      await axiosInstance.post(`${policyAcknowledgement}${id}/${employeeID}/`);
      setIsAcknowledged(true);
      toast.success("Policy acknowledged successfully");
    } catch (error) {
      console.error("Error acknowledging policy", error);
      toast.error("Failed to acknowledge policy");
    }
  };

  if (!policy) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Header />
      <Sidebar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/app/main/dashboard">Dashboard</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/policies">Policies</Link>
                  </li>
                  <li className="breadcrumb-item active">Policy Details</li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-sm-12">
              <div className="card shadow-sm">
                <div className="card-body">
                  <h4 className="card-title text-center mb-4">{policy.name}</h4>

                  <div className="text-end mb-3">
                    {policy.document && (
                      <button
                        className="btn btn-primary me-2"
                        onClick={handleViewDocument}
                      >
                        <FaFileAlt className="me-2" />
                        View Document
                      </button>
                    )}
                    {isAcknowledged ? (
                      <button className="btn btn-success" disabled>
                        Acknowledged
                      </button>
                    ) : (
                      <button
                        className="btn btn-secondary"
                        onClick={handleAcknowledge}
                      >
                        Acknowledge
                      </button>
                    )}
                  </div>

                  <div className="list-group list-group-flush">
                    <div className="list-group-item">
                      <strong>Objectives: </strong>
                      <div className="mt-1" style={{ whiteSpace: "pre-wrap" }}>
                        {policy.objectives}
                      </div>
                    </div>
                    <div className="list-group-item">
                      <strong>Approval Person: </strong>
                      <span className="text-muted">
                        {policy.approval_details?.employee_name}
                      </span>
                    </div>
                    <div className="list-group-item">
                      <strong>Guidelines: </strong>
                      <div className="mt-1" style={{ whiteSpace: "pre-wrap" }}>
                        {policy.guidelines}
                      </div>
                    </div>
                    <div className="list-group-item">
                      <strong>Compliance Monitored By: </strong>
                      <span className="text-muted">
                        {policy.compliance_monitoring_details
                          ?.map((item) => item.employee_name)
                          .join(", ")}
                      </span>
                    </div>
                    <div className="list-group-item">
                      <strong>Made By Persons: </strong>
                      <span className="text-muted">
                        {policy.made_by_persons_details
                          ?.map((item) => item.employee_name)
                          .join(", ")}
                      </span>
                    </div>
                    <div className="list-group-item">
                      <strong>Valid Until: </strong>
                      <span className="text-muted"> {policy.valid_until}</span>
                    </div>
                    <div className="list-group-item">
                      <strong>Value Compatibility: </strong>
                      <div className="mt-1" style={{ whiteSpace: "pre-wrap" }}>
                        {policy.value_compatibility}
                      </div>
                    </div>
                    <div className="list-group-item">
                      <strong>Policy Formulation Guidelines: </strong>
                      <div className="mt-1" style={{ whiteSpace: "pre-wrap" }}>
                        {policy.policy_guidelines}
                      </div>
                    </div>
                    <div className="list-group-item">
                      <strong>Policy Amendment Date: </strong>
                      <span className="text-muted">
                        {new Date(policy.updated).toLocaleDateString()}
                      </span>
                    </div>
                    {policy.approved_date !== null && (
                      <div className="list-group-item">
                        <strong>Approved Date: </strong>
                        <span className="text-muted">
                          {new Date(policy.approved_date).toLocaleDateString()}
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeePolicyDetails;
