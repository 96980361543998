import { useCallback, useEffect, useRef, useState } from "react";
import {
  Card,
  Button,
  Select,
  Typography,
  Dropdown,
  Avatar,
  Row,
  Col,
  Menu,
  Modal,
  Spin,
  Form,
  Skeleton,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  hastag,
  employeeDetails,
  weeklyReview,
  departmentList,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const { Title, Text } = Typography;

function AddWeeklyReview() {
  const [form] = Form.useForm();
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [editingId, setEditingId] = useState(null);
  const [formdata, setformdata] = useState({
    review: "",
    hastag: [],
    employeeid: "",
  });
  const [isLoading, setIsLoading] = useState(true);
  const [previousReviews, setpreviousReviews] = useState([]);
  const [employeeReviews, setemployeeReviews] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [employeereviewsloader, setemployeereviewsloader] = useState(true);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const PAGE_SIZE = 10;

  const months = [
    { value: 1, label: "January" },
    { value: 2, label: "February" },
    { value: 3, label: "March" },
    { value: 4, label: "April" },
    { value: 5, label: "May" },
    { value: 6, label: "June" },
    { value: 7, label: "July" },
    { value: 8, label: "August" },
    { value: 9, label: "September" },
    { value: 10, label: "October" },
    { value: 11, label: "November" },
    { value: 12, label: "December" },
  ];

  // Quill editor configurations
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }], // headers
      ["bold", "italic", "underline"], // text formatting
      [{ list: "ordered" }, { list: "bullet" }], // lists
      ["clean"], // remove formatting
    ],
  };

  const validateContent = (_, value) => {
    // Check if value exists
    if (!value) {
      return Promise.reject("Please enter your weekly review");
    }

    const plainText = value.replace(/<(.|\n)*?>/g, "").trim();

    if (plainText.length === 0) {
      return Promise.reject("Please enter your weekly review");
    }

    if (plainText.length < 10) {
      return Promise.reject("Review must be at least 10 characters long");
    }

    return Promise.resolve();
  };

  const ReviewEditor = () => (
    <Form.Item
      name="review"
      rules={[
        {
          required: true,
          validator: validateContent,
        },
      ]}
      validateTrigger={["onChange", "onBlur"]}
    >
      <ReactQuill
        theme="snow"
        modules={modules}
        className="review-editor"
        placeholder="Enter your weekly review..."
        style={{
          height: "150px",
          marginBottom: "40px",
        }}
      />
    </Form.Item>
  );

  const handleDelete = (ele) => {
    setSelectedReview(ele);
    setIsDeleteModalVisible(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalVisible(false);
    setSelectedReview(null);
  };

  const moreMenu = (ele) => (
    <>
      <Menu style={styles.menuStyle}>
        <Menu.Item
          style={styles.menuItem}
          key="edit"
          onClick={() => {
            setEditingId(ele.id);
          }}
        >
          Edit
        </Menu.Item>
        <Menu.Item
          style={styles.menuItem}
          key="delete"
          danger
          onClick={() => handleDelete(ele)}
        >
          Delete
        </Menu.Item>
      </Menu>
    </>
  );
  const fetchHashtag = useCallback(async () => {
    try {
      const response = await axiosInstance.get(hastag);
      const hashtagData = response.data.results.map((item) => ({
        id: item.id,
        hashtag: item.hashtag,
      }));

      setformdata((prev) => ({
        ...prev,
        hastag: hashtagData,
      }));
    } catch (error) {
      console.error("Error fetching hashtags:", error);
      toast.error("Failed to fetch hashtags");
      setformdata((prev) => ({
        ...prev,
        hastag: [{ id: 0, hashtag: "#WeeklyReview" }],
      }));
    }
  }, []);

  const fetchEmployeeDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);

      if (response.data?.employment_details?.[0]?.id) {
        setformdata((prev) => ({
          ...prev,
          employeeid: response.data?.employment_details[0].id,
        }));
      } else {
        throw new Error("No employee details found");
      }
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  }, []);

  const fetchpreviousemployeereviews = useCallback(
    async (page = 1, monthValue = selectedMonth) => {
      if (!formdata.employeeid) return;

      try {
        setIsLoading(true);
        const monthParam = monthValue ? `&month=${monthValue}` : "";

        const response = await axiosInstance.get(
          `${weeklyReview}?employee=${formdata.employeeid}&page=${page}&page_size=${PAGE_SIZE}${monthParam}`
        );

        if (response.data.results) {
          if (page === 1) {
            setpreviousReviews(response.data.results);
          } else {
            setpreviousReviews((prev) => [...prev, ...response.data.results]);
          }

          setTotalPages(Math.ceil(response.data.count / PAGE_SIZE));
          setHasMore(response.data.results.length === PAGE_SIZE);
        }
      } catch (error) {
        console.error("Error fetching employee reviews:", error);
        toast.error("Failed to fetch previous employee reviews");
      } finally {
        setIsLoading(false);
      }
    },
    [formdata.employeeid]
  );
  const fetchEmployeeReviews = useCallback(async (departmentName = "") => {
    try {
      setemployeereviewsloader(true);
      const url = departmentName
        ? `${weeklyReview}?department_name=${departmentName}`
        : weeklyReview;

      const response = await axiosInstance.get(url);

      if (response.data?.results) {
        setemployeeReviews(response.data.results);
      }
    } catch (error) {
      console.error("Error fetching employee reviews:", error);
      toast.error("Failed to fetch employee reviews");
    } finally {
      setemployeereviewsloader(false);
    }
  }, []);

  const deletepreviewsreview = async () => {
    try {
      const response = await axiosInstance.delete(
        `${weeklyReview}${selectedReview.id}/`
      );
      if (response.status === 204) {
        await fetchpreviousemployeereviews();
        await fetchEmployeeReviews();
        toast.success("Review deleted successfully");
        setIsDeleteModalVisible(false);
      }
    } catch (error) {
      console.error("Error deleting review:", error);
      toast.error("Failed to delete review");
      setIsDeleteModalVisible(false);
    }
  };

  const fetchDepartments = useCallback(async () => {
    try {
      const response = await axiosInstance.get(departmentList);
      // Filter out test department
      const filteredDepartments = response.data.filter(
        (dept) => dept.name.toLowerCase() !== "test department"
      );
      setDepartments(filteredDepartments);
    } catch (error) {
      console.error("Error fetching departments:", error);
      toast.error("Failed to fetch departments");
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      setSubmitLoading(true);

      const payload = {
        hashtag: formdata.hastag[0]?.id,
        employee: formdata.employeeid,
        comment: values.review,
      };

      const response = await axiosInstance.post(weeklyReview, payload);

      if (response.status === 201 || response.status === 200) {
        toast.success("Weekly review submitted successfully");
        form.resetFields();
        setformdata((prev) => ({
          ...prev,
          review: "",
        }));
        await fetchpreviousemployeereviews();
        await fetchEmployeeReviews();
      }
    } catch (error) {
      console.error("Error submitting weekly review:", error);

      if (error.response?.data) {
        const errorData = error.response.data;

        if (errorData.hashtag && Array.isArray(errorData.hashtag)) {
          toast.error(errorData.hashtag[0]);
        } else if (typeof errorData === "object") {
          const firstError = Object.values(errorData)[0];
          if (Array.isArray(firstError)) {
            toast.error(firstError[0]);
          } else {
            toast.error(String(firstError));
          }
        } else if (typeof errorData === "string") {
          toast.error(errorData);
        } else {
          toast.error("Failed to submit weekly review");
        }
      } else {
        toast.error("Failed to submit weekly review");
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const ReviewSkeleton = () => (
    <Card style={styles.reviewCard}>
      <div style={styles.reviewHeader}>
        <Skeleton.Input active size="small" style={{ width: 120 }} />
        <Skeleton.Button
          active
          size="small"
          style={{ width: 32, height: 32 }}
        />
      </div>
      <Skeleton
        active
        paragraph={{ rows: 2, width: ["100%", "60%"] }}
        title={false}
      />
      <div style={styles.dateText}>
        <Skeleton.Input active size="small" style={{ width: 100 }} />
      </div>
    </Card>
  );

  const resetfilters = () => {
    setSelectedDepartment(null);
    fetchEmployeeReviews();
  };

  // Previous Weekly Reviews Section
  const PreviousReviewsSection = () => {
    const loadMoreRef = useRef(null);

    useEffect(() => {
      const observer = new IntersectionObserver(
        (entries) => {
          const first = entries[0];
          if (first.isIntersecting && hasMore && !isLoading) {
            setCurrentPage((prev) => prev + 1);
            fetchpreviousemployeereviews(currentPage + 1);
          }
        },
        { threshold: 0.1 }
      );

      const currentRef = loadMoreRef.current;
      if (currentRef) {
        observer.observe(currentRef);
      }

      return () => {
        if (currentRef) {
          observer.unobserve(currentRef);
        }
      };
    }, [hasMore, isLoading, currentPage]);

    return (
      <>
        <Title level={4} style={styles.sectionTitle}>
          Previous Weekly Reviews
        </Title>

        <Select
          style={styles.filterSelect}
          value={selectedMonth}
          onChange={(value) => {
            setSelectedMonth(value);
            fetchpreviousemployeereviews(1, value);
          }}
          placeholder="Select Month"
        >
          {months.map((month) => (
            <Select.Option key={month.value} value={month.value}>
              {month.label}
            </Select.Option>
          ))}
        </Select>
        <div style={styles.scrollableSection_modified}>
          {previousReviews?.length > 0 ? (
            <>
              {previousReviews?.map((ele, index) => (
                <Card
                  key={ele.id}
                  style={styles.reviewCard}
                  ref={
                    index === previousReviews.length - 1 ? loadMoreRef : null
                  }
                >
                  <div style={styles.reviewHeader}>
                    <Title level={5}>{ele.hashtag_info.hashtag}</Title>
                    <Dropdown
                      overlay={moreMenu(ele)}
                      trigger={["click"]}
                      overlayStyle={{ padding: 8 }}
                    >
                      <Button type="text" style={styles.moreButton}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                        >
                          <path
                            fill="none"
                            stroke="currentColor"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 5.92A.96.96 0 1 0 12 4a.96.96 0 0 0 0 1.92m0 7.04a.96.96 0 1 0 0-1.92a.96.96 0 0 0 0 1.92M12 20a.96.96 0 1 0 0-1.92a.96.96 0 0 0 0 1.92"
                          />
                        </svg>
                      </Button>
                    </Dropdown>
                  </div>

                  {editingId === ele.id ? (
                    <Form
                      initialValues={{ editedReview: ele.comment }}
                      onFinish={(values) =>
                        handleupdate(ele.id, values.editedReview)
                      }
                    >
                      <Form.Item
                        name="editedReview"
                        rules={[
                          {
                            required: true,
                            message: "Please enter your review",
                          },
                          { validator: validateContent },
                        ]}
                      >
                        <ReactQuill
                          theme="snow"
                          modules={modules}
                          className="review-editor"
                          style={{
                            height: "200px",
                            marginBottom: "40px",
                            fontSize: "14px",
                            lineHeight: "1.6",
                          }}
                        />
                      </Form.Item>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "10px",
                          gap: "10px",
                        }}
                      >
                        <Button size="small" onClick={() => setEditingId(null)}>
                          Cancel
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="small"
                          style={{ backgroundColor: "#FD705C" }}
                        >
                          Save
                        </Button>
                      </div>
                    </Form>
                  ) : (
                    <>
                      <ReactQuill
                        value={ele.comment}
                        readOnly={true}
                        theme="bubble"
                        modules={{ toolbar: false }}
                      />
                      <div style={styles.dateText}>
                        <Text type="secondary">
                          {formatDate(ele.created_at)}
                        </Text>
                      </div>
                    </>
                  )}
                </Card>
              ))}

              {hasMore && (
                <div
                  ref={loadMoreRef}
                  style={{ height: "20px", margin: "10px 0" }}
                >
                  {isLoading && <Spin />}
                </div>
              )}
            </>
          ) : (
            <div style={styles.noDataContainer}>
              {isLoading
                ? // Show multiple skeleton cards while loading
                  [...Array(3)].map((_, index) => (
                    <ReviewSkeleton key={`skeleton-${index}`} />
                  ))
                : "No previous reviews found"}
            </div>
          )}
        </div>
      </>
    );
  };

  const formatDate = (dateString) => {
    return moment(dateString).format("DD MMM YYYY, hh:mm A");
  };

  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    fetchEmployeeReviews(value);
  };

  const LoadingIcon = () => <Spin size="small" style={loadingIconStyle} />;

  const loadingIconStyle = {
    fontSize: "16px",
    marginRight: "8px",
    color: "white",
  };

  const handleupdate = async (reviewId, editedvalue) => {
    try {
      setSubmitLoading(true);

      const payload = {
        comment: editedvalue,
      };

      const response = await axiosInstance.patch(
        `${weeklyReview}${reviewId}/`,
        payload
      );

      if (response.status === 200) {
        await fetchpreviousemployeereviews();
        await fetchEmployeeReviews();
        toast.success("Weekly review updated successfully");
        setEditingId(null);
      }
    } catch (error) {
      console.error("Error updating weekly review:", error);
      toast.error(
        error.response?.data?.message || "Failed to update weekly review"
      );
    } finally {
      setSubmitLoading(false);
    }
  };

  useEffect(() => {
    const initializeData = async () => {
      setIsLoading(true);
      try {
        await Promise.all([fetchHashtag(), fetchEmployeeDetails()]);
      } catch (error) {
        console.error("Error initializing data:", error);
        toast.error("Failed to initialize data");
      } finally {
        setIsLoading(false);
      }
    };

    initializeData();
  }, [fetchHashtag, fetchEmployeeDetails]);

  useEffect(() => {
    fetchpreviousemployeereviews(1, selectedMonth);
  }, [fetchpreviousemployeereviews, selectedMonth]);

  useEffect(() => {
    fetchEmployeeReviews();
    fetchDepartments();
  }, []);

  return (
    <>
      <style>
        {`
    @media (max-width:991px) {
       div .main-wrapper .page-wrapper > div {
        height: auto !important;
      }
      
       div .main-wrapper .page-wrapper {
        height: 100% !important;
      }
      
       div .main-wrapper .page-wrapper > div > .ant-row {
        height: 100% !important;
      }
    }
  `}
      </style>
      ;
      <div style={styles.pageWrapper} className="page-wrapper">
        <div style={styles.mainContainer}>
          <Row gutter={[24, 24]} style={styles.rowStyle}>
            {/* First Card - My Weekly Reviews */}
            <Col xs={24} lg={12} style={styles.cardWrapper}>
              <Card
                style={styles.mainCardStyle_myweeklyreview}
                bodyStyle={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "24px",
                }}
              >
                <div style={styles.headerSection}>
                  <Title level={4}>My Weekly Review</Title>
                  <Button
                    type="primary"
                    style={styles.customButtonsecond}
                    icon={isLoading ? <LoadingIcon /> : null}
                    disabled={isLoading}
                  >
                    {isLoading ? "Loading..." : formdata.hastag[0]?.hashtag}
                  </Button>
                </div>
                <Form
                  form={form}
                  onFinish={handleSubmit}
                  layout="vertical"
                  style={styles.formStyle}
                >
                  {ReviewEditor()}

                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitLoading}
                      style={styles.submitButton}
                      disabled={isLoading}
                    >
                      Submit
                    </Button>
                  </Form.Item>
                </Form>

                {/* previous weekly review section */}
                {PreviousReviewsSection()}
                {/* previous weekly review section ends */}
              </Card>
            </Col>

            {/* Second Card - Employees Weekly Review */}
            <Col xs={24} lg={12} style={styles.cardWrapper}>
              <Card
                style={styles.mainCardStyle}
                bodyStyle={{
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  padding: "24px",
                }}
              >
                <Title level={4} style={styles.sectionTitle}>
                  Employees Weekly Review
                </Title>

                <div className="d-flex gap-2">
                  <Select
                    showSearch
                    style={{
                      width: "100%",
                      maxWidth: 300,
                      height: 35,
                      marginBottom: 16,
                    }}
                    placeholder="Select Department"
                    optionFilterProp="children"
                    value={selectedDepartment}
                    onChange={handleDepartmentChange}
                    allowClear
                    onClear={() => {
                      setSelectedDepartment(null);
                      fetchEmployeeReviews();
                    }}
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {departments.map((dept) => (
                      <Select.Option key={dept.id} value={dept.name}>
                        {dept.name}
                      </Select.Option>
                    ))}
                  </Select>
                  <Button
                    label="Reset Filters"
                    style={{
                      height: 35,
                      width: 120,
                      backgroundColor: "#FD705C",
                      color: "white",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onClick={resetfilters}
                  >
                    Reset
                  </Button>
                </div>
                <div style={styles.scrollableSection}>
                  {employeereviewsloader
                    ? [...Array(3)].map((_, index) => (
                        <Card
                          key={`skeleton-${index}`}
                          style={styles.reviewCard}
                        >
                          <div style={styles.employeeInfo}>
                            <Skeleton.Avatar active size={40} />
                            <div style={styles.employeeDetails}>
                              <Skeleton.Input
                                active
                                size="small"
                                style={{ width: 150, marginBottom: 8 }}
                              />
                              <Skeleton.Input
                                active
                                size="small"
                                style={{ width: 100 }}
                              />
                            </div>
                          </div>
                          <Skeleton
                            active
                            paragraph={{ rows: 2 }}
                            title={false}
                          />
                        </Card>
                      ))
                    : employeeReviews?.map((ele, index) => (
                        <Card key={index} style={styles.reviewCard}>
                          <div style={styles.employeeInfo} key={ele.id}>
                            <Avatar size={40} icon={<UserOutlined />} />
                            <div style={styles.employeeDetails}>
                              <Title level={5} style={styles.employeeName}>
                                {ele.employee_info.employee_name}
                              </Title>
                              <Text type="secondary">
                                {
                                  ele.employee_info.employment_details[0]
                                    .department_detail.name
                                }
                              </Text>
                            </div>
                          </div>
                          <ReactQuill
                            value={ele.comment}
                            readOnly={true}
                            theme="bubble"
                            modules={{ toolbar: false }}
                          />
                          <div style={styles.dateText}>
                            <Text type="secondary">
                              {formatDate(ele.created_at)}
                            </Text>
                          </div>
                        </Card>
                      ))}
                </div>

                <div style={styles.viewAllButton}>
                  <Link to="/view-weekly-review">
                    <Button type="primary" style={styles.customButton}>
                      View All
                    </Button>
                  </Link>
                </div>
              </Card>
            </Col>
          </Row>
          <Modal
            title="Delete Weekly Review"
            open={isDeleteModalVisible}
            onOk={deletepreviewsreview}
            onCancel={handleDeleteCancel}
            okText="Yes"
            cancelText="No"
            okButtonProps={{
              danger: true,
              style: { backgroundColor: "#ff4d4f" },
            }}
          >
            <p>Are you sure you want to delete the Weekly Review?</p>
          </Modal>
        </div>
      </div>
    </>
  );
}
const styles = {
  mainContainer: {
    paddingTop: "10px",
    paddingLeft: "10px",
    paddingRight: "10px",
    paddingBottom: "30px",
    minHeight: "100vh",
    background: "#f0f5ff",
  },
  cardWrapper: {
    height: "100%",
  },
  mainCardStyle: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
  },
  headerSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },

  submitButton: {
    marginBottom: "20px",
    backgroundColor: "#FD705C",
    height: "41px",
    width: "20%",
  },
  sectionTitle: {
    marginBottom: "18px",
    fontSize: 24,
  },
  filterSelect: {
    width: "140px",
    marginBottom: "16px",
  },
  scrollableSection: {
    flex: 1,
    maxHeight: "100vh",
    padding: "5px",
    marginRight: -16,
    marginLeft: -16,
    paddingRight: 16,
    paddingLeft: 16,

    "&::-webkit-scrollbar": {
      width: "8px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "4px",
      "&:hover": {
        background: "#555",
      },
    },

    scrollbarWidth: "thin",
    scrollbarColor: "#888 #f1f1f1",
  },

  reviewCard: {
    marginBottom: "16px",
    boxShadow: "0 1px 4px rgba(0,0,0,0.1)",
    borderRadius: "6px",
  },
  reviewHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  dateText: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "10px",
    color: "#8c8c8c",
  },
  employeeInfo: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  employeeDetails: {
    marginLeft: "12px",
  },
  employeeName: {
    margin: 0,
  },
  viewAllButton: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "16px",
    padding: "16px 0 0 0",
    borderTop: "1px solid #f0f0f0",
  },
  pageWrapper: {
    height: "100vh",
    overflow: "hidden",
  },
  rowStyle: {
    height: "calc(100vh - 100px)",
    marginTop: "5px",
  },
  customButton: {
    backgroundColor: "#FD705C",
    width: "130px",
    height: "41px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "90px",
      height: "28px",
    },
  },
  customButtonsecond: {
    backgroundColor: "#34444C",
    height: "41px",
    display: "flex",
    color: "white",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 768px)": {
      width: "90px",
      height: "28px",
    },
  },
  moreButton: {
    padding: 0,
    border: "none",
    background: "transparent",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "40px",
    height: "30px",
  },
  menuStyle: {
    width: "120px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  menuItem: {
    padding: "8px 16px",
    margin: "2px 0",
    borderRadius: "4px",
  },
  cardWrapper: {
    height: "100%",
  },
  mainCardStyle: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  scrollableSection: {
    flex: 1,
    overflowY: "auto",
    marginRight: -12,
    paddingRight: 12,
    "&::-webkit-scrollbar": {
      width: "6px",
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      background: "#888",
      borderRadius: "3px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  },
  reviewCard: {
    marginBottom: 16,
    borderRadius: 8,
    boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
  },
  mainCardStyle_myweeklyreview: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    background: "#ffffff",
    borderRadius: "8px",
    boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
    overflow: "auto",
  },
  editorContainer: {
    marginBottom: 16,
  },
  editor: {
    height: "200px",
    marginBottom: "20px",
  },
  reviewDisplay: {
    padding: "12px",
    borderRadius: "4px",
    backgroundColor: "#fff",
    fontSize: "14px",
    lineHeight: "1.6",
    border: "1px solid #f0f0f0",
    "& ul, & ol": {
      // Maintain list styling
      paddingLeft: "20px",
      marginBottom: "10px",
    },
    "& li": {
      marginBottom: "5px",
    },
  },
  addReviewCard: {
    marginBottom: 24,
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.06)",
  },
  reviewCard: {
    marginBottom: 16,
    borderRadius: "8px",
    boxShadow: "0 2px 8px rgba(0,0,0,0.06)",
  },
  reviewHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 12,
  },
  reviewFooter: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 12,
  },
  moreButton: {
    padding: "4px 8px",
  },
};
export default AddWeeklyReview;
