import React, { useEffect, useState, useCallback } from "react";
import { Table } from "antd";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  hrCourses,
  hrCourseById,
} from "../../../../../helpers/endpoints/api_endpoints";
import TrainingForm from "../../../Employees/EmployeeTraining/TrainingForm";
import Header from "../../../../layout/Header";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";

const TrainingApproval = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [documentLists, setDocumentLists] = useState([]);
  const [filteredDocuments, setFilteredDocuments] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isFiltering, setIsFiltering] = useState(false);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDueDate, setSelectedDueDate] = useState(null);
  const [comment, setComment] = useState("");
  const [isEditingComment, setIsEditingComment] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();

  const fetchCourses = useCallback(() => {
    let endpoint = hrCourses;

    if (selectedDepartment !== null) {
      endpoint += `?department=${selectedDepartment}`;
    }

    if (searchTerm) {
      endpoint +=
        selectedDepartment !== null
          ? `&title__icontains=${searchTerm}`
          : `?title__icontains=${searchTerm}`;
    }

    if (selectedStatus) {
      endpoint +=
        (selectedDepartment !== null || searchTerm ? `&` : `?`) +
        `${selectedStatus}=true`;
    }

    if (selectedDueDate) {
      const formattedDate = selectedDueDate.toISOString().split("T")[0];
      endpoint +=
        (selectedDepartment !== null || searchTerm || selectedStatus
          ? `&`
          : `?`) + `due_date=${formattedDate}`;
    }

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setDocumentLists(response.data);
        setFilteredDocuments(response.data);
        setIsLoading(false);
        console.log("Courses", response.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [selectedDepartment, searchTerm, selectedStatus, selectedDueDate]);

  useEffect(() => {
    setIsLoading(true);
    axiosInstance
      .get(hrCourses)
      .then((response) => {
        setDocumentLists(response.data);
        setIsLoading(false);

        const params = new URLSearchParams(location.search);
        const iid = params.get("iid");
        if (iid) {
          const record = response.data.find((doc) => doc.id === parseInt(iid));
          if (record) {
            handleShowDetails(record);
            navigate(location.pathname, { replace: true });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setIsLoading(false);
      });
  }, [shouldRefresh, location, navigate]);

  useEffect(() => {
    setIsFiltering(true);

    try {
      let filtered = [...documentLists];

      // Apply department filter
      if (selectedDepartment) {
        filtered = filtered.filter((doc) =>
          doc.department_details.some((dept) => dept.id === selectedDepartment)
        );
      }

      // Apply status filter - this will be active by default now
      if (selectedStatus) {
        filtered = filtered.filter((doc) => {
          switch (selectedStatus) {
            case "is_approval":
              return doc.is_approval === true;
            case "is_approved":
              return doc.is_approved === true;
            case "is_publish":
              return doc.is_publish === true;
            default:
              return true;
          }
        });
      }

      // Apply search filter
      if (searchTerm) {
        filtered = filtered.filter((doc) =>
          doc.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
      }

      // Apply date filter
      if (selectedDueDate) {
        filtered = filtered.filter(
          (doc) =>
            moment(doc.due_date).format("YYYY-MM-DD") ===
            moment(selectedDueDate).format("YYYY-MM-DD")
        );
      }

      setFilteredDocuments(filtered);
    } catch (error) {
      console.error("Filtering error:", error);
    } finally {
      setIsFiltering(false);
    }
  }, [
    documentLists,
    selectedDepartment,
    searchTerm,
    selectedStatus,
    selectedDueDate,
  ]);

  const handleShowDetails = (record) => {
    setSelectedRecord(record);
    setFormData(record);
    setShowDetailsModal(true);
  };

  const handleCloseDetails = () => {
    setShowDetailsModal(false);
    setComment("");
    setIsEditingComment(false);
    setSelectedRecord(null);
  };

  const handleCloseAdd = () => {
    setShowAddModal(false);
    setShouldRefresh(!shouldRefresh); // Toggle the shouldRefresh state to trigger re-fetching
  };

  const handleApprove = (recordId) => {
    const updatedRecord = {
      ...selectedRecord,
      is_approved: true,
      is_approval: false,
      is_rejected: false,
      comment: comment || selectedRecord.comment,
    };
    setSelectedRecord(updatedRecord);

    axiosInstance
      .patch(hrCourseById(recordId), {
        is_approved: true,
        is_approval: false,
        is_rejected: false,
        comment: comment || selectedRecord.comment,
      })
      .then((response) => {
        const updatedList = documentLists.map((item) =>
          item.id === recordId
            ? {
                ...item,
                is_approved: true,
                is_approval: false,
                is_rejected: false,
                comment: comment || selectedRecord.comment,
              }
            : item
        );
        setDocumentLists(updatedList);
        setFilteredDocuments(updatedList);
        toast.success("Course approved successfully!");
        handleCloseDetails();
        setComment(""); // Reset comment after successful submission
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to approve course.");
        setSelectedRecord({ ...selectedRecord, is_approved: false });
      });
  };

  const handleReject = (recordId) => {
    const updatedRecord = {
      ...selectedRecord,
      is_approved: false,
      is_approval: false,
      is_rejected: true,
      comment: comment || selectedRecord.comment,
    };
    setSelectedRecord(updatedRecord);

    axiosInstance
      .patch(hrCourseById(recordId), {
        is_approved: false,
        is_approval: false,
        is_rejected: true,
        comment: comment || selectedRecord.comment,
      })
      .then((response) => {
        const updatedList = documentLists.map((item) =>
          item.id === recordId
            ? {
                ...item,
                is_approved: false,
                is_approval: false,
                is_rejected: true,
                comment: comment || selectedRecord.comment,
              }
            : item
        );
        setDocumentLists(updatedList);
        setFilteredDocuments(updatedList);
        toast.success("Course rejected successfully!");
        handleCloseDetails();
        setComment(""); // Reset comment after successful submission
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to reject course.");
        setSelectedRecord({ ...selectedRecord, is_approved: false });
      });
  };

  const formatDuration = (durationString) => {
    if (!durationString) return "";

    let totalHours = 0;
    let totalMinutes = 0;

    if (durationString.includes(" ")) {
      // Case when the string is like "1 01:00:00"
      const [daysPart, timePart] = durationString.split(" ");
      const days = parseInt(daysPart, 10);
      const [hours, minutes] = timePart.split(":").map(Number);

      totalHours = days * 24 + hours;
      totalMinutes = minutes;
    } else {
      // Case when the string is like "21:00:00"
      const [hours, minutes] = durationString.split(":").map(Number);

      totalHours = hours;
      totalMinutes = minutes;
    }

    return `${totalHours} hours ${totalMinutes} minutes`;
  };

  const handleFilter = (departmentId) => {
    setSelectedDepartment(departmentId);
  };

  const handleUpdateComment = (recordId) => {
    if (!comment.trim()) {
      toast.error("Please provide a comment");
      return;
    }

    axiosInstance
      .patch(hrCourseById(recordId), {
        comment: comment,
      })
      .then((response) => {
        const updatedList = documentLists.map((item) =>
          item.id === recordId
            ? {
                ...item,
                comment: comment,
              }
            : item
        );
        setDocumentLists(updatedList);
        setFilteredDocuments(updatedList);
        setSelectedRecord({ ...selectedRecord, comment: comment });
        setIsEditingComment(false);
        toast.success("Comment updated successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to update comment.");
      });
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Course Name",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Requested By",
      dataIndex: ["user_details", "employee_name"],
      key: "user_details",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (text) => <div className="table-avatar">{text}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleShowDetails(record)}
            className="btn btn-primary me-2"
          >
            Show Details
          </Button>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let status = {
          text: "",
          color: "",
        };

        // Case 1: Approved
        if (record.is_approved) {
          status = {
            text: "Approved",
            color: "#28a745", // green
          };
        }
        // Case 2: Rejected
        else if (record.is_rejected) {
          status = {
            text: "Rejected",
            color: "#dc3545", // red
          };
        }
        // Case 3: Pending Approval
        else if (record.is_approval) {
          status = {
            text: "Pending",
            color: "#ffc107", // yellow
          };
        }
        // Case 4: Draft/Initial State
        else {
          status = {
            text: "Draft",
            color: "#6c757d", // gray
          };
        }

        return (
          <span
            style={{
              padding: "10px",
              color: "#fff",
              backgroundColor: status.color,
              borderRadius: "4px",
              fontWeight: "500",
            }}
          >
            {status.text}
          </span>
        );
      },
    },
  ];

  const departments = [
    { id: 1, name: "Human Resources" },
    { id: 2, name: "GACL" },
    { id: 3, name: "Technical" },
    { id: 4, name: "Operations" },
    { id: 5, name: "Marketing" },
    { id: 6, name: "Head Office" },
  ];

  return (
    <>
      <Header />

      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4">
            <div className="col-12">
              <h1 className="text-2xl font-bold">Training Course Approval</h1>
            </div>
          </div>
          <div className="row mb-10">
            <div className="col-12 pb-2 d-flex align-items-center">
              {departments.map((department) => (
                <button
                  key={department.id}
                  onClick={() => handleFilter(department.id)}
                  className={
                    selectedDepartment === department.id
                      ? "btn btn-success me-2"
                      : "btn btn-outline-success me-2"
                  }
                >
                  {department.name}
                </button>
              ))}
              <button
                onClick={() => handleFilter(null)}
                className={
                  selectedDepartment === null
                    ? "btn btn-success"
                    : "btn btn-outline-success"
                }
              >
                All
              </button>
              <div className="ms-auto d-inline-block">
                <div className="input-block mb-0 ">
                  <select
                    className="form-select mt-1"
                    value={selectedStatus}
                    onChange={(e) => setSelectedStatus(e.target.value)}
                  >
                    <option value="">All Status</option>
                    <option value="is_approval">Sent for Approval</option>
                    <option value="is_approved">Approved</option>
                    <option value="is_publish">Published</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="row mb-1">
              <div className="col-md-3">
                <div className="input-block mb-1 position-relative">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search by Course Name"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <i
                    className="fa fa-search position-absolute"
                    style={{
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></i>
                </div>
              </div>
              <div className="col-md-3">
                <div className="input-block mb-1 position-relative">
                  <DatePicker
                    selected={selectedDueDate}
                    onChange={(date) => setSelectedDueDate(date)}
                    className="form-control"
                    placeholderText="Select Due Date"
                    dateFormat="yyyy/MM/dd"
                  />
                  <i
                    className="fa fa-calendar position-absolute"
                    style={{
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  ></i>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                style={{ overflowX: "auto" }}
                columns={columns}
                dataSource={filteredDocuments}
                rowKey={(record) => record?.id}
                loading={isLoading || isFiltering}
                pagination={{
                  total: filteredDocuments.length,
                  pageSize: 10,
                  showSizeChanger: true,
                  showTotal: (total) => `Total ${total} items`,
                }}
              />
            </div>
            <Modal
              show={showDetailsModal}
              onHide={handleCloseDetails}
              size="lg"
            >
              <Modal.Header closeButton>
                <Modal.Title>Course Details</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {selectedRecord && (
                  <Form>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Title</label>
                          <input
                            type="text"
                            className="form-control"
                            name="title"
                            value={formData.title || ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Platform</label>
                          <input
                            type="text"
                            className="form-control"
                            name="platform"
                            value={formData.platform || ""}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">
                            Employee Name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="user_details"
                            value={formData.user_details?.employee_name || ""}
                            readOnly
                          />
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Description</label>
                          <textarea
                            className="form-control"
                            name="description"
                            value={formData.description || ""}
                            readOnly
                            style={{
                              minHeight: "100px",
                              resize: "vertical",
                              overflow: "auto",
                            }}
                          />
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Cost</label>
                          <input
                            type="text"
                            className="form-control"
                            name="cost"
                            value={formData.cost || ""}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <Button
                            variant="primary"
                            onClick={() =>
                              window.open(formData.course_link, "_blank")
                            }
                            style={{ marginTop: "30px" }}
                          >
                            Redirect to Course
                          </Button>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Departments</label>
                          <input
                            type="text"
                            className="form-control"
                            name="departments"
                            value={selectedRecord.department_details
                              .map((dept) => dept.name)
                              .join(", ")}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Duration</label>
                          <input
                            type="text"
                            className="form-control"
                            name="duration"
                            value={formatDuration(formData.duration)}
                            readOnly
                          />
                        </div>
                      </div>
                      <Form.Group className="mb-3">
                        <Form.Label>Comments</Form.Label>
                        {selectedRecord?.comment ? (
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={selectedRecord.comment}
                            readOnly
                            className="bg-light mb-2"
                          />
                        ) : (
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Add your comment (optional)"
                            className="mb-2"
                          />
                        )}
                      </Form.Group>
                    </div>
                  </Form>
                )}
              </Modal.Body>
              <Modal.Footer>
                {selectedRecord && selectedRecord.is_approval && (
                  <>
                    <Button
                      variant="success"
                      className="me-2"
                      onClick={() => handleApprove(selectedRecord.id)}
                    >
                      Approve
                    </Button>
                    <Button
                      variant="danger"
                      className="me-2"
                      onClick={() => handleReject(selectedRecord.id)}
                    >
                      Reject
                    </Button>
                  </>
                )}
                {selectedRecord &&
                  !selectedRecord.comment &&
                  !selectedRecord.is_approval && (
                    <Button
                      variant="primary"
                      onClick={() => handleUpdateComment(selectedRecord.id)}
                      disabled={!comment.trim()}
                      className="me-2"
                    >
                      Submit Comment
                    </Button>
                  )}
                <Button variant="secondary" onClick={handleCloseDetails}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
            <Modal show={showAddModal} onHide={handleCloseAdd} size="xl">
              <Modal.Header closeButton></Modal.Header>
              <Modal.Body>
                <TrainingForm insideModal={true} />
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseAdd}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default TrainingApproval;
