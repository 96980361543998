import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

const PredecessorFieldsModal = () => {
  const [selectedSection, setSelectedSection] = useState("Section 1");

  return (
    <div className="container mt-4">
      <div className="row">
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Track Name</Form.Label>
            <Form.Control type="text" value="GACL" readOnly />
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>End Document Name</Form.Label>
            <Form.Control as="select">
              <option>Checklist</option>
            </Form.Control>
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Document Field</Form.Label>
            <Form.Control as="select">
              <option>Track Name</option>
            </Form.Control>
          </Form.Group>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <Form.Group>
            <Form.Label>Predecessor Field</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12">
          <div className="btn-group mb-3">
            <Button
              variant={selectedSection === "Section 1" ? "danger" : "light"}
              onClick={() => setSelectedSection("Section 1")}
            >
              Section 1
            </Button>
            <Button
              variant={selectedSection === "Section 2" ? "danger" : "light"}
              onClick={() => setSelectedSection("Section 2")}
            >
              Section 2
            </Button>
            <Button
              variant={selectedSection === "Section 3" ? "danger" : "light"}
              onClick={() => setSelectedSection("Section 3")}
            >
              Section 3
            </Button>
            <Button
              variant={selectedSection === "Section 4" ? "danger" : "light"}
              onClick={() => setSelectedSection("Section 4")}
            >
              Section 4
            </Button>
            <Button
              variant={selectedSection === "Section 5" ? "danger" : "light"}
              onClick={() => setSelectedSection("Section 5")}
            >
              Section 5
            </Button>
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>ED Number</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Field Code Number</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Source Document</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Cost</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
        </div>
        <div className="col-md-4">
          <Form.Group>
            <Form.Label>Revenue</Form.Label>
            <Form.Control type="text" />
          </Form.Group>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-6">
          <Form.Group>
            <Form.Label>Action to Move the Data</Form.Label>
            <Form.Control as="textarea" />
          </Form.Group>
        </div>
        <div className="col-md-6">
          <Form.Group>
            <Form.Label>Compliances</Form.Label>
            <Form.Control as="textarea" />
          </Form.Group>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-md-12 text-end">
          <Button variant="warning">Next</Button>
        </div>
      </div>
    </div>
  );
};

export default PredecessorFieldsModal;
