import React, { useState } from "react";
import {
  Form,
  Button,
  Modal,
  Row,
  Col,
  Tabs,
  Tab,
  Table,
} from "react-bootstrap";

const HandingOverTakingOverForm = () => {
  const [activeTab, setActiveTab] = useState("view");
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    step1: {
      name: "",
      date: "",
      email: "",
      phone: "",
    },
    step2: {
      tasks: [],
    },
    platforms: [],
  });

  const [platformDetails, setPlatformDetails] = useState({
    platformName: "",
    login: "",
    password: "",
  });

  const handleTabChange = (tab) => setActiveTab(tab);
  const handleModalShow = () => setShowModal(true);
  const handleModalClose = () => setShowModal(false);

  const handleInputChange = (e, step) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [step]: {
        ...prev[step],
        [name]: value,
      },
    }));
  };

  const handlePlatformAdd = () => {
    setFormData((prev) => ({
      ...prev,
      platforms: [...prev.platforms, platformDetails],
    }));
    setPlatformDetails({ platformName: "", login: "", password: "" });
    handleModalClose();
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Tabs activeKey={activeTab} onSelect={handleTabChange} className="mb-3">
          <Tab eventKey="view" title="View">
            {/* View Wireframe UI */}
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Date of Request</th>
                  <th>Resignation Letter</th>
                  <th>Handover Form</th>
                  <th>Handover Checklist</th>
                  <th>Successor Approval</th>
                  <th>TL/Boss Approval</th>
                  <th>HR Approval</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>10-10-2024</td>
                  <td>
                    <Button variant="outline-danger">View</Button>
                  </td>
                  <td>
                    <Button variant="outline-danger">View</Button>
                  </td>
                  <td>
                    <Button variant="outline-danger">View</Button>
                  </td>
                  <td>Pending</td>
                  <td>Approved</td>
                  <td>Pending</td>
                </tr>
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="step1" title="Step 1">
            {/* Step 1: HandingOverTakingOverForm-2 */}
            <h4>Handing Over Taking Over Form - Step 1</h4>
            <Form>
              <Row className="mb-3">
                <Col>
                  <Form.Group controlId="name">
                    <Form.Label>Name of Habotech Taking Over</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={formData.step1.name}
                      onChange={(e) => handleInputChange(e, "step1")}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="date">
                    <Form.Label>Date of Announcing Hand Over</Form.Label>
                    <Form.Control
                      type="date"
                      name="date"
                      value={formData.step1.date}
                      onChange={(e) => handleInputChange(e, "step1")}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="email">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={formData.step1.email}
                      onChange={(e) => handleInputChange(e, "step1")}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="phone">
                    <Form.Label>Mobile Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      value={formData.step1.phone}
                      onChange={(e) => handleInputChange(e, "step1")}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Tab>
          <Tab eventKey="step2" title="Step 2">
            {/* Step 2: HandingOverTakingOverForm-3 */}
            <h4>Handing Over Taking Over Form - Step 2</h4>
            <Form>
              <Form.Group controlId="taskName" className="mb-3">
                <Form.Label>Task Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Task Name"
                  value={formData.step2.taskName}
                  onChange={(e) => handleInputChange(e, "step2")}
                />
              </Form.Group>
              <Button variant="outline-danger" onClick={handleModalShow}>
                Add Details
              </Button>
            </Form>

            <h5 className="mt-4">Tasks Added:</h5>
            <ul>
              {formData.step2.tasks.map((task, index) => (
                <li key={index}>{task}</li>
              ))}
            </ul>
          </Tab>
        </Tabs>

        {/* Modal: Handing Over Taking Over Form Add Details Pop-Up */}
        <Modal show={showModal} onHide={handleModalClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>Add Platform Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="platformName" className="mb-3">
                <Form.Label>Platform Name</Form.Label>
                <Form.Control
                  type="text"
                  value={platformDetails.platformName}
                  onChange={(e) =>
                    setPlatformDetails({
                      ...platformDetails,
                      platformName: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="login" className="mb-3">
                <Form.Label>Login</Form.Label>
                <Form.Control
                  type="text"
                  value={platformDetails.login}
                  onChange={(e) =>
                    setPlatformDetails({
                      ...platformDetails,
                      login: e.target.value,
                    })
                  }
                />
              </Form.Group>
              <Form.Group controlId="password" className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  value={platformDetails.password}
                  onChange={(e) =>
                    setPlatformDetails({
                      ...platformDetails,
                      password: e.target.value,
                    })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handlePlatformAdd}>
              Add Platform
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default HandingOverTakingOverForm;
