import { Modal, Button, Descriptions, Tag, Input } from "antd";
import { useState } from "react";

const { TextArea } = Input;

const VendorDetailViewModal = ({ visible, record, onClose, type }) => {
  const [expandedFields, setExpandedFields] = useState({});

  if (!record) return null;

  const toggleExpand = (fieldName) => {
    setExpandedFields((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  const LongTextContent = ({ content, fieldName }) => {
    const isExpanded = expandedFields[fieldName];
    const shouldShowToggle = content && content.length > 100;

    return (
      <div>
        <TextArea
          value={content || "N/A"}
          readOnly
          autoSize={{ minRows: 2, maxRows: isExpanded ? 10 : 2 }}
          style={{
            width: "100%",
            backgroundColor: "#f5f5f5",
            cursor: "default",
            color: "rgba(0, 0, 0, 0.88)",
            border: "1px solid #d9d9d9",
          }}
        />
        {shouldShowToggle && (
          <Button
            type="link"
            onClick={() => toggleExpand(fieldName)}
            style={{ padding: "4px 0" }}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </Button>
        )}
      </div>
    );
  };

  const fieldConfigs = [
    {
      label: "Vendor Name",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Account Type",
      value: record.account_type?.replace(/_/g, " "),
    },
    {
      label: "Amount",
      value: `${record.currency} ${record.amount}`,
    },
    {
      label: "Request Type",
      value: (
        <span
          style={{
            padding: "4px 8px",
            borderRadius: "4px",
            fontSize: "14px",
            color: record.is_urgent ? "#ff4d4f" : "#52c41a",
          }}
        >
          {record.is_urgent ? "Urgent" : "Normal"}
        </span>
      ),
    },
    ...(record.is_urgent
      ? [
          {
            label: "Urgency Reason",
            value: (
              <LongTextContent
                content={record.urgency_reason}
                fieldName="urgency_reason"
              />
            ),
            span: 2,
          },
        ]
      : []),

    {
      label: "Subscription Frequency",
      value: record.subscription_frequency?.replace(/_/g, " "),
    },
    {
      label: " Description",
      value: (
        <LongTextContent content={record.description} fieldName="description" />
      ),
      span: 2,
    },
    ...(type !== "gacl"
      ? [
          {
            label: "Approval Status",
            value: (
              <>
                <Tag
                  color={
                    record.gacl_status === "APPROVED"
                      ? "success"
                      : record.gacl_status === "REJECTED"
                      ? "error"
                      : "warning"
                  }
                >
                  Vendor STO-:
                  {record.gacl_status === "APPROVED"
                    ? "Approved"
                    : record.gacl_status === "REJECTED"
                    ? "Rejected"
                    : "Pending"}
                </Tag>
                <Tag
                  color={
                    record.gacl_status === "APPROVED"
                      ? record.rs_status === "APPROVED"
                        ? "success"
                        : "warning"
                      : "default"
                  }
                >
                  RS Sir-:
                  {record.gacl_status === "APPROVED"
                    ? record.rs_status === "APPROVED"
                      ? "Approved"
                      : "Pending"
                    : "Awaiting Vendor Sto Review"}
                </Tag>
              </>
            ),
          },
        ]
      : []),

    ...(type !== "gacl"
      ? [
          {
            label: "Vendor STO comments",
            value: (
              <LongTextContent
                content={record.gacl_comments}
                fieldName="gacl_comments"
              />
            ),
            span: 2,
          },
        ]
      : []),
    ...(type === "gacl"
      ? [
          {
            label: "RS Sir Approval Status",
            value: (
              <Tag
                color={record.rs_status === "APPROVED" ? "success" : "warning"}
              >
                {record.rs_status === "APPROVED" ? "Approved" : "Pending"}
              </Tag>
            ),
          },
        ]
      : []),
    {
      label: "RS sir Comments",
      value: (
        <LongTextContent content={record.rs_comments} fieldName="rs_comments" />
      ),
      span: 2,
    },
  ];

  return (
    <Modal
      title="Vendor Details"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      width={800}
    >
      <Descriptions bordered column={1}>
        {fieldConfigs.map((field, index) => (
          <Descriptions.Item
            key={index}
            label={field.label}
            span={field.span || 1}
          >
            {field.value || "N/A"}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Modal>
  );
};

export default VendorDetailViewModal;
