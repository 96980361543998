import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Modals from "./CareerPage2Comp/Modals";

import {
  Layout,
  Typography,
  Button,
  Card,
  Input,
  Tag,
  Row,
  Col,
  Modal,
} from "antd";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const Navbar = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  return (
    <div>
      <Header
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 50px",
          background: "#F5F5F5",
        }}
      >
        <div></div>
        <div>
          <Text
            style={{
              marginRight: 20,
              color: "#FF8C66",
              cursor: "pointer",
            }}
            onClick={() => navigate("/career-page-2")}
          >
            Job Detail
          </Text>
          <Text
            style={{ marginRight: 20, cursor: "pointer" }}
            onClick={() => navigate("/job-search-results")}
          >
            Explore Jobs
          </Text>
          <Text
            style={{ marginRight: 20, cursor: "pointer" }}
            onClick={() => setIsLoginModalOpen(true)}
          >
            Login{" "}
          </Text>
          <Button type="default" onClick={() => setIsModalOpen(true)}>
            Register
          </Button>
        </div>
      </Header>
      <Modals
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isLoginModalOpen={isLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
      />
    </div>
  );
};

export default Navbar;
