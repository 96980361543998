import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Table,
  Row,
  Col,
  Spin,
  Typography,
  Avatar,
  Statistic,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import moment from "moment";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  policyDashboard,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";

const { Title } = Typography;

const PolicyDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState({ start: null, end: null });
  const [isHr, setIsHr] = useState(false);

  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(policyDashboard);
      setDashboardData(response.data.dashboard || []);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      toast.error("Failed to fetch dashboard data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    setCurrentMonth({
      start: moment().startOf("month").format("MMMM D, YYYY"),
      end: moment().endOf("month").format("MMMM D, YYYY"),
    });
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then((response) => {
      setIsHr(
        response.data.employment_details[0].department_detail.name ===
          "Human Resource"
      );
    });
  }, []);

  const demoCostStatus = {
    totalRevenue: 0,
    totalCostTrack: 0,
    costStation: 0,
    actualCostBudget: 0,
    actualRevenueBudget: 0,
  };

  const currentStationColumns = [
    {
      title: "Station",
      dataIndex: "policy_name",
      key: "station",
      width: "40%",
    },
    {
      title: "Owner Name",
      key: "ownerName",
      width: "30%",
      render: () => "Monisha",
    },
    {
      title: "Time to complete",
      key: "timeToComplete",
      width: "30%",
      render: (_, record) => {
        if (!record.start_time || !record.end_time) return "0 minutes";
        const start = moment(record.start_time);
        const end = moment(record.end_time);
        const minutes = end.diff(start, "minutes");
        return `${minutes} minutes`;
      },
    },
  ];

  const clockStatusColumns = [
    {
      title: "Station",
      dataIndex: "policy_name",
      key: "station",
      width: "20%",
    },
    {
      title: "Owner Name",
      key: "ownerName",
      width: "20%",
      render: () => "Monisha",
    },
    {
      title: "Station Clock",
      key: "stationClock",
      width: "20%",
      render: () => "0",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_date",
      width: "20%",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : "-"),
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_date",
      width: "20%",
      render: (text) => (text ? moment(text).format("DD-MM-YYYY") : "-"),
    },
  ];

  const timelineStatusColumns = [
    {
      title: "Station",
      dataIndex: "policy_name",
      key: "station",
      width: "25%",
    },
    {
      title: "Time to Accept",
      key: "timeToAccept",
      width: "25%",
      render: () => "0",
    },
    {
      title: "Time to complete",
      key: "timeToComplete",
      width: "25%",
      render: (_, record) => {
        if (!record.start_time || !record.end_time) return "0 minutes";
        const start = moment(record.start_time);
        const end = moment(record.end_time);
        const minutes = end.diff(start, "minutes");
        return `${minutes} minutes`;
      },
    },
    {
      title: "Average Time",
      key: "averageTime",
      width: "25%",
      render: () => "0",
    },
  ];

  return (
    <>
      {isHr ? (
        <>
          <Header />
          <AdminSideBar />
        </>
      ) : (
        <></>
      )}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Title level={2}>Policy Dashboard</Title>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                <Col span={24}>
                  <Card>
                    <Title level={4}>
                      Current Month: {currentMonth.start} - {currentMonth.end}
                    </Title>
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card className="profile-card" style={{ height: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Avatar
                        size={64}
                        icon={<UserOutlined />}
                        style={{ marginBottom: "16px" }}
                      />
                      <Title level={4}>Monisha</Title>
                      <p>Policy Administrator</p>
                    </div>
                  </Card>
                </Col>
                <Col span={16}>
                  <Card title="Current Station" className="station-card">
                    <Table
                      columns={currentStationColumns}
                      dataSource={dashboardData}
                      pagination={false}
                      scroll={{ y: 180 }}
                      rowKey="policy_name"
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={24}>
                  <Card title="Clock Status" className="clock-status-card">
                    <Table
                      columns={clockStatusColumns}
                      dataSource={dashboardData}
                      pagination={false}
                      rowKey="policy_name"
                      expandable={{
                        expandedRowRender: (record) => (
                          <p style={{ margin: 0 }}>
                            Date Range:{" "}
                            {record.start_time
                              ? moment(record.start_time).format("DD-MM-YYYY")
                              : "-"}{" "}
                            -{" "}
                            {record.end_time
                              ? moment(record.end_time).format("DD-MM-YYYY")
                              : "-"}
                          </p>
                        ),
                      }}
                      scroll={{ x: "max-content" }}
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={18}>
                  <Card
                    title="Timeline Status"
                    className="timeline-status-card"
                  >
                    <Table
                      columns={timelineStatusColumns}
                      dataSource={dashboardData}
                      pagination={false}
                      scroll={{ x: "max-content" }}
                      rowKey="policy_name"
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card title="Cost Status" className="cost-status-card">
                    <Row gutter={[8, 8]}>
                      <Col span={12}>
                        <Statistic
                          title="Total Revenue"
                          value={demoCostStatus.totalRevenue}
                          prefix="AED"
                          valueStyle={{ color: "#3f8600", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Total Cost/Track"
                          value={demoCostStatus.totalCostTrack}
                          prefix="AED"
                          valueStyle={{ color: "#cf1322", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Cost/Station"
                          value={demoCostStatus.costStation}
                          prefix="AED"
                          valueStyle={{ color: "#1890ff", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Actual Cost/Budget"
                          value={demoCostStatus.actualCostBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualCostBudget > 50
                                ? "#cf1322"
                                : "#3f8600",
                            fontSize: "14px",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <Statistic
                          title="Actual Revenue/Budget"
                          value={demoCostStatus.actualRevenueBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualRevenueBudget > 50
                                ? "#3f8600"
                                : "#cf1322",
                            fontSize: "14px",
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PolicyDashboard;
