import { useState, useEffect } from "react";
import { Card, Table, Button, message, Modal, Tag } from "antd";
import {
  vendorRequest,
  vendordocuments,
} from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import VendorDetailViewModal from "./VendorDetailViewModal/VendorDetailViewModal";
import VendorReqFilterModal from "./VendorReqFilterModal/VendorReqFilterModal";
import GaclSideBar from "../../../layout/GaclSidebar";
import { useNavigate } from "react-router-dom";

const Allvendors = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [vendorDocuments, setVendorDocuments] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    rs_status: undefined,
    department_id: undefined,
    account_type: undefined,
  });

  const fetchVendorDocuments = async (vendorId) => {
    try {
      const response = await axiosInstance.get(
        `${vendordocuments}?vendor_request_id=${vendorId}`
      );
      return Array.isArray(response.data)
        ? response.data
        : Array.isArray(response.data.results)
        ? response.data.results
        : [];
    } catch (error) {
      console.error(`Error fetching documents for vendor ${vendorId}:`, error);
      return [];
    }
  };

  const handleApplyFilters = (filters) => {
    setCurrentFilters(filters);

    const queryParams = new URLSearchParams();
    queryParams.append("page", "1");
    queryParams.append("page_size", pagination.pageSize);
    queryParams.append("gacl_status", "APPROVED");

    if (filters.rs_status) {
      queryParams.append("rs_status", filters.rs_status);
    }
    if (filters.department_id) {
      queryParams.append("department_id", filters.department_id);
    }
    if (filters.account_type) {
      queryParams.append("account_type", filters.account_type);
    }

    if (filters.dateType === "single") {
      if (filters.created_at) {
        queryParams.append("created_at", filters.created_at);
      }
    } else {
      if (filters.created_at_from) {
        queryParams.append("created_at_from", filters.created_at_from);
      }
      if (filters.created_at_to) {
        queryParams.append("created_at_to", filters.created_at_to);
      }
    }

    fetchVendorDataWithFilters(queryParams);
  };

  const fetchVendorDataWithFilters = async (queryParams) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${vendorRequest}?${queryParams.toString()}`
      );

      setPagination({
        current: parseInt(queryParams.get("page")) || 1,
        pageSize: parseInt(queryParams.get("page_size")) || 10,
        total: response.data.count,
      });

      const vendors = response.data.results;

      // Fetch documents for each vendor
      const documentsPromises = vendors.map(async (vendor) => {
        const documents = await fetchVendorDocuments(vendor.id);
        return { vendorId: vendor.id, documents };
      });

      const documentsResults = await Promise.all(documentsPromises);

      const documentsLookup = documentsResults.reduce((acc, curr) => {
        acc[curr.vendorId] = curr.documents;
        return acc;
      }, {});

      setVendorDocuments(documentsLookup);
      setVendorData(vendors);
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      message.error("Failed to fetch vendor data");
      setVendorData([]);
      setVendorDocuments({});
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    console.log(record);
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    const queryParams = new URLSearchParams();

    queryParams.append("page", newPagination.current);
    queryParams.append("page_size", newPagination.pageSize);
    queryParams.append("gacl_status", "APPROVED");

    if (currentFilters.rs_status) {
      queryParams.append("rs_status", currentFilters.rs_status);
    }
    if (currentFilters.department_id) {
      queryParams.append("department_id", currentFilters.department_id);
    }
    if (currentFilters.account_type) {
      queryParams.append("account_type", currentFilters.account_type);
    }

    fetchVendorDataWithFilters(queryParams);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.append("page", pagination.current);
    queryParams.append("page_size", pagination.pageSize);
    queryParams.append("gacl_status", "APPROVED");

    fetchVendorDataWithFilters(queryParams);
  }, []);

  const DocumentButton = ({ documents }) => {
    const getFileName = (url) => {
      if (!url) return "Unknown File";
      const urlParts = url.split("/");
      let fileName = urlParts[urlParts.length - 1];
      fileName = fileName.split("?")[0];
      try {
        fileName = decodeURIComponent(fileName);
      } catch (e) {
        console.error("Error decoding filename:", e);
      }
      return fileName;
    };

    // SVG Icons as components
    const FileIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "10px", color: "#1890ff" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );

    const FolderIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "8px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
        />
      </svg>
    );

    const ClickIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginLeft: "10px", color: "#52c41a" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    );

    const InfoIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="14"
        height="14"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "5px", color: "#666" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );

    const showDocumentList = () => {
      Modal.info({
        title: (
          <div
            style={{
              borderBottom: "1px solid #f0f0f0",
              padding: "8px 0",
              marginBottom: "16px",
              fontSize: "18px",
              color: "#34444C",
            }}
          >
            Available Documents
          </div>
        ),
        content: (
          <div>
            {documents.map((doc, index) => (
              <div
                key={index}
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  padding: "12px",
                  border: "1px solid #e8e8e8",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  ":hover": {
                    transform: "translateY(-1px)",
                    boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
                  },
                }}
                onClick={() => window.open(doc.document, "_blank")}
              >
                <FileIcon />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#1890ff",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginBottom: "2px",
                    }}
                  >
                    {doc.fileName || getFileName(doc.document)}
                  </span>
                  <span
                    style={{
                      color: "#8c8c8c",
                      fontSize: "12px",
                    }}
                  >
                    Click to open in new tab
                  </span>
                </div>
                <ClickIcon />
              </div>
            ))}
            <div
              style={{
                marginTop: "20px",
                padding: "12px",
                backgroundColor: "#f6ffed",
                borderRadius: "8px",
                fontSize: "13px",
                color: "#52c41a",
                display: "flex",
                alignItems: "center",
                border: "1px solid #b7eb8f",
              }}
            >
              <InfoIcon />
              Click on any document card to open it in a new tab
            </div>
          </div>
        ),
        width: 500,
        className: "document-modal",
        icon: null,
        okText: "Close",
        okButtonProps: {
          style: {
            backgroundColor: "#34444C",
            borderColor: "#34444C",
          },
        },
      });
    };

    const handleDocumentClick = () => {
      if (documents.length === 1) {
        // For single document, open directly
        window.open(documents[0].document, "_blank");
      } else {
        // For multiple documents, show modal
        showDocumentList();
      }
    };

    return (
      <Button
        type="primary"
        onClick={handleDocumentClick}
        style={{
          backgroundColor: "#34444C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px",
          height: "30px",
          borderRadius: "6px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {documents.length === 1 ? <FileIcon /> : <FolderIcon />}
        <span style={{ marginLeft: "4px" }}>
          {documents.length > 1
            ? `View All (${documents.length})`
            : "View Document"}
        </span>
      </Button>
    );
  };

  const columns = [
    {
      title: "Requestor Name",
      dataIndex: "team_lead_info",
      key: "team_lead_info",
      render: (info) => <span>{info?.name || "-"}</span>,
    },
    {
      title: "Department Name",
      dataIndex: "department_info",
      key: "department_info",
      render: (info) => <span>{info?.name || "-"}</span>,
    },

    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (vendor_name) => <span>{vendor_name}</span>,
    },
    {
      title: "Requesetd at",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <span>
          {new Date(date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </span>
      ),
    },

    // {
    //   title: "Vendor Account Type",
    //   dataIndex: "account_type",
    //   key: "account_type",
    //   render: (type) => (
    //     <span style={{ textTransform: "capitalize" }}>
    //       {type?.replace(/_/g, " ") || "-"}
    //     </span>
    //   ),
    // },

    {
      title: "RS sir Approval Status",
      dataIndex: "rs_status",
      key: "rs_status",
      render: (status) => (
        <Tag
          color={
            status === "APPROVED"
              ? "success"
              : status === "PENDING"
              ? "warning"
              : status === "REJECTED"
              ? "error"
              : "default"
          }
        >
          {status || "-"}
        </Tag>
      ),
    },

    {
      title: "Date of action (RS sir) ",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date, record) => (
        <span>
          {record.rs_status === "REJECTED" || record.rs_status === "APPROVED"
            ? date
              ? new Date(date).toLocaleDateString("en-GB", {
                  day: "2-digit",
                  month: "2-digit",
                  year: "numeric",
                })
              : "-"
            : "N/A"}
        </span>
      ),
    },

    {
      title: "View Details",
      key: "View",
      render: (_, record) => (
        <Button
          type="primary"
          style={{
            backgroundColor: "#202F36",
            color: "white",
          }}
          onClick={() => handleViewDetails(record)}
        >
          View Details
        </Button>
      ),
    },

    {
      title: "Document",
      dataIndex: "id",
      key: "documents",
      render: (vendorId) => {
        const documents = vendorDocuments[vendorId];

        if (!documents?.length) {
          return <span>No documents</span>;
        }

        return (
          <div style={{ marginBottom: "10px" }}>
            <DocumentButton documents={documents} />
          </div>
        );
      },
    },
    {
      title: "Initiate",
      key: "actions",
      render: (_, record) => {
        const handleClick = () => {
          navigate(`/vendor-documents-upload/${record.id}`);
        };

        const buttonStyles = {
          initiated: {
            backgroundColor: "#808080",
            color: "white",
          },
          initiate: {
            backgroundColor: "#FF902E",
            color: "white",
          },
        };

        if (record.rs_status === "APPROVED") {
          if (record.has_been_initiated) {
            return (
              <Button
                type="primary"
                style={buttonStyles.initiated}
                disabled={true}
              >
                Profile Initiated
              </Button>
            );
          } else {
            return (
              <Button
                type="primary"
                style={buttonStyles.initiate}
                onClick={handleClick}
              >
                Initiate Profile Setup
              </Button>
            );
          }
        } else {
          return "N/A";
        }
      },
    },
  ];

  return (
    <>
      <GaclSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Button
            onClick={() => setIsFilterModalVisible(true)}
            style={{ marginBottom: 16 }}
            icon={
              <svg
                xmlns="https://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
              >
                <g fill="none" fill-rule="evenodd">
                  <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                  <path
                    fill="currentColor"
                    d="M16 15c1.306 0 2.418.835 2.83 2H20a1 1 0 1 1 0 2h-1.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 1 1 0-2h9.17A3 3 0 0 1 16 15m0 2a1 1 0 1 0 0 2a1 1 0 0 0 0-2M8 9a3 3 0 0 1 2.762 1.828l.067.172H20a1 1 0 0 1 .117 1.993L20 13h-9.17a3.001 3.001 0 0 1-5.592.172L5.17 13H4a1 1 0 0 1-.117-1.993L4 11h1.17A3 3 0 0 1 8 9m0 2a1 1 0 1 0 0 2a1 1 0 0 0 0-2m8-8c1.306 0 2.418.835 2.83 2H20a1 1 0 1 1 0 2h-1.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1 0-2h9.17A3 3 0 0 1 16 3m0 2a1 1 0 1 0 0 2a1 1 0 0 0 0-2"
                  />
                </g>
              </svg>
            }
          >
            Filter
          </Button>
          <Card>
            <Table
              loading={loading}
              dataSource={vendorData}
              columns={columns}
              rowKey="id"
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                pageSizeOptions: ["10", "20", "30", "50"],
              }}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
              style={{
                overflowX: "auto",
                width: "100%",
              }}
            />

            {/* Modal to view the comments */}
            <Modal
              title="Comments"
              open={isCommentModalVisible}
              onCancel={() => setIsCommentModalVisible(false)}
              footer={[
                <Button
                  key="close"
                  onClick={() => setIsCommentModalVisible(false)}
                >
                  Close
                </Button>,
              ]}
            >
              <p>{selectedComment}</p>
            </Modal>
          </Card>

          <VendorDetailViewModal
            visible={isModalVisible}
            record={selectedRecord}
            onClose={() => {
              setIsModalVisible(false);
              setSelectedRecord(null);
            }}
            type={"gacl"}
          />
          <VendorReqFilterModal
            isVisible={isFilterModalVisible}
            onClose={() => setIsFilterModalVisible(false)}
            onApplyFilters={handleApplyFilters}
            currentFilters={currentFilters}
          />
        </div>
      </div>
    </>
  );
};

export default Allvendors;
