import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Select,
  message,
  Image,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { handoverCheckList } from "../../../../helpers/endpoints/api_endpoints";

const { Option } = Select;

const HandoverChecklist = ({ selectedResignation }) => {
  const [handOverChecklistData, setHandOverChecklistData] = useState(null);
  const [loading, setLoading] = useState(true); // For data fetching
  const [submitting, setSubmitting] = useState(false); // For form submission
  const [form] = Form.useForm();

  // Fetch Existing Data
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
      .then((response) => {
        console.log("Fetched Checklist Data:", response.data);
        setHandOverChecklistData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching checklist:", error);
      })
      .finally(() => setLoading(false));
  }, [selectedResignation]);

  // Populate Form Fields with Fetched Data
  useEffect(() => {
    if (handOverChecklistData?.results?.length > 0) {
      const checklist = handOverChecklistData.results[0];
      form.setFieldsValue({
        status1: checklist.status_1 ? "Yes" : "No",
        status2: checklist.status_2 ? "Yes" : "No",
        status3: checklist.status_3 ? "Yes" : "No",
        status4: checklist.status_4 ? "Yes" : "No",
        status5: checklist.status_5 ? "Yes" : "No",
        status6: checklist.status_6 ? "Yes" : "No",
        status7: checklist.status_7 ? "Yes" : "No",
        notes1: checklist.comments1 ?? "",
        notes2: checklist.comments2 ?? "",
        notes3: checklist.comments3 ?? "",
        notes4: checklist.comments4 ?? "",
        notes5: checklist.comments5 ?? "",
        notes6: checklist.comments6 ?? "",
        notes7: checklist.comments7 ?? "",
      });
    }
  }, [handOverChecklistData]);

  // Handle Form Submission (PATCH for updating taking_over_person_signature)
  const handleSubmit = (values) => {
    if (
      !values.taking_over_person_signature ||
      !values.taking_over_person_signature.originFileObj
    ) {
      message.error("Taking over Person's Signature is required!");
      return;
    }

    setSubmitting(true); // Start submission loader
    const checklistId = handOverChecklistData?.results?.[0]?.id;
    const formData = new FormData();
    formData.append(
      "taking_over_person_signature",
      values.taking_over_person_signature.originFileObj
    );
    formData.append("submitted_by_employee_taking", "true");

    console.log("Form Data Before Submission:");
    for (let pair of formData.entries()) {
      if (pair[1] instanceof File) {
        console.log(
          `${pair[0]}: File Name: ${pair[1].name}, Type: ${pair[1].type}, Size: ${pair[1].size} bytes`
        );
      } else {
        console.log(`${pair[0]}:`, pair[1]);
      }
    }

    axiosInstance
      .patch(`${handoverCheckList}${checklistId}/`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        message.success("Signature uploaded successfully!");
        console.log("Response:", response.data);
        // Re-fetch the data to update the UI in real time
        axiosInstance
          .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
          .then((res) => {
            setHandOverChecklistData(res.data);
          })
          .catch((err) => {
            console.error("Error re-fetching checklist data:", err);
          });
      })
      .catch((error) => {
        message.error("Failed to upload signature.");
        console.error("Error:", error);
      })
      .finally(() => setSubmitting(false));
  };

  const dynamicQuestions = [
    {
      question:
        "Have all the tracks that the resigned Habotech was the owner of been explained and handed over to the Habotech taking over?",
      statusName: "status1",
      notesName: "notes1",
    },
    {
      question:
        "Has the resigned Habotech completed giving the required training and knowledge transfer for the Habotech taking over?",
      statusName: "status2",
      notesName: "notes2",
    },
    {
      question:
        "Have the user IDs and passwords of all the platforms been shared by the Habotech leaving with the Habotech taking over?",
      statusName: "status3",
      notesName: "notes3",
    },
    {
      question:
        "Have ongoing tasks/projects been handed over with clear instructions?",
      statusName: "status4",
      notesName: "notes4",
    },
    {
      question:
        "Have all relevant documents, files, and resources been shared with the Habotech taking over?",
      statusName: "status5",
      notesName: "notes5",
    },
    {
      question:
        "Has an introduction been arranged between the new joined/taking over habotech and key internal and external contacts related to the tasks?",
      statusName: "status6",
      notesName: "notes6",
    },
    {
      question:
        "Has the habotech taking over been briefed on all pending actions or next steps that need immediate attention?",
      statusName: "status7",
      notesName: "notes7",
    },
  ];
  const uploadProps = {
    beforeUpload: (file) => {
      const isValid = file.type === "image/png" || file.type === "image/jpeg";
      if (!isValid) {
        message.error("You can only upload PNG or JPEG file!");
      }
      return isValid;
    },
    onRemove: () => {
      message.success("File removed successfully!");
    },
  };
  return (
    <div
      style={{
        margin: "auto",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
      }}
    >
      <h3
        style={{
          backgroundColor: "#374151",
          color: "#FFFFFF",
          padding: "10px",
          borderRadius: "4px",
          marginBottom: "20px",
        }}
      >
        Handover Checklist
      </h3>
      <p>
        This checklist needs to be completed and signed by the team
        leader/supervisor in order for the resigned employee to receive the pay
        for the month and the experience certificate.
      </p>

      {loading ? (
        <Spin
          size="large"
          style={{ display: "block", textAlign: "center", margin: "20px 0" }}
        />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                initialValue={
                  selectedResignation?.user_details?.employee_name ?? ""
                }
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Department"
                name="department"
                initialValue={
                  selectedResignation?.user_details?.employment_details[0]
                    ?.department_detail?.name
                }
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Designation"
                name="designation"
                initialValue={
                  selectedResignation?.user_details?.employment_details[0]
                    ?.designation_detail?.name
                }
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Last Working Day"
                name="lastWorkingDay"
                initialValue={selectedResignation?.last_working_date}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          {dynamicQuestions.map((item, index) => (
            <div key={index}>
              <h6 style={{ marginTop: "20px" }}>{item.question}</h6>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Status" name={item.statusName}>
                    <Input
                      value={
                        form.getFieldValue(item.statusName) || "Loading..."
                      }
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="Notes/Comments" name={item.notesName}>
                    <Input.TextArea
                      value={form.getFieldValue(item.notesName) || "Loading..."}
                      autoSize
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
          {selectedResignation?.is_tl ? (
            <>
              {" "}
              <h3 style={{ marginTop: "20px" }}>TL Leaving Signature</h3>
              {handOverChecklistData?.results[0]?.tl_signature ? (
                <Image
                  width={300}
                  src={handOverChecklistData?.results[0]?.tl_signature}
                  preview={{
                    zIndex: 3000,
                    getContainer: document.body,
                  }}
                />
              ) : (
                "Not Approved by TL Leaving"
              )}
              {handOverChecklistData?.results?.[0]?.id &&
                handOverChecklistData?.results?.[0]
                  ?.submitted_by_employee_taking === true &&
                handOverChecklistData?.results?.[0]
                  ?.submitted_by_employee_resigning === true && (
                  <h3 style={{ marginTop: "20px" }}>HR's Signature</h3>
                )}
              {handOverChecklistData?.results?.[0]?.id &&
                handOverChecklistData?.results?.[0]
                  ?.submitted_by_employee_taking === true &&
                handOverChecklistData?.results?.[0]
                  ?.submitted_by_employee_resigning === true && (
                  <Image
                    width={300}
                    src={handOverChecklistData?.results[0]?.hr_signature}
                    preview={{
                      zIndex: 3000,
                      getContainer: document.body,
                    }}
                  />
                )}
              <h3 style={{ marginTop: "20px" }}>
                Person Taking Over Signature
              </h3>
              {handOverChecklistData?.results[0]
                ?.taking_over_person_signature ? (
                handOverChecklistData?.results[0]
                  ?.taking_over_person_signature ? (
                  <Image
                    width={300}
                    src={
                      handOverChecklistData?.results[0]
                        ?.taking_over_person_signature
                    }
                    preview={{
                      zIndex: 3000,
                      getContainer: document.body,
                    }}
                  />
                ) : (
                  "Not Approved by TP "
                )
              ) : (
                <Form.Item
                  label="Person Taking Over Signature"
                  name="taking_over_person_signature"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please upload Person Taking Over Signature signature",
                    },
                  ]}
                  valuePropName="file"
                  getValueFromEvent={(e) =>
                    e && e.fileList.length > 0 ? e.fileList[0] : null
                  }
                >
                  <Upload maxCount={1} beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />} disabled={submitting}>
                      Upload Signature
                    </Button>
                  </Upload>
                </Form.Item>
              )}
              {handOverChecklistData?.results[0]
                ?.taking_over_person_signature ? (
                ""
              ) : (
                <Button type="primary" htmlType="submit" disabled={submitting}>
                  {submitting ? <Spin /> : "Submit"}
                </Button>
              )}
            </>
          ) : (
            <>
              {" "}
              {handOverChecklistData?.results[0]?.tl_signature && (
                <h3 style={{ marginTop: "20px" }}>TL Signature</h3>
              )}
              {handOverChecklistData?.results[0]?.tl_signature ? (
                <Image
                  width={300}
                  src={handOverChecklistData?.results[0]?.tl_signature}
                  preview={{
                    zIndex: 3000,
                    getContainer: document.body,
                  }}
                />
              ) : (
                "Not Approved by TL"
              )}
              {handOverChecklistData?.results[0]?.person_signature && (
                <h3 style={{ marginTop: "20px" }}>
                  Employee Leaving Signature
                </h3>
              )}
              {handOverChecklistData?.results[0]?.person_signature ? (
                <Image
                  width={300}
                  src={handOverChecklistData?.results[0]?.person_signature}
                  preview={{
                    zIndex: 3000,
                    getContainer: document.body,
                  }}
                />
              ) : (
                "Not Approved by Person Resigning"
              )}
              <h3 style={{ marginTop: "20px" }}>
                Person Taking Over Signature
              </h3>
              {handOverChecklistData?.results[0]
                ?.taking_over_person_signature ? (
                handOverChecklistData?.results[0]
                  ?.taking_over_person_signature ? (
                  <Image
                    width={300}
                    src={
                      handOverChecklistData?.results[0]
                        ?.taking_over_person_signature
                    }
                    preview={{
                      zIndex: 3000,
                      getContainer: document.body,
                    }}
                  />
                ) : (
                  "Not Approved by TP "
                )
              ) : (
                <Form.Item
                  label="Person Taking Over Signature"
                  name="taking_over_person_signature"
                  rules={[
                    {
                      required: true,
                      message:
                        "Please upload Person Taking Over Signature signature",
                    },
                  ]}
                  valuePropName="file"
                  getValueFromEvent={(e) =>
                    e && e.fileList.length > 0 ? e.fileList[0] : null
                  }
                >
                  <Upload maxCount={1} beforeUpload={() => false}>
                    <Button icon={<UploadOutlined />} disabled={submitting}>
                      Upload Signature
                    </Button>
                  </Upload>
                </Form.Item>
              )}
              {handOverChecklistData?.results[0]
                ?.taking_over_person_signature ? (
                ""
              ) : (
                <Button type="primary" htmlType="submit" disabled={submitting}>
                  {submitting ? <Spin /> : "Submit"}
                </Button>
              )}
            </>
          )}
        </Form>
      )}
    </div>
  );
};

export default HandoverChecklist;
