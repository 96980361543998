import React, { useState } from "react";
import { Steps, Form, Input, Select, Button, Row, Col, Card } from "antd";
import { CiCircleCheck } from "react-icons/ci";
import EmployeeAddress from "./detail/EmployeeAddress";
import EmployeeQualification from "./detail/EmployeeQualification";
import EmergencyContactForm from "./detail/EmergencyContactForm";
import DocumentUpload from "./detail/DocumentUpload";

const { Step } = Steps;

const EmployeePersonalDetails = () => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => {
    form.validateFields().then(() => setIsEditing(false));
  };

  return (
    <Card
      title="Employee Personal Details"
      extra={
        isEditing ? (
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        ) : (
          <Button onClick={handleEdit}>Edit</Button>
        )
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ maxWidth: "90%", margin: "auto" }}
      >
        <Row gutter={24}>
          <Col xs={24} md={12}>
            <Form.Item label="Gender">
              <Select placeholder="Select" disabled={!isEditing}>
                <Select.Option value="male">Male</Select.Option>
                <Select.Option value="female">Female</Select.Option>
                <Select.Option value="other">Other</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Marital Status">
              <Select placeholder="Select" disabled={!isEditing}>
                <Select.Option value="single">Single</Select.Option>
                <Select.Option value="married">Married</Select.Option>
                <Select.Option value="divorced">Divorced</Select.Option>
                <Select.Option value="widowed">Widowed</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Blood Group">
              <Select placeholder="Select" disabled={!isEditing}>
                <Select.Option value="A+">A+</Select.Option>
                <Select.Option value="A-">A-</Select.Option>
                <Select.Option value="B+">B+</Select.Option>
                <Select.Option value="B-">B-</Select.Option>
                <Select.Option value="O+">O+</Select.Option>
                <Select.Option value="O-">O-</Select.Option>
                <Select.Option value="AB+">AB+</Select.Option>
                <Select.Option value="AB-">AB-</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item label="Religion">
              <Select placeholder="Select" disabled={!isEditing}>
                <Select.Option value="hindu">Hindu</Select.Option>
                <Select.Option value="muslim">Muslim</Select.Option>
                <Select.Option value="christian">Christian</Select.Option>
                <Select.Option value="sikh">Sikh</Select.Option>
                <Select.Option value="other">Other</Select.Option>
              </Select>
            </Form.Item>
          </Col>

          <Col xs={12} md={6}>
            <Form.Item label="Birthdate">
              <Input.Group compact>
                <Input
                  style={{ width: "30%" }}
                  placeholder="DD"
                  disabled={!isEditing}
                />
                <Input
                  style={{ width: "30%" }}
                  placeholder="MM"
                  disabled={!isEditing}
                />
                <Input
                  style={{ width: "40%" }}
                  placeholder="YYYY"
                  disabled={!isEditing}
                />
              </Input.Group>
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Personal Email Address">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Mobile Number">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Whatsapp Number">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Landlline Number (if applicable)">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
const steps = [
  {
    title: "Employee Personal Details",
    content: (
      <Card style={{ fontWeight: "bold" }}>
        <EmployeePersonalDetails />
      </Card>
    ),
  },
  {
    title: "Employee's Address",
    content: (
      <Card style={{ fontWeight: "bold" }}>
        <EmployeeAddress />
      </Card>
    ),
  },
  {
    title: "Employee Contact Information",
    content: (
      <Card style={{ fontWeight: "bold" }}>
        <EmergencyContactForm />
      </Card>
    ),
  },
  {
    title: "Employee Qualification",
    content: (
      <Card style={{ fontWeight: "bold" }}>
        <EmployeeQualification />
      </Card>
    ),
  },
  {
    title: "Documents",
    content: (
      <Card style={{ fontWeight: "bold" }}>
        <DocumentUpload />
      </Card>
    ),
  },
];

const DataCollectionForm = () => {
  const [current, setCurrent] = useState(0);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid position-relative">
        <div style={{ padding: 20 }}>
          <h3
            style={{
              background: "#1D2939",
              color: "white",
              padding: 5,
              borderRadius: 5,
              marginBottom: "50px",
              width: "auto",
              display: "inline-block",
            }}
          >
            Data Collection form - New Joinee
          </h3>
          <Steps current={current}>
            {steps.map((step, index) => (
              <Step
                key={step.title}
                title={step.title}
                status={
                  index < current
                    ? "finish"
                    : index === current
                    ? "process"
                    : "wait"
                }
                icon={index < current ? <CiCircleCheck /> : null}
              />
            ))}
          </Steps>
          <div style={{ marginTop: 20 }}>{steps[current].content}</div>
          <div style={{ textAlign: "right", margin: "20px 0px" }}>
            {current > 0 && (
              <Button
                style={{ marginRight: 8 }}
                onClick={() => setCurrent(current - 1)}
              >
                Back
              </Button>
            )}
            <Button
              type="primary"
              style={{ backgroundColor: "#1D2939", borderColor: "#1D2939" }}
              onClick={() => setCurrent(current + 1)}
            >
              {current === steps.length - 1 ? "Save" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataCollectionForm;
