import React, { useState } from "react";
import { Modal, Select, Input, Button } from "antd";

const { Option } = Select;

const JobApplicationModal = ({ visible, onClose }) => {
  return (
    <div>
      <div
        style={{
          backgroundColor: "#1D1D1D",
          color: "white",
          padding: "10px",
          borderRadius: "5px 5px 0 0",
          textAlign: "center",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        Before you begin with your application, just answer a few quick
        job-related questions—then you’re good to go!
      </div>

      <div style={{ padding: "20px" }}>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
            gap: "20px",
          }}
        >
          <div>
            <label>Are you okay with the Salary mentioned in the Ad? *</label>
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </div>
          {/* <div>
            <label>Do you own a company or work as a freelancer? *</label>
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </div>
          <div>
            <label>Have you completed your graduation? *</label>
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </div> */}
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <div>
            <label>Currently, are you engaged in any studies? *</label>
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </div>
          <div>
            <label>experience in this field? [In years or months] *</label>
            <div style={{ display: "flex", gap: "10px" }}>
              <Input placeholder="Years" style={{ width: "50%" }} />
              <Input placeholder="Months" style={{ width: "50%" }} />
            </div>
          </div>
        </div>

        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "20px",
            marginTop: "20px",
          }}
        >
          <div>
            <label>
              There will be a project presentation round. Will you be able to
              complete the project and present it? *
            </label>
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </div>
          <div>
            <label>
              Do you have your own laptop & internet connection bandwidth that
              allows continuous audio and video usage? *
            </label>
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </div>
        </div>

        <div style={{ marginTop: "20px" }}>
          <label>How soon can you join? *</label>
          <Select placeholder="Select" style={{ width: "100%" }}>
            <Option value="immediate">Immediate</Option>
            <Option value="1_month">Within 1 month</Option>
            <Option value="2_months">Within 2 months</Option>
          </Select>
        </div>

        <div style={{ textAlign: "right", marginTop: "20px" }}>
          <Button
            type="primary"
            style={{
              background: "#FF6B4A",
              border: "none",
              padding: "0px 30px",
            }}
          >
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
};

export default JobApplicationModal;
