import { useState, useEffect } from "react";
import { Form, Spinner } from "react-bootstrap";
import { Tag, Pagination, message, Button, Modal } from "antd";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  InvoiceDocuments,
  vendordocumentsProfile,
} from "../../../../../helpers/endpoints/api_endpoints";
import { TrackProvider } from "../../../../../contexts/TrackContext";
import OperationSideBar from "../../../../layout/OperationsSidebar";
import ModalAddVendorDetails from "./ModalAddVendorDetails";
import ModalViewVendorDetails from "./ModalViewVendorDetails";

function VendorExtractionDetailForm() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [loading, setLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [documentsData, setDocumentsData] = useState({});
  const [error, setError] = useState([]);

  const [documentLoading, setDocumentLoading] = useState({});

  const fetchIntitatedProfile = async (page = 1, pageSize = 10) => {
    setLoading(true);
    setError([]);
    try {
      const response = await axiosInstance.get(vendordocumentsProfile, {
        params: {
          page,
          page_size: pageSize,
        },
      });

      if (response.data?.results) {
        const vendorResults = response.data.results;

        setVendorData({
          ...response.data,
          pagination: {
            current: page,
            pageSize,
            total: response.data.count || vendorResults.length,
          },
        });

        await Promise.all(
          vendorResults.map((vendor) => fetchVendorDocuments(vendor.id))
        );
      }
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      setVendorData({
        results: [],
        pagination: { current: 1, pageSize: 10, total: 0 },
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchVendorDocuments = async (vendorId) => {
    if (!vendorId) return;

    try {
      setDocumentLoading((prev) => ({ ...prev, [vendorId]: true }));

      const { data } = await axiosInstance.get(InvoiceDocuments, {
        params: { initiated_profile_id: vendorId },
      });

      const documents = data?.results || [];

      setDocumentsData((prev) => ({
        ...prev,
        [vendorId]: documents,
      }));
    } catch (error) {
      console.error(`Error fetching documents for vendor ${vendorId}:`, error);
      setDocumentsData((prev) => ({
        ...prev,
        [vendorId]: [],
      }));
    } finally {
      setDocumentLoading((prev) => ({ ...prev, [vendorId]: false }));
    }
  };
  useEffect(() => {
    fetchIntitatedProfile(currentPage, pageSize);
  }, []);

  // Calculate pagination values
  const indexOfLastItem = currentPage * pageSize;
  const indexOfFirstItem = indexOfLastItem - pageSize;

  const handlePageChange = (page, newPageSize) => {
    setCurrentPage(page);
    setPageSize(newPageSize);
    fetchIntitatedProfile(page, newPageSize);
  };

  const handleDocumentClick = (documents) => {
    if (documents.length === 0) {
      message.warning("No invoice document available");
      return;
    }

    documents.forEach((doc) => {
      window.open(doc.document, "_blank", "noopener,noreferrer");
    });
  };

  const DocumentButton = ({ documents }) => {
    const getFileName = (url) => {
      if (!url) return "Unknown File";
      const urlParts = url.split("/");
      let fileName = urlParts[urlParts.length - 1];
      fileName = fileName.split("?")[0];
      try {
        fileName = decodeURIComponent(fileName);
      } catch (e) {
        console.error("Error decoding filename:", e);
      }
      return fileName;
    };

    // SVG Icons as components
    const FileIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "10px", color: "#1890ff" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );

    const FolderIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "8px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
        />
      </svg>
    );

    const ClickIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginLeft: "10px", color: "#52c41a" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    );

    const InfoIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="14"
        height="14"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "5px", color: "#666" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );

    const showDocumentList = () => {
      Modal.info({
        title: (
          <div
            style={{
              borderBottom: "1px solid #f0f0f0",
              padding: "8px 0",
              marginBottom: "16px",
              fontSize: "18px",
              color: "#34444C",
            }}
          >
            Available Documents
          </div>
        ),
        content: (
          <div>
            {documents.map((doc, index) => (
              <div
                key={index}
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  padding: "12px",
                  border: "1px solid #e8e8e8",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  ":hover": {
                    transform: "translateY(-1px)",
                    boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
                  },
                }}
                onClick={() => window.open(doc.document, "_blank")}
              >
                <FileIcon />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#1890ff",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginBottom: "2px",
                    }}
                  >
                    {doc.fileName || getFileName(doc.document)}
                  </span>
                  <span
                    style={{
                      color: "#8c8c8c",
                      fontSize: "12px",
                    }}
                  >
                    Click to open in new tab
                  </span>
                </div>
                <ClickIcon />
              </div>
            ))}
            <div
              style={{
                marginTop: "20px",
                padding: "12px",
                backgroundColor: "#f6ffed",
                borderRadius: "8px",
                fontSize: "13px",
                color: "#52c41a",
                display: "flex",
                alignItems: "center",
                border: "1px solid #b7eb8f",
              }}
            >
              <InfoIcon />
              Click on any document card to open it in a new tab
            </div>
          </div>
        ),
        width: 500,
        className: "document-modal",
        icon: null,
        okText: "Close",
        okButtonProps: {
          style: {
            backgroundColor: "#34444C",
            borderColor: "#34444C",
          },
        },
      });
    };

    const handleDocumentClick = () => {
      if (documents.length === 1) {
        // For single document, open directly
        window.open(documents[0].document, "_blank");
      } else {
        // For multiple documents, show modal
        showDocumentList();
      }
    };

    return (
      <Button
        type="primary"
        onClick={handleDocumentClick}
        style={{
          backgroundColor: "#34444C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px",
          height: "30px",
          borderRadius: "6px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {documents.length === 1 ? <FileIcon /> : <FolderIcon />}
        <span style={{ marginLeft: "4px" }}>
          {documents.length > 1
            ? `View All (${documents.length})`
            : "View Document"}
        </span>
      </Button>
    );
  };

  return (
    <>
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Form>
              <Form.Group className="mb-3">
                <Button
                  style={{
                    backgroundColor: "#FD705C",
                    width: "240px",
                    height: "40px",
                    color: "white",
                  }}
                >
                  Vendor Detail Extraction Task
                </Button>
              </Form.Group>
              {loading ? (
                <div className="d-flex justify-content-center my-5">
                  <Spinner animation="border" variant="primary" />
                </div>
              ) : (
                <>
                  <div className="table-responsive m-t-15">
                    <table className="table table-striped custom-table">
                      <thead>
                        <tr>
                          <th className="text-center">Unique ID</th>
                          <th className="text-center">Date</th>
                          <th className="text-center">Invoice</th>
                          <th className="text-center">Action</th>
                          <th className="text-center">MTO Status</th>
                          <th className="text-center">TC Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {vendorData?.results?.length > 0 ? (
                          vendorData?.results?.map((vendor, index) => (
                            <tr key={index}>
                              {/* unique id  */}
                              <td className="text-center">
                                {vendor.vendor_profile_code || "N/A"}
                              </td>
                              {/* created at date  */}
                              <td className="text-center">
                                {new Date(vendor.created_at).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "2-digit",
                                    year: "numeric",
                                  }
                                )}
                              </td>
                              {/* Invoice  */}
                              <td className="text-center">
                                {(() => {
                                  const documents = documentsData[vendor.id];

                                  if (!documents?.length) {
                                    return <span>No documents</span>;
                                  }

                                  return (
                                    <div
                                      style={{
                                        marginBottom: "10px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                      }}
                                    >
                                      <DocumentButton
                                        documents={documents}
                                        style={{
                                          width: "120px",
                                          backgroundColor: "#202F36",
                                          color: "white",
                                          transition: "all 0.3s ease",
                                        }}
                                      />
                                    </div>
                                  );
                                })()}
                              </td>

                              {/* Assign button */}

                              <td className="text-center">
                                <Button
                                  variant="warning"
                                  size="sm"
                                  className="px-4"
                                  style={{
                                    backgroundColor:
                                      vendor.tc_status === "passed"
                                        ? "#e0e0e0"
                                        : "#FD705C",
                                    color: "white",
                                    border: "none",
                                    transition: "all 0.3s ease",
                                    opacity:
                                      vendor.tc_status === "passed" ? 0.7 : 1,
                                    cursor:
                                      vendor.tc_status === "passed"
                                        ? "not-allowed"
                                        : "pointer",
                                    boxShadow:
                                      vendor.tc_status === "passed"
                                        ? "none"
                                        : "0 2px 4px rgba(0,0,0,0.1)",
                                  }}
                                  onClick={() => {
                                    setSelectedVendorId(vendor.id);
                                    setShowAddModal(true);
                                  }}
                                  disabled={vendor.tc_status === "passed"}
                                >
                                  Assign
                                </Button>
                              </td>

                              {/* view mto status */}
                              <td className="text-center">
                                <Button
                                  variant="dark"
                                  size="sm"
                                  style={{
                                    backgroundColor: "#202F36",
                                    color: "white",
                                  }}
                                  className="px-4"
                                  onClick={() => {
                                    setSelectedVendorId(vendor.id);
                                    setShowViewModal(true);
                                  }}
                                >
                                  View
                                </Button>
                              </td>
                              {/* Tc status */}
                              <td className="text-center">
                                {(() => {
                                  const getTagType = () => {
                                    switch (
                                      vendor.tc_status?.toLowerCase()?.trim()
                                    ) {
                                      case "passed":
                                        return {
                                          color: "success",
                                          text: "Passed",
                                        };
                                      case "failed":
                                        return {
                                          color: "error",
                                          text: "Failed",
                                        };
                                      case "not_available":
                                        return {
                                          color: "default",
                                          text: "Not Assigned",
                                        };
                                      case "pending":
                                        return {
                                          color: "warning",
                                          text: "Pending",
                                        };
                                      default:
                                        return {
                                          color: "default",
                                          text: "Not Available",
                                        };
                                    }
                                  };

                                  const { color, text } = getTagType();

                                  return (
                                    <Tag
                                      color={color}
                                      style={{
                                        minWidth: "40px",
                                        textAlign: "center",
                                        textTransform: "capitalize",
                                      }}
                                    >
                                      {text}
                                    </Tag>
                                  );
                                })()}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="6" className="text-center">
                              No Data Available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* Add pagination */}
                  <div className="d-flex justify-content-end mt-3">
                    <Pagination
                      current={currentPage}
                      total={vendorData?.pagination?.total || 0}
                      pageSize={pageSize}
                      onChange={handlePageChange}
                      showSizeChanger={true}
                      showTotal={(total) => `Total ${total} items`}
                      onShowSizeChange={(current, size) =>
                        handlePageChange(1, size)
                      }
                    />
                  </div>
                </>
              )}
            </Form>

            {/* Assign task  modal   */}

            <ModalAddVendorDetails
              show={showAddModal}
              onHide={() => setShowAddModal(false)}
              vendorId={selectedVendorId}
              refreshlist={(updatedFields) => {
                setVendorData((prevData) => ({
                  ...prevData,
                  results: prevData.results.map((vendor) =>
                    vendor.id === selectedVendorId
                      ? { ...vendor, ...updatedFields }
                      : vendor
                  ),
                }));
              }}
            />

            {/* mto status modal  */}
            <ModalViewVendorDetails
              show={showViewModal}
              onHide={() => setShowViewModal(false)}
              vendorId={selectedVendorId}
            />
          </div>
        </div>
      </TrackProvider>
    </>
  );
}

export default VendorExtractionDetailForm;
