import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Select,
  Upload,
  Checkbox,
  DatePicker,
} from "antd";
import { FaPlus, FaTrash } from "react-icons/fa";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  handover,
  separation,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import moment from "moment";

const { Option } = Select;

// Add validation helper functions
const isValidUrl = (string) => {
  try {
    new URL(string);
    return true;
  } catch (_) {
    return false;
  }
};

const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

const formatDate = (date) => {
  if (!date) return null;
  return moment(date).format("YYYY-MM-DD");
};

const validatePhone = (phone) => {
  return phone.length <= 20;
};

const TrackHandover = ({ resignationId, onClose, onSuccess }) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);

  // Initialize platform state
  const [platforms, setPlatforms] = useState([
    {
      name: "",
      username: "",
      password: "",
    },
  ]);

  // Initialize tracks state
  const [tracks, setTracks] = useState([
    {
      track_name: "",
      track_status: "Not Started",
      comments: "",
      tasks: [
        {
          name: "",
          is_training: false,
          training_link: null,
          not_reason: "",
          course_list: [],
          platform: [
            {
              name: "",
              username: "",
              password: "",
            },
          ],
          document_links: [{ name: "", url: "" }],
        },
      ],
    },
  ]);

  // Add external contacts state
  const [externalContacts, setExternalContacts] = useState([
    {
      name: "",
      email: "",
      phone: "",
      position: "",
      detail: "",
      is_induction: false,
      intro_date: null,
    },
  ]);

  // Add platform functions
  const addPlatform = () => {
    setPlatforms([
      ...platforms,
      {
        name: "",
        username: "",
        password: "",
      },
    ]);
  };

  const removePlatform = (index) => {
    const newPlatforms = [...platforms];
    newPlatforms.splice(index, 1);
    setPlatforms(newPlatforms);
  };

  // Add track functions
  const addTrack = () => {
    setTracks([
      ...tracks,
      {
        track_name: "",
        track_status: "Not Started",
        comments: "",
        tasks: [
          {
            name: "",
            is_training: false,
            training_link: null,
            not_reason: "",
            course_list: [],
            platform: [
              {
                name: "",
                username: "",
                password: "",
              },
            ],
            document_links: [{ name: "", url: "" }],
          },
        ],
      },
    ]);
  };

  const removeTrack = (trackIndex) => {
    const newTracks = [...tracks];
    newTracks.splice(trackIndex, 1);
    setTracks(newTracks);
  };

  // Add task functions
  const addTask = (trackIndex) => {
    const newTracks = [...tracks];
    newTracks[trackIndex].tasks.push({
      name: "",
      is_training: false,
      training_link: null,
      not_reason: "",
      course_list: [],
      platform: [
        {
          name: "",
          username: "",
          password: "",
        },
      ],
      document_links: [{ name: "", url: "" }],
    });
    setTracks(newTracks);
  };

  const removeTask = (trackIndex, taskIndex) => {
    const newTracks = [...tracks];
    newTracks[trackIndex].tasks.splice(taskIndex, 1);
    setTracks(newTracks);
  };

  // Add task platform functions
  const addTaskPlatform = (trackIndex, taskIndex) => {
    const newTracks = [...tracks];
    if (!newTracks[trackIndex].tasks[taskIndex].platform) {
      newTracks[trackIndex].tasks[taskIndex].platform = [];
    }
    newTracks[trackIndex].tasks[taskIndex].platform.push({
      name: "",
      username: "",
      password: "",
    });
    setTracks(newTracks);
  };

  const removeTaskPlatform = (trackIndex, taskIndex, platformIndex) => {
    const newTracks = [...tracks];
    newTracks[trackIndex].tasks[taskIndex].platform.splice(platformIndex, 1);
    setTracks(newTracks);
  };

  const updateTaskPlatform = (
    trackIndex,
    taskIndex,
    platformIndex,
    field,
    value
  ) => {
    const newTracks = [...tracks];
    if (!newTracks[trackIndex].tasks[taskIndex].platform) {
      newTracks[trackIndex].tasks[taskIndex].platform = [
        {
          name: "",
          username: "",
          password: "",
        },
      ];
    }
    newTracks[trackIndex].tasks[taskIndex].platform[platformIndex][field] =
      value;
    setTracks(newTracks);
  };

  // Add document link functions
  const addDocumentLink = (trackIndex, taskIndex) => {
    const newTracks = [...tracks];
    if (!newTracks[trackIndex].tasks[taskIndex].document_links) {
      newTracks[trackIndex].tasks[taskIndex].document_links = [];
    }
    newTracks[trackIndex].tasks[taskIndex].document_links.push({
      name: "",
      url: "",
    });
    setTracks(newTracks);
  };

  // Add external contact functions
  const addExternalContact = () => {
    setExternalContacts([
      ...externalContacts,
      {
        name: "",
        email: "",
        phone: "",
        position: "",
        detail: "",
        is_induction: false,
        intro_date: null,
      },
    ]);
  };

  const removeExternalContact = (index) => {
    const newContacts = [...externalContacts];
    newContacts.splice(index, 1);
    setExternalContacts(newContacts);
  };

  const handleSubmit = async () => {
    try {
      // Check if at least one track exists and has a name
      if (!tracks.length || !tracks.some((track) => track.track_name.trim())) {
        toast.error("At least one track with a name is required");
        return;
      }

      // Check if at least one external contact exists
      const hasExternalContact = tracks.some((track) =>
        track.tasks.some((task) =>
          task.platform.some(
            (platform) =>
              platform.name.trim() &&
              platform.username.trim() &&
              platform.password.trim()
          )
        )
      );

      // if (!hasExternalContact) {
      //   toast.error(
      //     "At least one external contact with platform details is required"
      //   );
      //   return;
      // }

      // Validate emails and URLs in all tracks
      for (const track of tracks) {
        for (const task of track.tasks) {
          // Validate training link if exists
          if (task.training_link && !isValidUrl(task.training_link)) {
            toast.error(`Invalid training link in task: ${task.name}`);
            return;
          }

          // Validate platform emails if exist
          if (task.platform) {
            for (const platform of task.platform) {
              if (platform.username && !isValidEmail(platform.username)) {
                toast.error(`Invalid email in platform: ${platform.name}`);
                return;
              }
            }
          }
        }
      }

      setSubmitting(true);
      const formData = {
        separation: resignationId,
        handover_detail: tracks.map((track, index) => ({
          track_name: track.track_name,
          track_status: track.track_status,
          comments: track.comments,
          // Only include external_contact in the first track (index 0)
          ...(index === 0 ? { external_contact: externalContacts } : {}),
          tasks: track.tasks.map((task) => ({
            name: task.name,
            is_training: task.is_training,
            training_link: task.training_link,
            not_reason: task.not_reason,
            course_list: task.course_list,
            platform: task.platform,
            document_links: task.document_links,
            assigned_at: task.assigned_at,
          })),
        })),
        platform: platforms,
        email: form.getFieldValue("email"),
        phone: form.getFieldValue("phone"),
      };

      // Submit handover form
      await axiosInstance.post(`${handover}`, formData);

      // Update separation record
      await axiosInstance.patch(`${separation}${resignationId}/`, {
        is_handover: true,
      });

      // Reset states
      setPlatforms([
        {
          name: "",
          username: "",
          password: "",
        },
      ]);
      setTracks([
        {
          track_name: "",
          track_status: "Not Started",
          comments: "",
          external_contact: [],
          tasks: [
            {
              name: "",
              is_training: false,
              training_link: null,
              not_reason: "",
              course_list: [],
              platform: [
                {
                  name: "",
                  username: "",
                  password: "",
                },
              ],
              document_links: [{ name: "", url: "" }],
            },
          ],
        },
      ]);
      form.resetFields();

      toast.success("Handover form submitted successfully");
      onSuccess();
    } catch (error) {
      console.error("Error submitting handover form:", error);

      // Handle API error responses
      if (error.response?.data) {
        const errorData = error.response.data;

        // Handle field-level errors including nested errors
        Object.entries(errorData).forEach(([field, errors]) => {
          if (Array.isArray(errors)) {
            errors.forEach((error) => {
              toast.error(`${field}: ${error}`);
            });
          } else if (typeof errors === "object") {
            // Handle external_contact errors
            if (field === "external_contact") {
              errors.forEach((contactErrors, index) => {
                if (contactErrors && Object.keys(contactErrors).length > 0) {
                  Object.entries(contactErrors).forEach(
                    ([errorField, errorMessages]) => {
                      errorMessages.forEach((message) => {
                        toast.error(
                          `Contact ${index + 1} - ${errorField}: ${message}`
                        );
                      });
                    }
                  );
                }
              });
            }
          }
        });
      } else {
        toast.error("Error submitting handover form");
      }
    } finally {
      setSubmitting(false);
    }
  };

  const addButtonStyle = {
    backgroundColor: "#444444",
    borderColor: "#444444",
    color: "white",
  };

  const renderPlatformFields = (platform, index) => (
    <Row key={index} gutter={16} align="middle">
      <Col span={8}>
        <Form.Item label="Platform Name">
          <Input
            value={platform.name}
            onChange={(e) => {
              const newPlatforms = [...platforms];
              newPlatforms[index].name = e.target.value;
              setPlatforms(newPlatforms);
            }}
            placeholder="Tools or platforms used"
          />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item label="Email">
          <Input
            value={platform.username}
            onChange={(e) => {
              const newPlatforms = [...platforms];
              newPlatforms[index].username = e.target.value;
              setPlatforms(newPlatforms);
            }}
            placeholder="Usernames associated with platforms/tools"
          />
        </Form.Item>
      </Col>
      <Col span={7}>
        <Form.Item label="Password">
          <Input
            value={platform.password}
            onChange={(e) => {
              const newPlatforms = [...platforms];
              newPlatforms[index].password = e.target.value;
              setPlatforms(newPlatforms);
            }}
            placeholder="Passwords for platforms/tools"
          />
        </Form.Item>
      </Col>
      <Col
        span={2}
        style={{ display: "flex", alignItems: "flex-end", paddingTop: "6px" }}
      >
        <Button
          danger
          icon={<FaTrash />}
          onClick={() => removePlatform(index)}
          disabled={platforms.length === 1}
        />
      </Col>
    </Row>
  );

  const renderTaskFields = (track, trackIndex, task, taskIndex) => (
    <div key={taskIndex} className="task-container border p-3 my-2">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span>Task {taskIndex + 1}</span>
        <Button
          danger
          icon={<FaTrash />}
          onClick={() => removeTask(trackIndex, taskIndex)}
          disabled={track.tasks.length === 1}
        />
      </div>

      <Form.Item label="Tasks assigned under the track">
        <Input
          value={task.name}
          onChange={(e) => {
            const newTracks = [...tracks];
            newTracks[trackIndex].tasks[taskIndex].name = e.target.value;
            setTracks(newTracks);
          }}
          placeholder="Detailed list of tasks involved in this track"
        />
      </Form.Item>

      <Form.Item>
        Job Training/Knowledge transfer completion
        <br />
        <span style={{ color: "grey" }}>
          (Training on the Position to the employee taking over)
        </span>
        <Checkbox
          checked={task.is_training}
          style={{
            verticalAlign: "middle",
            marginLeft: "15px",
            margin: "15px",
            scale: "1.5",
          }}
          onChange={(e) => {
            const newTracks = [...tracks];
            newTracks[trackIndex].tasks[taskIndex].is_training =
              e.target.checked;
            // Reset related fields when toggling
            if (e.target.checked) {
              newTracks[trackIndex].tasks[taskIndex].not_reason = null;
            } else {
              newTracks[trackIndex].tasks[taskIndex].training_link = null;
              newTracks[trackIndex].tasks[taskIndex].course_list = [];
            }
            setTracks(newTracks);
          }}
        ></Checkbox>
      </Form.Item>

      {task.is_training ? (
        <>
          <Form.Item label="Training Record Link">
            <Input
              value={task.training_link}
              onChange={(e) => {
                const newTracks = [...tracks];
                newTracks[trackIndex].tasks[taskIndex].training_link =
                  e.target.value;
                setTracks(newTracks);
              }}
              placeholder="Enter training link"
            />
          </Form.Item>

          <Form.Item label="Trainings/Courses Required">
            <Select
              mode="tags"
              value={task.course_list || []}
              onChange={(values) => {
                const newTracks = [...tracks];
                newTracks[trackIndex].tasks[taskIndex].course_list = values;
                setTracks(newTracks);
              }}
              placeholder="The person leaving has to list out what courses taking over person
              has to take."
              tokenSeparators={[","]}
              style={{ width: "100%" }}
            >
              {/* If you have predefined courses, you can map them here */}
            </Select>
            <small className="text-muted">
              The person leaving has to list out what courses taking over person
              has to take. Please, Type course name and press Enter to add
              multiple courses
            </small>
          </Form.Item>
        </>
      ) : (
        <Form.Item label="if No,(Specify Why is it not completed?)">
          <Input.TextArea
            value={task.not_reason}
            onChange={(e) => {
              const newTracks = [...tracks];
              newTracks[trackIndex].tasks[taskIndex].not_reason =
                e.target.value;
              setTracks(newTracks);
            }}
            placeholder="Specify why training is not completed"
            rows={4}
          />
        </Form.Item>
      )}

      {/* Task Platform Section */}
      <div className="mb-3">
        <div className="d-flex justify-content-between align-items-center mb-2">
          <span>Task Platforms</span>
          <Button
            type="dashed"
            onClick={() => addTaskPlatform(trackIndex, taskIndex)}
            icon={<FaPlus />}
          >
            Add Platform
          </Button>
        </div>
        {task.platform.map((platform, platformIndex) => (
          <Row key={platformIndex} gutter={16} align="middle">
            <Col span={7}>
              <Form.Item label="Platform Name">
                <Input
                  value={platform.name}
                  onChange={(e) =>
                    updateTaskPlatform(
                      trackIndex,
                      taskIndex,
                      platformIndex,
                      "name",
                      e.target.value
                    )
                  }
                  placeholder="Tools or platforms used"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Email">
                <Input
                  value={platform.username}
                  onChange={(e) =>
                    updateTaskPlatform(
                      trackIndex,
                      taskIndex,
                      platformIndex,
                      "username",
                      e.target.value
                    )
                  }
                  placeholder="Usernames associated with platforms/tools"
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label="Password">
                <Input
                  value={platform.password}
                  onChange={(e) =>
                    updateTaskPlatform(
                      trackIndex,
                      taskIndex,
                      platformIndex,
                      "password",
                      e.target.value
                    )
                  }
                  placeholder="Passwords for platforms/tools"
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button
                danger
                icon={<FaTrash />}
                onClick={() =>
                  removeTaskPlatform(trackIndex, taskIndex, platformIndex)
                }
                disabled={task.platform.length === 1}
              />
            </Col>
          </Row>
        ))}
      </div>

      {/* Document Links */}
      {task.document_links.map((doc, docIndex) => (
        <Row key={docIndex} gutter={16} align="middle">
          <Col span={10}>
            <Form.Item label="Key Documents Name/Links">
              <Input
                value={doc.name}
                onChange={(e) => {
                  const newTracks = [...tracks];
                  newTracks[trackIndex].tasks[taskIndex].document_links[
                    docIndex
                  ].name = e.target.value;
                  setTracks(newTracks);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Form.Item label="URL">
              <Input
                value={doc.url}
                onChange={(e) => {
                  const newTracks = [...tracks];
                  newTracks[trackIndex].tasks[taskIndex].document_links[
                    docIndex
                  ].url = e.target.value;
                  setTracks(newTracks);
                }}
              />
            </Form.Item>
          </Col>
          <Col
            span={4}
            style={{
              display: "flex",
              alignItems: "flex-end",
              // paddingBottom: "24px",
              paddingTop: "6px",
            }}
          >
            <Button
              danger
              icon={<FaTrash />}
              onClick={() => {
                const newTracks = [...tracks];
                newTracks[trackIndex].tasks[taskIndex].document_links.splice(
                  docIndex,
                  1
                );
                setTracks(newTracks);
              }}
              disabled={task.document_links.length === 1}
            />
          </Col>
        </Row>
      ))}

      <Button
        type="dashed"
        onClick={() => addDocumentLink(trackIndex, taskIndex)}
        style={{ marginBottom: 16 }}
        icon={<FaPlus />}
      >
        Add Document Link
      </Button>
    </div>
  );

  return (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      {/* Platform Information */}
      <div className="bg-orange text-white py-2 ps-2 my-2">
        Company Credentials
      </div>
      {platforms.map((platform, index) =>
        renderPlatformFields(platform, index)
      )}
      <Button
        type="primary"
        onClick={addPlatform}
        style={addButtonStyle}
        icon={<FaPlus />}
        block
        className="mb-3"
      >
        Add Platform
      </Button>

      {/* Contact Information */}
      <div className="bg-orange text-white py-2 ps-2 my-2">
        Future Contact Information
      </div>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item name="email" label="Email">
            <Input placeholder="Enter email" />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="phone" label="Phone">
            <Input placeholder="Enter phone number" />
          </Form.Item>
        </Col>
      </Row>
      {/* External Contacts Section */}
      <div className="bg-orange text-white py-2 ps-2 my-2 d-flex justify-content-between align-items-center">
        <span>External Contacts Information</span>
        <Button
          type="dashed"
          onClick={addExternalContact}
          icon={<FaPlus />}
          style={{ marginRight: "10px" }}
        >
          Add Contact
        </Button>
      </div>

      {externalContacts.map((contact, index) => (
        <div key={index} className="border p-3 mb-3 position-relative">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h6>Contact {index + 1}</h6>
            <Button
              danger
              icon={<FaTrash />}
              onClick={() => removeExternalContact(index)}
              disabled={externalContacts.length === 1}
            />
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Name of the Contact"
                rules={[{ required: true, message: "Name is required" }]}
              >
                <Input
                  value={contact.name}
                  onChange={(e) => {
                    const newContacts = [...externalContacts];
                    newContacts[index].name = e.target.value;
                    setExternalContacts(newContacts);
                  }}
                  placeholder="Enter contact name"
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Email Address"
                rules={[
                  { required: true, message: "Email is required" },
                  { type: "email", message: "Invalid email format" },
                ]}
              >
                <Input
                  value={contact.email}
                  onChange={(e) => {
                    const newContacts = [...externalContacts];
                    newContacts[index].email = e.target.value;
                    setExternalContacts(newContacts);
                  }}
                  placeholder="Enter contact email"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Phone"
                rules={[
                  { required: true, message: "Phone is required" },
                  {
                    max: 20,
                    message: "Phone number cannot exceed 20 characters",
                  },
                ]}
              >
                <Input
                  value={contact.phone}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value.length <= 20) {
                      const newContacts = [...externalContacts];
                      newContacts[index].phone = value;
                      setExternalContacts(newContacts);
                    }
                  }}
                  placeholder="Enter contact phone"
                  maxLength={20}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Position"
                rules={[{ required: true, message: "Position is required" }]}
              >
                <Input
                  value={contact.position}
                  onChange={(e) => {
                    const newContacts = [...externalContacts];
                    newContacts[index].position = e.target.value;
                    setExternalContacts(newContacts);
                  }}
                  placeholder="Enter position"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="What does he/she do for us"
                rules={[{ required: true, message: "Detail is required" }]}
              >
                <Input.TextArea
                  value={contact.detail}
                  onChange={(e) => {
                    const newContacts = [...externalContacts];
                    newContacts[index].detail = e.target.value;
                    setExternalContacts(newContacts);
                  }}
                  placeholder="Brief description of the contact's role with us"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Introduction Done with Taking Over Habotech"
                valuePropName="checked"
              >
                <Checkbox
                  checked={contact.is_induction}
                  onChange={(e) => {
                    const newContacts = [...externalContacts];
                    newContacts[index].is_induction = e.target.checked;
                    setExternalContacts(newContacts);
                  }}
                >
                  Is Introduction Required?
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Introduction Date"
                rules={[
                  {
                    required: contact.is_induction,
                    message:
                      "Introduction date is required when induction is checked",
                  },
                ]}
              >
                <DatePicker
                  style={{ width: "100%" }}
                  value={contact.intro_date ? moment(contact.intro_date) : null}
                  onChange={(date) => {
                    const newContacts = [...externalContacts];
                    newContacts[index].intro_date = date
                      ? date.format("YYYY-MM-DD")
                      : null;
                    setExternalContacts(newContacts);
                  }}
                  disabled={!contact.is_induction}
                  format="YYYY-MM-DD"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      ))}
      <div
        className="text-white py-2 ps-2 mb-3"
        style={{ backgroundColor: "#FD715C" }}
      >
        Track Handover Details
      </div>
      {tracks.map((track, trackIndex) => (
        <div key={trackIndex} className="track-container border p-3 my-3">
          <div className="bg-orange text-white py-2 ps-2 my-2 d-flex justify-content-between align-items-center">
            <span>Track {trackIndex + 1}</span>
            <Button
              danger
              icon={<FaTrash />}
              onClick={() => removeTrack(trackIndex)}
              disabled={tracks.length === 1}
              style={{ marginRight: "10px" }}
            />
          </div>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label="Name of the Track">
                <Input
                  value={track.track_name}
                  placeholder="Name of the track habotech is owner of"
                  onChange={(e) => {
                    const newTracks = [...tracks];
                    newTracks[trackIndex].track_name = e.target.value;
                    setTracks(newTracks);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Current track status">
                <Select
                  value={track.track_status}
                  //placeholder="Select current track status"
                  onChange={(value) => {
                    const newTracks = [...tracks];
                    newTracks[trackIndex].track_status = value;
                    setTracks(newTracks);
                  }}
                >
                  <Option value="Not Started">Not Started</Option>
                  <Option value="Ongoing">Ongoing</Option>
                  <Option value="Completed">Completed</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {track.tasks.map((task, taskIndex) =>
            renderTaskFields(track, trackIndex, task, taskIndex)
          )}

          <Button type="primary" onClick={() => addTask(trackIndex)}>
            Add Task
          </Button>

          <Form.Item label="Notes/Comments" className="mt-3">
            <Input.TextArea
              value={track.comments}
              placeholder="Any other relevant information regarding tracks"
              onChange={(e) => {
                const newTracks = [...tracks];
                newTracks[trackIndex].comments = e.target.value;
                setTracks(newTracks);
              }}
            />
          </Form.Item>
        </div>
      ))}

      <Button
        type="dashed"
        onClick={addTrack}
        icon={<FaPlus />}
        block
        className="mb-3"
      >
        Add Track
      </Button>

      <div className="d-flex justify-content-end gap-2 mt-4">
        <Button onClick={onClose}>Cancel</Button>
        <Button
          type="primary"
          onClick={handleSubmit}
          loading={submitting}
          style={{ backgroundColor: "#444444", borderColor: "#444444" }}
        >
          Submit Handover Form
        </Button>
      </div>
    </Form>
  );
};

export default TrackHandover;
