import { useEffect, useState } from "react";
import { Form, Button, Container, Table } from "react-bootstrap";
import "../VendorManagement.css";
import { useParams } from "react-router-dom";
import { mtoExtractionForm } from "../../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import { toast } from "react-toastify";
const MTODataExtractionForm = () => {
  const { token, assignment_id } = useParams();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);
  const [isFormDisabled, setIsFormDisabled] = useState(false);

  useEffect(() => {
    if (!token || !assignment_id) {
      setIsFormDisabled(true);
    }
  }, [token, assignment_id]);

  const FORM_FIELDS = [
    {
      id: "vendor_name",
      label: "Name of the Vendor",
      type: "text",
      instruction:
        "Please mention the name of the vendor as per the Invoice or contract",
      required: true,
      validate: (value) => {
        if (!value?.trim()) return "Vendor name is required";
        return "";
      },
    },
    {
      id: "email",
      label: "Email Address",
      type: "email",
      instruction:
        "Please mention the Email Id given in the Invoice or contract",
      required: true,
      validate: (value) => {
        if (!value?.trim()) return "Email is required";
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value)
          ? ""
          : "Please enter a valid email address";
      },
    },
    {
      id: "contact_phone",
      label: "Contact Phone",
      type: "tel",
      instruction:
        "Please mention the Contact Phone number with Country code as given in the Invoice or contract",
      required: true,
      validate: (value) => {
        if (!value?.trim()) return "Contact phone is required";
        // Add phone validation if needed
        return "";
      },
    },
    {
      id: "place_of_supply",
      label: "Place of Supply",
      type: "select",
      instruction:
        "1. If you selected AED in Currency Code Column, then please select 'Dubai' from the drop-down menu.\n2. If you select any other currency other than AED from Currency Code, leave it blank.",
      options: [
        { value: "", label: "Select" },
        { value: "Dubai", label: "Dubai" },
      ],
      validate: (value, formData) => {
        if (formData.payment_currency === "AED" && value !== "Dubai") {
          return "Dubai must be selected when currency is AED";
        }
        return "";
      },
    },
    {
      id: "tax_treatment",
      label: "Tax Treatment",
      type: "select",
      instruction:
        '1. If "AED" is not selected in currency code column then Please select "Out_of_Scope" from the drop down List\n' +
        '2. If "Yes" then please check whether any "Tax registration number" is given on the Invoice/contract or not if "yes" then please select "vat_registered" from the drop down List and if "No" then please select "vat_not_registered" from the drop down List',
      options: [
        { value: "", label: "Select" },
        { value: "out_of_scope", label: "out_of_scope" },
        { value: "vat_registered", label: "vat_registered" },
        { value: "vat_not_registered", label: "vat_not_registered" },
      ],

      required: true,
      validate: (value, formData) => {
        if (!value) return "Tax treatment is required";
        if (formData.payment_currency !== "AED" && value !== "out_of_scope") {
          return "Must select 'Out of Scope' for non-AED currency";
        }
        return "";
      },
    },
    {
      id: "tax_registration_number",
      label: "Tax Registration No.",
      type: "text",
      instruction:
        "If in tax treatment column 'vat_registered' is selected then please fill the 15 digit Tax registration number as given in the Invoice or contract",
      validate: (value, formData) => {
        if (formData.tax_treatment === "vat_registered") {
          if (!value?.trim())
            return "Tax registration number is required for VAT registered vendors";
          if (value.length !== 15)
            return "Tax registration number must be 15 digits";
          if (!/^\d+$/.test(value))
            return "Tax registration number must contain only numbers";
        }
        return "";
      },
    },
    {
      id: "payment_currency",
      label: "Currency Code",
      type: "select",
      instruction: "Please Select the Code of currency from the drop Menu",
      options: [
        { value: "", label: "Select" },
        { value: "AED", label: "AED" },
        { value: "USD", label: "USD" },
        { value: "EUR", label: "EUR" },
        { value: "INR", label: "INR" },
      ],
      required: true,
      validate: (value) => {
        if (!value) return "Payment currency is required";
        return "";
      },
    },
    {
      id: "mode_of_payment",
      label: "Mode of Payment",
      type: "select",
      instruction:
        "Please select the 'Mode of payment' from the drop down menu",
      options: [
        { value: "", label: "Select" },
        { value: "bank_transfer", label: "Bank Transfer" },
        { value: "credit_card", label: "Credit Card" },
      ],
      required: true,
      validate: (value) => {
        if (!value) return "Payment mode is required";
        return "";
      },
    },
    {
      id: "bank_name",
      label: "Bank Name",
      type: "text",
      instruction:
        "1. If 'Bank transfer' is selected in the 'Mode of Payment' column then please fill the 'bank name' from the bank statement\n\n" +
        "2. Please fill the bank name in text format only",
    },
    {
      id: "bank_account_number",
      label: "Bank Account no.",
      type: "text",
      instruction:
        "1. If 'Bank transfer' is selected in the 'Mode of Payment' column then please fill the 'bank account no' from the bank statement\n\n",
    },
    {
      id: "iban_no",
      label: "IBAN No.",
      type: "text",
      instruction:
        "If 'Bank transfer' is selected in the 'Mode of Payment' column then please fill the 'IBAN no' from the bank statement",
    },
    {
      id: "bank_branch_address",
      label: "Bank Branch Address",
      type: "textarea",
      instruction:
        "1. If 'Bank transfer' is selected in the 'Mode of Payment' column then please fill the 'bank branch address' from the bank statement\n\n" +
        "2. If in 'bank branch address' the address is of India then please keep it as blank",
    },

    {
      id: "swift_code",
      label: "SWIFT Code",
      type: "text",
      instruction:
        "1. If 'Bank transfer' is selected in the 'Mode of Payment' column then please fill the 'SWIFT code' from the bank statement\n\n" +
        "2. Please fill the 8 or 11 character alphanumeric SWIFT code from the bank statement",
    },

    {
      id: "ifsc_code",
      label: "IFSC Code",
      type: "text",
      instruction:
        "1. If 'Bank transfer' is selected in the 'Mode of Payment' column then please fill the IFSC code from the bank statement\n\n" +
        "2. Please fill the 11-digit alphanumeric IFSC code from bank statement\n" +
        "3. If in 'Bank Branch Address' the address is of India then please mention IFSC code otherwise keep it blank",
    },

    {
      id: "expected_credit_days",
      label: "Expected Credit Days Policy",
      type: "select",
      options: [
        { value: "", label: "Select" },
        { value: "within_30_days", label: "Within 30 days" },
        { value: "within_15_days", label: "Within 15 days" },
        { value: "within_7_days", label: "Within 7 days" },
        { value: "within_1_year", label: "Within 1 year" },
        { value: "within_2_years", label: "Within 2 years" },
        { value: "on_demand", label: "On Demand" },
        { value: "other", label: "Other" },
      ],
      instruction:
        "1. From the Agreement/Contract/Invoice, please mention the expected credit days:\n" +
        "   • Within 30 days\n" +
        "   • Within 15 days\n" +
        "   • Within 7 days\n" +
        "   • Within 1 year\n" +
        "   • Within 2 years\n\n" +
        "2. If nothing is mentioned in the invoice regarding credit days, then select 'on demand'\n\n" +
        "3. If any option above is not applicable, then choose 'other' and mention the credit terms as per invoice",
    },
  ];

  const handleInputChange = (fieldId, value) => {
    setFormData((prev) => ({
      ...prev,
      [fieldId]: value,
      ...(fieldId === "expected_credit_days" && value !== "other"
        ? { other_credit_days: "" }
        : {}),
    }));

    setErrors((prev) => ({
      ...prev,
      [fieldId]: "",
      ...(fieldId === "expected_credit_days" && value !== "other"
        ? { other_credit_days: "" }
        : {}),
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    FORM_FIELDS.forEach((field) => {
      const value = formData[field.id];

      if (field.validate) {
        const errorMessage = field.validate(value, formData);
        if (errorMessage) {
          newErrors[field.id] = errorMessage;
        }
      }
    });

    // if (formData.expected_credit_days === "") {
    //   newErrors.expected_credit_days = "Please select credit days policy";
    // }

    // if (
    //   formData.expected_credit_days === "other" &&
    //   !formData.other_credit_days
    // ) {
    //   newErrors.other_credit_days = "Please specify credit terms";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const transformFormData = (data) => {
    return {
      vendor_name: data.vendor_name,
      email: data.email,
      contact_phone: data.contact_phone,
      place_of_supply: data.place_of_supply,
      tax_treatment: data.tax_treatment,
      tax_registration_number: data.tax_registration_number,
      payment_currency: data.payment_currency,
      mode_of_payment: data.mode_of_payment,
      bank_name: data.bank_name,
      bank_account_number: data.bank_account_number,
      iban_no: data.iban_no,
      bank_branch_address: data.bank_branch_address,
      ifsc_code: data.ifsc_code,
      swift_code: data.swift_code,
      expected_credit_days:
        data.expected_credit_days === "other"
          ? data.other_credit_days
          : data.expected_credit_days,
    };
  };

  const handleApiError = (error) => {
    if (error.response?.data?.errors) {
      // Handle validation errors
      const errors = error.response.data.errors;
      const errorMessages = [];

      Object.entries(errors).forEach(([field, message]) => {
        errorMessages.push(`${field}: ${message}`);
      });

      setApiErrors(errorMessages);
    } else if (error.response?.status === 401) {
      const message = "Session expired. Please login again.";
      toast.error(message);
      setApiErrors([message]);
    } else if (error.response?.status === 403) {
      const message = "You don't have permission to perform this action";
      toast.error(message);
      setApiErrors([message]);
    } else {
      const message = error.response?.data?.detail || "Error submitting form";
      setApiErrors([message]);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!token || !assignment_id) {
      setApiErrors([
        "You need proper access to proceed with this form. Please contact your administrator for assistance.",
      ]);
      return;
    }

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const transformedData = transformFormData(formData);
      const url = `${mtoExtractionForm}${token}/${assignment_id}/`;

      const response = await axiosInstance.post(url, transformedData);
      toast.success("Form submitted successfully");
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const renderFormInput = (field) => {
    const isInvalid = errors[field.id];
    const commonProps = {
      value: formData[field.id] || "",
      onChange: (e) => handleInputChange(field.id, e.target.value),
      className: `form-control-sm ${isInvalid ? "is-invalid" : ""}`,
      disabled: isFormDisabled || isLoading,
      placeholder: `Enter ${field.label}`,
    };

    // Check if this is the credit days field and if "other" is selected
    const isOtherSelected =
      field.id === "expected_credit_days" && formData[field.id] === "other";

    return (
      <>
        <div className="form-group">
          {field.type === "select" ? (
            <Form.Select {...commonProps}>
              {field.options.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Form.Select>
          ) : field.type === "textarea" ? (
            <Form.Control as="textarea" rows={3} {...commonProps} />
          ) : (
            <Form.Control type={field.type} {...commonProps} />
          )}
          {isInvalid && (
            <div className="invalid-feedback d-block">{errors[field.id]}</div>
          )}
        </div>

        {/* Render additional input field when "other" is selected */}
        {isOtherSelected && (
          <div className="form-group mt-2">
            <Form.Control
              type="text"
              value={formData.other_credit_days || ""}
              onChange={(e) =>
                handleInputChange("other_credit_days", e.target.value)
              }
              className={`form-control-sm ${
                errors.other_credit_days ? "is-invalid" : ""
              }`}
              disabled={isFormDisabled || isLoading}
              placeholder="Please specify credit terms as per invoice"
            />
            {errors.other_credit_days && (
              <div className="invalid-feedback d-block">
                {errors.other_credit_days}
              </div>
            )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="content container-fluid">
      <div className="account-logo">
        <img
          src="https://storage.googleapis.com/varal-habot-vault-marketplace-10032022/images/updated%20trans-Habot-logo-png.png"
          alt="Habot Logo"
          style={{
            width: "140px",
            height: "auto",
            maxWidth: "100%",
            display: "block",
            margin: "0 auto",
          }}
        />
      </div>

      {isFormDisabled && (
        <>
          <div
            className="position-fixed top-0 start-0 w-100 h-100 bg-dark bg-opacity-75"
            style={{ zIndex: 1040 }}
          ></div>
          <div
            className="card border-primary shadow-sm mx-auto position-fixed top-50 start-50 translate-middle"
            style={{ maxWidth: "800px", zIndex: 1050 }}
          >
            <div className="card-body d-flex align-items-center p-4">
              <div className="rounded-circle bg-primary bg-opacity-10 p-3 me-4">
                <i className="fas fa-info-circle text-primary fs-4"></i>
              </div>
              <div>
                <h4 className="card-title fw-semibold mb-2 text-dark">
                  Please Note
                </h4>
                <p className="card-text text-secondary mb-0">
                  You need proper access to proceed with this form. Please
                  contact your administrator for assistance.
                </p>
              </div>
            </div>
          </div>
        </>
      )}

      <Container className="mt-4 border p-4">
        <h3 className="text-center mb-3">DATA EXTRACTION FORM</h3>
        <p className="text-center mb-4">
          Please refer to the invoice details and bank documents to extract the
          required details.
        </p>

        <Form onSubmit={handleSubmit}>
          <div className="table-responsive">
            <Table bordered responsive className="mto_table">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Details to be extracted</th>
                  <th style={{ width: "40%" }}>Instructions</th>
                  <th style={{ width: "40%" }}>Extracted Details</th>
                </tr>
              </thead>
              <tbody>
                {FORM_FIELDS.map((field) => (
                  <tr key={field.id}>
                    <td className="align-top py-3">
                      <b>{field.label}</b>
                      {field.required && (
                        <span
                          style={{
                            color: "#dc3545",
                            marginLeft: "3px",
                            fontWeight: "bold",
                          }}
                        >
                          *
                        </span>
                      )}
                    </td>
                    <td>
                      {field.instruction
                        ? field.instruction.split("\n").map((line, i) => (
                            <p key={i} className="mb-1">
                              {line}
                            </p>
                          ))
                        : null}
                    </td>
                    <td className="align-top py-3">{renderFormInput(field)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {apiErrors.length > 0 && (
            <div className="api-errors mt-2">
              <ul className="error-list">
                {apiErrors.map((error, index) => (
                  <li key={index} className="text-danger">
                    <i className="fas fa-exclamation-triangle me-2"></i>
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="text-end mt-3">
            <Button
              variant="primary"
              type="submit"
              disabled={isFormDisabled || isLoading}
              className="px-4"
            >
              {isLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" />
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default MTODataExtractionForm;
