import React, { useState, useEffect } from "react";
import { CiSearch } from "react-icons/ci";
import { HiAdjustmentsHorizontal } from "react-icons/hi2";
import {
  Table,
  Button,
  Drawer,
  Form,
  Input,
  Select,
  Radio,
  Modal,
  Spin,
} from "antd";
import JRFComponent from "./JRFComponent";
import CAFComponent from "./CAFComponent";
import HPFComponent from "./HPFComponent";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { jobRequest } from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import dayjs from "dayjs";

const { Option } = Select;

const ViewJobRequest = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState("");
  const [currentId, setCurrentId] = useState(null);
  const [currentPosition, setCurrentPosition] = useState("");
  const [currentDesignation, setCurrentDesignation] = useState("");
  const [jobRequestDatas, setJobRequestDatas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [filterDate, setFilterDate] = useState(null);
  const [filterStatus, setFilterStatus] = useState("");
  const [departmentFilter, setDepartmentFilter] = useState(null);

  const [isLargeDevice, setIsLargeDevice] = useState(window.innerWidth >= 1024);
  const handleDrawerOpen = () => setIsDrawerOpen(true);
  const handleDrawerClose = () => setIsDrawerOpen(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeDevice(window.innerWidth >= 1024);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchSpecficData = async (status, date) => {
    const params = new URLSearchParams({
      page: pagination.current,
      page_size: pagination.pageSize,
    });

    if (status) params.append("status", status);

    //we need a filter in backend that will have date of creation as simple YYYY-MM-DD format
    if (date) params.append("created_at", date);
    try {
      const response = await axiosInstance.get(
        `${jobRequest}?${params.toString()}`
      );
      console.log("Fetched job request data:", response.data); // Log the fetched data

      // Ensure response.data.results is an array
      if (Array.isArray(response.data.results)) {
        setJobRequestDatas(response.data.results); // Set the state to the results array
        setPagination((prev) => ({
          ...prev,
          total: response.data.count,
        }));
      } else {
        console.error("Expected an array but got:", response.data.results);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        await fetchSpecficData(""); // Fetch data without filters
      } catch (error) {
        console.log("Error fetching data:", error);
      } finally {
        setLoading(false); // End loading
      }
    };
    fetchData();
  }, [pagination.current, pagination.pageSize, departmentFilter]);

  const handleModalOpen = (type, id, position, designation) => {
    setCurrentModal(type);
    setIsModalOpen(true);
    setCurrentId(id);
    setCurrentPosition(position);
    setCurrentDesignation(designation);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setCurrentModal("");
  };

  const renderModalContent = () => {
    switch (currentModal) {
      case "JRF":
        return <JRFComponent jobRequestId={currentId} />;
      case "HPF":
        return <HPFComponent jobRequestId={currentId} />;
      case "CAF":
        return (
          <CAFComponent
            jobRequestId={currentId}
            position={currentPosition}
            designation={currentDesignation}
          />
        );
      default:
        return <p>No data available</p>;
    }
  };

  /// need to add more option for HR View ⚠️⚠️ /////

  const columns = [
    { title: "Position Title", dataIndex: "designation", key: "designation" },
    {
      title: "Date Submitted",
      dataIndex: "created_at",
      key: "created_at",
      render: (text) => {
        const date = new Date(text); // Convert the string to a Date object
        return date.toLocaleDateString(); // Format the date to a readable format
      },
    },
    {
      title: "View JRF",
      key: "viewJrf",
      render: (text, record) => (
        <Button
          style={{
            backgroundColor: "#344955",
            color: "#fff",
            border: "none",
          }}
          onClick={() =>
            handleModalOpen(
              "JRF",
              record.id,
              record.designation,
              record.position_title
            )
          }
        >
          View
        </Button>
      ),
    },
    {
      title: "View HPF",
      key: "viewHpf",
      render: (text, record) => (
        <Button
          style={{
            backgroundColor: "#344955",
            color: "#fff",
            border: "none",
          }}
          onClick={() =>
            handleModalOpen(
              "HPF",
              record.id,
              record.designation,
              record.position_title
            )
          }
        >
          View
        </Button>
      ),
    },
    {
      title: "View CAF",
      key: "viewCaf",
      render: (text, record) => {
        // Check if interview_type is "Project round"
        if (record.interview_type === "Project round") {
          return <p style={{ textAlign: "start" }}>NA</p>; // Do not render the button
        }
        return (
          <Button
            style={{
              backgroundColor: "#344955",
              color: "#fff",
              border: "none",
            }}
            onClick={() =>
              handleModalOpen(
                "CAF",
                record.id,
                record.designation,
                record.position_title
              )
            }
          >
            View
          </Button>
        );
      },
    },
  ];

  const filterApplied = async (status, date) => {
    try {
      setLoading(true);
      await fetchSpecficData(status, date);
    } catch (error) {
      console.log("filter failed to applied", error);
    } finally {
      setLoading(false);
    }
    console.log("this is my status value", status, date);
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div style={{ padding: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: 16,
              }}
            >
              <div>
                <Input
                  placeholder="Search"
                  style={{
                    width: "300px",
                    marginLeft: "10px",
                    marginRight: "10px",
                    marginBottom: "30px",
                  }}
                />
                <Button
                  style={{
                    backgroundColor: "#344955",
                    color: "#fff",
                    border: "none",
                  }}
                >
                  <CiSearch style={{ scale: 1.5 }} />
                </Button>
              </div>

              <Button
                style={{
                  backgroundColor: "#f7f7f7",
                  border: "1px solid rgb(6, 6, 6)",
                  display: "flex",
                  alignItems: "center",
                }}
                className="flex gap-2 font-bold"
                onClick={handleDrawerOpen}
              >
                <HiAdjustmentsHorizontal /> Filter
              </Button>
            </div>

            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                  minHeight: "400px",
                }}
              >
                <Spin size="large" />
              </div>
            ) : (
              <Table
                columns={columns}
                dataSource={jobRequestDatas}
                rowKey="id"
                pagination={{
                  current: pagination.current,
                  pageSize: pagination.pageSize,
                  total: pagination.total,
                  onChange: (page, pageSize) => {
                    setPagination({ ...pagination, current: page, pageSize });
                  },
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                }}
                style={{
                  borderRadius: 8,
                }}
              />
            )}

            {/* Drawer for Filter */}
            <Drawer
              title={
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    fontWeight: "bold",
                    color: "#722ed1",
                  }}
                >
                  Filter
                </div>
              }
              placement="right"
              onClose={handleDrawerClose}
              open={isDrawerOpen}
              width={300}
            >
              <Form layout="vertical">
                <Form.Item label="Status">
                  <Radio.Group
                    onChange={(e) => setFilterStatus(e.target.value)}
                  >
                    <Radio value="Progress">In Progress</Radio>
                    <Radio value="Complete">Completed</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item label="Position">
                  <Select placeholder="Select">
                    <Option value="developer">Developer</Option>
                    <Option value="designer">Designer</Option>
                  </Select>
                </Form.Item>
                <Form.Item label="Date">
                  <Input
                    type="date"
                    onChange={(e) =>
                      setFilterDate(dayjs(e.target.value).format("YYYY-MM-DD"))
                    }
                  />
                </Form.Item>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button>Reset</Button>
                  <Button
                    type="primary"
                    style={{
                      backgroundColor: "#344955",
                      border: "none",
                    }}
                    onClick={() => filterApplied(filterStatus, filterDate)}
                  >
                    Search
                  </Button>
                </div>
              </Form>
            </Drawer>

            {/* Modal */}
            <Modal
              open={isModalOpen}
              onCancel={handleModalClose}
              width={isLargeDevice ? 1100 : "100%"} // Full-width for smaller devices
              footer={null}
              style={
                isLargeDevice ? { marginLeft: "350px" } : { margin: "0 auto" }
              }
              // bodyStyle={{
              //   overflowY: "auto", // Enable vertical scrolling inside modal
              //   maxHeight: "80vh", // Adjust the height for scrolling (80% of the viewport height)
              // }}
            >
              {renderModalContent()}
            </Modal>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewJobRequest;
