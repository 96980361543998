import React, { useState } from "react";
import { Modal, Select, Button, Row, Col, DatePicker, Radio } from "antd";
import moment from "moment";

const VendorReqFilterModal = ({
  isVisible,
  onClose,
  onApplyFilters,
  currentFilters,
}) => {
  const [filters, setFilters] = useState({
    ...currentFilters,
    dateType: "single",
    created_at: currentFilters?.created_at
      ? moment(currentFilters.created_at)
      : null,
    created_at_from: currentFilters?.created_at_from
      ? moment(currentFilters.created_at_from)
      : null,
    created_at_to: currentFilters?.created_at_to
      ? moment(currentFilters.created_at_to)
      : null,
  });

  const STATUS_OPTIONS = [
    { value: "APPROVED", label: "Approved" },
    { value: "REJECTED", label: "Rejected" },
  ];

  const departments = [
    { id: 1, name: "Human Resources" },
    { id: 2, name: "GACL" },
    { id: 3, name: "Technical" },
    { id: 4, name: "Operations" },
    { id: 5, name: "Marketing" },
    { id: 6, name: "Head Office" },
  ];

  const ACCOUNT_TYPES = [
    { value: "administrative_expenses", label: "Administrative Expenses" },
    { value: "auditing_expenses", label: "Auditing Expenses" },
    {
      value: "employees_medical_insurance",
      label: "Employees Medical Insurance",
    },
    {
      value: "employees_visas_permits_ids",
      label: "Employees Visa's, Permits & ID's",
    },
    {
      value: "expenses_recover_singhania_clinic",
      label: "Expenses to Recover From Singhania Clinic",
    },
    { value: "license_and_permits", label: "License and Permits" },
    {
      value: "marketing_advertising_expenses",
      label: "Marketing & Advertising Expenses",
    },
    {
      value: "offshore",
      label: "Offshore",
    },
    {
      value: "freezone",
      label: "Freezone",
    },
    {
      value: "office_rent",
      label: "Office Rent",
    },

    {
      value: "online_platform_website_expenses",
      label: "Online Platform/Website Expenses",
    },
    { value: "other_expenses", label: "Other Expenses" },
    { value: "postage", label: "Postage" },
    {
      value: "software_infrastructure_expenses",
      label: "Software Infrastructure Expenses",
    },
    { value: "telephone_expenses", label: "Telephone Expenses" },
    { value: "training_costs", label: "Training Costs" },
    { value: "vendor_outsourcee_expense", label: "Vendor Outsourcee Expense" },
  ];
  const handleReset = () => {
    const resetFilters = {
      rs_status: undefined,
      department_id: undefined,
      account_type: undefined,
      dateType: "single",
      created_at: null,
      created_at_from: null,
      created_at_to: null,
    };
    setFilters(resetFilters);
    onApplyFilters(resetFilters);
    onClose();
  };

  const handleSearch = () => {
    const filtersToApply = {
        ...filters,
        created_at:
            filters.dateType === "single" && filters.created_at
                ? filters.created_at.format("YYYY-MM-DD")
                : null,
        created_at_from:  
            filters.dateType === "range" && filters.created_at_from
                ? filters.created_at_from.format("YYYY-MM-DD")
                : null,
        created_at_to:    
            filters.dateType === "range" && filters.created_at_to
                ? filters.created_at_to.format("YYYY-MM-DD")
                : null,
    };
    onApplyFilters(filtersToApply);
    onClose();
};

  return (
    <Modal
      title="Filter Vendors"
      open={isVisible}
      onCancel={onClose}
      footer={null}
      width={600}
    >
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <label>Status</label>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Status"
            value={filters.rs_status}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, rs_status: value }))
            }
            allowClear
            options={STATUS_OPTIONS}
          />
        </Col>

        <Col span={24}>
          <label>Department</label>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Department"
            value={filters.department_id}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, department_id: value }))
            }
            allowClear
            options={departments.map((dept) => ({
              value: dept.id,
              label: dept.name,
            }))}
          />
        </Col>

        <Col span={24}>
          <label>Account Type</label>
          <Select
            style={{ width: "100%" }}
            placeholder="Select Account Type"
            value={filters.account_type}
            onChange={(value) =>
              setFilters((prev) => ({ ...prev, account_type: value }))
            }
            allowClear
            options={ACCOUNT_TYPES}
          />
        </Col>

        <Col span={24} className="d-flex flex-column">
          <label>Date Filter Type</label>
          <Radio.Group
            value={filters.dateType}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                dateType: e.target.value,
                // Reset dates when switching between single and range
                created_at: null,
                updated_at_from: null,
                updated_at_to: null,
              }))
            }
          >
            <Radio value="single">Single Date</Radio>
            <Radio value="range">Date Range</Radio>
          </Radio.Group>
        </Col>

        {filters.dateType === "single" ? (
          <Col span={24}>
            <label>Date</label>
            <DatePicker
              style={{ width: "100%" }}
              placeholder="Select Date"
              value={filters.created_at}
              onChange={(date) => {
                setFilters((prev) => ({
                  ...prev,
                  created_at: date,
                }));
              }}
              format="DD-MM-YY"
              allowClear
              disabledDate={(current) => {
                return current && current > moment().endOf("day");
              }}
            />
          </Col>
        ) : (
          <>
            <Col span={12}>
              <label>From</label>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="From Date"
                value={filters.created_at_from}
                onChange={(date) => {
                  setFilters((prev) => ({
                    ...prev,
                    created_at_from: date,
                    created_at_to:
                      prev.created_at_to &&
                      date &&
                      date.isAfter(prev.created_at_to)
                        ? date
                        : prev.created_at_to,
                  }));
                }}
                format="DD-MM-YY"
                allowClear
                disabledDate={(current) => {
                  return current && current > moment().endOf("day");
                }}
              />
            </Col>

            <Col span={12}>
              <label>To</label>
              <DatePicker
                style={{ width: "100%" }}
                placeholder="To Date"
                value={filters.created_at_to}
                onChange={(date) => {
                  setFilters((prev) => ({
                    ...prev,
                    created_at_to: date,
                  }));
                }}
                format="DD-MM-YY"
                allowClear
                disabledDate={(current) => {
                  return (
                    current &&
                    (current > moment().endOf("day") ||
                      (filters.created_at_from &&
                        current.isBefore(filters.created_at_from, "day")))
                  );
                }}
              />
            </Col>
          </>
        )}

        <Col span={24} style={{ textAlign: "right", marginTop: 16 }}>
          <Button onClick={handleReset} style={{ marginRight: 8 }}>
            Reset
          </Button>
          <Button type="primary" onClick={handleSearch}>
            Search
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default VendorReqFilterModal;
