import { useState, useEffect } from "react";
import { Card, Table, Button, message, Modal } from "antd";
import {
  vendorRequest,
  vendordocuments,
} from "../../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import ExpenseDetailViewModal from "../ExpenseDetailViewModal/ExpenseDetailViewModal";
import VendorReqFilterModal from "../VendorReqFilterModal/VendorReqFilterModal";
import GaclSideBar from "../../../../layout/GaclSidebar";
import { useNavigate } from "react-router-dom";
import "../VendorManagement.css"

const ExpenseBankInvoice = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [vendorDocuments, setVendorDocuments] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [currentFilters, setCurrentFilters] = useState({
    rs_status: undefined,
    department_id: undefined,
    account_type: undefined,
  });

  const fetchVendorDocuments = async (vendorId) => {
    try {
      const response = await axiosInstance.get(
        `${vendordocuments}?vendor_request_id=${vendorId}`
      );
      return Array.isArray(response.data)
        ? response.data
        : Array.isArray(response.data.results)
        ? response.data.results
        : [];
    } catch (error) {
      console.error(`Error fetching documents for vendor ${vendorId}:`, error);
      return [];
    }
  };

  const handleApplyFilters = (filters) => {
    setCurrentFilters(filters);

    const queryParams = new URLSearchParams();
    queryParams.append("page", "1");
    queryParams.append("page_size", pagination.pageSize);
    queryParams.append("gacl_status", "APPROVED");

    if (filters.rs_status) {
      queryParams.append("rs_status", filters.rs_status);
    }
    if (filters.department_id) {
      queryParams.append("department_id", filters.department_id);
    }
    if (filters.account_type) {
      queryParams.append("account_type", filters.account_type);
    }

    if (filters.dateType === "single") {
      if (filters.created_at) {
        queryParams.append("created_at", filters.created_at);
      }
    } else {
      if (filters.created_at_from) {
        queryParams.append("created_at_from", filters.created_at_from);
      }
      if (filters.created_at_to) {
        queryParams.append("created_at_to", filters.created_at_to);
      }
    }

    fetchVendorDataWithFilters(queryParams);
  };

  const fetchVendorDataWithFilters = async (queryParams) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${vendorRequest}?${queryParams.toString()}`
      );

      setPagination({
        current: parseInt(queryParams.get("page")) || 1,
        pageSize: parseInt(queryParams.get("page_size")) || 10,
        total: response.data.count,
      });

      const vendors = response.data.results;

      // Fetch documents for each vendor
      const documentsPromises = vendors.map(async (vendor) => {
        const documents = await fetchVendorDocuments(vendor.id);
        return { vendorId: vendor.id, documents };
      });

      const documentsResults = await Promise.all(documentsPromises);

      const documentsLookup = documentsResults.reduce((acc, curr) => {
        acc[curr.vendorId] = curr.documents;
        return acc;
      }, {});

      setVendorDocuments(documentsLookup);
      setVendorData(vendors);
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      message.error("Failed to fetch vendor data");
      setVendorData([]);
      setVendorDocuments({});
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
    console.log(record);
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    const queryParams = new URLSearchParams();

    queryParams.append("page", newPagination.current);
    queryParams.append("page_size", newPagination.pageSize);
    queryParams.append("gacl_status", "APPROVED");

    if (currentFilters.rs_status) {
      queryParams.append("rs_status", currentFilters.rs_status);
    }
    if (currentFilters.department_id) {
      queryParams.append("department_id", currentFilters.department_id);
    }
    if (currentFilters.account_type) {
      queryParams.append("account_type", currentFilters.account_type);
    }

    fetchVendorDataWithFilters(queryParams);
  };
  useEffect(() => {
    const queryParams = new URLSearchParams();
    queryParams.append("page", pagination.current);
    queryParams.append("page_size", pagination.pageSize);
    queryParams.append("gacl_status", "APPROVED");

    fetchVendorDataWithFilters(queryParams);
  }, []);

  const handleMultipleDocumentOpen = (documents) => {
    if (!documents || !Array.isArray(documents)) return;

    documents.forEach((doc) => {
      if (doc.document) {
        window.open(doc.document, "_blank");
      }
    });
  };

  const handleSingleDocumentOpen = (doc) => {
    if (doc.document) {
      window.open(doc.document, "_blank");
    }
  };

  const columns = [
    {
      title: "Entry Number",
      dataIndex: "team_lead_info",
      key: "team_lead_info",
      render: (info) => <span>{info?.name || "-"}</span>,
    },
    {
      title: "Expense Date",
      dataIndex: "department_info",
      key: "department_info",
      render: (info) => <span>{info?.name || "-"}</span>,
    },
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (vendor_name) => <span>{vendor_name}</span>,
    },
    {
      title: "Vendor Account Type",
      dataIndex: "account_type",
      key: "account_type",
      render: (type) => (
        <span style={{ textTransform: "capitalize" }}>
          {type?.replace(/_/g, " ") || "-"}
        </span>
      ),
    },

    {
      title: "View Details",
      key: "View",
      render: (_, record) => (
        <Button
          type="primary"
          style={{
            backgroundColor: "#202F36",
            color: "white",
          }}
          onClick={() => handleViewDetails(record)}
        >
          View
        </Button>
      ),
    },

    {
      title: "Document",
      dataIndex: "id",
      key: "documents",
      render: (vendorId) => {
        const documents = vendorDocuments[vendorId];

        if (!documents || !Array.isArray(documents)) {
          return <span>No documents</span>;
        }

        return (
          <div>
            {documents.length > 0 ? (
              <div style={{ marginBottom: "10px" }}>
                {documents.length > 1 ? (
                  <Button
                    type="primary"
                    onClick={() => handleMultipleDocumentOpen(documents)}
                    style={{ backgroundColor: "#34444C" }}
                  >
                    Download All ({documents.length})
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => handleSingleDocumentOpen(documents[0])}
                    style={{ backgroundColor: "#34444C" }}
                  >
                    Download
                  </Button>
                )}
              </div>
            ) : (
              <div>No documents</div>
            )}
          </div>
        );
      },
    },
    // {
    //   title: "Initiate",
    //   key: "actions",
    //   render: (_, record) => {
    //     const handleClick = () => {
    //       navigate(`/expense-documents-upload/${record.id}`);
    //     };

    //     const buttonStyles = {
    //       initiated: {
    //         backgroundColor: "#808080",
    //         color: "white",
    //       },
    //       initiate: {
    //         backgroundColor: "#FF902E",
    //         color: "white",
    //       },
    //     };

    //     if (record.rs_status === "APPROVED") {
    //       if (record.has_been_initiated) {
    //         return (
    //           <Button
    //             type="primary"
    //             style={buttonStyles.initiated}
    //             disabled={true}
    //           >
    //             Profile Initiated
    //           </Button>
    //         );
    //       } else {
    //         return (
    //           <Button
    //             type="primary"
    //             style={buttonStyles.initiate}
    //             // onClick={handleClick}
    //           >
    //             Initiate Profile Setup
    //           </Button>
    //         );
    //       }
    //     } else {
    //       return "N/A";
    //     }
    //   },
    // },
  ];

  return (
    <>
      <GaclSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid ">
          <div className="d-flex justify-content-between align-items-center mb-3">
          <Button className="btn-expense">Details Extraction</Button>

          <div className="d-flex gap-2">
          <Button className="btn-expense">Generate CSV</Button>

          <Button
            onClick={() => setIsFilterModalVisible(true)}
            style={{ marginBottom: 16 }}
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="15"
                viewBox="0 0 24 24"
              >
                <g fill="none" fill-rule="evenodd">
                  <path d="m12.594 23.258l-.012.002l-.071.035l-.02.004l-.014-.004l-.071-.036q-.016-.004-.024.006l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.016-.018m.264-.113l-.014.002l-.184.093l-.01.01l-.003.011l.018.43l.005.012l.008.008l.201.092q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.003-.011l.018-.43l-.003-.012l-.01-.01z" />
                  <path
                    fill="currentColor"
                    d="M16 15c1.306 0 2.418.835 2.83 2H20a1 1 0 1 1 0 2h-1.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 1 1 0-2h9.17A3 3 0 0 1 16 15m0 2a1 1 0 1 0 0 2a1 1 0 0 0 0-2M8 9a3 3 0 0 1 2.762 1.828l.067.172H20a1 1 0 0 1 .117 1.993L20 13h-9.17a3.001 3.001 0 0 1-5.592.172L5.17 13H4a1 1 0 0 1-.117-1.993L4 11h1.17A3 3 0 0 1 8 9m0 2a1 1 0 1 0 0 2a1 1 0 0 0 0-2m8-8c1.306 0 2.418.835 2.83 2H20a1 1 0 1 1 0 2h-1.17a3.001 3.001 0 0 1-5.66 0H4a1 1 0 0 1 0-2h9.17A3 3 0 0 1 16 3m0 2a1 1 0 1 0 0 2a1 1 0 0 0 0-2"
                  />
                </g>
              </svg>
            }
          >
            Filter
          </Button>
</div></div>
          <Card>
            <Table
              loading={loading}
              dataSource={vendorData}
              columns={columns}
              rowKey="id"
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                pageSizeOptions: ["10", "20", "30", "50"],
              }}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
              style={{
                overflowX: "auto",
                width: "100%",
              }}
            />

            {/* Modal to view the comments */}
            <Modal
              title="Comments"
              open={isCommentModalVisible}
              onCancel={() => setIsCommentModalVisible(false)}
              footer={[
                <Button
                  key="close"
                  onClick={() => setIsCommentModalVisible(false)}
                >
                  Close
                </Button>,
              ]}
            >
              <p>{selectedComment}</p>
            </Modal>
          </Card>

          <ExpenseDetailViewModal
            visible={isModalVisible}
            record={selectedRecord}
            onClose={() => {
              setIsModalVisible(false);
              setSelectedRecord(null);
            }}
            type={"gacl"}
          />
          <VendorReqFilterModal
            isVisible={isFilterModalVisible}
            onClose={() => setIsFilterModalVisible(false)}
            onApplyFilters={handleApplyFilters}
            currentFilters={currentFilters}
          />
        </div>
      </div>
    </>
  );
};

export default ExpenseBankInvoice;
