import React from "react";
import { Form, Input, Select, Button, Row, Col, Typography } from "antd";

const { Option } = Select;
const { Title } = Typography;

const CAFModal = () => {
  return (
    <Form
      layout="vertical"
      style={{ maxWidth: 900, margin: "auto", padding: 20 }}
    >
      <Button
        type="primary"
        style={{ backgroundColor: "#1E2D3D", marginBottom: 20 }}
      >
        Candidate Assessment Form
      </Button>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Title">
            <Input placeholder="UI/UX Designer" disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Name of the Candidate">
            <Input placeholder="Anushka" disabled />
          </Form.Item>
        </Col>
      </Row>

      <Button type="primary" danger style={{ marginBottom: 10 }}>
        Section 1
      </Button>
      {[...Array(6)].map((_, index) => (
        <Row gutter={16} key={index} style={{ margin: "10px 0px" }}>
          <Col span={18}>
            <Input placeholder="Question from the form (CAF)" disabled />
          </Col>
          <Col span={6}>
            <Input placeholder="Score" />
          </Col>
        </Row>
      ))}

      <Button type="primary" danger style={{ marginTop: 20, marginBottom: 10 }}>
        Section 2: HabotConnect Fit
      </Button>
      {[
        "Name 2 leadership principles and what they mean to you",
        "Name 2 HabotConnect values you admire and how do you abide by them in your life",
        "One reason why you want this job",
        "One thing you will contribute to team performance",
      ].map((question, index) => (
        <Row gutter={16} key={index} style={{ margin: "10px 0px" }}>
          <Col span={18}>
            <Input placeholder={question} disabled />
          </Col>
          <Col span={6}>
            <Input placeholder="Score" />
          </Col>
        </Row>
      ))}

      <Form.Item label="Total Score" style={{ marginTop: 20 }}>
        <Input style={{ width: 150 }} />
      </Form.Item>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item label="Salary offer accepted?">
            <Select placeholder="Select">
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="SHORTLISTED?">
            <Select placeholder="Select">
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item style={{ textAlign: "right" }}>
        <Button type="primary" danger>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default CAFModal;
