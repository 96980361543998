import React, { useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { pdcaEd } from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

const DocumentViewModal = ({ taskId, show, handleClose }) => {
  const [remarks, setRemarks] = useState("");
  const [error, setError] = useState(null);

  const handleApprove = (data) => {
    axiosInstance
      .patch(`${pdcaEd}${data?.pdca_ed[0]?.id}/`, {
        pdca: data?.id,
        approve_status: "Approved",
        remarks: remarks,
      })
      .then((response) => {
        console.log("Approval successful:", response.data);
        if (data?.pdca_ed?.[0]) {
          data.pdca_ed[0].approve_status = "Approved";
        }
        setError(null);
        setRemarks("");
        toast.success("Document Approved");
        handleClose();
      })
      .catch((err) => {
        console.error("Error approving document:", err);
        setError("Failed to approve the document. Please try again.");
      });
  };

  const handleReject = (data) => {
    if (!remarks) {
      setError("Remarks cannot be empty");
      return;
    }

    axiosInstance
      .patch(`${pdcaEd}${data?.pdca_ed[0]?.id}/`, {
        pdca: data?.id,
        approve_status: "Rejected",
        remarks: remarks,
      })
      .then((response) => {
        console.log("Rejection successful:", response.data);
        if (data?.pdca_ed?.[0]) {
          data.pdca_ed[0].approve_status = "Rejected";
        }
        setError(null);
        setRemarks("");
        toast.error("Document Rejected");
        handleClose();
      })
      .catch((err) => {
        console.error("Error rejecting document:", err);
        setError("Failed to reject the document. Please try again.");
      });
  };

  const documentData = taskId?.pdca_ed?.[0];

  const isImage = (url) => {
    const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp"];
    const fileExtension = url?.split(".").pop().toLowerCase();
    return imageExtensions.includes(fileExtension);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton className="modal-style">
        <Modal.Title>Document Viewer</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {documentData ? (
          <div style={{ marginBottom: "10px" }}>
            {isImage(documentData.document) ? (
              <img
                src={documentData.document}
                alt="Task Document"
                style={{
                  width: "100%",
                  height: "auto",
                  cursor: "pointer",
                }}
              />
            ) : (
              <a
                href={documentData.document}
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "#007bff" }}
              >
                Open Document
              </a>
            )}
            <p style={{ marginTop: "5px" }}>
              {documentData.approve_status === "Approved" && (
                <span style={{ color: "green" }}>Approved</span>
              )}
              {documentData.approve_status === "Rejected" && (
                <span style={{ color: "red" }}>Rejected</span>
              )}
              {documentData.approve_status === null && (
                <span style={{ color: "orange" }}>Pending</span>
              )}
            </p>
          </div>
        ) : (
          <p>No documents available</p>
        )}
      </Modal.Body>

      <Modal.Footer>
        <div className="d-flex flex-column w-100">
          <Form.Control
            placeholder="Enter remarks"
            type="text"
            value={remarks}
            onChange={(e) => setRemarks(e.target.value)}
            isInvalid={!!error}
          />
          <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
          {taskId?.pdca_ed?.length > 0 ? (
            <div className="d-flex justify-content-center mt-2">
              {taskId?.pdca_ed[0]?.approve_status === "Approved" ? (
                <>
                  <Button className="mx-2" variant="success" disabled>
                    Approved
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={() => handleApprove(taskId)}
                    className="mx-2"
                    variant="primary"
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={() => handleReject(taskId)}
                    variant="secondary"
                  >
                    Reject
                  </Button>
                </>
              )}
            </div>
          ) : (
            "Document not submitted yet"
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default DocumentViewModal;
