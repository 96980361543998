import React, { useEffect, useState, useCallback } from "react";
import Select from "react-select";
import { Table, Spin, Pagination } from "antd";
import { Button, Modal, Tabs, Tab } from "react-bootstrap";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  enrolledCourses,
  patchEnrolledCourses,
  deleteEnrolledCertificate,
  hrCourses,
} from "../../../../../helpers/endpoints/api_endpoints";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import Header from "../../../../layout/Header";

const TrainingVerificationList = () => {
  const [enrolledCoursesList, setEnrolledCoursesList] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showCertificateModal, setShowCertificateModal] = useState(false);
  const [certificateSrc, setCertificateSrc] = useState(null);
  const [activeKey, setActiveKey] = useState("nonVerified");
  const [courseOptions, setCourseOptions] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoadingCourses, setIsLoadingCourses] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const PAGE_SIZE = 15; // Add this constant for the fixed page size
  const [nonVerifiedPage, setNonVerifiedPage] = useState(1);
  const [verifiedPage, setVerifiedPage] = useState(1);

  // Fetch paginated courses from the backend
  const fetchCoursesForDropdown = useCallback(() => {
    setIsLoadingCourses(true);
    axiosInstance
      .get(`${hrCourses}?is_publish=true`)
      .then((response) => {
        const newCourses = response.data.results.map((course) => ({
          value: course.id,
          label: course.title,
        }));
        setCourseOptions((prevOptions) => [...prevOptions, ...newCourses]);
        setIsLoadingCourses(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoadingCourses(false);
      });
  }, [page]);

  useEffect(() => {
    fetchCoursesForDropdown();
  }, [fetchCoursesForDropdown]);

  // When a course is selected, filter the enrolled courses list
  const fetchEnrolledCourses = useCallback(() => {
    setIsLoading(true);
    const currentPage =
      activeKey === "nonVerified" ? nonVerifiedPage : verifiedPage;
    let endpoint =
      activeKey === "nonVerified"
        ? `${enrolledCourses}?is_verified=false&is_completed=true&page=${currentPage}`
        : `${enrolledCourses}?is_verified=true&is_completed=true&page=${currentPage}`;

    if (selectedCourse) {
      endpoint += `&course=${selectedCourse.value}`;
    }

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setEnrolledCoursesList(response.data.results);
        setTotalCount(response.data.count);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [activeKey, selectedCourse, nonVerifiedPage, verifiedPage]);

  useEffect(() => {
    setPage(1); // Reset to first page when switching tabs
    fetchEnrolledCourses();
  }, [fetchEnrolledCourses, activeKey]);

  // Handle course selection from dropdown
  const handleCourseChange = (selectedOption) => {
    setSelectedCourse(selectedOption);
    setNonVerifiedPage(1);
    setVerifiedPage(1);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (activeKey === "nonVerified") {
      setNonVerifiedPage(newPage);
    } else {
      setVerifiedPage(newPage);
    }
  };

  const handleShowDetails = (record) => {
    setSelectedRecord(record);
    setShowDetailsModal(true);
  };

  const handleCloseDetails = () => {
    setShowDetailsModal(false);
  };

  const handleShowCertificate = (record) => {
    setSelectedRecord(record);
    setShowCertificateModal(true);
    setCertificateSrc(null); // Reset certificate source
    setTimeout(() => {
      setCertificateSrc(record.certificate); // Lazy load certificate
    }, 1000); // Simulate network delay
  };

  const handleCloseCertificate = () => {
    setShowCertificateModal(false);
  };

  const handleVerify = (courseId) => {
    axiosInstance
      .patch(patchEnrolledCourses(courseId), { is_verified: true })
      .then((response) => {
        toast.success("Course verified successfully!");
        fetchEnrolledCourses();
        handleCloseCertificate();
      })
      .catch((error) => {
        toast.error("Failed to verify course.");
        console.log(error);
      });
  };

  const handleReject = (courseId) => {
    axiosInstance
      .delete(deleteEnrolledCertificate(courseId))
      .then((response) => {
        toast.success("Course rejected successfully!");
        fetchEnrolledCourses();
        handleCloseCertificate();
      })
      .catch((error) => {
        toast.error("Failed to reject course.");
        console.log(error);
      });
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
    },
    {
      title: "Course Name",
      dataIndex: ["course_details", "title"],
      key: "course_name",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleShowDetails(record)}
            className="btn btn-primary me-2"
          >
            Show Details
          </Button>
          <Button
            onClick={() => handleShowCertificate(record)}
            className="btn btn-secondary"
          >
            See Certificate
          </Button>
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let statusText = "";
        let backgroundColor = "";
        if (record.is_completed && !record.is_verified) {
          statusText = "Under Verification";
          backgroundColor = "#ffc107";
        } else if (record.is_completed && record.is_verified) {
          statusText = "Verified";
          backgroundColor = "#4caf50";
        }

        return (
          <span
            style={{
              padding: "10px",
              color: "#fff",
              backgroundColor,
            }}
          >
            {statusText}
          </span>
        );
      },
    },
  ];

  const handleTabSelect = (key) => {
    setActiveKey(key);
    // No need to reset pages here, as we're using separate page states
  };

  return (
    <>
      <Header />
      <div className="row mb-4 d-flex align-items-center justify-content-between">
        <div className="col-md-6">
          <h1 className="text-2xl font-bold">Training Verification</h1>
        </div>
        <div className="col-md-3">
          <Select
            options={courseOptions}
            value={selectedCourse}
            onChange={handleCourseChange}
            isLoading={isLoadingCourses}
            placeholder="Select Course"
            onMenuScrollToBottom={() => setPage((prev) => prev + 1)}
          />
        </div>
      </div>
      <Tabs activeKey={activeKey} onSelect={handleTabSelect}>
        <Tab eventKey="nonVerified" title="Non-Verified Courses">
          <div className="col-md-12">
            <div className="table-responsive">
              <Spin spinning={isLoading}>
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={enrolledCoursesList}
                  rowKey={(record) => record.id}
                  pagination={false}
                />
                <Pagination
                  current={nonVerifiedPage}
                  total={totalCount}
                  pageSize={PAGE_SIZE}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  showQuickJumper={false}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                />
              </Spin>
            </div>
          </div>
        </Tab>
        <Tab eventKey="verified" title="Verified Courses">
          <div className="col-md-12">
            <div className="table-responsive">
              <Spin spinning={isLoading}>
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={enrolledCoursesList}
                  rowKey={(record) => record.id}
                  pagination={false}
                />
                <Pagination
                  current={verifiedPage}
                  total={totalCount}
                  pageSize={PAGE_SIZE}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                  showQuickJumper={false}
                  showTotal={(total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`
                  }
                />
              </Spin>
            </div>
          </div>
        </Tab>
      </Tabs>

      {/* Details Modal */}
      <Modal show={showDetailsModal} onHide={handleCloseDetails} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Course Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRecord && (
            <div className="row">
              <div className="col-md-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">Employee Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRecord.user_details.employee_name || ""}
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">Department</label>
                  <input
                    type="text"
                    className="form-control"
                    value={
                      selectedRecord.user_details.employment_details[0]
                        .department_detail.name || ""
                    }
                    readOnly
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">Course Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRecord.course_details.title || ""}
                    readOnly
                  />
                </div>
              </div>

              <div className="col-md-12">
                <div className="input-block mb-3">
                  <label className="col-form-label">Platform</label>
                  <input
                    type="text"
                    className="form-control"
                    value={selectedRecord.course_details.platform || ""}
                    readOnly
                  />
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDetails}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Certificate Modal */}
      <Modal
        show={showCertificateModal}
        onHide={handleCloseCertificate}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>Certificate</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRecord && (
            <img
              src={certificateSrc}
              alt="Certificate"
              style={{ width: "100%" }}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => handleVerify(selectedRecord.id)}
            disabled={selectedRecord?.is_verified}
          >
            Verify
          </Button>
          <Button
            variant="danger"
            onClick={() => handleReject(selectedRecord.id)}
            disabled={selectedRecord?.is_verified}
          >
            Reject
          </Button>
          <Button variant="secondary" onClick={handleCloseCertificate}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .nav-tabs .nav-link {
          color: red;
        }
        .nav-tabs .nav-link.active {
          color: blue;
        }
      `}</style>
    </>
  );
};

export default TrainingVerificationList;
