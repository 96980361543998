import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Button,
  Select,
  Tabs,
  Col,
  Row,
  Input,
  DatePicker,
  Modal,
  Typography,
} from "antd";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

import ShortlistedCandidateListProject from "./ShortlistedCandidateListProject";
import ShortlistedCandidateFinal from "./ShortlistedCandidateFinal";
import ShortListCandidatesTech from "./ShortlistedCandidateListTech";
import { FaLocationArrow } from "react-icons/fa";
import CutoffFilterForm from "./modals/CutoffFilterForm";

const { Option } = Select;
const { TabPane } = Tabs;

const ShortListCandidate = () => {
  const [filter, setFilter] = useState("all");
  const [interviewCount, setInterviewCount] = useState("10");
  const [interviewDate, setInterviewDate] = useState(null);
  const [activeTab, setActiveTab] = useState("shortlisted");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const navigate = useNavigate();

  const toSchedule = () => {
    navigate("/tech-schedule-candidate");
  };

  const columns = () => {
    if (filter === "all") {
      return [
        { title: "Candidate Name", dataIndex: "name", key: "name" },
        { title: "Experience", dataIndex: "experience", key: "experience" },
        { title: "Email", dataIndex: "email", key: "email" },
        {
          title: "Mobile (With Country Code)",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "Cut off Filter form",
          dataIndex: "filterForm",
          key: "filterForm",
          render: () => (
            <Button
              style={{ background: "#374151", color: "white", border: "none" }}
              onClick={() => setIsModalVisible(true)}
            >
              View
            </Button>
          ),
        },
        {
          title: "Shortlisted",
          dataIndex: "shortlisted",
          key: "shortlisted",
          render: () => (
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          ),
        },
        {
          title: "View Resume",
          dataIndex: "resume",
          key: "resume",
          render: () => <Button>View Resume</Button>,
        },
      ];
    } else if (filter === "not_Schedule") {
      return [
        { title: "Candidate Name", dataIndex: "name", key: "name" },
        { title: "Experience", dataIndex: "experience", key: "experience" },
        { title: "Education", dataIndex: "education", key: "education" },
        { title: "Email", dataIndex: "email", key: "email" },
        {
          title: "Mobile (With Country Code)",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "Shortlisted",
          dataIndex: "shortlisted",
          key: "shortlisted",
          render: () => (
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          ),
        },
        {
          title: "View Resume",
          dataIndex: "resume",
          key: "resume",
          render: () => <Button>View Resume</Button>,
        },
      ];
    } else if (filter === "Schedule") {
      return [
        { title: "Candidate Name", dataIndex: "name", key: "name" },
        { title: "Experience", dataIndex: "experience", key: "experience" },
        {
          title: "Link",
          dataIndex: "link",
          key: "link",
          render: () => <Button>Join</Button>,
        },
        { title: "Email", dataIndex: "email", key: "email" },
        {
          title: "Mobile (With Country Code)",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "Status",
          dataIndex: "status",
          render: () => (
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="Available">Available</Option>
              <Option value="Not_Available">Not Available </Option>
            </Select>
          ),
        },
        {
          title: "Shortlisted",
          dataIndex: "shortlisted",
          key: "shortlisted",
          render: () => (
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Yes</Option>
              <Option value="no">No</Option>
            </Select>
          ),
        },
        {
          title: "View Resume",
          dataIndex: "resume",
          key: "resume",
          render: () => <Button>View Resume</Button>,
        },
      ];
    }
  };

  const dataSource = [
    {
      key: "1",
      name: "John Doe",
      experience: "5 years",
      email: "john.doe@example.com",
      mobile: "+1234567890",
      filterForm: "View",
      shortlisted: "Select",
      resume: "View Resume",
      education: "Bachelor's Degree",
      link: "https://example.com",
      status: "Pending",
    },
  ];

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <Tabs activeKey={activeTab} onChange={setActiveTab} centered>
            <TabPane tab="Shortlisted Candidate List Table" key="shortlisted">
              <h2 level={2} style={{ margin: "20px 0px" }}>
                Interview Candidates
              </h2>

              <Row gutter={16} style={{ marginBottom: 20 }}>
                <Col span={6}>
                  <Select
                    value={filter}
                    onChange={setFilter}
                    style={{ width: "100%" }}
                  >
                    <Option value="all">All</Option>
                    <Option value="Schedule">Schedule</Option>
                    <Option value="not_Schedule">not Schedule</Option>
                  </Select>
                </Col>
                <Col span={6}>
                  <Input
                    value={interviewCount}
                    onChange={(e) => setInterviewCount(e.target.value)}
                  />
                </Col>
                <Col span={6}>
                  <DatePicker
                    style={{ width: "100%" }}
                    onChange={(date, dateString) =>
                      setInterviewDate(dateString)
                    }
                    placeholder="pick a date"
                  />
                </Col>
              </Row>

              <Table
                dataSource={dataSource}
                columns={columns()}
                pagination={false}
              />
            </TabPane>

            <TabPane tab="Technical Interview Candidate List" key="Tech">
              <ShortListCandidatesTech />
            </TabPane>
            <TabPane tab="Project round Shortlisted Candidate" key="project">
              <ShortlistedCandidateListProject />
            </TabPane>
            <TabPane tab="Final Short listed Candidates" key="final">
              <ShortlistedCandidateFinal />
            </TabPane>
          </Tabs>
          <Modal
            title={null}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            centered
            width={900}
          >
            <CutoffFilterForm setIsModalVisible={setIsModalVisible} />
          </Modal>
        </div>
      </div>
    </>
  );
};

export default ShortListCandidate;
