import React from "react";
import { Button, Select, Tabs, Col, Row, Input, Typography } from "antd";

const { Title } = Typography;

const CutoffFilterForm = ({ setIsModalVisible }) => {
  return (
    <div>
      <Button
        style={{
          background: "#1E2B32",
          color: "white",
          marginBottom: 20,
        }}
      >
        Cut off Filter Form
      </Button>
      <Row gutter={24}>
        <Col span={12}>
          <Title level={5}>
            Are you okay with the Salary mentioned in the Ad?*
          </Title>
          <Input value="Yes" />
        </Col>
        <Col span={12}>
          <Title level={5}>
            Do you own a company or work as a freelancer?*
          </Title>
          <Input value="Freelancer" />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={12}>
          <Title level={5}>Have you completed your graduation?*</Title>
          <Input value="Yes" />
        </Col>
        <Col span={12}>
          <Title level={5}>Currently, are you engaged in any studies?*</Title>
          <Input value="No" />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={12}>
          <Title level={5}>
            What is your maximum experience in this field? [In years or months]*
          </Title>
          <Row gutter={24}>
            <Col span={12}>
              <Input value="Years" />
            </Col>
            <Col span={12}>
              <Input value="2" />
            </Col>
          </Row>
        </Col>
        <Col span={12}>
          <Title level={5}>
            There will be a project presentation round. Will you be able to
            complete the project and present it?*
          </Title>
          <Input value="Yes" />
        </Col>
      </Row>
      <br />
      <Row gutter={24}>
        <Col span={12}>
          <Title level={5}>
            Do you have your own laptop & internet connection bandwidth that
            allows continuous audio and video usage?*
          </Title>
          <Input value="Yes" />
        </Col>
        <Col span={12}>
          <Title level={5}>How soon can you join?*</Title>
          <Input value="Today" />
        </Col>
      </Row>
      <br />
      <br />
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ background: "#4A4A4A", color: "white" }}
          onClick={() => setIsModalVisible(false)}
        >
          Close
        </Button>
      </div>
    </div>
  );
};

export default CutoffFilterForm;
