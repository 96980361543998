import React, { useEffect, useState } from "react";
import { Form, Input, Button, Row, Col, Card } from "antd";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { useNavigate, useParams } from "react-router-dom";
import {
  candidateAssesment,
  jobRequestById,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

function CandidateAssessment() {
  const { id } = useParams();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [position, setPosition] = useState();
  const [designation, setDesignation] = useState();

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await axiosInstance.get(jobRequestById(id));
        console.log("Fetched all hiring project data:", response.data);
        setPosition(response.data.position_title);
        setDesignation(response.data.designation);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch jrf data");
      }
    };
    fetchDetails();
  }, [id]);
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const data = {
        ...values,
        job_request: id, //for testing, later need to be param from job requset form
      };
      await axiosInstance.post(candidateAssesment, data);
      toast.success("Candidate assesment form is submitted");

      const response = await axiosInstance.get(candidateAssesment);
      console.log("Fetched data after submission:", response.data);

      form.resetFields();
      navigate(`/hiring-project-form/${id}`);
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit assesment form");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <Card>
            <h2
              style={{
                fontWeight: "bold",
                marginBottom: "20px",
              }}
            >
              Candidate Assessment Form (CAF) - First Interview Round
            </h2>
            <Form layout="vertical" form={form} onFinish={handleSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item label={<strong>HabotConnect Position</strong>}>
                    <Input size="large" value={position} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label={<strong>Portal Designation</strong>}>
                    <Input size="large" value={designation} />
                  </Form.Item>
                </Col>
              </Row>

              <h3 style={{ fontWeight: "bold", margin: "20px 0px 10px 0px" }}>
                Can they give the required output or not?
              </h3>
              <Form.Item>
                <Input.TextArea
                  rows={3}
                  disabled
                  defaultValue={`1. Copy from JRF and convert to question.
2. MUST be the same as of JRF.
3. MUST change for each job and come from JRF only.`}
                />
              </Form.Item>

              <Form.Item
                name="required_1"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="required_2"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="required_3"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="required_4"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="required_5"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="required_6"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>
              <Form.Item
                name="required_7"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input size="large" />
              </Form.Item>

              <h3 style={{ fontWeight: "bold", margin: "20px 0px 10px 0px" }}>
                HabotConnect Fit
              </h3>
              <Form.Item
                name="connect_fit_1"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input
                  size="large"
                  value="8. Name 2 leadership principles and what they mean to you"
                />
              </Form.Item>
              <Form.Item
                name="connect_fit_2"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input
                  size="large"
                  value="9. Name 2 HabotConnect values you admire and how do you abide by them in your life"
                />
              </Form.Item>
              <Form.Item
                name="connect_fit_3"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input
                  size="large"
                  value="10. One reason why you want this job"
                />
              </Form.Item>
              <Form.Item
                name="connect_fit_4"
                rules={[
                  {
                    required: true,
                    message: "this field is required",
                  },
                ]}
              >
                <Input
                  size="large"
                  value="11. One thing you will contribute to team performance"
                />
              </Form.Item>

              <div style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit & Proceed
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
}

export default CandidateAssessment;
