export const Vendordbstosidebardata_GACL = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Gacl Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/gacl-dashboard",
        icon: "la la-dashcube",
      },
      //task Management
      {
        menuValue: "Task Assignment",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "fa fa-tasks",
        subMenus: [
          {
            menuValue: "Dashboard",
            route: "/esr-aml-dashboard",
          },
          {
            menuValue: "Your Tasks",
            route: "/gacl-task-assigned",
          },
        ],
      },
      {
        menuValue: "Board Meeting",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/board-meeting",
        icon: "la la-list-alt",
      },

      {
        menuValue: "Vendor managment",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-wallet",
        subMenus: [
          {
            menuValue: "Vendor Requests",
            route: "/vendor-requests",
          },
          {
            menuValue: "Review & Initiate",
            route: "/all-vendors",
          },

          {
            menuValue: "Upload Documents",
            route: "/vendor-documents-upload",
          },
          {
            menuValue: "Vendor Profiles",
            route: "/documents-history",
          },
        ],
      },
      // {
      //   menuValue: "Expense Track",
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   route: "#",
      //   icon: "la la-file-invoice-dollar",
      //   subMenus: [
      //     {
      //       menuValue: "Add Invoice",
      //       route: "/expense-documents-upload",
      //     },
      //     {
      //       menuValue: "Ongoing Extraction",
      //       route: "/expense-documents-history",
      //     },
      //     {
      //       menuValue: "Bank Invoice",
      //       route: "/expense-bank-invoice",
      //     },
      //   ],
      // }
    ],
  },
];
