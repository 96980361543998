import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import "../../../../assets/css/calendar.css";

function JobRequestTech() {
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <Form>
            <Form.Group className="mb-3" controlId="resignationLetter">
              <Row>
                <Col>
                  <Form.Label className="mt-4">
                    Initiate Hiring request
                  </Form.Label>
                  <Form.Select>
                    <option>Select</option>
                    <option value="Technical">Technical + Project Round</option>
                    <option value="Project">Project Round</option>
                  </Form.Select>
                </Col>
                <Col></Col>
                <Col></Col>
              </Row>

              <Button variant="secondary" className="my-3">
                Job Request Form (JRF)
              </Button>
              <Row>
                <Col>
                  <Form.Label className="mt-4">
                    First Round Interview Date
                  </Form.Label>
                  <Form.Control size="lg" type="date" />
                </Col>
                <Col>
                  <Form.Label className="mt-4">
                    Panel Members First Round
                  </Form.Label>
                  <Form.Select>
                    <option>Select</option>
                    <option value="">abc</option>
                    <option value="">def</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label className="mt-4">
                    Second Round Interview Date
                  </Form.Label>
                  <Form.Control size="lg" type="date" />
                </Col>
                <Col>
                  <Form.Label className="mt-4">
                    Panel Members Second Round
                  </Form.Label>
                  <Form.Select>
                    <option>Select</option>
                    <option value="">abc</option>
                    <option value="">def</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="mt-4">
                    Name of person requesting{" "}
                  </Form.Label>
                  <Form.Control size="lg" type="text" />
                </Col>
                <Col>
                  <Form.Label className="mt-4">
                    HabotConnect Position Title
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    className="custom-placeholder"
                    type="text"
                    placeholder="Full Internal Designation to be added in contract"
                  />
                </Col>
                <Col>
                  <Form.Label className="mt-4">Portal Designation </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    className="custom-placeholder"
                    placeholder="Job title that will be used to post the job on portals like LinkedIn and Indeed"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="mt-4">Team</Form.Label>
                  <Form.Select>
                    <option>Select</option>
                    <option value="Technical">Technical</option>
                    <option value="hr">HR</option>
                    <option value="Operations">Operations</option>
                    <option value="Marketing">Marketing</option>
                  </Form.Select>
                </Col>
                <Col>
                  <Form.Label className="mt-4">Reports to</Form.Label>
                  <Form.Select>
                    <option>Select</option>
                    <option value="">abc</option>
                    <option value="">def</option>
                  </Form.Select>{" "}
                </Col>
                <Col>
                  <Form.Label className="mt-4">Pay Scale </Form.Label>
                  <Row>
                    <Col xs={4}>
                      <Form.Control
                        size="lg"
                        type="text"
                        placeholder="Currency"
                      />
                    </Col>
                    <Col xs={4}>
                      <Form.Control size="lg" type="number" placeholder="Min" />
                    </Col>
                    <Col xs={4}>
                      <Form.Control size="lg" type="number" placeholder="Max" />
                    </Col>
                  </Row>{" "}
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <Form.Label className="mt-4">Number of hires</Form.Label>
                  <Form.Control size="lg" type="number" />
                </Col>
                <Col md={8}>
                  <Form.Label className="mt-4">Education</Form.Label>
                  <Form.Control size="lg" type="text" />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="mt-4">
                    Things you will require them to know during the Interviews?
                  </Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    className="custom-placeholder"
                    placeholder="Add Job Decription, specific deliverables, results, or outcomes expected from the employee for the job role, outlining the key responsibilities for the position"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label className="mt-4">Minimum experience</Form.Label>
                  <Form.Control size="lg" type="text" />
                </Col>
                <Col>
                  <Form.Label className="mt-4">Maximum experience</Form.Label>
                  <Form.Control size="lg" type="text" />
                </Col>
                <Col>
                  <Form.Label className="mt-4">Employment Type</Form.Label>
                  <Form.Control
                    size="lg"
                    type="text"
                    placeholder="Full Time100% Remote Working: Your camera, video connection must work well"
                  />
                </Col>
              </Row>
            </Form.Group>

            <Button variant="warning" className="px-4 mt-2">
              Submit & Proceed
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
}

export default JobRequestTech;
