import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Typography,
  Card,
  Row,
  Col,
  DatePicker,
} from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  hiringProjectFormById,
  hiringProjectForm,
} from "../../../../helpers/endpoints/api_endpoints";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const { TextArea } = Input;
const { Title } = Typography;

const HPFComponent = ({ jobRequestId }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [hpfData, setHpfData] = useState(null);

  // Separate states for each field
  const [context, setContext] = useState("");
  const [assessedOn, setAssessedOn] = useState("");
  const [expectedToDo, setExpectedToDo] = useState("");
  const [presentationToBe, setPresentationToBe] = useState("");
  const [lastWorkingDate, setLastWorkingDate] = useState(null);
  const [resourceLinks, setResourceLinks] = useState([]);
  const [editing, setEditing] = useState(false);
  const [id, setId] = useState();

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axiosInstance.get(
          `${hiringProjectForm}?job_request=${jobRequestId}`
        );
        console.log("Fetched hiring project data:", response.data);

        if (
          response.data &&
          response.data.results &&
          response.data.results.length > 0
        ) {
          const data = response.data.results[0];
          setHpfData(data);
          setId(data.id);

          // Set all field states
          setContext(data.context || "");
          setAssessedOn(data.assessed_on || "");
          setExpectedToDo(data.expected_to_do || "");
          setPresentationToBe(data.presentation_is_to_be || "");
          setLastWorkingDate(
            data.submission_date ? dayjs(data.submission_date) : null
          );

          // Set form values
          form.setFieldsValue({
            context: data.context,
            assessed_on: data.assessed_on,
            expected_to_do: data.expected_to_do,
            presentation_is_to_be: data.presentation_is_to_be,
            submission_date: data.submission_date
              ? dayjs(data.submission_date)
              : null,
          });
          setResourceLinks(data.resource_link || []);
        } else {
          toast.error("No matching project data found");
        }
      } catch (error) {
        console.error("Error fetching hiring project data:", error);
        console.error("Error response:", error.response);
        toast.error("Failed to fetch hiring project data");
      }
    };

    if (jobRequestId) {
      console.log("JobRequestId received:", jobRequestId);
      fetchAllData();
    } else {
      console.log("No jobRequestId provided");
    }
  }, [jobRequestId, form]);

  const addResourceLink = () => {
    setResourceLinks([...resourceLinks, ""]);
  };

  // URL validation function
  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const handleLinkChange = (index, value) => {
    const newLinks = [...resourceLinks];
    newLinks[index] = value;
    setResourceLinks(newLinks);
  };

  const validateResourceLinks = (links) => {
    const invalidLinks = links.filter((link) => {
      // Skip empty links as they're allowed (nullable)
      if (!link.trim()) return false;
      return !isValidUrl(link);
    });

    if (invalidLinks.length > 0) {
      toast.error("Please enter valid URLs for resource links");
      return false;
    }

    // Check max length
    const tooLongLinks = links.filter((link) => link.length > 200);
    if (tooLongLinks.length > 0) {
      toast.error("Resource links must not exceed 200 characters");
      return false;
    }

    return true;
  };

  const removeResourceLink = (index) => {
    const newLinks = resourceLinks.filter((_, i) => i !== index);
    setResourceLinks(newLinks);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);

      // Validate resource links before submission
      const validLinks = resourceLinks.filter((link) => link.trim() !== "");
      if (!validateResourceLinks(validLinks)) {
        setLoading(false);
        return;
      }

      console.log("Current values:", {
        context,
        assessedOn,
        expectedToDo,
        presentationToBe,
        lastWorkingDate,
      });

      const data = {
        id: hpfData.id,
        job_request: hpfData.job_request,
        context: context,
        assessed_on: assessedOn,
        expected_to_do: expectedToDo,
        presentation_is_to_be: presentationToBe,
        submission_date: lastWorkingDate
          ? lastWorkingDate.format("YYYY-MM-DD")
          : null,
        resource_link: validLinks,
        created_at: hpfData.created_at,
        updated_at: dayjs().format("YYYY-MM-DD"),
      };

      console.log("Submitting data:", data);

      if (id) {
        await axiosInstance.patch(hiringProjectFormById(id), data);
        console.log("passed");
        toast.success("Hiring project form updated successfully");
      } else {
        await axiosInstance.post(hiringProjectForm, data);
        toast.success("Hiring project form submitted successfully");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      console.error("Error details:", error.response?.data);
      toast.error("Failed to submit hiring project form");
    } finally {
      setLoading(false);
    }
  };

  const toggleEdit = () => {
    setEditing(!editing);
  };

  return (
    <div style={{ maxWidth: 1100, margin: "auto", padding: "30px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <h3
          className="py-1 px-4 bg-black text-white"
          style={{ marginBottom: "30px" }}
        >
          Hiring Project Form (HPF)
        </h3>
        <Button
          onClick={toggleEdit}
          style={{
            backgroundColor: "orange",
            borderColor: "orange",
            color: "#fff",
          }}
        >
          {editing ? "Cancel" : "Edit"}
        </Button>
      </div>

      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        style={{ fontWeight: "bold" }}
      >
        <Form.Item label="Project Context" name="context">
          <TextArea
            disabled={!editing}
            value={context}
            onChange={(e) => setContext(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="You Will Be Assessed On" name="assessed_on">
          <Input
            disabled={!editing}
            value={assessedOn}
            onChange={(e) => setAssessedOn(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="You are Expected To Do" name="expected_to_do">
          <Input
            disabled={!editing}
            value={expectedToDo}
            onChange={(e) => setExpectedToDo(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="The Presentation Is To Be"
          name="presentation_is_to_be"
        >
          <Input
            disabled={!editing}
            value={presentationToBe}
            onChange={(e) => setPresentationToBe(e.target.value)}
          />
        </Form.Item>

        <Row gutter={24}>
          <Col span={8}>
            <Form.Item
              label="Project Submission Deadline"
              name="submission_date"
            >
              <DatePicker
                disabled={!editing}
                style={{ width: "100%" }}
                format="YYYY-MM-DD"
                value={lastWorkingDate}
                onChange={(date) => {
                  setLastWorkingDate(date);
                  form.setFieldsValue({ submission_date: date });
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/* Resource Links Section with validation feedback */}
        <div className="resource-links-section mt-4">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <h5>Resource Links</h5>
            {editing && (
              <Button
                onClick={addResourceLink}
                type="dashed"
                style={{ marginBottom: "10px" }}
              >
                Add Resource Link
              </Button>
            )}
          </div>

          {resourceLinks.map((link, index) => (
            <div key={index} className="d-flex gap-2 mb-2">
              <Form.Item
                validateStatus={link && !isValidUrl(link) ? "error" : ""}
                help={
                  link && !isValidUrl(link) ? "Please enter a valid URL" : ""
                }
                style={{ width: "100%", marginBottom: "8px" }}
              >
                <Input
                  value={link}
                  onChange={(e) => handleLinkChange(index, e.target.value)}
                  placeholder="Enter resource link (e.g., https://example.com)"
                  disabled={!editing}
                  maxLength={200}
                  status={link && !isValidUrl(link) ? "error" : ""}
                />
              </Form.Item>
              {editing && (
                <Button
                  onClick={() => removeResourceLink(index)}
                  danger
                  type="text"
                >
                  Delete
                </Button>
              )}
            </div>
          ))}
          <div className="text-muted" style={{ fontSize: "12px" }}>
            * URLs must be valid and not exceed 200 characters
          </div>
        </div>

        {editing && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: "orange",
                borderColor: "orange",
                color: "#fff",
                marginRight: 10,
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => setEditing(false)}
              style={{
                backgroundColor: "gray",
                borderColor: "gray",
                color: "#fff",
              }}
            >
              Close
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default HPFComponent;
