export const SidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "HR Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/hr-dashboard",
        icon: "la la-dashcube",
      },

      {
        menuValue: "Employees",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-user",
        subMenus: [
          {
            menuValue: "Employee Data Dashboard",
            route: "/employee-data-dashboard",
          },
          {
            menuValue: "Add Employee",
            route: "/employees",
          },
          {
            menuValue: "All Employee",
            route: "/employeeDetails",
          },
        ],
      },
      {
        menuValue: "ZII",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-list",
        subMenus: [
          {
            menuValue: "ZII Dashboard",
            route: "/zii-dashboard",
          },
          {
            menuValue: "ZII Topics",
            route: "/zii-topic",
          },
          {
            menuValue: "ZII Reports",
            route: "/zii-reports",
          },
        ],
      },
      {
        menuValue: "Training",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-graduation-cap",
        subMenus: [
          {
            menuValue: "Training Dashboard",
            route: "/training-dashboard",
          },
          {
            menuValue: "Approval List",
            route: "/training-reports",
          },
          {
            menuValue: "Certificate Verification",
            route: "/training-verification",
          },
        ],
      },
      {
        menuValue: "Policies",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-pdf-o",
        subMenus: [
          {
            menuValue: "Policy Dashboard",
            route: "/policy-dashboard",
          },
          {
            menuValue: "Add New Policy",
            route: "/add-policies",
          },
        ],
      },
      {
        menuValue: "Appraisal Reports",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-star-o",
        subMenus: [
          {
            menuValue: "Appraisal Dashboard",
            route: "/appraisal-dashboard",
          },
          {
            menuValue: "Department Appraisal Report",
            route: "/hr-app-report",
          },
          {
            menuValue: "Monthly Report",
            route: "/appraisal-report",
          },
        ],
      },

      {
        menuValue: "Holidays",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar-minus-o",
        subMenus: [
          {
            menuValue: "Holiday Dashboard",
            route: "/holiday-dashboard",
          },
          {
            menuValue: "Add Holidays",
            route: "/holidays-calendar",
          },
        ],
      },

      {
        menuValue: "Attendance",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar-check-o",
        subMenus: [
          {
            menuValue: "Attendance Dashboard",
            route: "/attendance-dashboard",
          },
          {
            menuValue: "Attendance Reports",
            route: "/hr-attendance",
          },
        ],
      },

      {
        menuValue: "Celebrations",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-birthday-cake",
        subMenus: [
          {
            menuValue: "Celebrations Dashboard",
            route: "/celebrations-dashboard",
          },
          {
            menuValue: "Celebrations",
            route: "/celebrations",
          },
        ],
      },
      {
        menuValue: "Leaves",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar",
        subMenus: [
          {
            menuValue: "Leave Dashboard",
            route: "/leave-dashboard",
          },
          {
            menuValue: "Approve Leaves",
            route: "/hr-leaves",
          },
        ],
      },
      {
        menuValue: "QPA",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-folder",
        subMenus: [
          {
            menuValue: "QPA Dashboard",
            route: "/qpa-dashboard",
          },
          {
            menuValue: "QPA Report",
            route: "/qpa-report",
          },
          {
            menuValue: "Engine Report",
            route: "/engine-report",
          },
        ],
      },
      {
        menuValue: "Resignation",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-external-link-square",
        subMenus: [
          {
            menuValue: "View Resignation letter (HR)",
            route: "/view-resignation-letter-hr",
          },
          {
            menuValue: "View Resignation letter (For TL)",
            route: "/view-resignation-letter-hr-for-tl",
          },
        ],
      },
      // {
      //   menuValue: "Recruitment",
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   route: "#",
      //   icon: "la la-tasks",
      //   subMenus: [
      //     {
      //       menuValue: "Job Request Form",
      //       route: "/job-request-form",
      //     },
      //     {
      //       menuValue: "Job Request Technical Form",
      //       route: "/job-request-technical-form",
      //     },
      //     {
      //       menuValue: "View Job Request",
      //       route: "/view-job-request",
      //     },
      //     {
      //       menuValue: "Candidate Assessment Form",
      //       route: "/candidate-assessment-form",
      //     },
      //     {
      //       menuValue: "Hiring Project Form",
      //       route: "/hiring-project-form",
      //     },
      //     {
      //       menuValue: "Career Page",
      //       route: "/career-page-2",
      //     },
      //     {
      //       menuValue: "View All Jobs",
      //       route: "/view-all-jobs",
      //     },
      //     {
      //       menuValue: "List For Technical Round",
      //       route: "/shortlisted-candidate-tech",
      //     },
      //     {
      //       menuValue: "List For Project Round",
      //       route: "/shortlisted-candidate-project",
      //     },
      //     {
      //       menuValue: "Final Shortlisted Candidate",
      //       route: "/shortlisted-candidate-final",
      //     },
      //   ],
      // },
      //{
      //   menuValue: "View Job Request",
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: "/view-job-request",
      //   icon: "la la-eye",
      // },
      // {
      //   menuValue: "Career Page",
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   route: "#",
      //   icon: "la la-briefcase",
      //   subMenus: [
      //     {
      //       menuValue: "Career Page",
      //       route: "/career-page",
      //     },
      //     {
      //       menuValue: "Job Details",
      //       route: "/job-details",
      //     },
      //   ],
      // },
      // {
      //   menuValue: "Engine Report",
      //   hasSubRoute: false,
      //   showSubRoute: false,
      //   route: "/engine-report",
      //   icon: "la la-clipboard-check",
      // },
    ],
  },
];
