import React, { useState, useEffect } from "react";
import {
  DatePicker,
  Input,
  Upload,
  Button,
  Radio,
  Select,
  Modal,
  Table,
} from "antd";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeDetails,
  employeeList,
  holidays,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../../../layout/Header";

// Constants for leave specifications - match exactly with backend
const LEAVE_SPECS = {
  FIRST_HALF: "First half",
  SECOND_HALF: "Second half",
  FULL_DAY: "Full day",
};

const LeaveApplicationForm = () => {
  const { Option } = Select;
  const [reason, setReason] = useState("");
  const [fileList, setFileList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalDays, setTotalDays] = useState(0);
  const [employeeID, setEmployeeID] = useState(null);
  const [contactPersonID, setContactPersonID] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [employeeListData, setEmployeeListData] = useState([]);
  const [isRSRD, setIsRSRD] = useState(false);

  // New states
  const [isMultiDay, setIsMultiDay] = useState(false);
  const [dailyLeaveDetails, setDailyLeaveDetails] = useState([]);
  const [showLeaveModal, setShowLeaveModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [showTaskModal, setShowTaskModal] = useState(false);
  const [currentTask, setCurrentTask] = useState({
    task_description: "",
    document_link: "",
    platform_link: "",
    assignee: null,
  });

  // Add new state for credentials
  const [credentials, setCredentials] = useState({
    user_id: "",
    password: "",
  });

  // Add holidayDates state
  const [holidayDates, setHolidayDates] = useState([]);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeID(response.data.employment_details[0].id);
        if (
          response.data.employment_details[0].designation_detail.name ===
            "MD" ||
          response.data.employment_details[0].designation_detail.name === "CEO"
        ) {
          setIsRSRD(true);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get(employeeList, {
          params: { employee_name: searchTerm },
        });
        setEmployeeListData(response.data);
      } catch (error) {
        console.error("Error fetching employee list:", error);
      }
    };

    if (searchTerm) {
      fetchEmployees();
    }
  }, [searchTerm]);

  useEffect(() => {
    fetchHolidays();
  }, []);

  const fetchHolidays = async () => {
    try {
      const response = await axiosInstance.get(`${holidays}`);
      const uniqueDates = [
        ...new Set(
          response.data.map((holiday) =>
            moment(holiday.date).format("YYYY-MM-DD")
          )
        ),
      ];
      console.log(uniqueDates, "Unique Holiday Dates");
      setHolidayDates(uniqueDates);
    } catch (error) {
      console.error("Error fetching holidays:", error);
    }
  };

  const handleUploadChange = ({ fileList }) => {
    setFileList(fileList.slice(-1));
  };

  const calculateTotalDays = () => {
    if (dailyLeaveDetails.length === 0) return 0;

    return dailyLeaveDetails.reduce((total, detail) => {
      switch (detail.leave_specification) {
        case LEAVE_SPECS.FIRST_HALF:
        case LEAVE_SPECS.SECOND_HALF:
          return total + 0.5;
        case LEAVE_SPECS.FULL_DAY:
          return total + 1;
        default:
          return total;
      }
    }, 0);
  };

  // Separate function for calculating leave details
  const calculateLeaveDetails = (start, end) => {
    let details = [];
    let currentDate = new Date(start);
    const endDate = new Date(end);

    while (currentDate <= endDate) {
      const formattedDate = moment(currentDate).format("YYYY-MM-DD");
      if (
        currentDate.getDay() !== 0 && // Sunday
        currentDate.getDay() !== 6 && // Saturday
        !holidayDates.includes(formattedDate) // Holiday check
      ) {
        details.push({
          date: formattedDate,
          leave_specification: "Full day",
        });
      }
      currentDate.setDate(currentDate.getDate() + 1);
    }
    return details;
  };

  // Update handleDateRangeSelect to separate concerns
  const handleDateRangeSelect = (dates) => {
    if (!dates) {
      setStartDate(null);
      setEndDate(null);
      setTotalDays(0);
      setDailyLeaveDetails([]);
      return;
    }

    if (isMultiDay) {
      const [start, end] = dates;
      if (!start || !end) {
        setStartDate(start || null);
        setEndDate(null);
        setTotalDays(0);
        setDailyLeaveDetails([]);
        return;
      }

      // First, update the date states
      setStartDate(start);
      setEndDate(end);

      // Then, calculate leave details separately
      const details = calculateLeaveDetails(start, end);
      setDailyLeaveDetails(details);
      setTotalDays(details.length);
    } else {
      // Single day selection
      setStartDate(dates);
      setEndDate(dates);

      const details = calculateLeaveDetails(dates, dates);
      setDailyLeaveDetails(details);
      setTotalDays(1);
    }
  };

  const handleDailyLeaveChange = (date, specification) => {
    setDailyLeaveDetails((prev) => {
      const newDetails = [...prev];
      const index = newDetails.findIndex((x) => x.date === date);
      if (index >= 0) {
        newDetails[index].leave_specification = specification;
      }
      return newDetails;
    });

    // Debug log
    console.log("Leave specification changed:", { date, specification });

    // Recalculate total days after state update
    setTimeout(() => {
      setTotalDays(calculateTotalDays());
    }, 0);
  };

  const handleAddTask = () => {
    if (!currentTask.task_description) {
      toast.error("Task description is required");
      return;
    }
    if (!credentials.user_id || !credentials.password) {
      toast.error("Credentials are required");
      return;
    }

    const newTask = {
      ...currentTask,
      id: Date.now(),
      credentials: {
        user_id: credentials.user_id,
        password: credentials.password,
      },
    };

    console.log("New Task with Credentials:", newTask); // Debug log
    setTasks([...tasks, newTask]);

    // Reset states
    setCurrentTask({
      task_description: "",
      document_link: "",
      platform_link: "",
      assignee: null,
    });
    setCredentials({
      user_id: "",
      password: "",
    });
    setShowTaskModal(false);
  };

  const validateForm = () => {
    if (!reason.trim()) {
      toast.error("Reason is required");
      return false;
    }
    if (!startDate) {
      toast.error("Start date is required");
      return false;
    }
    if (isMultiDay && !endDate) {
      toast.error("End date is required");
      return false;
    }
    if (!isRSRD && !contactPersonID) {
      toast.error("Contact person is required");
      return false;
    }
    if (dailyLeaveDetails.length === 0) {
      toast.error("Leave details are required");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    try {
      // Create FormData for the file
      const formData = new FormData();
      if (fileList.length > 0) {
        formData.append("document", fileList[0].originFileObj);
      }

      // Create the JSON payload for other data
      const jsonPayload = {
        user: employeeID,
        reason: reason,
        start_date: dailyLeaveDetails[0].date,
        end_date: dailyLeaveDetails[dailyLeaveDetails.length - 1].date,
        total_days: totalDays,
        leave_details: dailyLeaveDetails.map((detail) => ({
          date: detail.date,
          leave_specification: detail.leave_specification,
        })),
        tasks: tasks.map((task) => ({
          task_description: task.task_description,
          document_link: task.document_link || "",
          platform_link: task.platform_link || "",
          assignee: null,
          credentials: {
            user_id: task.credentials.user_id,
            password: task.credentials.password,
          },
        })),
        contact_person: contactPersonID,
      };

      // Debug log to verify payload
      console.log(
        "Submitting JSON payload:",
        JSON.stringify(jsonPayload, null, 2)
      );

      // First, send the JSON data
      const jsonResponse = await axiosInstance.post(addLeaves, jsonPayload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      // If there's a file, send it as a separate request
      if (fileList.length > 0) {
        const fileResponse = await axiosInstance.patch(
          `${addLeaves}${jsonResponse.data.id}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        console.log("File upload response:", fileResponse);
      }

      toast.success("Leave application submitted successfully");
      // Reset form
      setReason("");
      setStartDate(null);
      setEndDate(null);
      setTotalDays(0);
      setFileList([]);
      setDailyLeaveDetails([]);
      setTasks([]);
      setContactPersonID(null);
    } catch (error) {
      console.error("Submit Error:", error.response?.data || error);
      toast.error("Error submitting leave application");
    }
  };

  const taskColumns = [
    {
      title: "Task Description",
      dataIndex: "task_description",
      key: "task_description",
    },
    {
      title: "Document Link",
      dataIndex: "document_link",
      key: "document_link",

      render: (text) => text.slice(0, 20) + "...",
    },
    {
      title: "Platform Link",
      dataIndex: "platform_link",
      key: "platform_link",
      render: (text) => text.slice(0, 20) + "...",
    },
    // {
    //   title: "Assignee",
    //   dataIndex: "assignee",
    //   key: "assignee",
    //   render: (assignee) => {
    //     const employee = employeeListData.find((emp) => emp.id === assignee);
    //     return employee ? employee.employee_name : "";
    //   },
    // },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="link"
          onClick={() =>
            setTasks(tasks.filter((task) => task.id !== record.id))
          }
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleModeChange = (e) => {
    const isMulti = e.target.value === "multi";
    setIsMultiDay(isMulti);

    // Reset states when switching modes
    setStartDate(null);
    setEndDate(null);
    setTotalDays(0);
    setDailyLeaveDetails([]);
  };

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header text-center">
              <h2 className="text-3xl font-bold text-gray-700">
                Leave Application Form
              </h2>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="leave-application-form">
                    <form>
                      <div className="form-item mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Reason for Leave (Write in brief)
                        </label>
                        <Input.TextArea
                          value={reason}
                          onChange={(e) => setReason(e.target.value)}
                          rows={3}
                          placeholder="Please provide your reason for leave"
                        />
                      </div>

                      {!isRSRD && (
                        <div className="form-item mb-4">
                          <label className="block text-gray-700 font-semibold mb-2">
                            Contact Person
                          </label>
                          <Select
                            showSearch
                            placeholder="Search employee"
                            onSearch={setSearchTerm}
                            onChange={setContactPersonID}
                            value={contactPersonID}
                            style={{ width: "100%" }}
                            filterOption={false}
                          >
                            {employeeListData.map((employee) => (
                              <Option key={employee.id} value={employee.id}>
                                {employee.employee_name}
                              </Option>
                            ))}
                          </Select>
                        </div>
                      )}

                      <div className="form-item mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Leave Duration
                        </label>
                        <Radio.Group
                          value={isMultiDay ? "multi" : "single"}
                          onChange={handleModeChange}
                        >
                          <Radio value="single">Single Day</Radio>
                          <Radio value="multi">Multi Day</Radio>
                        </Radio.Group>
                      </div>

                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-item mb-4">
                            <label className="block text-gray-700 font-semibold mb-2">
                              Select Date{isMultiDay ? " Range" : ""}
                            </label>
                            {isMultiDay ? (
                              <DatePicker.RangePicker
                                onChange={handleDateRangeSelect}
                                format="YYYY-MM-DD"
                                disabledDate={(current) => {
                                  // Can't select days before today
                                  if (
                                    current &&
                                    current < moment().startOf("day")
                                  ) {
                                    return true;
                                  }
                                  // Disable weekends and holidays
                                  return (
                                    current &&
                                    (current.day() === 0 ||
                                      current.day() === 6 ||
                                      holidayDates.includes(
                                        current.format("YYYY-MM-DD")
                                      ))
                                  );
                                }}
                                value={[startDate, endDate]}
                                className="w-full"
                                placeholder={["Start Date", "End Date"]}
                              />
                            ) : (
                              <DatePicker
                                onChange={handleDateRangeSelect}
                                format="YYYY-MM-DD"
                                disabledDate={(current) => {
                                  // Can't select days before today
                                  if (
                                    current &&
                                    current < moment().startOf("day")
                                  ) {
                                    return true;
                                  }
                                  // Disable weekends and holidays
                                  return (
                                    current &&
                                    (current.day() === 0 ||
                                      current.day() === 6 ||
                                      holidayDates.includes(
                                        current.format("YYYY-MM-DD")
                                      ))
                                  );
                                }}
                                value={startDate}
                                className="w-full"
                                placeholder="Select Date"
                              />
                            )}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-item mb-4">
                            <label className="block text-gray-700 font-semibold mb-2">
                              Total Working Days
                            </label>
                            <Input
                              value={totalDays}
                              readOnly
                              disabled
                              suffix="day(s)"
                              style={{ width: "200px" }}
                              className="bg-gray-100 cursor-not-allowed"
                            />
                          </div>
                        </div>
                      </div>

                      {dailyLeaveDetails.length > 0 && (
                        <div className="form-item mb-4">
                          <label className="block text-gray-700 font-semibold mb-2">
                            Daily Leave Details
                          </label>
                          <div className="daily-leave-table">
                            <Table
                              dataSource={dailyLeaveDetails}
                              columns={[
                                {
                                  title: "Date",
                                  dataIndex: "date",
                                  key: "date",
                                  width: "20%",
                                  render: (date) =>
                                    moment(date).format("DD MMM YYYY"),
                                },
                                {
                                  title: "Leave Type",
                                  dataIndex: "leave_specification",
                                  key: "leave_specification",
                                  width: "80%",
                                  render: (text, record) => (
                                    <Radio.Group
                                      value={text}
                                      onChange={(e) =>
                                        handleDailyLeaveChange(
                                          record.date,
                                          e.target.value
                                        )
                                      }
                                    >
                                      <Radio value={LEAVE_SPECS.FIRST_HALF}>
                                        First Half (8:30am - 1:30pm)
                                      </Radio>
                                      <Radio value={LEAVE_SPECS.SECOND_HALF}>
                                        Second Half (1:30pm - 6:30pm)
                                      </Radio>
                                      <Radio value={LEAVE_SPECS.FULL_DAY}>
                                        Full Day
                                      </Radio>
                                    </Radio.Group>
                                  ),
                                },
                              ]}
                              pagination={false}
                              rowKey={(record) => record.date}
                              scroll={{ y: 240 }}
                              className="daily-leave-details-table"
                            />
                          </div>
                        </div>
                      )}

                      {totalDays >= 4 && (
                        <div className="form-item mb-4">
                          <label className="block text-gray-700 font-semibold mb-2">
                            Tasks (Optional)
                          </label>
                          <Button
                            type="dashed"
                            onClick={() => setShowTaskModal(true)}
                            icon={<PlusOutlined />}
                          >
                            Add Task
                          </Button>
                          {tasks.length > 0 && (
                            <Table
                              dataSource={tasks}
                              columns={taskColumns}
                              rowKey={(record) => record.id}
                              className="mt-4"
                              scroll={{ y: 240 }}
                            />
                          )}
                        </div>
                      )}

                      <div className="form-item mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">
                          Upload Document (optional)
                        </label>
                        <Upload
                          fileList={fileList}
                          onChange={handleUploadChange}
                          beforeUpload={() => false}
                          maxCount={1}
                        >
                          <Button icon={<UploadOutlined />}>Upload</Button>
                        </Upload>
                      </div>

                      <Button type="primary" onClick={handleSubmit}>
                        Submit Application
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Task Modal */}
      <Modal
        title="Add Task"
        visible={showTaskModal}
        onOk={handleAddTask}
        onCancel={() => setShowTaskModal(false)}
      >
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Task Description
          </label>
          <Input.TextArea
            value={currentTask.task_description}
            onChange={(e) =>
              setCurrentTask({
                ...currentTask,
                task_description: e.target.value,
              })
            }
            rows={3}
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Document Link
          </label>
          <Input
            value={currentTask.document_link}
            onChange={(e) =>
              setCurrentTask({ ...currentTask, document_link: e.target.value })
            }
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Platform Link
          </label>
          <Input
            value={currentTask.platform_link}
            onChange={(e) =>
              setCurrentTask({ ...currentTask, platform_link: e.target.value })
            }
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Credentials
          </label>
          <div className="space-y-2">
            <Input
              placeholder="User ID"
              value={credentials.user_id}
              onChange={(e) =>
                setCredentials({ ...credentials, user_id: e.target.value })
              }
            />
            <Input
              placeholder="Password"
              value={credentials.password}
              onChange={(e) =>
                setCredentials({ ...credentials, password: e.target.value })
              }
            />
          </div>
        </div>
        {/* <div className="mb-4">
          <label className="block text-gray-700 font-semibold mb-2">
            Assignee
          </label>
          <Select
            showSearch
            placeholder="Select assignee"
            value={currentTask.assignee}
            onChange={(value) =>
              setCurrentTask({ ...currentTask, assignee: value })
            }
            style={{ width: "100%" }}
          >
            {employeeListData.map((employee) => (
              <Option key={employee.id} value={employee.id}>
                {employee.employee_name}
              </Option>
            ))}
          </Select>
        </div> */}
      </Modal>
    </>
  );
};

export default LeaveApplicationForm;
