export const MMsidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Employee Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employee-dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "Documents",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-text",
        subMenus: [
          {
            menuValue: "Add Document",

            route: "/file-upload",
          },
          {
            menuValue: "View Documents",

            route: "/view-documents",
          },
          {
            menuValue: "Document Naming",

            route: "/document-naming",
          },
        ],
      },
      {
        menuValue: "ZII",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-list",
        subMenus: [
          {
            menuValue: "Add ZII",
            route: "/add-zii",
          },
          {
            menuValue: "Your ZII",
            route: "/employee-zii",
          },
        ],
      },
      {
        menuValue: "Board Meeting",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/board-meeting",
        icon: "la la-list-alt",
      },
      {
        menuValue: "Courses",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-graduation-cap",
        subMenus: [
          {
            menuValue: "Course List",
            route: "/employee-training",
          },
          {
            menuValue: "Your Courses",
            route: "/employee-courses",
          },
        ],
      },
      {
        menuValue: "Policies",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-pdf-o",
        subMenus: [
          {
            menuValue: "View Policies",
            route: "/employee-policy",
          },
        ],
      },
      {
        menuValue: "Appraisal",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-star-o",
        subMenus: [
          {
            menuValue: "Weekly Appraisal",
            route: "/employee-appraisal",
          },
          {
            menuValue: "My Appraisal",
            route: "/appraisal-table",
          },
          {
            menuValue: "Department Appraisal Report",
            route: "/appraisal-report-dept",
          },
        ],
      },
      {
        menuValue: "Holidays",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employee-holidays",
        icon: "la la-calendar-minus-o",
      },
      {
        menuValue: "Attendance",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar-check-o",
        subMenus: [
          {
            menuValue: "Active Employees",
            route: "/daily-log",
          },
          {
            menuValue: "Your Attendance",
            route: "/employee-attendance",
          },
        ],
      },
      {
        menuValue: "Leaves",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar",
        subMenus: [
          {
            menuValue: "Leave Form",
            route: "/leaves-form",
          },
          {
            menuValue: "My Leaves",
            route: "/your-leaves",
          },
          {
            menuValue: "Approve Leaves",
            route: "/approve-leaves",
          },
          {
            menuValue: "Your Task",
            route: "/your-task-leaves",
          },
        ],
      },
      {
        menuValue: "QPA",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-folder",
        subMenus: [
          {
            menuValue: "Nominate for QPA",
            route: "/nomination-details",
          },
          {
            menuValue: "Nominated QPA",
            route: "/nominated-qpa",
          },
          {
            menuValue: "Your QPA",
            route: "/your-qpa",
          },
          {
            menuValue: "QPA Report",
            route: "/qpa-report",
          },
          {
            menuValue: "End Document",
            route: "/end-document-qpa",
          },
        ],
      },
      {
        menuValue: "PDCA",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-clipboard-check",
        subMenus: [
          {
            menuValue: "Plan Phase",
            route: "/pdca-plan-phase",
          },
          {
            menuValue: "Problem Statement",
            route: "/pdca-problem-statement",
          },
        ],
      },
      {
        menuValue: "Resignation",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-external-link-square",
        subMenus: [
          {
            menuValue: "Resignation Form",
            route: "/resignation-form",
          },
          {
            menuValue: "View Resignation letter (Self)",
            route: "/resignation-letter",
          },
          {
            menuValue: "View Resignation letter (TL)",
            route: "/view-resignation-letter-tl",
          },
          {
            menuValue: "Handover Assigned Tasks",
            route: "/takeover-assigned-tasks",
          },
          {
            menuValue: "Handover - Taking over person",
            route: "/view-assigned-handovers",
          },
        ],
      },
      {
        menuValue: "Job Request",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-tasks",
        subMenus: [
          {
            menuValue: "Job Request Form",
            route: "/job-request-form",
          },
          {
            menuValue: "Job Request Technical Form",
            route: "/job-request-technical-form",
          },
          {
            menuValue: "Candidate Assessment Form",
            route: "/candidate-assessment-form",
          },
          {
            menuValue: "Hiring Project Form",
            route: "/hiring-project-form",
          },
        ],
      },
      {
        menuValue: "View Job Request",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/view-job-request",
        icon: "la la-eye",
      },
      {
        menuValue: "Career Page",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Career Page",
            route: "/career-page",
          },
          {
            menuValue: "Job Details",
            route: "/job-details",
          },
        ],
      },
      {
        menuValue: "Weekly Review",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-folder-open",
        subMenus: [
          {
            menuValue: "Add Review",
            route: "/add-weekly-review",
          },
          {
            menuValue: "View Review",
            route: "/view-weekly-review",
          },
        ],
      },
    ],
  },
];
