import React, { useState, useCallback, useRef, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Table,
  Tabs,
  Select,
  message,
  Tooltip,
  Modal,
  Spin,
  Space,
} from "antd";
import { CalendarOutlined, UnorderedListOutlined } from "@ant-design/icons";
import moment from "moment";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { holidays } from "../../../../helpers/endpoints/api_endpoints";
import "../../../../assets/css/calendar.css";

const { Option } = Select;

const MONTHS = {
  JANUARY: 1,
  FEBRUARY: 2,
  MARCH: 3,
  APRIL: 4,
  MAY: 5,
  JUNE: 6,
  JULY: 7,
  AUGUST: 8,
  SEPTEMBER: 9,
  OCTOBER: 10,
  NOVEMBER: 11,
  DECEMBER: 12,
};

const YEARS = (() => {
  const currentYear = moment().year();
  return Array.from({ length: 5 }, (_, i) => ({
    value: currentYear - 2 + i,
    label: `${currentYear - 2 + i}`,
  }));
})();

const MonthSelector = ({ value, onChange }) => (
  <Select
    value={value}
    onChange={onChange}
    style={{ width: 120, marginRight: 16 }}
  >
    {Object.entries(MONTHS).map(([name, value]) => (
      <Option key={value} value={value}>
        {name.charAt(0) + name.slice(1).toLowerCase()}
      </Option>
    ))}
  </Select>
);

const columns = [
  { title: "Holiday Name", dataIndex: "title", key: "title" },
  {
    title: "Date",
    dataIndex: "start",
    key: "start",
    sorter: (a, b) =>
      moment(a.start, "MMM D, YYYY").valueOf() -
      moment(b.start, "MMM D, YYYY").valueOf(),
    defaultSortOrder: "ascend",
  },
];

const EmployeeHolidays = () => {
  const [activeView, setActiveView] = useState("calendar");
  const [holidayEvents, setHolidayEvents] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(moment().month() + 1);
  const [currentCalendarMonth, setCurrentCalendarMonth] = useState(
    moment().month() + 1
  );
  const [currentCalendarYear, setCurrentCalendarYear] = useState(
    moment().year()
  );
  const [isLoading, setIsLoading] = useState(false);
  const calendarRef = useRef(null);
  const [allHolidays, setAllHolidays] = useState([]);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [listViewData, setListViewData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(moment().year());

  const handleCalendarMonthChange = useCallback((dateInfo) => {
    const firstDayOfMonth = moment(dateInfo.start);
    const newMonth = firstDayOfMonth.month() + 1;
    const newYear = firstDayOfMonth.year();

    setCurrentCalendarMonth(newMonth);
    setCurrentCalendarYear(newYear);
    setSelectedMonth(newMonth);
  }, []);

  const handleMonthButtonClick = useCallback(() => {
    const currentDate = moment();
    const currentMonth = currentDate.month() + 1;
    const currentYear = currentDate.year();

    setCurrentCalendarMonth(currentMonth);
    setCurrentCalendarYear(currentYear);

    if (calendarRef.current) {
      calendarRef.current.getApi().gotoDate(currentDate.toDate());
    }
  }, []);

  const fetchHolidaysForList = useCallback(async (month, year) => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(
        `${holidays}?month=${month}&year=${year}`
      );
      if (response.data) {
        const formattedData = response.data.map((holiday) => ({
          key: holiday.id,
          id: holiday.id,
          title: holiday.holiday_name,
          start: moment(holiday.date).format("MMM D, YYYY"),
          end: holiday.end_date
            ? moment(holiday.end_date).format("MMM D, YYYY")
            : null,
        }));
        setListViewData(formattedData);
      }
    } catch (error) {
      console.error("Error fetching holidays:", error);
      message.error("Failed to fetch holidays");
    } finally {
      setIsLoading(false);
    }
  }, []);

  const filterHolidaysForCurrentMonth = useCallback(
    (holidays) => {
      if (!holidays || holidays.length === 0) return;

      // Get the first and last day of the current month's view
      const viewStart = moment(
        new Date(currentCalendarYear, currentCalendarMonth - 1, 1)
      )
        .startOf("month")
        .startOf("week");

      const viewEnd = moment(
        new Date(currentCalendarYear, currentCalendarMonth - 1, 1)
      )
        .endOf("month")
        .endOf("week");

      // Format holidays for FullCalendar
      const formattedHolidays = holidays.map((holiday) => ({
        id: holiday.id,
        title: holiday.title,
        start: holiday.start,
        end: holiday.end,
        className: "holiday-event",
        allDay: true,
        extendedProps: {
          ...holiday.extendedProps,
        },
      }));

      setHolidayEvents(formattedHolidays);
    },
    [currentCalendarMonth, currentCalendarYear]
  );

  const fetchHolidays = useCallback(async () => {
    setIsLoading(true);
    try {
      // Get previous, current, and next month
      const previousMonth = moment()
        .year(currentCalendarYear)
        .month(currentCalendarMonth - 1)
        .subtract(1, "month");

      const currentMonth = moment()
        .year(currentCalendarYear)
        .month(currentCalendarMonth - 1);

      const nextMonth = moment()
        .year(currentCalendarYear)
        .month(currentCalendarMonth - 1)
        .add(1, "month");

      const months = [previousMonth, currentMonth, nextMonth];

      const responses = await Promise.all(
        months.map((month) =>
          axiosInstance.get(
            `${holidays}?month=${month.month() + 1}&year=${month.year()}`
          )
        )
      );

      // Merge all holidays and group consecutive dates
      const allHolidaysData = responses
        .flatMap((response) => response.data)
        .sort((a, b) => moment(a.date).diff(moment(b.date)))
        .reduce((groups, holiday) => {
          const currentDate = moment(holiday.date);
          const lastGroup = groups[groups.length - 1];

          if (
            lastGroup &&
            lastGroup.title === holiday.holiday_name &&
            currentDate.diff(moment(lastGroup.end), "days") === 0
          ) {
            lastGroup.end = currentDate.add(1, "days").format("YYYY-MM-DD");
            lastGroup.dates.push(holiday);
          } else {
            groups.push({
              id: holiday.id,
              title: holiday.holiday_name,
              start: currentDate.format("YYYY-MM-DD"),
              end: currentDate.clone().add(1, "days").format("YYYY-MM-DD"),
              className: "holiday-event",
              extendedProps: {
                type: "holiday",
              },
              dates: [holiday],
            });
          }
          return groups;
        }, []);

      setAllHolidays(allHolidaysData);
    } catch (error) {
      console.error("Error fetching holidays:", error);
      message.error("Failed to fetch holidays");
    } finally {
      setIsLoading(false);
    }
  }, [currentCalendarMonth, currentCalendarYear]);

  useEffect(() => {
    if (activeView === "calendar") {
      fetchHolidays();
    }
  }, [fetchHolidays, activeView]);

  useEffect(() => {
    if (allHolidays && allHolidays.length > 0) {
      filterHolidaysForCurrentMonth(allHolidays);
    }
  }, [allHolidays, filterHolidaysForCurrentMonth]);

  const handleViewChange = useCallback(
    (newView) => {
      setActiveView(newView);
      if (newView === "calendar") {
        const currentDate = moment();
        const currentMonth = currentDate.month() + 1;
        const currentYear = currentDate.year();

        // Update all month-related states
        setCurrentCalendarMonth(currentMonth);
        setCurrentCalendarYear(currentYear);
        setSelectedMonth(currentMonth);

        if (calendarRef.current) {
          calendarRef.current.getApi().gotoDate(currentDate.toDate());
        }
        fetchHolidays();
      } else {
        // When switching to list view, use the current calendar month
        setSelectedMonth(currentCalendarMonth);
        fetchHolidaysForList(currentCalendarMonth, currentCalendarYear);
      }
    },
    [
      fetchHolidays,
      fetchHolidaysForList,
      currentCalendarMonth,
      currentCalendarYear,
    ]
  );

  const handleMonthChange = useCallback(
    (month) => {
      setSelectedMonth(month);
      fetchHolidaysForList(month, selectedYear);
    },
    [fetchHolidaysForList, selectedYear]
  );

  const handleYearChange = useCallback(
    (year) => {
      setSelectedYear(year);
      fetchHolidaysForList(selectedMonth, year);
    },
    [selectedMonth, fetchHolidaysForList]
  );

  const eventContent = useCallback((eventInfo) => {
    const event = eventInfo.event;
    const endDate = moment(event.end).subtract(1, "day");
    const startDate = moment(event.start);
    const isMultiDay = !startDate.isSame(endDate, "day");

    const handleClick = (e) => {
      e.stopPropagation();
      setSelectedHoliday({
        title: event.title,
        startDate,
        endDate,
        isMultiDay,
      });
    };

    return (
      <div className="holiday-event-wrapper" onClick={handleClick}>
        <div className="holiday-event-content">
          <span className="holiday-dot"></span>
          <span className="holiday-name">{event.title}</span>
        </div>
      </div>
    );
  }, []);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="card mb-0">
          <div className="card-body pt-4">
            <div className="page-header mb-3">
              <div className="row align-items-center">
                <div className="col-12 col-md-6 mb-3 mb-md-0">
                  <h3 className="page-title">Holidays</h3>
                </div>
                <div className="col-12 col-md-6">
                  <div className="d-flex justify-content-md-end">
                    <Tabs
                      defaultActiveKey="calendar"
                      className="view-toggle holiday-tabs"
                      onChange={handleViewChange}
                      size="middle"
                      items={[
                        {
                          key: "calendar",
                          label: (
                            <span className="tab-label">
                              <CalendarOutlined /> Calendar
                            </span>
                          ),
                        },
                        {
                          key: "list",
                          label: (
                            <span className="tab-label">
                              <UnorderedListOutlined /> List
                            </span>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="card-body pt-3">
              <div className="mb-4">
                {activeView === "list" && (
                  <Space wrap>
                    <MonthSelector
                      value={selectedMonth}
                      onChange={handleMonthChange}
                    />
                    <Select
                      value={selectedYear}
                      onChange={handleYearChange}
                      style={{ width: 100 }}
                      options={YEARS}
                    />
                  </Space>
                )}
              </div>

              {activeView === "calendar" ? (
                <Spin spinning={isLoading} tip="Loading holidays...">
                  <div className="calendar-wrapper">
                    <FullCalendar
                      ref={calendarRef}
                      plugins={[
                        dayGridPlugin,
                        timeGridPlugin,
                        interactionPlugin,
                      ]}
                      headerToolbar={{
                        left: "prev,next today",
                        center: "title",
                        right: "month",
                      }}
                      customButtons={{
                        month: {
                          text: "month",
                          click: handleMonthButtonClick,
                        },
                      }}
                      initialView="dayGridMonth"
                      editable={false}
                      selectable={false}
                      selectMirror={true}
                      dayMaxEvents={true}
                      weekends={true}
                      events={holidayEvents}
                      datesSet={handleCalendarMonthChange}
                      eventClassNames="holiday-event"
                      eventColor="#dc3545"
                      eventTextColor="#ffffff"
                      eventDisplay="block"
                      displayEventEnd={true}
                      eventTimeFormat={{
                        hour: "numeric",
                        minute: "2-digit",
                        meridiem: "short",
                      }}
                      initialDate={moment()
                        .year(currentCalendarYear)
                        .month(currentCalendarMonth - 1)
                        .startOf("month")
                        .format("YYYY-MM-DD")}
                      dayCellClassNames={(data) =>
                        moment(data.date).day() === 0 ||
                        moment(data.date).day() === 6
                          ? "weekend-day"
                          : ""
                      }
                      dayHeaderClassNames={(data) =>
                        moment(data.date).day() === 0 ||
                        moment(data.date).day() === 6
                          ? "weekend-header"
                          : ""
                      }
                      eventContent={eventContent}
                    />
                  </div>
                </Spin>
              ) : (
                <div className="table-responsive">
                  <Table
                    className="events-table"
                    columns={columns}
                    dataSource={listViewData}
                    pagination={false}
                    loading={isLoading}
                    locale={{
                      emptyText: "No holidays found for selected month",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <style jsx>{`
        .card-body {
          padding: 1.5rem;
        }

        .calendar-wrapper {
          margin-top: 1rem;
          overflow-x: auto;
        }

        .fc {
          min-height: 400px;
        }

        .fc-toolbar {
          flex-wrap: wrap;
          gap: 0.5rem;
        }

        .fc-toolbar-chunk {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        .fc-header-toolbar {
          margin-bottom: 1rem !important;
          padding: 0.5rem;
        }

        @media (max-width: 768px) {
          .fc-toolbar {
            justify-content: center;
          }

          .fc-toolbar-title {
            font-size: 1.2rem !important;
            text-align: center;
            width: 100%;
          }

          .fc-header-toolbar {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;
          }

          .fc-toolbar-chunk {
            justify-content: center;
            width: 100%;
          }

          .fc-view-harness {
            height: auto !important;
          }

          .fc-daygrid-day-events {
            min-height: auto !important;
          }
        }

        .holiday-event-wrapper {
          padding: 2px 4px;
          cursor: pointer;
        }

        .holiday-event-content {
          display: flex;
          align-items: center;
          gap: 4px;
          font-size: 12px;
        }

        .holiday-dot {
          width: 6px;
          height: 6px;
          background-color: #ffffff;
          border-radius: 50%;
        }

        .holiday-name {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .weekend-day {
          background-color: #f5f5f5;
        }

        .weekend-header {
          color: #dc3545;
        }

        .holiday-tabs {
          margin-bottom: 0;
        }

        .tab-label {
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }

        @media (max-width: 576px) {
          .fc-daygrid-day-number {
            font-size: 0.875rem;
          }

          .holiday-event-content {
            font-size: 10px;
          }
        }
      `}</style>

      {selectedHoliday && (
        <Modal
          title="Holiday Details"
          open={!!selectedHoliday}
          onCancel={() => setSelectedHoliday(null)}
          footer={null}
          styles={{
            header: {
              borderBottom: "1px solid #f0f0f0",
              padding: "16px 24px",
            },
            body: {
              padding: "24px",
            },
          }}
        >
          <div className="holiday-details">
            <h3>{selectedHoliday.title}</h3>
            <p className="holiday-date">
              {selectedHoliday.isMultiDay ? (
                <>
                  {selectedHoliday.startDate.format("DD MMM")} -{" "}
                  {selectedHoliday.endDate.format("DD MMM YYYY")}
                </>
              ) : (
                selectedHoliday.startDate.format("DD MMM YYYY")
              )}
            </p>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default EmployeeHolidays;
