import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { axiosInstance } from "../../../helpers/axios/axios";
import {
  namingList,
  nameShareApi,
  basicUserDetails,
} from "../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { Button, Form, Modal } from "react-bootstrap";
import "./DocumentNaming.css";

const DocumentNaming = () => {
  const [personName, setPersonName] = useState();
  const [dateValue, setDate] = useState();
  const [nameLists, setNameLists] = useState([]);
  const [newName, setNewName] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null); // State to store the selected item

  const handleClose = () => setShow(false);
  const handleShow = (item) => {
    setSelectedItem(item); // Set the selected item when the button is clicked
    setShow(true);
  };

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`${namingList}`);
      console.log("Naming List", response.data);
      const documents = response.data.flatMap((array) => array); // Flatten the nested arrays

      const details = await Promise.all(
        documents.map(async (item) => {
          console.log("items", item);
          const userDetail = await fetchUserDetail(
            item.document_detail.uploaded_by
          );
          console.log(userDetail);

          return {
            ...item,

            userDetail: userDetail,
          };
        })
      );
      console.log(details);
      setNameLists(details);
    } catch (error) {
      console.error("Error fetching document list:", error);
    }
  };

  useEffect(() => {
    fetchData(); // Call fetchData initially
  }, []);

  const fetchUserDetail = async (id) => {
    console.log("userid", id);
    try {
      const response = await axiosInstance.get(`${basicUserDetails}${id}/`);
      return response.data;
    } catch (error) {
      console.error("Error fetching document detail:", error);
      return null;
    }
  };

  const handleRenameInput = (e) => {
    console.log(e.target.value);
    setNewName(e.target.value);
  };
  const handlePersonNameInput = (e) => {
    console.log(e.target.value);
    setPersonName(e.target.value);
  };
  const handleDateInput = (e) => {
    console.log(e.target.value);
    setDate(e.target.value);
  };
  const documentNamingData = {
    shared_name: `${personName}-${newName}-${dateValue}`,
  };
  console.log(documentNamingData);
  const renameHandler = (data) => {
    axiosInstance
      .patch(`${nameShareApi}${data.id}/`, {
        documentNamingData,
        is_done: "True",
      })
      .then((response) => {
        console.log(response.data);
        toast.success("Successfully renamed");
        handleClose();
        setNewName("");
        // Refresh data after renaming
        fetchData();
        setDate("");
        setPersonName("");
        setNewName("");
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.shared_name[0]) {
          toast.error("Please fill out the name");
        }
      });
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Document Naming"
          title="Documents"
          subtitle="Document naming"
        />
        <table className="table table-striped text-center">
          <thead className="table-secondary">
            <tr>
              <th>ID</th>
              <th>Document Name</th>
              <th>Uploaded By</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {nameLists.length > 0 ? (
              nameLists.map((item, index) => (
                <tr key={item.id}>
                  <td>{index + 1}</td>
                  <td>{item?.document_detail?.file_name || "Loading..."}</td>
                  <td>{item?.userDetail.employee_name || "Loading..."}</td>
                  <td>
                    <button
                      variant="primary"
                      onClick={() => handleShow(item)} // Pass item to handleShow
                      className="btn btn-primary btn-sm ms-1"
                    >
                      <i className="fa fa-pen me-1" />
                      Add Name
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">No items</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        dialogClassName="modal-90w modal-fullscreen"
        aria-labelledby="example-custom-modal-styling-title"
        show={show}
        onHide={handleClose}
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Name</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedItem && (
            <div className="d-flex mx-auto justify-content-evenly ">
              <div>
                file name: {selectedItem.document_detail?.file_name}
                <iframe
                  title="Document"
                  src={selectedItem.document_detail?.uploaded_file}
                  width="100%"
                  height="500px"
                />
              </div>
              <form>
                <div className="mb-3">
                  <label
                    htmlFor="message-text"
                    className="col-form-label text-xl"
                  >
                    Document name
                  </label>
                  <input
                    onChange={(e) => handleRenameInput(e)}
                    className="form-control"
                    id="message-text"
                  />
                </div>

                <div className="mb-3">
                  <label
                    htmlFor="message-text"
                    className="col-form-label text-xl"
                  >
                    person name/ company name
                  </label>
                  <input
                    onChange={(e) => handlePersonNameInput(e)}
                    className="form-control"
                    id="message-text"
                  />
                </div>
                <div className="mb-3">
                  <label
                    htmlFor="message-text"
                    className="col-form-label text-xl"
                  >
                    Expiry/ issue date [date]
                  </label>
                  <input
                    type="date"
                    onChange={(e) => handleDateInput(e)}
                    className="form-control"
                    id="message-text"
                  />
                </div>
                <Button
                  variant="primary"
                  onClick={() => renameHandler(selectedItem)}
                >
                  Save Changes
                </Button>
              </form>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-start">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default DocumentNaming;
