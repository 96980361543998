import React, { useState } from "react";
import {
  Row,
  Col,
  Table,
  Typography,
  Button,
  Card,
  Select,
  Radio,
  Space,
  Modal,
  Input,
  Upload,
} from "antd";
import { CiFilter } from "react-icons/ci";
import { MdFileUpload } from "react-icons/md";

const { Title, Text } = Typography;
const { Option } = Select;

const JobsApplied = () => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [jobTitle, setJobTitle] = useState(null);
  const [status, setStatus] = useState(null);
  const [dateOrder, setDateOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isProjectModalVisible, setIsProjectModalVisible] = useState(false);
  const columns = [
    { title: "Applied Jobs", dataIndex: "job", key: "job" },
    { title: "Applied Date", dataIndex: "date", key: "date" },
    {
      title: "Application Form",
      dataIndex: "form",
      key: "form",
      render: () => (
        <Button onClick={() => setIsModalVisible(true)}>View</Button>
      ),
    },
    {
      title: "Project Submission",
      dataIndex: "submission",
      key: "submission",
      render: () => (
        <Button
          onClick={() => setIsProjectModalVisible(true)}
          style={{ background: "#FF6B6B", color: "white", border: "none" }}
        >
          Add
        </Button>
      ),
    },
    { title: "Status", dataIndex: "status", key: "status" },
  ];

  const dataSource = [
    {
      key: "1",
      job: "Frontend Developer",
      date: "15-03-2025",
      form: "View",
      submission: "Add",
      status: "Under Review",
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Title level={2}>Jobs Applied</Title>

        <Button
          icon={<CiFilter />}
          style={{ marginBottom: 10 }}
          onClick={() => setIsFilterVisible(!isFilterVisible)}
        >
          Filter
        </Button>

        {isFilterVisible && (
          <Card style={{ marginBottom: 20, width: "600px" }}>
            <Title level={5}>Applied Job Title</Title>
            <Select
              placeholder="Select"
              style={{ width: "100%", marginBottom: 10 }}
              onChange={setJobTitle}
            >
              <Option value="frontend">Frontend Developer</Option>
              <Option value="backend">Backend Developer</Option>
              <Option value="designer">UI/UX Designer</Option>
            </Select>

            <Title level={5}>Status</Title>
            <Radio.Group onChange={(e) => setStatus(e.target.value)}>
              <Space direction="vertical">
                <Radio value="hired">Hired</Radio>
                <Radio value="shortlisted">Shortlisted</Radio>
                <Radio value="applied">Applied</Radio>
                <Radio value="project_in_view">Project in View</Radio>
                <Radio value="interview_scheduled">Interview Scheduled</Radio>
                <Radio value="rejected">Rejected</Radio>
              </Space>
            </Radio.Group>

            <Title level={5} style={{ marginTop: 10 }}>
              Applied Date
            </Title>
            <Radio.Group onChange={(e) => setDateOrder(e.target.value)}>
              <Space direction="vertical">
                <Radio value="newest">Newest to Oldest</Radio>
                <Radio value="oldest">Oldest to Newest</Radio>
              </Space>
            </Radio.Group>

            <div style={{ marginTop: 20, textAlign: "right" }}>
              <Button
                style={{ marginRight: 10 }}
                onClick={() => {
                  setJobTitle(null);
                  setStatus(null);
                  setDateOrder(null);
                }}
              >
                Reset
              </Button>
              <Button type="primary">Search</Button>
            </div>
          </Card>
        )}

        <Table dataSource={dataSource} columns={columns} pagination={false} />
        <Modal
          title="Application Form"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
          centered
          width={900}
        >
          <Text>
            One final step in your job application! Please complete these
            questions to help us gain a deeper understanding of you.
          </Text>
          <br />
          <br />
          <Row gutter={24}>
            <Col span={8}>
              <Title level={5}>
                Are you okay with the Salary mentioned in the Ad?*
              </Title>
              <Select placeholder="Select" style={{ width: "100%" }}>
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Col>
            <Col span={8}>
              <Title level={5}>
                Currently, are you engaged in any studies?*
              </Title>
              <Select placeholder="Select" style={{ width: "100%" }}>
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Col>
          </Row>
          <br />

          <div style={{ display: "flex", gap: "20px" }}>
            <Col span={8}>
              <Title level={5}>How soon you can join?*</Title>
              <Select placeholder="Select" style={{ width: "100%" }}>
                <Option value="immediately">Immediately</Option>
                <Option value="1_week">In 1 week</Option>
                <Option value="1_month">In 1 month</Option>
              </Select>
            </Col>

            <Col span={16}>
              <Title level={5}>
                What is your maximum experience in this field? [In years or
                months]*
              </Title>
              <div style={{ display: "flex", gap: "10px" }}>
                <Select placeholder="Years" style={{ width: "100%" }}>
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4+">4+</Option>
                </Select>
                <Select placeholder="Months" style={{ width: "100%" }}>
                  <Option value="0">0</Option>
                  <Option value="1">1</Option>
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="6">6</Option>
                </Select>
              </div>
            </Col>
          </div>

          <br />
          <Row gutter={16}>
            <Col span={12}>
              <Title level={5}>
                Will you be able to complete the project and present it?
              </Title>
              <Select placeholder="Select" style={{ width: "100%" }}>
                <Option value="yes">Yes</Option>
                <Option value="no">No</Option>
              </Select>
            </Col>
          </Row>
          <br />
          <div style={{ textAlign: "center" }}>
            <Button
              type="primary"
              style={{ background: "#FF6B6B", border: "none" }}
            >
              Submit
            </Button>
          </div>
        </Modal>

        <Modal
          title="Project Submission"
          visible={isProjectModalVisible}
          onCancel={() => setIsProjectModalVisible(false)}
          footer={null}
          centered
          width={500}
        >
          <Text>
            Job Applied Grid / <b>Project Submission</b>
          </Text>
          <br />
          <br />
          <Row gutter={16}>
            <Col span={16}>
              <Title level={5}>Add the project Link</Title>

              <div style={{ display: "flex", gap: "10px" }}>
                <Input
                  placeholder="Enter project link"
                  style={{ width: "100%" }}
                />
                <Upload>
                  <Button icon={<MdFileUpload />} />
                </Upload>
              </div>
            </Col>
          </Row>
          <br />
          <Row gutter={16}>
            <Col span={16}>
              <Title level={5}>Upload the Project</Title>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div style={{ display: "flex", gap: "10px" }}>
                  <Input placeholder="Choose file" style={{ width: "100%" }} />

                  <Upload>
                    <Button icon={<MdFileUpload />} />
                  </Upload>
                </div>
                <div style={{}}>
                  <Button
                    type="primary"
                    style={{ background: "#1E2B32", border: "none" }}
                  >
                    Upload
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Modal>
      </div>
    </div>
  );
};

export default JobsApplied;
