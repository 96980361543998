import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Form,
  Input,
  Button,
  Spin,
  Typography,
  Select,
  Row,
  Col,
  Divider,
  Descriptions,
} from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  predecessorField,
  predecessorElement,
  employeeDetails,
  assignedJob,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Title, Text } = Typography;
const { Option } = Select;

const PDElement = () => {
  const { id, jobId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [pdFieldDetails, setPdFieldDetails] = useState(null);
  const [employeeId, setEmployeeId] = useState(null);
  const [elementDetails, setElementDetails] = useState(null);
  const [isEditable, setIsEditable] = useState(false);

  const fetchPDFieldDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${predecessorField}${id}/`);
      setPdFieldDetails(response.data);
    } catch (error) {
      console.error("Error fetching Predecessor Field details:", error);
      toast.error("Failed to fetch Predecessor Field details");
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  const fetchElementDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${predecessorElement}?predecessor=${id}`
      );
      if (response.data && response.data.length > 0) {
        setElementDetails(response.data[0]);
        form.setFieldsValue(response.data[0]);
        setIsEditable(response.data[0].status === "TC pending");
      } else {
        setIsEditable(true);
        setElementDetails(null);
      }
    } catch (error) {
      console.error("Error fetching Predecessor Element details:", error);
      toast.error("Failed to fetch Predecessor Element details");
      setIsEditable(true);
      setElementDetails(null);
    }
  }, [id, form]);

  useEffect(() => {
    fetchPDFieldDetails();
    fetchEmployeeDetails();
    fetchElementDetails();
  }, [fetchPDFieldDetails, fetchElementDetails]);

  const onFinish = async (values) => {
    setSubmitting(true);
    const toastId = toast.loading("Processing...");

    try {
      if (elementDetails) {
        const changedValues = {};
        Object.keys(values).forEach((key) => {
          if (values[key] !== elementDetails[key]) {
            changedValues[key] = values[key];
          }
        });

        if (Object.keys(changedValues).length > 0) {
          await axiosInstance.patch(
            `${predecessorElement}${elementDetails.id}/`,
            changedValues
          );
          setElementDetails({ ...elementDetails, ...changedValues });
          toast.success("Predecessor Element updated successfully");
        } else {
          toast.info("No changes detected");
        }
      } else {
        const payload = {
          ...values,
          assignee: employeeId,
          predecessor: id,
          document_status: "NA",
        };
        const response = await axiosInstance.post(predecessorElement, payload);
        setElementDetails(response.data);
        if (jobId) {
          await axiosInstance.patch(`${assignedJob}${jobId}/`, {
            status: "Completed",
          });
        }
        toast.success("Predecessor Element submitted successfully");
      }
    } catch (error) {
      console.error("Error submitting Predecessor Element:", error);
      toast.error("Failed to submit Predecessor Element");
    } finally {
      setSubmitting(false);
      toast.dismiss(toastId);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <Card
              title={
                <Title level={4}>
                  PREDECESSOR ELEMENT FOR:{" "}
                  <Text type="secondary">
                    {pdFieldDetails?.field_name || "N/A"}
                  </Text>
                </Title>
              }
            >
              <Descriptions bordered column={2} style={{ marginBottom: 24 }}>
                <Descriptions.Item label="End Document" span={2}>
                  <Text strong>
                    {pdFieldDetails?.ed_field_details?.end_document_details
                      ?.end_name || "N/A"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="End Field">
                  <Text>
                    {pdFieldDetails?.ed_field_details?.field_name || "N/A"}
                  </Text>
                </Descriptions.Item>
                <Descriptions.Item label="Predecessor Field">
                  <Text>{pdFieldDetails?.field_name || "N/A"}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Track">
                  <Text>{pdFieldDetails?.track_details?.name || "N/A"}</Text>
                </Descriptions.Item>
                <Descriptions.Item label="Department">
                  <Text>
                    {pdFieldDetails?.track_details?.department_details?.name ||
                      "N/A"}
                  </Text>
                </Descriptions.Item>
              </Descriptions>

              <Form form={form} onFinish={onFinish} layout="vertical">
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="assembly_line"
                      label="Assembly Line"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="end_document_number"
                      label="End Document Number"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="field_code_number"
                      label="Field Code Number"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="source_document"
                      label="Source Document"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="action_sourcing"
                      label="Action Sourcing"
                      rules={[{ required: true }]}
                    >
                      <Select disabled={!isEditable}>
                        <Option value="Automation">Automation</Option>
                        <Option value="Outsource">Outsource</Option>
                        <Option value="In house">In house</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>

                <Form.Item
                  name="actions"
                  label="Actions"
                  rules={[{ required: true }]}
                >
                  <Input.TextArea rows={4} disabled={!isEditable} />
                </Form.Item>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="compliance"
                      label="Compliance"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="cost"
                      label="Cost"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="revenue"
                      label="Revenue"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation="left">Job Details</Divider>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="job_acceptance"
                      label="Job Acceptance"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="job_initiation"
                      label="Job Initiation"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="job_completion"
                      label="Job Completion"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="total_execution_time"
                      label="Total Execution Time"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="average_time"
                      label="Average Time"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="database"
                      label="Database"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation="left">Additional Information</Divider>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="accuracy_check"
                      label="Accuracy Check"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="notification"
                      label="Notification"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="incoming_data"
                      label="Incoming Data"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea rows={4} disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="outgoing_data"
                      label="Outgoing Data"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea rows={4} disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="data_field_format"
                      label="Data Field Format"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="metadata"
                      label="Metadata"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea rows={4} disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider orientation="left">
                  Input and Output Information
                </Divider>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="input_data"
                      label="Input Data"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea rows={4} disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="output_data"
                      label="Output Data"
                      rules={[{ required: true }]}
                    >
                      <Input.TextArea rows={4} disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="input_source"
                      label="Input Source"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="output_source"
                      label="Output Source"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="input_field_number"
                      label="Input Field Number"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="output_field_number"
                      label="Output Field Number"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="document_status"
                      label="Document Status"
                      rules={[{ required: true }]}
                    >
                      <Select disabled={!isEditable}>
                        <Option value="NA">NA</Option>
                        <Option value="Not Ready">Not Ready</Option>
                        <Option value="Collection Required">
                          Collection Required
                        </Option>
                        <Option value="Uploading Required">
                          Uploading Required
                        </Option>
                        <Option value="Initiating Track Required">
                          Initiating Track Required
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider orientation="left">
                  Requirements and Permissions
                </Divider>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="skills_required"
                      label="Skills Required"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="triggers"
                      label="Triggers"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="permissions"
                      label="Permissions"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item
                      name="responsible_department"
                      label="Responsible Department"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="branch"
                      label="Branch"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name="system_action"
                      label="System Action"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item
                      name="action_code"
                      label="Action Code"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="events"
                      label="Events"
                      rules={[{ required: true }]}
                    >
                      <Input disabled={!isEditable} />
                    </Form.Item>
                  </Col>
                </Row>

                {(isEditable || !elementDetails) && (
                  <Form.Item>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={submitting}
                      size="large"
                    >
                      {elementDetails
                        ? "Update Predecessor Element"
                        : "Submit Predecessor Element"}
                    </Button>
                  </Form.Item>
                )}
              </Form>
            </Card>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default PDElement;
