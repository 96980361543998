import React, { useState } from "react";
import { Form, Button, Upload, Row, Col, Typography, Card } from "antd";
import { MdOutlineFileUpload } from "react-icons/md";

const { Text } = Typography;

const DocumentUpload = () => {
  const [fileList, setFileList] = useState([]);

  const handleUpload = ({ fileList }) => setFileList(fileList);

  return (
    <Form layout="vertical" style={{ maxWidth: "90%", margin: "auto" }}>
      <Card style={{ marginBottom: 20 }}>
        <Text strong>
          Please ensure that the document is properly uploaded by following
          these guidelines:
        </Text>
        <ul>
          <li>File Type: Only PDF, DOCX formats are accepted.</li>
          <li>File Size: The document should not exceed 10MB.</li>
          <li>File Integrity: Ensure the document is not corrupted.</li>
          <li>Naming Convention: Use a clear and relevant filename.</li>
        </ul>
      </Card>
      <Row gutter={24}>
        {[
          "Passport Copy",
          "National ID Card",
          "Formal Photograph",
          "Proof of Address",
          "Educational Documents",
          "Scanned copies of your previous employment",
          "Salary Slip",
          "Bank Passbook",
          "Current employer resignation approval letter/email",
        ].map((label, index) => (
          <Col xs={12} md={6} key={index}>
            <Form.Item label={label}>
              <Upload
                beforeUpload={(file) => {
                  setFileList([...fileList, file]);
                  return false; // Prevent automatic upload
                }}
                fileList={fileList}
              >
                <Button
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: 4,
                    marginTop: 5,
                  }}
                >
                  <MdOutlineFileUpload style={{ margin: 0 }} />
                  Upload
                </Button>
              </Upload>
            </Form.Item>
          </Col>
        ))}
      </Row>
      <Text
        strong
        style={{
          background: "#1D2B2D",
          color: "white",
          padding: "6px 12px",
          borderRadius: "4px",
          display: "inline-block",
          marginBottom: "12px",
          marginTop: "20px",
        }}
      >
        Documents Required only for UAE Employee
      </Text>
      <Row gutter={24}>
        {[
          "Driving License",
          "Visa",
          "UAE ID card",
          "Insurance card",
          "Emirates ID",
          "Employment Card",
        ].map((label, index) => (
          <Col xs={12} md={6} key={index}>
            <Text>{label}</Text>
            <Upload
              beforeUpload={(file) => {
                setFileList([...fileList, file]);
                return false; // Prevent automatic upload
              }}
              fileList={fileList}
            >
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 4,
                  marginTop: 5,
                }}
              >
                <MdOutlineFileUpload style={{ margin: 0 }} />
                Upload
              </Button>
            </Upload>
          </Col>
        ))}
      </Row>
    </Form>
  );
};

export default DocumentUpload;
