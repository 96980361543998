import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Container,
  Upload,
} from "react-bootstrap";
import {
  Table,
  Typography,
  Tag,
  DatePicker,
  Spin,
  message,
  Tabs,
  Select,
} from "antd";
import { FaUpload, FaExternalLinkAlt } from "react-icons/fa";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  separation,
  handover,
  employeeList,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import Header from "../../../layout/Header";
import ViewHandoverForm from "../../Employees/EmployeeResignation/ViewHandoverForm";
import HandoverChecklist from "./checkListHR";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const getStatusColor = (status) => {
  switch (status) {
    case "Submission":
      return "orange";
    case "Reviewed":
      return "cyan";
    case "Handover":
      return "blue";
    case "Handover Approval":
      return "purple";
    case "Approved":
      return "green";
    case "Rejected":
      return "red";
    default:
      return "default";
  }
};

const ViewModal = React.memo(
  ({
    show,
    onHide,
    resignation,
    employees = [],
    onSubmit,
    activeTabKey,
    setActiveTabKey,
    refreshTable,
  }) => {
    const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
    const [lastWorkingDate, setLastWorkingDate] = useState(null);
    const [submitting, setSubmitting] = useState(false);
    const [acceptanceLetter, setAcceptanceLetter] = useState(null);
    const [experienceLetter, setExperienceLetter] = useState(null);
    const [handoverData, setHandoverData] = useState(null);

    useEffect(() => {
      console.log("Resignation data:", resignation);
      if (resignation) {
        fetchHandoverData(resignation.id);
      }
    }, [resignation]);

    useEffect(() => {
      if (!show) {
        setSelectedTeamLeader(null);
      }
    }, [show]);

    const fetchHandoverData = async (separationId) => {
      try {
        const response = await axiosInstance.get(
          `${handover}?separation=${separationId}`
        );
        setHandoverData(response.data.results[0]);
      } catch (error) {
        console.error("Error fetching handover data:", error);
        toast.error("Failed to fetch handover data");
      }
    };

    const handleFileChange = (e, type) => {
      const file = e.target.files[0];
      if (file) {
        if (file.type === "application/pdf") {
          if (type === "acceptance") {
            setAcceptanceLetter(file);
          } else if (type === "experience") {
            setExperienceLetter(file);
          }
        } else {
          toast.error("Please upload a PDF file");
          e.target.value = null;
        }
      }
    };

    const handleExperienceLetterUpload = async () => {
      if (!experienceLetter) {
        toast.error("Please select a file to upload");
        return;
      }

      setSubmitting(true);
      try {
        const formData = new FormData();
        formData.append("experience_letter", experienceLetter);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        await axiosInstance.patch(
          `${separation}${resignation.id}/`,
          formData,
          config
        );

        toast.success("Experience letter uploaded successfully");
        refreshTable();
        setExperienceLetter(null);
      } catch (error) {
        console.error("Error uploading experience letter:", error);
        toast.error("Failed to upload experience letter");
      } finally {
        setSubmitting(false);
      }
    };

    const handleSubmit = async () => {
      if (!lastWorkingDate) {
        toast.error("Please select a last working date");
        return;
      }

      if (!acceptanceLetter) {
        toast.error("Please upload acceptance letter");
        return;
      }

      setSubmitting(true);
      try {
        const formData = new FormData();
        formData.append("status", "Handover");
        formData.append(
          "last_working_date",
          lastWorkingDate.format("YYYY-MM-DD")
        );
        formData.append("acceptance_letter", acceptanceLetter);

        await onSubmit(formData);
      } finally {
        setSubmitting(false);
      }
    };

    const handleApproveHandover = async () => {
      if (!handoverData) return;

      try {
        await axiosInstance.patch(`${handover}${handoverData.id}/`, {
          is_hr_approved: true,
        });

        await axiosInstance.patch(`${separation}${resignation.id}/`, {
          status: "Handover Approval",
        });

        toast.success("Handover approved successfully");
        onHide();
        refreshTable();
      } catch (error) {
        console.error("Error approving handover:", error);
        toast.error("Failed to approve handover");
      }
    };

    return (
      <Modal show={show} onHide={onHide} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Resignation Details
            {resignation && (
              <Tag
                color={getStatusColor(resignation.status)}
                style={{ marginLeft: "10px" }}
              >
                {resignation.status}
              </Tag>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resignation ? (
            <Tabs
              activeKey={activeTabKey}
              onChange={(key) => setActiveTabKey(key)}
              style={{ marginTop: -15 }}
            >
              <TabPane tab="Resignation Letter" key="1">
                <Spin spinning={submitting} tip="Processing...">
                  <Typography>
                    <Title level={4}>
                      {resignation.user_details.employee_name}'s Resignation
                    </Title>

                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        marginBottom: "20px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Paragraph style={{ margin: 0 }}>
                        <strong>Department:</strong>{" "}
                        {
                          resignation.user_details.employment_details[0]
                            .department_detail.name
                        }
                      </Paragraph>

                      <Paragraph style={{ margin: 0 }}>
                        <strong>Designation:</strong>{" "}
                        {
                          resignation.user_details.employment_details[0]
                            .designation_detail.name
                        }
                      </Paragraph>

                      <Paragraph style={{ margin: 0 }}>
                        <strong>Submitted Date:</strong>{" "}
                        {moment(resignation.created_at).format("DD-MM-YYYY")}
                      </Paragraph>

                      {resignation.last_working_date && (
                        <Paragraph style={{ margin: 0 }}>
                          <strong>Last Working Date:</strong>{" "}
                          {moment(resignation.last_working_date).format(
                            "DD-MM-YYYY"
                          )}
                        </Paragraph>
                      )}
                    </div>

                    <Title level={5}>Resignation Letter:</Title>
                    <Paragraph style={{ whiteSpace: "pre-line" }}>
                      {resignation.resignation}
                    </Paragraph>

                    {resignation.signature && (
                      <>
                        <Title level={5}>Signature:</Title>
                        <div
                          style={{
                            maxWidth: "200px",
                            border: "1px solid #f0f0f0",
                            padding: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            src={resignation.signature}
                            alt="Signature"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </>
                    )}
                    {resignation?.remarks && (
                      <Paragraph style={{ marginTop: "10px" }}>
                        <strong>Rejection Reason:</strong> {resignation.remarks}
                      </Paragraph>
                    )}
                    {resignation.handover_details && (
                      <div className="mt-4">
                        <Title level={5}>Person Taking Over:</Title>
                        <div className="border rounded p-3">
                          <Paragraph>
                            <strong>Name:</strong>{" "}
                            {resignation.handover_details.employee_name}
                          </Paragraph>
                          <Paragraph>
                            <strong>Department:</strong>{" "}
                            {
                              resignation.handover_details.employment_details[0]
                                ?.department_detail?.name
                            }
                          </Paragraph>
                          <Paragraph>
                            <strong>Designation:</strong>{" "}
                            {
                              resignation.handover_details.employment_details[0]
                                ?.designation_detail?.name
                            }
                          </Paragraph>
                        </div>
                      </div>
                    )}

                    {resignation.acceptance_letter && (
                      <div className="mt-3">
                        <Title level={5}>Acceptance Letter:</Title>
                        <Button
                          variant="outline-primary"
                          onClick={() =>
                            window.open(resignation.acceptance_letter, "_blank")
                          }
                          className="d-flex align-items-center gap-2"
                        >
                          <FaExternalLinkAlt /> View Acceptance Letter
                        </Button>
                      </div>
                    )}

                    {resignation.status === "Approved" && (
                      <div className="mt-4">
                        <Title level={5}>Experience Letter:</Title>
                        {resignation.experience_letter && (
                          <div className="mb-3">
                            <Button
                              variant="outline-primary"
                              onClick={() =>
                                window.open(
                                  resignation.experience_letter,
                                  "_blank"
                                )
                              }
                              className="d-flex align-items-center gap-2"
                            >
                              <FaExternalLinkAlt /> View Experience Letter
                            </Button>
                          </div>
                        )}

                        <Form.Group>
                          <Form.Label>
                            <strong>
                              {resignation.experience_letter
                                ? "Re-upload Experience Letter"
                                : "Upload Experience Letter"}
                            </strong>
                          </Form.Label>
                          <div className="d-flex align-items-center gap-2">
                            <Form.Control
                              type="file"
                              accept=".pdf"
                              onChange={(e) =>
                                handleFileChange(e, "experience")
                              }
                              className="me-2"
                            />
                            <Button
                              variant="primary"
                              onClick={handleExperienceLetterUpload}
                              disabled={!experienceLetter || submitting}
                              style={{ flexShrink: 0 }}
                            >
                              {submitting ? (
                                <>
                                  <span
                                    className="spinner-border spinner-border-sm me-2"
                                    role="status"
                                    aria-hidden="true"
                                  ></span>
                                  Uploading...
                                </>
                              ) : resignation.experience_letter ? (
                                "Re-upload"
                              ) : (
                                "Upload"
                              )}
                            </Button>
                          </div>
                          <Form.Text className="text-muted">
                            Please upload experience letter in PDF format
                          </Form.Text>
                        </Form.Group>
                      </div>
                    )}

                    {resignation.status === "Reviewed" && (
                      <div className="mt-4">
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <strong>Last Working Date</strong>
                          </Form.Label>
                          <DatePicker
                            style={{ width: "100%" }}
                            onChange={(date) => setLastWorkingDate(date)}
                            disabledDate={(current) =>
                              current && current < moment().endOf("day")
                            }
                            format="DD-MM-YYYY"
                          />
                        </Form.Group>

                        <Form.Group className="mb-3">
                          <Form.Label>
                            <strong>Upload Acceptance Letter</strong>
                          </Form.Label>
                          <div className="d-flex align-items-center">
                            <Form.Control
                              type="file"
                              accept=".pdf"
                              onChange={(e) =>
                                handleFileChange(e, "acceptance")
                              }
                              className="me-2"
                            />
                            {acceptanceLetter && (
                              <span className="text-success">
                                ✓ {acceptanceLetter.name}
                              </span>
                            )}
                          </div>
                          <Form.Text className="text-muted">
                            Please upload acceptance letter in PDF format
                          </Form.Text>
                        </Form.Group>
                      </div>
                    )}

                    {resignation.status === "Submission" &&
                      resignation.is_reviewed === false && (
                        <div className="mt-4">
                          <Title level={5}>Assign Team Leader</Title>
                          <Form.Group>
                            <Form.Label>
                              <strong>Select Team Leader</strong>
                            </Form.Label>
                            <div>
                              <Select
                                showSearch
                                style={{ width: "100%" }}
                                placeholder="Search and select team leader"
                                optionFilterProp="children"
                                onChange={(value) =>
                                  setSelectedTeamLeader(value)
                                }
                                filterOption={(input, option) =>
                                  option?.label
                                    ?.toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                options={
                                  Array.isArray(employees)
                                    ? employees.map((emp) => ({
                                        value: emp.id,
                                        label: `${emp.employee_name} - ${
                                          emp.employment_details[0]
                                            ?.department_detail?.name || ""
                                        } - ${
                                          emp.employment_details[0]
                                            ?.designation_detail?.name || ""
                                        }`,
                                      }))
                                    : []
                                }
                              />
                              <Button
                                variant="primary"
                                className="mt-3"
                                disabled={!selectedTeamLeader}
                                onClick={() => {
                                  const formData = new FormData();
                                  formData.append(
                                    "team_leader",
                                    selectedTeamLeader
                                  );

                                  formData.append("is_reviewed", true);

                                  axiosInstance
                                    .patch(
                                      `${separation}${resignation.id}/`,
                                      formData
                                    )
                                    .then(() => {
                                      toast.success(
                                        "Team Leader assigned successfully"
                                      );
                                      refreshTable();
                                      onHide();
                                    })
                                    .catch((error) => {
                                      console.error(
                                        "Error assigning team leader:",
                                        error
                                      );
                                      toast.error(
                                        "Failed to assign team leader"
                                      );
                                    });
                                }}
                              >
                                Assign Team Leader
                              </Button>
                            </div>
                          </Form.Group>
                        </div>
                      )}
                    {resignation.is_reviewed === true && (
                      <div className="mt-4">
                        <Title level={5}>Team Leader Assigned</Title>
                        <Paragraph>
                          <strong>Team Leader:</strong>{" "}
                          {resignation?.team_leader_details?.employee_name}
                        </Paragraph>
                      </div>
                    )}
                  </Typography>
                </Spin>
              </TabPane>

              <TabPane tab="Handover Form" key="2">
                {resignation.handover_details ? (
                  <>
                    <ViewHandoverForm separationId={resignation.id} />
                    {handoverData?.is_tl_approved &&
                      resignation.status === "Handover" && (
                        <>
                          <div className="bg-orange text-white py-2 ps-2 mb-3 br-5">
                            Note - Please confirm that all the above forms have
                            been accurately completed. Check the box below to
                            acknowledge your confirmation before submitting for
                            further processing.
                          </div>
                          <div
                            className="text-white py-2 ps-2 mb-3"
                            style={{
                              backgroundColor: "#808080",
                              borderRadius: 5,
                            }}
                          >
                            Have the company credentials been shared by the
                            Habotech leaving with the Habotech taking over?
                            Also, has the two-step verification phone number for
                            the company email ID been changed?
                          </div>
                          <Button
                            variant="primary"
                            onClick={handleApproveHandover}
                            style={{ marginTop: "16px" }}
                          >
                            Confirm Handover
                          </Button>
                        </>
                      )}
                  </>
                ) : (
                  <div>No handover details available.</div>
                )}
              </TabPane>
              {(resignation?.status === "Handover Approval" ||
                resignation?.status === "Approved") && (
                <TabPane tab="Handover Checklist" key="3">
                  <Typography>
                    <Title level={5}>Checklist Items</Title>
                    {/* Add checklist items here */}
                    <HandoverChecklist
                      selectedResignation={resignation}
                      onChecklistUpdate={refreshTable}
                    />
                  </Typography>
                </TabPane>
              )}
            </Tabs>
          ) : (
            <div>No resignation data available.</div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide} disabled={submitting}>
            Close
          </Button>
          {resignation?.status === "Reviewed" && activeTabKey === "1" && (
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={!lastWorkingDate || !acceptanceLetter || submitting}
            >
              {submitting ? (
                <>
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                    aria-hidden="true"
                  ></span>
                  Processing...
                </>
              ) : (
                "Approve & Set Last Working Date"
              )}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    );
  }
);

const ViewResignationLetterHR = () => {
  const [resignations, setResignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedResignation, setSelectedResignation] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
  });
  const [employees, setEmployees] = useState([]);

  const fetchResignations = useCallback(
    async (page = pagination.current, pageSize = pagination.pageSize) => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `${separation}?page=${page}&page_size=${pageSize}&is_tl=false`
        );
        console.log("Fetched resignations:", response.data); // Debug log
        setResignations(response.data.results);
        setPagination((prev) => ({
          ...prev,
          current: page,
          pageSize: pageSize,
          total: response.data.count,
        }));
      } catch (error) {
        console.error("Error fetching resignations:", error);
        toast.error("Error fetching resignations");
      } finally {
        setLoading(false);
      }
    },
    []
  );

  useEffect(() => {
    fetchResignations(pagination.current, pagination.pageSize);
  }, [fetchResignations, pagination.current, pagination.pageSize]);

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get(employeeList);
      const activeEmployees = response.data.filter(
        (emp) => emp.employment_details[0]?.employment_status === "Active"
      );
      setEmployees(activeEmployees);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Error fetching employees list");
    }
  };

  useEffect(() => {
    fetchEmployees();
  }, []);

  const handleTableChange = useCallback(
    (newPagination, filters, sorter) => {
      console.log("Pagination changed:", newPagination); // Debug log
      fetchResignations(newPagination.current, newPagination.pageSize);
    },
    [fetchResignations]
  );

  const handleView = (record) => {
    setSelectedResignation(record);
    setActiveTabKey("1");
    setViewModalVisible(true);
  };

  const handleModalClose = () => {
    setViewModalVisible(false);
    setSelectedResignation(null);
  };

  const handleSubmitApproval = useCallback(
    async (formData) => {
      try {
        await axiosInstance.patch(
          `${separation}${selectedResignation.id}/`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        toast.success(
          "Resignation approved and last working date set successfully"
        );
        handleModalClose();
        fetchResignations(pagination.current, pagination.pageSize);
      } catch (error) {
        console.error("Error updating resignation:", error);
        toast.error("Error updating resignation");
        throw error;
      }
    },
    [selectedResignation, pagination.current, pagination.pageSize]
  );

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      title: "Submitted Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <Button type="primary" onClick={() => handleView(record)}>
            View
          </Button>
          {record.status === "Handover" && record.is_handover && (
            <Button
              type="primary"
              onClick={() => {
                setSelectedResignation(record);
                setActiveTabKey("2");
                setViewModalVisible(true);
              }}
              style={{ backgroundColor: "#FF9F45" }}
            >
              View Handover
            </Button>
          )}
          {record.status === "Approved" &&
            record.experience_letter === null && (
              <Button
                type="primary"
                onClick={() => handleView(record)}
                style={{ backgroundColor: "#FF9F45" }}
              >
                Upload Experience Letter
              </Button>
            )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Review Resignations"
            title="Dashboard"
            subtitle="Resignations"
          />

          <div className="row">
            <div className="col-md-12">
              <Table
                columns={columns}
                dataSource={resignations}
                loading={loading}
                rowKey="id"
                pagination={{
                  ...pagination,
                  showTotal: (total) => `Total ${total} items`,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  responsive: true,
                  position: ["bottom", "right"],
                  onChange: (page, pageSize) => {
                    handleTableChange({ current: page, pageSize });
                  },
                  onShowSizeChange: (current, size) => {
                    handleTableChange({ current: 1, pageSize: size });
                  },
                }}
                onChange={handleTableChange}
                scroll={{ x: true }}
              />
            </div>
          </div>

          <ViewModal
            show={viewModalVisible}
            onHide={handleModalClose}
            resignation={selectedResignation}
            employees={employees}
            onSubmit={handleSubmitApproval}
            activeTabKey={activeTabKey}
            setActiveTabKey={setActiveTabKey}
            refreshTable={() =>
              fetchResignations(pagination.current, pagination.pageSize)
            }
          />
        </div>
      </div>
    </>
  );
};

export default ViewResignationLetterHR;
