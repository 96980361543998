import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GaclSideData } from "./GaclSideData";
import { GaclTLSideData } from "./GaclTLsidebarData";
import { employeeDetails } from "../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../helpers/axios/axios";
import { Vendordbstosidebardata_GACL } from "./Vendordbstosidebardata_GACL";
import Scrollbars from "react-custom-scrollbars-2";

const GaclSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const { t } = useTranslation();

  const [sidebarData, setSidebarData] = useState(GaclSideData);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);
  const [isMiniSidebar, setIsMiniSidebar] = useState(false);
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        if (response.data.employee_name === "Arihant Bohara") {
          setUserRole("VendorSTO");
        } else if (
          response.data.employment_details[0].designation_detail.name ===
            "Team Leader" ||
          response.data.employment_details[0].designation_detail.name ===
            "Group Leader"
        ) {
          setUserRole("TeamLead");
        }
        // else if (
        //   response.data.employment_details[0].designation_detail.name === "MD"
        // ) {
        //   setUserRole("RS");
        // } else if (
        //   ["Deputy Team Leader", "CEO"].includes(
        //     response.data.employment_details[0].designation_detail.name
        //   )
        // ) {
        //   setUserRole("Approval");
        // } else if (response.data.employee_name === "Meenakshi Pahariya") {
        //   setUserRole("MP");
        // }
        else {
          setUserRole("Default");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    switch (userRole) {
      case "TeamLead":
        setSidebarData(GaclTLSideData);
        break;
      case "VendorSTO":
        setSidebarData(Vendordbstosidebardata_GACL);
        break;
      default:
        setSidebarData(GaclSideData);
    }
  }, [userRole]);

  useEffect(() => {
    const handleResize = () => {
      setIsMiniSidebar(document.body.classList.contains("mini-sidebar"));
    };

    handleResize(); // Initial check
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
      return;
    }
    document.body.classList.remove("expand-menu");
  }, [isMouseOverSidebar]);

  const handleMouseEnter = () => {
    setMouseOverSidebar(true);
  };

  const handleMouseLeave = () => {
    setMouseOverSidebar(false);
  };

  const expandSubMenus = (menu) => {
    const updatedSidebarData = sidebarData.map((section) => {
      if (section.tittle === "MAIN") {
        const updatedMenu = section.menu.map((menuItem) =>
          menuItem.menuValue === menu.menuValue
            ? { ...menuItem, showSubRoute: !menuItem.showSubRoute }
            : { ...menuItem, showSubRoute: false }
        );
        return { ...section, menu: updatedMenu };
      }
      return section;
    });
    setSidebarData(updatedSidebarData);
  };

  const handleMenuClick = (menu) => {
    if (menu.hasSubRoute) {
      expandSubMenus(menu);
    } else {
      navigate(menu.route);
    }
  };

  return (
    <div
      className="sidebar"
      id="sidebar"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            <ul
              className="sidebar-vertical"
              id="vertical-sidebar"
              style={{ display: "block" }}
            >
              {sidebarData.map((mainTitle, index) => (
                <React.Fragment key={index}>
                  <li className="menu-title">
                    <span>{t(mainTitle.tittle)}</span>
                  </li>
                  {mainTitle.menu.map((menu, menuIndex) => (
                    <li
                      key={menuIndex}
                      className={`${menu.hasSubRoute ? "submenu" : ""} ${
                        pathname === menu.route ? "active" : ""
                      } ${isMiniSidebar ? "mini-sidebar-item" : ""}`}
                    >
                      <Link
                        to={menu.hasSubRoute ? "#" : menu.route}
                        onClick={() => handleMenuClick(menu)}
                        className={`${menu.showSubRoute ? "subdrop" : ""} ${
                          isMiniSidebar && pathname === menu.route
                            ? "mini-sidebar-active"
                            : ""
                        }`}
                      >
                        <i className={menu.icon} />
                        <span>{t(menu.menuValue)}</span>
                        {menu.hasSubRoute && <span className="menu-arrow" />}
                      </Link>
                      {menu.hasSubRoute && (
                        <ul
                          style={{
                            display: menu.showSubRoute ? "block" : "none",
                          }}
                        >
                          {menu.subMenus?.map((subMenu, subMenuIndex) => (
                            <li key={subMenuIndex}>
                              <Link
                                to={subMenu.route}
                                onClick={subMenu.onClick}
                                className={
                                  pathname === subMenu.route ? "active" : ""
                                }
                              >
                                {t(subMenu.menuValue)}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default GaclSideBar;
