import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Applogo } from "../../../Routes/ImagePath";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup.js";
import { resetFunctionwithlogin } from "../../../components/ResetFunction";
import axios from "axios"; // Import axios
import { toast } from "react-toastify"; // Import toast for notifications
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { LOGIN, LOGOUT } from "../../../store/actions/accountsActions";
import { axiosInstance } from "../../../helpers/axios/axios";
import { loginUrl } from "../../../helpers/endpoints/api_endpoints";

// const validationSchema = Yup.object().shape({
//   email: Yup.string()
//     .email("Please enter a valid email address")
//     .required("Email is required"),
//   password: Yup.string()
//     .min(6, "Password must be at least 6 characters")
//     .max(20, "Password must be at most 20 characters")
//     .required("Password is required"),
// });

const Login = () => {
  const [passwordShown, setPasswordShown] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const { jobPostingFormData } = useSelector((state) => state.jobsReducer);
  const [loggingOut, setLoggingOut] = useState(false);

  // console.log(jobPostingFormData);
  const [eye, setEye] = useState(true);

  const togglePassword = () => {
    setEye(!eye);
  };

  const {
    register,
    control,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    // resolver: yupResolver(validationSchema),
  });

  // const accessToken = useSelector((state) => state.accessToken);

  const authenticateUser = (data) => {
    const {
      access: accessToken,
      refresh: refreshToken,
      is_client: isClient,
      is_vendor: isVendor,
      is_staff: isStaff,
    } = data;
    const payload = { accessToken, refreshToken, isClient, isVendor, isStaff };

    dispatch({ type: LOGIN, payload });
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);

    setIsLoggingIn(false);

    if (isVendor || isClient || isStaff) {
      navigate("/employee-dashboard");
    }
  };

  const handleUserLogin = useCallback(async (data) => {
    try {
      setIsLoggingIn(true);
      const response = await axiosInstance.post(`${loginUrl}`, data);

      toast.success("Log in Successful");
      const responseData = response.data;
      authenticateUser(responseData);
    } catch (error) {
      // Handle errors in order of priority
      if (!navigator.onLine) {
        toast.error("Invalid email or password");
      } else if (error.response?.data?.message) {
        toast.error(
          `${error.response?.data?.message}. Please check your email...`
        );
        navigate(`/otp/${data?.email}`);
      } else if (error.response?.status === 401) {
        toast.error("Invalid email or password");
      } else if (error.response?.data?.detail) {
        toast.error(`${error.response?.data?.detail}`);
      } else {
        toast.error("Login failed. Please check your credentials.");
      }
      console.log("login error >>>", error);
    } finally {
      setIsLoggingIn(false);
    }
  }, []);

  // useEffect(() => {
  //   setValue("email", localStorage.getItem("email"));
  //   setValue("password", localStorage.getItem("password"));
  // }, []);

  const token = localStorage.getItem("accessToken");
  useEffect(() => {
    if (token) {
      navigate("/employee-dashboard");
    } else {
      localStorage.clear();
      dispatch({ type: LOGOUT });
      navigate("/");
    }
  }, [dispatch, navigate, token]);

  if (token) {
    return null;
  }

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            <div className="account-logo">
              <Link>
                <img
                  src={
                    "https://storage.googleapis.com/varal-habot-vault-marketplace-10032022/images/updated%20trans-Habot-logo-png.png"
                  }
                  alt=""
                />
              </Link>
            </div>
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Login</h3>
                <p className="account-subtitle">Access to our dashboard</p>
                <div>
                  <form onSubmit={handleSubmit(handleUserLogin)}>
                    <div className="input-block mb-4">
                      <label className="col-form-label">Email Address</label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            className={`form-control ${
                              errors?.email ? "error-input" : ""
                            }`}
                            type="text"
                            // defaultValue={localStorage.getItem("email")}
                            onChange={field.onChange}
                            placeholder="Enter your email address"
                            // value={field.value}
                            autoComplete="true"
                          />
                        )}
                      />
                      <span className="text-danger">
                        {errors.email?.message}
                      </span>
                    </div>
                    <div className="input-block mb-4">
                      <div className="row">
                        <div className="col">
                          <label className="col-form-label">Password</label>
                        </div>
                        {/* <div className="col-auto">
                          <Link className="text-muted" to="/forgot-password">
                            Forgot password?
                          </Link>
                        </div> */}
                      </div>
                      <div style={{ position: "relative" }}>
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <input
                              className={`form-control ${
                                errors?.password ? "error-input" : ""
                              }`}
                              type={eye ? "password" : "text"}
                              // defaultValue={localStorage.getItem("password")}
                              // value={field.value}
                              onChange={field.onChange}
                              placeholder="Enter your password"
                            />
                          )}
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: "5%",
                            top: "30%",
                          }}
                          onClick={togglePassword}
                          className={`fa-solid ${
                            eye ? "fa-eye-slash" : "fa-eye"
                          }`}
                        />
                      </div>
                      <span className="text-danger">
                        {errors.password?.message}
                      </span>
                    </div>
                    <div className="input-block text-center">
                      <button
                        className="btn w-100"
                        type="submit"
                        style={{ backgroundColor: "#072f57", color: "white" }}
                        disabled={isLoggingIn}
                      >
                        {isLoggingIn ? "Logging in..." : "Login"}
                      </button>
                    </div>
                  </form>
                  <div className="text-center mt-3">
                    <small className="text-muted">
                      By logging in, you agree to our{" "}
                      <Link
                        to="/privacy-policy"
                        className="text-primary"
                        style={{ textDecoration: "none" }}
                      >
                        Privacy Policy & Terms of Service
                      </Link>
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
