import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { OperationsideData, kartikMenuItems } from "./OperationsideData";
import Scrollbars from "react-custom-scrollbars-2";
import { Spin } from "antd";
import { employeeDetails } from "../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../helpers/axios/axios";
import { useTrackContext } from "../../contexts/TrackContext";

const OperationSideBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const pathname = location.pathname;
  const { t } = useTranslation();
  const trackContext = useTrackContext();
  const tracks = trackContext?.tracks || [];

  const [sidebarData, setSidebarData] = useState([]);
  const [isMouseOverSidebar, setMouseOverSidebar] = useState(false);
  const [isMiniSidebar, setIsMiniSidebar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Update sidebar data with tracks
  const updateSidebarWithTracks = (baseData, isKartik) => {
    return baseData.map((section) => {
      if (section.tittle === "MAIN") {
        const updatedMenu = section.menu.map((menuItem) => {
          if (menuItem.menuValue === "Track Management") {
            return {
              ...menuItem,
              subMenus: tracks.map((track) => ({
                menuValue: track.name,
                route: `/track-management/${track.id}`,
                onClick: () => navigate(`/track-management/${track.id}`),
              })),
            };
          }
          return menuItem;
        });

        // Add Kartik's menu items if needed
        if (isKartik) {
          updatedMenu.push(kartikMenuItems);
        }

        return {
          ...section,
          menu: updatedMenu,
        };
      }
      return section;
    });
  };

  useEffect(() => {
    const fetchEmployeeDetails = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        const isKartik =
          response?.data?.employee_name === "Kartik Purushottam Sonawane";

        // Update sidebar with tracks and conditional menu items
        const updatedSidebarData = updateSidebarWithTracks(
          OperationsideData,
          isKartik
        );
        setSidebarData(updatedSidebarData);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        const updatedSidebarData = updateSidebarWithTracks(
          OperationsideData,
          false
        );
        setSidebarData(updatedSidebarData);
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployeeDetails();
  }, [tracks, navigate]); // Add tracks as dependency

  useEffect(() => {
    const handleResize = () => {
      setIsMiniSidebar(document.body.classList.contains("mini-sidebar"));
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (
      isMouseOverSidebar &&
      document.body.classList.contains("mini-sidebar")
    ) {
      document.body.classList.add("expand-menu");
    } else {
      document.body.classList.remove("expand-menu");
    }
  }, [isMouseOverSidebar]);

  const expandSubMenus = (menu) => {
    setSidebarData((prevData) =>
      prevData.map((section) => ({
        ...section,
        menu: section.menu.map((menuItem) => ({
          ...menuItem,
          showSubRoute:
            menuItem.menuValue === menu.menuValue
              ? !menuItem.showSubRoute
              : false,
        })),
      }))
    );
  };

  const handleMenuClick = (menu) => {
    if (menu.hasSubRoute) {
      expandSubMenus(menu);
    } else {
      navigate(menu.route);
    }
  };

  if (isLoading) {
    return (
      <div className="sidebar" id="sidebar">
        <div className="text-center p-4">
          <Spin size="default" />
        </div>
      </div>
    );
  }

  return (
    <div
      className="sidebar"
      id="sidebar"
      onMouseEnter={() => setMouseOverSidebar(true)}
      onMouseLeave={() => setMouseOverSidebar(false)}
    >
      <div className="sidebar-inner slimscroll">
        <div id="sidebar-menu" className="sidebar-menu">
          <Scrollbars
            autoHide
            autoHideTimeout={1000}
            autoHideDuration={200}
            autoHeight
            autoHeightMin={0}
            autoHeightMax="100vh"
            thumbMinSize={30}
            universal={false}
            hideTracksWhenNotNeeded={true}
          >
            <ul className="sidebar-vertical" id="vertical-sidebar">
              {sidebarData.map((mainTitle, index) => (
                <React.Fragment key={index}>
                  <li className="menu-title">
                    <span>{t(mainTitle.tittle)}</span>
                  </li>
                  {mainTitle.menu.map((menu, menuIndex) => (
                    <li
                      key={menuIndex}
                      className={`${menu.hasSubRoute ? "submenu" : ""} ${
                        pathname === menu.route ? "active" : ""
                      } ${isMiniSidebar ? "mini-sidebar-item" : ""}`}
                    >
                      <Link
                        to={menu.hasSubRoute ? "#" : menu.route}
                        onClick={() => handleMenuClick(menu)}
                        className={`${menu.showSubRoute ? "subdrop" : ""} ${
                          isMiniSidebar && pathname === menu.route
                            ? "mini-sidebar-active"
                            : ""
                        }`}
                      >
                        <i className={menu.icon} />
                        <span>{t(menu.menuValue)}</span>
                        {menu.hasSubRoute && <span className="menu-arrow" />}
                      </Link>
                      {menu.hasSubRoute && menu.subMenus && (
                        <ul
                          style={{
                            display: menu.showSubRoute ? "block" : "none",
                          }}
                        >
                          {menu.subMenus.map((subMenu, subMenuIndex) => (
                            <li key={subMenuIndex}>
                              <Link
                                to={subMenu.route}
                                onClick={subMenu.onClick}
                                className={
                                  pathname === subMenu.route ? "active" : ""
                                }
                              >
                                {t(subMenu.menuValue)}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      )}
                    </li>
                  ))}
                </React.Fragment>
              ))}
            </ul>
          </Scrollbars>
        </div>
      </div>
    </div>
  );
};

export default OperationSideBar;
