import React, { useEffect, useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { pdcaassigntask,employeeDetails } from "../../../../helpers/endpoints/api_endpoints";

function EmployeePDCAViewDetails() {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [employeeID, setEmployeeID] = useState();

  useEffect(() => {
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        console.log(
          "Employee id Response:",
          response.data.employment_details[0].id
        );
        setEmployeeID(response.data.employment_details[0].id);
      })
      .catch((err) => console.error("Error fetching employee details:", err));
  }, []);

  useEffect(() => {
    if (employeeID) {
      const fetchTasks = async () => {
        try {
          const response = await axiosInstance.get(
            `${pdcaassigntask}?assignee=${employeeID}`
          );
          setTasks(response.data);
          console.log(response.data);
          setLoading(false);
        } catch (err) {
          setError(err.message);
          setLoading(false);
        }
      };

      fetchTasks();
    }
  }, [employeeID]);

  if (loading) {
    return <div>Loading tasks...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="p-3">
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Form>
            <Form.Group className="mb-3" controlId="resignationLetter">
              <Button>View Assigned Task</Button>
            </Form.Group>
            <div className="table-responsive m-t-15">
              <table className="table table-striped custom-table">
                <thead>
                  <tr>
                    <th className="text-center">Task</th>
                    <th className="text-center">Due Date</th>
                    <th className="text-center">Accept/View</th>
                  </tr>
                </thead>
                <tbody>
                  {tasks?.results?.map((task) => (
                    <tr key={task.id}>
                      <td className="text-center">{task.pdca_task}</td>
                      <td className="text-center">{task.end_date}</td>
                      <td className="text-center">
                        <Link to={`/view-assign-document/${task.id}`}>
                          <Button
                            variant="warning"
                            type="submit"
                            className="px-4"
                          >
                            View
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
}

export default EmployeePDCAViewDetails;
