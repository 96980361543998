import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Typography,
  Spin,
  Form,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
  Tooltip,
} from "antd";
import { Modal } from "react-bootstrap";
import { EyeOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  employeeQPA,
  employeeDetails,
  QPAform,
  nomination,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";

const { Title, Text } = Typography;
const { Option } = Select;

const NominatedQPA = () => {
  const [qpaData, setQPAData] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQPA, setSelectedQPA] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPrinciples, setSelectedPrinciples] = useState([]);
  const [employeeName, setEmployeeName] = useState("");
  const [tableLoading, setTableLoading] = useState(false);
  const [currentQuarter, setCurrentQuarter] = useState(null);

  // Fetch current employee details
  useEffect(() => {
    const fetchCurrentEmployee = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setCurrentEmployee(response.data);
        // After getting employee details, fetch QPA nominations

        fetchQPAData(response.data.employment_details[0].id);
        setEmployeeName(response.data.employee_name);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };
    fetchCurrentEmployee();
  }, []);

  const fetchQPAData = async (employeeId, page = 1, pageSize = 10) => {
    setTableLoading(true);
    try {
      const response = await axiosInstance.get(
        `${nomination}?nominee=${employeeId}&page=${page}&page_size=${pageSize}`
      );
      setQPAData(response.data.results);

      // If there's QPA data, set the quarter
      if (response.data.results.length > 0) {
        const qpaDetails = response.data.results[0].qpa_details;
        setCurrentQuarter(qpaDetails.quarter);
      }

      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching QPA data:", error);
      toast.error("Failed to fetch QPA nominations");
    } finally {
      setTableLoading(false);
      setIsLoading(false);
    }
  };

  const handleTableChange = (newPagination) => {
    fetchQPAData(
      currentEmployee?.employment_details[0].id,
      newPagination.current,
      newPagination.pageSize
    );
  };

  const handleAcceptNomination = async (record) => {
    try {
      console.log("Received record in handleAcceptNomination:", record);
      console.log("QPA Nomination ID in handler:", record.qpa_nomination);
      console.log(
        "Full nomination endpoint:",
        `${nomination}${record.qpa_nomination}/`
      );

      if (!record.qpa_nomination) {
        console.error("Missing qpa_nomination:", record);
        toast.error("Invalid nomination ID");
        return;
      }

      const response = await axiosInstance.patch(`${nomination}${record.id}/`, {
        is_accepted: true,
      });

      console.log("Patch response:", response);

      // Refresh the data
      await fetchQPAData(
        currentEmployee?.employment_details[0].id,
        pagination.current,
        pagination.pageSize
      );

      toast.success("Nomination accepted successfully");
    } catch (error) {
      console.error("Error accepting nomination:", error);
      console.error("Failed record:", record);
      console.error(
        "Attempted endpoint:",
        `${nomination}${record.qpa_nomination}/`
      );
      toast.error("Failed to accept nomination");
    }
  };

  const validateWordCount = (text, requiredCount) => {
    if (!text) return false;
    const wordCount = text.trim().split(/\s+/).length;
    return wordCount >= requiredCount;
  };

  const WordCountInput = ({
    value,
    required,
    maxWords,
    onChange,
    rows = 6,
  }) => {
    const wordCount = value ? value.trim().split(/\s+/).length : 0;

    // Fix the validation logic
    const isValid = maxWords
      ? wordCount >= 30 && wordCount <= 50 // Only for fields with maxWords prop (30-50 range)
      : wordCount <= required; // For all other fields (maximum only)

    return (
      <div>
        <Input.TextArea
          value={value}
          onChange={onChange}
          rows={rows}
          style={{
            borderColor: value && !isValid ? "#ff4d4f" : undefined,
          }}
        />
        <div
          style={{
            textAlign: "right",
            color: value && !isValid ? "#ff4d4f" : "#888",
            fontSize: "12px",
          }}
        >
          {wordCount} /{" "}
          {maxWords
            ? "30-50 words required" // Only for fields with maxWords prop
            : `${required} words maximum`}{" "}
        </div>
      </div>
    );
  };

  const handleFormSubmit = async (values) => {
    // Word count validations
    const wordCountValidations = [
      {
        field: "instance_item",
        required: 150,
        message: "Cannot exceed 150 words for the instances",
      },
      {
        field: "example_3",
        required: 100,
        message: "Cannot exceed 100 words for this response",
      },
      {
        field: "example_4",
        required: 100,
        message: "Cannot exceed 100 words for this response",
      },
      {
        field: "example_5",
        required: 100,
        message: "Cannot exceed 100 words for this response",
      },
      {
        field: "example_6",
        required: 100,
        message: "Cannot exceed 100 words for this response",
      },
      {
        field: "example_7",
        required: 100,
        message: "Cannot exceed 100 words for this response",
      },
      // Only these three have the 30-50 range requirement
      {
        field: "example_8",
        required: 30,
        maxWords: 50,
        message: "Please write between 30-50 words",
      },
      {
        field: "example_9",
        required: 30,
        maxWords: 50,
        message: "Please write between 30-50 words",
      },
      {
        field: "example_10",
        required: 30,
        maxWords: 50,
        message: "Please write between 30-50 words",
      },
      // This should now accept 1-100 words
      {
        field: "example_11",
        required: 100,
        message: "Cannot exceed 100 words for this response",
      },
    ];

    // Check all word count validations
    for (const validation of wordCountValidations) {
      const wordCount =
        values[validation.field]?.trim().split(/\s+/).length || 0;

      if (validation.maxWords) {
        // Only 30-50 range fields have maxWords
        if (wordCount < 30 || wordCount > 50) {
          toast.error(validation.message);
          return;
        }
      } else {
        // For all other fields, ONLY check if exceeds maximum
        if (wordCount > validation.required) {
          toast.error(validation.message);
          return;
        }
      }
    }

    // Add validation for rating examples
    if (values.example_1) {
      const wordCount1 = values.example_1.trim().split(/\s+/).length;
      if (wordCount1 > 50) {
        toast.error("First rating example cannot exceed 50 words");
        return;
      }
    }

    if (values.example_2) {
      const wordCount2 = values.example_2.trim().split(/\s+/).length;
      if (wordCount2 > 50) {
        toast.error("Second rating example cannot exceed 50 words");
        return;
      }
    }

    setIsSubmitting(true);
    try {
      if (
        !selectedQPA?.qpa_details?.user ||
        !currentEmployee?.employment_details[0]?.id
      ) {
        throw new Error("Missing required data");
      }

      // Remove quarter but keep type
      const { quarter, ...formData } = values;

      const payload = {
        ...formData,
        user: selectedQPA.qpa_details.user,
        nominee: currentEmployee.employment_details[0].id,
        nomination_qpa: selectedQPA.id,
      };

      await axiosInstance.post(QPAform, payload);

      // Update nomination status to done
      await axiosInstance.patch(`${nomination}${selectedQPA.id}/`, {
        is_done: true,
      });

      // Refresh the data
      await fetchQPAData(
        currentEmployee.employment_details[0].id,
        pagination.current,
        pagination.pageSize
      );

      toast.success("QPA form submitted successfully");
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error submitting QPA form:", error);
      toast.error(error.response?.data?.message || "Failed to submit QPA form");
    } finally {
      setIsSubmitting(false);
    }
  };

  const columns = [
    {
      title: "Sr.No",
      key: "sr_no",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Nominated By",
      dataIndex: ["qpa_details", "user_details", "employee_name"],
      key: "nominator",
    },
    {
      title: "Department",
      dataIndex: ["qpa_details", "user_details", "employment_details"],
      key: "department",
      render: (details) => details[0]?.department_detail?.name,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => new Date(date).toLocaleDateString(),
    },
    {
      title: "Quarter",
      dataIndex: ["qpa_details", "quarter"],
      key: "quarter",
      render: (quarter) => quarter || "N/A",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        if (!record.is_accepted) {
          return (
            <Button
              type="primary"
              onClick={() => {
                console.log("Record being passed:", record);
                console.log("QPA Nomination ID:", record.qpa_nomination);
                handleAcceptNomination(record);
              }}
            >
              Accept Nomination
            </Button>
          );
        }

        return (
          <Button
            type="primary"
            icon={<EyeOutlined />}
            onClick={() => {
              console.log("Selected QPA record:", record);
              setSelectedQPA(record);
              setIsModalVisible(true);
            }}
            disabled={record.is_done}
          >
            {record.is_done ? "Completed" : "Fill QPA Form"}
          </Button>
        );
      },
    },
  ];

  const quarterOptions = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];

  const typeOptions = ["Self", "Colleague", "Boss"];

  const renderFormItems = () => {
    if (!selectedQPA || !currentEmployee) {
      return null;
    }

    const validateDecimal = (_, value) => {
      if (!value) {
        return Promise.reject("This field is required");
      }

      const decimalRegex = /^\d*\.?\d+$/;
      if (!decimalRegex.test(value)) {
        return Promise.reject("Please enter a decimal number (e.g., 4.5)");
      }

      const numValue = parseFloat(value);
      if (numValue < 1 || numValue > 5) {
        return Promise.reject("Rating must be between 1 and 5");
      }

      return Promise.resolve();
    };

    const ratingLabels = {
      1: "Have patience and think it through.",
      2: "Cowardice will lead to misfortune.",
      3: "Over solicitude for our team members exposes us to worry and trouble.",
      4: "Must know when to fight and when to withdraw.",
      5: "Win in our own temple before engaging.",
      6: "Solve problems by leveraging a combination of Moral and Method.",
      7: "Harmonize any situation to release Vitality and Prosperity [VAP].",
      8: "Lead with absolute virtuous intentions:\n1. Be wise, sincere, benevolent, courageous, and strict\n2. Never lie to the team or boss.",
      9: "Fight without wanting to win accolades or retreat without fearing disgrace.",
      10: "Become adept at using Moral & Method to thread Shiva & Shakti factors to achieve VAP on both sides in any conflict situation.",
      11: "Must be present and accountable.",
      12: "Avoid dependence on praise, recognition and temptations.",
      13: "Be the master of his or her own reality.",
      14: "View reality without judgment.",
      15: "Identify the other side, and view the reality from a 3rd, neutral position.",
      16: "Have the ability to pick the right person for the right job.",
      17: "Keep strategy confidential from environment & team.",
      18: "Encourage team members who can distinguish autonomy and master their own reality and make them Engines.",
      19: "Be constant and consistent in applying discipline and APS.",
      20: "Continuously improve themselves.",
      21: "Be unassailable and defend against retaliation to our vulnerabilities.",
      22: "See and identify deception.",
      23: "Understand and use human nature.",
    };
    const checks = {
      1: "Watch finances daily & act to cut losses without remorse.",
      2: "Keep 'Thinking'- jobs in-house & outsource all 'Doing' jobs.",
      3: "Reconstruct & standardize complex tasks and processes including outsourcing, into simple elements & then go accomplish them.",
      4: "Continuously look for money-making opportunities for our platform.",
      5: "Keep an open mind & embrace the diversity of people, ideas & beliefs.",
      6: "Encourage & promote passion and accountability.",
      7: "The understanding of Habot's Vision & Values must be very clear to the whole team.",
      8: "Even if you disagree, express it, and commit to Habot's course while you are a HabotTech",
    };

    const exampleLabels = {
      3: "Describe how the Assessee has taken responsibility for his or her own work. Explain in 100 words.",
      4: "A. What superpowers does the Assessee possess? Please list and explain his/her superpowers and abilities- write in total of 100 words.",
      5: "B. What challenges does the Assessee face and in which type of situations? Please list and explain where Assessee struggles - write in total of 100 words.",
      6: "What specific superpowers should the Assesssee be helped out with or provided with? Write in total 100 words.",
      7: "What are 2 specific contributions made by the Assessee to advance towards becoming a 'high-performing engine'? (Write 100 words after you have understood the concept of 'engine')",
      8: "What stands out about the assessee (Use about 30-50 words)",
      9: "What are the assessee's strengths? (Use about 30-50 words)",
      10: "Comments on superpowers as mentioned above (Use about 30-50 words)",
      11: "What can the Assessee do to develop and grow - Write up to 100 words.",
    };
    const selfLabels = {
      3: "Describe how you have taken responsibility for your own work. Explain in 100 words.",
      4: "A. What superpowers do you possess? Please list and explain your superpowers and abilities - write in total of 100 words.",
      5: "B. What challenges do you face and in which type of situations? - write in total of 100 words.",
      6: "What challenges would you like your supervisor to help you with? Write in total 100 words.",
      7: "What are 2 specific contributions made by you to advance towards becoming a 'high-performing engine'? (Write 100 words after you have understood the concept of 'engine')",
      8: "What excites you about your work? Use about 30-50 words.",
      9: "What are your superpowers and What are your challenges? Use about 30-50 words.",
      10: "What are your aspirations and which are being met by your work? Use about 30-50 words.",
      11: "What can you do to develop and grow - Write 100 words.",
    };
    // Handler for checkbox changes
    const handlePrincipleSelection = (checkedValues) => {
      if (checkedValues.length <= 2) {
        setSelectedPrinciples(checkedValues);
        form.setFieldsValue({
          checkbox_1: checkedValues[0],
          checkbox_2: checkedValues[1],
        });
      }
    };

    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          declaration: false,
          checkbox_1: "",
          checkbox_2: "",
          type: selectedQPA?.type || "N/A",
          quarter: currentQuarter || "N/A",
        }}
        onFinish={handleFormSubmit}
      >
        {/* Type selection */}
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: "Please select type" }]}
        >
          <Select disabled={true} value={selectedQPA?.type || "N/A"}>
            <Option value="N/A">N/A</Option>
            {typeOptions.map((type) => (
              <Option
                key={type}
                value={type}
                disabled={type !== selectedQPA?.type}
              >
                {type}
              </Option>
            ))}
          </Select>
        </Form.Item>

        {/* Quarter display */}
        <Form.Item name="quarter" label="Quarter">
          <Select disabled value={currentQuarter || "N/A"}>
            <Option value="N/A">N/A</Option>
            {quarterOptions.map((quarter) => (
              <Option
                key={quarter}
                value={quarter}
                disabled={quarter !== currentQuarter}
              >
                {quarter}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="instance_item"
          label="What 3 specific instances can you provide of how the Assessee contributed to enhancing the team's performance? Please write actual cases (maximum 150 words)"
          rules={[{ required: true, message: "Please provide instances" }]}
        >
          <WordCountInput required={150} />
        </Form.Item>

        {/* Leadership Principles Rating Table */}
        <div style={{ marginBottom: 24 }}>
          <Text strong>
            Rate the Assessee on each of the following Leadership Principles,
            using a scale from 1 to 5.
          </Text>
          <Text type="secondary" style={{ display: "block" }}>
            [5 means the Assesssee fits the principle perfectly and 1 means the
            Assessee does not fit the principle at all]
          </Text>
        </div>

        <Table
          dataSource={Object.keys(ratingLabels).map((key, index) => ({
            key: key,
            sl: index + 1, // Dynamic numbering based on index
            principle: ratingLabels[key],
            rating: (
              <Form.Item
                name={`rating_${key}`}
                noStyle
                rules={[{ validator: validateDecimal }]}
              >
                <Input
                  placeholder="Enter rating (1-5)"
                  style={{ width: "100%", minWidth: 80, maxWidth: 120 }}
                />
              </Form.Item>
            ),
          }))}
          columns={[
            {
              title: "Sl No.",
              dataIndex: "sl",
              width: 60,
              fixed: "left",
            },
            {
              title: "Leadership Principles",
              dataIndex: "principle",
              render: (text) => (
                <div
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    minWidth: 200,
                  }}
                >
                  {text}
                </div>
              ),
            },
            {
              title: "Ratings",
              dataIndex: "rating",
              width: 130,
              fixed: "right",
            },
          ]}
          pagination={false}
          bordered
          scroll={{
            x: "max-content",
            y: undefined,
          }}
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        />

        {/* Leadership Principles Selection */}
        <div style={{ marginBottom: 24, marginTop: 24 }}>
          <Text strong>
            A - Which two specific LPs did the Assessee focus on and excel at
            during the quarter under review?
          </Text>
        </div>

        <Form.Item>
          <Checkbox.Group
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            onChange={handlePrincipleSelection}
            value={selectedPrinciples}
          >
            {Object.entries(checks).map(([key, value]) => (
              <Checkbox
                key={key}
                value={value}
                disabled={
                  selectedPrinciples.length >= 2 &&
                  !selectedPrinciples.includes(value)
                }
              >
                {value}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        {/* Hidden fields to store selected values */}
        <Form.Item name="checkbox_1" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="checkbox_2" hidden>
          <Input />
        </Form.Item>

        {/* Dynamic Example Questions based on selection */}
        {selectedPrinciples.length > 0 && (
          <div style={{ marginBottom: 24 }}>
            <Text strong>
              3B - About the above question A, write 1 example for each of the
              LPs chosen. About 50 words each
            </Text>
          </div>
        )}

        {selectedPrinciples[0] && (
          <Form.Item
            name="example_1"
            label={selectedPrinciples[0]}
            rules={[
              {
                required: true,
                message:
                  "Please provide an example for the first selected principle",
              },
            ]}
          >
            <WordCountInput required={50} />
          </Form.Item>
        )}

        {selectedPrinciples[1] && (
          <Form.Item
            name="example_2"
            label={selectedPrinciples[1]}
            rules={[
              {
                required: true,
                message:
                  "Please provide an example for the second selected principle",
              },
            ]}
          >
            <WordCountInput required={50} />
          </Form.Item>
        )}

        {/* Additional Questions */}
        {form.getFieldValue("type") === "Self"
          ? Object.entries(selfLabels).map(([key, label]) => (
              <Form.Item
                key={`example_${key}`}
                name={`example_${key}`}
                label={<Text>{label}</Text>}
                rules={[
                  {
                    required: true,
                    message: `Please provide input for "${label}"`,
                  },
                ]}
              >
                <WordCountInput
                  required={["8", "9", "10"].includes(key) ? 30 : 100}
                  maxWords={["8", "9", "10"].includes(key) ? 50 : undefined}
                />
              </Form.Item>
            ))
          : Object.entries(exampleLabels).map(([key, label]) => (
              <Form.Item
                key={`example_${key}`}
                name={`example_${key}`}
                label={<Text>{label}</Text>}
                rules={[
                  {
                    required: true,
                    message: `Please provide input for "${label}"`,
                  },
                ]}
              >
                <WordCountInput
                  required={["8", "9", "10"].includes(key) ? 30 : 100}
                  maxWords={["8", "9", "10"].includes(key) ? 50 : undefined}
                />
              </Form.Item>
            ))}

        {/* Declaration */}
        <Form.Item
          name="declaration"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Declaration is mandatory. Please accept it to proceed."
                      )
                    ),
            },
          ]}
          style={{ marginTop: "24px" }}
        >
          <Checkbox>
            <Text strong type="danger">
              *{" "}
            </Text>
            <Text>
              I declare that all the information provided above is true to the
              best of my knowledge.
            </Text>
          </Checkbox>
        </Form.Item>
      </Form>
    );
  };

  // When initializing the form
  useEffect(() => {
    if (selectedQPA && currentEmployee) {
      const isSelfNomination =
        selectedQPA?.qpa_details?.user_details?.employee_name === employeeName;

      // If it's a self nomination, set the type and disable the field
      if (isSelfNomination) {
        form.setFieldsValue({
          type: "Self",
        });
      }
    }
  }, [selectedQPA, currentEmployee, form]);

  // Add useEffect to refetch when type changes
  useEffect(() => {
    if (currentEmployee?.employment_details[0]?.id) {
      fetchQPAData(currentEmployee.employment_details[0].id);
    }
  }, [currentEmployee]);

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {isLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "calc(100vh - 60px)",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "20px",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
              >
                <Title level={2}>Nominations Received</Title>
              </div>
              <Card>
                <Table
                  dataSource={qpaData}
                  columns={columns}
                  rowKey="id"
                  loading={tableLoading}
                  pagination={{
                    ...pagination,
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                  }}
                  onChange={handleTableChange}
                  scroll={{ x: "max-content" }}
                />
              </Card>

              <Modal
                show={isModalVisible}
                onHide={() => {
                  setIsModalVisible(false);
                  form.resetFields();
                }}
                size="xl"
                backdrop="static"
                keyboard={false}
                style={{ zIndex: 1056 }}
              >
                <Modal.Header closeButton>
                  <Modal.Title>QPA Form</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ maxHeight: "80vh", overflow: "auto" }}>
                  {selectedQPA && (
                    <div>
                      <Row gutter={24} style={{ marginBottom: "20px" }}>
                        <Col span={12}>
                          <Text strong>Nominated By: </Text>
                          <Text>
                            {selectedQPA?.qpa_details?.user_details
                              ?.employee_name || "N/A"}
                          </Text>
                        </Col>
                        <Col span={12}>
                          <Text strong>Created on: </Text>
                          <Text>
                            {selectedQPA?.created_at
                              ? new Date(
                                  selectedQPA.created_at
                                ).toLocaleDateString()
                              : "N/A"}
                          </Text>
                        </Col>
                      </Row>
                      {renderFormItems()}
                    </div>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <Button
                    onClick={() => {
                      setIsModalVisible(false);
                      form.resetFields();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    type="primary"
                    onClick={() => form.submit()}
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NominatedQPA;
