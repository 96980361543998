import { useState, useEffect } from "react";
import { Button, Card, Table, message, Spin } from "antd";
import { vendordocumentsProfile } from "../../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import GaclSideBar from "../../../../layout/GaclSidebar";
import { vendorprofile } from "../../../../../helpers/endpoints/api_endpoints";
import { LoadingOutlined } from "@ant-design/icons";
import { Tag } from "antd";
const DocumentsHistory = () => {
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [loadingId, setLoadingId] = useState(null);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: "#1890ff" }} spin />
  );

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchVendorData = async (page, pageSize) => {
    try {
      setLoading(true);
      const pageNumber = Math.max(1, page);
      const url = `${vendordocumentsProfile}?page=${pageNumber}&page_size=${pageSize}`;
      const response = await axiosInstance.get(url);

      const { data } = response;

      if (data?.results && Array.isArray(data.results)) {
        setVendorData(data.results);
        setPagination({
          current: pageNumber,
          pageSize,
          total: data.count || 0,
        });
      } else if (Array.isArray(data)) {
        setVendorData(data);
        setPagination({
          current: pageNumber,
          pageSize,
          total: data.length,
        });
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      message.error("Failed to fetch vendor data");
      setVendorData([]);
    } finally {
      setLoading(false);
    }
  };
  const handleViewDetails = async (record) => {
    try {
      setLoadingId(record.id);
      const response = await axiosInstance.get(`${vendorprofile}`, {
        params: {
          vendor_profile_initiation: record.id,
        },
      });

      console.log("Vendor Profile Details:", response.data);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
      message.error("Failed to fetch vendor details");
    } finally {
      setLoadingId(null);
    }
  };

  const handleTableChange = (newPagination) => {
    const { current, pageSize } = newPagination;

    setPagination((prev) => ({
      ...prev,
      current,
      pageSize,
    }));

    fetchVendorData(current, pageSize);
  };

  useEffect(() => {
    fetchVendorData(pagination.current, pagination.pageSize, {});
  }, []);

  const columns = [
    {
      title: "Request ID",
      dataIndex: "vendor_request_info",
      key: "vendor_request_info",
      render: (info, record) => (
        <span>
          {record.is_existing_vendor && !info ? (
            <span
              style={{
                backgroundColor: "#e6f7ff",
                color: "#1890ff",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "12px",
              }}
            >
              Existing Vendor
            </span>
          ) : (
            info?.vendor_name || "NA"
          )}
        </span>
      ),
    },

    {
      title: "Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <span>
          {new Date(date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </span>
      ),
    },
    {
      title: "Document Name",
      dataIndex: "department_info",
      key: "department_info",
      render: (department_info) => (
        <span style={{ textTransform: "capitalize" }}>
          {department_info?.name || "NA"}
        </span>
      ),
    },
    {
      title: "Document",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <span>
          {new Date(date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </span>
      ),
    },

    {
      title: "MTO Status",
      key: "invoice",
      render: (record) => (
        <Button
          style={{
            backgroundColor: "#202F36",
            color: "white",
          }}
          onClick={() => {
            if (record.invoice_document) {
              window.open(record.invoice_document, "_blank");
            } else {
              message.warning("No invoice document available");
            }
          }}
        >
          View
        </Button>
      ),
    },

    {
      title: "TC Status",
      key: "invoice",
      render: (record) => {
        const getTagType = () => {
          switch (record.tc_status?.toLowerCase()?.trim()) {
            case "passed":
              return { color: "success", text: "Passed" };
            case "failed":
              return { color: "error", text: "Failed" };
            default:
              return { color: "warning", text: "Pending" };
          }
        };

        const { color, text } = getTagType();

        return <Tag color={color}>{text}</Tag>;
      },
    },
  ];

  return (
    <>
      <GaclSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Button
            style={{
              backgroundColor: "#FD705C",
              width: "180px",
              height: "44px",
              backgroundColor: "#FD705C",
              color: "white",
              marginBottom: "30px",
            }}
          >
            Ongoing Extraction{" "}
          </Button>
          <Card>
            <Table
              loading={loading}
              dataSource={vendorData}
              columns={columns}
              rowKey="id"
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                pageSizeOptions: ["10", "20", "30", "50"],
                current: pagination.current,
                pageSize: pagination.pageSize,
              }}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
              style={{
                overflowX: "auto",
                width: "100%",
              }}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default DocumentsHistory;
