import { useState, useEffect } from "react";
import { Button, Card, Table, message } from "antd";
import {
  departmentList,
  vendordocuments,
  vendorRequest,
} from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";

const ApprovedvendorsGACL = () => {
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [vendorDocuments, setVendorDocuments] = useState({});
  const [departmentOptions, setDepartmentOptions] = useState([]);

  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  useEffect(() => {
    axiosInstance
      .get(departmentList)
      .then((response) => {
        const departments = response.data.map((dept) => ({
          label: dept.name,
          value: dept.id,
        }));
        setDepartmentOptions(departments);
      })
      .catch((err) => console.log(err));
  }, []);

  const fetchVendorDocuments = async (vendorId) => {
    try {
      const response = await axiosInstance.get(
        `${vendordocuments}?vendor_request=${vendorId}`
      );
      return Array.isArray(response.data)
        ? response.data
        : Array.isArray(response.data.results)
        ? response.data.results
        : [];
    } catch (error) {
      console.error(`Error fetching documents for vendor ${vendorId}:`, error);
      return [];
    }
  };

  const fetchVendorData = async (page, pageSize) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${vendorRequest}?page=${page}&page_size=${pageSize}`
      );
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
      const vendors = response.data.results;

      const documentsPromises = vendors.map(async (vendor) => {
        const documents = await fetchVendorDocuments(vendor.id);
        return { vendorId: vendor.id, documents };
      });

      const documentsResults = await Promise.all(documentsPromises);

      const documentsLookup = documentsResults.reduce((acc, curr) => {
        acc[curr.vendorId] = curr.documents;
        return acc;
      }, {});

      setVendorDocuments(documentsLookup);

      setVendorData(vendors);
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      message.error("Failed to fetch vendor data");
      setVendorData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchVendorData(newPagination.current, newPagination.pageSize);
  };

  useEffect(() => {
    fetchVendorData(pagination.current, pagination.pageSize);
  }, []);
  const handleMultipleDocumentOpen = (documents) => {
    if (!documents || !Array.isArray(documents)) return;

    documents.forEach((doc) => {
      if (doc.document) {
        window.open(doc.document, "_blank");
      }
    });
  };

  const handleSingleDocumentOpen = (doc) => {
    if (doc.document) {
      window.open(doc.document, "_blank");
    }
  };
  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (name) => <span>{name || "-"}</span>,
    },

    {
      title: "Subscription Frequency",
      dataIndex: "subscription_frequency",
      key: "subscription_frequency",
      render: (frequency) => (
        <span style={{ textTransform: "capitalize" }}>
          {frequency?.replace(/_/g, " ") || "-"}
        </span>
      ),
    },
    {
      title: "Approved At",
      dataIndex: "updated_at",
      key: "updated_at",
      render: (date) => (
        <span>{date ? new Date(date).toLocaleDateString() : "-"}</span>
      ),
    },

    {
      title: "Request Type",
      dataIndex: "is_urgent",
      key: "is_urgent",
      render: (isUrgent) => (
        <span
          style={{
            color: isUrgent ? "#ff4d4f" : "#52c41a",
            backgroundColor: isUrgent ? "#fff1f0" : "#f6ffed",
            padding: "4px 8px",
            borderRadius: "4px",
            border: `1px solid ${isUrgent ? "#ffa39e" : "#b7eb8f"}`,
          }}
        >
          {isUrgent ? "Urgent" : "Normal"}
        </span>
      ),
    },
    {
      title: "Approval Status ",
      dataIndex: "gacl_status",
      key: "gacl_status",
      render: (status) => (
        <span
          style={{
            padding: "4px 8px",
            borderRadius: "4px",
            backgroundColor:
              status === "APPROVED"
                ? "#52c41a"
                : status === "PENDING"
                ? "#faad14"
                : status === "REJECTED"
                ? "#f5222d"
                : "#d9d9d9",
            color: "#fff",
          }}
        >
          {status || "-"}
        </span>
      ),
    },
    {
      title: "Document",
      dataIndex: "id",
      key: "documents",
      render: (vendorId) => {
        const documents = vendorDocuments[vendorId];

        if (!documents || !Array.isArray(documents)) {
          return <span>No documents</span>;
        }

        return (
          <div>
            {documents.length > 0 ? (
              <div style={{ marginBottom: "10px" }}>
                {documents.length > 1 ? (
                  <Button
                    type="primary"
                    onClick={() => handleMultipleDocumentOpen(documents)}
                    style={{ backgroundColor: "#34444C" }}
                  >
                    Download All ({documents.length})
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => handleSingleDocumentOpen(documents[0])}
                    style={{ backgroundColor: "#34444C" }}
                  >
                    Download
                  </Button>
                )}
              </div>
            ) : (
              <div>No documents</div>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Card>
          <Table
            loading={loading}
            dataSource={vendorData}
            columns={columns}
            rowKey="id"
            pagination={{
              ...pagination,
              showSizeChanger: true,
              showTotal: (total, range) =>
                `Showing ${range[0]}-${range[1]} of ${total} items`,
              pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleTableChange}
            scroll={{ x: "max-content" }}
            style={{
              overflowX: "auto",
              width: "100%",
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default ApprovedvendorsGACL;
