// import React, { useEffect, useState } from "react";
// import {
//   Form,
//   Input,
//   Button,
//   Row,
//   Col,
//   Upload,
//   Select,
//   message,
//   Image,
//   Spin,
// } from "antd";
// import { UploadOutlined } from "@ant-design/icons";
// import { axiosInstance } from "../../../../helpers/axios/axios";
// import {
//   handoverCheckList,
//   separation,
// } from "../../../../helpers/endpoints/api_endpoints";
// import { toast } from "react-toastify";

// const { Option } = Select;

// const HandoverChecklist = ({ selectedResignation, onChecklistUpdate }) => {
//   console.log(selectedResignation);
//   const [handOverChecklistData, setHandOverChecklistData] = useState(null);
//   const [loading, setLoading] = useState(true); // For data fetching
//   const [submitting, setSubmitting] = useState(false); // For form submission
//   // For TL functionality: determine if we are in edit mode
//   const [isEditing, setIsEditing] = useState(false);
//   const [form] = Form.useForm();

//   // Dynamic questions (common to both modes)
//   const dynamicQuestions = [
//     {
//       question:
//         "Have all the tracks that the resigned Habotech was the owner of been explained and handed over to the Habotech taking over?",
//       statusName: "status1",
//       notesName: "notes1",
//     },
//     {
//       question:
//         "Has the resigned Habotech completed giving the required training and knowledge transfer for the Habotech taking over?",
//       statusName: "status2",
//       notesName: "notes2",
//     },
//     {
//       question:
//         "Have the user IDs and passwords of all the platforms been shared by the Habotech leaving with the Habotech taking over?",
//       statusName: "status3",
//       notesName: "notes3",
//     },
//     {
//       question:
//         "Have ongoing tasks/projects been handed over with clear instructions?",
//       statusName: "status4",
//       notesName: "notes4",
//     },
//     {
//       question:
//         "Have all relevant documents, files, and resources been shared with the Habotech taking over?",
//       statusName: "status5",
//       notesName: "notes5",
//     },
//     {
//       question:
//         "Has an introduction been arranged between the new joined/taking over habotech and key internal and external contacts related to the tasks?",
//       statusName: "status6",
//       notesName: "notes6",
//     },
//     {
//       question:
//         "Has the habotech taking over been briefed on all pending actions or next steps that need immediate attention?",
//       statusName: "status7",
//       notesName: "notes7",
//     },
//   ];

//   // Helper for TL patching: builds patch data from dynamic questions & HR signature
//   const buildPatchData = (oldRecord, newValues) => {
//     const patchData = {};
//     const toBool = (val) => (val === "Yes" ? true : false);
//     dynamicQuestions.forEach((item, index) => {
//       const oldStatus = oldRecord[`status_${index + 1}`];
//       const newStatus = toBool(newValues[item.statusName]);
//       if (newValues[item.statusName] !== undefined && newStatus !== oldStatus) {
//         patchData[`status_${index + 1}`] = newStatus;
//       }
//       const oldComment = oldRecord[`comments${index + 1}`] || "";
//       const newComment = newValues[item.notesName] || "";
//       if (
//         newValues[item.notesName] !== undefined &&
//         newComment !== oldComment
//       ) {
//         patchData[`comments${index + 1}`] = newComment;
//       }
//     });
//     // If HR signature is provided, include it
//     if (newValues.hr_signature && newValues.hr_signature.originFileObj) {
//       patchData.hr_signature = newValues.hr_signature.originFileObj;
//     }
//     // Always update submitted_by_hr to true
//     patchData.submitted_by_hr = "true";
//     return patchData;
//   };

//   // Fetch checklist data on load
//   useEffect(() => {
//     setLoading(true);
//     axiosInstance
//       .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
//       .then((response) => {
//         console.log("Fetched Checklist Data:", response.data);
//         setHandOverChecklistData(response.data);
//         if (response.data.results?.length > 0) {
//           const checklist = response.data.results[0];
//           form.setFieldsValue({
//             status1: checklist.status_1 ? "Yes" : "No",
//             status2: checklist.status_2 ? "Yes" : "No",
//             status3: checklist.status_3 ? "Yes" : "No",
//             status4: checklist.status_4 ? "Yes" : "No",
//             status5: checklist.status_5 ? "Yes" : "No",
//             status6: checklist.status_6 ? "Yes" : "No",
//             status7: checklist.status_7 ? "Yes" : "No",
//             notes1: checklist.comments1 ?? "",
//             notes2: checklist.comments2 ?? "",
//             notes3: checklist.comments3 ?? "",
//             notes4: checklist.comments4 ?? "",
//             notes5: checklist.comments5 ?? "",
//             notes6: checklist.comments6 ?? "",
//             notes7: checklist.comments7 ?? "",
//           });
//           if (!selectedResignation?.is_tl) {
//             setIsEditing(true);
//           }
//         }
//       })
//       .catch((error) => {
//         console.error("Error fetching checklist:", error);
//       })
//       .finally(() => setLoading(false));
//   }, [selectedResignation, form]);

//   // Handle form submission
//   // const handleSubmit = (values) => {
//   //   const checklistId = handOverChecklistData?.results?.[0]?.id;
//   //   if (!checklistId) {
//   //     message.error("Checklist record not found.");
//   //     return;
//   //   }
//   //   if (selectedResignation?.is_tl) {
//   //     // TL mode: send dynamic question changes along with HR signature (if any)
//   //     setSubmitting(true);
//   //     const oldRecord = handOverChecklistData.results[0];
//   //     const patchData = buildPatchData(oldRecord, values);
//   //     const formData = new FormData();
//   //     Object.entries(patchData).forEach(([key, value]) => {
//   //       formData.append(key, value);
//   //     });
//   //     console.log("Form Data (TL PATCH) Before Submission:");
//   //     for (let pair of formData.entries()) {
//   //       console.log(
//   //         `${pair[0]}:`,
//   //         pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
//   //       );
//   //     }
//   //     axiosInstance.patch(`${separation}${selectedResignation?.id}/`, {
//   //       status: "Approved",
//   //     });
//   //     axiosInstance
//   //       .patch(`${handoverCheckList}${checklistId}/`, formData, {
//   //         headers: { "Content-Type": "multipart/form-data" },
//   //       })
//   //       .then((response) => {
//   //         toast.success("Updated successfully!");
//   //         console.log("Response:", response.data);
//   //         onChecklistUpdate();
//   //         // Re-fetch checklist data to update UI in real time
//   //         axiosInstance
//   //           .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
//   //           .then((res) => {
//   //             setHandOverChecklistData(res.data);
//   //           })
//   //           .catch((err) =>
//   //             console.error("Error re-fetching checklist data:", err)
//   //           );
//   //       })

//   //       .catch((error) => {
//   //         toast.error("Failed to update.");
//   //         console.error("Error:", error);
//   //       })
//   //       .finally(() => setSubmitting(false));
//   //   } else {
//   //     // Non‑TL mode: require HR signature upload only
//   //     if (!values.hr_signature || !values.hr_signature.originFileObj) {
//   //       message.error("HR Signature is required!");
//   //       return;
//   //     }
//   //     setSubmitting(true);
//   //     const formData = new FormData();
//   //     formData.append("submitted_by_hr", "true");
//   //     formData.append("hr_signature", values.hr_signature.originFileObj);
//   //     console.log("Form Data (Non-TL PATCH) Before Submission:");
//   //     for (let pair of formData.entries()) {
//   //       console.log(
//   //         `${pair[0]}:`,
//   //         pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
//   //       );
//   //     }
//   //     axiosInstance.patch(`${separation}${selectedResignation?.id}/`, {
//   //       status: "Approved",
//   //     });
//   //     axiosInstance
//   //       .patch(`${handoverCheckList}${checklistId}/`, formData, {
//   //         headers: { "Content-Type": "multipart/form-data" },
//   //       })
//   //       .then((response) => {
//   //         onChecklistUpdate();
//   //         toast.success("Signature uploaded successfully!");
//   //         console.log("Response:", response.data);
//   //         axiosInstance
//   //           .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
//   //           .then((res) => {
//   //             setHandOverChecklistData(res.data);
//   //           })
//   //           .catch((err) =>
//   //             console.error("Error re-fetching checklist data:", err)
//   //           );
//   //       })
//   //       .catch((error) => {
//   //         toast.error("Failed to upload signature.");
//   //         console.error("Error:", error);
//   //       })
//   //       .finally(() => setSubmitting(false));
//   //   }
//   // };
//   const handleSubmit = (values) => {
//     const checklistId = handOverChecklistData?.results?.[0]?.id;

//     // 1. TL + no checklist record => POST
//     if (selectedResignation?.is_tl && !checklistId) {
//       setSubmitting(true);
//       const oldRecord = {}; // No existing record
//       const patchData = buildPatchData(oldRecord, values);

//       const formData = new FormData();
//       Object.entries(patchData).forEach(([key, value]) => {
//         formData.append(key, value);
//       });
//       formData.append("separation", selectedResignation?.id);
//       console.log("Form Data (TL POST) Before Submission:");
//       for (let pair of formData.entries()) {
//         console.log(
//           `${pair[0]}:`,
//           pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
//         );
//       }

//       // POST the new checklist
//       axiosInstance
//         .post(`${handoverCheckList}`, formData, {
//           headers: { "Content-Type": "multipart/form-data" },
//         })
//         .then((response) => {
//           toast.success("Checklist created successfully!");
//           console.log("Response:", response.data);

//           // Only patch separation status if signature is uploaded
//           if (values.hr_signature && values.hr_signature.originFileObj) {
//             axiosInstance.patch(`${separation}${selectedResignation?.id}/`, {
//               status: "Approved",
//             });
//           }

//           // Safely call onChecklistUpdate
//           // (CHANGED: check that it's a function before calling)
//           if (typeof onChecklistUpdate === "function") {
//             onChecklistUpdate();
//           }

//           // Re-fetch checklist data to update UI in real time
//           axiosInstance
//             .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
//             .then((res) => {
//               setHandOverChecklistData(res.data);
//             })
//             .catch((err) =>
//               console.error("Error re-fetching checklist data:", err)
//             );
//         })
//         .catch((error) => {
//           toast.error("Failed to create checklist.");
//           console.error("Error:", error);
//         })
//         .finally(() => setSubmitting(false));

//       return; // prevent falling into the patch logic below
//     }

//     // 2. If there's STILL no checklistId, show an error
//     if (!checklistId) {
//       message.error("Checklist record not found.");
//       return;
//     }

//     // 3. TL with existing checklist => PATCH
//     if (selectedResignation?.is_tl) {
//       setSubmitting(true);
//       const oldRecord = handOverChecklistData.results[0];
//       const patchData = buildPatchData(oldRecord, values);
//       const formData = new FormData();
//       Object.entries(patchData).forEach(([key, value]) => {
//         formData.append(key, value);
//       });
//       console.log("Form Data (TL PATCH) Before Submission:");
//       for (let pair of formData.entries()) {
//         console.log(
//           `${pair[0]}:`,
//           pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
//         );
//       }

//       // Only patch separation status if a signature is uploaded
//       if (values.hr_signature && values.hr_signature.originFileObj) {
//         axiosInstance.patch(`${separation}${selectedResignation?.id}/`, {
//           status: "Approved",
//         });
//       }
//       axiosInstance
//         .patch(`${handoverCheckList}${checklistId}/`, formData, {
//           headers: { "Content-Type": "multipart/form-data" },
//         })
//         .then((response) => {
//           toast.success("Updated successfully!");
//           console.log("Response:", response.data);

//           // Safely call onChecklistUpdate
//           // (CHANGED: check that it's a function before calling)
//           if (typeof onChecklistUpdate === "function") {
//             onChecklistUpdate();
//           }

//           // Re-fetch checklist data to update UI in real time
//           axiosInstance
//             .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
//             .then((res) => {
//               setHandOverChecklistData(res.data);
//             })
//             .catch((err) =>
//               console.error("Error re-fetching checklist data:", err)
//             );
//         })
//         .catch((error) => {
//           toast.error("Failed to update.");
//           console.error("Error:", error);
//         })
//         .finally(() => setSubmitting(false));
//     } else {
//       // 4. Non‑TL mode => require HR signature
//       if (!values.hr_signature || !values.hr_signature.originFileObj) {
//         message.error("HR Signature is required!");
//         return;
//       }
//       setSubmitting(true);
//       const formData = new FormData();
//       formData.append("submitted_by_hr", "true");
//       formData.append("hr_signature", values.hr_signature.originFileObj);
//       console.log("Form Data (Non‑TL PATCH) Before Submission:");
//       for (let pair of formData.entries()) {
//         console.log(
//           `${pair[0]}:`,
//           pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
//         );
//       }

//       // Only patch separation status if signature is provided
//       if (values.hr_signature && values.hr_signature.originFileObj) {
//         axiosInstance.patch(`${separation}${selectedResignation?.id}/`, {
//           status: "Approved",
//         });
//       }
//       axiosInstance
//         .patch(`${handoverCheckList}${checklistId}/`, formData, {
//           headers: { "Content-Type": "multipart/form-data" },
//         })
//         .then((response) => {
//           // Safely call onChecklistUpdate
//           // (CHANGED: check that it's a function before calling)
//           if (typeof onChecklistUpdate === "function") {
//             onChecklistUpdate();
//           }

//           toast.success("Signature uploaded successfully!");
//           console.log("Response:", response.data);
//           axiosInstance
//             .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
//             .then((res) => {
//               setHandOverChecklistData(res.data);
//             })
//             .catch((err) =>
//               console.error("Error re-fetching checklist data:", err)
//             );
//         })
//         .catch((error) => {
//           toast.error("Failed to upload signature.");
//           console.error("Error:", error);
//         })
//         .finally(() => setSubmitting(false));
//     }
//   };

//   // Upload props remain unchanged
//   const uploadProps = {
//     beforeUpload: (file) => {
//       const isValid = file.type === "image/png" || file.type === "image/jpeg";
//       if (!isValid) {
//         message.error("You can only upload PNG or JPEG file!");
//       }
//       return isValid;
//     },
//     onRemove: () => {
//       message.success("File removed successfully!");
//     },
//   };

//   // Render signature sections for non‑TL in a grid layout
//   const renderNonTlSignatures = () => {
//     return (
//       <>
//         <Row gutter={16} style={{ marginTop: 20 }}>
//           <Col span={12} style={{ textAlign: "center" }}>
//             <h4>TL Signature</h4>
//             {handOverChecklistData?.results?.[0]?.tl_signature ? (
//               <Image
//                 width={250}
//                 src={handOverChecklistData?.results[0]?.tl_signature}
//                 preview={{ zIndex: 3000, getContainer: document.body }}
//               />
//             ) : (
//               "Not Approved by TL"
//             )}
//           </Col>
//           <Col span={12} style={{ textAlign: "center" }}>
//             <h4>Employee Leaving Signature</h4>
//             {handOverChecklistData?.results?.[0]?.person_signature ? (
//               <Image
//                 width={250}
//                 src={handOverChecklistData?.results[0]?.person_signature}
//                 preview={{ zIndex: 3000, getContainer: document.body }}
//               />
//             ) : (
//               "Not Approved by Person Resigning"
//             )}
//           </Col>
//         </Row>
//         <Row gutter={16} style={{ marginTop: 20 }}>
//           <Col span={12} style={{ textAlign: "center" }}>
//             <h4>Person Taking Over Signature</h4>
//             {handOverChecklistData?.results?.[0]
//               ?.taking_over_person_signature ? (
//               <Image
//                 width={250}
//                 src={
//                   handOverChecklistData?.results[0]
//                     ?.taking_over_person_signature
//                 }
//                 preview={{ zIndex: 3000, getContainer: document.body }}
//               />
//             ) : (
//               "Not Approved by Person Taking over"
//             )}
//           </Col>
//           <Col span={12} style={{ textAlign: "center" }}>
//             <h4>HR Signature</h4>
//             {handOverChecklistData?.results?.[0]?.hr_signature ? (
//               <>
//                 <Image
//                   width={250}
//                   src={handOverChecklistData?.results[0]?.hr_signature}
//                   preview={{ zIndex: 3000, getContainer: document.body }}
//                 />
//                 <Form.Item
//                   label=""
//                   name="hr_signature"
//                   valuePropName="file"
//                   getValueFromEvent={(e) =>
//                     e && e.fileList.length > 0 ? e.fileList[0] : null
//                   }
//                 >
//                   <Upload
//                     {...uploadProps}
//                     maxCount={1}
//                     beforeUpload={() => false}
//                   >
//                     <Button
//                       icon={<UploadOutlined />}
//                       disabled={submitting}
//                       style={{
//                         marginTop: 8,
//                         backgroundColor: "#374151",
//                         borderColor: "#374151",
//                         borderRadius: "4px",
//                         color: "#fff",
//                       }}
//                     >
//                       Reupload HR Signature
//                     </Button>
//                   </Upload>
//                 </Form.Item>
//               </>
//             ) : (
//               <Form.Item
//                 label=""
//                 name="hr_signature"
//                 valuePropName="file"
//                 getValueFromEvent={(e) =>
//                   e && e.fileList.length > 0 ? e.fileList[0] : null
//                 }
//               >
//                 <Upload
//                   {...uploadProps}
//                   maxCount={1}
//                   beforeUpload={() => false}
//                 >
//                   <Button
//                     icon={<UploadOutlined />}
//                     disabled={submitting}
//                     style={{
//                       marginTop: 8,
//                       backgroundColor: "#374151",
//                       borderColor: "#374151",
//                       borderRadius: "4px",
//                       color: "#fff",
//                     }}
//                   >
//                     Upload HR Signature
//                   </Button>
//                 </Upload>
//               </Form.Item>
//             )}
//           </Col>
//         </Row>
//       </>
//     );
//   };

//   return (
//     <div
//       style={{
//         margin: "auto",
//         padding: "20px",
//         backgroundColor: "#F9FAFB",
//         borderRadius: "8px",
//         border: "1px solid #E5E7EB",
//       }}
//     >
//       <h3
//         style={{
//           backgroundColor: "#374151",
//           color: "#FFFFFF",
//           padding: "10px",
//           borderRadius: "4px",
//           marginBottom: "20px",
//         }}
//       >
//         Handover Checklist
//       </h3>
//       <p>
//         This checklist needs to be completed and signed by the team
//         leader/supervisor in order for the resigned employee to receive the pay
//         for the month and the experience certificate.
//       </p>

//       {loading ? (
//         <Spin
//           size="large"
//           style={{ display: "block", textAlign: "center", margin: "20px 0" }}
//         />
//       ) : (
//         <Form
//           form={form}
//           layout="vertical"
//           onFinish={handleSubmit}
//           style={{ marginTop: "20px" }}
//         >
//           <Row gutter={16}>
//             <Col span={8}>
//               <Form.Item
//                 label="Name"
//                 name="name"
//                 initialValue={
//                   selectedResignation?.user_details?.employee_name ?? ""
//                 }
//               >
//                 <Input readOnly />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item
//                 label="Department"
//                 name="department"
//                 initialValue={
//                   selectedResignation?.user_details?.employment_details?.[0]
//                     ?.department_detail?.name
//                 }
//               >
//                 <Input readOnly />
//               </Form.Item>
//             </Col>
//             <Col span={8}>
//               <Form.Item
//                 label="Designation"
//                 name="designation"
//                 initialValue={
//                   selectedResignation?.user_details?.employment_details?.[0]
//                     ?.designation_detail?.name
//                 }
//               >
//                 <Input readOnly />
//               </Form.Item>
//             </Col>
//           </Row>
//           <Row gutter={16}>
//             <Col span={8}>
//               <Form.Item
//                 label="Last Working Day"
//                 name="lastWorkingDay"
//                 initialValue={selectedResignation?.last_working_date}
//               >
//                 <Input readOnly />
//               </Form.Item>
//             </Col>
//           </Row>

//           {dynamicQuestions.map((item, index) => (
//             <div key={index}>
//               <h6 style={{ marginTop: "20px" }}>{item.question}</h6>
//               <Row gutter={16}>
//                 <Col span={8}>
//                   {selectedResignation?.is_tl ? (
//                     <Form.Item label="Status" name={item.statusName}>
//                       <Select disabled={submitting} placeholder="Select">
//                         <Option value="Yes">Yes</Option>
//                         <Option value="No">No</Option>
//                       </Select>
//                     </Form.Item>
//                   ) : (
//                     <Form.Item label="Status" name={item.statusName}>
//                       <Input
//                         value={
//                           form.getFieldValue(item.statusName) || "Loading..."
//                         }
//                         readOnly
//                       />
//                     </Form.Item>
//                   )}
//                 </Col>
//                 <Col span={16}>
//                   {selectedResignation?.is_tl ? (
//                     <Form.Item label="Notes/Comments" name={item.notesName}>
//                       <Input.TextArea
//                         disabled={submitting}
//                         placeholder="Notes/Comments"
//                         autoSize
//                       />
//                     </Form.Item>
//                   ) : (
//                     <Form.Item label="Notes/Comments" name={item.notesName}>
//                       <Input.TextArea
//                         value={
//                           form.getFieldValue(item.notesName) || "Loading..."
//                         }
//                         readOnly
//                       />
//                     </Form.Item>
//                   )}
//                 </Col>
//               </Row>
//             </div>
//           ))}
//           {selectedResignation?.id && (
//             <>
//               <Row>
//                 <Col span={12}>
//                   {handOverChecklistData?.results?.[0]?.tl_signature && (
//                     <>
//                       <h3 style={{ marginTop: "20px" }}>
//                         TL Leaving Signature
//                       </h3>
//                       <Image
//                         width={300}
//                         src={handOverChecklistData?.results[0]?.tl_signature}
//                         preview={{
//                           zIndex: 3000,
//                           getContainer: document.body,
//                         }}
//                       />
//                     </>
//                   )}
//                 </Col>
//                 <Col span={12}>
//                   {handOverChecklistData?.results?.[0]
//                     ?.taking_over_person_signature && (
//                     <>
//                       <h3 style={{ marginTop: "20px" }}>
//                         Taking Over Person Signature
//                       </h3>
//                       <Image
//                         width={300}
//                         src={
//                           handOverChecklistData?.results[0]
//                             ?.taking_over_person_signature
//                         }
//                         preview={{
//                           zIndex: 3000,
//                           getContainer: document.body,
//                         }}
//                       />
//                     </>
//                   )}
//                 </Col>
//               </Row>
//             </>
//           )}
//           {selectedResignation?.is_tl ? (
//             // TL mode: show only HR Signature upload (or image if already uploaded)
//             handOverChecklistData?.results?.[0]?.hr_signature ? (
//               <>
//                 <Row>
//                   <Col span={12}>
//                     <h3 style={{ marginTop: "20px" }}>HR's Signature</h3>
//                     <Image
//                       width={300}
//                       src={handOverChecklistData?.results[0]?.hr_signature}
//                       preview={{ zIndex: 3000, getContainer: document.body }}
//                     />
//                     <Form.Item
//                       label="HR Signature"
//                       name="hr_signature"
//                       valuePropName="file"
//                       getValueFromEvent={(e) =>
//                         e && e.fileList.length > 0 ? e.fileList[0] : null
//                       }
//                     >
//                       <Upload
//                         {...uploadProps}
//                         maxCount={1}
//                         beforeUpload={() => false}
//                       >
//                         <Button
//                           icon={<UploadOutlined />}
//                           disabled={submitting}
//                           style={{
//                             marginTop: 8,
//                             backgroundColor: "#374151",
//                             borderColor: "#374151",
//                             borderRadius: "4px",
//                             color: "#fff",
//                           }}
//                         >
//                           Reupload HR Signature
//                         </Button>
//                       </Upload>
//                     </Form.Item>
//                   </Col>
//                 </Row>
//               </>
//             ) : (
//               handOverChecklistData?.results?.[0]?.id &&
//               handOverChecklistData?.results?.[0]
//                 ?.submitted_by_employee_taking === true &&
//               handOverChecklistData?.results?.[0]
//                 ?.submitted_by_employee_resigning === true && (
//                 <Form.Item
//                   label="HR Signature"
//                   name="hr_signature"
//                   valuePropName="file"
//                   getValueFromEvent={(e) =>
//                     e && e.fileList.length > 0 ? e.fileList[0] : null
//                   }
//                 >
//                   <Upload
//                     {...uploadProps}
//                     maxCount={1}
//                     beforeUpload={() => false}
//                   >
//                     <Button
//                       icon={<UploadOutlined />}
//                       disabled={submitting}
//                       style={{
//                         marginTop: 8,
//                         backgroundColor: "#374151",
//                         borderColor: "#374151",
//                         borderRadius: "4px",
//                         color: "#fff",
//                       }}
//                     >
//                       Upload HR Signature
//                     </Button>
//                   </Upload>
//                 </Form.Item>
//               )
//             )
//           ) : (
//             // Non‑TL mode: show all signature sections in a two-column grid.
//             renderNonTlSignatures()
//           )}

//           {/* Submit Button */}
//           <div style={{ marginTop: "20px" }}>
//             {selectedResignation?.submitted_by_hr ? (
//               <Button
//                 type="primary"
//                 disabled
//                 style={{
//                   backgroundColor: "#374151",
//                   borderColor: "#374151",
//                   borderRadius: "4px",
//                 }}
//               >
//                 Approved
//               </Button>
//             ) : (
//               <Button
//                 type="primary"
//                 htmlType="submit"
//                 disabled={submitting}
//                 style={{
//                   backgroundColor: "#374151",
//                   borderColor: "#374151",
//                   borderRadius: "4px",
//                 }}
//               >
//                 {submitting ? <Spin /> : "Submit"}
//               </Button>
//             )}
//           </div>
//         </Form>
//       )}
//     </div>
//   );
// };

// export default HandoverChecklist;
import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Select,
  message,
  Image,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  handoverCheckList,
  separation,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

const { Option } = Select;

const HandoverChecklist = ({ selectedResignation, onChecklistUpdate }) => {
  console.log(selectedResignation);
  const [handOverChecklistData, setHandOverChecklistData] = useState(null);
  const [loading, setLoading] = useState(true); // For data fetching
  const [submitting, setSubmitting] = useState(false); // For form submission
  // For TL functionality: determine if we are in edit mode
  const [isEditing, setIsEditing] = useState(false);
  const [form] = Form.useForm();

  // Dynamic questions (common to both modes)
  const dynamicQuestions = [
    {
      question:
        "Have all the tracks that the resigned Habotech was the owner of been explained and handed over to the Habotech taking over?",
      statusName: "status1",
      notesName: "notes1",
    },
    {
      question:
        "Has the resigned Habotech completed giving the required training and knowledge transfer for the Habotech taking over?",
      statusName: "status2",
      notesName: "notes2",
    },
    {
      question:
        "Have the user IDs and passwords of all the platforms been shared by the Habotech leaving with the Habotech taking over?",
      statusName: "status3",
      notesName: "notes3",
    },
    {
      question:
        "Have ongoing tasks/projects been handed over with clear instructions?",
      statusName: "status4",
      notesName: "notes4",
    },
    {
      question:
        "Have all relevant documents, files, and resources been shared with the Habotech taking over?",
      statusName: "status5",
      notesName: "notes5",
    },
    {
      question:
        "Has an introduction been arranged between the new joined/taking over habotech and key internal and external contacts related to the tasks?",
      statusName: "status6",
      notesName: "notes6",
    },
    {
      question:
        "Has the habotech taking over been briefed on all pending actions or next steps that need immediate attention?",
      statusName: "status7",
      notesName: "notes7",
    },
  ];

  // Helper for TL patching: builds patch data from dynamic questions & HR signature
  const buildPatchData = (oldRecord, newValues) => {
    const patchData = {};
    const toBool = (val) => (val === "Yes" ? true : false);
    dynamicQuestions.forEach((item, index) => {
      const oldStatus = oldRecord[`status_${index + 1}`];
      const newStatus = toBool(newValues[item.statusName]);
      if (newValues[item.statusName] !== undefined && newStatus !== oldStatus) {
        patchData[`status_${index + 1}`] = newStatus;
      }
      const oldComment = oldRecord[`comments${index + 1}`] || "";
      const newComment = newValues[item.notesName] || "";
      if (
        newValues[item.notesName] !== undefined &&
        newComment !== oldComment
      ) {
        patchData[`comments${index + 1}`] = newComment;
      }
    });
    // If HR signature is provided, include it
    if (newValues.hr_signature && newValues.hr_signature.originFileObj) {
      patchData.hr_signature = newValues.hr_signature.originFileObj;
    }
    // Always update submitted_by_hr to true
    patchData.submitted_by_hr = "true";
    return patchData;
  };

  // Fetch checklist data on load
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
      .then((response) => {
        console.log("Fetched Checklist Data:", response.data);
        setHandOverChecklistData(response.data);
        if (response.data.results?.length > 0) {
          const checklist = response.data.results[0];
          form.setFieldsValue({
            status1: checklist.status_1 ? "Yes" : "No",
            status2: checklist.status_2 ? "Yes" : "No",
            status3: checklist.status_3 ? "Yes" : "No",
            status4: checklist.status_4 ? "Yes" : "No",
            status5: checklist.status_5 ? "Yes" : "No",
            status6: checklist.status_6 ? "Yes" : "No",
            status7: checklist.status_7 ? "Yes" : "No",
            notes1: checklist.comments1 ?? "",
            notes2: checklist.comments2 ?? "",
            notes3: checklist.comments3 ?? "",
            notes4: checklist.comments4 ?? "",
            notes5: checklist.comments5 ?? "",
            notes6: checklist.comments6 ?? "",
            notes7: checklist.comments7 ?? "",
          });
          if (!selectedResignation?.is_tl) {
            setIsEditing(true);
          }
        }
      })
      .catch((error) => {
        console.error("Error fetching checklist:", error);
      })
      .finally(() => setLoading(false));
  }, [selectedResignation, form]);

  // Handle form submission
  const handleSubmit = (values) => {
    const checklistId = handOverChecklistData?.results?.[0]?.id;

    // 1. TL + no checklist record => POST
    if (selectedResignation?.is_tl && !checklistId) {
      setSubmitting(true);
      const oldRecord = {}; // No existing record
      const patchData = buildPatchData(oldRecord, values);

      const formData = new FormData();
      Object.entries(patchData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("separation", selectedResignation?.id);
      console.log("Form Data (TL POST) Before Submission:");
      for (let pair of formData.entries()) {
        console.log(
          `${pair[0]}:`,
          pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
        );
      }

      // POST the new checklist
      axiosInstance
        .post(`${handoverCheckList}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          toast.success("Checklist created successfully!");
          console.log("Response:", response.data);

          // Only patch separation status if signature is uploaded
          if (values.hr_signature && values.hr_signature.originFileObj) {
            axiosInstance.patch(`${separation}${selectedResignation?.id}/`, {
              status: "Approved",
            });
          }

          // Safely call onChecklistUpdate
          if (typeof onChecklistUpdate === "function") {
            onChecklistUpdate();
          }

          // Re-fetch checklist data to update UI in real time
          axiosInstance
            .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
            .then((res) => {
              setHandOverChecklistData(res.data);
            })
            .catch((err) =>
              console.error("Error re-fetching checklist data:", err)
            );
        })
        .catch((error) => {
          toast.error("Failed to create checklist.");
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));

      return; // prevent falling into the patch logic below
    }

    // 2. If there's STILL no checklistId, show an error
    if (!checklistId) {
      message.error("Checklist record not found.");
      return;
    }

    // 3. TL with existing checklist => PATCH
    if (selectedResignation?.is_tl) {
      setSubmitting(true);
      const oldRecord = handOverChecklistData.results[0];
      const patchData = buildPatchData(oldRecord, values);
      const formData = new FormData();
      Object.entries(patchData).forEach(([key, value]) => {
        formData.append(key, value);
      });
      console.log("Form Data (TL PATCH) Before Submission:");
      for (let pair of formData.entries()) {
        console.log(
          `${pair[0]}:`,
          pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
        );
      }

      // Only patch separation status if a signature is uploaded
      if (values.hr_signature && values.hr_signature.originFileObj) {
        axiosInstance.patch(`${separation}${selectedResignation?.id}/`, {
          status: "Approved",
        });
      }
      axiosInstance
        .patch(`${handoverCheckList}${checklistId}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          toast.success("Updated successfully!");
          console.log("Response:", response.data);

          if (typeof onChecklistUpdate === "function") {
            onChecklistUpdate();
          }

          axiosInstance
            .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
            .then((res) => {
              setHandOverChecklistData(res.data);
            })
            .catch((err) =>
              console.error("Error re-fetching checklist data:", err)
            );
        })
        .catch((error) => {
          toast.error("Failed to update.");
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));
    } else {
      // 4. Non‑TL mode => require HR signature
      if (!values.hr_signature || !values.hr_signature.originFileObj) {
        message.error("HR Signature is required!");
        return;
      }
      setSubmitting(true);
      const formData = new FormData();
      formData.append("submitted_by_hr", "true");
      formData.append("hr_signature", values.hr_signature.originFileObj);
      console.log("Form Data (Non‑TL PATCH) Before Submission:");
      for (let pair of formData.entries()) {
        console.log(
          `${pair[0]}:`,
          pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
        );
      }

      if (values.hr_signature && values.hr_signature.originFileObj) {
        axiosInstance.patch(`${separation}${selectedResignation?.id}/`, {
          status: "Approved",
        });
      }
      axiosInstance
        .patch(`${handoverCheckList}${checklistId}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          if (typeof onChecklistUpdate === "function") {
            onChecklistUpdate();
          }
          toast.success("Signature uploaded successfully!");
          console.log("Response:", response.data);
          axiosInstance
            .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
            .then((res) => {
              setHandOverChecklistData(res.data);
            })
            .catch((err) =>
              console.error("Error re-fetching checklist data:", err)
            );
        })
        .catch((error) => {
          toast.error("Failed to upload signature.");
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));
    }
  };

  // Upload props remain unchanged
  const uploadProps = {
    beforeUpload: (file) => {
      const isValid = file.type === "image/png" || file.type === "image/jpeg";
      if (!isValid) {
        message.error("You can only upload PNG or JPEG file!");
      }
      return isValid;
    },
    onRemove: () => {
      message.success("File removed successfully!");
    },
  };

  // Render signature sections for non‑TL in a grid layout
  const renderNonTlSignatures = () => {
    return (
      <>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={12} style={{ textAlign: "center" }}>
            {handOverChecklistData?.results?.[0]?.tl_signature ? (
              <>
                <h4>TL Signature</h4>
                <Image
                  width={250}
                  src={handOverChecklistData?.results[0]?.tl_signature}
                  preview={{ zIndex: 3000, getContainer: document.body }}
                />
              </>
            ) : (
              "Not Approved by TL"
            )}
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            {handOverChecklistData?.results?.[0]?.person_signature ? (
              <>
                {" "}
                <h4>Employee Leaving Signature</h4>
                <Image
                  width={250}
                  src={handOverChecklistData?.results[0]?.person_signature}
                  preview={{ zIndex: 3000, getContainer: document.body }}
                />
              </>
            ) : (
              "Not Approved by Person Resigning"
            )}
          </Col>
        </Row>
        <Row gutter={16} style={{ marginTop: 20 }}>
          <Col span={12} style={{ textAlign: "center" }}>
            {handOverChecklistData?.results?.[0]
              ?.taking_over_person_signature ? (
              <>
                <h4>Person Taking Over Signature</h4>
                <Image
                  width={250}
                  src={
                    handOverChecklistData?.results[0]
                      ?.taking_over_person_signature
                  }
                  preview={{ zIndex: 3000, getContainer: document.body }}
                />
              </>
            ) : (
              "Not Approved by Person Taking over"
            )}
          </Col>
          <Col span={12} style={{ textAlign: "center" }}>
            {handOverChecklistData?.results?.[0]?.hr_signature ? (
              <>
                {" "}
                <h4>HR Signature</h4>
                <Image
                  width={250}
                  src={handOverChecklistData?.results[0]?.hr_signature}
                  preview={{ zIndex: 3000, getContainer: document.body }}
                />
              </>
            ) : (
              <Form.Item
                label=""
                name="hr_signature"
                valuePropName="file"
                getValueFromEvent={(e) =>
                  e && e.fileList.length > 0 ? e.fileList[0] : null
                }
              >
                <Upload
                  {...uploadProps}
                  maxCount={1}
                  beforeUpload={() => false}
                >
                  <Button
                    icon={<UploadOutlined />}
                    disabled={submitting}
                    style={{
                      marginTop: 8,
                      backgroundColor: "#374151",
                      borderColor: "#374151",
                      borderRadius: "4px",
                      color: "#fff",
                    }}
                  >
                    Upload HR Signature
                  </Button>
                </Upload>
              </Form.Item>
            )}
          </Col>
        </Row>
      </>
    );
  };

  return (
    <div
      style={{
        margin: "auto",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
      }}
    >
      <h3
        style={{
          backgroundColor: "#374151",
          color: "#FFFFFF",
          padding: "10px",
          borderRadius: "4px",
          marginBottom: "20px",
        }}
      >
        Handover Checklist
      </h3>
      <p>
        This checklist needs to be completed and signed by the team
        leader/supervisor in order for the resigned employee to receive the pay
        for the month and the experience certificate.
      </p>

      {loading ? (
        <Spin
          size="large"
          style={{ display: "block", textAlign: "center", margin: "20px 0" }}
        />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                initialValue={
                  selectedResignation?.user_details?.employee_name ?? ""
                }
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Department"
                name="department"
                initialValue={
                  selectedResignation?.user_details?.employment_details?.[0]
                    ?.department_detail?.name
                }
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Designation"
                name="designation"
                initialValue={
                  selectedResignation?.user_details?.employment_details?.[0]
                    ?.designation_detail?.name
                }
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Last Working Day"
                name="lastWorkingDay"
                initialValue={selectedResignation?.last_working_date}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>

          {dynamicQuestions.map((item, index) => (
            <div key={index}>
              <h6 style={{ marginTop: "20px" }}>{item.question}</h6>
              <Row gutter={16}>
                <Col span={8}>
                  {selectedResignation?.is_tl ? (
                    <Form.Item label="Status" name={item.statusName}>
                      <Select disabled={submitting} placeholder="Select">
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                  ) : (
                    <Form.Item label="Status" name={item.statusName}>
                      <Input
                        value={
                          form.getFieldValue(item.statusName) || "Loading..."
                        }
                        readOnly
                      />
                    </Form.Item>
                  )}
                </Col>
                <Col span={16}>
                  {selectedResignation?.is_tl ? (
                    <Form.Item label="Notes/Comments" name={item.notesName}>
                      <Input.TextArea
                        disabled={submitting}
                        placeholder="Notes/Comments"
                        autoSize
                      />
                    </Form.Item>
                  ) : (
                    <Form.Item label="Notes/Comments" name={item.notesName}>
                      <Input.TextArea
                        value={
                          form.getFieldValue(item.notesName) || "Loading..."
                        }
                        readOnly
                      />
                    </Form.Item>
                  )}
                </Col>
              </Row>
            </div>
          ))}

          {selectedResignation?.is_tl ? (
            handOverChecklistData?.results?.[0]?.hr_signature ? (
              <>
                {" "}
                <Row>
                  <Col span={12}>
                    {handOverChecklistData?.results?.[0]?.tl_signature && (
                      <>
                        <h3 style={{ marginTop: "20px" }}>
                          TL Leaving Signature
                        </h3>
                        <Image
                          width={300}
                          src={handOverChecklistData?.results[0]?.tl_signature}
                          preview={{
                            zIndex: 3000,
                            getContainer: document.body,
                          }}
                        />
                      </>
                    )}
                  </Col>
                  <Col span={12}>
                    {handOverChecklistData?.results?.[0]
                      ?.taking_over_person_signature && (
                      <>
                        <h3 style={{ marginTop: "20px" }}>
                          Taking Over Person Signature
                        </h3>
                        <Image
                          width={300}
                          src={
                            handOverChecklistData?.results[0]
                              ?.taking_over_person_signature
                          }
                          preview={{
                            zIndex: 3000,
                            getContainer: document.body,
                          }}
                        />
                      </>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col span={12}>
                    <h3 style={{ marginTop: "20px" }}>HR's Signature</h3>
                    <Image
                      width={300}
                      src={handOverChecklistData?.results[0]?.hr_signature}
                      preview={{ zIndex: 3000, getContainer: document.body }}
                    />
                    <Form.Item
                      label="HR Signature"
                      name="hr_signature"
                      valuePropName="file"
                      getValueFromEvent={(e) =>
                        e && e.fileList.length > 0 ? e.fileList[0] : null
                      }
                    >
                      <Upload
                        {...uploadProps}
                        maxCount={1}
                        beforeUpload={() => false}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={submitting}
                          style={{
                            marginTop: 8,
                            backgroundColor: "#374151",
                            borderColor: "#374151",
                            borderRadius: "4px",
                            color: "#fff",
                          }}
                        >
                          Reupload HR Signature
                        </Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>
              </>
            ) : (
              handOverChecklistData?.results?.[0]?.id &&
              handOverChecklistData?.results?.[0]
                ?.submitted_by_employee_taking === true &&
              handOverChecklistData?.results?.[0]
                ?.submitted_by_employee_resigning === true && (
                <>
                  <Row>
                    <Col span={12}>
                      {handOverChecklistData?.results?.[0]?.tl_signature && (
                        <>
                          <h3 style={{ marginTop: "20px" }}>
                            TL Leaving Signature
                          </h3>
                          <Image
                            width={300}
                            src={
                              handOverChecklistData?.results[0]?.tl_signature
                            }
                            preview={{
                              zIndex: 3000,
                              getContainer: document.body,
                            }}
                          />
                        </>
                      )}
                    </Col>
                    <Col span={12}>
                      {handOverChecklistData?.results?.[0]
                        ?.taking_over_person_signature && (
                        <>
                          <h3 style={{ marginTop: "20px" }}>
                            Taking Over Person Signature
                          </h3>
                          <Image
                            width={300}
                            src={
                              handOverChecklistData?.results[0]
                                ?.taking_over_person_signature
                            }
                            preview={{
                              zIndex: 3000,
                              getContainer: document.body,
                            }}
                          />
                        </>
                      )}
                    </Col>
                  </Row>

                  <Form.Item
                    label="HR Signature"
                    name="hr_signature"
                    valuePropName="file"
                    getValueFromEvent={(e) =>
                      e && e.fileList.length > 0 ? e.fileList[0] : null
                    }
                  >
                    <Upload
                      {...uploadProps}
                      maxCount={1}
                      beforeUpload={() => false}
                    >
                      <Button
                        icon={<UploadOutlined />}
                        disabled={submitting}
                        style={{
                          marginTop: 8,
                          backgroundColor: "#374151",
                          borderColor: "#374151",
                          borderRadius: "4px",
                          color: "#fff",
                        }}
                      >
                        Upload HR Signature
                      </Button>
                    </Upload>
                  </Form.Item>
                </>
              )
            )
          ) : (
            // Non‑TL mode: show all signature sections in a two-column grid.
            renderNonTlSignatures()
          )}

          <div style={{ marginTop: "20px" }}>
            {selectedResignation?.is_tl ? (
              <Button
                type="primary"
                htmlType="submit"
                disabled={submitting}
                style={{
                  backgroundColor: "#374151",
                  borderColor: "#374151",
                  borderRadius: "4px",
                }}
              >
                {submitting ? <Spin /> : "Submit"}
              </Button>
            ) : (
              // For non‑TL, only show the submit button if HR has not yet submitted their signature.
              !handOverChecklistData?.results[0]?.submitted_by_hr && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={submitting}
                  style={{
                    backgroundColor: "#374151",
                    borderColor: "#374151",
                    borderRadius: "4px",
                  }}
                >
                  {submitting ? <Spin /> : "Submit"}
                </Button>
              )
            )}
          </div>
        </Form>
      )}
    </div>
  );
};

export default HandoverChecklist;
