export const AppraisalSidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Employee Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employee-dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "Documents",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-text",
        subMenus: [
          {
            menuValue: "Add Document",

            route: "/file-upload",
          },
          {
            menuValue: "View Documents",

            route: "/view-documents",
          },
          {
            menuValue: "Document Naming",

            route: "/document-naming",
          },
        ],
      },
      {
        menuValue: "ZII",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-list",
        subMenus: [
          {
            menuValue: "Add ZII",
            route: "/add-zii",
          },
          {
            menuValue: "Your ZII",
            route: "/employee-zii",
          },
        ],
      },
      {
        menuValue: "Courses",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-graduation-cap",
        subMenus: [
          {
            menuValue: "Your Courses",
            route: "/employee-courses",
          },
        ],
      },

      {
        menuValue: "Policies",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-file-pdf-o",
        subMenus: [
          {
            menuValue: "View Policies",
            route: "/employee-policy",
          },
        ],
      },
      {
        menuValue: "Appraisal",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-star-o",
        subMenus: [
          {
            menuValue: "Weekly Appraisal",
            route: "/employee-appraisal",
          },
          {
            menuValue: "My Appraisal",
            route: "/appraisal-table",
          },
        ],
      },
      {
        menuValue: "Attendance",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar-check-o",
        subMenus: [
          {
            menuValue: "Active Employees",
            route: "/daily-log",
          },
          {
            menuValue: "Your Attendance",
            route: "/employee-attendance",
          },
        ],
      },
      {
        menuValue: "Holidays",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/employee-holidays",
        icon: "la la-calendar-minus-o",
      },
      {
        menuValue: "Leaves",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-calendar",
        subMenus: [
          {
            menuValue: "Leave Form",
            route: "/leaves-form",
          },
          {
            menuValue: "My Leaves",
            route: "/your-leaves",
          },
          {
            menuValue: "Your Task",
            route: "/your-task-leaves",
          },
        ],
      },
      {
        menuValue: "QPA",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-folder",
        subMenus: [
          {
            menuValue: "Nominate for QPA",
            route: "/nomination-details",
          },
          {
            menuValue: "Nominated QPA",
            route: "/nominated-qpa",
          },
          {
            menuValue: "Your QPA",
            route: "/your-qpa",
          },
          {
            menuValue: "End Document",
            route: "/end-document-qpa",
          },
        ],
      },
      {
        menuValue: "Resignation",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-external-link-square",
        subMenus: [
          {
            menuValue: "Resignation Form",
            route: "/resignation-form",
          },
          {
            menuValue: "View Resignation",
            route: "/resignation-letter",
          },
          {
            menuValue: "Handover Assigned Tasks",
            route: "/takeover-assigned-tasks",
          },
          {
            menuValue: "Handover - Taking over person",
            route: "/view-assigned-handovers",
          },

          // {
          //   menuValue: "Handing Over taking over from",
          //   route: "/handing-over-form",
          // },
        ],
      },
      {
        menuValue: "View Assigned Task",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/view-assigned-task",
        icon: "la la-eye",
      },
      {
        menuValue: "Weekly Review",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-folder-open",
        subMenus: [
          {
            menuValue: "Add Review",
            route: "/add-weekly-review",
          },
          {
            menuValue: "View Review",
            route: "/view-weekly-review",
          },
        ],
      },
    ],
  },
];
