import React, { useState, useEffect } from "react";
import { Typography, Spin, Tag, Button, Row, Col, Empty } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { handover } from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { FaExternalLinkAlt } from "react-icons/fa";
import moment from "moment";

const { Title, Paragraph } = Typography;

const ViewHandoverForm = ({ separationId, isTlforHR }) => {
  const [loading, setLoading] = useState(true);
  const [handoverData, setHandoverData] = useState(null);

  useEffect(() => {
    fetchHandoverData();
  }, [separationId]);

  const fetchHandoverData = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${handover}?separation=${separationId}`
      );
      setHandoverData(response.data.results[0]);
    } catch (error) {
      console.error("Error fetching handover data:", error);
      toast.error("Error fetching handover data");
    } finally {
      setLoading(false);
    }
  };

  const getApprovalStatus = (isApproved) => {
    if (isApproved === true) {
      return <Tag color="green">Approved</Tag>;
    } else if (isApproved === false || isApproved === null) {
      return <Tag color="yellow">Pending</Tag>;
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "200px",
        }}
      >
        <Spin tip="Loading handover details..." size="large" />
      </div>
    );
  }

  if (!handoverData) {
    return <Paragraph>No handover data available.</Paragraph>;
  }

  return (
    <div className="handover-view">
      {/* Approval Status Section */}
      <div
        style={{
          backgroundColor: "#f5f5f5",
          padding: "16px",
          borderRadius: "8px",
          marginBottom: "20px",
          width: "100%",
        }}
      >
        <Title level={5} style={{ marginTop: 0, marginBottom: "16px" }}>
          Approval Status
        </Title>
        <div
          style={{
            display: "flex",
            gap: "32px",
            flexWrap: "wrap",
            width: "100%",
          }}
        >
          {!isTlforHR && (
            <div>
              <strong>Team Lead:</strong>{" "}
              {getApprovalStatus(handoverData?.is_tl_approved)}
            </div>
          )}
          <div>
            <strong>HR:</strong>{" "}
            {getApprovalStatus(handoverData?.is_hr_approved)}
          </div>
          <div>
            <strong>Person Taking Over:</strong>{" "}
            {getApprovalStatus(handoverData?.confirm_by_taking_over_person)}
          </div>
        </div>
        {/*  */}
      </div>

      {/* Platform Information */}
      <Title level={5}>Company Credentials</Title>
      <div className="mb-4">
        {handoverData?.platform?.map((platform, index) => (
          <div key={index} className="platform-card">
            <strong>Platform Name:</strong> {platform.name}
            <br />
            <strong>Username:</strong> {platform.username}
            <br />
            <strong>Password:</strong> {platform.password}
          </div>
        ))}
      </div>

      {/* Contact Information */}
      <Title level={5}>Future Contact Information</Title>
      <div className="mb-4" style={{ width: "100%" }}>
        <Paragraph>
          <strong>Email:</strong> {handoverData?.email}
          <br />
          <strong>Phone:</strong> {handoverData?.phone}
        </Paragraph>
      </div>

      {/* External Contacts Section */}
      <div className="section-container mt-4">
        <div className="bg-orange text-white py-2 ps-2 mb-3">
          External Contact Information
        </div>

        {handoverData?.handover_detail?.length > 0 ? (
          handoverData.handover_detail.map((detail, detailIndex) => (
            <div key={detailIndex} className="handover-detail-section">
              {detail.external_contact?.length > 0 ? (
                detail.external_contact.map((contact, index) => (
                  <div key={contact.id} className="border p-3 mb-3">
                    <h6 className="mb-3">Contact {index + 1}</h6>

                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <div className="field-container">
                          <label className="fw-bold">
                            Name of the Contact:
                          </label>
                          <span>{contact.name || "N/A"}</span>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="field-container">
                          <label className="fw-bold">
                            Email Address / Username:
                          </label>
                          <span>{contact.email || "N/A"}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]} className="mt-2">
                      <Col span={12}>
                        <div className="field-container">
                          <label className="fw-bold">Phone:</label>
                          <span>{contact.phone || "N/A"}</span>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="field-container">
                          <label className="fw-bold">Position:</label>
                          <span>{contact.position || "N/A"}</span>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]} className="mt-2">
                      <Col span={12}>
                        <div className="field-container">
                          <label className="fw-bold">
                            What does he/ She do for us:
                          </label>
                          <span>{contact.detail || "N/A"}</span>
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} className="mt-2">
                      <Col span={12}>
                        <div className="field-container">
                          <label className="fw-bold">
                            Introduction Done with Taking Over Habotech:
                          </label>
                          <span>
                            {contact.is_induction ? (
                              <Tag color="green">Yes</Tag>
                            ) : (
                              <Tag color="red">No</Tag>
                            )}
                          </span>
                        </div>
                      </Col>
                      {contact.is_induction && (
                        <Col span={12}>
                          <div className="field-container">
                            <label className="fw-bold">
                              Introduction Date:
                            </label>
                            <span>
                              {contact.intro_date
                                ? moment(contact.intro_date).format(
                                    "YYYY-MM-DD"
                                  )
                                : "N/A"}
                            </span>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          ))
        ) : (
          <Empty description="No handover details found" />
        )}
      </div>

      {/* Handover Details */}
      {/* <Title level={4} style={{ marginTop: "15px" }}>
        Handover Details
      </Title> */}
      <div
        className="text-white py-2 ps-2 mb-3"
        style={{ backgroundColor: "#FD715C" }}
      >
        Track Handover Details
      </div>
      <div>
        {handoverData?.handover_detail?.map((track, trackIndex) => (
          <div key={trackIndex} className="track-container border p-3 mb-3">
            <Title level={5} style={{ marginBottom: "16px" }}>
              Name of the Track: {track.track_name}
              <br />
              Current Track Status:{" "}
              <Tag
                color={track.track_status === "Completed" ? "green" : "orange"}
              >
                {track.track_status}
              </Tag>
            </Title>

            {track.comments && (
              <Paragraph>
                <strong>Notes/Comments:</strong> {track.comments}
              </Paragraph>
            )}
            {track.tasks?.length > 0 && (
              <Title level={5}>Tasks assigned under the track</Title>
            )}
            {track.tasks?.map((task, taskIndex) => (
              <div
                key={taskIndex}
                className="task-container border-left p-3 mb-3"
              >
                <strong>Task {taskIndex + 1}:</strong> {task.name}
                <br />
                <strong>Training Completed:</strong>{" "}
                {task.is_training ? "Yes" : "No"}
                {task.is_training ? (
                  <>
                    {task.training_link && (
                      <>
                        <br />
                        <strong>Training Record Link:</strong>{" "}
                        <Button
                          type="link"
                          icon={<FaExternalLinkAlt />}
                          onClick={() =>
                            window.open(task.training_link, "_blank")
                          }
                        >
                          View Training
                        </Button>
                      </>
                    )}
                    {task.course_list?.length > 0 && (
                      <>
                        <br />
                        <strong>Trainings/Courses Required:</strong>{" "}
                        {task.course_list.join(", ")}
                      </>
                    )}
                  </>
                ) : (
                  task.not_reason && (
                    <>
                      <br />
                      <strong>
                        if No,(Specify Why is it not completed?):
                      </strong>{" "}
                      {task.not_reason}
                    </>
                  )
                )}
                {/* Task Platforms */}
                {task.platform?.length > 0 && (
                  <div className="mt-3">
                    <strong>Task Platforms:</strong>
                    {task.platform.map((platform, platformIndex) => (
                      <div key={platformIndex} className="platform-card mt-2">
                        <strong>Platform Name:</strong>{" "}
                        {platform.name ? platform.name : "N/A"}
                        <br />
                        <strong>Username:</strong>{" "}
                        {platform.username ? platform.username : "N/A"}
                        <br />
                        <strong>Password:</strong>{" "}
                        {platform.password ? platform.password : "N/A"}
                      </div>
                    ))}
                  </div>
                )}
                {/* Document Links */}
                {task.document_links?.length > 0 && (
                  <div className="mt-3">
                    <strong>Key Documents/Links:</strong>
                    <div className="table-responsive">
                      <table className="table table-bordered mt-2">
                        <thead>
                          <tr>
                            <th>Document Name</th>
                            <th>Links</th>
                          </tr>
                        </thead>
                        <tbody>
                          {task.document_links.map((doc, docIndex) => (
                            <tr key={docIndex}>
                              <td>{doc.name ? doc.name : "N/A"}</td>
                              <td>
                                {doc.url ? (
                                  <Button
                                    type="link"
                                    icon={<FaExternalLinkAlt />}
                                    onClick={() =>
                                      window.open(doc.url, "_blank")
                                    }
                                  >
                                    View Document
                                  </Button>
                                ) : (
                                  <span className="text-muted">
                                    No document available
                                  </span>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
                {task.assignee_details && (
                  <div className="mt-3">
                    <strong className="me-2">Assigned to:</strong>
                    {task.assignee_details.employee_name}
                    {task.is_reviewed && (
                      <Tag color="green" className="ms-2">
                        Reviewed
                      </Tag>
                    )}
                  </div>
                )}
              </div>
            ))}
          </div>
        ))}
        {handoverData.confirm_by_taking_over_person && (
          <div>
            <strong>Reviewed by Taking Over Person:</strong>{" "}
            {handoverData.confirm_by_taking_over_person ? "Yes" : "No"}
          </div>
        )}
      </div>

      <style jsx>{`
        .handover-view {
          padding: 20px;
        }
        .field-container {
          display: flex;
          gap: 10px;
          align-items: center;
        }
        .field-container label {
          margin-bottom: 0;
          min-width: 120px;
        }
        .section-container {
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }
        .responsive-container {
          width: 100%;
          max-width: 100%;
          overflow-x: hidden;
        }

        .contact-card {
          background: #fff;
          padding: 16px;
          border-radius: 8px;
          margin-bottom: 16px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }

        .platform-card {
          background: #fff;
          padding: 12px;
          border-radius: 6px;
          border: 1px solid #f0f0f0;
          margin-bottom: 12px;
        }

        .track-container {
          background: #fff;
          border-radius: 8px;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        }

        .task-container {
          background: #fafafa;
          border-radius: 6px;
        }

        .border-left {
          border-left: 4px solid #1890ff;
        }

        @media (max-width: 768px) {
          .table-responsive {
            overflow-x: auto;
          }

          .contact-card {
            padding: 12px;
          }
        }

        @media (max-width: 576px) {
          .contact-card {
            padding: 8px;
          }
        }
      `}</style>
    </div>
  );
};

export default ViewHandoverForm;
