import React, { useState, useEffect } from "react";
import Header from "../../../../layout/Header";
import EmployeeZIIList from "./EmployeeZIIList";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  getZii,
  employeeDetails,
  basicUserDetails,
} from "../../../../../helpers/endpoints/api_endpoints";

const EmployeeZII = () => {
  const [activeWeekButton, setActiveWeekButton] = useState(1);
  const [activeMonthButton, setActiveMonthButton] = useState(
    new Date().getUTCMonth() + 1
  );
  const [activeYear, setActiveYear] = useState(new Date().getFullYear());
  const [data, setData] = useState([]);
  const [employeeDetail, setEmployeeDetails] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  // Generate year options from 2024 to current year
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const startYear = 2024;
    const years = [];
    for (let year = currentYear; year >= startYear; year--) {
      years.push(year);
    }
    return years;
  };

  useEffect(() => {
    axiosInstance.get(employeeDetails).then(
      (response) => {
        setEmployeeDetails(response.data);
        console.log("Employee details", response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    if (employeeDetail) {
      const userId = employeeDetail.user.id;
      console.log("UserIDemployee", userId);
      axiosInstance.get(basicUserDetails(userId)).then((response) => {
        setUserDetails(response.data);
        console.log("Basic-user-details", response.data.id);
      });
    }
  }, [employeeDetail]);

  useEffect(() => {
    if (userDetails) {
      const userid = userDetails.id;
      console.log("User Idin :", userid);
      const monthName = new Date(Date.UTC(activeYear, activeMonthButton - 1))
        .toLocaleString("default", { month: "long" })
        .toLowerCase();
      const weekNumber = activeWeekButton;
      console.log(userid, monthName, weekNumber, activeYear);

      axiosInstance
        .get(getZii(userid, monthName, weekNumber, activeYear))
        .then((response) => {
          setData(response.data);
        })
        .catch((err) => console.log(err));
    }
  }, [userDetails, activeMonthButton, activeWeekButton, activeYear]);

  const handleWeekClick = (weekNumber) => {
    setActiveWeekButton(weekNumber);
    console.log(`Week-${weekNumber} button clicked`);
  };

  const handleMonthClick = (monthNumber) => {
    setActiveMonthButton(monthNumber);
    setActiveWeekButton(1); // Reset to the first week of the selected month
    console.log(`Month-${monthNumber} button clicked`);
  };

  const handleYearChange = (event) => {
    setActiveYear(parseInt(event.target.value));
    setActiveWeekButton(1); // Reset to the first week when year changes
    setActiveMonthButton(new Date().getUTCMonth() + 1); // Reset to current month when year changes
  };

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4 align-items-center">
            <div className="col">
              <h1 className="text-2xl font-bold mb-0">Your ZII's</h1>
            </div>
            <div className="col-auto">
              <select
                className="form-select form-select-sm"
                value={activeYear}
                onChange={handleYearChange}
                style={{
                  minWidth: "100px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                }}
              >
                {generateYearOptions().map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="tabs mb-1">
            {Array.from({ length: 12 }, (_, i) => i + 1).map((monthNumber) => (
              <div
                key={monthNumber}
                className={`tab-item ${
                  activeMonthButton === monthNumber ? "active" : ""
                }`}
                onClick={() => handleMonthClick(monthNumber)}
              >
                {new Date(Date.UTC(activeYear, monthNumber - 1)).toLocaleString(
                  "default",
                  { month: "long" }
                )}
              </div>
            ))}
          </div>
          <div className="week-buttons mb-1">
            {[1, 2, 3, 4, 5].map((weekNumber) => (
              <button
                key={weekNumber}
                type="button"
                className={`btn mx-1 ${
                  activeWeekButton === weekNumber
                    ? "btn-success"
                    : "btn-outline-success"
                }`}
                onClick={() => handleWeekClick(weekNumber)}
              >
                Week-{weekNumber}
              </button>
            ))}
          </div>
          <EmployeeZIIList filteredData={data} hideButtons={false} />
        </div>
      </div>
      <style jsx>{`
        .tabs {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          border-bottom: 2px solid #ddd;
          overflow-x: auto;
        }
        .tab-item {
          cursor: pointer;
          padding: 10px 20px;
          color: var(--text-color, gray);
          white-space: nowrap;
        }
        .tab-item.active {
          color: var(--active-color, black);
          font-weight: bold;
          border-bottom: 2px solid var(--active-color, black);
        }
        [data-layout-mode="dark"] .tab-item {
          color: white;
        }
        [data-layout-mode="dark"] .tab-item.active {
          color: #1890ff;
          border-bottom-color: #1890ff;
        }
        .form-select {
          padding: 0.375rem 2.25rem 0.375rem 0.75rem;
          background-color: #fff;
          cursor: pointer;
        }
        .form-select:focus {
          border-color: #80bdff;
          box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
        }
        .week-buttons {
          display: flex;
          flex-wrap: wrap;
          gap: 8px;
          justify-content: center;
        }
        @media (max-width: 768px) {
          .tabs {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 5px;
          }
          .tab-item {
            text-align: center;
          }
          .week-buttons {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 8px;
            padding: 0 10px;
          }
          .week-buttons .btn {
            margin: 0 !important;
            width: 100%;
            font-size: 14px;
            padding: 6px 4px;
          }
        }
        @media (max-width: 480px) {
          .week-buttons {
            grid-template-columns: repeat(2, 1fr);
          }
        }
      `}</style>
    </>
  );
};

export default EmployeeZII;
