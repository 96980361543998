// export const ACCOUNT_INITIALIZE = "ACCOUNT_INITIALIZE"
export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_PROFILE = "SET_PROFILE";
export const GET_USER_INFO = "GET_USER_INFO";

export const asyncLogout = () => {
  return (dispatch) => {
    // Clear tokens from localStorage
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("clientProfile");
    localStorage.removeItem("vendorProfile");
    localStorage.removeItem("user_type");
    localStorage.removeItem("isHRstatus");

    // Dispatch the LOGOUT action to update the state
    dispatch({ type: LOGOUT });

    // Return a resolved promise to indicate that the process has finished
    return Promise.resolve();
  };
};
