import React, { useState } from "react";
import {
  Steps,
  Button,
  Input,
  Row,
  Col,
  Card,
  DatePicker,
  Form,
  Select,
} from "antd";
import { CiCircleCheck } from "react-icons/ci";
import PersonalDetails from "./ExtractFormDetail/PersonalDetails";
import VisaDetails from "./ExtractFormDetail/VisaDetails";

const { Step } = Steps;
const { Option } = Select;

const EmpAndSalDetail = () => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => setIsEditing(false);

  return (
    <Card
      title="Employee and Salary Details"
      style={{ textTransform: "capitalize", fontWeight: "bold" }}
      extra={
        isEditing ? (
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        ) : (
          <Button onClick={handleEdit}>Edit</Button>
        )
      }
    >
      <Button style={{ background: "#1E2B32", color: "#fff" }}>
        Employment Details
      </Button>
      <Row gutter={24} style={{ marginTop: 16 }}>
        <Col xs={12} md={8}>
          <p>Designation</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={8}>
          <p>Start Date</p>
          <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
        </Col>
        <Col xs={12} md={8}>
          <p>Probation Period</p>
          <Input disabled={!isEditing} />
        </Col>
      </Row>
      <Button style={{ background: "#1E2B32", color: "#fff", marginTop: 16 }}>
        Salary Details
      </Button>
      <Row gutter={24} style={{ marginTop: 16 }}>
        <Col xs={6} md={2}>
          <p>Currency</p>
          <Select
            placeholder="CUR"
            style={{ width: "100%" }}
            disabled={!isEditing}
          >
            <Option value="INR">INR</Option>
            <Option value="USD">USD</Option>
            <Option value="AED">AED</Option>
          </Select>
        </Col>
        <Col xs={12} md={6}>
          <p>Base Salary</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>
            Effective Date{" "}
            <span style={{ fontSize: "12px" }}>
              (start date for current salary structure)
            </span>
          </p>
          <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6} style={{ marginBottom: 16 }}>
          <p>
            Travel Allowance [20%]{" "}
            <span style={{ fontSize: "12px" }}>(Only For UAE employees)</span>
          </p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>
            Phone Allowance [5%]{" "}
            <span style={{ fontSize: "12px" }}>(Only For UAE employees)</span>
          </p>
          <Input disabled={!isEditing} />
        </Col>
      </Row>
    </Card>
  );
};

const steps = [
  {
    title: "Employment details & Salary Details",
    content: <EmpAndSalDetail />,
  },
  { title: "Personal Details & Bank Details", content: <PersonalDetails /> },
  {
    title: "Visa & Passport Details",
    content: (
      <Card>
        <VisaDetails />
      </Card>
    ),
  },
];

const DataExtractionForm = () => {
  const [current, setCurrent] = useState(0);

  const next = () => setCurrent(current + 1);
  const prev = () => setCurrent(current - 1);

  return (
    <div className="page-wrapper">
      <div className="content container-fluid position-relative">
        <div style={{ padding: 20 }}>
          <Button
            style={{ background: "#1E2B32", color: "#fff", marginBottom: 16 }}
          >
            Data Extraction form
          </Button>
          <Steps current={current} size="small" style={{ marginBottom: 20 }}>
            {steps.map((item, index) => (
              <Step
                key={item.title}
                title={item.title}
                status={
                  index < current
                    ? "finish"
                    : index === current
                    ? "process"
                    : "wait"
                }
                icon={index < current ? <CiCircleCheck /> : null}
              />
            ))}
          </Steps>

          {/* Render the current step content */}
          {steps[current].content}

          <div style={{ margin: "20px 0px", textAlign: "right" }}>
            {current > 0 && (
              <Button
                onClick={prev}
                style={{
                  marginRight: 8,
                  background: "#D3D3D3",
                  color: "black",
                }}
              >
                Back
              </Button>
            )}
            <Button
              type="primary"
              style={{
                background: "#1E2B32",
                color: "white",
              }}
              onClick={next}
            >
              {current === steps.length - 1 ? "Save" : "Next"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DataExtractionForm;
