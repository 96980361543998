import React, { useState, useEffect } from "react";
import { Form, Button, Modal, Spinner } from "react-bootstrap";
import ModalAddExpenseDetails from "./ModalAddExpenseDetails";
import ModalViewExpenseDetails from "./ModalViewExpenseDetails";
import { vendordocumentsProfile } from "../../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../../helpers/axios/axios";

function BillExtraction() {
  const [showAddModal, setShowAddModal] = useState(false);
  const [showViewModal, setShowViewModal] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [selectedVendorId, setSelectedVendorId] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVendorData = async () => {
      try {
        const response = await axiosInstance.get(vendordocumentsProfile);
        console.log("Vendor Data Response:", response.data);
        setVendorData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching vendor data:", error);
        setLoading(false);
      }
    };

    fetchVendorData();
  }, []);

  const handleViewInvoice = (invoiceLink) => {
    if (invoiceLink) {
      window.open(invoiceLink, "_blank", "noopener,noreferrer");
    } else {
      alert("Invoice document not available");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Form>
          <Form.Group className="mb-3">
            <Button>Outsourcing Task</Button>
          </Form.Group>

          <Form.Group className="mb-3">
            <Button variant="outline-secondary">Bill Extraction</Button> 
            <Button className="mx-3" variant="outline-secondary">Bank Statement Extraction</Button>
            <Button variant="dark">Invoice Extraction</Button>

          </Form.Group>
          {loading ? (
            <div className="d-flex justify-content-center my-5">
              <Spinner animation="border" variant="primary" />
            </div>
          ) : (
            <div className="table-responsive m-t-15">
              <table className="table table-striped custom-table">
                <thead>
                  <tr>
                    <th className="text-center">Request ID</th>
                    <th className="text-center">Date</th>
                    <th className="text-center">Document Name</th>
                    <th className="text-center">Document</th>
                    <th className="text-center">Form</th>
                    <th className="text-center">Assignee</th>
                    <th className="text-center">MTO Status</th>
                    <th className="text-center">TC Status</th>
                  </tr>
                </thead>

                <tbody>
                  {vendorData.length > 0 ? (
                    vendorData.map((vendor, index) => (
                      <tr key={index}>
                        <td className="text-center">{vendor.id}</td>
                        <td className="text-center">
                          {new Date(vendor.created_at).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td className="text-center">{vendor.document}</td>
                        <td className="text-center">
                          <Button
                            variant="dark"
                            size="sm"
                            className="px-4"
                            onClick={() =>
                              handleViewInvoice(vendor.invoice_document)
                            }
                          >
                            View
                          </Button>
                        </td>
                        <td className="text-center">
                          <Button
                            variant="dark"
                            size="sm"
                            className="px-4"
                            onClick={() => {
                              setSelectedVendorId(vendor.id);
                              setShowViewModal(true);
                            }}
                          >
                            View
                          </Button>
                        </td>
                        <td className="text-center">
                          {vendor.tc_status === "Passed" ? (
                            <span className="text-success">
                              Already Assigned
                            </span>
                          ) : (
                            <Button
                              variant="warning"
                              size="sm"
                              className="px-4"
                              onClick={() => {
                                setSelectedVendorId(vendor.id);
                                setShowAddModal(true);
                              }}
                            >
                              Add
                            </Button>
                          )}
                        </td>

                        <td className="text-center">{vendor.tc_status}</td>

                        <td className="text-center">{vendor.tc_status}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No Data Available
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          )}
        </Form>

        <ModalAddExpenseDetails
          show={showAddModal}
          onHide={() => setShowAddModal(false)}
          vendorId={selectedVendorId}
        />

        <ModalViewExpenseDetails
          show={showViewModal}
          onHide={() => setShowViewModal(false)}
          vendorId={selectedVendorId}
        />
      </div>
    </div>
  );
}

export default BillExtraction;
