import React from "react";
import { Table, Button, Pagination } from "antd";
import { useNavigate } from "react-router-dom";

const columns = (navigate) => [
  {
    title: "Job Title",
    dataIndex: "jobTitle",
  },
  {
    title: "Requested Team",
    dataIndex: "requestedTeam",
  },
  {
    title: "Date of Hiring",
    dataIndex: "dateOfHiring",
  },
  {
    title: "Employee Name",
    dataIndex: "employeeName",
  },
  {
    title: "View Data",
    dataIndex: "viewData",
    render: () => (
      <Button
        style={{ background: "#37474F", color: "#fff" }}
        onClick={() => navigate("/data-collection-form")}
      >
        View
      </Button>
    ),
  },
  {
    title: "Extracted Details",
    dataIndex: "extractedDetails",
    render: () => (
      <Button
        type="primary"
        danger
        onClick={() => navigate("/data-extract-form")}
      >
        Add
      </Button>
    ),
  },
  {
    title: "Contract",
    dataIndex: "contract",
    render: () => (
      <Button
        type="primary"
        danger
        onClick={() => navigate("/employee-contract")}
      >
        View
      </Button>
    ),
  },
];

const data = [
  {
    key: "1",
    jobTitle: "Software Engineer",
    requestedTeam: "Development",
    dateOfHiring: "2025-02-15",
    employeeName: "John Doe",
    viewData: "",
    extractedDetails: "",
    contract: "",
  },
  {
    key: "2",
    jobTitle: "UI/UX Designer",
    requestedTeam: "Design",
    dateOfHiring: "2025-02-20",
    employeeName: "Jane Smith",
    viewData: "",
    extractedDetails: "",
    contract: "",
  },
];

const ViewDCFhr = () => {
  const navigate = useNavigate();
  return (
    <div className="page-wrapper">
      <div className="content container-fluid position-relative">
        <div style={{ padding: 20 }}>
          <Button
            style={{ background: "#1E2B32", color: "#fff", marginBottom: 16 }}
          >
            New joinee data - HR
          </Button>
          <div style={{ overflowX: "auto" }}>
            <Table
              columns={columns(navigate)}
              dataSource={data}
              pagination={false}
              bordered
              scroll={{ x: "max-content" }}
            />
          </div>
          <Pagination
            style={{ marginTop: 20, textAlign: "right" }}
            defaultCurrent={1}
            total={10}
          />
        </div>
      </div>
    </div>
  );
};

export default ViewDCFhr;
