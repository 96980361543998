import React, { useState } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Space,
  message,
} from "antd";
import { PlusOutlined } from "@ant-design/icons";

const { Option } = Select;

const VendorProfilecreation = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  // Sample data - Replace with your actual data source
  const tableData = [
    {
      key: "1",
      vendorName: "Vendor A",
      accountType: "Business",
    },
    {
      key: "2",
      vendorName: "Vendor B",
      accountType: "Individual",
    },
  ];

  // Table columns configuration
  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendorName",
      key: "vendorName",
      sorter: (a, b) => a.vendorName.localeCompare(b.vendorName),
    },
    {
      title: "Account Type",
      dataIndex: "accountType",
      key: "accountType",
      filters: [
        { text: "Business", value: "Business" },
        { text: "Individual", value: "Individual" },
      ],
      onFilter: (value, record) => record.accountType === value,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button type="link" onClick={() => handleCreateProfile(record)}>
          Create Profile
        </Button>
      ),
    },
  ];

  const handleCreateProfile = (record) => {
    form.setFieldsValue({
      vendorName: record?.vendorName,
      accountType: record?.accountType,
    });
    setIsModalVisible(true);
  };

  const handleModalSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();

      // Handle form submission logic here
      console.log("Form values:", values);

      message.success("Vendor profile created successfully!");
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      message.error("Failed to create vendor profile");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ padding: "24px" }} className="page-wrapper">
      <div style={{ marginBottom: "16px" }}>
        <Button
          type="primary"
          icon={<PlusOutlined />}
          onClick={() => setIsModalVisible(true)}
        >
          Add New Vendor
        </Button>
      </div>

      <Table
        columns={columns}
        dataSource={tableData}
        bordered
        responsive
        scroll={{ x: true }}
      />

      <Modal
        title="Create Vendor Profile"
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
        footer={[
          <Button
            key="cancel"
            onClick={() => {
              setIsModalVisible(false);
              form.resetFields();
            }}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            loading={loading}
            onClick={handleModalSubmit}
          >
            Submit
          </Button>,
        ]}
        width={720}
      >
        <Form form={form} layout="vertical" requiredMark="optional">
          <Form.Item
            name="vendorName"
            label="Vendor Name"
            rules={[{ required: true, message: "Please enter vendor name" }]}
          >
            <Input placeholder="Enter vendor name" />
          </Form.Item>

          <Form.Item
            name="accountType"
            label="Account Type"
            rules={[{ required: true, message: "Please select account type" }]}
          >
            <Select placeholder="Select account type">
              <Option value="Business">Business</Option>
              <Option value="Individual">Individual</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name="email"
            label="Email"
            rules={[
              { required: true, message: "Please enter email" },
              { type: "email", message: "Please enter a valid email" },
            ]}
          >
            <Input placeholder="Enter email address" />
          </Form.Item>

          <Form.Item
            name="phone"
            label="Phone Number"
            rules={[{ required: true, message: "Please enter phone number" }]}
          >
            <Input placeholder="Enter phone number" />
          </Form.Item>

          <Form.Item
            name="address"
            label="Address"
            rules={[{ required: true, message: "Please enter address" }]}
          >
            <Input.TextArea placeholder="Enter complete address" rows={4} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default VendorProfilecreation;
