import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  DatePicker,
  Typography,
} from "antd";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import { hiringProjectForm } from "../../../../helpers/endpoints/api_endpoints";
import { useNavigate, useParams } from "react-router-dom";

const { Title, Text } = Typography;

function HiringProjectForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [resourceLinks, setResourceLinks] = useState([""]); // State to manage resource links

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const data = {
        ...values,
        submission_date: values.submission_date?.format("YYYY-MM-DD"),
        resource_links: resourceLinks, // Include resource links in the data
        job_request: id, // Include the job_request id in the data
      };

      // Post the data to the endpoint
      await axiosInstance.post(hiringProjectForm, data);
      toast.success("Hiring project form is submitted");

      // Fetch all data from the same endpoint for verification
      const response = await axiosInstance.get(hiringProjectForm);
      console.log("Fetched data after submission:", response.data); // Log all data

      form.resetFields();
      navigate("/view-job-request");
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit hiring project form");
    } finally {
      setLoading(false);
    }
  };

  const addResourceLink = () => {
    setResourceLinks([...resourceLinks, ""]); // Add a new empty link
  };

  const handleLinkChange = (index, value) => {
    const newLinks = [...resourceLinks];
    newLinks[index] = value; // Update the specific link
    setResourceLinks(newLinks);
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <Card>
            <h2 style={{ fontWeight: "bold", marginBottom: "20px" }}>
              Hiring Project Form (HPF)
            </h2>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                name="context"
                label={<strong>Project Context</strong>}
                rules={[
                  {
                    required: true,
                    message: "Please provide the project context.",
                  },
                ]}
              >
                <Input.TextArea
                  rows={2}
                  placeholder="Provide a brief background for the project."
                />
              </Form.Item>

              <Form.Item
                name="assessed_on"
                label={<strong>You Will Be Assessed On</strong>}
                rules={[
                  {
                    required: true,
                    message: "Please specify assessment criteria.",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Instructions for assessment."
                />
              </Form.Item>

              <Form.Item
                name="expected_to_do"
                label={<strong>You Are Expected To Do</strong>}
                rules={[
                  { required: true, message: "Please specify expectations." },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Instructions for what is expected."
                />
              </Form.Item>

              <Form.Item
                name="presentation_is_to_be"
                label={<strong>The Presentation Is To Be</strong>}
                rules={[
                  {
                    required: true,
                    message: "Please specify presentation requirements.",
                  },
                ]}
              >
                <Input.TextArea
                  rows={3}
                  placeholder="Instructions for the presentation."
                />
              </Form.Item>

              <Form.Item
                name="submission_date"
                label={<strong>Project Submission Deadline</strong>}
                rules={[
                  {
                    required: true,
                    message: "Please select a submission date.",
                  },
                ]}
              >
                <DatePicker style={{ width: "100%" }} />
              </Form.Item>

              <Form.Item
                label={<strong>Additional HabotConnect Resource Links</strong>}
              >
                {resourceLinks.map((link, index) => (
                  <Input
                    key={index}
                    value={link}
                    onChange={(e) => handleLinkChange(index, e.target.value)}
                    placeholder="Enter resource link"
                    style={{ marginBottom: 10 }}
                  />
                ))}
                <Button
                  style={{
                    background: "#374151",
                    color: "white",
                    border: "none",
                    marginBottom: 10,
                  }}
                  onClick={addResourceLink}
                >
                  Add more links
                </Button>
              </Form.Item>

              <Row justify="end">
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit
                </Button>
              </Row>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
}

export default HiringProjectForm;
