import React, { useState } from "react";
import { Input, Row, Col, Card, DatePicker, Button } from "antd";

const PersonalDetails = () => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => setIsEditing(false);

  return (
    <Card
      title="Personal and Bank Details"
      style={{ fontWeight: "bold" }}
      extra={
        isEditing ? (
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        ) : (
          <Button onClick={handleEdit}>Edit</Button>
        )
      }
    >
      <Button
        style={{ background: "#1E2B32", color: "#fff", marginBottom: 18 }}
      >
        Personal details
      </Button>
      <Button
        style={{
          background: "#FD715C",
          color: "#fff",
          marginBottom: 18,
          marginLeft: "10px",
        }}
      >
        View
      </Button>
      <Row gutter={24} style={{ marginBottom: 18 }}>
        <Col xs={12} md={6}>
          <p>Full Name [Passport/National ID]</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>First Name [Passport/National ID]</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>Middle Name [Passport/National ID]</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>Last Name [Passport/National ID]</p>
          <Input disabled={!isEditing} />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: 28 }}>
        <Col xs={12} md={6}>
          <p>Passport/National ID Number</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>Date of Birth</p>
          <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
        </Col>
      </Row>
      <Button
        style={{ background: "#1E2B32", color: "#fff", marginBottom: 16 }}
      >
        Bank Account Details
      </Button>
      <Button
        style={{
          background: "#FD715C",
          color: "#fff",
          marginBottom: 18,
          marginLeft: "10px",
        }}
      >
        View
      </Button>
      <Row gutter={24} style={{ marginBottom: 18 }}>
        <Col xs={12} md={6}>
          <p>Account Holder Name</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>Account Number</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>IBAN (Optional)</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>Bank Name</p>
          <Input disabled={!isEditing} />
        </Col>
      </Row>
      <Row gutter={24} style={{ marginBottom: 18 }}>
        <Col xs={12} md={6}>
          <p>Bank Branch</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>Bank City</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>Bank Country</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p>IFSC Code/Swift Code</p>
          <Input disabled={!isEditing} />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <p>Currency Code (eg, INR, USD, AED)</p>
          <Input disabled={!isEditing} />
        </Col>
      </Row>
    </Card>
  );
};

export default PersonalDetails;
