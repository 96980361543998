import React, { useState, useEffect } from "react";
import Header from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import {
  Card,
  Row,
  Col,
  Typography,
  Spin,
  Statistic,
  Table,
  Avatar,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  trainingDashboard,
  employeeDetails,
} from "../../../../../helpers/endpoints/api_endpoints";

const { Title } = Typography;

const TrainingDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState({ start: null, end: null });
  const [isHr, setIsHr] = useState(false);

  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(trainingDashboard);
      setDashboardData(response.data.trainings || []);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      toast.error("Failed to fetch dashboard data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    setCurrentMonth({
      start: moment().startOf("month").format("MMMM D, YYYY"),
      end: moment().endOf("month").format("MMMM D, YYYY"),
    });
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then((response) => {
      setIsHr(
        response.data.employment_details[0].department_detail.name ===
          "Human Resource"
      );
    });
  }, []);

  const demoCostStatus = {
    totalRevenue: 0,
    totalCostTrack: 0,
    costStation: 0,
    actualCostBudget: 0,
    actualRevenueBudget: 0,
  };

  // Helper function to truncate and wrap text
  const renderTrainingTitle = (text) => {
    return {
      children: text,
      props: {
        style: {
          whiteSpace: "normal",
          wordBreak: "break-word",
          maxWidth: "250px", // Adjust this value as needed
        },
      },
    };
  };

  const currentStationColumns = [
    {
      title: "Training Title",
      dataIndex: "training_title",
      key: "training_title",
      width: "40%",
      render: renderTrainingTitle,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "30%",
    },
    {
      title: "Time to complete",
      key: "timeToComplete",
      width: "30%",
      render: (_, record) => {
        if (!record.time_to_complete_hours) return "Not started";
        return `${record.time_to_complete_hours} hours`;
      },
    },
  ];

  const clockStatusColumns = [
    {
      title: "Training Title",
      dataIndex: "training_title",
      key: "training_title",
      width: "30%",
      render: renderTrainingTitle,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Station Clock",
      key: "stationClock",
      render: () => "0",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY") : "Not started",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
      render: (date) =>
        date ? moment(date).format("DD-MM-YYYY") : "Not completed",
    },
  ];

  const timelineStatusColumns = [
    {
      title: "Training Title",
      dataIndex: "training_title",
      key: "training_title",
      width: "30%",
      render: renderTrainingTitle,
    },
    {
      title: "Time to Accept",
      key: "timeToAccept",
      render: () => "0",
    },
    {
      title: "Time to complete",
      key: "timeToComplete",
      render: (_, record) => {
        if (!record.time_to_complete_hours) return "Not started";
        return `${record.time_to_complete_hours} hours`;
      },
    },
    {
      title: "Average Time",
      key: "averageTime",
      render: () => "-",
    },
  ];

  return (
    <>
      {isHr ? (
        <>
          <Header />
          <AdminSideBar />
        </>
      ) : (
        <></>
      )}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Title level={2}>Training Dashboard</Title>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                <Col span={24}>
                  <Card>
                    <Title level={4}>
                      Current Month: {currentMonth.start} - {currentMonth.end}
                    </Title>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card className="profile-card" style={{ height: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                      }}
                    >
                      <Avatar
                        size={64}
                        icon={<UserOutlined />}
                        style={{ marginBottom: "16px" }}
                      />
                      <Title level={4}>Ashwini S. & Anukriti & Monisha</Title>
                      <p>SubTrack Owner</p>
                    </div>
                  </Card>
                </Col>
                <Col span={16}>
                  <Card title="Current Trainings" className="station-card">
                    <Table
                      columns={currentStationColumns}
                      dataSource={dashboardData}
                      pagination={false}
                      rowKey="training_title"
                      scroll={{ y: 240 }}
                      style={{
                        overflowX: "auto",
                        maxHeight: "240px",
                      }}
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={24}>
                  <Card title="Clock Status" className="clock-status-card">
                    <Table
                      columns={clockStatusColumns}
                      dataSource={dashboardData}
                      pagination={false}
                      rowKey="training_title"
                      expandable={{
                        expandedRowRender: (record) => (
                          <p style={{ margin: 0 }}>
                            Training Period:{" "}
                            {record.start_date || "Not started"} -{" "}
                            {record.end_time || "Not completed"}
                          </p>
                        ),
                      }}
                      scroll={{ y: 240, x: "max-content" }}
                      style={{
                        overflowX: "auto",
                        maxHeight: "240px",
                      }}
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={18}>
                  <Card
                    title="Timeline Status"
                    className="timeline-status-card"
                    style={{ height: "100%" }}
                  >
                    <Table
                      columns={timelineStatusColumns}
                      dataSource={dashboardData}
                      pagination={false}
                      rowKey="training_title"
                      scroll={{ y: 240, x: "max-content" }}
                      style={{
                        overflowX: "auto",
                        maxHeight: "240px",
                      }}
                    />
                  </Card>
                </Col>

                <Col span={6}>
                  <Card
                    title="Cost Status"
                    className="cost-status-card"
                    style={{ height: "100%" }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Statistic
                          title="Total Revenue"
                          value={demoCostStatus.totalRevenue}
                          prefix="AED"
                          valueStyle={{ color: "#3f8600", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Total Cost/Track"
                          value={demoCostStatus.totalCostTrack}
                          prefix="AED"
                          valueStyle={{ color: "#cf1322", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Cost/Station"
                          value={demoCostStatus.costStation}
                          prefix="AED"
                          valueStyle={{ color: "#1890ff", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Actual Cost/Budget"
                          value={demoCostStatus.actualCostBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualCostBudget > 50
                                ? "#cf1322"
                                : "#3f8600",
                            fontSize: "14px",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <Statistic
                          title="Actual Revenue/Budget"
                          value={demoCostStatus.actualRevenueBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualRevenueBudget > 50
                                ? "#3f8600"
                                : "#cf1322",
                            fontSize: "14px",
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

// Add this CSS to your stylesheet
const styles = `
  .ant-table-cell {
    vertical-align: top;
  }

  .ant-table-tbody > tr > td {
    padding: 8px 16px;
    line-height: 1.4;
  }

  .ant-table-thead > tr > th {
    padding: 12px 16px;
    background: #fafafa;
  }
`;

export default TrainingDashboard;
