import { useState, useEffect } from "react";
import { Upload, Button, Card, Space, Typography, message } from "antd";

const { Text, Title } = Typography;
const THEME_COLOR = "#FF902E";

const MultipleFileUpload = ({ value, onChange, showError }) => {
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (value) {
      setFileList(value);
    }
  }, [value]);

  // get file icon
  const getFileIcon = (fileType) => {
    if (fileType.includes("pdf"))
      return (
        <svg
          xmlns="https://www.w3.org/2000/svg"
          width="32"
          height="32"
          viewBox="0 0 32 32"
        >
          <path
            fill="#909090"
            d="m24.1 2.072l5.564 5.8v22.056H8.879V30h20.856V7.945z"
          />
          <path fill="#f4f4f4" d="M24.031 2H8.808v27.928h20.856V7.873z" />
          <path fill="#7a7b7c" d="M8.655 3.5h-6.39v6.827h20.1V3.5z" />
          <path fill="#dd2025" d="M22.472 10.211H2.395V3.379h20.077z" />
          <path
            fill="#464648"
            d="M9.052 4.534H7.745v4.8h1.028V7.715L9 7.728a2 2 0 0 0 .647-.117a1.4 1.4 0 0 0 .493-.291a1.2 1.2 0 0 0 .335-.454a2.1 2.1 0 0 0 .105-.908a2.2 2.2 0 0 0-.114-.644a1.17 1.17 0 0 0-.687-.65a2 2 0 0 0-.409-.104a2 2 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.193a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.94.94 0 0 1-.524.114m3.671-2.306c-.111 0-.219.008-.295.011L12 4.538h-.78v4.8h.918a2.7 2.7 0 0 0 1.028-.175a1.7 1.7 0 0 0 .68-.491a1.9 1.9 0 0 0 .373-.749a3.7 3.7 0 0 0 .114-.949a4.4 4.4 0 0 0-.087-1.127a1.8 1.8 0 0 0-.4-.733a1.6 1.6 0 0 0-.535-.4a2.4 2.4 0 0 0-.549-.178a1.3 1.3 0 0 0-.228-.017m-.182 3.937h-.1V5.392h.013a1.06 1.06 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a3 3 0 0 1-.033.513a1.8 1.8 0 0 1-.169.5a1.1 1.1 0 0 1-.363.36a.67.67 0 0 1-.416.106m5.08-3.915H15v4.8h1.028V7.434h1.3v-.892h-1.3V5.43h1.4v-.892"
          />
          <path
            fill="#dd2025"
            d="M21.781 20.255s3.188-.578 3.188.511s-1.975.646-3.188-.511m-2.357.083a7.5 7.5 0 0 0-1.473.489l.4-.9c.4-.9.815-2.127.815-2.127a14 14 0 0 0 1.658 2.252a13 13 0 0 0-1.4.288Zm-1.262-6.5c0-.949.307-1.208.546-1.208s.508.115.517.939a10.8 10.8 0 0 1-.517 2.434a4.4 4.4 0 0 1-.547-2.162Zm-4.649 10.516c-.978-.585 2.051-2.386 2.6-2.444c-.003.001-1.576 3.056-2.6 2.444M25.9 20.895c-.01-.1-.1-1.207-2.07-1.16a14 14 0 0 0-2.453.173a12.5 12.5 0 0 1-2.012-2.655a11.8 11.8 0 0 0 .623-3.1c-.029-1.2-.316-1.888-1.236-1.878s-1.054.815-.933 2.013a9.3 9.3 0 0 0 .665 2.338s-.425 1.323-.987 2.639s-.946 2.006-.946 2.006a9.6 9.6 0 0 0-2.725 1.4c-.824.767-1.159 1.356-.725 1.945c.374.508 1.683.623 2.853-.91a23 23 0 0 0 1.7-2.492s1.784-.489 2.339-.623s1.226-.24 1.226-.24s1.629 1.639 3.2 1.581s1.495-.939 1.485-1.035"
          />
          <path fill="#909090" d="M23.954 2.077V7.95h5.633z" />
          <path fill="#f4f4f4" d="M24.031 2v5.873h5.633z" />
          <path
            fill="#fff"
            d="M8.975 4.457H7.668v4.8H8.7V7.639l.228.013a2 2 0 0 0 .647-.117a1.4 1.4 0 0 0 .493-.291a1.2 1.2 0 0 0 .332-.454a2.1 2.1 0 0 0 .105-.908a2.2 2.2 0 0 0-.114-.644a1.17 1.17 0 0 0-.687-.65a2 2 0 0 0-.411-.105a2 2 0 0 0-.319-.026m-.189 2.294h-.089v-1.48h.194a.57.57 0 0 1 .459.181a.92.92 0 0 1 .183.558c0 .246 0 .469-.222.626a.94.94 0 0 1-.524.114m3.67-2.306c-.111 0-.219.008-.295.011l-.235.006h-.78v4.8h.918a2.7 2.7 0 0 0 1.028-.175a1.7 1.7 0 0 0 .68-.491a1.9 1.9 0 0 0 .373-.749a3.7 3.7 0 0 0 .114-.949a4.4 4.4 0 0 0-.087-1.127a1.8 1.8 0 0 0-.4-.733a1.6 1.6 0 0 0-.535-.4a2.4 2.4 0 0 0-.549-.178a1.3 1.3 0 0 0-.228-.017m-.182 3.937h-.1V5.315h.013a1.06 1.06 0 0 1 .6.107a1.2 1.2 0 0 1 .324.4a1.3 1.3 0 0 1 .142.526c.009.22 0 .4 0 .549a3 3 0 0 1-.033.513a1.8 1.8 0 0 1-.169.5a1.1 1.1 0 0 1-.363.36a.67.67 0 0 1-.416.106m5.077-3.915h-2.43v4.8h1.028V7.357h1.3v-.892h-1.3V5.353h1.4v-.892"
          />
        </svg>
      );
    if (fileType.includes("image"))
      return (
        <svg
          xmlns="https://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
        >
          <path
            fill="none"
            d="m19.016 22.848l.008.015l.994 1.794l.725 1.309h1.918v-8.084h-1.679v2.351c0 1.223.036 2.314.144 3.382h-.023a26 26 0 0 0-1.319-2.771l-.759-1.34l-.919-1.623H15.97v8.084h1.679V23.53c0-1.319-.024-2.459-.084-3.538h.048c.383.948.923 1.98 1.403 2.856m-8.149 4.527h18.266v5.333H10.867zm3.192-5.115c.492-.443.744-1.115.744-1.919c0-.791-.336-1.463-.864-1.858c-.539-.444-1.343-.66-2.471-.66c-1.115 0-1.906.072-2.47.168v7.976h1.811v-2.902c.168.036.384.048.624.048c1.079-.002 2.003-.277 2.626-.853m-3.25-.6v-2.387c.132-.036.384-.06.743-.06c.924 0 1.439.443 1.439 1.187c0 .816-.6 1.308-1.571 1.308c-.276 0-.455-.012-.611-.048m18.324-8.45h-4.89a.554.554 0 0 1-.554-.554V6.993H10.867v8.99h18.266zm-1.142 9.554h1.248v1.703c-.145.059-.48.107-.888.107c-1.499 0-2.555-.971-2.555-2.65c0-1.751 1.163-2.614 2.687-2.614c.888 0 1.427.144 1.871.348l.384-1.476c-.396-.18-1.199-.384-2.243-.384c-2.65 0-4.605 1.535-4.617 4.222c0 1.188.407 2.243 1.14 2.951c.743.707 1.811 1.079 3.286 1.079c1.067 0 2.135-.264 2.698-.456v-4.258h-3.011z"
          />
          <path
            fill="#000"
            d="M12.991 20.4c0-.743-.516-1.187-1.439-1.187c-.359 0-.611.024-.743.06v2.387c.156.036.336.048.611.048c.972 0 1.571-.491 1.571-1.308"
          />
          <path
            fill="#000"
            d="M30.918 15.983h-.678v-3.271a.4.4 0 0 0-.006-.061a.55.55 0 0 0-.131-.359L24.66 6.075l-.004-.004a.6.6 0 0 0-.11-.092l-.036-.022a.6.6 0 0 0-.109-.046l-.03-.01a.5.5 0 0 0-.127-.016H10.867c-.611 0-1.107.497-1.107 1.107v8.99h-.678c-.874 0-1.582.708-1.582 1.582v8.228c0 .873.708 1.582 1.582 1.582h.678v5.632c0 .61.496 1.107 1.107 1.107h18.266c.61 0 1.107-.497 1.107-1.107v-5.632h.678c.874 0 1.582-.708 1.582-1.582v-8.228c0-.873-.709-1.581-1.582-1.581m-20.051-8.99H23.69v5.664c0 .306.248.554.554.554h4.89v2.773H10.867zm6.781 16.538v2.435H15.97v-8.084h2.135l.919 1.623l.759 1.34c.479.852.947 1.858 1.319 2.771h.023c-.107-1.067-.144-2.159-.144-3.382v-2.351h1.679v8.084h-1.918l-.725-1.309l-.994-1.794l-.008-.015c-.479-.876-1.02-1.907-1.403-2.855h-.048c.06 1.078.084 2.218.084 3.537m-6.839-.468v2.902H8.998V17.99c.563-.096 1.354-.168 2.47-.168c1.128 0 1.932.216 2.471.66c.528.395.864 1.067.864 1.858c0 .804-.252 1.476-.744 1.919c-.623.576-1.547.852-2.626.852a3 3 0 0 1-.624-.048m18.324 9.645H10.867v-5.333h18.266zm1.869-7.114c-.564.192-1.631.456-2.698.456c-1.476 0-2.543-.372-3.286-1.079c-.732-.708-1.14-1.764-1.14-2.951c.012-2.687 1.967-4.222 4.617-4.222c1.044 0 1.847.204 2.243.384l-.384 1.476c-.444-.204-.983-.348-1.871-.348c-1.523 0-2.687.863-2.687 2.614c0 1.68 1.056 2.65 2.555 2.65c.407 0 .743-.048.888-.107v-1.703h-1.248v-1.428h3.011z"
          />
        </svg>
      );
  };

  const uploadProps = {
    multiple: true,
    fileList,
    accept: ".pdf,.doc,.docx,.jpg,.jpeg,.png",
    disabled: showError,
    beforeUpload: (file) => {
      if (showError) return Upload.LIST_IGNORE;

      const isValidType = [
        "application/pdf",
        "application/msword",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        "image/jpeg",
        "image/png",
      ].includes(file.type);

      if (!isValidType) {
        message.error(`${file.name} is not a supported file type`);
        return Upload.LIST_IGNORE;
      }

      const isLessThan5MB = file.size / 1024 / 1024 < 5;
      if (!isLessThan5MB) {
        message.error(`${file.name} must be smaller than 5MB`);
        return Upload.LIST_IGNORE;
      }

      return false;
    },
    onChange: ({ fileList: newFileList }) => {
      if (!showError) {
        setFileList(newFileList);
        onChange?.(newFileList);
      }
    },
    onRemove: (file) => {
      if (!showError) {
        const updatedFileList = fileList.filter(
          (item) => item.uid !== file.uid
        );
        setFileList(updatedFileList);
        onChange?.(updatedFileList);
      }
    },
    showUploadList: false,
  };

  return (
    <Space direction="vertical" style={{ width: "100%" }} size={16}>
      <Card
        style={{
          borderRadius: 12,
          border: `2px dashed ${showError ? "#d9d9d9" : THEME_COLOR}`,
          background: showError ? "#f5f5f5" : "#fff",
          transition: "all 0.3s ease",
        }}
        bodyStyle={{ padding: "24px" }}
      >
        <Space direction="vertical" style={{ width: "100%" }} align="center">
          <Upload {...uploadProps}>
            <Button
              disabled={showError}
              style={{
                height: "40px",
                backgroundColor: showError ? "#d9d9d9" : THEME_COLOR,
                borderColor: showError ? "#d9d9d9" : THEME_COLOR,
                color: "white",
                borderRadius: "6px",
                padding: "0 24px",
                display: "flex",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <span style={{ marginLeft: 8 }}>Select Files to Upload</span>
            </Button>
          </Upload>
          <Space align="center" style={{ marginTop: 12 }}>
            <Text type="secondary">
              Supported formats: PDF, DOC, DOCX, JPG, JPEG, PNG (Max: 5MB per
              file)
            </Text>
          </Space>
        </Space>
      </Card>

      {/* uploaded documents lists */}
      {fileList.length > 0 && !showError && (
        <Card
          title={
            <Space>
              <Title level={5} style={{ margin: 0 }}>
                Selected Documents ({fileList.length})
              </Title>
            </Space>
          }
          style={{ borderRadius: 12 }}
        >
          <Space direction="vertical" style={{ width: "100%" }}>
            {fileList.map((file, index) => (
              <Card
                key={file.uid || index}
                size="small"
                style={{
                  marginBottom: 8,
                  backgroundColor: "#fafafa",
                  borderRadius: 8,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Space>
                    {getFileIcon(file.type)}
                    <div>
                      <Text strong>{file.name}</Text>
                      <br />
                      <Text type="secondary">
                        {(file.size / (1024 * 1024)).toFixed(2)} MB
                      </Text>
                    </div>
                  </Space>
                  {/* delete icon  */}
                  <Button
                    type="text"
                    icon={
                      <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path d="m12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.018-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                          <path
                            fill="#f20000"
                            d="M14.28 2a2 2 0 0 1 1.897 1.368L16.72 5H20a1 1 0 1 1 0 2l-.003.071l-.867 12.143A3 3 0 0 1 16.138 22H7.862a3 3 0 0 1-2.992-2.786L4.003 7.07L4 7a1 1 0 0 1 0-2h3.28l.543-1.632A2 2 0 0 1 9.721 2zm3.717 5H6.003l.862 12.071a1 1 0 0 0 .997.929h8.276a1 1 0 0 0 .997-.929zM10 10a1 1 0 0 1 .993.883L11 11v5a1 1 0 0 1-1.993.117L9 16v-5a1 1 0 0 1 1-1m4 0a1 1 0 0 1 1 1v5a1 1 0 1 1-2 0v-5a1 1 0 0 1 1-1m.28-6H9.72l-.333 1h5.226z"
                          />
                        </g>
                      </svg>
                    }
                    onClick={() => uploadProps.onRemove(file)}
                  />
                </div>
              </Card>
            ))}
          </Space>
        </Card>
      )}
    </Space>
  );
};

export default MultipleFileUpload;
