import React, { useState } from "react";
import Header from "../../../layout/Header";
import { useParams } from "react-router-dom";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import {
  Button,
  Input,
  Select,
  DatePicker,
  TimePicker,
  Typography,
  Row,
  Col,
} from "antd";
import dayjs from "dayjs";

const { Title, Text } = Typography;
const { Option } = Select;

const TechScheduleShortlistedCandidate = () => {
  const { id } = useParams();

  const candidate = {
    1: {
      name: "Anushka Srivastava",
      email: "anushka@gmail.com",
      status: "Pending",
    },
    2: {
      name: "Rahul Verma",
      email: "rahul@gmail.com",
      status: "Not Scheduled",
    },
    3: {
      name: "Priya Sharma",
      email: "priya@gmail.com",
      status: "Confirmed",
    },
  }[id];

  const [scheduledInterviews, setScheduledInterviews] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);

  const handleScheduleInterview = () => {
    // Logic to schedule the interview and update the scheduledInterviews state
    const newInterview = {
      date: selectedDate,
      candidate: candidate.name,
    };
    setScheduledInterviews([...scheduledInterviews, newInterview]);
  };

  const countInterviewsOnDate = (date) => {
    return scheduledInterviews.filter((interview) =>
      dayjs(interview.date).isSame(date, "day")
    ).length;
  };

  return (
    <>
      {" "}
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div
          style={{ maxWidth: "1200px", margin: "auto" }}
          className="content container-fluid position-relative"
        >
          {/* <div>
            <h2>Candidate Details</h2>
            {candidate ? (
              <div>
                <p>Name: {candidate.name}</p>
                <p>Email: {candidate.email}</p>
                <p>Status: {candidate.status}</p>
              </div>
            ) : (
              <p>Candidate not found</p>
            )}
          </div> */}

          <div style={{ padding: 30 }}>
            <Button
              type="primary"
              block
              style={{
                backgroundColor: "#FF6F61",
                border: "none",
                marginBottom: 20,
              }}
              onClick={handleScheduleInterview}
            >
              Interview Schedule Shortlisted Candidate
            </Button>

            <Row gutter={[16, 16]}>
              <Col span={8}>
                <Text strong>Job Title</Text>
                <Input
                  value="ABCD"
                  disabled
                  style={{ background: "#ddd", fontWeight: "bold" }}
                />
              </Col>
              <Col span={8}>
                <Text strong>Interview Type</Text>
                <Select placeholder="Select" style={{ width: "100%" }}>
                  <Option value="technical">Technical</Option>
                  <Option value="hr">HR</Option>
                </Select>
              </Col>
              <Col span={8}>
                <Text strong>Interview Date</Text>
                <DatePicker
                  style={{ width: "100%" }}
                  onChange={(date) => setSelectedDate(date)}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col span={8}>
                <Text strong>Start Time</Text>
                <TimePicker
                  use12Hours
                  format="h:mm A"
                  suffixIcon="GST"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8}>
                <Text strong>End Time</Text>
                <TimePicker
                  use12Hours
                  format="h:mm A"
                  suffixIcon="GST"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8}>
                <Text strong>Interview Duration</Text>
                <Select
                  defaultValue="15"
                  style={{ width: "100%" }}
                  options={[
                    { value: "5", label: "5 mins" },
                    { value: "10", label: "10 mins" },
                    { value: "15", label: "15 mins" },
                  ]}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col span={8}>
                <Text strong>Number of Interviews/Slot</Text>
                <Select
                  placeholder="Select interviews per slot"
                  style={{ width: "100%" }}
                  options={[
                    { value: "1", label: "1 interview/slot" },
                    { value: "2", label: "2 interviews/slot" },
                    { value: "3", label: "3 interviews/slot" },
                    { value: "4", label: "4 interviews/slot" },
                    { value: "5", label: "5 interviews/slot" },
                  ]}
                />
              </Col>
              <Col span={8}>
                <Text strong>Break Start Time</Text>
                <TimePicker
                  use12Hours
                  format="h:mm A"
                  suffixIcon="GST"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col span={8}>
                <Text strong>Break End Time</Text>
                <TimePicker
                  use12Hours
                  format="h:mm A"
                  suffixIcon="GST"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col span={8}>
                <Text strong>Number of Candidates</Text>
                <Input />
              </Col>
              <Col span={8}>
                <Text strong>Select Panel Members</Text>
                <Input value="Autofilled" disabled />
              </Col>
            </Row>

            <Row justify="end" style={{ marginTop: 16 }}>
              <Button
                type="primary"
                style={{ backgroundColor: "#FF6F61", border: "none" }}
                onClick={handleScheduleInterview}
              >
                Schedule
              </Button>
            </Row>

            {/* Section to display total interviews scheduled */}
            <div
              style={{
                marginTop: 30,
                padding: 20,
                background: "#F9FAFB",
                borderRadius: 8,
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div>
                <Text strong style={{ fontSize: 16, color: "#374151" }}>
                  Select Date to Check Scheduled Interviews:
                </Text>
                <DatePicker
                  style={{
                    marginLeft: 10,
                    padding: "6px 12px",
                    borderRadius: 6,
                    borderColor: "#D1D5DB",
                  }}
                  onChange={(date) => setSelectedDate(date)}
                />
              </div>

              <div
                style={{
                  marginTop: 15,
                  padding: 12,
                  background: "#E5E7EB",
                  borderRadius: 6,
                  display: "inline-block",
                }}
              >
                <Text style={{ fontSize: 14, color: "#1F2937" }}>
                  Total Interviews Scheduled on{" "}
                  <span style={{ fontWeight: "bold", color: "#111827" }}>
                    {selectedDate ? selectedDate.format("YYYY-MM-DD") : "N/A"}
                  </span>
                  : {countInterviewsOnDate(selectedDate)}
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TechScheduleShortlistedCandidate;
