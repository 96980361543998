import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DownloadImg } from "../../../../Routes/ImagePath";
import { Trash2 } from "react-feather";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  baseURL,
  uploadDocuments,
} from "../../../../helpers/endpoints/api_endpoints";

const FileUpload = () => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fileName, setFileName] = useState("");
  const [personName, setPersonName] = useState("");
  const [dateValue, setDateValue] = useState("");

  const handleFileNameChange = (e) => {
    const name = e.target.value;
    setFileName(name);
  };

  const handlePersonNameChange = (e) => {
    const name = e.target.value;
    setPersonName(name);
  };

  const handleDateChange = (e) => {
    const date = e.target.value;
    setDateValue(date);
  };

  const handleFileChange = (e) => {
    const uploadedFile = e.target.files[0];
    setFile(uploadedFile);
    console.log("Uploaded file:", uploadedFile);
  };

  const deleteHandler = () => {
    setFile(null);
    const fileInput = document.querySelector(
      ".custom-file-container__custom-file__custom-file-input"
    );
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      // Check if fileName is empty
      if (!fileName.trim()) {
        toast.error("File name cannot be empty");
        return;
      }

      const sharedName = `${personName}-${fileName}-${dateValue}`;

      const formData = new FormData();
      formData.append("file_name", sharedName);
      formData.append("uploaded_file", file);

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const response = await axiosInstance.post(
        `${baseURL}${uploadDocuments}`,
        formData,
        config
      );
      console.log("Upload success:", response.data);
      if (response.data) {
        toast.success("File Uploaded Successfully");
        setFileName("");
        setPersonName("");
        setDateValue("");
        setFile(null);
        const fileInput = document.querySelector(
          ".custom-file-container__custom-file__custom-file-input"
        );
        if (fileInput) {
          fileInput.value = "";
        }
      }
    } catch (error) {
      console.log(error);
      console.error("Upload error:", error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderFileContent = () => {
    if (!file) return null;

    const isImage = file?.type?.startsWith("image");

    return (
      <div>
        <p>Uploaded File: {file?.name}</p>
        <button
          onClick={deleteHandler}
          type="button"
          className="btn btn-soft-danger me-2"
        >
          <Trash2
            className="feather-trash-2"
            data-bs-toggle="tooltip"
            title="Delete File"
          />
          Delete File
        </button>
        {loading ? (
          <button type="button" className="btn btn-primary" disabled>
            <i className="fas fa-spinner fa-spin me-2" />
            Loading...
          </button>
        ) : (
          <>
            <button
              type="button"
              className="btn btn-soft-primary me-2"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </>
        )}

        {isImage ? (
          <img src={URL.createObjectURL(file)} alt="Uploaded file" />
        ) : (
          <p>File: {file.name}</p>
        )}
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {/* Page Header */}
        <div className="page-header">
          <div className="content-page-header">
            <h5>File Upload</h5>
          </div>
        </div>
        {/* /Page Header */}
        <form>
          <div className="row">
            {/* Drag Card */}
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h5 className="card-title">Single File Upload</h5>
                </div>

                <div className="input-block m-4">
                  <label className="col-form-label">
                    File Name <span className="text-danger">*</span>
                  </label>
                  <input
                    placeholder="Please enter file name"
                    type="text"
                    className="form-control w-50"
                    onChange={handleFileNameChange}
                    value={fileName}
                  />
                </div>

                <div className="input-block m-4">
                  <label className="col-form-label">
                    Person Name <span className="text-danger">*</span>
                  </label>
                  <input
                    placeholder="Please enter person name"
                    type="text"
                    className="form-control w-50"
                    onChange={handlePersonNameChange}
                    value={personName}
                  />
                </div>

                <div className="input-block m-4">
                  <label className="col-form-label">
                    Date <span className="text-danger">*</span>
                  </label>
                  <input
                    type="date"
                    className="form-control w-50"
                    onChange={handleDateChange}
                    value={dateValue}
                  />
                </div>

                <div className="card-body">
                  <div
                    className="custom-file-container"
                    data-upload-id="myFirstImage"
                  >
                    <label>
                      Upload File{" "}
                      <Link
                        to="#"
                        className="custom-file-container__image-clear"
                        title="Clear Image"
                      >
                        x
                      </Link>
                    </label>
                    <label className="custom-file-container__custom-file">
                      <input
                        type="file"
                        className="custom-file-container__custom-file__custom-file-input"
                        onChange={handleFileChange}
                      />
                      <span className="custom-file-container__custom-file__custom-file-control">
                        <span className="custom-file-container__custom-file__custom-file-control_button">
                          Browser
                        </span>
                      </span>
                      <br />
                    </label>

                    <div className="custom-file-container__image-preview">
                      {file ? (
                        renderFileContent()
                      ) : (
                        <div
                          className="custom-file-container__image-preview"
                          style={{
                            backgroundImage: "url(" + DownloadImg + ")",
                          }}
                        ></div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /Drag Card */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FileUpload;
