import React from "react";
import { Modal, Card, Row, Col, Input, Form } from "antd";

const TcPassedVendorProfile = ({ isVisible, onClose, vendorData }) => {
  const renderSection = (title, fields) => {
    return (
      <Card
        title={title}
        className="detail-section"
        style={{ marginBottom: "16px" }}
        headStyle={{
          background: "#f8f9fa",
          borderBottom: "2px solid #e7eaf3",
        }}
      >
        <Row gutter={[16, 16]}>
          {fields.map(({ label, key, span = 12 }) => (
            <Col span={span} key={key}>
              <Form.Item
                label={label}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  value={vendorData?.[key] || "Not Available"}
                  readOnly
                  className="readonly-input"
                  style={{
                    color: vendorData?.[key] ? "inherit" : "#999",
                    fontStyle: vendorData?.[key] ? "normal" : "italic",
                  }}
                />
              </Form.Item>
            </Col>
          ))}
        </Row>
      </Card>
    );
  };

  return (
    <Modal
      title="Vendor Profile"
      open={isVisible}
      onCancel={onClose}
      width={800}
      footer={null}
    >
      <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
        <Form layout="vertical">
          {renderSection("Basic Information", [
            { label: "Vendor Name", key: "vendor_name" },
            { label: "Email", key: "email" },
            { label: "Contact Phone", key: "contact_phone" },
          ])}

          {renderSection("Bank Details", [
            { label: "Bank Name", key: "bank_name" },
            { label: "Bank Account Number", key: "bank_account_number" },
            {
              label: "Bank Branch Address",
              key: "bank_branch_address",
              span: 24,
            },
            { label: "IFSC Code", key: "ifsc_code" },
            { label: "IBAN No", key: "iban_no" },
            { label: "Swift Code", key: "swift_code" },
          ])}

          {renderSection("Payment Information", [
            { label: "Mode of Payment", key: "mode_of_payment" },
            { label: "Currency Code", key: "payment_currency" },
            { label: "Payment Dispute Status", key: "payment_dispute_status" },
            {
              label: "Expected Credit Days Policy",
              key: "expected_credit_days",
            },
            // { label: "Expected Due Date", key: "expected_due_date" },
          ])}

          {renderSection("Tax & Regulatory", [
            {
              label: "Tax Registration Number",
              key: "tax_registration_number",
            },
            { label: "Tax Treatment", key: "tax_treatment" },
            // {
            //   label: "VAT Registration Number",
            //   key: "vat_registration_number",
            // },
            // { label: "VAT Included", key: "vat_included" },
            { label: "Place of Supply", key: "place_of_supply", span: 24 },
          ])}

          {renderSection("Additional Information", [
            { label: "Subscription Status", key: "subscription_status" },
          ])}
        </Form>
      </div>
    </Modal>
  );
};

export default TcPassedVendorProfile;
