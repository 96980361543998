import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  Button,
  Form,
  Input,
  Row,
  Col,
  Select,
  Checkbox,
  Modal,
  Spin,
  DatePicker,
  Typography,
} from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { FaPlus, FaTrash } from "react-icons/fa";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  handoverDetail,
  taskModel,
  handover,
  separation,
  externalContactModel,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import moment from "moment";
// import "./date.css";

const { Option } = Select;
const { confirm } = Modal;
const { Title } = Typography;

const EditTrackHandover = ({
  resignationId,
  onClose,
  onSuccess,
  existingTracks = [],
  employees = [],
  employeeEdit,
  externalContacts: initialExternalContacts = [],
}) => {
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [tracks, setTracks] = useState(existingTracks);
  const [deletedTrackIds, setDeletedTrackIds] = useState([]);
  const [employeeOptions, setEmployeeOptions] = useState(employees);
  const [searchText, setSearchText] = useState("");
  const [loading, setLoading] = useState(false);
  const [externalContacts, setExternalContacts] = useState(
    initialExternalContacts
  );
  const [openDatePicker, setOpenDatePicker] = useState(null);

  useEffect(() => {
    console.log("Existing Tracks:", existingTracks); // Debug log
    setTracks(existingTracks);
  }, [existingTracks]);

  // Memoize filtered employees to prevent unnecessary re-renders
  const filteredEmployees = useMemo(() => {
    if (!searchText) return employees;

    const searchLower = searchText.toLowerCase();
    return employees.filter(
      (emp) =>
        (emp.employee_name?.toLowerCase().includes(searchLower) ||
          emp.employee_id?.toLowerCase().includes(searchLower)) &&
        emp.id !== employeeEdit?.id
    );
  }, [searchText, employees, employeeEdit]);

  // Optimized search handler
  const handleEmployeeSearch = useCallback(
    (value) => {
      setSearchText(value);
      setEmployeeOptions(!value ? employees : filteredEmployees);
    },
    [employees, filteredEmployees]
  );

  const isValidUrl = (string) => {
    try {
      new URL(string);
      return true;
    } catch (_) {
      return false;
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validateFields = () => {
    let isValid = true;
    let errorMessage = "";

    // Validate tracks
    for (const track of tracks) {
      if (!track.track_name?.trim()) {
        isValid = false;
        errorMessage = "Track name cannot be empty";
        break;
      }

      for (const task of track.tasks) {
        if (!task.name?.trim()) {
          isValid = false;
          errorMessage = "Task name cannot be empty";
          break;
        }
      }
    }

    if (!isValid) {
      toast.error(errorMessage);
    }
    return isValid;
  };

  const removeTrack = (trackIndex) => {
    if (tracks.length === 1) {
      toast.error("At least one track is required");
      return;
    }

    const trackToRemove = tracks[trackIndex];
    if (trackToRemove.id) {
      setDeletedTrackIds([...deletedTrackIds, trackToRemove.id]);
    }

    const newTracks = [...tracks];
    newTracks.splice(trackIndex, 1);
    setTracks(newTracks);
  };

  const showDeleteConfirm = (trackIndex, taskIndex) => {
    const track = tracks[trackIndex];
    const task = track.tasks[taskIndex];

    if (track.tasks.length === 1) {
      toast.error("At least one task is required per track");
      return;
    }

    confirm({
      title: "Delete Task",
      content: `Are you sure you want to delete the task "${task.name}"?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          if (task.id) {
            await axiosInstance.delete(taskModel(task.id));
          }

          const newTracks = [...tracks];
          newTracks[trackIndex].tasks.splice(taskIndex, 1);
          setTracks(newTracks);
          toast.success("Task deleted successfully");
        } catch (error) {
          console.error("Error deleting task:", error);
          toast.error("Error deleting task");
        }
      },
      onCancel() {
        // Do nothing when cancelled
      },
    });
  };

  const handleExternalContactChange = (index, field, value) => {
    const updatedContacts = [...externalContacts];

    if (field === "intro_date") {
      // Handle date field specifically
      updatedContacts[index] = {
        ...updatedContacts[index],
        [field]: value ? value.format("YYYY-MM-DD") : null,
      };
      // Update form with dayjs object for DatePicker
      form.setFieldValue(
        `contact_${field}_${index}`,
        value ? dayjs(value) : null
      );
    } else {
      // Handle other fields
      updatedContacts[index] = {
        ...updatedContacts[index],
        [field]: value,
      };
      form.setFieldValue(`contact_${field}_${index}`, value);
    }

    setExternalContacts(updatedContacts);
  };

  const addExternalContact = () => {
    setExternalContacts([
      ...externalContacts,
      {
        name: "",
        email: "",
        phone: "",
        position: "",
        detail: "",
        is_induction: false,
        intro_date: null,
      },
    ]);
  };

  const handleDeleteExternalContact = (contactIndex) => {
    const contact = externalContacts[contactIndex];

    confirm({
      title: "Delete External Contact",
      content: `Are you sure you want to delete the contact "${contact.name}"?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          if (contact.id) {
            await axiosInstance.delete(`${externalContactModel(contact.id)}`);
          }

          const newContacts = [...externalContacts];
          newContacts.splice(contactIndex, 1);
          setExternalContacts(newContacts);
          toast.success("External contact deleted successfully");
        } catch (error) {
          console.error("Error deleting external contact:", error);
          toast.error("Error deleting external contact");
        }
      },
    });
  };

  const handleSubmit = async () => {
    if (!validateFields()) {
      return;
    }

    try {
      setSubmitting(true);

      const payload = {
        handover_detail: tracks.map((track, index) => ({
          id: track.id,
          track_name: track.track_name,
          track_status: track.track_status,
          comments: track.comments,
          // Only include external_contact in the first track
          ...(index === 0
            ? {
                external_contact: externalContacts.map((contact) => ({
                  id: contact.id,
                  name: contact.name,
                  email: contact.email,
                  phone: contact.phone,
                  position: contact.position,
                  detail: contact.detail,
                  is_induction: contact.is_induction,
                  intro_date: contact.intro_date,
                })),
              }
            : {}),
          tasks: track.tasks.map((task) => ({
            id: task.id,
            name: task.name,
            is_training: task.is_training,
            training_link: task.training_link,
            not_reason: task.not_reason,
            course_list: task.course_list,
            platform: task.platform,
            document_links: task.document_links,
            assignee: task.assignee,
          })),
        })),
      };

      await axiosInstance.patch(`${handover}${resignationId}/`, payload);
      toast.success("Handover details updated successfully");
      onSuccess?.();
    } catch (error) {
      console.error("Error updating handover:", error);
      toast.error(error.message || "Error updating handover details");
    } finally {
      setSubmitting(false);
    }
  };

  const addTrack = () => {
    setTracks([
      ...tracks,
      {
        track_name: "",
        track_status: "Not Started",
        comments: "",
        tasks: [],
        external_contact: [], // Initialize with empty external contacts
      },
    ]);
  };

  const addTask = (trackIndex) => {
    const newTracks = [...tracks];
    newTracks[trackIndex].tasks.push({
      id: null,
      name: "",
      is_training: false,
      training_link: null,
      not_reason: "",
      course_list: [],
      assignee: null,
      platform: [
        {
          name: "",
          username: "",
          password: "",
        },
      ],
      document_links: [{ name: "", url: "" }],
    });
    setTracks(newTracks);
  };

  const addTaskPlatform = (trackIndex, taskIndex) => {
    const newTracks = [...tracks];
    if (!newTracks[trackIndex].tasks[taskIndex].platform) {
      newTracks[trackIndex].tasks[taskIndex].platform = [];
    }
    newTracks[trackIndex].tasks[taskIndex].platform.push({
      name: "",
      username: "",
      password: "",
    });
    setTracks(newTracks);
  };

  const removeTaskPlatform = (trackIndex, taskIndex, platformIndex) => {
    const newTracks = [...tracks];
    newTracks[trackIndex].tasks[taskIndex].platform.splice(platformIndex, 1);
    setTracks(newTracks);
  };

  const addDocumentLink = (trackIndex, taskIndex) => {
    const newTracks = [...tracks];
    if (!newTracks[trackIndex].tasks[taskIndex].document_links) {
      newTracks[trackIndex].tasks[taskIndex].document_links = [];
    }
    newTracks[trackIndex].tasks[taskIndex].document_links.push({
      name: "",
      url: "",
    });
    setTracks(newTracks);
  };

  const removeDocumentLink = (trackIndex, taskIndex, linkIndex) => {
    const newTracks = [...tracks];
    newTracks[trackIndex].tasks[taskIndex].document_links.splice(linkIndex, 1);
    setTracks(newTracks);
  };

  const handleTaskChange = (trackIndex, taskIndex, field, value) => {
    const newTracks = [...tracks];
    newTracks[trackIndex].tasks[taskIndex][field] = value;
    setTracks(newTracks);
  };

  const updateTaskPlatform = (
    trackIndex,
    taskIndex,
    platformIndex,
    field,
    value
  ) => {
    const newTracks = [...tracks];
    newTracks[trackIndex].tasks[taskIndex].platform[platformIndex][field] =
      value;
    setTracks(newTracks);
  };

  const handleDeleteTrack = (trackIndex) => {
    const track = tracks[trackIndex];

    confirm({
      title: "Delete Track",
      content: `Are you sure you want to delete the track "${track.track_name}"?`,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      async onOk() {
        try {
          if (track.id) {
            await axiosInstance.delete(handoverDetail(track.id));
          }

          const newTracks = [...tracks];
          newTracks.splice(trackIndex, 1);
          setTracks(newTracks);
          toast.success("Track deleted successfully");
        } catch (error) {
          console.error("Error deleting track:", error);
          toast.error("Error deleting track");
        }
      },
      onCancel() {
        // Do nothing when cancelled
      },
    });
  };

  const renderTaskFields = (track, trackIndex, task, taskIndex) => (
    <div key={taskIndex} className="task-container border p-3 my-2">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <span>Task {taskIndex + 1}</span>
        <Button
          danger
          icon={<FaTrash />}
          onClick={() => showDeleteConfirm(trackIndex, taskIndex)}
          disabled={track.tasks.length === 1}
        />
      </div>

      <Form.Item>
        <Input
          value={task.name}
          onChange={(e) =>
            handleTaskChange(trackIndex, taskIndex, "name", e.target.value)
          }
          placeholder="Detailed list of tasks involved in this track"
        />
      </Form.Item>

      {!employeeEdit && (
        <>
          <Form.Item label="Assignee">
            <Select
              showSearch
              value={task.assignee}
              placeholder="Select an assignee"
              optionFilterProp="children"
              onChange={(value) => {
                const newTracks = [...tracks];
                newTracks[trackIndex].tasks[taskIndex].assignee = value;
                newTracks[trackIndex].tasks[taskIndex].assigned_at = value
                  ? new Date().toISOString()
                  : null;
                setTracks(newTracks);
              }}
              onSearch={handleEmployeeSearch}
              searchValue={searchText}
              filterOption={false}
              style={{ width: "100%" }}
              defaultActiveFirstOption={false}
              showArrow={false}
              notFoundContent={null}
            >
              {employeeOptions.map((employee) => (
                <Option
                  key={employee.id}
                  value={employee.id}
                  disabled={employee.id === employeeEdit?.id}
                >
                  {employee.employee_name} ({employee.employee_id})
                </Option>
              ))}
            </Select>
          </Form.Item>
        </>
      )}
      <Form.Item>
        Job Training/Knowledge transfer completion
        <br />
        <span style={{ color: "grey" }}>
          (Training on the Position to the employee taking over)
        </span>
        {/* style the checkbox in alignment */}
        <Checkbox
          checked={task.is_training}
          style={{
            verticalAlign: "middle",
            marginLeft: "15px",
            margin: "15px",
            scale: "1.5",
          }}
          onChange={(e) => {
            const newTracks = [...tracks];
            newTracks[trackIndex].tasks[taskIndex].is_training =
              e.target.checked;
            if (e.target.checked) {
              newTracks[trackIndex].tasks[taskIndex].not_reason = null;
            } else {
              newTracks[trackIndex].tasks[taskIndex].training_link = null;
              newTracks[trackIndex].tasks[taskIndex].course_list = [];
            }
            setTracks(newTracks);
          }}
        ></Checkbox>
      </Form.Item>

      {task.is_training ? (
        <>
          <Form.Item label="Training Record Link">
            <Input
              value={task.training_link}
              onChange={(e) => {
                const newTracks = [...tracks];
                newTracks[trackIndex].tasks[taskIndex].training_link =
                  e.target.value;
                setTracks(newTracks);
              }}
              placeholder="Enter training link"
            />
          </Form.Item>

          <Form.Item label="Trainings/Courses Required">
            <Select
              mode="tags"
              value={task.course_list || []}
              onChange={(values) => {
                const newTracks = [...tracks];
                newTracks[trackIndex].tasks[taskIndex].course_list = values;
                setTracks(newTracks);
              }}
              placeholder="The person leaving has to list out what courses taking over person
              has to take."
              tokenSeparators={[","]}
              style={{ width: "100%" }}
            />
            <small className="text-muted">
              The person leaving has to list out what courses taking over person
              has to take. Please, Type course name and press Enter to add
              multiple courses
            </small>
          </Form.Item>
        </>
      ) : (
        <Form.Item label="if No,(Specify Why is it not completed?)">
          <Input.TextArea
            value={task.not_reason}
            onChange={(e) => {
              const newTracks = [...tracks];
              newTracks[trackIndex].tasks[taskIndex].not_reason =
                e.target.value;
              setTracks(newTracks);
            }}
            placeholder="Specify why training is not completed"
            rows={4}
          />
        </Form.Item>
      )}

      <div className="mb-3">
        {task.platform.map((platform, platformIndex) => (
          <Row key={platformIndex} gutter={16} align="middle">
            <Col span={7}>
              <Form.Item
                label={`Platform ${platformIndex + 1} Name`}
                rules={[
                  {
                    required: true,
                    message: "Platform name is required",
                  },
                ]}
              >
                <Input
                  value={platform.name}
                  onChange={(e) =>
                    updateTaskPlatform(
                      trackIndex,
                      taskIndex,
                      platformIndex,
                      "name",
                      e.target.value
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label={`Platform ${platformIndex + 1} Username`}
                rules={[
                  {
                    required: true,
                    message: "Platform username is required",
                  },
                ]}
              >
                <Input
                  value={platform.username}
                  onChange={(e) =>
                    updateTaskPlatform(
                      trackIndex,
                      taskIndex,
                      platformIndex,
                      "username",
                      e.target.value
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label={`Platform ${platformIndex + 1} Password`}
                rules={[
                  {
                    required: true,
                    message: "Platform password is required",
                  },
                ]}
              >
                <Input
                  value={platform.password}
                  onChange={(e) =>
                    updateTaskPlatform(
                      trackIndex,
                      taskIndex,
                      platformIndex,
                      "password",
                      e.target.value
                    )
                  }
                />
              </Form.Item>
            </Col>
            <Col span={3}>
              <Button
                danger
                icon={<FaTrash />}
                onClick={() =>
                  removeTaskPlatform(trackIndex, taskIndex, platformIndex)
                }
                disabled={task.platform.length === 1}
              />
            </Col>
          </Row>
        ))}

        <Button
          type="dashed"
          onClick={() => addTaskPlatform(trackIndex, taskIndex)}
          icon={<FaPlus />}
        >
          Add Platform
        </Button>
      </div>

      <div className="mb-3">
        {task.document_links.map((doc, docIndex) => (
          <Row key={docIndex} gutter={16} align="middle">
            <Col span={10}>
              <Form.Item
                label="Key Documents Name/Links"
                rules={[
                  {
                    required: true,
                    message: "Document name is required",
                  },
                ]}
              >
                <Input
                  value={doc.name}
                  onChange={(e) => {
                    const newTracks = [...tracks];
                    newTracks[trackIndex].tasks[taskIndex].document_links[
                      docIndex
                    ].name = e.target.value;
                    setTracks(newTracks);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item
                label="URL"
                rules={[{ required: true, message: "URL is required" }]}
              >
                <Input
                  value={doc.url}
                  onChange={(e) => {
                    const newTracks = [...tracks];
                    newTracks[trackIndex].tasks[taskIndex].document_links[
                      docIndex
                    ].url = e.target.value;
                    setTracks(newTracks);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={4}>
              <Button
                danger
                icon={<FaTrash />}
                onClick={() =>
                  removeDocumentLink(trackIndex, taskIndex, docIndex)
                }
                disabled={task.document_links.length === 1}
              />
            </Col>
          </Row>
        ))}

        <Button
          type="dashed"
          onClick={() => addDocumentLink(trackIndex, taskIndex)}
          icon={<FaPlus />}
        >
          Add Document Link
        </Button>
      </div>
    </div>
  );

  return (
    <Spin spinning={submitting}>
      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <div className="external-contacts-container">
          <div className="bg-orange text-white py-2 ps-2 my-2 d-flex justify-content-between align-items-center">
            <span>External Contacts Information</span>
            <Button
              type="dashed"
              onClick={addExternalContact}
              icon={<FaPlus />}
              style={{ marginRight: "10px" }}
            >
              Add Contact
            </Button>
          </div>
          {externalContacts.map((contact, contactIndex) => (
            <div
              key={contactIndex}
              className="border p-3 mb-3 position-relative"
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h6>Contact {contactIndex + 1}</h6>
                <Button
                  danger
                  icon={<FaTrash />}
                  onClick={() => handleDeleteExternalContact(contactIndex)}
                  disabled={externalContacts.length === 1}
                />
              </div>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Name of the Contact"
                    rules={[{ required: true, message: "Name is required" }]}
                  >
                    <Input
                      value={contact.name}
                      onChange={(e) => {
                        const newContacts = [...externalContacts];
                        newContacts[contactIndex].name = e.target.value;
                        setExternalContacts(newContacts);
                      }}
                      placeholder="Enter contact name"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Email Address / Username"
                    rules={[
                      { required: true, message: "Email is required" },
                      { type: "email", message: "Invalid email format" },
                    ]}
                  >
                    <Input
                      value={contact.email}
                      onChange={(e) => {
                        const newContacts = [...externalContacts];
                        newContacts[contactIndex].email = e.target.value;
                        setExternalContacts(newContacts);
                      }}
                      placeholder="Enter contact email"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    label="Phone"
                    rules={[
                      { required: true, message: "Phone is required" },
                      {
                        max: 20,
                        message: "Phone number cannot exceed 20 characters",
                      },
                    ]}
                  >
                    <Input
                      value={contact.phone}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length <= 20) {
                          const newContacts = [...externalContacts];
                          newContacts[contactIndex].phone = value;
                          setExternalContacts(newContacts);
                        }
                      }}
                      placeholder="Enter contact phone"
                      maxLength={20}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="Position"
                    rules={[
                      { required: true, message: "Position is required" },
                    ]}
                  >
                    <Input
                      value={contact.position}
                      onChange={(e) => {
                        const newContacts = [...externalContacts];
                        newContacts[contactIndex].position = e.target.value;
                        setExternalContacts(newContacts);
                      }}
                      placeholder="Enter position"
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label="What does he/ She do for us"
                    rules={[{ required: true, message: "Detail is required" }]}
                  >
                    <Input.TextArea
                      value={contact.detail}
                      onChange={(e) => {
                        const newContacts = [...externalContacts];
                        newContacts[contactIndex].detail = e.target.value;
                        setExternalContacts(newContacts);
                      }}
                      placeholder="Brief description of the contact's role with us"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Introduction Done with Taking Over Habotech"
                    valuePropName="checked"
                  >
                    <Checkbox
                      checked={contact.is_induction}
                      onChange={(e) => {
                        const newContacts = [...externalContacts];
                        newContacts[contactIndex].is_induction =
                          e.target.checked;
                        setExternalContacts(newContacts);
                      }}
                    >
                      Is Introduction Required?
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Introduction Date"
                    rules={[
                      {
                        required: contact.is_induction,
                        message:
                          "Introduction date is required when induction is checked",
                      },
                    ]}
                  >
                    <DatePicker
                      style={{ width: "100%" }}
                      value={
                        contact.intro_date ? dayjs(contact.intro_date) : null
                      }
                      onChange={(date) => {
                        const newContacts = [...externalContacts];
                        newContacts[contactIndex].intro_date = date
                          ? date.format("YYYY-MM-DD")
                          : null;
                        setExternalContacts(newContacts);
                      }}
                      disabled={!contact.is_induction}
                      format="YYYY-MM-DD"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
        </div>
        <div
          className="text-white py-2 ps-2 mb-3"
          style={{ backgroundColor: "#FD715C" }}
        >
          Track Handover Details
        </div>
        {tracks.map((track, trackIndex) => (
          <div key={trackIndex} className="track-container border p-3 my-3">
            <div className="bg-orange text-white py-2 ps-2 my-2 d-flex justify-content-between align-items-center">
              <span>Track {trackIndex + 1}</span>
              <Button
                danger
                icon={<FaTrash />}
                onClick={() => handleDeleteTrack(trackIndex)}
                disabled={tracks.length === 1}
                style={{ marginRight: "5px" }}
              />
            </div>
            <Row gutter={16}>
              <Col span={12}>
                <Form.Item label="Name of the Track">
                  <Input
                    value={track.track_name}
                    placeholder="Name of the track habotech is owner of"
                    onChange={(e) => {
                      const newTracks = [...tracks];
                      newTracks[trackIndex].track_name = e.target.value;
                      setTracks(newTracks);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Current Track Status">
                  <Select
                    value={track.track_status}
                    onChange={(value) => {
                      const newTracks = [...tracks];
                      newTracks[trackIndex].track_status = value;
                      setTracks(newTracks);
                    }}
                  >
                    <Option value="Not Started">Not Started</Option>
                    <Option value="Ongoing">Ongoing</Option>
                    <Option value="Completed">Completed</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div className="tasks-section mt-4">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h5>Tasks assigned under the track</h5>
                <Button
                  type="dashed"
                  onClick={() => addTask(trackIndex)}
                  icon={<FaPlus />}
                >
                  Add Task
                </Button>
              </div>
              {track.tasks.map((task, taskIndex) =>
                renderTaskFields(track, trackIndex, task, taskIndex)
              )}
            </div>

            <Form.Item label="Notes/Comments" className="mt-3">
              <Input.TextArea
                value={track.comments}
                onChange={(e) => {
                  const newTracks = [...tracks];
                  newTracks[trackIndex].comments = e.target.value;
                  setTracks(newTracks);
                }}
              />
            </Form.Item>
          </div>
        ))}

        <Button
          type="primary"
          onClick={addTrack}
          block
          className="my-3"
          icon={<FaPlus />}
          disabled={submitting}
        >
          Add Track
        </Button>

        {/* External Contacts Section - Move outside tracks mapping */}

        <div className="d-flex justify-content-end gap-2 mt-4">
          <Button onClick={onClose} disabled={submitting}>
            Cancel
          </Button>
          <Button type="primary" onClick={handleSubmit} loading={submitting}>
            {submitting ? "Updating..." : "Update Handover Details"}
          </Button>
        </div>
      </Form>
    </Spin>
  );
};

// Add some styles
const style = document.createElement("style");
style.textContent = `
  .ant-select-item-option-content {
    white-space: normal;
    word-break: break-word;
  }
 
  .ant-select-dropdown {
    max-height: 300px;
  }
 
  .ant-select-selection-search-input {
    height: 32px !important;
  }
`;
document.head.appendChild(style);

export default EditTrackHandover;
