import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import Header1 from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import Modals from "./CareerPage2Comp/Modals";
import { FaAngleDown, FaAngleUp } from "react-icons/fa6";
import { IoTimeOutline } from "react-icons/io5";
import { IoArrowRedoSharp } from "react-icons/io5";
import { MdPlace } from "react-icons/md";
import { SearchOutlined } from "@ant-design/icons";

import {
  Layout,
  Typography,
  Button,
  Card,
  Input,
  Tag,
  Row,
  Col,
  Modal,
  Collapse,
} from "antd";
import Navbar from "./Navbar";
import Hero from "./Hero";
const { Panel } = Collapse;

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const CareerPage2 = () => {
  const [filters, setFilters] = useState({
    category: "Engineering, Marketing, Sales, etc",
    title: "Software Engineer, Content Writer",
    location: "Remote",
    employmentType: "Full time",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const [expanded, setExpanded] = useState(false);
  const [expandedId, setExpandedId] = useState(null);

  const navigate = useNavigate();
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const jobDetails = {
    title: "UI/UX Designer",
    employmentType: "Full Time, 100% Remote Working",
    salary: "20000-30000",
    experience: "2-3 years",
    education: [
      "A bachelor's Degree in a relevant field, such as English, Technical Writing, Computer Science, Information Technology, or a related discipline, is expected.",
      "Basic knowledge of designing",
    ],
    expectations: [
      "Better Communication",
      "Good Research in Designing",
      "Tackling Ability",
      "Working Style",
    ],
  };

  const jobListings = [
    {
      id: 1,
      title: "UI/UX Designer",
      employmentType: "Full Time",
      location: "100% Remote",
      salary: "20000-30000",
      experience: "2-3 years",
      education: [
        "A bachelor’s Degree in a relevant field, such as Computer Science, Information Technology, or a related discipline.",
        "Basic knowledge of designing.",
      ],
      jobExpectations: [
        "Better Communication",
        "Good Research in Designing",
        "Tackling Ability",
        "Working Style",
      ],
    },
    {
      id: 2,
      title: "Software Engineer",
      employmentType: "Full Time",
      location: "Hybrid",
      salary: "25000-40000",
      experience: "3-5 years",
      education: [
        "A bachelor’s Degree in Computer Science, Information Technology, or a related discipline.",
        "Strong problem-solving skills.",
      ],
      jobExpectations: [
        "Excellent coding skills",
        "Experience with React & Node.js",
        "Agile Development Experience",
        "Team Collaboration",
      ],
    },
    {
      id: 3,
      title: "Marketing Manager",
      employmentType: "Part Time",
      location: "On-Site",
      salary: "15000-25000",
      experience: "4+ years",
      education: [
        "Degree in Marketing, Business, or a related field.",
        "Strong communication skills.",
      ],
      jobExpectations: [
        "Creative Marketing Strategies",
        "SEO & Social Media Management",
        "Brand Awareness",
        "Campaign Optimization",
      ],
    },
    {
      id: 4,
      title: "Marketing Manager",
      employmentType: "Part Time",
      location: "On-Site",
      salary: "15000-25000",
      experience: "4+ years",
      education: [
        "Degree in Marketing, Business, or a related field.",
        "Strong communication skills.",
      ],
      jobExpectations: [
        "Creative Marketing Strategies",
        "SEO & Social Media Management",
        "Brand Awareness",
        "Campaign Optimization",
      ],
    },
  ];

  return (
    <>
      <Header1 />
      <AdminSideBar />
      <div className="page-wrapper ">
        <div className="content container-fluid position-relative">
          <Layout>
            <Navbar />
            <Content
              style={{ padding: "10px 10px 10px 20px", position: "relative" }}
            >
              <Hero />

              <Row
                gutter={24}
                style={{
                  display: "flex",
                  marginTop: 30,
                  flexDirection: isSmallScreen ? "column" : "row",
                  position: "absolute",
                  top: "75vh",
                  width: "100%",
                  padding: "10px",
                }}
              >
                <Col
                  xs={24}
                  md={8}
                  style={{
                    position: "sticky",
                    top: "80px",
                    alignSelf: "flex-start",
                  }}
                >
                  <Card
                    title="Filters"
                    extra={<a href="#">Clear All Filters</a>}
                  >
                    <div style={{ marginBottom: "10px" }}>
                      <p>Job Category</p>
                      <Input placeholder={filters.category} />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <p>Job Title</p>

                      <Input
                        placeholder={filters.title}
                        style={{ marginBottom: 0 }}
                      />
                    </div>
                    <div style={{ marginBottom: "10px" }}>
                      <p>Location</p>
                      <Tag color="blue">On-Site</Tag>
                      <Tag color="blue">Hybrid</Tag>
                      <Tag color="blue">Remote</Tag>
                    </div>

                    <div style={{ marginBottom: "10px" }}>
                      <p>Employement Type</p>
                      <Tag color="blue">Full time</Tag>
                      <Tag color="blue">Part time</Tag>
                      <Tag color="blue">Internship</Tag>
                    </div>

                    <Button
                      style={{
                        marginTop: 10,
                        width: "100%",
                        backgroundColor: "#FF883A",
                        color: "white",
                      }}
                      onClick={() => navigate("/job-search-results")}
                    >
                      Search
                    </Button>
                  </Card>
                  <Card
                    title="Other Related Jobs"
                    extra={<a href="#">View All</a>}
                    style={{ marginTop: 20 }}
                  >
                    <Text>Technical Writer</Text>
                    <Text type="secondary">Posted 3 days ago</Text>
                  </Card>
                </Col>

                <Col xs={24} md={16}>
                  <div
                    style={{
                      height: "80%",
                      position: "sticky",
                      top: "80px",
                      alignSelf: "flex-start",

                      overflowX: "auto",
                      whiteSpace: "nowrap",
                      scrollbarWidth: "none", // Firefox
                      msOverflowStyle: "none",
                    }}
                  >
                    {jobListings.map((job) => (
                      <Card
                        style={{ borderRadius: "8px", margin: "10px 0px" }}
                        key={job.id}
                      >
                        <h4 style={{ color: "#666" }}>Job Title</h4>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <h3 style={{ fontWeight: "bold" }}>{job.title}</h3>
                          <Button
                            type="primary"
                            style={{
                              background: "#204884",
                              border: "none",
                              cursor: "pointer",
                              display: "flex",
                              gap: "10px",
                              alignItems: "center",
                            }}
                            onClick={() => setIsApplyModalOpen(true)}
                          >
                            Apply Now <IoArrowRedoSharp />
                          </Button>
                        </div>

                        {/* Employment Type */}
                        <h6
                          style={{
                            marginTop: "20px",
                            color: "#666",
                            margin: "10px 0px",
                          }}
                        >
                          Employment Type
                        </h6>
                        <p style={{ fontSize: "12px" }}>
                          <b
                            style={{
                              border: "1px solid gray",
                              padding: "4px 10px",
                              borderRadius: "20px",
                              margin: "5px 6px 0 0",
                              backgroundColor: "#1E1E1E",
                              color: "white",
                            }}
                          >
                            {job.employmentType} <IoTimeOutline />
                          </b>
                          <b
                            style={{
                              border: "1px solid gray",
                              padding: "4px 10px",
                              borderRadius: "20px",
                              margin: "5px 6px 0 0",
                            }}
                          >
                            {job.location} <MdPlace />
                          </b>
                        </p>

                        {/* Expand/Collapse Button */}
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          <p
                            onClick={() =>
                              setExpandedId(
                                expandedId === job.id ? null : job.id
                              )
                            }
                            style={{
                              padding: 0,
                              fontSize: "14px",
                              backgroundColor: "#FFFDF7",
                              color: "gray",
                            }}
                            type="primary"
                          >
                            {expandedId === job.id ? (
                              <FaAngleUp />
                            ) : (
                              <FaAngleDown />
                            )}
                          </p>
                        </div>

                        {/* Expanded Details */}
                        {expandedId === job.id && (
                          <Collapse activeKey={expandedId ? ["1"] : []} ghost>
                            <Panel key="1" showArrow={false}>
                              {/* Salary */}
                              <div style={{ marginTop: "10px" }}>
                                <h4 style={{ color: "#666" }}>Salary</h4>
                                <p>
                                  <b>{job.salary}</b>
                                </p>
                              </div>

                              {/* Experience */}
                              <h4 style={{ marginTop: "20px", color: "#666" }}>
                                Experience
                              </h4>
                              <p>{job.experience}</p>

                              {/* Education */}
                              <h4 style={{ color: "#666" }}>Education</h4>
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "20px",
                                }}
                              >
                                {job.education.map((edu, index) => (
                                  <li key={index}>{edu}</li>
                                ))}
                              </ul>

                              {/* Job Expectations */}
                              <h4 style={{ color: "#666" }}>
                                Job Output Expected
                              </h4>
                              <ul
                                style={{
                                  listStyleType: "disc",
                                  paddingLeft: "20px",
                                }}
                              >
                                {job.jobExpectations.map(
                                  (expectation, index) => (
                                    <li key={index}>{expectation}</li>
                                  )
                                )}
                              </ul>

                              {/* Application Process */}
                              <Card
                                style={{
                                  marginTop: "20px",
                                  borderRadius: "8px",
                                }}
                              >
                                <h4 style={{ color: "#666" }}>
                                  Application Process
                                </h4>
                                <p>
                                  If you meet the job criteria, submit your
                                  application. If shortlisted, you'll receive an
                                  invitation for the first-round interview via
                                  Google Meet.
                                </p>
                                <p>
                                  After clearing the first round, you'll move to
                                  the second round for a project presentation.
                                </p>
                                <Button
                                  type="primary"
                                  style={{
                                    background: "#5E6B86",
                                    border: "none",
                                  }}
                                  onClick={() => navigate("/project-detail")}
                                >
                                  View Project
                                </Button>
                              </Card>
                            </Panel>
                          </Collapse>
                        )}
                      </Card>
                    ))}
                  </div>
                  {/* Job Summary */}
                </Col>
              </Row>
            </Content>
          </Layout>
          <Modals
            setIsApplyModalOpen={setIsApplyModalOpen}
            isApplyModalOpen={isApplyModalOpen}
          />
        </div>
      </div>
    </>
  );
};

export default CareerPage2;
