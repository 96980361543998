import React from "react";
import TrackInitialisationList from "./TrackInitialisationList";
import Header from "../../../layout/Header";
import TechnicalSideBar from "../../../layout/TechnicalSideBar";
import { TrackInitProvider } from "../../../../contexts/TrackInitContext";

const TrackInitialisation = () => {
  return (
    <>
      <Header />
      <TrackInitProvider>
        <TechnicalSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <div className="row mb-4">
              <div className="col-12">
                <h1 className="text-2xl font-bold">Track Initialisation</h1>
              </div>
            </div>
            <TrackInitialisationList />
          </div>
        </div>
      </TrackInitProvider>
    </>
  );
};

export default TrackInitialisation;
