import { useState, useEffect } from "react";
import { Card, Table, Button, message, Space, Select, Tag, Modal } from "antd";
import {
  employeeDetails,
  vendorRequest,
  vendordocuments,
} from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import VendorRequestviewmodal from "./VendorRequestviewmodal/VendorRequestviewmodal";
import GaclSideBar from "../../../layout/GaclSidebar";

const Vendorapprovalrequest = () => {
  const [vendorData, setVendorData] = useState([]);
  const [vendorDocuments, setVendorDocuments] = useState({});
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [formData, setFormData] = useState({
    comments: selectedVendor?.gacl_comments || "",
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        const userId = response.data.user.id;
        setCurrentUser(userId);
      } catch (err) {
        console.error("Error fetching employee details:", err);
        toast.error("Failed to fetch user details");
      }
    };

    fetchUserDetails();
  }, []);

  const fetchVendorDocuments = async (vendorId) => {
    try {
      const response = await axiosInstance.get(
        `${vendordocuments}?vendor_request_id=${vendorId}`
      );
      return Array.isArray(response.data)
        ? response.data
        : Array.isArray(response.data.results)
        ? response.data.results
        : [];
    } catch (error) {
      console.error(`Error fetching documents for vendor ${vendorId}:`, error);
      return [];
    }
  };

  const fetchVendorData = async (page, pageSize, departmentId = null) => {
    try {
      setLoading(true);
      let url = `${vendorRequest}?page=${page}&page_size=${pageSize}`;

      if (departmentId) {
        url += `&department_id=${departmentId}`;
      }

      const response = await axiosInstance.get(url);

      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });

      const vendors = response.data.results;

      // Fetch documents for each vendor
      const documentsPromises = vendors.map(async (vendor) => {
        const documents = await fetchVendorDocuments(vendor.id);
        return { vendorId: vendor.id, documents };
      });

      const documentsResults = await Promise.all(documentsPromises);

      const documentsLookup = documentsResults.reduce((acc, curr) => {
        acc[curr.vendorId] = curr.documents;
        return acc;
      }, {});

      setVendorDocuments(documentsLookup);
      setVendorData(vendors);
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      message.error("Failed to fetch vendor data");
      setVendorData([]);
      setVendorDocuments({});
    } finally {
      setLoading(false);
    }
  };
  const handleVendorApproval = async (status, id) => {
    try {
      setLoading(true);

      const patchBody =
        selectedVendor.modalType === "edit"
          ? { ...selectedVendor }
          : {
              gacl_status: status === "accept" ? "APPROVED" : "REJECTED",
              gacl_comments: formData.comments || "",
              approved_by: currentUser,
            };

      delete patchBody.modalType;
      delete patchBody.id;

      await axiosInstance.patch(`${vendorRequest}${id}/`, patchBody);

      toast.success(
        selectedVendor.modalType === "edit"
          ? "Vendor details updated successfully"
          : `Vendor request ${status === "accept" ? "approved" : "rejected"}`
      );

      await fetchVendorData(pagination.current, pagination.pageSize);
      handleModalClose();
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        `Failed to ${
          selectedVendor.modalType === "edit" ? "update" : status
        } vendor request`
      );
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (newPagination) => {
    fetchVendorData(
      newPagination.current,
      newPagination.pageSize,
      selectedDepartment
    );
  };
  const handleDepartmentChange = (value) => {
    setSelectedDepartment(value);
    fetchVendorData(1, pagination.pageSize, value);
  };

  const handleVendorSelection = (id, mode = "view") => {
    const vendor = vendorData.find((vendor) => vendor.id === id);
    if (vendor) {
      setSelectedVendor({ ...vendor, modalType: mode });
      setIsModalVisible(true);
    }
  };

  const DocumentButton = ({ documents }) => {
    const getFileName = (url) => {
      if (!url) return "Unknown File";
      const urlParts = url.split("/");
      let fileName = urlParts[urlParts.length - 1];
      fileName = fileName.split("?")[0];
      try {
        fileName = decodeURIComponent(fileName);
      } catch (e) {
        console.error("Error decoding filename:", e);
      }
      return fileName;
    };

    // SVG Icons as components
    const FileIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "10px", color: "#1890ff" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );

    const FolderIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "8px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
        />
      </svg>
    );

    const ClickIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginLeft: "10px", color: "#52c41a" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    );

    const InfoIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="14"
        height="14"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "5px", color: "#666" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );

    const showDocumentList = () => {
      Modal.info({
        title: (
          <div
            style={{
              borderBottom: "1px solid #f0f0f0",
              padding: "8px 0",
              marginBottom: "16px",
              fontSize: "18px",
              color: "#34444C",
            }}
          >
            Available Documents
          </div>
        ),
        content: (
          <div>
            {documents.map((doc, index) => (
              <div
                key={index}
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  padding: "12px",
                  border: "1px solid #e8e8e8",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  ":hover": {
                    transform: "translateY(-1px)",
                    boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
                  },
                }}
                onClick={() => window.open(doc.document, "_blank")}
              >
                <FileIcon />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#1890ff",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginBottom: "2px",
                    }}
                  >
                    {doc.fileName || getFileName(doc.document)}
                  </span>
                  <span
                    style={{
                      color: "#8c8c8c",
                      fontSize: "12px",
                    }}
                  >
                    Click to open in new tab
                  </span>
                </div>
                <ClickIcon />
              </div>
            ))}
            <div
              style={{
                marginTop: "20px",
                padding: "12px",
                backgroundColor: "#f6ffed",
                borderRadius: "8px",
                fontSize: "13px",
                color: "#52c41a",
                display: "flex",
                alignItems: "center",
                border: "1px solid #b7eb8f",
              }}
            >
              <InfoIcon />
              Click on any document card to open it in a new tab
            </div>
          </div>
        ),
        width: 500,
        className: "document-modal",
        icon: null,
        okText: "Close",
        okButtonProps: {
          style: {
            backgroundColor: "#34444C",
            borderColor: "#34444C",
          },
        },
      });
    };

    const handleDocumentClick = () => {
      if (documents.length === 1) {
        // For single document, open directly
        window.open(documents[0].document, "_blank");
      } else {
        // For multiple documents, show modal
        showDocumentList();
      }
    };

    return (
      <Button
        type="primary"
        onClick={handleDocumentClick}
        style={{
          backgroundColor: "#34444C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px",
          height: "30px",
          borderRadius: "6px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {documents.length === 1 ? <FileIcon /> : <FolderIcon />}
        <span style={{ marginLeft: "4px" }}>
          {documents.length > 1
            ? `View All (${documents.length})`
            : "View Document"}
        </span>
      </Button>
    );
  };

  useEffect(() => {
    fetchVendorData(pagination.current, pagination.pageSize);
  }, []);

  const handleModalClose = () => {
    setIsModalVisible(false);
    setSelectedVendor(null);
    setFormData({ comments: "", approvedby: "" });
  };

  const columns = [
    {
      title: "Requestor Name",
      dataIndex: "team_lead_info",
      key: "team_lead_info",
      render: (info) => <span>{info?.name || "-"}</span>,
    },
    {
      title: "Department Name",
      dataIndex: "department_info",
      key: "department_info",
      render: (info) => <span>{info?.name || "-"}</span>,
    },

    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (type) => (
        <span style={{ textTransform: "capitalize" }}>
          {type?.replace(/_/g, " ") || "-"}
        </span>
      ),
    },

    {
      title: "Request Type",
      dataIndex: "is_urgent",
      key: "is_urgent",
      render: (isUrgent) => (
        <span
          style={{
            color: isUrgent ? "#ff4d4f" : "#52c41a",
            borderRadius: "4px",
          }}
        >
          {isUrgent ? "Urgent" : "Normal"}
        </span>
      ),
    },
    {
      title: "Requested At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <span>
          {date
            ? new Date(date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "-"}
        </span>
      ),
    },
    {
      title: "Approval Status",
      dataIndex: "gacl_status",
      key: "gacl_status",
      render: (status) => {
        let color;
        let text;

        switch (status) {
          case "APPROVED":
            color = "success";
            text = "Approved";
            break;
          case "PENDING":
            color = "warning";
            text = "Pending";
            break;
          case "REJECTED":
            color = "error";
            text = "Rejected";
            break;
          default:
            color = "default";
            text = "-";
        }

        return <Tag color={color}>{text}</Tag>;
      },
    },

    {
      title: "Actions",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => handleVendorSelection(record.id, "view")}
          style={{
            backgroundColor: "#FF902E",
            borderColor: "#FF902E",
            color: "white",
          }}
        >
          View Details
        </Button>
      ),
    },
    {
      title: "Add/Edit",
      key: "edit",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => handleVendorSelection(record.id, "edit")}
          style={{
            background: "#FD705C",
            borderColor: "#FF902E",
            color: "white",
          }}
        >
          Edit
        </Button>
      ),
    },

    {
      title: "Document",
      dataIndex: "id",
      key: "documents",
      render: (vendorId) => {
        const documents = vendorDocuments[vendorId];

        if (!documents?.length) {
          return <span>No documents</span>;
        }

        return (
          <div style={{ marginBottom: "10px" }}>
            <DocumentButton documents={documents} />
          </div>
        );
      },
    },
  ];
  // filters options
  const departments = [
    { id: 1, name: "Human Resources" },
    { id: 2, name: "GACL" },
    { id: 3, name: "Technical" },
    { id: 4, name: "Operations" },
    { id: 5, name: "Marketing" },
    { id: 6, name: "Head Office" },
  ];

  return (
    <>
      <GaclSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Space
            direction="vertical"
            style={{ width: "100%", marginBottom: 16 }}
          >
            <div className="filters-section d-flex gap-2">
              <Select
                showSearch
                style={{ width: "100%", maxWidth: 300, height: 38 }}
                placeholder="Select Department"
                optionFilterProp="children"
                value={selectedDepartment}
                onChange={handleDepartmentChange}
                allowClear
                onClear={() => {
                  setSelectedDepartment(null);
                  fetchVendorData(1, pagination.pageSize);
                }}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {departments.map((dept) => (
                  <Select.Option key={dept.id} value={dept.id}>
                    {dept.name}
                  </Select.Option>
                ))}
              </Select>
              <Button
                type="primary"
                style={{
                  marginBottom: 16,
                  height: 38,
                  backgroundColor: "#FF902E",
                }}
                onClick={() => {
                  fetchVendorData(1, pagination.pageSize);
                  setSelectedDepartment(null);
                }}
              >
                Reset Filter
              </Button>
            </div>
          </Space>

          <Card>
            <Table
              loading={loading}
              dataSource={vendorData}
              columns={columns}
              rowKey="id"
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                pageSizeOptions: ["10", "20", "30", "50"],
              }}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
              style={{
                overflowX: "auto",
                width: "100%",
              }}
            />

            <VendorRequestviewmodal
              isVisible={isModalVisible}
              onClose={handleModalClose}
              vendorDetails={selectedVendor}
              val={formData.comments}
              valhandler={(e) => {
                setFormData((prevState) => ({
                  ...prevState,
                  comments: e.target.value,
                }));
              }}
              approvalhandler={handleVendorApproval}
              setSelectedvendor={setSelectedVendor}
              gacluser={true}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default Vendorapprovalrequest;
