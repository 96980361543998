import React, { useState, useEffect } from "react";
import { Card, Table, Button, Modal, Form, Input } from "antd";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  trackInitialisation,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { useTrackInitContext } from "../../../../contexts/TrackInitContext";

const TrackInitialisationList = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [trackName, setTrackName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [employeeDesignation, setEmployeeDesignation] = useState(null);
  const { tracks, updateTracks, fetchTracks } = useTrackInitContext();

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
      setEmployeeDesignation(
        response.data.employment_details[0].designation_detail.name
      );
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        await fetchEmployeeDetails();

        const response = await axiosInstance.get(trackInitialisation);
        updateTracks(response.data);
      } catch (error) {
        console.error("Error fetching initial data:", error);
        toast.error("Failed to fetch initial data");
      }
    };

    fetchInitialData();
  }, [updateTracks]);

  const handleSave = async () => {
    if (!trackName.trim()) {
      toast.error("Track name is required");
      return;
    }

    if (!employeeId) {
      toast.error("Employee details not available");
      return;
    }

    setIsLoading(true);
    try {
      const payload = {
        name: trackName,
        created_by: employeeId,
      };

      let response;
      if (selectedTrack) {
        response = await axiosInstance.put(
          `${trackInitialisation}${selectedTrack.id}/`,
          payload
        );
      } else {
        response = await axiosInstance.post(trackInitialisation, payload);
      }

      // Fetch updated data and update context
      const updatedData = await axiosInstance.get(trackInitialisation);
      updateTracks(updatedData.data);

      setIsModalVisible(false);
      setTrackName("");
      setSelectedTrack(null);
      toast.success(
        `Track successfully ${selectedTrack ? "updated" : "created"}`
      );
    } catch (error) {
      console.error("Error saving track:", error);
      toast.error("Failed to save track");
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axiosInstance.delete(`${trackInitialisation}${id}/`);
      // Fetch updated data and update context
      const updatedData = await axiosInstance.get(trackInitialisation);
      updateTracks(updatedData.data);
      toast.success("Track successfully deleted");
    } catch (error) {
      console.error("Error deleting track:", error);
      toast.error("Failed to delete track");
    }
  };

  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Track Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Created By",
      dataIndex: ["created_details", "employee_name"],
      key: "created_by",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) =>
        employeeDesignation === "Group Leader" ? (
          <>
            <Button
              type="primary"
              onClick={() => {
                setSelectedTrack(record);
                setTrackName(record.name);
                setIsModalVisible(true);
              }}
              style={{ marginRight: 8 }}
            >
              Edit
            </Button>
            <Button
              type="primary"
              danger
              onClick={() => handleDelete(record.id)}
            >
              Delete
            </Button>
          </>
        ) : (
          <Button
            type="primary"
            onClick={() => {
              setSelectedTrack(record);
              setTrackName(record.name);
              setIsModalVisible(true);
            }}
          >
            View
          </Button>
        ),
    },
  ];

  return (
    <>
      <Card
        title={
          <div style={{ fontSize: "20px", fontWeight: "bold" }}>
            Track Initialisation
          </div>
        }
        extra={
          employeeDesignation === "Group Leader" && (
            <Button
              type="primary"
              onClick={() => {
                setIsModalVisible(true);
                setSelectedTrack(null);
                setTrackName("");
              }}
              style={{ background: "#28a745", borderColor: "#28a745" }}
            >
              Add Track
            </Button>
          )
        }
        style={{ boxShadow: "0 2px 4px rgba(0,0,0,0.1)" }}
      >
        <Table
          columns={columns}
          dataSource={tracks}
          rowKey="id"
          loading={isLoading}
          pagination={{ pageSize: 10 }}
          scroll={{ x: true }}
        />
      </Card>

      <Modal
        title={
          <div style={{ color: "#28a745" }}>
            {selectedTrack
              ? employeeDesignation === "Group Leader"
                ? "Edit Track"
                : "View Track"
              : "Add Track"}
          </div>
        }
        open={isModalVisible}
        onOk={handleSave}
        onCancel={() => {
          setIsModalVisible(false);
          setSelectedTrack(null);
          setTrackName("");
        }}
        confirmLoading={isLoading}
        okButtonProps={{
          style: { background: "#28a745", borderColor: "#28a745" },
          disabled: employeeDesignation !== "Group Leader",
        }}
      >
        <Form layout="vertical">
          <Form.Item
            label="Track Name"
            required
            rules={[{ required: true, toast: "Please input track name!" }]}
          >
            <Input
              value={trackName}
              onChange={(e) => setTrackName(e.target.value)}
              placeholder="Enter track name"
              disabled={employeeDesignation !== "Group Leader"}
            />
          </Form.Item>
          {selectedTrack && (
            <Form.Item label="Created By">
              <Input
                value={selectedTrack.created_details?.employee_name}
                disabled
              />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default TrackInitialisationList;
