import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Card, Form, Input, Button, Spin, Typography, List } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  endDocumentField,
  predecessorField,
  assignedJob,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import OperationSideBar from "../../../layout/OperationsSidebar";
import { TrackProvider } from "../../../../contexts/TrackContext";

const { Text, Title } = Typography;

const PredecessorFields = () => {
  const { id, jobId } = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [endFieldDetails, setEndFieldDetails] = useState(null);
  const [pdFields, setPdFields] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Failed to fetch employee details");
    }
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  const fetchEndFieldDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(`${endDocumentField}${id}/`);
      setEndFieldDetails(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching end field details:", error);
      toast.error("Failed to fetch end field details");
      setLoading(false);
    }
  }, [id]);

  const fetchPdFields = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${predecessorField}?end_field=${id}`
      );
      setPdFields(response.data);
    } catch (error) {
      console.error("Error fetching predecessor fields:", error);
      toast.error("Failed to fetch predecessor fields");
    }
  }, [id]);

  useEffect(() => {
    fetchEndFieldDetails();
    fetchPdFields();
  }, [fetchEndFieldDetails, fetchPdFields]);

  const onFinish = async (values) => {
    setSubmitting(true);
    const toastId = toast.loading("Processing...");

    try {
      const payload = {
        end_field: id,
        assignee: employeeId,
        pd_fields: values.pd_fields,
      };
      await axiosInstance.post(predecessorField, payload);

      if (jobId) {
        await axiosInstance.patch(`${assignedJob}${jobId}/`, {
          status: "Completed",
        });
      }
      toast.success("Predecessor fields submitted successfully");
      fetchPdFields();
      form.resetFields();
    } catch (error) {
      console.error("Error submitting predecessor fields:", error);
      toast.error("Failed to submit predecessor fields");
    } finally {
      setSubmitting(false);
      toast.dismiss(toastId);
    }
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <TrackProvider>
        <OperationSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            <Card
              title={`End Document Field: ${
                endFieldDetails?.field_name || "N/A"
              }`}
            >
              <Title level={4}>
                End Document:{" "}
                {endFieldDetails?.end_document_details?.end_name || "N/A"}
              </Title>
              <Title level={5}>
                Track: {endFieldDetails?.track_details?.name || "N/A"}
              </Title>

              <div style={{ marginBottom: "20px" }}>
                <Text strong>Existing Predecessor Fields:</Text>
                <List
                  dataSource={pdFields}
                  renderItem={(item) => (
                    <List.Item>{item.field_name}</List.Item>
                  )}
                />
              </div>

              <Form form={form} onFinish={onFinish} layout="vertical">
                <Form.List name="pd_fields">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map((field, index) => (
                        <Form.Item
                          required={false}
                          key={field.key}
                          label={
                            index === 0 ? "Add New Predecessor Fields" : ""
                          }
                        >
                          <Form.Item
                            {...field}
                            validateTrigger={["onChange", "onBlur"]}
                            rules={[
                              {
                                required: true,
                                whitespace: true,
                                message:
                                  "Please input predecessor field or delete this field.",
                              },
                            ]}
                            noStyle
                          >
                            <Input
                              style={{ width: "60%" }}
                              placeholder="Enter Predecessor Field"
                            />
                          </Form.Item>
                          <Button
                            type="link"
                            onClick={() => remove(field.name)}
                          >
                            Delete
                          </Button>
                        </Form.Item>
                      ))}
                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => add()}
                          icon={<PlusOutlined />}
                        >
                          Add Predecessor Field
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={submitting}>
                    Submit Predecessor Fields
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </div>
        </div>
      </TrackProvider>
    </>
  );
};

export default PredecessorFields;
