export const TechnicalSidebarData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Technical Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/technical-dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "Track Initialisation",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/technical/track-initialisation",
        icon: "fa fa-tasks",
      },
      {
        menuValue: "Tasks/Assignee",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/technical/task-dashboard",
        icon: "fa fa-user",
      },

      {
        menuValue: "Track Management",
        hasSubRoute: false,
        showSubRoute: false,
        route: [],
        icon: "fa fa-road",
      },

      {
        menuValue: "Projects",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-project-diagram",
        subMenus: [
          {
            menuValue: "DCDF Dashboard",
            route: "/tech-dashboard",
          },
          {
            menuValue: "ZII Dashboard",
            route: "/zii-tech-dashboard",
          },
          // {
          //   menuValue: "Projects",
          //   route: "#",
          //   // route: "/technical-projects",
          // },
          // {
          //   menuValue: "Project Management",
          //   route: "#",
          //   // route: "/tech-projects-management",
          // },

          // {
          //   menuValue: "Task Assigned",
          //   route: "/project-view",
          // },
        ],
      },
    ],
  },
];
