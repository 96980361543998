import { useState, useEffect } from "react";
import { Button, Card, Modal, Table, message } from "antd";
import {
  InvoiceDocuments,
  vendordocumentsProfile,
} from "../../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import GaclSideBar from "../../../../layout/GaclSidebar";
import { vendorprofile } from "../../../../../helpers/endpoints/api_endpoints";
import { LoadingOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import TcPassedVendorProfile from "../TcPassedVendorProfile/TcPassedVendorProfile";
const DocumentsHistory = () => {
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState([]);

  const [loadingId, setLoadingId] = useState(null);
  const antIcon = (
    <LoadingOutlined style={{ fontSize: 16, color: "#1890ff" }} spin />
  );
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [error, setError] = useState([]);
  const [documentsData, setDocumentsData] = useState({});
  const [documentLoading, setDocumentLoading] = useState({});

  // Fetch Intiated vendor profiles setup data
  const fetchVendorData = async (page, pageSize) => {
    try {
      setLoading(true);
      setError([]);

      const pageNumber = Math.max(1, page);
      const response = await axiosInstance.get(vendordocumentsProfile, {
        params: {
          page: pageNumber,
          page_size: pageSize,
        },
      });

      const { data } = response;
      const vendorResults =
        data?.results && Array.isArray(data.results)
          ? data.results
          : Array.isArray(data)
          ? data
          : [];

      setVendorData(vendorResults);
      setPagination({
        current: pageNumber,
        pageSize,
        total: data?.count || vendorResults.length || 0,
      });

      // Fetch documents for all vendors in parallel
      await Promise.all(
        vendorResults.map((vendor) => fetchVendorDocuments(vendor.id))
      );
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      setVendorData([]);
    } finally {
      setLoading(false);
    }
  };

  // Fetch documents for specific vendor
  const fetchVendorDocuments = async (vendorId) => {
    if (!vendorId) return;

    try {
      setDocumentLoading((prev) => ({ ...prev, [vendorId]: true }));

      const { data } = await axiosInstance.get(InvoiceDocuments, {
        params: { initiated_profile_id: vendorId },
      });

      const documents = data?.results || [];

      setDocumentsData((prev) => ({
        ...prev,
        [vendorId]: documents,
      }));
    } catch (error) {
      console.error(`Error fetching documents for vendor ${vendorId}:`, error);
      setDocumentsData((prev) => ({
        ...prev,
        [vendorId]: [],
      }));
    } finally {
      setDocumentLoading((prev) => ({ ...prev, [vendorId]: false }));
    }
  };

  // fetch tc passed vendor profiles
  const handleViewDetails = async (record) => {
    try {
      setLoadingId(record.id);
      const response = await axiosInstance.get(`${vendorprofile}`, {
        params: {
          vendor_profile_initiation: record.id,
        },
      });

      setSelectedVendor(response.data.results[0]);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error fetching vendor details:", error);
      message.error("Failed to fetch vendor details");
    } finally {
      setLoadingId(null);
    }
  };

  const handleTableChange = (newPagination) => {
    const { current, pageSize } = newPagination;

    setPagination((prev) => ({
      ...prev,
      current,
      pageSize,
    }));

    fetchVendorData(current, pageSize);
  };

  useEffect(() => {
    fetchVendorData(pagination.current, pagination.pageSize, {});
  }, []);

  const DocumentButton = ({ documents }) => {
    const getFileName = (url) => {
      if (!url) return "Unknown File";
      const urlParts = url.split("/");
      let fileName = urlParts[urlParts.length - 1];
      fileName = fileName.split("?")[0];
      try {
        fileName = decodeURIComponent(fileName);
      } catch (e) {
        console.error("Error decoding filename:", e);
      }
      return fileName;
    };

    // SVG Icons as components
    const FileIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "10px", color: "#1890ff" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );

    const FolderIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "8px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
        />
      </svg>
    );

    const ClickIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginLeft: "10px", color: "#52c41a" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    );

    const InfoIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="14"
        height="14"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "5px", color: "#666" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );

    const showDocumentList = () => {
      Modal.info({
        title: (
          <div
            style={{
              borderBottom: "1px solid #f0f0f0",
              padding: "8px 0",
              marginBottom: "16px",
              fontSize: "18px",
              color: "#34444C",
            }}
          >
            Available Documents
          </div>
        ),
        content: (
          <div>
            {documents.map((doc, index) => (
              <div
                key={index}
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  padding: "12px",
                  border: "1px solid #e8e8e8",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  ":hover": {
                    transform: "translateY(-1px)",
                    boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
                  },
                }}
                onClick={() => window.open(doc.document, "_blank")}
              >
                <FileIcon />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#1890ff",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginBottom: "2px",
                    }}
                  >
                    {doc.fileName || getFileName(doc.document)}
                  </span>
                  <span
                    style={{
                      color: "#8c8c8c",
                      fontSize: "12px",
                    }}
                  >
                    Click to open in new tab
                  </span>
                </div>
                <ClickIcon />
              </div>
            ))}
            <div
              style={{
                marginTop: "20px",
                padding: "12px",
                backgroundColor: "#f6ffed",
                borderRadius: "8px",
                fontSize: "13px",
                color: "#52c41a",
                display: "flex",
                alignItems: "center",
                border: "1px solid #b7eb8f",
              }}
            >
              <InfoIcon />
              Click on any document card to open it in a new tab
            </div>
          </div>
        ),
        width: 500,
        className: "document-modal",
        icon: null,
        okText: "Close",
        okButtonProps: {
          style: {
            backgroundColor: "#34444C",
            borderColor: "#34444C",
          },
        },
      });
    };

    const handleDocumentClick = () => {
      if (documents.length === 1) {
        // For single document, open directly
        window.open(documents[0].document, "_blank");
      } else {
        // For multiple documents, show modal
        showDocumentList();
      }
    };

    return (
      <Button
        type="primary"
        onClick={handleDocumentClick}
        style={{
          backgroundColor: "#34444C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px",
          height: "30px",
          borderRadius: "6px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {documents.length === 1 ? <FileIcon /> : <FolderIcon />}
        <span style={{ marginLeft: "4px" }}>
          {documents.length > 1
            ? `View All (${documents.length})`
            : "View Document"}
        </span>
      </Button>
    );
  };

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendor_request_info",
      key: "vendor_request_info",
      render: (info, record) => (
        <span>
          {record.is_existing_vendor && !info ? (
            <span
              style={{
                backgroundColor: "#e6f7ff",
                color: "#1890ff",
                padding: "4px 8px",
                borderRadius: "4px",
                fontSize: "12px",
              }}
            >
              Existing Vendor
            </span>
          ) : (
            info?.vendor_name || "NA"
          )}
        </span>
      ),
    },
    {
      title: "Unique id",
      dataIndex: "vendor_profile_code",
      key: "vendor_profile_code",
      render: (info) => <span>{info || "N/A"}</span>,
    },

    {
      title: "Expense Account Type",
      dataIndex: "account_type",
      key: "account_type",
      render: (account_type) => (
        <span style={{ textTransform: "capitalize" }}>
          {account_type ? account_type.replace(/_/g, " ") : "NA"}
        </span>
      ),
    },
    {
      title: "Department",
      dataIndex: "department_info",
      key: "department_info",
      render: (department_info) => (
        <span style={{ textTransform: "capitalize" }}>
          {department_info?.name || "NA"}
        </span>
      ),
    },
    {
      title: "Profile Initiated at",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <span>
          {new Date(date).toLocaleDateString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          })}
        </span>
      ),
    },

    {
      title: "Invoice",
      dataIndex: "id",
      key: "documents",
      render: (vendorId) => {
        const documents = documentsData[vendorId];

        if (!documents?.length) {
          return <span>No documents</span>;
        }

        return (
          <div style={{ marginBottom: "10px" }}>
            <DocumentButton documents={documents} />
          </div>
        );
      },
    },

    {
      title: "TC Status",
      key: "invoice",
      render: (record) => {
        const getTagType = () => {
          switch (record.tc_status?.toLowerCase()?.trim()) {
            case "passed":
              return { color: "success", text: "Passed" };
            case "failed":
              return { color: "error", text: "Failed" };
            case "not_available":
              return { color: "default", text: "Not Assigned" };
            default:
              return { color: "warning", text: "Pending" };
          }
        };

        const { color, text } = getTagType();

        return <Tag color={color}>{text}</Tag>;
      },
    },

    {
      title: "View Profile",
      key: "action",
      render: (_, record) => {
        const isDisabled =
          record.tc_status?.toLowerCase()?.trim() === "not_available" ||
          record.tc_status?.toLowerCase()?.trim() === "pending" ||
          record.tc_status?.toLowerCase()?.trim() === "failed";

        // Custom Loader Component
        const CustomLoader = () => (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              padding: "4px 8px",
            }}
          >
            {[1, 2, 3].map((dot) => (
              <div
                key={dot}
                style={{
                  width: "6px",
                  height: "6px",
                  backgroundColor: "#1890ff",
                  borderRadius: "50%",
                  animation: `bounce 0.8s ${dot * 0.2}s infinite`,
                }}
              />
            ))}
            <style>
              {`
                @keyframes bounce {
                  0%, 100% { transform: translateY(0); }
                  50% { transform: translateY(-8px); }
                }
              `}
            </style>
          </div>
        );

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            {loadingId === record.id ? (
              <CustomLoader />
            ) : (
              <Button
                type="primary"
                onClick={() => handleViewDetails(record)}
                disabled={isDisabled}
                title={
                  isDisabled
                    ? `Cannot view details while TC status is ${record.tc_status}`
                    : ""
                }
                style={{
                  opacity: isDisabled ? 0.6 : 1,
                  cursor: isDisabled ? "not-allowed" : "pointer",
                }}
              >
                View Profile
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <GaclSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Button
            style={{
              backgroundColor: "#FD705C",
              width: "180px",
              height: "44px",
              backgroundColor: "#FD705C",
              color: "white",
              marginBottom: "30px",
            }}
          >
            Vendor Profiles
          </Button>
          <Card>
            <Table
              loading={loading}
              dataSource={vendorData}
              columns={columns}
              rowKey="id"
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                pageSizeOptions: ["10", "20", "30", "50"],
                current: pagination.current,
                pageSize: pagination.pageSize,
              }}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
              style={{
                overflowX: "auto",
                width: "100%",
              }}
            />
          </Card>
        </div>
      </div>

      <TcPassedVendorProfile
        isVisible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        vendorData={selectedVendor}
      />
    </>
  );
};

export default DocumentsHistory;
