import React, { useState, useEffect } from "react";
import { Card, Row, Col, Table, Typography, Button, Modal, Avatar } from "antd";
import { FaUserCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const { Title, Text } = Typography;

const CandidateProfile = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsModalVisible(true);
  }, []);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { title: "Applied Jobs", dataIndex: "job", key: "job" },
    { title: "Applied Date", dataIndex: "date", key: "date" },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text) => <span style={{ color: "#FFD700" }}>{text}</span>,
    },
  ];

  const dataSource = [
    {
      key: "1",
      job: "UI Designer",
      date: "02-02-2025",
      status: "Application in view",
    },
  ];

  const handleProfileSetup = () => {
    setIsModalVisible(false);
    navigate("/career-profile");
  };

  const handleLater = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div style={{ padding: 20 }}>
          <Title level={2}>Hi, Welcome to HABOT CONNECT</Title>

          <Row gutter={16}>
            <Col span={12}>
              <Card
                bordered
                style={{ background: "#FFE5E5", borderColor: "#FFAAAA" }}
              >
                <Row
                  align="middle"
                  justify="space-between"
                  style={{ padding: "15px 50px" }}
                >
                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Text strong>Applied Jobs</Text>
                      <Title level={2}>1</Title>
                      <Button
                        style={{
                          background: "black",
                          color: "white",
                          border: "none",
                        }}
                      >
                        View All
                      </Button>
                    </div>
                  </Col>
                  <Col
                    span={2}
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <div
                      style={{
                        width: "1px",
                        height: "80px",
                        backgroundColor: "pink",
                      }}
                    ></div>
                  </Col>

                  <Col>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Text strong>Project Submission</Text>
                      <Title level={2}>0</Title>
                    </div>
                  </Col>
                </Row>
              </Card>
              <Table
                dataSource={dataSource}
                columns={columns}
                pagination={false}
                style={{ marginTop: 20 }}
              />
            </Col>

            <Col span={12}>
              <Card bordered style={{ borderColor: "#007AFF" }}>
                <Title
                  level={4}
                  style={{
                    background: "#1E2B32",
                    color: "white",
                    padding: 10,
                    margin: "-16px -16px 10px -16px",
                  }}
                >
                  Notifications
                </Title>
                <Text>
                  Thank you for setting up your profile! Now, fill out the
                  application form to take the next step in the process.{" "}
                  <a href="#">Application Form</a>
                </Text>
                <br />
                <br />
                <Text>
                  Congratulations! You have been selected for the next round.
                  Please note the project deadline is on 3rd March 2025. Kindly
                  upload your project in the <a href="#">Project submission</a>{" "}
                  page. Best of luck!
                </Text>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      {/* Profile Setup Modal */}
      {/* this modal will only appear only for once after login/signup, but for
      testing purpose it will appear in each load */}
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={handleOk}
        footer={null}
        centered
        width={300}
      >
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar
            size={64}
            icon={<FaUserCircle size={400} />}
            style={{ margin: "20px 0" }}
          />
          <Title level={3}>Welcome</Title>
          <Text>Set up your profile</Text>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              type="primary"
              style={{ background: "#1E2B32", border: "none" }}
              onClick={handleProfileSetup}
            >
              Profile Setup
            </Button>
            <Button>Later</Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CandidateProfile;
