import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import {
  planPhase,
  employeeDetails,
  pdcaassigntask,
} from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";

function EmployeeFinalStatus({ taskId, onUpdate }) {
  const [status, setStatus] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [parameters, setParameters] = useState([
    { name: "", target: "", current: "" },
  ]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [employeeID, setEmployeeID] = useState();
  const [loading, setLoading] = useState(true);
  const [tasks, setTasks] = useState([]);
  const [error, setError] = useState(null);
  const [editableTasks, setEditableTasks] = useState({});

  useEffect(() => {
    if (taskId?.id) {
      axiosInstance
        .get(`${planPhase}${taskId.id}/`)
        .then((response) => {
          setCurrentStatus(response.data.status || "");
          setStatus(response.data.status || "");
          setSelectedOption(response.data.status || "");
        })
        .catch((error) => {
          console.error("Failed to fetch current status:", error);
          toast.error("Failed to fetch current status");
        });
    }
  }, [taskId]);

  const handleSubmit = async () => {
    try {
      await axiosInstance.patch(`${planPhase}${taskId?.id}/`, {
        status: status,
      });
      onUpdate();
      toast.success("Status updated successfully");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update status");
    }
  };
  useEffect(() => {
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setEmployeeID(response.data.employment_details[0].id);
      })
      .catch((err) => console.error(err));
  }, []);

  useEffect(() => {
    if (formSubmitted) {
      setParameters([{ name: "", target: "", current: "" }]);
      setFormSubmitted(false);
      setErrors({});
    }
  }, [formSubmitted]);

  const handleAddParameter = () => {
    setParameters([...parameters, { name: "", target: "", current: "" }]);
  };

  const handleParameterChange = (index, field, value) => {
    const updatedParameters = [...parameters];
    updatedParameters[index][field] = value;
    setParameters(updatedParameters);
  };

  const handleSave = () => {
    const newErrors = {};

    if (
      parameters.some((param) => !param.name || !param.target || !param.current)
    ) {
      newErrors.parameters = "All fields in parameters are required";
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      const formData = {
        user: employeeID,
        pdca_parameter: parameters.map((param) => ({
          parameter_name: param.name,
          improvement_target: parseInt(param.target, 10),
          actual_data: parseInt(param.current, 10),
        })),
      };

      console.log("Form data:", formData);
      console.log(taskId?.id);
      axiosInstance
        .patch(`${planPhase}${taskId?.id}/`, formData)
        .then((response) => {
          console.log(response.data);
          toast.success("PDCA Plan Phase saved successfully");
          setFormSubmitted(true);
        })
        .catch((error) => {
          console.error("Error saving PDCA Plan Phase", error);
          toast.error("Error saving PDCA Plan Phase");
        });
    }
  };
  const handleUpdate = async () => {
    const updatedData = {
      user: employeeID,
      pdca_parameter: parameters.map((param) => ({
        parameter_name: param.name,
        improvement_target: parseInt(param.target, 10),
        actual_data: parseInt(param.current, 10),
      })),
    };

    try {
      await axiosInstance.patch(`${planPhase}${taskId?.id}/`, updatedData);
      toast.success("Updated successfully!");
      onUpdate();
    } catch (error) {
      console.error("Update failed:", error);
      toast.error("Failed to update.");
    }
  };

  const fetchTasks = () => {
    axiosInstance
      .get(`${pdcaassigntask}?pdca_form=${taskId?.id}&user=${employeeID}`)
      .then((response) => {
        setTasks(response.data);
        console.log(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error fetching tasks:", err);
        setError(err.message);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (employeeID) {
      fetchTasks();
    }
  }, [employeeID]);

  const refreshTasks = () => {
    fetchTasks();
  };

  const handleEditDueDate = (taskId) => {
    setEditableTasks((prevState) => ({
      ...prevState,
      [taskId]: {
        ...prevState[taskId],
        isEditable: true,
      },
    }));
  };

  const handleDueDateChange = (taskId, value) => {
    setEditableTasks((prevState) => ({
      ...prevState,
      [taskId]: {
        ...prevState[taskId],
        editedDueDate: value,
      },
    }));
  };

  const handleDueDatePatch = async (taskId) => {
    const editedDueDate = editableTasks[taskId]?.editedDueDate;
    if (editedDueDate) {
      try {
        await axiosInstance.patch(`${pdcaassigntask}${taskId}/`, {
          due_date: editedDueDate,
        });
        setTasks((prevState) => {
          const updatedTasks = prevState.results.map((task) => {
            if (task.id === taskId) {
              return { ...task, due_date: editedDueDate };
            }
            return task;
          });
          return { ...prevState, results: updatedTasks };
        });
        toast.success("Due date updated successfully");
        setEditableTasks((prevState) => ({
          ...prevState,
          [taskId]: {
            ...prevState[taskId],
            isEditable: false,
          },
        }));
        onUpdate();
      } catch (error) {
        console.error(error);
        toast.error("Failed to update due date");
      }
    }
  };

  return (
    <div className="p-3">
      <Form>
        <Form.Group className="mb-3">
          <Button variant="primary" className="mb-3">
            Final Status & Closure
          </Button>
          <div>
            <Form.Label className="mt-4">Problem Statement</Form.Label>
            <Form.Control
              size="lg"
              type="text"
              value={taskId?.problem_statement || ""}
              disabled
            />
          </div>
          <Row>
            <Col>
              <Form.Label className="mt-4">PDCA Cycle Status</Form.Label>
              <Form.Select
                value={status}
                onChange={(e) => {
                  setStatus(e.target.value);
                  setSelectedOption(e.target.value); // Update selected option
                }}
              >
                <option>Select</option>
                <option value="Closed">Closed</option>
                <option value="Continue">Continue</option>
                <option value="Iterative">Iterative</option>
              </Form.Select>
            </Col>
          </Row>
        </Form.Group>

        {selectedOption === "Continue" && (
          <div className="mt-3">
            <div className="table-responsive m-t-15">
              <table className="table table-striped custom-table">
                <thead>
                  <tr>
                    <th className="text-center">Task</th>
                    <th className="text-center">Due Date</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="6" className="text-center">
                        Loading tasks...
                      </td>
                    </tr>
                  ) : error ? (
                    <tr>
                      <td colSpan="6" className="text-center text-danger">
                        {error}
                      </td>
                    </tr>
                  ) : tasks.results?.length > 0 ? (
                    tasks.results.map((task) => (
                      <tr key={task.id}>
                        <td className="text-center">{task.pdca_task}</td>
                        <td className="text-center">
                          {editableTasks[task.id]?.isEditable ? (
                            <input
                              type="date"
                              value={
                                editableTasks[task.id]?.editedDueDate ||
                                task.due_date
                              }
                              onChange={(e) =>
                                handleDueDateChange(task.id, e.target.value)
                              }
                            />
                          ) : (
                            task.due_date
                          )}
                        </td>
                        <td className="text-center">
                          {editableTasks[task.id]?.isEditable ? (
                            <Button
                              variant="secondary"
                              onClick={() => handleDueDatePatch(task.id)}
                            >
                              Submit Due Date
                            </Button>
                          ) : (
                            <Button
                              variant="primary"
                              onClick={() => handleEditDueDate(task.id)}
                            >
                              Edit Due Date
                            </Button>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No tasks found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}

        <Button onClick={handleSubmit} variant="warning" className="px-4 mt-2">
          Submit
        </Button>

        {selectedOption === "Iterative" && (
          <div>
            <div>
              <div>
                <Form>
                  {parameters.map((param, index) => (
                    <Row key={index}>
                      <Col>
                        <Form.Label className="mt-4">Parameter</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          value={param.name}
                          onChange={(e) =>
                            handleParameterChange(index, "name", e.target.value)
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Label className="mt-4">
                          Improvement Target
                        </Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          value={param.target}
                          onChange={(e) =>
                            handleParameterChange(
                              index,
                              "target",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                      <Col>
                        <Form.Label className="mt-4">Current Data</Form.Label>
                        <Form.Control
                          size="lg"
                          type="text"
                          value={param.current}
                          onChange={(e) =>
                            handleParameterChange(
                              index,
                              "current",
                              e.target.value
                            )
                          }
                        />
                      </Col>
                    </Row>
                  ))}

                  <Button
                    variant="secondary"
                    type="button"
                    className="mt-4 mb-3"
                    onClick={handleAddParameter}
                  >
                    + Add more
                  </Button>
                  <div>
                    <Button
                      variant="warning"
                      type="button"
                      className="px-4"
                      onClick={handleSave}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        )}
      </Form>
    </div>
  );
}

export default EmployeeFinalStatus;
