import React, { useState } from "react";
import { Form, Input, Button, Row, Col, Typography, Card } from "antd";

const { Text } = Typography;

const AddressForm = () => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => {
    form.validateFields().then(() => setIsEditing(false));
  };

  return (
    <Card
      title="Address Details"
      extra={
        isEditing ? (
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        ) : (
          <Button onClick={handleEdit}>Edit</Button>
        )
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ maxWidth: "90%", margin: "auto" }}
      >
        {/* Permanent Address */}
        <Text
          strong
          style={{
            background: "#1D2B2D",
            color: "white",
            padding: "6px 12px",
            borderRadius: "4px",
            display: "inline-block",
            marginBottom: "12px",
          }}
        >
          Permanent Address:
        </Text>
        <Row gutter={24}>
          <Col xs={12} md={6}>
            <Form.Item label="Street Address">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="House No. (optional)">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="City">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="State/Province">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12} md={6}>
            <Form.Item label="Zip/Postal Code">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Country">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
        </Row>

        {/* Present Address */}
        <Text
          strong
          style={{
            background: "#1D2B2D",
            color: "white",
            padding: "6px 12px",
            borderRadius: "4px",
            display: "inline-block",
            marginBottom: "12px",
          }}
        >
          Present Address (if different from permanent):
        </Text>
        <Row gutter={24}>
          <Col xs={12} md={6}>
            <Form.Item label="Street Address">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="House No. (optional)">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="City">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="State/Province">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xs={12} md={6}>
            <Form.Item label="Zip/Postal Code">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
          <Col xs={12} md={6}>
            <Form.Item label="Country">
              <Input disabled={!isEditing} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AddressForm;
