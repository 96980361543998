import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button, Container, Space } from "react-bootstrap";
import { Table, Tabs, Typography, Tag, Select, Spin } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  separation,
  employeeList,
  employeeDetails,
  handover,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ViewHandoverForm from "../../Employees/EmployeeResignation/ViewHandoverForm";
import EditTrackHandover from "../../Employees/EmployeeResignation/EditTrackHandover";
import HandoverChecklist from "./checkListTL";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const getStatusColor = (status) => {
  switch (status) {
    case "Submission":
      return "orange"; // Pending state
    case "Reviewed":
      return "cyan"; // Initial review done
    case "Handover":
      return "blue"; // In handover process
    case "Handover Approval":
      return "purple"; // Waiting for final approval
    case "Approved":
      return "green"; // Final approved state
    case "Rejected":
      return "red"; // Rejected state
    default:
      return "default"; // Fallback color
  }
};

const ViewModal = React.memo(
  ({
    show,
    onHide,
    resignation,
    employees = [],
    onSubmit,
    onApproveHandover,
    onEmployeeSelect,
    selectedEmployee,
    activeTabKey,
    setActiveTabKey,
    isEditing,
    setIsEditing,
    handleEditClick,
    handleSuccess,
    handoverData,
  }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [actionType, setActionType] = useState(null); // 'approve' or 'reject'
    const [remarks, setRemarks] = useState("");

    const handleAction = (type) => {
      setActionType(type);
      setShowConfirmModal(true);
    };

    console.log("ViewModal Props:", {
      resignationStatus: resignation?.status,
      isEditing,
      handoverData,
    });

    return (
      <>
        <Modal
          show={show}
          onHide={onHide}
          size="xl"
          centered
          onExited={() => setIsEditing(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Resignation Details
              {resignation && (
                <Tag
                  color={getStatusColor(resignation.status)}
                  style={{ marginLeft: "10px" }}
                >
                  {resignation.status}
                </Tag>
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {resignation && (
              <Tabs
                activeKey={activeTabKey}
                onChange={(key) => setActiveTabKey(key)}
                style={{ marginTop: -15 }}
              >
                <TabPane tab="Resignation Letter" key="1">
                  <Typography>
                    <Title level={4}>
                      {resignation.user_details.employee_name}'s Resignation
                    </Title>

                    <div
                      style={{
                        display: "flex",
                        gap: "24px",
                        marginBottom: "20px",
                        flexWrap: "wrap",
                      }}
                    >
                      <Paragraph style={{ margin: 0 }}>
                        <strong>Department:</strong>{" "}
                        {
                          resignation.user_details.employment_details[0]
                            .department_detail.name
                        }
                      </Paragraph>

                      <Paragraph style={{ margin: 0 }}>
                        <strong>Designation:</strong>{" "}
                        {
                          resignation.user_details.employment_details[0]
                            .designation_detail.name
                        }
                      </Paragraph>

                      <Paragraph style={{ margin: 0 }}>
                        <strong>Submitted Date:</strong>{" "}
                        {moment(resignation.created_at).format("DD-MM-YYYY")}
                      </Paragraph>
                    </div>

                    <Title level={5}>Resignation Letter:</Title>
                    <Paragraph style={{ whiteSpace: "pre-line" }}>
                      {resignation.resignation}
                    </Paragraph>

                    {resignation.signature && (
                      <>
                        <Title level={5}>Signature:</Title>
                        <div
                          style={{
                            maxWidth: "200px",
                            border: "1px solid #f0f0f0",
                            padding: "10px",
                            marginTop: "10px",
                          }}
                        >
                          <img
                            src={resignation.signature}
                            alt="Signature"
                            style={{ width: "100%" }}
                          />
                        </div>
                      </>
                    )}

                    <Form.Group className="mt-4">
                      {resignation.status === "Submission" &&
                        resignation.is_reviewed === true &&
                        resignation.team_leader && (
                          <>
                            <Form.Label>
                              <strong>Assign Person Taking Over</strong>
                            </Form.Label>
                            <Select
                              showSearch
                              style={{ width: "100%" }}
                              placeholder="Search and select employee for person taking over"
                              optionFilterProp="children"
                              onChange={onEmployeeSelect}
                              value={selectedEmployee}
                              filterOption={(input, option) =>
                                option?.label
                                  ?.toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              options={
                                employees?.map((emp) => ({
                                  value: emp.id,
                                  label: `${emp.employee_name} - ${
                                    emp.employment_details[0]?.department_detail
                                      ?.name || ""
                                  } - ${
                                    emp.employment_details[0]
                                      ?.designation_detail?.name || ""
                                  }`,
                                })) || []
                              }
                            />
                            <Button
                              variant="danger"
                              className="mt-3 me-2"
                              onClick={() => handleAction("reject")}
                            >
                              Reject
                            </Button>

                            <Button
                              variant="success"
                              className="mt-3"
                              onClick={() => handleAction("approve")}
                              disabled={selectedEmployee === null}
                            >
                              Assign & Approve
                            </Button>
                          </>
                        )}

                      {resignation.remarks && (
                        <Paragraph>
                          <strong>Rejection Reason:</strong>{" "}
                          {resignation.remarks}
                        </Paragraph>
                      )}

                      {resignation.handover_details && (
                        <>
                          <Form.Label>
                            <strong>Person Taking Over:</strong>
                            {/* {resignation.handover_date && (
                              <small className="text-muted ml-2">
                                (Handover Date:{" "}
                                {moment(resignation.handover_date).format(
                                  "DD-MM-YYYY"
                                )}
                                )
                              </small>
                            )} */}
                          </Form.Label>
                          <div className="border rounded p-3">
                            <Paragraph>
                              <strong>Name:</strong>{" "}
                              {resignation.handover_details?.employee_name}
                            </Paragraph>
                            <Paragraph>
                              <strong>Department:</strong>{" "}
                              {
                                resignation.handover_details
                                  ?.employment_details[0]?.department_detail
                                  ?.name
                              }
                            </Paragraph>
                            <Paragraph>
                              <strong>Designation:</strong>{" "}
                              {
                                resignation.handover_details
                                  ?.employment_details[0]?.designation_detail
                                  ?.name
                              }
                            </Paragraph>
                            {resignation.is_approved && (
                              <Paragraph>
                                <strong>Approved Date:</strong>{" "}
                                {moment(resignation.approved_date).format(
                                  "DD-MM-YYYY"
                                )}
                              </Paragraph>
                            )}
                          </div>
                        </>
                      )}

                      {resignation.status === "Submission" &&
                        resignation.is_reviewed === false && (
                          <div className="text-muted">
                            Handover person can be assigned after HR reviews the
                            resignation
                          </div>
                        )}
                    </Form.Group>
                  </Typography>
                </TabPane>

                <TabPane tab="Handover Form" key="2">
                  {isEditing ? (
                    <EditTrackHandover
                      resignationId={handoverData?.id}
                      onClose={() => setIsEditing(false)}
                      onSuccess={handleSuccess}
                      existingTracks={handoverData?.handover_detail || []}
                      employees={employees}
                      externalContacts={
                        handoverData?.handover_detail?.[0]?.external_contact ||
                        []
                      }
                    />
                  ) : (
                    <div>
                      <ViewHandoverForm separationId={resignation.id} />
                      <div
                        style={{
                          // display: "flex",
                          // gap: "16px",
                          // marginTop: "16px",
                          justifyContent: "flex-start",
                        }}
                      >
                        {handoverData?.handover_detail?.length > 0 && (
                          <Button type="primary" onClick={handleEditClick}>
                            Assign Handover Task/ Edit
                          </Button>
                        )}
                        {resignation?.status === "Handover" &&
                          onApproveHandover()}
                      </div>
                    </div>
                  )}
                </TabPane>
                {(resignation?.status === "Handover Approval" ||
                  resignation?.status === "Approved") && (
                  <TabPane tab="Handover Checklist" key="3">
                    <Typography>
                      <Title level={5}>Checklist Items</Title>
                      {/* Add checklist items here */}
                      <HandoverChecklist selectedResignation={resignation} />
                    </Typography>
                  </TabPane>
                )}
              </Tabs>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onHide}>
              Close
            </Button>
            {/* {resignation?.status === "Submission" && resignation.is_reviewed && (
              <Button variant="primary" onClick={onSubmit}>
                Submit Handover/Review
              </Button>
            )} */}
          </Modal.Footer>
        </Modal>

        {/* Confirmation Modal */}
        <Modal
          show={showConfirmModal}
          onHide={() => setShowConfirmModal(false)}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {actionType === "approve"
                ? "Confirm Approval"
                : "Confirm Rejection"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {actionType === "approve" ? (
              <p>
                Are you sure you want to approve and assign the handover person?
              </p>
            ) : (
              <Form>
                <Form.Group>
                  <Form.Label>Remarks</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    value={remarks}
                    onChange={(e) => setRemarks(e.target.value)}
                    required
                    placeholder="Please provide remarks for rejection"
                  />
                </Form.Group>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => {
                setShowConfirmModal(false);
                setRemarks("");
              }}
            >
              Cancel
            </Button>
            <Button
              variant={actionType === "approve" ? "success" : "danger"}
              onClick={async () => {
                try {
                  if (actionType === "reject") {
                    if (!remarks.trim()) {
                      toast.error("Please provide remarks for rejection");
                      return;
                    }
                    await axiosInstance.patch(
                      `${separation}${resignation.id}/`,
                      {
                        status: "Rejected",
                        remarks: remarks,
                      }
                    );
                  } else {
                    // Existing approve logic
                    onSubmit("Handover");
                  }

                  toast.success(
                    `Resignation ${
                      actionType === "approve" ? "approved" : "rejected"
                    } successfully`
                  );
                  setShowConfirmModal(false);
                  setRemarks("");
                  onHide();
                } catch (error) {
                  console.error("Error updating resignation:", error);
                  toast.error("Error updating resignation");
                }
              }}
            >
              {actionType === "approve" ? "Approve" : "Reject"}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
);

const ViewResignationLetterTL = () => {
  const [resignations, setResignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedResignation, setSelectedResignation] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [employeeID, setEmployeeID] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showHandoverModal, setShowHandoverModal] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
  });
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [handoverFormKey, setHandoverFormKey] = useState(0);
  const [handoverData, setHandoverData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);

  // First fetch department ID
  useEffect(() => {
    const fetchDepartmentID = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        const empId = response.data.employment_details[0].id;
        setEmployeeID(empId);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };

    fetchDepartmentID();
  }, []);

  const fetchResignations = useCallback(
    async (page = pagination.current, pageSize = pagination.pageSize) => {
      if (!employeeID) return;

      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `${separation}?page=${page}&page_size=${pageSize}&team_leader=${employeeID}&is_tl=false`
        );
        console.log("Fetched resignations:", response.data); // Debug log
        setResignations(response.data.results);
        setPagination((prev) => ({
          ...prev,
          current: page,
          pageSize: pageSize,
          total: response.data.count,
        }));
      } catch (error) {
        console.error("Error fetching resignations:", error);
        toast.error("Error fetching resignations");
      } finally {
        setLoading(false);
      }
    },
    [employeeID]
  );

  // Update initial data fetch
  useEffect(() => {
    if (employeeID) {
      fetchResignations(1, pagination.pageSize);
    }
  }, [employeeID, fetchResignations, pagination.pageSize]);

  const handleTableChange = useCallback(
    (newPagination, filters, sorter) => {
      console.log("Pagination changed:", newPagination); // Debug log
      fetchResignations(newPagination.current, newPagination.pageSize);
    },
    [fetchResignations]
  );

  // Only fetch resignations when departmentID is available
  useEffect(() => {
    if (employeeID) {
      fetchResignations(1, pagination.pageSize);
      fetchEmployees();
    }
  }, [employeeID]);

  // Fetch handover data when a resignation is selected
  useEffect(() => {
    const fetchHandoverData = async () => {
      if (selectedResignation) {
        try {
          const response = await axiosInstance.get(
            `${handover}?separation=${selectedResignation.id}`
          );
          setHandoverData(response.data.results[0]);
        } catch (error) {
          console.error("Error fetching handover data:", error);
          toast.error("Failed to fetch handover data");
        }
      }
    };

    fetchHandoverData();
  }, [selectedResignation]);

  const handleEditClick = () => {
    if (!handoverData) {
      toast.error("No handover data available to edit");
      return;
    }
    setIsEditing(true);
  };

  const handleSuccess = async () => {
    try {
      // Refresh handover data
      const response = await axiosInstance.get(
        `${handover}?separation=${selectedResignation.id}`
      );
      setHandoverData(response.data.results[0]);

      // Refresh resignation list
      await refreshData();

      setIsEditing(false);
      toast.success("Handover form updated successfully");
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data");
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get(employeeList);
      const activeEmployees = response.data.filter(
        (emp) => emp.employment_details[0]?.employment_status === "Active"
      );

      setEmployees(activeEmployees);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Error fetching employees list");
    }
  };

  const handleView = (record) => {
    console.log("View button clicked", record);
    console.log("Record status:", record.status);
    setSelectedResignation(record);
    setActiveTabKey("1");
    setViewModalVisible(true);
  };

  const handleViewHandover = (record) => {
    console.log("View Handover button clicked", record);
    console.log("Record status:", record.status);
    setSelectedResignation(record);
    setActiveTabKey("2");
    setViewModalVisible(true);
  };

  const handleEmployeeSelect = useCallback((value) => {
    setSelectedEmployee(value);
  }, []);

  const handleModalClose = useCallback(async () => {
    setViewModalVisible(false);
    setSelectedEmployee(null);
    setIsEditing(false);

    // Refresh separation data
    try {
      await fetchResignations(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error refreshing resignation data:", error);
      toast.error("Failed to refresh resignation data");
    }
  }, [pagination.current, pagination.pageSize, fetchResignations]);

  const handleSubmitReview = useCallback(async () => {
    if (!selectedEmployee) {
      toast.error("Please select a handover person");
      return;
    }

    try {
      await axiosInstance.patch(`${separation}${selectedResignation.id}/`, {
        handover_person: selectedEmployee,
        status: "Reviewed",
      });

      toast.success("Resignation reviewed successfully");
      handleModalClose();
      fetchResignations(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error updating resignation:", error);
      toast.error("Error updating resignation");
    }
  }, [
    selectedEmployee,
    selectedResignation,
    pagination.current,
    pagination.pageSize,
    fetchResignations,
    handleModalClose,
  ]);

  const fetchHandoverData = useCallback(async (separationId) => {
    try {
      const response = await axiosInstance.get(
        `${handover}?separation=${separationId}`
      );
      setHandoverData(response.data.results[0]);
    } catch (error) {
      console.error("Error fetching handover data:", error);
      toast.error("Failed to fetch handover data");
    }
  }, []);

  const handleApproveHandover = useCallback(async () => {
    if (!handoverData) return;

    try {
      await axiosInstance.patch(`${handover}${handoverData.id}/`, {
        is_tl_approved: true,
      });

      toast.success("Handover approved successfully");
      setHandoverFormKey((prev) => prev + 1);
      fetchResignations();
      setViewModalVisible(false);
    } catch (error) {
      console.error("Error approving handover:", error);
      toast.error("Failed to approve handover");
    }
  }, [handoverData, fetchResignations]);

  const renderApproveHandoverButton = () => {
    if (
      selectedResignation?.status === "Handover" &&
      handoverData?.is_tl_approved === false
    ) {
      return (
        <Button
          type="primary"
          onClick={handleApproveHandover}
          style={{
            backgroundColor: "#52c41a",
            borderColor: "#52c41a",
            marginLeft: "10px",
          }}
        >
          Approve Handover
        </Button>
      );
    }
    return null;
  };

  useEffect(() => {
    if (selectedResignation) {
      fetchHandoverData(selectedResignation.id);
    }
  }, [selectedResignation, fetchHandoverData]);

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
      responsive: ["md"],
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
      responsive: ["lg"],
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      title: "Submitted Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD-MM-YYYY"),
      responsive: ["md"],
    },
    {
      title: "Last Working Date",
      dataIndex: "last_working_date",
      key: "last_working_date",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "N/A"),
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          <Button type="primary" onClick={() => handleView(record)}>
            View
          </Button>
          {record.status === "Handover" && record.is_handover && (
            <Button
              type="primary"
              onClick={() => handleViewHandover(record)}
              style={{ backgroundColor: "#FF9F45" }}
            >
              View Handover
            </Button>
          )}
        </div>
      ),
    },
  ];

  console.log("Rendering ViewResignationLetterTL, isEditing:", isEditing);

  const refreshData = async () => {
    try {
      const response = await axiosInstance.get(separation);
      setResignations(response.data.results);
      const updatedResignation = response.data.results.find(
        (r) => r.id === selectedResignation.id
      );
      setSelectedResignation(updatedResignation);
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data");
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Review Resignations"
          title="Dashboard"
          subtitle="Resignations"
        />

        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={resignations}
                loading={loading || !employeeID}
                rowKey="id"
                pagination={{
                  ...pagination,
                  showTotal: (total) => `Total ${total} items`,
                  showSizeChanger: true,
                  pageSizeOptions: ["10", "20", "50", "100"],
                  responsive: true,
                  position: ["bottom", "right"],
                  onChange: (page, pageSize) => {
                    handleTableChange({ current: page, pageSize });
                  },
                  onShowSizeChange: (current, size) => {
                    handleTableChange({ current: 1, pageSize: size });
                  },
                }}
                onChange={handleTableChange}
                scroll={{ x: true }}
              />
            </div>
          </div>
        </div>

        <ViewModal
          show={viewModalVisible}
          onHide={handleModalClose}
          resignation={selectedResignation}
          employees={employees}
          onSubmit={handleSubmitReview}
          onApproveHandover={renderApproveHandoverButton}
          onEmployeeSelect={handleEmployeeSelect}
          selectedEmployee={selectedEmployee}
          activeTabKey={activeTabKey}
          setActiveTabKey={setActiveTabKey}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleEditClick={handleEditClick}
          handleSuccess={handleSuccess}
          handoverData={handoverData}
        />

        <Modal
          title="Handover Form"
          visible={showHandoverModal}
          onCancel={() => setShowHandoverModal(false)}
          footer={null}
          width={1200}
          zIndex={1200}
        >
          {selectedResignation && (
            <div>
              {isEditing ? (
                <EditTrackHandover
                  resignationId={selectedResignation.id}
                  onClose={() => setIsEditing(false)}
                  onSuccess={handleSuccess}
                  existingTracks={handoverData?.handover_detail || []}
                />
              ) : (
                <div>
                  <ViewHandoverForm
                    separationId={selectedResignation.id}
                    handoverData={handoverData}
                  />
                  {selectedResignation.status === "Handover" && (
                    <Button
                      type="primary"
                      onClick={handleEditClick}
                      style={{ marginTop: "16px" }}
                      disabled={!handoverData}
                    >
                      Edit
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </Modal>
      </div>

      <style jsx>{`
        .responsive-content {
          width: 100%;
          max-width: 100%;
          overflow-x: hidden;
          padding: 16px;
        }

        @media (max-width: 768px) {
          .responsive-content {
            padding: 12px;
          }
        }

        @media (max-width: 576px) {
          .responsive-content {
            padding: 8px;
          }
        }

        .table-responsive {
          overflow-x: auto;
          -webkit-overflow-scrolling: touch;
        }
      `}</style>
    </div>
  );
};

export default ViewResignationLetterTL;
