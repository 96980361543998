import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  Spin,
  Descriptions,
  Tag,
  Typography,
  Row,
  Col,
  Divider,
} from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { QPAform } from "../../../../helpers/endpoints/api_endpoints";

const { Title, Text, Paragraph } = Typography;

const QPAForm = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(true);

  const ratingLabels = {
    1: "Watch finances daily & act to cut losses without remorse.",
    2: "Keep 'Thinking'- jobs in-house & outsource all 'Doing' jobs.",
    3: "Reconstruct & standardize complex tasks and processes including outsourcing, into simple elements & then go accomplish them.",
    4: "Continuously look for money-making opportunities for our platform.",
    5: "Keep an open mind & embrace the diversity of people, ideas & beliefs.",
    6: "Encourage & promote passion and accountability.",
    7: "The understanding of Habot's Vision & Values must be very clear to the whole team.",
    8: "Even if you disagree, express it, and commit to Habot's course while you are a HabotTech",
  };

  const exampleLabels = {
    3: "Describe how the Assessee has taken responsibility for his or her own work.",
    4: "A. What superpowers does the Assessee possess? Please list and explain his/her superpowers and abilities",
    5: "B. What challenges does the Assessee face and in which type of situations? Please list and explain where Assessee struggles",
    6: "What specific superpowers should the Assesssee be helped out with or provided with?",
    7: "What are 2 specific contributions made by the Assessee to advance towards becoming a 'high-performing engine'?",
    8: "What stands out about the assessee",
    9: "What are the assessee's strengths?",
    10: "Comments on superpowers as mentioned above",
    11: "What can the Assessee do to develop and grow ",
  };

  useEffect(() => {
    const fetchFormData = async () => {
      try {
        const response = await axiosInstance.get(
          `${QPAform}?nomination_qpa=${id}`
        );
        if (response.data.results && response.data.results.length > 0) {
          setFormData(response.data.results[0]); // Get the first result
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchFormData();
  }, [id]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  if (!formData) {
    return (
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Card>
            <Title level={4}>No form data found</Title>
          </Card>
        </div>
      </div>
    );
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Card>
          <Row gutter={[0, 24]}>
            {/* Header Section */}
            <Col span={24}>
              <Title level={2} style={{ marginBottom: 0 }}>
                QPA Form Details
              </Title>
              <Text type="secondary">
                Created on: {new Date(formData.created_at).toLocaleString()}
              </Text>
            </Col>

            {/* Basic Information Section */}
            <Col span={24}>
              <Card type="inner" title="Basic Information">
                <Row gutter={[24, 16]}>
                  <Col xs={24} sm={12}>
                    <Text strong>Nominated By: </Text>
                    <Text>{formData.user_details?.employee_name}</Text>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Text strong>Department: </Text>
                    <Text>
                      {formData.user_details?.employment_details[0]
                        ?.department_detail?.name || "N/A"}
                    </Text>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Text strong>Type: </Text>
                    <Text>{formData.type}</Text>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Text strong>Quarter: </Text>
                    <Text>{formData.quarter}</Text>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Text strong>Status: </Text>
                    <Tag color={formData.declaration ? "green" : "orange"}>
                      {formData.declaration ? "Completed" : "Pending"}
                    </Tag>
                  </Col>
                </Row>
              </Card>
            </Col>

            {/* LP Questions Section - Styled like Assessment Details */}
            <Col span={24}>
              <Card
                type="inner"
                title={
                  <Title level={4} style={{ marginBottom: 0 }}>
                    Leadership Principles Assessment
                  </Title>
                }
              >
                <div style={styles.assessmentContainer}>
                  <div style={styles.assessmentLabel}>
                    <Text strong>
                      What 3 specific instances can you provide of how the
                      Assessee contributed to enhancing the team's performance?
                      Please write actual cases in 50 words each.
                    </Text>
                  </div>
                  <div style={styles.assessmentContent}>
                    <Paragraph style={styles.contentText}>
                      {formData.instance_item || "N/A"}
                    </Paragraph>
                  </div>
                  <Divider style={styles.divider} />
                </div>

                <div style={styles.assessmentContainer}>
                  <div style={styles.assessmentLabel}>
                    <Text strong>
                      A - Which two specific LPs did the Assessee focus on and
                      excel at during the quarter under review?
                    </Text>
                  </div>
                  <div style={styles.assessmentContent}>
                    <div style={{ marginBottom: "8px" }}>
                      1. {formData.checkbox_1 || "N/A"}
                    </div>
                    <div>2. {formData.checkbox_2 || "N/A"}</div>
                  </div>
                  <Divider style={styles.divider} />
                </div>

                <div style={styles.assessmentContainer}>
                  <div style={styles.assessmentLabel}>
                    <Text strong>Answer:</Text>
                  </div>
                  <div style={styles.assessmentContent}>
                    <div style={{ marginBottom: "16px" }}>
                      <Text strong>For LP 1:</Text>
                      <Paragraph style={styles.contentText}>
                        {formData.example_1 || "N/A"}
                      </Paragraph>
                    </div>
                    <div>
                      <Text strong>For LP 2:</Text>
                      <Paragraph style={styles.contentText}>
                        {formData.example_2 || "N/A"}
                      </Paragraph>
                    </div>
                  </div>
                  <Divider style={styles.divider} />
                </div>
              </Card>
            </Col>

            {/* Ratings Section */}
            <Col span={24}>
              <Card
                type="inner"
                title={
                  <div>
                    <Title level={4} style={{ marginBottom: "8px" }}>
                      Ratings
                    </Title>
                    <Text type="secondary" style={{ fontSize: "14px" }}>
                      [5 means the Assesssee fits the principle perfectly and 1
                      means the Assessee does not fit the principle at all]
                    </Text>
                  </div>
                }
              >
                <Descriptions
                  bordered
                  column={{ xs: 1, sm: 1, md: 1 }}
                  labelStyle={{
                    whiteSpace: "pre-wrap",
                    padding: "16px",
                    fontWeight: 500,
                  }}
                  contentStyle={{
                    padding: "16px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {Object.entries(ratingLabels).map(([key, label]) => (
                    <Descriptions.Item
                      key={key}
                      label={
                        <div
                          style={{
                            fontWeight: 500,
                            maxWidth: "80%",
                          }}
                        >
                          {`${key}. ${label}`}
                        </div>
                      }
                    >
                      <Text>
                        {typeof formData[`rating_${key}`] === "string"
                          ? parseFloat(formData[`rating_${key}`]).toFixed(2)
                          : formData[`rating_${key}`]?.toFixed(2) || "N/A"}
                      </Text>
                    </Descriptions.Item>
                  ))}
                </Descriptions>
              </Card>
            </Col>

            {/* Examples Section - Keeps new format */}
            <Col span={24}>
              <Card
                type="inner"
                title={
                  <Title level={4} style={{ marginBottom: 0 }}>
                    Assessment Details
                  </Title>
                }
              >
                {Object.entries(exampleLabels).map(([key, label]) => (
                  <div key={key} style={styles.assessmentContainer}>
                    <div style={styles.assessmentLabel}>
                      <Text strong>{label}</Text>
                    </div>
                    <div style={styles.assessmentContent}>
                      <Paragraph style={styles.contentText}>
                        {formData[`example_${key}`] || "N/A"}
                      </Paragraph>
                    </div>
                    <Divider style={styles.divider} />
                  </div>
                ))}
              </Card>
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

// Updated styles for better content display
const styles = {
  ratingContainer: {
    padding: "16px 0",
  },
  ratingLabel: {
    marginBottom: "12px",
  },
  ratingContent: {
    paddingLeft: "24px",
  },
  assessmentContainer: {
    padding: "16px 0",
  },
  assessmentLabel: {
    marginBottom: "12px",
  },
  assessmentContent: {
    paddingLeft: "24px",
  },
  contentText: {
    fontSize: "14px",
    lineHeight: "1.8",
    margin: "8px 0",
    whiteSpace: "pre-line",
  },
  divider: {
    margin: "16px 0 0 0",
  },
};

export default QPAForm;
