import React, {
  useEffect,
  useState,
  useCallback,
  useLayoutEffect,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { avatar19, clockin } from "../../../../../Routes/ImagePath";
import { ArrowRightCircle, Gift, Award, CheckCircle } from "react-feather";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  employeeDetails,
  employeeNotifications,
  patchNotifications,
  holidays,
  celebrations,
  attendance,
  clockIn,
  clockOut,
  clockStatus,
  addLeaves,
  departmentList,
  readNotification,
} from "../../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { LOGOUT } from "../../../../../store/actions/accountsActions";
import { debounce } from "lodash"; // Make sure to import lodash
import moment from "moment";
import { Modal, Button } from "antd";
import { useSelector } from "react-redux";

const EmployeeDashboard = () => {
  const [employeeDetail, setEmployeeDetails] = useState();
  const [unreadNotifications, setUnreadNotifications] = useState([]);
  const [readNotifications, setReadNotifications] = useState([]);
  const [isPageReloaded, setIsPageReloaded] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const [upcomingHoliday, setUpcomingHoliday] = useState(null);
  const [todaysBirthdays, setTodaysBirthdays] = useState([]);
  const [todaysAnniversaries, setTodaysAnniversaries] = useState([]);
  const [activeTab, setActiveTab] = useState("birthdays");
  const [attendanceData, setAttendanceData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [clockInTime, setClockInTime] = useState(null);
  const [totalHours, setTotalHours] = useState("00:00:00");
  const [currentStatus, setCurrentStatus] = useState(null);
  const [employeeID, setEmployeeID] = useState(null);
  const [isAttendanceLoading, setIsAttendanceLoading] = useState(true);
  const [leaveData, setLeaveData] = useState(null);
  const [isLeaveLoading, setIsLeaveLoading] = useState(true);
  const [isTodayHoliday, setIsTodayHoliday] = useState(false);
  const [activeEmployees, setActiveEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDept, setSelectedDept] = useState({ id: "", name: "" });
  const [currentDeptIndex, setCurrentDeptIndex] = useState(0);
  const [isDepartmentsLoading, setIsDepartmentsLoading] = useState(true);
  const [showClockOutModal, setShowClockOutModal] = useState(false);
  const [pendingLeaves, setPendingLeaves] = useState(0);
  const [userRole, setUserRole] = useState(null);
  const [department, setDepartment] = useState(null);
  const [quickViewLeaves, setQuickViewLeaves] = useState([]);
  const [isApproving, setIsApproving] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.account.userInfo);

  const debouncedHandleLogout = useCallback(
    debounce(() => {
      if (loggingOut) return; // Prevent multiple logout attempts
      setLoggingOut(true);
      dispatch({ type: LOGOUT });
      localStorage.removeItem("accessToken");
      navigate("/", { replace: true });
      setLoggingOut(false);
    }, 300),
    [dispatch, navigate, loggingOut]
  );

  useEffect(() => {
    const isFirstTime = sessionStorage.getItem("isFirstTimeOnDashboard");

    if (!isFirstTime) {
      sessionStorage.setItem("isFirstTimeOnDashboard", "true");
      sessionStorage.setItem("showToast", "true");
      setIsPageReloaded(true);
    } else {
      setIsPageReloaded(true);
    }
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeDetails(response.data);
        setEmployeeID(response.data.employment_details[0].id);
        setDepartment(response.data.employment_details[0].department_detail.id);

        // Set user role based on designation
        const designation =
          response.data.employment_details[0].designation_detail.name;
        const employeeName = response.data.employee_name;

        if (designation === "Team Leader" || designation === "Group Leader") {
          setUserRole("TeamLead");
        } else if (designation === "MD") {
          setUserRole("RS");
        } else if (designation === "CEO") {
          setUserRole("RD");
        } else if (employeeName === "Manuraj Madayi") {
          setUserRole("MM");
        } else {
          setUserRole("Default");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  useEffect(() => {
    // Initial fetch of notifications
    axiosInstance
      .get(`${employeeNotifications}?is_read=false&page=1&page_size=20`)
      .then(
        (response) => {
          setUnreadNotifications(response.data.results);
        },
        (error) => {
          console.log(error);
        }
      );

    axiosInstance
      .get(`${employeeNotifications}?is_read=true&page=1&page_size=20`)
      .then(
        (response) => {
          setReadNotifications(response.data.results);
        },
        (error) => {
          console.log(error);
        }
      );
  }, []);

  useEffect(() => {
    if (isPageReloaded && sessionStorage.getItem("showToast") === "true") {
      toast.success("Log in Successful");
      sessionStorage.removeItem("showToast");
    }
  }, [isPageReloaded]);

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      debouncedHandleLogout();
    }
  }, [debouncedHandleLogout]);

  useEffect(() => {
    const fetchUpcomingHoliday = async () => {
      try {
        const response = await axiosInstance.get(holidays);
        if (response.data) {
          const today = moment();
          const nextHoliday = response.data
            .filter((holiday) => moment(holiday.date).isAfter(today))
            .sort((a, b) => moment(a.date).diff(moment(b.date)))[0];

          if (nextHoliday) {
            setUpcomingHoliday({
              title: nextHoliday.holiday_name,
              date: moment(nextHoliday.date),
            });
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchUpcomingHoliday();
  }, []);

  useEffect(() => {
    const fetchCelebrations = async () => {
      const today = moment();
      const day = today.format("D");
      const month = today.format("M");

      try {
        const [birthdaysRes, anniversariesRes] = await Promise.all([
          axiosInstance.get(
            `${celebrations}?type=birthday&day=${day}&month=${month}`
          ),
          axiosInstance.get(
            `${celebrations}?type=anniversary&day=${day}&month=${month}`
          ),
        ]);

        setTodaysBirthdays(birthdaysRes.data);
        setTodaysAnniversaries(anniversariesRes.data);
      } catch (error) {
        console.error("Error fetching celebrations:", error);
      }
    };

    fetchCelebrations();
  }, []);

  useEffect(() => {
    if (todaysBirthdays.length === 0 && todaysAnniversaries.length > 0) {
      setActiveTab("anniversaries");
    }
  }, [todaysBirthdays.length, todaysAnniversaries.length]);

  useEffect(() => {
    const fetchAttendanceData = async () => {
      setIsAttendanceLoading(true);
      try {
        const today = moment().format("YYYY-MM-DD");
        const response = await axiosInstance.get(
          `${attendance}?user=${employeeID}&date=${today}`
        );
        if (response.data.results && response.data.results.length > 0) {
          const data = response.data.results[0];
          setAttendanceData(data);
          setClockInTime(moment(data.clock_in));
          const activeTimingEntry = data.timings.find((t) => !t.clock_out);
          setCurrentStatus(activeTimingEntry?.status || null);
        }
      } catch (error) {
        console.error("Error fetching attendance:", error);
      } finally {
        setIsAttendanceLoading(false);
      }
    };

    if (employeeID) {
      fetchAttendanceData();
    }
  }, [employeeID]);

  useLayoutEffect(() => {
    if (attendanceData) {
      const isFullyclockedOut = attendanceData.timings.every(
        (t) => t.clock_out
      );
      const activeTimingEntry = attendanceData.timings.find(
        (t) => !t.clock_out
      );

      if (isFullyclockedOut) {
        setCurrentStatus(null);
      } else {
        setCurrentStatus(activeTimingEntry?.status || null);
      }
    }
  }, [attendanceData]);

  useEffect(() => {
    const fetchLeaveData = async () => {
      setIsLeaveLoading(true);
      try {
        const today = moment().format("YYYY-MM-DD");
        const response = await axiosInstance.get(
          `${addLeaves}?user=${employeeID}&date=${today}&status=Approved`
        );
        if (response.data.results && response.data.results.length > 0) {
          // Store the complete leave data including leave_details
          setLeaveData(response.data.results[0]);
        }
      } catch (error) {
        console.error("Error fetching leave data:", error);
      } finally {
        setIsLeaveLoading(false);
      }
    };

    if (employeeID) {
      fetchLeaveData();
    }
  }, [employeeID]);

  useEffect(() => {
    const checkTodayHoliday = async () => {
      try {
        const today = moment();
        const response = await axiosInstance.get(
          `${holidays}?day=${today.date()}&month=${
            today.month() + 1
          }&year=${today.year()}`
        );
        console.log(response.data);

        if (response.data && response.data.length > 0) {
          setIsTodayHoliday(true);
          console.log("Today is a holiday");
        } else {
          setIsTodayHoliday(false);
          console.log("Today is not a holiday");
        }
      } catch (error) {
        console.error("Error checking today's holiday:", error);
        setIsTodayHoliday(false);
      }
    };

    checkTodayHoliday();
  }, []); // Run once when component mounts

  useEffect(() => {
    if (!["TeamLead", "MM", "RS", "RD"].includes(userRole)) return;

    const params = new URLSearchParams({
      status: "Pending",
    });

    // Add appropriate filter based on user role
    if (["TeamLead", "MM"].includes(userRole) && department) {
      params.append("department", department);
    } else if (["RS", "RD"].includes(userRole) && employeeID) {
      params.append("supervisor", employeeID);
    }

    axiosInstance.get(`${addLeaves}?${params.toString()}`).then(
      (response) => {
        setQuickViewLeaves(response.data.results);
      },
      (error) => {
        console.error("Error fetching quick view leaves:", error);
      }
    );
  }, [userRole, department, employeeID]);

  const handleQuickApprove = async (leaveId) => {
    try {
      setIsApproving(true);
      await axiosInstance.patch(`${addLeaves}${leaveId}/`, {
        status: "Approved",
        approved_by: employeeID,
        approved_at: moment().format("YYYY-MM-DD HH:mm:ss"),
      });

      // Refresh the quick view leaves
      const updatedLeaves = quickViewLeaves.filter(
        (leave) => leave.id !== leaveId
      );
      setQuickViewLeaves(updatedLeaves);

      toast.success("Leave approved successfully");
    } catch (error) {
      console.error("Error approving leave:", error);
      toast.error("Failed to approve leave");
    } finally {
      setIsApproving(false);
    }
  };

  const canClockIn = () => {
    // Check for holiday first
    if (isTodayHoliday) {
      return false;
    }

    // Then check for leave
    if (!leaveData) return true;

    const currentTime = moment();
    const currentDate = currentTime.format("YYYY-MM-DD");
    const currentHour = currentTime.hours();
    const currentMinutes = currentTime.minutes();
    const timeInMinutes = currentHour * 60 + currentMinutes;

    const session1Start = 8 * 60 + 30; // 8:30 AM
    const session1End = 13 * 60 + 30; // 1:30 PM
    const session2Start = 13 * 60 + 30; // 1:30 PM
    const session2End = 18 * 60 + 30; // 6:30 PM

    // Find leave specification for current date
    const todayLeave = leaveData.leave_details.find(
      (detail) => moment(detail.date).format("YYYY-MM-DD") === currentDate
    );

    if (!todayLeave) return true;

    const leaveSpec = todayLeave.leave_specification;

    if (leaveSpec === "Full day") {
      return false;
    } else if (leaveSpec === "First half") {
      return timeInMinutes >= session1End && timeInMinutes < session2End;
    } else if (leaveSpec === "Second half") {
      return timeInMinutes >= session1Start && timeInMinutes < session1End;
    }

    return true;
  };

  const getLeaveMessage = () => {
    if (isTodayHoliday) {
      return "Today is a holiday. Clock-in disabled.";
    }

    if (!leaveData) return null;

    const currentDate = moment().format("YYYY-MM-DD");

    // Find leave specification for current date
    const todayLeave = leaveData.leave_details.find(
      (detail) => moment(detail.date).format("YYYY-MM-DD") === currentDate
    );

    if (!todayLeave) return null;

    const leaveSpec = todayLeave.leave_specification;

    if (leaveSpec === "Full day") {
      return "You are on leave today. Clock-in disabled.";
    } else if (leaveSpec === "First half") {
      return "You are on leave for first half (8:30 AM - 1:30 PM). You can clock in after 1:30 PM.";
    } else if (leaveSpec === "Second half") {
      return "You are on leave for second half (1:30 PM - 6:30 PM). You can clock in between 8:30 AM - 1:30 PM.";
    }
    return null;
  };

  // Fetch departments first
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        setIsDepartmentsLoading(true);
        const response = await axiosInstance.get(departmentList);
        // Filter out test department
        const filteredDepartments = response.data.filter(
          (dept) => dept.name.toLowerCase() !== "test department"
        );
        setDepartments(filteredDepartments);
        if (filteredDepartments.length > 0) {
          setSelectedDept(filteredDepartments[0]);
        }
      } catch (error) {
        console.error("Error fetching departments:", error);
      } finally {
        setIsDepartmentsLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  // Fetch active employees only after department is selected
  const fetchActiveEmployees = useCallback(async () => {
    if (!selectedDept.id) return;

    try {
      setIsLoading(true);
      const today = moment().format("YYYY-MM-DD");
      const response = await axiosInstance.get(
        `${attendance}?date=${today}&department=${selectedDept.id}`
      );

      // Filter and map employees
      const filteredEmployees = response.data.results
        .filter(
          (emp) =>
            emp.user_details.employment_details[0]?.department_detail?.name.toLowerCase() !==
            "test department"
        )
        .map((emp) => {
          const timings = emp.timings || [];
          const isToday = moment(emp.date).isSame(moment(), "day");

          let status;
          if (!isToday) {
            status = "Clocked Out";
          } else if (!timings.length) {
            status = "Not Clocked In";
          } else if (!timings[0].clock_out) {
            status = timings[0].status || "Present";
          } else {
            status = "Clocked Out";
          }

          return {
            ...emp,
            currentStatus: status,
            timings: timings.sort((a, b) =>
              moment(b.clock_in).diff(moment(a.clock_in))
            ),
          };
        });

      setActiveEmployees(filteredEmployees);
    } catch (error) {
      console.error("Error fetching active employees:", error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedDept.id]);

  useEffect(() => {
    if (selectedDept.id) {
      fetchActiveEmployees();
    }
  }, [selectedDept.id, fetchActiveEmployees]);

  // Active Employees Card Component
  const renderActiveEmployeesCard = () => {
    if (isDepartmentsLoading) {
      return (
        <div className="card h-100">
          <div className="card-body p-2">
            <div className="text-center py-3">
              <div
                className="spinner-border spinner-border-sm text-primary"
                role="status"
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="card h-100">
        <div className="card-body p-2">
          <div className="d-flex justify-content-between align-items-center mb-2">
            <small className="fw-bold text-muted">Active Employees</small>
          </div>

          {isLoading ? (
            <div className="text-center py-1">
              <div
                className="spinner-border spinner-border-sm text-primary"
                role="status"
              />
            </div>
          ) : (
            <>
              {/* Department Navigation */}
              <div className="d-flex align-items-center justify-content-between mb-2">
                <button
                  className="btn btn-link btn-sm p-0 text-muted"
                  onClick={prevDepartment}
                  disabled={currentDeptIndex === 0}
                >
                  ←
                </button>
                <span
                  className="badge bg-primary"
                  style={{ fontSize: "0.7rem" }}
                >
                  {selectedDept.name}
                </span>
                <button
                  className="btn btn-link btn-sm p-0 text-muted"
                  onClick={nextDepartment}
                  disabled={currentDeptIndex === departments.length - 1}
                >
                  →
                </button>
              </div>

              <div
                className="active-employees-list"
                style={{ maxHeight: "120px", overflowY: "auto" }}
              >
                {activeEmployees.length > 0 ? (
                  activeEmployees.map((emp) => {
                    const statusStyle = getStatusStyle(emp.currentStatus);
                    const lastTiming = emp.timings?.[0];
                    const timeToShow = lastTiming
                      ? lastTiming.clock_out
                        ? moment(lastTiming.clock_out).format("hh:mm A")
                        : moment(lastTiming.clock_in).format("hh:mm A")
                      : "";

                    return (
                      <div
                        key={emp.id}
                        className="d-flex align-items-center py-1 border-bottom"
                      >
                        <div className={`status-dot ${statusStyle.dotClass}`} />
                        <div style={{ flex: 1 }} className="px-2">
                          <div
                            className="text-truncate"
                            style={{ fontSize: "0.75rem" }}
                          >
                            {emp.user_details.employee_name}
                            <span
                              className={`ms-1 badge ${statusStyle.badgeClass}`}
                              style={{ fontSize: "0.65rem" }}
                            >
                              {emp.currentStatus}
                            </span>
                          </div>
                          <div
                            className="text-muted text-truncate"
                            style={{ fontSize: "0.65rem" }}
                          >
                            {
                              emp.user_details.employment_details[0]
                                .designation_detail.name
                            }
                          </div>
                        </div>
                        <small
                          className="text-muted"
                          style={{ fontSize: "0.65rem" }}
                        >
                          {timeToShow}
                        </small>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center py-3">
                    <div className="text-muted" style={{ fontSize: "0.8rem" }}>
                      No active employees in {selectedDept.name}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    );
  };

  const nextDepartment = useCallback(() => {
    if (currentDeptIndex < departments.length - 1) {
      const nextIndex = currentDeptIndex + 1;
      setCurrentDeptIndex(nextIndex);
      setSelectedDept(departments[nextIndex]);
    }
  }, [currentDeptIndex, departments]);

  const prevDepartment = useCallback(() => {
    if (currentDeptIndex > 0) {
      const prevIndex = currentDeptIndex - 1;
      setCurrentDeptIndex(prevIndex);
      setSelectedDept(departments[prevIndex]);
    }
  }, [currentDeptIndex, departments]);

  const handleClockIn = async () => {
    setIsLoading(true);
    setIsAttendanceLoading(true);
    try {
      await axiosInstance.post(clockIn);

      // Fetch updated attendance data
      const today = moment().format("YYYY-MM-DD");
      const response = await axiosInstance.get(
        `${attendance}?user=${employeeID}&date=${today}`
      );

      if (response.data.results && response.data.results.length > 0) {
        const data = response.data.results[0];
        setAttendanceData(data);
      }

      // Fetch updated active employees
      await fetchActiveEmployees();

      toast.success("Clocked in successfully!");
    } catch (error) {
      toast.error("Failed to clock in");
      console.error("Clock in error:", error);
    } finally {
      setIsLoading(false);
      setIsAttendanceLoading(false);
    }
  };

  const formatTime = (timeString) => {
    if (!timeString) return "00:00:00";
    // Take only HH:mm:ss part from the time string
    return timeString.substring(0, 8);
  };

  const handleClockOut = async () => {
    setShowClockOutModal(true);
  };

  const confirmClockOut = async () => {
    if (!attendanceData?.id) return;

    setIsLoading(true);
    setIsAttendanceLoading(true);
    try {
      await axiosInstance.post(clockOut(attendanceData.id));

      // Fetch updated attendance data
      const today = moment().format("YYYY-MM-DD");
      const response = await axiosInstance.get(
        `${attendance}?user=${employeeID}&date=${today}`
      );

      if (response.data.results && response.data.results.length > 0) {
        const data = response.data.results[0];
        setAttendanceData(data);
      }

      // Fetch updated active employees
      await fetchActiveEmployees();

      toast.success("Clocked out successfully!");
    } catch (error) {
      toast.error("Failed to clock out");
      console.error("Clock out error:", error);
    } finally {
      setIsLoading(false);
      setIsAttendanceLoading(false);
      setShowClockOutModal(false);
    }
  };

  const handleBreak = async () => {
    if (!attendanceData?.id) return;

    const activeTimingEntry = attendanceData.timings.find((t) => !t.clock_out);
    const newStatus =
      activeTimingEntry?.status === "Present" ? "Away" : "Present";

    setIsLoading(true);
    setIsAttendanceLoading(true);
    try {
      await axiosInstance.post(clockStatus(attendanceData.id), {
        status: newStatus,
      });
      const today = moment().format("YYYY-MM-DD");
      const response = await axiosInstance.get(
        `${attendance}?user=${employeeID}&date=${today}`
      );
      if (response.data.results && response.data.results.length > 0) {
        const data = response.data.results[0];
        setAttendanceData(data);
        const latestActiveTimingEntry = data.timings.find((t) => !t.clock_out);
        setCurrentStatus(latestActiveTimingEntry?.status || null);
      }
      toast.success(`Status changed to ${newStatus}`);
    } catch (error) {
      toast.error("Failed to update status");
      console.error("Status update error:", error);
    } finally {
      setIsLoading(false);
      setIsAttendanceLoading(false);
    }
  };

  const markAsRead = useCallback(
    (id) => {
      axiosInstance
        .patch(patchNotifications(id), { is_read: true })
        .then((response) => {
          const updatedUnreadNotifications = unreadNotifications.filter(
            (notification) => notification.id !== id
          );
          setUnreadNotifications(updatedUnreadNotifications);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    [unreadNotifications]
  );

  const getStatusStyle = (status) => {
    switch (status) {
      case "Present":
        return {
          dotClass: "bg-success",
          badgeClass: "bg-success",
        };
      case "Away":
        return {
          dotClass: "bg-warning",
          badgeClass: "bg-warning",
        };
      case "Not Clocked In":
        return {
          dotClass: "bg-info",
          badgeClass: "bg-info",
        };
      default:
        return {
          dotClass: "bg-secondary",
          badgeClass: "bg-secondary",
        };
    }
  };

  const renderPendingLeavesCard = () => {
    if (!["TeamLead", "MM", "RS", "RD"].includes(userRole)) {
      return null;
    }

    return (
      <div className="card mb-4">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center mb-3">
            <div>
              <h4 className="mb-0">Pending Leave Approvals</h4>
              <small className="text-muted">
                Total: {quickViewLeaves.length}
              </small>
            </div>
            <Link to="/approve-leaves" className="btn btn-primary btn-sm">
              View All
            </Link>
          </div>

          <div style={{ maxHeight: "250px", overflowY: "auto" }}>
            {quickViewLeaves.length > 0 ? (
              quickViewLeaves.map((leave) => (
                <div
                  key={leave.id}
                  className="d-flex justify-content-between align-items-center p-2 border-bottom"
                >
                  <div className="d-flex flex-column">
                    <span className="fw-bold">
                      {leave.user_details?.employee_name}
                    </span>
                    <small className="text-muted">
                      {moment(leave.leave_details?.[0]?.date).format("DD MMM")}
                      {leave.leave_details?.length > 1 &&
                        ` - ${moment(
                          leave.leave_details[leave.leave_details.length - 1]
                            .date
                        ).format("DD MMM")}`}
                      <span className="ms-2">
                        ({leave.total_days}{" "}
                        {leave.total_days > 1 ? "days" : "day"})
                      </span>
                      {leave.leave_details?.[0]?.leave_specification && (
                        <span className="ms-2">
                          • {leave.leave_details[0].leave_specification}
                        </span>
                      )}
                    </small>
                  </div>
                  {employeeID !== leave.user ? (
                    <button
                      className="btn btn-success btn-sm"
                      onClick={() => handleQuickApprove(leave.id)}
                      disabled={isApproving}
                    >
                      {isApproving ? (
                        <span className="spinner-border spinner-border-sm" />
                      ) : (
                        "Approve"
                      )}
                    </button>
                  ) : (
                    <></>
                  )}
                </div>
              ))
            ) : (
              <div className="text-center py-3">
                <p className="text-muted mb-0">No pending leaves</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  const fetchNotifications = async () => {
    try {
      // Fetch unread notifications with pagination
      const unreadResponse = await axiosInstance.get(
        `${employeeNotifications}?is_read=false&page=1&page_size=20`
      );
      setUnreadNotifications(unreadResponse.data.results);

      // Fetch read notifications with pagination
      const readResponse = await axiosInstance.get(
        `${employeeNotifications}?is_read=true&page=1&page_size=20`
      );
      setReadNotifications(readResponse.data.results);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      toast.error("Failed to fetch notifications");
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const response = await axiosInstance.post(readNotification);
      await fetchNotifications();
      toast.success(response.data.message);
    } catch (error) {
      console.error("Error marking notifications as read:", error);
      toast.error("Failed to mark notifications as read");
    }
  };

  return (
    <div className="page-wrapper">
      <div className=" content container-fluid">
        {/* Alert Message */}
        <div className="row">
          <div className="col-md-12">
            <div className="employee-alert-box">
              <div className="alert alert-outline-success alert-dismissible fade show">
                <div className="employee-alert-request">
                  <i className="far fa-circle-question" />
                  Portal is on an updation and all the features will be added
                  later!!!
                </div>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                >
                  <i className="fas fa-xmark" />
                </button>
              </div>
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="row">
          {/* Left Column - Welcome & Holiday Cards */}
          <div className="col-xxl-4 col-lg-4">
            {/* Welcome Card */}
            <div className="card employee-welcome-card mb-3">
              <div className="card-body">
                <div className="welcome-info">
                  <div className="welcome-content">
                    <h4>Welcome,{employeeDetail?.employee_name || "NA"}</h4>
                    <p>
                      You have <span>0 meetings</span> today,
                    </p>
                  </div>
                  <div className="welcome-img">
                    <img src={avatar19} className="img-fluid" alt="User" />
                  </div>
                </div>
              </div>
            </div>

            {/* Clock In/Out Card */}
            <div className="card employee-attendance-card mb-3">
              <div className="card-body">
                <div className="attendance-info">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h4 className="card-title mb-0">Attendance</h4>
                    <span className="text-muted">
                      {moment().format("DD MMM YYYY")}
                    </span>
                  </div>

                  {isAttendanceLoading || isLeaveLoading ? (
                    <div className="attendance-loader">
                      <div className="d-flex flex-column align-items-center py-4">
                        <div
                          className="spinner-border text-primary"
                          role="status"
                        >
                          <span className="visually-hidden">Loading...</span>
                        </div>
                        <span className="mt-2 text-muted">
                          Loading attendance...
                        </span>
                      </div>
                    </div>
                  ) : (
                    <>
                      {getLeaveMessage() && (
                        <div className="alert alert-info mb-3">
                          {getLeaveMessage()}
                        </div>
                      )}

                      {attendanceData ? (
                        <div className="time-details mb-3">
                          <div className="d-flex justify-content-between align-items-center">
                            <div>
                              <small className="text-muted d-block">
                                Clock In
                              </small>
                              <span>
                                {moment(attendanceData.clock_in).format(
                                  "hh:mm A"
                                )}
                              </span>
                            </div>

                            {/* Break Time if status is away*/}
                            {currentStatus === "Away" && (
                              <div className="text-center mt-2">
                                <small className="text-muted d-block">
                                  Break Time
                                </small>
                                <span>
                                  {moment(
                                    attendanceData.timings[0].clock_in
                                  ).format("hh:mm A")}
                                </span>
                              </div>
                            )}

                            {attendanceData.timings[0]?.clock_out && (
                              <div className="text-end">
                                <small className="text-muted d-block">
                                  Clock Out
                                </small>
                                <span>
                                  {moment(
                                    attendanceData.timings[0].clock_out
                                  ).format("hh:mm A")}
                                </span>
                              </div>
                            )}
                          </div>
                          {attendanceData.total_working_hours !==
                            "00:00:00" && (
                            <div className="text-center mt-2">
                              <small className="text-muted d-block">
                                Total Hours
                              </small>
                              <span className="fw-bold">
                                {formatTime(attendanceData.total_working_hours)}
                              </span>
                            </div>
                          )}
                        </div>
                      ) : null}

                      <div className="d-flex gap-2">
                        {!attendanceData ? (
                          <button
                            className="btn btn-primary flex-grow-1"
                            onClick={handleClockIn}
                            disabled={isLoading || !canClockIn()}
                          >
                            Clock In
                          </button>
                        ) : (
                          <>
                            {attendanceData.timings.every((t) => t.clock_out) ||
                            !currentStatus ? (
                              // Show disabled buttons when fully clocked out
                              <>
                                <button
                                  className="btn btn-warning flex-grow-1"
                                  disabled={true}
                                >
                                  Take Break
                                </button>
                                <button
                                  className="btn btn-danger flex-grow-1"
                                  disabled={true}
                                >
                                  Clock Out
                                </button>
                              </>
                            ) : (
                              // Show active buttons based on current status
                              <>
                                {attendanceData.timings.find(
                                  (t) => !t.clock_out
                                )?.status === "Away" ? (
                                  <button
                                    className="btn btn-success flex-grow-1"
                                    onClick={handleBreak}
                                    disabled={isLoading}
                                  >
                                    Back to Work
                                  </button>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-warning flex-grow-1"
                                      onClick={handleBreak}
                                      disabled={isLoading}
                                    >
                                      Take Break
                                    </button>
                                    <button
                                      className="btn btn-danger flex-grow-1"
                                      onClick={handleClockOut}
                                      disabled={isLoading}
                                    >
                                      Clock Out
                                    </button>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>

                      {currentStatus &&
                        !attendanceData?.timings.some((t) => t.clock_out) && (
                          <div className="mt-2 text-center">
                            <span
                              className={`badge ${
                                currentStatus === "Present"
                                  ? "bg-success"
                                  : "bg-warning"
                              }`}
                            >
                              {currentStatus}
                            </span>
                          </div>
                        )}
                    </>
                  )}
                </div>
              </div>
            </div>

            {/* Holiday Card */}
            <div className="card info-card">
              <div className="card-body ">
                <h4>Upcoming Holidays</h4>
                <div className="holiday-details">
                  <div className="holiday-calendar">
                    <div className="holiday-calendar-icon">
                      <svg
                        xmlns="https://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#fff"
                          d="M19 19H5V8h14m-3-7v2H8V1H6v2H5c-1.11 0-2 .89-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2h-1V1m-1 11h-5v5h5z"
                        />
                      </svg>
                    </div>
                    <div className="holiday-calendar-content">
                      {upcomingHoliday ? (
                        <>
                          <h6>{upcomingHoliday.title}</h6>
                          <p>
                            {upcomingHoliday.date.format("ddd DD MMM YYYY")}
                          </p>
                        </>
                      ) : (
                        <p>No upcoming holidays</p>
                      )}
                    </div>
                  </div>
                  <div className="holiday-btn">
                    <Link to="/employee-holidays" className="btn">
                      View All <ArrowRightCircle size={15} className="ms-1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Middle Column - Celebrations Card */}
          <div className="col-xxl-4 col-lg-4">
            {renderPendingLeavesCard()}

            <div
              className="card employee-month-card"
              style={{
                backgroundColor: "#fff5f7",
                border: "1px solid #ffccd5",
              }}
            >
              <div className="card-body">
                <h4 className="mb-4">Today's Celebrations</h4>

                <div className="notification-tab">
                  <ul className="nav nav-tabs flex-nowrap overflow-auto small-screen-tabs">
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "birthdays" ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        href="#birthdays_tab"
                        onClick={() => setActiveTab("birthdays")}
                      >
                        <Gift className="me-1" size={14} />
                        <span className="tab-text">Birthdays</span>
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className={`nav-link ${
                          activeTab === "anniversaries" ? "active" : ""
                        }`}
                        data-bs-toggle="tab"
                        href="#anniversaries_tab"
                        onClick={() => setActiveTab("anniversaries")}
                        style={{
                          position: "relative",
                          // Add a subtle glow effect if there are both birthdays and anniversaries
                          ...(todaysBirthdays.length > 0 &&
                            todaysAnniversaries.length > 0 && {
                              backgroundColor: "#fff3cd",
                              color: "#ffc107",
                              fontWeight: "500",
                            }),
                        }}
                      >
                        <Award className="me-1" size={14} />
                        <span className="tab-text">Anniversaries</span>
                        {/* Add a small indicator dot if there are both types of celebrations */}
                        {todaysBirthdays.length > 0 &&
                          todaysAnniversaries.length > 0 && (
                            <span
                              style={{
                                position: "absolute",
                                top: -5,
                                right: -5,
                                width: 8,
                                height: 8,
                                borderRadius: "50%",
                                backgroundColor: "#ffc107",
                              }}
                            />
                          )}
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content mt-3">
                    {/* Birthdays Tab */}
                    <div
                      className={`tab-pane ${
                        activeTab === "birthdays" ? "active" : ""
                      }`}
                      id="birthdays_tab"
                    >
                      <div
                        className="celebration-list"
                        style={{
                          maxHeight: "200px",
                          overflowY: "auto",
                          paddingRight: "10px",
                          // Add responsive styles
                          width: "100%",
                          minWidth: "250px", // Minimum width for readability
                        }}
                      >
                        {todaysBirthdays.length > 0 ? (
                          todaysBirthdays.map((person, index) => (
                            <div
                              key={index}
                              className="employee-month-details mb-3"
                            >
                              <div className="d-flex align-items-center">
                                <div
                                  className="avatar-circle"
                                  style={{
                                    width: "45px",
                                    height: "45px",
                                    backgroundColor: "#ffe0e6",
                                    color: "#ff4d6d",
                                    borderRadius: "50%",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "18px",
                                    fontWeight: "600",
                                    marginRight: "15px",
                                  }}
                                >
                                  {person.employee_name.charAt(0)}
                                </div>
                                <div>
                                  <h6 className="mb-1">
                                    {person.employee_name}
                                  </h6>
                                  <p
                                    className="mb-0"
                                    style={{
                                      fontSize: "12px",
                                      color: "#666",
                                    }}
                                  >
                                    Wish them a happy birthday! 🎉
                                  </p>
                                </div>
                              </div>
                            </div>
                          ))
                        ) : (
                          <p className="text-muted mb-0">No birthdays today</p>
                        )}
                      </div>
                    </div>

                    {/* Anniversaries Tab */}
                    <div
                      className={`tab-pane ${
                        activeTab === "anniversaries" ? "active" : ""
                      }`}
                      id="anniversaries_tab"
                    >
                      <div
                        className="celebration-list"
                        style={{
                          maxHeight:
                            todaysAnniversaries.length > 3 ? "200px" : "auto",
                          overflowY:
                            todaysAnniversaries.length > 3 ? "auto" : "visible",
                          paddingRight:
                            todaysAnniversaries.length > 3 ? "10px" : "0",
                        }}
                      >
                        {todaysAnniversaries.length > 0 ? (
                          todaysAnniversaries.map((person, index) => {
                            // Calculate years of service
                            const yearsOfService = moment().diff(
                              moment(person.effective_date),
                              "years"
                            );

                            return (
                              <div
                                key={index}
                                className="employee-month-details mb-3"
                              >
                                <div className="d-flex align-items-center">
                                  <div
                                    className="avatar-circle"
                                    style={{
                                      width: "45px",
                                      height: "45px",
                                      backgroundColor: "#fff3cd",
                                      color: "#ffc107",
                                      borderRadius: "50%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      fontSize: "18px",
                                      fontWeight: "600",
                                      marginRight: "15px",
                                    }}
                                  >
                                    {person.employee_details.employee_name.charAt(
                                      0
                                    )}
                                  </div>
                                  <div>
                                    <h6 className="mb-1">
                                      {person.employee_details.employee_name}
                                    </h6>
                                    <p
                                      className="mb-0"
                                      style={{
                                        fontSize: "12px",
                                        color: "#666",
                                      }}
                                    >
                                      {yearsOfService} years at company
                                    </p>
                                    <small className="text-muted">
                                      {
                                        person.employee_details
                                          .employment_details[0]
                                          .designation_detail.name
                                      }{" "}
                                      -{" "}
                                      {
                                        person.employee_details
                                          .employment_details[0]
                                          .department_detail.name
                                      }
                                    </small>
                                  </div>
                                </div>
                              </div>
                            );
                          })
                        ) : (
                          <p className="text-muted mb-0">
                            No work anniversaries today
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row mb-4">
              <div className="col-md-12">{renderActiveEmployeesCard()}</div>
            </div>
          </div>

          {/* Add Active Employees Card here */}

          {/* Right Column - Notifications */}
          <div className="col-xxl-4 col-lg-4">
            <div className="card">
              <div className="card-body">
                <div className="statistic-header">
                  <h4>Important</h4>
                  <div className="important-notification d-flex flex-column align-items-end">
                    <Link to="/activities" className="mb-2">
                      <span className="me-1">View All</span>
                      <ArrowRightCircle size={15} />
                    </Link>
                    {unreadNotifications.length > 0 && (
                      <div
                        onClick={handleMarkAllAsRead}
                        className="cursor-pointer"
                        style={{
                          color: "#0d6efd", // Link color
                          fontSize: "14px", // Match View All size
                          cursor: "pointer",
                        }}
                      >
                        <span className="me-1">Mark all as read</span>
                        <CheckCircle size={15} />
                      </div>
                    )}
                  </div>
                </div>
                <div className="notification-tab">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-bs-toggle="tab"
                        href="#unread_notification_tab"
                      >
                        <i className="la la-bell" /> Unread
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-bs-toggle="tab"
                        href="#read_notification_tab"
                      >
                        <i className="la la-check-circle" /> Read
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className="tab-pane active"
                      id="unread_notification_tab"
                    >
                      <div
                        className="employee-noti-content"
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                      >
                        <ul className="employee-notification-list">
                          {unreadNotifications.length > 0 ? (
                            unreadNotifications.map((notification) => (
                              <NotificationItem
                                key={notification.id}
                                notification={notification}
                                markAsRead={markAsRead}
                                isRead={false}
                              />
                            ))
                          ) : (
                            <li className="employee-notification-grid">
                              <div className="employee-notification-content">
                                <h6>No unread notifications!</h6>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="tab-pane" id="read_notification_tab">
                      <div
                        className="employee-noti-content"
                        style={{ maxHeight: "400px", overflowY: "auto" }}
                      >
                        <ul className="employee-notification-list">
                          {readNotifications.length > 0 ? (
                            readNotifications.map((notification) => (
                              <NotificationItem
                                key={notification.id}
                                notification={notification}
                                markAsRead={() => {}}
                                isRead={true}
                              />
                            ))
                          ) : (
                            <li className="employee-notification-grid">
                              <div className="employee-notification-content">
                                <h6>No read notifications!</h6>
                              </div>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Clock Out Confirmation Modal */}
      <Modal
        title="Confirm Clock Out"
        open={showClockOutModal}
        onOk={confirmClockOut}
        onCancel={() => setShowClockOutModal(false)}
        confirmLoading={isLoading}
        okText="Yes, Clock Out"
        cancelText="Cancel"
        okButtonProps={{
          className: "btn btn-primary mb-1 btn-sm",
        }}
        cancelButtonProps={{
          className: "btn btn-light mb-1 btn-sm",
        }}
      >
        <div className="text-center mb-3">
          <i
            className="fas fa-sign-out-alt text-warning"
            style={{ fontSize: "48px" }}
          />
        </div>
        <p className="text-center mb-0">Are you sure you want to clock out?</p>
      </Modal>
    </div>
  );
};

const NotificationItem = ({ notification, markAsRead, isRead = false }) => {
  const navigate = useNavigate();

  const userInfo = useSelector((state) => state.account.userInfo);

  const isDeputyTeamLeaderFromGACL =
    userInfo?.employeeData?.employment_details?.[0]?.designation_detail
      ?.name === "Deputy Team Leader" &&
    userInfo?.employeeData?.employment_details?.[0]?.department_detail?.name ===
      "GACL";

  // for kartik
  const isOperationsKarthik =
    userInfo?.employeeData?.employee_name === "Kartik Purushottam Sonawane";

  const getLinkTo = (type) => {
    switch (type) {
      case "course_added":
        return "/training-reports";
      case "course_rs":
        return "/training-approval";
      case "course_approval":
        return "/training-reports";
      case "course_publish":
        return "/employee-courses";
      case "certificate_rejected":
        return "/employee-courses";
      case "policy_rs":
        return "/policy-approval";
      case "feedback_added":
        return "/add-policies";
      case "policy_added":
        return "/add-policies";
      case "policy_approval":
        return "/add-policies";
      case "policy_emp":
        return "/employee-policy";
      case "appraisal":
        return "/appraisal-table";
      case "leaves_hr":
        return "/hr-leaves";
      case "leaves_sup":
        return "/approve-leaves";
      case "leaves_emp":
        return "/your-leaves";
      case "leaves_noti":
        return "/employee-leaves";
      case "technical_task":
        return "/technical-dashboard";
      case "holiday_added":
        return "/employee-holidays";
      case "gacl_task":
        return "/gacl-task-assigned";
      case "task_aml":
        return "/gacl-add-tasks";
      case "task_esr":
        return "/gacl-add-tasks";
      case "qpa_nomination_colleague":
        return "/nominated-qpa";
      case "qpa_form":
        return "/your-qpa";
      case "qpa_nomination_boss":
        return "/nominated-qpa";
      case "all_qpa_done":
        return "/end-document-qpa";
      case "employee_separation":
        return "/view-resignation-letter-hr";
      case "tl_review_req_res":
        return "/view-resignation-letter-tl";
      case "tl_reviewed_res":
        return "/view-resignation-letter-hr";
      case "emp_tl_reviewed_res":
        return "/resignation-letter";
      case "fill_handover_emp":
        return "/resignation-letter";
      case "handover_res":
        return "/view-resignation-letter-tl";
      case "task_assigned_res":
        return "/takeover-assigned-tasks";
      case "handover_approved_res":
        return "/view-resignation-letter-hr";
      case "handover_review_emp":
        return "/view-assigned-handovers";
      case "handover_checklist_res":
        return "/view-resignation-letter-tl";
      case "handover_checklist_person":
        return "/view-assigned-handovers";
      case "handover_checklist_emp":
        return "/resignation-letter";
      case "handover_checklist_hr":
        return "/view-resignation-letter-hr";
      case "experience_letter_res":
        return "/resignation-letter";
      case "exp_letter_hr":
        return "/view-resignation-letter-hr";
      case "tl_separation":
        return "/view-resignation-letter-hr-for-tl";
      case "rs_review_res":
        return "/view-resignation-letter-rs";
      case "emp_review_req_res":
        return "/resignation-letter";
      case "tl_res_reject":
        return "/resignation-letter";
      case "tl_hr_res_reject":
        return " /view-resignation-letter-hr-for-tl";
      case "rs_reviewed_res":
        return "/view-resignation-letter-hr-for-tl";
      case "hr_reviewed_res":
        return "/view-resignation-letter-hr-for-tl";
      case "fill_handover_tl":
        return "/resignation-letter";
      case "assign_task_tl":
        return "/view-resignation-letter-hr-for-tl";
      case "task_assigned_res_tl":
        return "/takeover-assigned-tasks";
      case "handover_review_tl":
        return "/view-assigned-handovers";
      case "handover_checklist_tl":
        return "/view-resignation-letter-hr-for-tl";
      case "handover_check_emp":
        return "/resignation-letter";
      case "handover_takingover":
        return "/view-assigned-handovers";
      case "hr_check_review":
        return "/view-resignation-letter-hr-for-tl";
      case "upload_exp":
        return "/view-resignation-letter-hr-for-tl";
      case " experience_letter_res":
        return " /resignation-letter";
      case "vendor_request_gacl":
        return isDeputyTeamLeaderFromGACL ? "/vendor-requests" : "/activities";
      case "vendor_approval_status_tl":
        return "/my-vendor";
      case "vendor_request_rs":
        return "/vendor-request";
      case "vendor_approval_status_gacl":
        return isDeputyTeamLeaderFromGACL ? "/all-vendors" : "/activities";
      case "vendor_request_operations":
        return isOperationsKarthik
          ? "/vendor-detail-extraction-task"
          : "/activities";
      case "weekly_review":
        return "/add-weekly-review";
      case "vendor_request_operations_mto_status":
        return isOperationsKarthik
          ? "/vendor-detail-extraction-task"
          : "/activities";
      case "vendor_request_gacl_tc_status":
        return isDeputyTeamLeaderFromGACL
          ? "/documents-history"
          : "/activities";

      case "vendor_request_operations_tc_status":
        return isOperationsKarthik
          ? "/vendor-detail-extraction-task"
          : "/activities";
      default:
        return "/activities";
    }
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (!isRead) {
      markAsRead(notification.id);
    }

    if (notification.type === "course_rs" && notification.iid) {
      navigate(`/training-approval?iid=${notification.iid}`);
    } else {
      navigate(getLinkTo(notification.type));
    }
  };

  return (
    <li
      className="employee-notification-grid"
      style={{
        backgroundColor: isRead ? "#e0f7fa" : "#fff3e0",
        padding: "10px",
        margin: "5px 0",
        borderRadius: "5px",
        cursor: "pointer",
      }}
      onClick={handleClick}
    >
      <div className="employee-notification-icon">
        <span className="badge-soft-danger rounded-circle">HR</span>
      </div>
      <div className="employee-notification-content">
        <h6
          style={{
            color: isRead ? "#6c757d" : "#FF902F",
          }}
        >
          {notification.title}
        </h6>
        <div
          style={{
            fontSize: 11,
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            color: "black",
          }}
        >
          {notification.description}
        </div>
        <ul className="nav">
          <li>{new Date(notification.time).toLocaleString()}</li>
        </ul>
      </div>
    </li>
  );
};

export default EmployeeDashboard;
