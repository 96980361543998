import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Tag,
  Modal,
  Input,
  Checkbox,
  Form,
  Row,
  Col,
  Pagination,
} from "antd";
import { Typography } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  tasksassignedModel,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import { FaExternalLinkAlt } from "react-icons/fa";

const { Title } = Typography;

const TakeoverAssignedTasks = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [form] = Form.useForm();
  const [confirmationChecked, setConfirmationChecked] = useState(false);

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      setEmployeeId(response.data.employment_details[0].id);
      fetchTasks(response.data.employment_details[0].id, 1);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Error fetching employee details");
    }
  };

  const fetchTasks = async (empId, page) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${tasksassignedModel}?assignee=${empId}&page=${page}&page_size=${pagination.pageSize}`
      );
      setTasks(response.data.results);
      setPagination({
        ...pagination,
        current: page,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Error fetching tasks");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (page) => {
    fetchTasks(employeeId, page);
  };

  const renderDocumentLinks = (documents) => {
    if (!documents || !Array.isArray(documents) || documents.length === 0) {
      return "No documents";
    }

    return (
      <div className="document-links">
        {documents.map((doc, index) => (
          <div key={index} className="mb-3 document-item">
            <div className="document-name mb-1">
              <strong>Name:</strong> {doc.name || `Document ${index + 1}`}
            </div>
            <div className="document-url d-flex align-items-center">
              <strong className="me-2">Link:</strong>
              <a
                href={doc.url}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center"
              >
                <FaExternalLinkAlt className="me-2" />
                View Document
              </a>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const showModal = (record) => {
    setSelectedTask(record);
    setIsModalVisible(true);
    form.setFieldsValue({
      taskName: record.name,
      employeeName: record.separation_details?.user_details?.employee_name,
      employeeId: record.separation_details?.user_details?.employee_id,
      department:
        record.separation_details?.user_details?.employment_details?.[0]
          ?.department_detail?.name,
      designation:
        record.separation_details?.user_details?.employment_details?.[0]
          ?.designation_detail?.name,
      trainingRequired: record.is_training ? "Yes" : "No",
      trainingLink: record.training_link || "N/A",
      notReason: record.not_reason || "N/A",
      courseList: Array.isArray(record.course_list)
        ? record.course_list.join(", ")
        : "N/A",
      platform: record.platform
        ? record.platform.map((p) => `${p.name} (${p.username})`).join(", ")
        : "N/A",
      assignedDate: record.assigned_at
        ? moment(record.assigned_at).format("DD-MM-YYYY")
        : "N/A",
      documents: record.document_links || [],
    });
  };

  const handleClose = () => {
    setIsModalVisible(false);
    setSelectedTask(null);
    form.resetFields();
  };

  const handleConfirmationSubmit = async (taskId) => {
    try {
      await axiosInstance.patch(`${tasksassignedModel}${taskId}/`, {
        is_reviewed: true,
      });
      toast.success("Task marked as reviewed successfully");
      setIsModalVisible(false);
      fetchTasks(employeeId, pagination.current); // Refresh the tasks
    } catch (error) {
      console.error("Error updating task review status:", error);
      toast.error("Failed to mark task as reviewed");
    }
  };

  const renderModalContent = () => (
    <Form form={form} layout="vertical">
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Employee Name" name="employeeName">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Employee ID" name="employeeId">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col span={12}>
          <Form.Item label="Department" name="department">
            <Input disabled />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item label="Designation" name="designation">
            <Input disabled />
          </Form.Item>
        </Col>
      </Row>

      <Form.Item label="Task Name" name="taskName">
        <Input disabled />
      </Form.Item>

      <Form.Item label={<span className="font-weight-bold">Documents</span>}>
        <div className="p-3 border rounded">
          {renderDocumentLinks(selectedTask?.document_links)}
        </div>
      </Form.Item>

      <Form.Item label="Training Required">
        <Input disabled value={form.getFieldValue("trainingRequired")} />
      </Form.Item>

      {form.getFieldValue("trainingRequired") === "Yes" && (
        <>
          <Form.Item label="Training Record Link">
            {selectedTask?.training_link ? (
              <a
                href={selectedTask.training_link}
                target="_blank"
                rel="noopener noreferrer"
                className="d-flex align-items-center"
              >
                <FaExternalLinkAlt className="me-2" />
                View Training Material
              </a>
            ) : (
              "No training link provided"
            )}
          </Form.Item>

          <Form.Item label="Course List">
            <Input disabled value={form.getFieldValue("courseList")} />
          </Form.Item>
        </>
      )}

      {form.getFieldValue("trainingRequired") === "No" && (
        <Form.Item label="Reason">
          <Input disabled value={form.getFieldValue("notReason")} />
        </Form.Item>
      )}

      <Form.Item label="Platform" name="platform">
        <Input disabled />
      </Form.Item>

      <Form.Item label="Assigned Date" name="assignedDate">
        <Input disabled />
      </Form.Item>

      <Form.Item name="confirmation" valuePropName="checked">
        <Checkbox
          disabled={selectedTask?.is_reviewed}
          onChange={(e) => setConfirmationChecked(e.target.checked)}
        >
          I confirm that I have reviewed and understood all the task details
          {selectedTask?.is_reviewed && " (Already reviewed)"}
        </Checkbox>
      </Form.Item>
    </Form>
  );

  const columns = [
    {
      title: "Task Name",
      dataIndex: "name",
      key: "name",
      width: "30%",
    },
    {
      title: "Employee",
      dataIndex: ["separation_details", "user_details", "employee_name"],
      key: "employee_name",
      width: "10%",
    },
    {
      title: "Department",
      dataIndex: [
        "separation_details",
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
      width: "10%",
    },
    {
      title: "Assigned Date",
      dataIndex: "assigned_at",
      key: "assigned_at",
      width: "15%",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "N/A"),
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      render: (_, record) => (
        <Tag color={record.is_reviewed ? "green" : "orange"}>
          {record.is_reviewed ? "Reviewed" : "Pending"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      width: "20%",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => showModal(record)}
          icon={<FaExternalLinkAlt />}
        >
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Takeover Assigned Tasks"
          title="Dashboard"
          subtitle="Takeover Tasks"
        />

        <div className="card">
          <div className="card-body">
            <Table
              columns={columns}
              dataSource={tasks}
              rowKey="id"
              loading={loading}
              pagination={{
                ...pagination,
                onChange: handlePageChange,
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
              }}
              className="table-striped"
              scroll={{ x: true }}
            />
          </div>
        </div>

        <Modal
          title="Task Details"
          open={isModalVisible}
          onCancel={handleClose}
          footer={[
            <Button key="close" onClick={handleClose}>
              Close
            </Button>,
            <Button
              key="submit"
              type="primary"
              disabled={!confirmationChecked || selectedTask?.is_reviewed}
              onClick={() => handleConfirmationSubmit(selectedTask?.id)}
            >
              Submit
            </Button>,
          ]}
          width={1200}
          zIndex={1200}
        >
          {renderModalContent()}
        </Modal>
      </div>

      <style jsx>{`
        .table-striped tbody tr:nth-of-type(odd) {
          background-color: rgba(0, 0, 0, 0.02);
        }

        .card {
          box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
          border-radius: 8px;
          background-color: white;
        }

        .card-body {
          padding: 1.25rem;
        }

        .page-wrapper {
          background-color: #f8f9fa;
          min-height: 100vh;
        }

        .container-fluid {
          padding-right: 15px;
          padding-left: 15px;
          margin-right: auto;
          margin-left: auto;
        }

        .table-responsive {
          overflow-x: auto;
        }

        .table {
          width: 100%;
          margin-bottom: 1rem;
          background-color: transparent;
        }

        .table-bordered {
          border: 1px solid #dee2e6;
        }

        .table-bordered th,
        .table-bordered td {
          border: 1px solid #dee2e6;
          padding: 0.75rem;
          vertical-align: top;
        }

        @media (max-width: 768px) {
          .table-responsive {
            overflow-x: auto;
          }
        }

        .document-item {
          padding: 10px;
          border-bottom: 1px solid #f0f0f0;
        }

        .document-item:last-child {
          border-bottom: none;
        }

        .document-name {
          color: #262626;
        }

        .document-url a {
          color: #1890ff;
          font-size: 14px;
        }

        .document-url a:hover {
          color: #40a9ff;
        }
      `}</style>
    </div>
  );
};

export default TakeoverAssignedTasks;
