import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Tag,
  Tooltip,
  Spin,
  Input,
} from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import { FaListAlt, FaExternalLinkAlt } from "react-icons/fa";
import Header from "../../../layout/Header";
import Sidebar from "../../../layout/Sidebar";

const YourTask = () => {
  const [tasks, setTasks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userId, setUserId] = useState(null);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch user details
  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setUserId(response.data.employment_details[0].id);
      } catch (error) {
        console.error("Error fetching user details:", error);
        toast.error("Failed to fetch user details");
      }
    };
    fetchUserDetails();
  }, []);

  // Fetch tasks assigned to the user
  const fetchTasks = useCallback(async () => {
    if (!userId) return;

    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${addLeaves}?assignee=${userId}`
      );
      const allLeaves = response.data.results;

      // Extract tasks assigned to the current user
      const userTasks = allLeaves.reduce((acc, leave) => {
        const assignedTasks =
          leave.tasks?.filter((task) => task.assignee === userId) || [];
        return [
          ...acc,
          ...assignedTasks.map((task) => ({
            ...task,
            leave_id: leave.id,
            employee_name: leave.user_details?.employee_name,
            leave_dates: leave.dates,
            leave_type: leave.leave_type,
            leave_status: leave.status,
          })),
        ];
      }, []);

      setTasks(userTasks);
    } catch (error) {
      console.error("Error fetching tasks:", error);
      toast.error("Failed to fetch tasks");
    } finally {
      setLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchTasks();
    }
  }, [userId, fetchTasks]);

  const handleViewTask = (task) => {
    setSelectedTask(task);
    setIsModalOpen(true);
  };

  const columns = [
    {
      title: "Employee",
      dataIndex: "employee_name",
      key: "employee_name",
    },
    {
      title: "Task Description",
      dataIndex: "task_description",
      key: "task_description",
      render: (text) => <div style={{ whiteSpace: "pre-wrap" }}>{text}</div>,
    },
    {
      title: "Leave Status",
      dataIndex: "leave_status",
      key: "leave_status",
      render: (status) => (
        <Tag
          color={
            status === "Approved"
              ? "green"
              : status === "Pending"
              ? "orange"
              : status === "Rejected"
              ? "red"
              : "default"
          }
        >
          {status}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button type="primary" onClick={() => handleViewTask(record)}>
          View Details
        </Button>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="page-header">
          <div className="row align-items-center">
            <div className="col">
              <h3 className="page-title">Your Tasks</h3>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">Employee</li>
                <li className="breadcrumb-item active">Your Tasks</li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-12">
            <div className="card card-table">
              <div className="card-body">
                <Spin spinning={loading}>
                  <Table
                    columns={columns}
                    dataSource={tasks}
                    rowKey={(record) => `${record.leave_id}-${record.id}`}
                  />
                </Spin>

                <Modal
                  title="Task Details"
                  open={isModalOpen}
                  onCancel={() => setIsModalOpen(false)}
                  footer={[
                    <Button key="close" onClick={() => setIsModalOpen(false)}>
                      Close
                    </Button>,
                  ]}
                  width={800}
                >
                  {selectedTask && (
                    <Form layout="vertical">
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item label="Employee Name">
                            <Input
                              value={selectedTask.employee_name}
                              disabled
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Row gutter={16}>
                        <Col span={24}>
                          <Form.Item label="Task Description">
                            <Input.TextArea
                              value={selectedTask.task_description}
                              disabled
                              autoSize={false}
                              style={{
                                height: "100px", // Fixed height
                                resize: "none", // Prevents manual resizing
                                overflowY: "auto", // Enables vertical scrolling
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      {selectedTask.document_link && (
                        <Row gutter={16}>
                          <Col span={24}>
                            <Form.Item label="Document">
                              <a
                                href={selectedTask.document_link}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <FaExternalLinkAlt /> View Document
                              </a>
                            </Form.Item>
                          </Col>
                        </Row>
                      )}

                      {selectedTask.credentials && (
                        <Row gutter={16}>
                          <Col span={12}>
                            <Form.Item label="User ID">
                              <Input
                                value={selectedTask.credentials.user_id}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                          <Col span={12}>
                            <Form.Item label="Password">
                              <Input
                                value={selectedTask.credentials.password}
                                disabled
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      )}
                    </Form>
                  )}
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourTask;
