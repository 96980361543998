import { useState } from "react";
import { Form, Button, Container, Table } from "react-bootstrap";
import "../VendorManagement.css";
import { useParams } from "react-router-dom";
import { mtoExtractionForm } from "../../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import { toast } from "react-toastify";

const ExpenseDataExtractionForm = () => {
  const { token, assignment_id } = useParams();
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [apiErrors, setApiErrors] = useState([]);

  const FORM_FIELDS = [
    {
      id: "entry_number",
      label: "Entry Number",
      type: "text",
      instruction: "Serial Number",
      // required: true,
      validate: (value) => {
        if (!value?.trim()) return "Vendor name is required";
        return "";
      },
    },
    {
      id: "expense_date",
      label: "Expense Date",
      type: "email",
      instruction:
        "Fill the Date as mentioned in the Invoice in DD/MM/YYYY format",
      // required: true,
      validate: (value) => {
        if (!value?.trim()) return "Email is required";
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(value)
          ? ""
          : "Please enter a valid email address";
      },
    },
    {
      id: "vendor",
      label: "Vendor",
      type: "select",
      instruction:
        "Please select the 'Name of the Vendor' as mentioned on the Invoice from the drop down list",
      // required: true,
      options: [
        { value: "", label: "Select" },
        { value: "Dubai", label: "Dubai" },
      ],
    },
    {
      id: "expense_account",
      label: "Expense Account",
      type: "text",
      instruction: "Select the 'Expense Account' from the drop down list",

      // required: true,
      validate: (value, formData) => {
        if (!value) return "Place of supply is required";
        if (formData.payment_currency === "AED" && value !== "Dubai") {
          return "Dubai must be selected when currency is AED";
        }
        return "";
      },
    },
    {
      id: "expense_amount",
      label: "Expense Amount",
      type: "number",
      instruction:
        "Fill the 'Amount including tax' as mentioned on the Invoice,  or the total amount given in the invoice",
      // required: true,
      validate: (value, formData) => {
        if (!value) return "Tax treatment is required";
        if (formData.payment_currency !== "AED" && value !== "out_of_scope") {
          return "Must select 'Out of Scope' for non-AED currency";
        }
        return "";
      },
    },
    {
      id: "paid_through",
      label: "Paid Through",
      type: "select",
      instruction:
        "Select paid through account as given in the invoice, from the list",
      // required: true,
      options: [
        { value: "", label: "Select" },
        { value: "credit_card", label: "Credit card" },
        { value: "adcb_4446", label: "ADCB-4446" },
        { value: "adcb_4504", label: "ADCB-4504" },
        { value: "enbd_cc_4208", label: "ENBD-CC-4208 (Payable to RS)" },
        { value: "credit_card_ending", label: "Credit card ending 2727" },
      ],
      validate: (value, formData) => {
        if (formData.tax_treatment === "vat_registered") {
          if (!value?.trim())
            return "Tax registration number is required for VAT registered vendors";
          if (value.length !== 15)
            return "Tax registration number must be 15 digits";
          if (!/^\d+$/.test(value))
            return "Tax registration number must contain only numbers";
        }
        return "";
      },
    },
    {
      id: "vat_treatment",
      label: "VAT Treatment",
      type: "select",
      instruction:
        "Please check the payment receipt whether the  amount is in AED or not?\n If 'No' then Please select 'Out_of_Scope' from the drop down List\n If 'Yes' then please check whether any 'Tax registration number' is given on the Invoice or not if 'yes' then please select 'vat_registered from the drop down List and if  'No' then please select 'vat_not_registered' from the drop the List",
      options: [
        { value: "", label: "Select" },
        { value: "vat_registered ", label: "VAT Registered " },
        { value: "vat_not_registered", label: "VAT Not Registered" },
      ],
      // required: true,
      validate: (value) => {
        if (!value) return "Payment currency is required";
        return "";
      },
    },
    {
      id: "place_of_supply",
      label: "Place Of Supply",
      type: "select",
      instruction:
        "If You have selected 'vat_registered or vat_not_registered' in VAT treatment column then please Enter the Place of Supply as mentioned on the Invoice\n If You have selected 'Out_of_Scope' in VAT treatment column then you can keep it blank",
      options: [
        { value: "", label: "Select" },
        { value: "standard_rate", label: "Standard Rate" },
        { value: "zero_rate", label: "Zero Rate" },
      ],
      // required: true,
      validate: (value) => {
        if (!value) return "Payment mode is required";
        return "";
      },
    },
    {
      id: "currency_code",
      label: "Currency Code",
      type: "select",
      instruction:
        "Please select the 'Currency code' as mentioned on the Invoice from the drop down List",
      options: [
        { value: "", label: "Select" },
        { value: "AED", label: "AED" },
        { value: "USD", label: "USD" },
        { value: "EUR", label: "EUR" },
        { value: "INR", label: "INR" },
      ],
      validate: (value, formData) => {
        if (formData.mode_of_payment === "bank_transfer") {
          if (!value?.trim()) return "Bank name is required for Bank Transfer";
        }
        return "";
      },
    },
    {
      id: "exchange_rate",
      label: "Exchange Rate",
      type: "number",
      instruction:
        "If Invoice Amount in AED the Please mention 'Exchange rate' as 1 and if amount in other currency then mention the current exchange rate using the website based on the Expense Date",
    },
    {
      id: "iban_no",
      label: "Tax Registration Number",
      type: "text",
      instruction:
        "If in 'VAT treatment column' if you selected the 'vat_registered' from the drop down List  then Please Mention the 15-digit Tax Registration number mentioned on the Invoice",
      validate: (value, formData) => {
        if (formData.mode_of_payment === "bank_transfer" && !value?.trim()) {
          return "IBAN number is required for Bank Transfer";
        }
        return "";
      },
    },
    {
      id: "is_inclusive_tax",
      label: "Is Inclusive Tax",
      type: "textarea",
      instruction:
        "If you have selected vat_registered in the VAT Treatment, select TRUE\n Leave it blank if you have selected any other lists from the VAT Treatment",
    },
    {
      id: "tax_name",
      label: "Tax Name",
      type: "select",
      instruction:
        "If you selected 'VAT Registered' in VAT treatment column then please check the 'rate of tax' as mentioned on the Invoice.If it is '5%' then please select 'standard rate' from the drop down list and if it is '0%' then please select 'Zero rate' from the drop down list",
      options: [
        { value: "", label: "Select" },
        { value: "standard_rate", label: "Standard Rate" },
        { value: "zero_rate", label: "Zero Rate" },
      ],
      validate: (value, formData) => {
        if (!/^[A-Za-z0-9]{8}$|^[A-Za-z0-9]{11}$/.test(value)) {
          return "SWIFT code must be 8 or 11 characters alphanumeric";
        }
        return "";
      },
    },
    {
      id: "tax_percentage",
      label: "Tax Percentage",
      type: "select",
      instruction:
        "Please select the 'tax percentage' as mentioned on the Invoice from the drop down List",
      options: [
        { value: "", label: "Select" },
        { value: "0", label: "0" },
        { value: "5", label: "5" },
      ],
      validate: (value) => {
        if (!value) return "Credit days policy is required";
        if (value < 0) return "Credit days cannot be negative";
        if (!Number.isInteger(Number(value)))
          return "Credit days must be a whole number";
        return "";
      },
    },
    {
      id: "expense_description",
      label: "Expense Description",
      type: "number",
      instruction: "Fill the purpose of expenses mentioned in the invoice",
      validate: (value) => {
        if (!value) return "Credit days policy is required";
        if (value < 0) return "Credit days cannot be negative";
        if (!Number.isInteger(Number(value)))
          return "Credit days must be a whole number";
        return "";
      },
    },
  ];

  const handleInputChange = (fieldId, value) => {
    setFormData((prev) => ({
      ...prev,
      [fieldId]: value,
    }));

    // Clear error when user types
    if (errors[fieldId]) {
      setErrors((prev) => ({
        ...prev,
        [fieldId]: "",
      }));
    }
  };

  const validateForm = () => {
    const formErrors = {};

    FORM_FIELDS.forEach((field) => {
      const value = formData[field.id];

      // Check if field is conditionally required
      const isRequired =
        typeof field.required === "function"
          ? field.required(formData)
          : field.required;

      // Required field validation
      if (isRequired && (!value || value.trim() === "")) {
        formErrors[field.id] = `${field.label} is required`;
        return;
      }

      // Field-specific validation
      if (field.validate && value) {
        const errorMessage = field.validate(value, formData);
        if (errorMessage) {
          formErrors[field.id] = errorMessage;
        }
      }
    });

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const transformFormData = (data) => {
    return {
      vendor_name: data.vendor_name,
      email: data.email,
      contact_phone: data.contact_phone,
      place_of_supply: data.place_of_supply,
      tax_treatment: data.tax_treatment,
      tax_registration_number: data.tax_registration_number,
      payment_currency: data.payment_currency,
      payment_mode: data.mode_of_payment,
      bank_name: data.bank_name,
      bank_account_number: data.bank_account_number,
      iban_no: data.iban_no,
      bank_branch_address: data.bank_branch_address,
      ifsc_code: data.ifsc_code,
      swift_code: data.swift_code,
      expected_credit_days: data.expected_credit_days,
    };
  };

  const handleApiError = (error) => {
    if (error.response?.data?.errors) {
      // Handle validation errors
      const errors = error.response.data.errors;
      const errorMessages = [];

      Object.entries(errors).forEach(([field, message]) => {
        errorMessages.push(`${field}: ${message}`);
      });

      setApiErrors(errorMessages);
    } else if (error.response?.status === 401) {
      const message = "Session expired. Please login again.";
      toast.error(message);
      setApiErrors([message]);
    } else if (error.response?.status === 403) {
      const message = "You don't have permission to perform this action";
      toast.error(message);
      setApiErrors([message]);
    } else {
      const message = error.response?.data?.detail || "Error submitting form";
      setApiErrors([message]);
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);
    try {
      const transformedData = transformFormData(formData);
      const url = `${mtoExtractionForm}luckyalani665@gmail.com:1tuwi5:mj7VcJrpe-flio3kdwiyGinWxmYupYtFiIoZLtKR53c/26/`;

      const response = await axiosInstance.post(url, transformedData);
      toast.success("Form submitted successfully");
    } catch (error) {
      handleApiError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = () => {
    setFormData({});
    setErrors({});
    setApiErrors([]);
  };

  const renderFormInput = (field) => {
    const isInvalid = errors[field.id];
    const commonProps = {
      value: formData[field.id] || "",
      onChange: (e) => handleInputChange(field.id, e.target.value),
      className: `form-control-sm ${isInvalid ? "is-invalid" : ""}`,
      disabled: isLoading,
    };

    return (
      <div className="form-group">
        {field.type === "select" ? (
          <Form.Select {...commonProps}>
            {field.options?.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Form.Select>
        ) : field.type === "textarea" ? (
          <Form.Control as="textarea" rows={3} {...commonProps} />
        ) : (
          <Form.Control type={field.type} {...commonProps} />
        )}
        {isInvalid && (
          <div className="invalid-feedback d-block">{errors[field.id]}</div>
        )}
      </div>
    );
  };

  return (
    <div className="content container-fluid">
      <Container className="mt-4 p-4">
        <div className="d-flex justify-content-between align-items-center mb-3 position-relative">
          <div className="flex-grow-1 text-center">
            <h3 className="mb-0">DATA EXTRACTION FORM</h3>
          </div>
          <div className="position-absolute end-0">
            <Button>Edit</Button>
          </div>
        </div>

        <hr />
        <Form onSubmit={handleSubmit} className="mt-4">
          <div className="table-responsive">
            <Table bordered responsive className="mto_table">
              <thead>
                <tr>
                  <th style={{ width: "20%" }}>Details to be extracted</th>
                  <th style={{ width: "40%" }}>Instructions</th>
                  <th style={{ width: "40%" }}>Extracted Details</th>
                </tr>
              </thead>
              <tbody>
                {FORM_FIELDS?.map((field) => (
                  <tr key={field.id}>
                    <td>
                      <b>{field.label}</b>
                      {field.required && (
                        <span
                          style={{
                            color: "#dc3545",
                            marginLeft: "3px",
                            fontWeight: "bold",
                          }}
                        >
                          *
                        </span>
                      )}
                    </td>
                    <td>
                      {field.instruction.split("\n")?.map((line, i) => (
                        <p key={i} className="mb-1">
                          {line}
                        </p>
                      ))}
                    </td>
                    <td>{renderFormInput(field)}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          {apiErrors.length > 0 && (
            <div className="api-errors mt-2">
              <ul className="error-list">
                {apiErrors?.map((error, index) => (
                  <li key={index} className="text-danger">
                    <i className="fas fa-exclamation-triangle me-2"></i>
                    {error}
                  </li>
                ))}
              </ul>
            </div>
          )}

          <div className="text-center mt-4">
            <Button variant="secondary" className="me-2" onClick={handleReset}>
              Reset
            </Button>
            <Button variant="primary" type="submit" disabled={isLoading}>
              {isLoading ? (
                <>
                  <span className="spinner-border spinner-border-sm me-2" />
                  Submitting...
                </>
              ) : (
                "Submit"
              )}
            </Button>
          </div>
        </Form>
      </Container>
    </div>
  );
};

export default ExpenseDataExtractionForm;
