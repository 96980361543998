import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Table } from "antd";

import AddandEditModal from "./AddandEditModal";

import AdminSideBar from "../../../../views/pages/MainPages/Dashboard/AdminDashboard/AdminSideBar";
import Header from "../../../layout/Header";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { addPolicy } from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { Dropdown, Menu } from "antd";

const Policies = () => {
  const [policies, setPolicies] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const location = useLocation();

  const fetchPolicies = () => {
    setIsLoading(true);
    axiosInstance
      .get(addPolicy)
      .then((response) => {
        const fetchedPolicies = response.data.map((policy) => ({
          id: policy.id,
          policyName: policy.name,
          objectives: policy.objectives,
          approvalPersonName: policy.approval_details?.employee_name || "N/A",
          guidelines: policy.guidelines,
          complianceMonitoredBy: policy.compliance_monitoring_details
            .map((item) => item.employee_name)
            .join(", "),
          madeByPersons: policy.made_by_persons_details
            .map((item) => item.employee_name)
            .join(", "),
          validUntil: policy.valid_until,
          valueCompatibility: policy.value_compatibility,
          createdAt: new Date(policy.created).toLocaleDateString(),
          policy_guidelines: policy.policy_guidelines,
          is_approved: policy.is_approved,
          is_approval: policy.is_approval,
          is_publish: policy.is_publish,
        }));
        setPolicies(fetchedPolicies);
        console.log("Fetched policies", response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching policies", error);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    fetchPolicies();
  }, [location]); // Fet

  const handleAddPolicy = () => {
    setSelectedPolicy(null);
    setIsAddModalOpen(true);
  };

  const handleAcknowledgement = (policy) => {
    // Navigate to the Policy Acknowledgement page
    window.location.href = `/policy-acknowledgement/${policy.id}`;
  };

  const handleView = (policy) => {
    setSelectedPolicy(policy);
    // Navigate to the Policy Details page
    window.location.href = `/policy-details/${policy.id}`;
  };

  const handleEdit = (policy) => {
    setSelectedPolicy(policy);
    setIsAddModalOpen(true);
  };

  const handleDelete = (policy) => {
    axiosInstance
      .delete(`${addPolicy}${policy.id}/`)
      .then(() => {
        toast.success("Policy deleted successfully");
        fetchPolicies();
      })
      .catch((error) => {
        console.error("Error deleting policy", error);
        toast.error("Failed to delete policy");
      });
  };

  const handleRequestApproval = (policy) => {
    axiosInstance
      .patch(`${addPolicy}${policy.id}/`, { is_approval: true })
      .then(() => {
        toast.success("Approval requested successfully");
        fetchPolicies();
      })
      .catch((error) => {
        console.error("Error requesting approval", error);
        toast.error("Failed to request approval");
      });
  };

  const handlePublish = (policy) => {
    axiosInstance
      .patch(`${addPolicy}${policy.id}/`, { is_publish: true })
      .then(() => {
        toast.success("Policy Published successfully");
        fetchPolicies();
      })
      .catch((error) => {
        console.error("Error publishing policy", error);
        toast.error("Failed to publish Policy");
      });
  };
  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Policy Name",
      dataIndex: "policyName",
      key: "policyName",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Objectives",
      dataIndex: "objectives",
      key: "objectives",
      render: (text) => (
        <div>{text.length > 29 ? text.substring(0, 30) + "..." : text}</div>
      ),
    },
    {
      title: "Approval Person Name",
      dataIndex: "approvalPersonName",
      key: "approvalPersonName",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Made By Persons",
      dataIndex: "madeByPersons",
      key: "madeByPersons",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Created At",
      dataIndex: "createdAt",
      sorter: (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        let statusText = "Not Requested";
        let backgroundColor = "#ffc107";
        if (record.is_approved && !record.is_publish) {
          statusText = "Policy Approved";
          backgroundColor = "#80bfff";
        } else if (
          !record.is_approved &&
          record.is_approval &&
          !record.is_publish
        ) {
          statusText = "Requested for Approval";
          backgroundColor = "#3788d8";
        } else if (record.is_approved && record.is_publish) {
          statusText = "Policy Published";
          backgroundColor = "#4caf50";
        }

        return (
          <span
            style={{
              padding: "10px",
              color: "#fff",
              backgroundColor,
              borderRadius: "5px",
            }}
          >
            {statusText}
          </span>
        );
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item key="view" onClick={() => handleView(record)}>
              <i className="fa fa-eye m-r-5" /> View
            </Menu.Item>
            {record.is_publish && (
              <Menu.Item
                key="acknowledgement"
                onClick={() => handleAcknowledgement(record)}
              >
                <i className="fa fa-info m-r-5" /> Acknowledgements
              </Menu.Item>
            )}
            {!record.is_publish && (
              <Menu.Item key="edit" onClick={() => handleEdit(record)}>
                <i className="fa fa-pencil m-r-5" /> Edit
              </Menu.Item>
            )}
            {!record.is_approved && (
              <Menu.Item
                key="request"
                onClick={() => handleRequestApproval(record)}
              >
                <i className="fa fa-check m-r-5" /> Request for Approval
              </Menu.Item>
            )}
            <Menu.Item key="delete" onClick={() => handleDelete(record)}>
              <i className="fa fa-trash m-r-5" /> Delete
            </Menu.Item>
            {record.is_approved && !record.is_publish && (
              <Menu.Item key="publish" onClick={() => handlePublish(record)}>
                <i className="fa fa-check m-r-5" /> Publish
              </Menu.Item>
            )}
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={["click"]}>
            <button
              className="ant-dropdown-link"
              onClick={(e) => e.preventDefault()}
              style={{ background: "none", border: "none", cursor: "pointer" }}
            >
              <i className="material-icons">more_vert</i>
            </button>
          </Dropdown>
        );
      },
    },
  ];

  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    setSelectedPolicy(null);
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row align-items-center mb-3">
            <div className="col-md-8">
              <Breadcrumbs
                maintitle="Policies"
                title="Add Policy"
                subtitle="Manage Policy"
                modal="#add_policy"
                name="Policies"
              />
            </div>
            <div className="col-md-4 text-end">
              <button
                className="btn add-btn"
                data-bs-toggle="modal"
                data-bs-target="#add_policy"
                onClick={handleAddPolicy}
              >
                <i className="fa fa-plus"></i> Add Policy
              </button>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={policies}
                  rowKey={(record) => record.id}
                  loading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddandEditModal
        selectedPolicy={selectedPolicy}
        fetchPolicies={fetchPolicies}
        clearSelectedPolicy={() => setSelectedPolicy(null)}
        isOpen={isAddModalOpen}
        onClose={handleCloseModal}
      />
    </>
  );
};

export default Policies;
