export const GaclSideData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Gacl Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/gacl-dashboard",
        icon: "la la-dashcube",
      },
      //task Management
      {
        menuValue: "Task Assignment",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "fa fa-tasks",
        subMenus: [
          {
            menuValue: "Dashboard",
            route: "/esr-aml-dashboard",
          },
          {
            menuValue: "Your Tasks",
            route: "/gacl-task-assigned",
          },
        ],
      },
      {
        menuValue: "Board Meeting",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/board-meeting",
        icon: "la la-list-alt",
      },
    ],
  },
];
