import React, { useState } from "react";
import { Table, Button, Tag, Modal, Typography, Divider } from "antd";
const { Title, Text } = Typography;

const ShortlistedCandidateFinal = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTechNotes, setSelectedTechNotes] = useState("");
  const [selectedProjectNotes, setSelectedProjectNotes] = useState("");

  const showModal = (techNotes, projectNotes) => {
    setSelectedTechNotes(techNotes);
    setSelectedProjectNotes(projectNotes);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: "Candidate Name",
      dataIndex: "candidateName",
      key: "candidateName",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "Email ID",
      dataIndex: "emailID",
      key: "emailID",
    },
    {
      title: "View Resume",
      key: "viewResume",
      render: () => (
        <Button style={{ backgroundColor: "#374151", color: "#fff" }}>
          View
        </Button>
      ),
    },
    {
      title: "Notes & Remarks",
      key: "notesRemarks",
      render: (_, record) => (
        <Button
          type="default"
          onClick={() => showModal(record.techNotes, record.projectNotes)}
          style={{ backgroundColor: "#374151", color: "#fff" }}
        >
          View
        </Button>
      ),
    },
    {
      title: "Decision to Hire",
      dataIndex: "decisionToHire",
      key: "decisionToHire",
      render: (decision) => (
        <Tag color={decision === "YES" ? "green" : "red"}>{decision}</Tag>
      ),
    },
    {
      title: "Induction",
      key: "induction",
      render: () => (
        <Button type="primary" danger>
          Schedule
        </Button>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      candidateName: "John Doe",
      contactNumber: "123-456-7890",
      emailID: "johndoe@example.com",
      decisionToHire: "YES",
      techNotes: "Good problem-solving skills.",
      projectNotes: "Strong domain knowledge.",
    },
    {
      key: "2",
      candidateName: "Jane Smith",
      contactNumber: "987-654-3210",
      emailID: "janesmith@example.com",
      decisionToHire: "NO",
      techNotes: "Lacks depth in algorithms.",
      projectNotes: "Average communication skills.",
    },
    {
      key: "3",
      candidateName: "Alice Johnson",
      contactNumber: "555-666-7777",
      emailID: "alicej@example.com",
      decisionToHire: "YES",
      techNotes: "Excellent coding skills.",
      projectNotes: "Impressive project contributions.",
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Button
        type="primary"
        style={{
          marginBottom: 16,
          backgroundColor: "#212529",
          borderColor: "#212529",
        }}
      >
        Final Shortlisted Candidate Page
      </Button>
      <Table columns={columns} dataSource={data} pagination={false} bordered />
      <Modal
        title={
          <Title level={4} style={{ marginBottom: 0 }}>
            Notes & Remarks
          </Title>
        }
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={null}
        centered
        bodyStyle={{ padding: "20px", fontSize: "16px" }}
      >
        <div style={{ marginBottom: "10px" }}>
          <Text strong style={{ fontSize: "16px", color: "#1890ff" }}>
            Tech Round:
          </Text>
          <Text style={{ display: "block", marginTop: "5px" }}>
            {selectedTechNotes}
          </Text>
        </div>
        <Divider />
        <div>
          <Text strong style={{ fontSize: "16px", color: "#1890ff" }}>
            Project Round:
          </Text>
          <Text style={{ display: "block", marginTop: "5px" }}>
            {selectedProjectNotes}
          </Text>
        </div>
      </Modal>
    </div>
  );
};

export default ShortlistedCandidateFinal;
