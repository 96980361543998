import React, { useState, useEffect } from "react";
import { Card, Table, Select, Button, Typography, Spin, Tag } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  employeeQPA,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const { Title } = Typography;
const { Option } = Select;

const EngineReport = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isHr, setIsHr] = useState(false);

  const fetchData = async (page = 1, pageSize = 10) => {
    try {
      setLoading(true);
      const url = `${employeeQPA}?is_boss_review=true&page=${page}&page_size=${pageSize}`;

      const response = await axiosInstance.get(url);

      const formattedData = response.data.results.map((item, index) => ({
        key: index + 1 + (page - 1) * pageSize,
        id: item.id,
        employeeName: item.user_details?.employee_name || "N/A",
        department:
          item.user_details?.employment_details?.[0]?.department_detail?.name ||
          "N/A",
        is_engine: item.is_engine,
        remarks: item.remarks || "N/A",
        quarter: item.quarter || "N/A",
        rating: item.rating || "N/A",
      }));

      setData(formattedData);
      setPagination({
        ...pagination,
        current: page,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then((response) => {
      setIsHr(
        response.data.employment_details[0].department_detail.name ===
          "Human Resource"
      );
    });
  }, []);

  useEffect(() => {
    fetchData(pagination.current, pagination.pageSize);
  }, []);

  const handleTableChange = (newPagination) => {
    fetchData(newPagination.current, newPagination.pageSize);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "key",
      key: "key",
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      key: "employeeName",
      render: (text) => <span className="fw-bold">{text}</span>,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
    },
    {
      title: "Quarter",
      dataIndex: ["quarter"],
      key: "quarter",
      render: (quarter) => quarter || "N/A",
    },
    {
      title: "Engine Status",
      dataIndex: "is_engine",
      key: "is_engine",
      render: (is_engine) => (is_engine ? "Yes" : "No"),
    },
    {
      title: "Rating",
      dataIndex: "rating",
      key: "rating",
      render: (rating) => {
        let color;
        switch (rating) {
          case "Gold":
            color = "gold";
            break;
          case "Silver":
            color = "silver";
            break;
          case "Bronze":
            color = "#cd7f32";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{rating || "N/A"}</Tag>;
      },
    },
    // {
    //   title: "Remarks",
    //   dataIndex: "remarks",
    //   key: "remarks",
    //   width: "30%",
    // },
  ];

  return (
    <>
      {isHr ? (
        <>
          <Header />
          <AdminSideBar />
        </>
      ) : (
        <></>
      )}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Title level={2}>Engine Report</Title>

          {/* Table Section */}
          <Card>
            <Table
              columns={columns}
              dataSource={data}
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              onChange={handleTableChange}
              loading={loading}
            />
          </Card>
        </div>
      </div>
    </>
  );
};

export default EngineReport;
