import React, { useEffect, useState } from "react";
import { Typography, Button } from "antd";
import { FaSmileBeam } from "react-icons/fa";
import { FaUserFriends } from "react-icons/fa";
import { FaEarthAfrica } from "react-icons/fa6";
import Logo from "../../../../assets/img/company/Herohabot.png";
const { Title, Text } = Typography;

const CareerHeroSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById("career-hero");
      if (section) {
        const rect = section.getBoundingClientRect();
        setIsVisible(rect.top < window.innerHeight / 1.2);
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Run initially
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      id="career-hero"
      style={{
        position: "relative",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        justifyContent: "center",
        backgroundImage: `url("https://storage.googleapis.com/habot-hr-gacl-dc-30042023/pexels-mubazbasheer-916017.jpg")`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        color: "white",
        textAlign: "center",
        padding: "50px 20px",
        transition: "opacity 1s ease-out, transform 1s ease-out",
        opacity: isVisible ? 1 : 0,
        transform: isVisible ? "translateY(0)" : "translateY(50px)",
        borderRadius: "0px 0px 50% 0",
      }}
    >
      <div
        style={{
          maxWidth: "800px",
          transition: "opacity 1s ease-out, transform 1s ease-out",
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? "translateX(0)" : "translateX(-50px)",
        }}
      >
        <img
          src="https://storage.googleapis.com/habot-hr-gacl-dc-30042023/Herohabot.png"
          alt=""
          style={{ width: "250px" }}
        />
        <Title level={1} style={{ color: "white", fontSize: "3rem" }}>
          Build Your Future With Us
        </Title>
        <Text
          style={{
            display: "block",
            fontSize: "20px",
            marginTop: "5px",
            color: "#f0f0f0",
          }}
        >
          Join a team that values innovation, collaboration, and personal
          growth.
        </Text>

        <h4
          style={{
            color: "#FD7B6B",
            fontSize: "16px",
            fontWeight: "lighter",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            flexWrap: "wrap", // Ensures responsiveness on small screens
            padding: "10px 0",
            margin: "20px 0",
          }}
        >
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaSmileBeam />
            Friendly Environment
          </span>
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaUserFriends />
            180+ Candidates
          </span>
          <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <FaEarthAfrica />
            Work From Home
          </span>
        </h4>
        <h5
          style={{
            margin: "10px 0px 20px 0px",
            color: "white",
            fontWeight: "lighter",
          }}
        >
          WE ARE HIRING
        </h5>
        <button
          type="primary"
          style={{
            border: "none",
            fontSize: "20px",
            color: "white",
            backgroundColor: "#FB913D",
            padding: "5px 25px",
            borderRadius: "20px",
            boxShadow: "0px 0px 5px 2px white",
            cursor: "pointer",
            transition: "all 0.3s ease-in-out",
          }}
          onMouseEnter={(e) =>
            (e.currentTarget.style.transform = "scale(1.05)")
          }
          onMouseLeave={(e) => (e.currentTarget.style.transform = "scale(1)")}
        >
          Join Our Team
        </button>
      </div>
    </div>
  );
};

export default CareerHeroSection;
