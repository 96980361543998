import React, { useState, useEffect } from "react";
import { Form, Button, Card, Row, Col, Spinner, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import "../EmployeeCareerPage/career.css";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  weeklyReview,
  departmentList,
} from "../../../../helpers/endpoints/api_endpoints";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function ViewWeeklyReview() {
  const [weeklyReviews, setWeeklyReviews] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [selectedYear, setSelectedYear] = useState("2025");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [availableYears, setAvailableYears] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axiosInstance.get(departmentList);
        setDepartments(response.data);
      } catch (error) {
        console.error("Error fetching departments:", error);
        toast.error("Failed to fetch departments");
      }
    };
    fetchDepartments();
  }, []);

  useEffect(() => {
    const fetchWeeklyReview = async () => {
      setLoading(true);
      try {
        let url = `${weeklyReview}?year=${selectedYear}&page=${currentPage}&page_size=10`;
        if (selectedMonth) {
          const monthIndex =
            new Date(`${selectedMonth} 1, ${selectedYear}`).getMonth() + 1;
          url += `&month=${monthIndex}`;
        }
        if (selectedDepartment) {
          url += `&department_name=${selectedDepartment}`;
        }

        const response = await axiosInstance.get(url);
        const reviews = response.data.results;
        setTotalPages(response.data.total_pages);
        setWeeklyReviews(reviews);
        console.log(reviews);

        const totalPagesCount =
          response.data.total_pages || Math.ceil(response.data.count / 10);
        setTotalPages(totalPagesCount);

        const years = [
          ...new Set(
            reviews?.map((review) => new Date(review.created_at).getFullYear())
          ),
        ];
        setAvailableYears(years.sort((a, b) => b - a));
      } catch (error) {
        console.error("Error fetching weekly reviews:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchWeeklyReview();
  }, [selectedDepartment, selectedYear, selectedMonth, currentPage]);

  const truncateText = (text, maxLength = 40) => {
    if (text.length > maxLength) {
      return {
        truncated: text.substring(0, maxLength) + "...",
        isTruncated: true,
      };
    }
    return { truncated: text, isTruncated: false };
  };

  const handleShowMore = (review) => {
    setSelectedComment(review);
    setShowModal(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid position-relative">
        <Card className="p-5">
          <h3>Weekly Review</h3>
          <div className="row d-flex">
            <div className="col-md-3">
              <Form.Select
                value={selectedYear}
                onChange={(e) => setSelectedYear(e.target.value)}
              >
                <option>Year</option>
                {availableYears.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="col-md-3">
              <Form.Select
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(e.target.value)}
              >
                <option value="">All Months</option>
                {[
                  "January",
                  "February",
                  "March",
                  "April",
                  "May",
                  "June",
                  "July",
                  "August",
                  "September",
                  "October",
                  "November",
                  "December",
                ].map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </Form.Select>
            </div>

            <div className="col-md-3">
              <Form.Select
                value={selectedDepartment}
                onChange={(e) => setSelectedDepartment(e.target.value)}
              >
                <option value="">All Departments</option>
                {departments?.map((dept) => (
                  <option key={dept?.id} value={dept?.name}>
                    {dept?.name}
                  </option>
                ))}
              </Form.Select>
            </div>
          </div>
          <br />

          {loading ? (
            <div className="text-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <Row>
              {weeklyReviews?.length > 0 ? (
                weeklyReviews?.map((review) => {
                  const { truncated, isTruncated } = truncateText(
                    review.comment
                  );
                  return (
                    <Col key={review.id} md={6}>
                      <div className="position-relative card-weekly mb-4">
                        <span className="status-indicator"></span>
                        <Card.Body className="ps-5">
                          <Card.Title>
                            {review.employee_info.employee_name}
                          </Card.Title>
                          <Card.Subtitle className="mb-2 text-muted">
                            {review.employee_info.employment_details[0]
                              ?.department_detail.name || "N/A"}
                          </Card.Subtitle>

                          <div className="comment-container">
                            <ReactQuill
                              value={review.comment}
                              readOnly={true}
                              theme="bubble"
                              modules={{ toolbar: false }}
                            />
                            <div className="fade-overlay"></div>
                            <span
                              className="text-primary cursor-pointer m-0 p-0"
                              onClick={() => handleShowMore(review)}
                            >
                              more...
                            </span>
                          </div>

                          <p className="text-muted text-end">
                            {new Date(review.created_at).toLocaleString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "long",
                                day: "numeric",
                                hour: "2-digit",
                                minute: "2-digit",
                                hour12: true,
                              }
                            )}
                          </p>
                        </Card.Body>
                      </div>
                    </Col>
                  );
                })
              ) : (
                <p className="text-center">No weekly reviews available.</p>
              )}
            </Row>
          )}
        </Card>

        <div className="d-flex justify-content-center mt-3">
          {totalPages > 1 &&
            [...Array(totalPages)].map((_, index) => (
              <Button
                key={index}
                variant={index + 1 === currentPage ? "primary" : "light"}
                className="mx-1"
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Button>
            ))}
        </div>
      </div>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header className="modal-weekly" closeButton>
          <Modal.Title>
            Full Review -&nbsp;
            <small>
              {selectedComment?.hashtag_info?.hashtag || ""}
            </small>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="modal-weekly">
          <ReactQuill
            value={selectedComment?.comment}
            readOnly={true}
            theme="bubble"
            modules={{ toolbar: false }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default ViewWeeklyReview;
