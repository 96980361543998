import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { addPolicy } from "../../../../helpers/endpoints/api_endpoints";
import { Table, Spin, Typography, Card, Tag, Tabs } from "antd";
import moment from "moment";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const { Title } = Typography;
const { TabPane } = Tabs;

const PolicyAcknowledgement = () => {
  const { id } = useParams();
  const [policyData, setPolicyData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);

  useEffect(() => {
    const fetchPolicyData = async () => {
      try {
        const response = await axiosInstance.get(`${addPolicy}${id}/`);
        setPolicyData(response.data);

        // Extract unique departments
        const uniqueDepartments = [
          ...new Set(
            response.data.acknowledgments.map(
              (ack) =>
                ack.employee_details.employment_details[0].department_detail
                  .name
            )
          ),
        ];
        setDepartments(uniqueDepartments);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching policy data:", error);
        setLoading(false);
      }
    };

    fetchPolicyData();
  }, [id]);

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["employee_details", "employee_name"],
      key: "employee_name",
      render: (text) => <strong>{text}</strong>,
    },
    {
      title: "Department",
      dataIndex: ["employee_details", "employment_details"],
      key: "department",
      render: (details) => details[0].department_detail.name,
    },
    {
      title: "Acknowledged Date",
      dataIndex: "acknowledged_date",
      key: "acknowledged_date",
      render: (date) => moment(date).format("YYYY-MM-DD"),
    },
    {
      title: "Status",
      dataIndex: "is_acknowledged",
      key: "is_acknowledged",
      render: (status) => (
        <Tag color={status ? "green" : "red"}>
          {status ? "Acknowledged" : "Not Acknowledged"}
        </Tag>
      ),
    },
  ];

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Card>
            <Title level={2} style={{ marginBottom: "20px" }}>
              Policy Acknowledgement List
            </Title>
            <Title level={4} style={{ marginBottom: "30px", color: "#1890ff" }}>
              {policyData?.name}
            </Title>

            {policyData?.acknowledgments &&
            policyData.acknowledgments.length > 0 ? (
              <Tabs defaultActiveKey="All">
                <TabPane tab="All Departments" key="All">
                  <Table
                    dataSource={policyData.acknowledgments}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                    bordered
                    style={{ backgroundColor: "white" }}
                  />
                </TabPane>
                {departments.map((dept) => (
                  <TabPane tab={dept} key={dept}>
                    <Table
                      dataSource={policyData.acknowledgments.filter(
                        (ack) =>
                          ack.employee_details.employment_details[0]
                            .department_detail.name === dept
                      )}
                      columns={columns}
                      rowKey="id"
                      pagination={{ pageSize: 10 }}
                      bordered
                      style={{ backgroundColor: "white" }}
                    />
                  </TabPane>
                ))}
              </Tabs>
            ) : (
              <div
                style={{
                  textAlign: "center",
                  padding: "20px",
                  backgroundColor: "#f0f2f5",
                  borderRadius: "4px",
                }}
              >
                <Title level={4} style={{ color: "#8c8c8c" }}>
                  No acknowledgment data available.
                </Title>
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  );
};

export default PolicyAcknowledgement;
