import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "./Navbar";

import Header1 from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import Modals from "./CareerPage2Comp/Modals";
import {
  Layout,
  Typography,
  Button,
  Card,
  Input,
  Tag,
  List,
  Row,
  Col,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
const { Search } = Input;

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const jobs = [
  { title: "Developer", salary: "₹20000 - ₹30000" },
  { title: "Technical Writer", salary: "₹20000 - ₹30000" },
  { title: "Technical Writer", salary: "₹20000 - ₹30000" },
  { title: "Technical Writer", salary: "₹20000 - ₹30000" },
];

const CareerPage1 = () => {
  const [filters, setFilters] = useState({
    category: "Engineering, Marketing, Sales, etc",
    title: "Software Engineer, Content Writer",
    location: "Remote",
    employmentType: "Full time",
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  const jobDetails = {
    title: "UI/UX Designer",
    employmentType: "Full Time, 100% Remote Working",
    salary: "20000-30000",
    experience: "2-3 years",
    education: [
      "A bachelor's Degree in a relevant field, such as English, Technical Writing, Computer Science, Information Technology, or a related discipline, is expected.",
      "Basic knowledge of designing",
    ],
    expectations: [
      "Better Communication",
      "Good Research in Designing",
      "Tackling Ability",
      "Working Style",
    ],
  };
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <>
      <Header1 />
      <AdminSideBar />
      <div className="page-wrapper ">
        <div className="content container-fluid position-relative">
          <Layout>
            <Navbar />
            <Content style={{ padding: "40px 20px" }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: isSmallScreen ? "column-reverse" : "row",
                  justifyContent: "space-between",
                  padding: "10px 10px",
                  background: "#F5F5F5",
                  gap: "40px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    gap: "10px",
                  }}
                >
                  <Title>Welcome to HABOT</Title>
                  <button
                    style={{
                      background: "#FF883A",
                      color: "white",
                      padding: "10px 25px",
                      border: "0px",
                      borderRadius: "30px",
                      fontWeight: "semibold",
                    }}
                    className="hover:scale-115"
                  >
                    WE ARE HIRING!!
                  </button>
                  <Text block className="text-xl font-bold">
                    Be part of our mission.
                  </Text>
                  <span>
                    Join our team and shape the future with your passion,
                    creativity, and innovation.
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    textAlign: "center",
                    width: "100%",
                    maxWidth: "600px",
                    margin: "auto",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    {/* Left Image */}
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src="https://s3-alpha-sig.figma.com/img/8447/ba49/f2130cdf53b1a6ea8ba3887de7d58848?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=XvrLTpy7gNi~J134Gz5xZ6JPIq--mBmckoOW0Nm5lfaDeWr5A7vdRu3~9uOpQLOi5UEtD-FVxpZHHv9UX5AQDBr40SCsh3741-MvSbY7qkHh~xSDtQwX136G~thIoy1KfLg9BBYexTjXP8~jJyCGL1laM1zHOxZd1dU1G~m1~EvxyFRdVlEwIUVc3P9xN6uOCBlhOA9G7dV7MNqjSzDMvxRRqC5UCDqgBCCMEVYDCEIVWzBiwf69m3ODUxHjUkUVK5kFsy3KFq76zTer5~jN6VsLLrhbVmW3ZosfbrumULR7Ozdu9AlbpOyw7c0M814pzn5gAAMofMdtJ~aCNwqABw__"
                        alt="Friendly Environment"
                        style={{
                          width: "120px",
                          height: "180px",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    {/* Center Image */}
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src="https://s3-alpha-sig.figma.com/img/5a11/0ee6/1d61dbe13de0ddde54ef107698bb5a8c?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=oO87-yN1WDl92QOC5cVGpPekVrAIBlBIScfyx8frviVBYzzp2yHyN1OkePYc4p8w~g3YRSHFbda-KIGMeowKRMCah4s3KBcUiwb6A-25zO4k~gXr-vMe5qybGG1SNDjIBuOuoLKunZDtWoOB9ELFwFhKAoRLGpNh8OvWZgOgmo-eJJ-zF6gmg-gTyk3iqKwmTGhcntJiaQgXZ3Hzc~7FGkaDO06wqAWpARP-GXiOCTbhgCv9YFTSOhihJVAq-XJ3T5YNSHEEjr-YNwI9ZSDVilDqY448K2Yoonc7KfBKxyu0KG9aXPek6h3ZSECC-jGNzvUthAfySonZOWDyA-m0~Q__"
                        alt="Work from home"
                        style={{
                          width: "150px",
                          height: "220px",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                      />
                    </div>

                    {/* Right Image */}
                    <div
                      style={{
                        position: "relative",
                        borderRadius: "8px",
                        overflow: "hidden",
                      }}
                    >
                      <img
                        src="https://s3-alpha-sig.figma.com/img/d26a/2b98/79268e3399e7264b3d3399ba76de06ef?Expires=1742169600&Key-Pair-Id=APKAQ4GOSFWCW27IBOMQ&Signature=FuhRGTlBNGUOaQ2auvlR2t6MD7xM7GBsQlySzGn1hKc25z8vSxKqMORQkHwnoh~fY-1ODj2aj9w1VkFXNcJdKEONq-rjo4gsUWDTJpt39n5WLiH7Fv8R~9SaeGatIAMVBUWrYJFTk8JdUSozEfrBQ5LttWtyv02sKBiO1hFaGyuuIE55G~LkZ9qhU7V2wmfqq~e~7mpoVtVCC5HmwRHwxY1PL4aDo3SEA1c55j4uUJVVPhy6yJ9DFm4g1g5Ci71C7s1cNe6ZYMCBFxTk0ahxXw633RF~OD4cG3BRIDBTTf7kyNqx~kdwFXR6SwjkN~ZpxvV2PQbEXIPaSfs0kXkLKA__"
                        alt="Work from home"
                        style={{
                          width: "120px",
                          height: "180px",
                          borderRadius: "8px",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </div>

                  {/* Bottom Tag */}

                  <div
                    style={{
                      position: "absolute",
                      top: "100px",
                      left: "15%",
                      transform: "translateX(15%)",
                      background: "rgba(255, 112, 67, 0.8)",
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "6px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      transition: "transform 0.3s ease-in-out",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.05)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  >
                    Friendly Enviroment
                  </div>

                  <div
                    style={{
                      position: "absolute",
                      bottom: "15px",
                      left: "40%",
                      transform: "translateX(-40%)",
                      background: "rgba(0, 51, 102, 0.8)",
                      color: "#fff",
                      padding: "10px 20px",
                      borderRadius: "6px",
                      fontSize: "16px",
                      fontWeight: "bold",
                      transition: "transform 0.3s ease-in-out",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.05)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  >
                    180 + Candidates
                  </div>
                  <div
                    style={{
                      position: "absolute",
                      top: "40px",
                      left: "60%",
                      transform: "translateX(50%)",
                      background: "rgba(255, 112, 67, 0.8)",
                      color: "white",
                      padding: "10px 20px",
                      borderRadius: "6px",
                      fontSize: "12px",
                      fontWeight: "bold",
                      transition: "transform 0.3s ease-in-out",
                      cursor: "pointer",
                    }}
                    onMouseEnter={(e) =>
                      (e.currentTarget.style.transform = "scale(1.05)")
                    }
                    onMouseLeave={(e) =>
                      (e.currentTarget.style.transform = "scale(1)")
                    }
                  >
                    Work From Home
                  </div>
                </div>
              </div>
            </Content>
            <div style={{ margin: "0px 30px" }}>
              <Input
                size="large"
                placeholder="Job title e.g. - Developer, Technical Writer, Accountant"
                prefix={<SearchOutlined />}
                style={{ marginBottom: 16 }}
              />
              <Button
                type="primary"
                danger
                style={{
                  marginBottom: 16,
                  float: "right",
                  marginRight: "10px",
                }}
              >
                Search
              </Button>
            </div>

            {/* <List
              dataSource={jobs}
              style={{ margin: "0px 30px" }}
              renderItem={(job, index) => (
                <Card
                  key={index}
                  bordered={true}
                  style={{
                    marginBottom: 12,
                  }}
                >
                  <h3
                    style={{
                      fontSize: 16,
                      fontWeight: "bold",
                      marginBottom: 8,
                    }}
                  >
                    {job.title}
                  </h3>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      marginBottom: 8,
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <Tag color="default">Full time</Tag>
                      <Tag color="default">1-2yrs Exp.</Tag>
                      <Tag color="default">{job.salary}</Tag>
                    </div>

                    <Button
                      type="primary"
                      style={{
                        background: "#003366",
                        border: "none",
                        padding: "0px 30px",
                      }}
                      shape="round"
                    >
                      View
                    </Button>
                  </div>
                </Card>
              )}
            /> */}

            <Col xs={24} md={24} style={{ padding: "24px" }}>
              <Card style={{ borderRadius: "8px" }}>
                <h3 style={{ color: "#666" }}>Job Title</h3>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <h2 style={{ fontWeight: "bold" }}>UI/UX Designer</h2>
                  <Button
                    type="primary"
                    style={{
                      background: "#5E6B86",
                      border: "none",

                      cursor: "pointer",
                    }}
                    onClick={() => setIsApplyModalOpen(true)}
                  >
                    Apply Now
                  </Button>
                </div>

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <div>
                    <h4 style={{ color: "#666" }}>Employment Type</h4>
                    <p>
                      <b>Full Time, 100% Remote Working</b>
                    </p>
                    <p style={{ fontSize: "12px", color: "#666" }}>
                      Your camera, video connection must work well
                    </p>
                  </div>
                  <div>
                    <h4 style={{ color: "#666" }}>Salary</h4>
                    <p>
                      <b>20000-30000</b>
                    </p>
                  </div>
                </div>

                <h4 style={{ marginTop: "20px", color: "#666" }}>Experience</h4>
                <p>2-3 years</p>

                <h4 style={{ color: "#666" }}>Education</h4>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>
                    A bachelor's Degree in a relevant field, such as English,
                    Technical Writing, Computer Science, Information Technology,
                    or a related discipline, is expected.
                  </li>
                  <li>Basic knowledge of designing</li>
                </ul>

                <h4 style={{ color: "#666" }}>
                  Job output expected from the candidate
                </h4>
                <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                  <li>Better Communication</li>
                  <li>Good Research in Designing</li>
                  <li>Tackling Ability</li>
                  <li>Working Style</li>
                </ul>

                <Card style={{ marginTop: "20px", borderRadius: "8px" }}>
                  <h4 style={{ color: "#666" }}>Application Process</h4>
                  <p>
                    If you are confident in your ability to meet the
                    requirements outlined in the job description, please submit
                    your application using the apply now button.
                  </p>
                  <p>
                    If your application meets our criteria, and your application
                    gets shortlisted we will send you an invitation for the
                    first-round interview, which will be scheduled via Google
                    Meet.
                  </p>
                  <p>
                    Upon successful completion of the first round, you will be
                    invited to the second round, which will involve a project
                    presentation. Further details for this round will be shared
                    with you after you successfully passed the first round.
                  </p>
                  <Button
                    type="primary"
                    style={{ background: "#5E6B86", border: "none" }}
                    onClick={() => navigate("/project-detail")}
                  >
                    View Project
                  </Button>
                </Card>
              </Card>
            </Col>
          </Layout>
          <Modals
            setIsApplyModalOpen={setIsApplyModalOpen}
            isApplyModalOpen={isApplyModalOpen}
          />
        </div>
      </div>
    </>
  );
};

export default CareerPage1;
