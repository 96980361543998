import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Select,
  Checkbox,
  Modal,
  Col,
  DatePicker,
  Row,
  Input,
} from "antd";
import { useNavigate } from "react-router-dom";
import "antd/dist/reset.css";
import { employeeDetails } from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import CutoffFilterForm from "./modals/CutoffFilterForm";
import { FaLocationArrow } from "react-icons/fa";

const { Option } = Select;

const ShortlistedCandidateListProject = () => {
  const [filter, setFilter] = useState("All");
  const [employeeDetail, setEmployeeDetails] = useState();
  const [userRole, setUserRole] = useState(null);
  const [isModalVisibleProject, setIsModalVisibleProject] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [interviewDate, setInterviewDate] = useState(null);
  const [interviewCount, setInterviewCount] = useState("10");

  const navigate = useNavigate();

  const toSchedule = () => {
    navigate("/tech-schedule-candidate");
  };

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then(
      (response) => {
        setEmployeeDetails(response.data);
        if (
          response?.data?.employment_details?.[0].designation_detail.name ===
            "Team Leader" ||
          response?.data?.employment_details?.[0].designation_detail.name ===
            "Group Leader"
        ) {
          setUserRole("TeamLead");
        } else if (
          response?.data?.employment_details?.[0].department_detail.name ===
            "Human Resource" &&
          response?.data?.employment_details?.[0].designation_detail.name !==
            "Team Leader"
        ) {
          setUserRole("HR");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);
  const showProjectModal = (event, project) => {
    event.stopPropagation(); // Prevent the row click handler from being triggered
    setIsModalVisibleProject(true);
  };

  //for tl
  const columnsTL = [
    {
      title: "Candidate Name",
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <Button
          type="link"
          onClick={() => navigate(`/candidate/${record.key}`)}
        >
          {text}
        </Button>
      ),
    },
    {
      title: "Experience",
      dataIndex: "experience",
      key: "experience",
    },
    {
      title: "Education",
      dataIndex: "education",
      key: "education",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      key: "mobile",
    },
    {
      title: "Cut off Filter form",
      dataIndex: "filterForm",
      key: "filterForm",
      render: () => (
        <Button
          style={{ background: "#374151", color: "white", border: "none" }}
          onClick={() => setIsModalVisible(true)}
        >
          View
        </Button>
      ),
    },
    {
      title: "Project",
      dataIndex: "project",
      key: "project",
      render: (text, record) => (
        <Button
          style={{ backgroundColor: "#374151", color: "#fff" }}
          onClick={(e) => showProjectModal(e, record.projectDetails)}
        >
          View
        </Button>
      ),
    },
    {
      title: "Project Status",
      dataIndex: "status",
      key: "status",
      render: () => (
        <Select
          defaultValue="Select"
          style={{ width: 100 }}
          onClick={console.log("")}
        >
          <Option value="pending">Pending</Option>
          <Option value="completed">Completed</Option>
        </Select>
      ),
    },
    {
      title: "View Resume",
      dataIndex: "resume",
      key: "resume",
      render: () => (
        <Button style={{ backgroundColor: "#374151", color: "#fff" }}>
          View Resume
        </Button>
      ),
    },
  ];

  // for HR
  const columns = () => {
    if (filter === "all") {
      return [
        { title: "Candidate Name", dataIndex: "name", key: "name" },
        {
          title: "Interview Time",
          dataIndex: "InterviewTime",
          key: "InterviewTime",
        },
        { title: "Email", dataIndex: "email", key: "email" },
        {
          title: "Mobile (With Country Code)",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "Meet",
          dataIndex: "meet",
          key: "meet",
          render: () => (
            <Button
              style={{
                background: "#374151",
                color: "white",
                border: "none",
              }}
              onClick={() => setIsModalVisible(true)}
            >
              View
            </Button>
          ),
        },
        {
          title: "Project",
          dataIndex: "project",
          dataIndex: "project",

          render: () => (
            <Button
              style={{
                background: "#374151",
                color: "white",
                border: "none",
              }}
              onClick={() => setIsModalVisibleProject(true)}
            >
              View
            </Button>
          ),
        },
        {
          title: "Project Status ",
          dataIndex: "shortlisted",
          key: "shortlisted",
          render: () => <p style={{ color: "green" }}>shortlisted</p>,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: () => (
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Available</Option>
              <Option value="no">Not Available</Option>
            </Select>
          ),
        },
        {
          title: "View Resume",
          dataIndex: "resume",
          key: "resume",
          render: () => <Button>View Resume</Button>,
        },
      ];
    } else if (filter === "not_Schedule") {
      return [
        { title: "Candidate Name", dataIndex: "name", key: "name" },
        { title: "Experience", dataIndex: "experience", key: "experience" },
        { title: "Education", dataIndex: "education", key: "education" },
        { title: "Email", dataIndex: "email", key: "email" },
        {
          title: "Mobile (With Country Code)",
          dataIndex: "mobile",
          key: "mobile",
        },
        {
          title: "Project",
          dataIndex: "project",
          dataIndex: "project",

          render: () => (
            <Button
              style={{
                background: "#374151",
                color: "white",
                border: "none",
              }}
              onClick={() => setIsModalVisibleProject(true)}
            >
              View
            </Button>
          ),
        },
        {
          title: "Project Status ",
          dataIndex: "shortlisted",
          key: "shortlisted",
          render: () => <p style={{ color: "green" }}>shortlisted</p>,
        },
        {
          title: "Status",
          dataIndex: "status",
          key: "status",
          render: () => (
            <Select placeholder="Select" style={{ width: "100%" }}>
              <Option value="yes">Available</Option>
              <Option value="no">Not Available</Option>
            </Select>
          ),
        },
        {
          title: "View Resume",
          dataIndex: "resume",
          key: "resume",
          render: () => <Button>View Resume</Button>,
        },
      ];
    }
  };

  if (userRole === "HR") {
    columns.push({
      title: "Project status",
      dataIndex: "hrReview",
      key: "hrReview",
      render: () => <span>Yes</span>,
    });
  }

  const dataSource = [
    {
      key: "1",
      name: "John Doe",
      experience: "5 years",
      education: "Bachelor's Degree",
      email: "john.doe@example.com",
      mobile: "+1234567890",
      filterForm: "View",
      project: "View",
      projectDetails: "Project details here", // Ensure you have project details for the modal
      status: "Pending",
      resume: "View Resume",
    },
  ];

  const handleRowClick = (record, e) => {
    if (e.target.closest(".ant-select")) {
      e.stopPropagation();
    } else if (!record.scheduled) {
      navigate(`/tech-schedule-candidate`);
    }
  };

  return (
    <>
      <div style={{ padding: "20px" }}>
        <div
          style={{
            backgroundColor: "#FE8147",
            padding: "10px 20px",
            borderRadius: "5px",
            color: "white",
            fontSize: "16px",
            fontWeight: "bold",
            display: "inline-block",
            marginBottom: "20px",
          }}
        >
          Shortlisted Candidate List
        </div>
        <p style={{ color: "gray", marginBottom: "16px" }}>
          View all jobs /{" "}
          <span style={{ fontWeight: "bold" }}>Shortlisted candidates</span>
        </p>

        <Row gutter={16} style={{ marginBottom: 20 }}>
          <Col span={6}>
            <Select
              value={filter}
              onChange={setFilter}
              style={{ width: "100%" }}
            >
              <Option value="all">All</Option>
              {/* <Option value="Schedule">Schedule</Option> */}
              <Option value="not_Schedule">not Schedule</Option>
            </Select>
          </Col>
          <Col span={6}>
            <Input
              value={interviewCount}
              onChange={(e) => setInterviewCount(e.target.value)}
            />
          </Col>
          <Col span={6}>
            <DatePicker
              style={{ width: "100%" }}
              onChange={(date, dateString) => setInterviewDate(dateString)}
              placeholder="pick a date"
            />
          </Col>
        </Row>

        <Table
          dataSource={dataSource}
          columns={columns()}
          pagination={false}
          size="small"
        />
      </div>

      <Modal
        title="Project Details"
        visible={isModalVisibleProject}
        onCancel={() => setIsModalVisibleProject(false)}
        footer={null}
      >
        <div>
          <p>
            <strong>Project Name:</strong> E-commerce Landing page
          </p>
          <p>
            <strong>Project Link:</strong>{" "}
            <a href="xyz" target="_blank" rel="noopener noreferrer">
              xyx
            </a>
          </p>
          <Button onClick={() => setIsModalVisibleProject(false)}>Close</Button>
        </div>
      </Modal>
      <Modal
        title={null}
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        centered
        width={900}
      >
        <CutoffFilterForm setIsModalVisible={setIsModalVisible} />
      </Modal>
    </>
  );
};

export default ShortlistedCandidateListProject;
