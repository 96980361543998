import React from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import "./career.css";

function JobDetails() {
  return (
    <div className="page-wrapper">
      <div className="content container-fluid position-relative">
        <div>
          <h1>Welcome to HABOT</h1>
          <Button variant="secondary" className="my-2 filter-btn px-4 py-2">
            WE ARE HIRING
          </Button>
          <h2>Be part of our mission</h2>
          <h5 className="my-2">
            Join our team and shape the future with your passion, creativity,
            and innovation.
          </h5>
          <div className="d-flex gap-2 my-2 mb-3">
            <Button variant="outline-secondary" className="filter-btn">
              All
            </Button>
            <Button variant="outline-secondary" className="filter-btn">
              Development
            </Button>
            <Button variant="outline-secondary" className="filter-btn">
              Design
            </Button>
            <Button variant="outline-secondary" className="filter-btn">
              Marketing
            </Button>
            <Button variant="outline-secondary" className="filter-btn">
              Finance
            </Button>
            <Button variant="outline-secondary" className="filter-btn">
              Operations
            </Button>
          </div>
        </div>
        <Row>
          <Col md={5}>
            <Row>
              <div className="card-career">
                <div className="d-flex justify-content-between align-items-center">
                  <h4 className="mb-0">Filters</h4>
                  <h5 className="mb-0">Clear All Filters</h5>
                </div>

                <Form.Label className="mt-3">Job Category</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Engineering, Marketing, Sales, etc"
                />

                <Form.Label className="mt-3">Job Title</Form.Label>
                <Form.Control
                  size="lg"
                  type="text"
                  placeholder="Software Engineer, Content Writer"
                />

                <div>
                  <Form.Label className="mt-3">Location</Form.Label>
                  <br />
                  <Button variant="outline-secondary" className="filter-btn">
                    On-Site
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="mx-2 filter-btn"
                  >
                    Hybrid
                  </Button>
                  <Button variant="outline-secondary" className="filter-btn">
                    Remote
                  </Button>
                </div>

                <div>
                  {" "}
                  <Form.Label className="mt-3">Employment Type</Form.Label>
                  <br />
                  <Button variant="outline-secondary" className="filter-btn">
                    Full time
                  </Button>
                  <Button
                    variant="outline-secondary"
                    className="mx-2 filter-btn"
                  >
                    Part time
                  </Button>
                  <Button variant="outline-secondary" className="filter-btn">
                    Internship
                  </Button>
                </div>

                <div className="my-3 d-flex justify-content-center align-items-center">
                  <Button className="px-5">Search</Button>
                </div>
              </div>
            </Row>
            <Row>
              <div className="card-career my-4">
                <h3 className="mb-4">Other Related Jobs</h3>
                <div className="card-career">
                  <div>
                    <div className="d-flex justify-content-between align-items-center">
                      <h4 className="mb-0">Technical Writer</h4>
                      <h6 className="mb-0">Posted 3 days ago</h6>
                    </div>

                    <br />
                    <Button variant="outline-secondary" className="filter-btn">
                      Full time
                    </Button>
                    <Button
                      variant="outline-secondary"
                      className="mx-2 filter-btn"
                    >
                      1-2yrs Exp.
                    </Button>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
          <Col md={7}>
            <div className="card-career">
              <div className="d-flex justify-content-between align-items-center">
                <h4 className="mb-0">Job Title</h4>
                <h6 className="mb-0">
                  {" "}
                  <Button variant="secondary" className="px-5 mt-2">
                    Apply Now
                  </Button>
                </h6>
              </div>

              <h2 className="my-3">UI/UX Designer</h2>
              <Row>
                <Col>
                  <h5>Employment Type</h5>
                  <p>Full Time, 100% Remote Working</p>
                </Col>
                <Col>
                  <h5>Salary</h5>
                  <p>20000-30000</p>
                </Col>
              </Row>
              <h5>Experience</h5>
              <p>2-3 years</p>

              <h5>Education</h5>
              <ul className="career-list">
                <li>
                  A bachelor's Degree in a relevant field, such as English,
                  Technical Writing, Computer Science, Information Technology,
                  or a related discipline, is expected.
                </li>
                <li>Basic knowledge of designing</li>
              </ul>

              <h5>Job output expected from the candidate</h5>
              <ul className="career-list">
                <li>Better Communication </li>
                <li>Good Research in Designing</li>
                <li>Tackling Ability</li>
                <li>Working Style</li>
              </ul>
              <div className="card-career">
                <h5>Application Process</h5>

                <p>
                  If you are confident in your ability to meet the requirements
                  outlined in the job description, please submit your
                  application using the apply now button.
                </p>
                <p>
                  If your application meets our criteria, and your application
                  gets shortlisted we will send you an invitation for the
                  first-round interview, which will be scheduled via Google
                  Meet.
                </p>
                <p>
                  Upon successful completion of the first round, you will be
                  invited to the second round, which will involve a project
                  presentation. Further details for this round will be shared
                  with you after you successfully passed the first round.
                </p>

                <Button variant="secondary" className="px-5 mt-2">
                  View Project
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default JobDetails;
