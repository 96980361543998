import React from "react";

import Header from "../../../layout/Header";

import TeamLeaderList from "../../HR/Reports/TrainingReports/TeamLeaderList";

const EmployeeTraining = () => {
  return (
    <>
      <Header />

      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4">
            <div className="col-12">
              <h1 className="text-2xl font-bold">Training Courses</h1>
            </div>
          </div>
          <TeamLeaderList />
        </div>
      </div>
    </>
  );
};

export default EmployeeTraining;
