import React, { useEffect, useState } from "react";
import { Form, Input, Button, Typography, Card, Switch, Row, Col } from "antd";
import {
  candidateAssesmentById,
  candidateAssesment,
  jobRequest,
} from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import dayjs from "dayjs";

const { TextArea } = Input;
const { Title } = Typography;

const CAFComponent = ({ jobRequestId, position, designation }) => {
  console.log("this is position and designation ->", position, designation);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [cafData, setCafData] = useState(null);
  const [editing, setEditing] = useState(false);
  const [id, setId] = useState();

  // Initialize states with props
  const [position1, setPosition1] = useState(position || "");
  const [designation1, setDesignation1] = useState(designation || "");

  // Separate states for each field
  const [req1, setReq1] = useState("");
  const [req2, setReq2] = useState("");
  const [req3, setReq3] = useState("");
  const [req4, setReq4] = useState("");
  const [req5, setReq5] = useState("");
  const [req6, setReq6] = useState("");
  const [req7, setReq7] = useState("");
  const [hobCon1, setHobCon1] = useState("");
  const [hobCon2, setHobCon2] = useState("");
  const [hobCon3, setHobCon3] = useState("");
  const [hobCon4, setHobCon4] = useState("");

  // Add useEffect to update states when props change
  useEffect(() => {
    setPosition1(position);
    setDesignation1(designation);

    // Update form values
    form.setFieldsValue({
      position: position,
      designation: designation,
    });
  }, [position, designation, form]);

  useEffect(() => {
    const fetchAllData = async () => {
      try {
        const response = await axiosInstance.get(
          `${candidateAssesment}?job_request=${jobRequestId}`
        );
        console.log("Fetched assessment data:", response.data);

        if (
          response.data &&
          response.data.results &&
          response.data.results.length > 0
        ) {
          const data = response.data.results[0];
          console.log("CAF data:", data);
          setCafData(data);
          setId(data.id);

          setReq1(data.required_1 || "");
          setReq2(data.required_2 || "");
          setReq3(data.required_3 || "");
          setReq4(data.required_4 || "");
          setReq5(data.required_5 || "");
          setReq6(data.required_6 || "");
          setReq7(data.required_7 || "");
          setHobCon1(data.connect_fit_1 || "");
          setHobCon2(data.connect_fit_2 || "");
          setHobCon3(data.connect_fit_3 || "");
          setHobCon4(data.connect_fit_4 || "");

          // Set form values but keep position and designation from props
          form.setFieldsValue({
            position: position1,
            designation: designation1,
            required_1: data.required_1,
            required_2: data.required_2,
            required_3: data.required_3,
            required_4: data.required_4,
            required_5: data.required_5,
            required_6: data.required_6,
            required_7: data.required_7,
            connect_fit_1: data.connect_fit_1,
            connect_fit_2: data.connect_fit_2,
            connect_fit_3: data.connect_fit_3,
            connect_fit_4: data.connect_fit_4,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch CAF data");
      }
    };

    if (jobRequestId) {
      console.log("JobRequestId received:", jobRequestId);
      fetchAllData();
    }
  }, [jobRequestId, form, position1, designation1]);

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const data = {
        id: cafData.id,
        job_request: cafData.job_request,

        required_1: req1,
        required_2: req2,
        required_3: req3,
        required_4: req4,
        required_5: req5,
        required_6: req6,
        required_7: req7,
        connect_fit_1: hobCon1,
        connect_fit_2: hobCon2,
        connect_fit_3: hobCon3,
        connect_fit_4: hobCon4,
        created_at: cafData.created_at,
        updated_at: dayjs().format("YYYY-MM-DD"),
      };

      console.log("Submitting data:", data);

      if (id) {
        await axiosInstance.patch(candidateAssesmentById(id), data);
        console.log("passed");
        toast.success("CAF updated successfully");
      } else {
        await axiosInstance.post(candidateAssesment, data);
        toast.success("CAF submitted successfully");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      console.error("Error details:", error.response?.data);
      toast.error("Failed to submit CAF");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 1000, margin: "auto" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",

          marginBottom: 20,
          marginTop: 20,
        }}
      >
        <h3
          className="py-1 px-4 bg-black text-white"
          style={{ marginBottom: "30px" }}
        >
          Candidate Assessment Form (CAF) - First Interview Round
        </h3>
        <Button
          type="primary"
          onClick={() => setEditing(!editing)}
          style={{
            backgroundColor: "orange",
            borderColor: "orange",
            color: "#fff",
          }}
        >
          {editing ? "Save" : "Edit"}
        </Button>
      </div>

      <Form
        layout="vertical"
        form={form}
        onFinish={handleSubmit}
        style={{ fontWeight: "bold" }}
      >
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item label="HabotConnect Position" name="position">
              <Input
                disabled={true}
                value={position1}
                onChange={(e) => setPosition1(e.target.value)}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label="Portal Designation" name="designation">
              <Input
                disabled={true}
                value={designation1}
                onChange={(e) => setDesignation1(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>

        <span
          className="py-1 px-4 bg-black text-white"
          style={{ marginBottom: "40px", fontSize: "20px" }}
        >
          Can they give the required output or not?
        </span>

        <Form.Item name="required_1" style={{ marginTop: "40px" }}>
          <TextArea
            disabled={!editing}
            value={req1}
            onChange={(e) => setReq1(e.target.value)}
          />
        </Form.Item>

        <span
          className="py-1 px-4 bg-black text-white"
          style={{ fontSize: "20px" }}
        >
          HabotConnect fit
        </span>

        <Form.Item
          label="Name 2 leadership principles"
          name="connect_fit_1"
          style={{ marginTop: "40px" }}
        >
          <TextArea
            disabled={!editing}
            value={hobCon1}
            onChange={(e) => setHobCon1(e.target.value)}
          />
        </Form.Item>

        <Form.Item label="Name 2 HabotConnect values" name="connect_fit_2">
          <TextArea
            disabled={!editing}
            value={hobCon2}
            onChange={(e) => setHobCon2(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="One reason why you want this job"
          name="connect_fit_3"
        >
          <TextArea
            disabled={!editing}
            onChange={(e) => setHobCon3(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          label="One thing you will contribute to team performance"
          name="connect_fit_4"
        >
          <TextArea
            disabled={!editing}
            value={hobCon4}
            onChange={(e) => setHobCon4(e.target.value)}
          />
        </Form.Item>

        {editing && (
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={handleSubmit}
              style={{
                backgroundColor: "orange",
                borderColor: "orange",
                color: "#fff",
                marginRight: 10,
              }}
            >
              Save
            </Button>
            <Button
              onClick={() => setEditing(false)}
              style={{
                backgroundColor: "gray",
                borderColor: "gray",
                color: "#fff",
              }}
            >
              Close
            </Button>
          </div>
        )}
      </Form>
    </div>
  );
};

export default CAFComponent;
