import React, { useState } from "react";
import { Form, Row, Col, Table } from "react-bootstrap";
import { Layout, Typography, Button } from "antd";
import Header1 from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import "./career.css";
import Modals from "./CareerPage2Comp/Modals";
import Navbar from "./Navbar";
const { Header, Content } = Layout;
const { Title, Text } = Typography;

function CareerPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isApplyModalOpen, setIsApplyModalOpen] = useState(false);

  return (
    <>
      <Header1 />
      <AdminSideBar />
      <div className="page-wrapper ">
        <div className="content container-fluid position-relative">
          <Navbar />
          <div className="card-career">
            <div className="d-flex justify-content-between align-items-center">
              <h1 className="mb-3">UI/UX DESIGNER </h1>
              <div>
                <Button
                  className="px-5 mx-1 text-white"
                  style={{ backgroundColor: "#FD705C" }}
                >
                  Download
                </Button>
                <Button
                  variant="secondary"
                  className="px-5 text-white"
                  style={{ backgroundColor: "#47617C" }}
                  onClick={() => setIsApplyModalOpen(true)}
                >
                  Apply Now
                </Button>
              </div>
            </div>
            <h2>Project</h2>

            <h4 className="mt-3">Must Know</h4>
            <ul className="career-list">
              <li>
                Hiring at HabotConnect is a demanding process. Typically 1 out
                of 180+ candidates are accepted. You must show that you know
                your work.
              </li>
              <li>If you wish to be a HabotTech, you must know its:</li>
              <li>Values</li>
              <li>Leadership Principles</li>
            </ul>

            <h4 className="mt-3">Project Context</h4>
            <ul className="career-list">
              <li>
                A new product is being launched by way of a platform to bring
                together Buyers and Suppliers of service businesses in the
                United Arab Emirates
              </li>
            </ul>

            <h4 className="mt-3">You Will Be Assessed On</h4>
            <ul className="career-list">
              <li>Design format </li>
              <li>Research related to the design</li>
              <li>User journey with optimized UI Design</li>
            </ul>

            <h4 className="mt-3">You are Expected To Do</h4>
            <div>
              {" "}
              Read the attached Creative brief and find 8 visuals pictures, ads,
              text that convey the message asked for in the above brief What
              kind of time would you give the creative team to come up with the
              creatives.
            </div>

            <h4 className="mt-3">The Presentation Is To Be</h4>
            <ul className="career-list">
              <li>
                Present each of the above using a Powerpoint or Google Slides
                type presentation.
              </li>
              <li>Explain your answers to the above</li>
            </ul>

            <h4 className="mt-3">Suggestions</h4>
            <ul className="career-list">
              <li>The project should take you 4 to 8 hrs to complete.</li>
              <li>
                You are expected to use outside resources to help you understand
                what is required
              </li>
              <li>
                Use this opportunity to find out whether you want to work with
                HabotConnect
              </li>
              <li>
                Show us that you can do what is required by us without
                supervision
              </li>
              <li>No company resource person will be available</li>
            </ul>

            <div className="container mt-4">
              <table className="table-with-spacing">
                <tbody>
                  <tr>
                    <td className="table-left">
                      <strong>When</strong>
                    </td>
                    <td className="table-right">
                      You will present your completed project during the Project
                      interview
                    </td>
                  </tr>
                  <tr>
                    <td className="table-left">
                      <strong>Where</strong>
                    </td>
                    <td className="table-right">
                      Video call presentation via Google Meet. You will receive
                      a calendar invite with the date and time from our HR team
                      members.
                    </td>
                  </tr>
                  <tr>
                    <td className="table-left">
                      <strong>Who</strong>
                    </td>
                    <td className="table-right">
                      You will be presenting to the panel members which may
                      include CEO, Team Lead, and HR team members.
                    </td>
                  </tr>
                  <tr>
                    <td className="table-left">
                      <strong>How</strong>
                    </td>
                    <td className="table-right">
                      It is assumed that you will let us know if you are unable
                      to do the required presentation and that you no longer
                      wish to continue with the interview process at
                      HabotConnect. Slide presentation with links to other
                      documents or sheets if required. Max of 15 slides.
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <h4 className="mt-3">Additional HabotConnect Resource links</h4>
            <ul className="career-list">
              <li>Leadership Principle Links</li>
              <li>Habot Value links</li>
            </ul>

            <Button
              variant="secondary"
              className="px-5 text-white"
              style={{ backgroundColor: "#47617C", margin: "20px 0px" }}
              onClick={() => setIsApplyModalOpen(true)}
            >
              Apply Now
            </Button>
          </div>
          <Modals
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isLoginModalOpen={isLoginModalOpen}
            setIsLoginModalOpen={setIsLoginModalOpen}
            setIsApplyModalOpen={setIsApplyModalOpen}
            isApplyModalOpen={isApplyModalOpen}
          />
        </div>
      </div>
    </>
  );
}

export default CareerPage;
