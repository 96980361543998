import React, { useState } from "react";
import {
  Button,
  Input,
  Row,
  Col,
  DatePicker,
  Upload,
  Avatar,
  Typography,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import Header1 from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { MdFileUpload } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const { Text } = Typography;

const MyProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const navigate = useNavigate();

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleDataCollectionFormClick = () => {
    navigate("/data-collection-form");
  };

  return (
    <>
      <Header1 />
      <AdminSideBar />
      <div className="page-wrapper ">
        <div className="content container-fluid position-relative">
          <div style={{ padding: 20 }}>
            <Row gutter={[16, 16]}>
              <Col
                span={24}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <h2 style={{ margin: 0, color: "#1D2939" }}>My Profile</h2>
              </Col>
            </Row>
            <Row gutter={[16, 16]} style={{ marginTop: 16 }}>
              <Col
                span={24}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "40px",
                }}
              >
                <div
                  style={{
                    margin: "0px 10px",
                    color: "#1D2939",
                    fontSize: "1rem",
                    fontWeight: "bold",
                    borderBottom: "1px solid #E86F53",
                    paddingBottom: "8px",
                    width: "40%",
                  }}
                >
                  Basic Info
                </div>
                <div>
                  <Button
                    style={{ backgroundColor: "#E86F53", color: "white" }}
                    onClick={toggleEditMode}
                  >
                    {isEditing ? "Save" : "Edit"}
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#1D2939",
                      color: "white",
                      marginLeft: 8,
                    }}
                    onClick={handleDataCollectionFormClick}
                  >
                    Data Collection Form
                  </Button>
                </div>
              </Col>
              <Col span={24}>
                <Text
                  style={{
                    display: "block",
                    marginBottom: "20px",
                    color: "#1D2939",
                    fontSize: "1rem",
                    fontWeight: "500",
                    background: "#E6F7FF",
                    padding: "10px",
                    borderRadius: "4px",
                    border: "1px solid #91d5ff",
                  }}
                >
                  Please add the emergency contact details of either your
                  parents, spouse, siblings, or guardian.
                </Text>
              </Col>
              <Col span={8}>
                <label>Full Name</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="John Doe"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>Experience</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="5 years"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>Education</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="Bachelor's Degree in Computer Science"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>Country</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="United States"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>Email</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="johndoe@example.com"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>Mobile (With Country Code)</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="+1 234 567 8901"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>Gender</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="Male"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>Date of Birth</label>
                <DatePicker
                  disabled={!isEditing}
                  defaultValue={null}
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                />
              </Col>
              <Col
                span={8}
                style={{ display: "flex", alignItems: "center", gap: 10 }}
              >
                <label>Resume</label>
                <Upload>
                  <Button
                    icon={<MdFileUpload />}
                    style={{ backgroundColor: "black", color: "white" }}
                    disabled={!isEditing}
                  >
                    Resume
                  </Button>
                </Upload>
              </Col>
              <Col span={8}>
                <label>Do you own a company or work as a freelancer?</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="No"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>Have you completed your graduation?</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="Yes"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>
                  There will be a project presentation round. Will you be able
                  to complete the project and present it?
                </label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="Immediately"
                  disabled={!isEditing}
                />
              </Col>

              <Col span={8}>
                <label>How soon you can join?</label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="Immediately"
                  disabled={!isEditing}
                />
              </Col>
              <Col span={8}>
                <label>
                  Do you have your own laptop & internet connection bandwidth
                  that allows continuous audio and video usage?*
                </label>
                <Input
                  placeholder="Autofilled"
                  defaultValue="Immediately"
                  disabled={!isEditing}
                />
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProfile;
