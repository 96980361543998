import React, { useState, useEffect } from "react";
import Header from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import {
  Card,
  Row,
  Col,
  Typography,
  Spin,
  Statistic,
  Table,
  Avatar,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import { employeeDetails } from "../../../../../helpers/endpoints/api_endpoints";

const { Title } = Typography;

const EmployeeDataDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState(null);
  const [isHr, setIsHr] = useState(false);

  useEffect(() => {
    // Simulating API call with setTimeout
    setTimeout(() => {
      setDashboardData({
        totalEmployees: 150,
        averageProductivity: 85,
        completedTasks: 1250,
      });
      setIsLoading(false);
    }, 1000);
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then((response) => {
      setIsHr(
        response.data.employment_details[0].department_detail.name ===
          "Human Resource"
      );
    });
  }, []);

  const demoCostStatus = {
    totalRevenue: 0,
    totalCostTrack: 0,
    costStation: 0,
    actualCostBudget: 0,
    actualRevenueBudget: 0,
  };

  const columns = [
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
    },
    {
      title: "Owner Name",
      dataIndex: "ownerName",
      key: "ownerName",
    },
    {
      title: "Time to complete",
      dataIndex: "timeToComplete",
      key: "timeToComplete",
      render: (time) => `${time} hours`,
    },
  ];

  const data = [
    {
      key: "1",
      station: "-",
      ownerName: "-",
      task: "-",
      timeToComplete: "-",
    },
  ];

  const clockStatusColumns = [
    {
      title: "Station",
      dataIndex: "station",
      key: "station",
      width: "30%",
    },
    {
      title: "Station Clock",
      dataIndex: "stationClock",
      key: "stationClock",
    },
    {
      title: "Time to Accept Job",
      dataIndex: "timeToAccept",
      key: "timeToAccept",
    },
    {
      title: "Start Time",
      dataIndex: "start_time",
      key: "start_time",
    },
    {
      title: "End Time",
      dataIndex: "end_time",
      key: "end_time",
    },
    {
      title: "Time to complete",
      dataIndex: "timeToComplete",
      key: "timeToComplete",
      render: (time) => `${time} hours`,
    },
    {
      title: "Avg. Time",
      dataIndex: "timeToComplete",
      key: "timeToComplete",
      render: (time) => `${time} hours`,
    },
  ];

  // Dummy data for the Clock Status table
  const clockStatusData = [
    {
      key: "1",
      station: "-",
      stationClock: "-",
      timeToAccept: "-",
      task: "Assignee",
      timeToComplete: "-",
      start_time: "-",
      end_time: "-",
    },
  ];

  return (
    <>
      {isHr ? (
        <>
          <Header />
          <AdminSideBar />
        </>
      ) : (
        <></>
      )}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Title level={2}>Employee Data Dashboard</Title>
          {isLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card className="profile-card" style={{ height: "100%" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        height: "100%",
                      }}
                    >
                      <Avatar
                        size={64}
                        icon={<UserOutlined />}
                        style={{ marginBottom: "16px" }}
                      />
                      <Title level={4}>Monisha Paranthaman</Title>
                      <p>SubTrack Owner</p>
                    </div>
                  </Card>
                </Col>
                <Col span={16}>
                  <Card title="Current Station" className="station-card">
                    <Table
                      columns={columns}
                      dataSource={data}
                      pagination={false}
                      rowKey="key"
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={24}>
                  <Card title="Clock Status" className="clock-status-card">
                    <Table
                      columns={clockStatusColumns}
                      dataSource={clockStatusData}
                      pagination={false}
                      rowKey="key"
                    />
                  </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={24}>
                  <Card title="Cost Status" className="cost-status-card">
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Statistic
                          title="Total Revenue"
                          value={demoCostStatus.totalRevenue}
                          prefix="AED"
                          valueStyle={{ color: "#3f8600" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Total Cost/Track"
                          value={demoCostStatus.totalCostTrack}
                          prefix="AED"
                          valueStyle={{ color: "#cf1322" }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                      <Col span={12}>
                        <Statistic
                          title="Cost/Station"
                          value={demoCostStatus.costStation}
                          prefix="AED"
                          valueStyle={{ color: "#1890ff" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Actual Cost/Budget"
                          value={demoCostStatus.actualCostBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualCostBudget > 50
                                ? "#cf1322"
                                : "#3f8600",
                          }}
                        />
                      </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                      <Col span={24}>
                        <Statistic
                          title="Actual Revenue/Budget"
                          value={demoCostStatus.actualRevenueBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualRevenueBudget > 50
                                ? "#3f8600"
                                : "#cf1322",
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default EmployeeDataDashboard;
