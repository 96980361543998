import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import accountReducer from "./accountsReducer";
import jobsReducer from "./jobsReducer";
import hrReducer from "../store/HrReducer";

const reducers = combineReducers({
  account: persistReducer(
    {
      key: "account",
      storage,
      keyPrefix: "datta-",
    },
    accountReducer
  ),
  hr: hrReducer,
  jobsReducer,
});

export default reducers;
