import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  ziitopic,
  employeeDetails,
} from "../../../../../helpers/endpoints/api_endpoints";
import { Button, Modal, Form } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ZIITopicList = () => {
  const [show, setShow] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false); // New state to manage add/edit mode
  const [documentLists, setDocumentLists] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [editTopic, setEditTopic] = useState("");
  const [editUser, setEditUser] = useState("");
  const [editIsCompleted, setEditIsCompleted] = useState(false);
  const [employeeDetail, setEmployeeDetails] = useState(null);

  useEffect(() => {
    axiosInstance
      .get(ziitopic)
      .then((response) => setDocumentLists(response.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axiosInstance.get(employeeDetails).then(
      (response) => {
        console.log("User Info", response.data.employment_details);
        setEmployeeDetails(response.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }, []);

  const handleStatusChange = async (record) => {
    const newStatus = !record.is_completed;
    const updatedList = documentLists.map((item) =>
      item.id === record.id ? { ...item, is_completed: newStatus } : item
    );
    setDocumentLists(updatedList);

    try {
      await axiosInstance.patch(`${ziitopic}${record.id}/`, {
        is_completed: newStatus,
      });
      toast.success("Status updated successfully!");
    } catch (error) {
      console.error("There was an error updating the status!", error);
      toast.error("Error updating status!");
      // Revert the status in case of an error
      const revertedList = updatedList.map((item) =>
        item.id === record.id ? { ...item, is_completed: !newStatus } : item
      );
      setDocumentLists(revertedList);
    }
  };

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setEditTopic(record.topic);
    setEditUser(record.user_details.employee_name);
    setEditIsCompleted(record.is_completed);
    setIsEditMode(true);
    setShow(true);
  };

  const handleAdd = () => {
    setSelectedRecord(null);
    setEditTopic("");
    setEditUser(employeeDetail?.employee_name || "");
    setEditIsCompleted(false);
    setIsEditMode(false);
    setShow(true);
  };

  const handleSave = async () => {
    const newRecord = {
      topic: editTopic,
      user: employeeDetail?.employment_details[0]?.id,
      user_details: {
        user: employeeDetail?.user?.id,
        employee_id: employeeDetail?.employee_id,
        employee_name: editUser,
      },
      is_completed: editIsCompleted,
    };

    try {
      if (isEditMode) {
        const updatedList = documentLists.map((item) =>
          item.id === selectedRecord.id ? { ...item, topic: editTopic } : item
        );
        setDocumentLists(updatedList);
        await axiosInstance.put(`${ziitopic}${selectedRecord.id}/`, newRecord);
        toast.success("Topic edited successfully!");
      } else {
        const response = await axiosInstance.post(ziitopic, newRecord);
        setDocumentLists([...documentLists, response.data]);
        toast.success("Topic added successfully!");
      }
      setShow(false);
    } catch (error) {
      console.error("There was an error saving the topic!", error);
      toast.error("Error saving topic!");
    }
  };

  const handleClose = () => setShow(false);

  const columns = [
    {
      title: "S.No",
      dataIndex: "serial_number",
      key: "serial_number",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Topic",
      dataIndex: "topic",
      render: (text) => <div className="table-avatar">{text}</div>,
    },
    {
      title: "Created By",
      dataIndex: ["user_details", "employee_name"],
      render: (text) => <div className="table-avatar">{text}</div>,
    },
    {
      title: "Created At",
      dataIndex: "created",
      render: (created) => (
        <div className="table-avatar">{created.slice(0, 10)}</div>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updated",
      render: (updated) => (
        <div className="table-avatar">{updated.slice(0, 10)}</div>
      ),
    },
    {
      title: "Actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleEdit(record)}
            className="btn btn-sm btn-primary ms-1"
          >
            Edit
          </Button>
        </>
      ),
    },
    {
      title: "Status",
      render: (record) => (
        <Button
          className={`btn btn-sm ${
            record.is_completed ? "btn-success" : "btn-secondary"
          }`}
          onClick={() => handleStatusChange(record)}
        >
          {record.is_completed ? "Completed" : "Mark as Complete"}
        </Button>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="row mb-10">
        <div className="col-12">
          <div
            className="position-absolute"
            style={{ top: "30px", right: "20px" }}
          >
            <button
              type="button"
              className="btn btn-success"
              onClick={handleAdd}
            >
              Add Topic
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={documentLists}
            rowKey={(record) => record.id}
          />
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{isEditMode ? "Edit Topic" : "Add Topic"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="form-group row align-items-center">
                <label
                  className="col-lg-3 col-form-label"
                  style={{ paddingLeft: "10px" }}
                >
                  Topic
                </label>
                <div className="col-lg-9">
                  <input
                    type="text"
                    className="form-control"
                    value={editTopic}
                    onChange={(e) => setEditTopic(e.target.value)}
                    aria-label="Topic"
                  />
                </div>
              </div>
              {!isEditMode && (
                <div className="form-group row align-items-center mt-2">
                  <label
                    className="col-lg-3 col-form-label"
                    style={{ paddingLeft: "10px" }}
                  >
                    Created By
                  </label>
                  <div className="col-lg-9">
                    <input
                      type="text"
                      className="form-control"
                      value={editUser}
                      onChange={(e) => setEditUser(e.target.value)}
                      aria-label="User"
                      readOnly // Read-only since the created by should not be edited
                    />
                  </div>
                </div>
              )}
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default ZIITopicList;
