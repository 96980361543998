import React, { useState } from "react";
import {
  Table,
  Button,
  Select,
  message,
  Dropdown,
  Menu,
  Modal,
  Tabs,
  Card,
} from "antd";

import { useNavigate } from "react-router-dom";
import CAFModal from "./modals/CAFModal";
import ViewDetailsModal from "./modals/ViewDetailsModal";
import ShortlistedCandidateListProject from "./ShortlistedCandidateListProject";
import ShortlistedCandidateFinal from "./ShortlistedCandidateFinal";

const { Option } = Select;
const { TabPane } = Tabs;

const ShortListCandidatesTech = () => {
  const [activeTab, setActiveTab] = useState("tech");
  const [filter, setFilter] = useState("All");
  const [isModalVisible, setIsModalVisible] = useState(false); // State to control modal visibility
  const [modalContent, setModalContent] = useState(""); // State to control modal content (e.g., CAF or Details)
  const navigate = useNavigate();

  const scheduledColumns = [
    {
      title: "Candidate Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Interview Slot",
      dataIndex: "interviewSlot",
      key: "interviewSlot",
    },
    {
      title: "Availability",
      dataIndex: "availability",
      key: "availability",
    },
    {
      title: "CAF",
      key: "caf",
      render: () => (
        <Button
          style={{ background: "#374151", color: "white", border: "none" }}
          onClick={() => showModal("CAF")} // Open modal when CAF button is clicked
        >
          View
        </Button>
      ),
    },
    {
      title: "View Details",
      key: "details",
      render: () => (
        <Button
          style={{ background: "#374151", color: "white", border: "none" }}
          onClick={() => showModal("Details")} // Open modal when View Details button is clicked
        >
          View Details
        </Button>
      ),
    },
    {
      title: "View Resume",
      key: "resume",
      render: () => (
        <Button
          style={{ background: "#374151", color: "white", border: "none" }}
        >
          View Resume
        </Button>
      ),
    },
    {
      title: "Shortlisted",
      key: "shortlisted",
      render: () => (
        <Dropdown
          overlay={
            <Menu>
              <Menu.Item key="1">Yes</Menu.Item>
              <Menu.Item key="2">Remove from Shortlist</Menu.Item>
            </Menu>
          }
        >
          <Button>Yes</Button>
        </Dropdown>
      ),
    },
  ];

  const data = [
    {
      key: "1",
      name: "Anushka Srivastava",
      experience: "2 years",
      education: "B.Tech",
      email: "anushka@gmail.com",
      mobile: "+91 9876543210",
      scheduled: true,
      interviewSlot: "10:00 AM",
      availability: "Available",
      caf: "Yes",
      googleMeetLink: "https://meet.google.com/abc123",
      status: "Pending",
    },
    {
      key: "2",
      name: "Rahul Verma",
      experience: "1.5 years",
      education: "MCA",
      email: "rahul@gmail.com",
      mobile: "+91 8765432109",
      scheduled: false,
    },
    {
      key: "3",
      name: "Priya Sharma",
      experience: "3 years",
      education: "B.Sc IT",
      email: "priya@gmail.com",
      mobile: "+91 7654321098",
      scheduled: true,
      interviewSlot: "02:00 PM",
      availability: "Available",
      caf: "No",
      googleMeetLink: "https://meet.google.com/xyz789",
      status: "Confirmed",
    },
  ];

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  // Filter data based on the selected option
  const filteredData = data.filter((item) => {
    if (filter === "Scheduled") {
      return item.scheduled;
    }
    if (filter === "Not Scheduled") {
      return !item.scheduled;
    }
    return true; // Show all candidates for the "All" option
  });

  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        message.success("Google Meet link copied to clipboard!");
      })
      .catch((error) => {
        message.error("Failed to copy the link");
      });
  };

  // Function to show the modal
  const showModal = (content) => {
    setModalContent(content); // Set the content for the modal (CAF or Details)
    setIsModalVisible(true); // Show the modal
  };

  // Function to handle modal close
  const handleCancel = () => {
    setIsModalVisible(false); // Close the modal
  };

  return (
    <>
      <div style={{ maxWidth: "1200px", margin: "auto" }}>
        <Button
          style={{
            background: "#FE8147",
            color: "white",
            border: "none",
            marginBottom: "8px",
            marginTop: "20px",
          }}
        >
          Shortlisted Candidate List Table
        </Button>
        <p style={{ color: "gray", marginBottom: "16px" }}>
          View all jobs /{" "}
          <span style={{ fontWeight: "bold" }}>Shortlisted candidate</span>
        </p>

        <Table
          columns={scheduledColumns}
          dataSource={filteredData}
          pagination={false}
          bordered
          size="small"
          style={{ fontSize: "12px" }}
          rowClassName="hover-row"
        />

        {/* Modal for CAF or Details */}
        <Modal
          title={modalContent === "CAF" ? "CAF Details" : "Candidate Details"}
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={null}
          width={800}
        >
          <p>{modalContent === "CAF" ? <CAFModal /> : <ViewDetailsModal />}</p>
        </Modal>
      </div>
    </>
  );
};

export default ShortListCandidatesTech;
