import React, { useState, useEffect, useCallback } from "react";
import { Table, Button, Tag, Typography, Space, Tabs } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  separation,
  employeeList,
  employeeDetails,
  handover,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import moment from "moment";
import { FaUpload, FaExternalLinkAlt } from "react-icons/fa";
import ResignationLetter from "./ResignationLetter";
import TrackHandover from "./TrackHandOver";
import ViewHandoverForm from "./ViewHandoverForm";
import EditTrackHandover from "./EditTrackHandover";
import HandoverChecklist from "./Checklist";
import { Modal as BootstrapModal } from "react-bootstrap";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const getStatusColor = (status) => {
  switch (status) {
    case "Submission":
      return "orange"; // Pending state
    case "Reviewed":
      return "cyan"; // Initial review done
    case "Handover":
      return "blue"; // In handover process
    case "Handover Approval":
      return "purple"; // Waiting for final approval
    case "Approved":
      return "green"; // Final approved state
    case "Rejected":
      return "red"; // Rejected state
    default:
      return "default"; // Fallback color
  }
};

const ViewModal = React.memo(
  ({
    show,
    onHide,
    resignation,
    activeTabKey,
    setActiveTabKey,
    isEditing,
    setIsEditing,
    handleEditClick,
    handleSuccess,
    handoverData,
  }) => {
    const [externalContacts, setExternalContacts] = useState([]);

    useEffect(() => {
      if (handoverData?.handover_detail?.[0]?.external_contact) {
        setExternalContacts(handoverData.handover_detail[0].external_contact);
      }
    }, [handoverData]);

    return (
      <BootstrapModal
        show={show}
        onHide={onHide}
        size="xl"
        centered
        onExited={() => setIsEditing(false)}
      >
        {console.log("resignation", resignation)}
        <BootstrapModal.Header closeButton>
          <BootstrapModal.Title>
            Resignation Details
            {resignation && (
              <Tag
                color={getStatusColor(resignation.status)}
                style={{ marginLeft: "10px" }}
              >
                {resignation.status}
              </Tag>
            )}
          </BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body>
          {resignation && (
            <Tabs
              activeKey={activeTabKey}
              onChange={setActiveTabKey}
              style={{ marginTop: -15 }}
            >
              <TabPane tab="Resignation Letter" key="1">
                <Typography>
                  <Title level={4}>
                    {resignation.user_details.employee_name}'s Resignation
                  </Title>
                  <div
                    style={{
                      display: "flex",
                      gap: "24px",
                      marginBottom: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Paragraph style={{ margin: 0 }}>
                      <strong>Department:</strong>{" "}
                      {
                        resignation.user_details.employment_details[0]
                          .department_detail.name
                      }
                    </Paragraph>

                    <Paragraph style={{ margin: 0 }}>
                      <strong>Designation:</strong>{" "}
                      {
                        resignation.user_details.employment_details[0]
                          .designation_detail.name
                      }
                    </Paragraph>

                    <Paragraph style={{ margin: 0 }}>
                      <strong>Submitted Date:</strong>{" "}
                      {moment(resignation.created_at).format("DD-MM-YYYY")}
                    </Paragraph>
                  </div>

                  <Title level={5}>Resignation Letter:</Title>
                  <Paragraph style={{ whiteSpace: "pre-line" }}>
                    {resignation.resignation}
                  </Paragraph>

                  {resignation.signature && (
                    <>
                      <Title level={5}>Signature:</Title>
                      <div
                        style={{
                          maxWidth: "200px",
                          border: "1px solid #f0f0f0",
                          padding: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          src={resignation.signature}
                          alt="Signature"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </>
                  )}

                  {resignation.handover_details && (
                    <>
                      <Title level={5}>Employee Taking Over:</Title>
                      <div style={{ marginBottom: "20px" }}>
                        <Paragraph>
                          <strong>Name:</strong>{" "}
                          {resignation.handover_details.employee_name}
                        </Paragraph>
                        <Paragraph>
                          <strong>Department:</strong>{" "}
                          {
                            resignation.handover_details.employment_details[0]
                              ?.department_detail?.name
                          }
                        </Paragraph>
                        <Paragraph>
                          <strong>Designation:</strong>{" "}
                          {
                            resignation.handover_details.employment_details[0]
                              ?.designation_detail?.name
                          }
                        </Paragraph>
                        {resignation.handover_date && (
                          <Paragraph>
                            <strong>Handover Date:</strong>{" "}
                            {moment(resignation.handover_date).format(
                              "DD-MM-YYYY"
                            )}
                          </Paragraph>
                        )}
                      </div>
                    </>
                  )}
                  {resignation.acceptance_letter && (
                    <div className="mt-3">
                      <Title level={5}>Acceptance Letter:</Title>
                      <Button
                        variant="outline-primary"
                        onClick={() =>
                          window.open(resignation.acceptance_letter, "_blank")
                        }
                        className="d-flex align-items-center gap-2"
                      >
                        <FaExternalLinkAlt /> View Acceptance Letter
                      </Button>
                    </div>
                  )}

                  {resignation.is_approved && (
                    <>
                      <Title level={5}>Approval Details:</Title>
                      <Paragraph>
                        <strong>Approved Date:</strong>{" "}
                        {moment(resignation.approved_date).format("DD-MM-YYYY")}
                      </Paragraph>
                    </>
                  )}
                  {resignation.remarks && (
                    <Paragraph>
                      <strong>Remarks:</strong> {resignation.remarks}
                    </Paragraph>
                  )}
                  {resignation.comments && (
                    <Paragraph>
                      <strong>Comments:</strong> {resignation.comments}
                    </Paragraph>
                  )}
                </Typography>
              </TabPane>

              <TabPane tab="Handover Form" key="2">
                {isEditing ? (
                  <EditTrackHandover
                    resignationId={handoverData?.id}
                    onClose={() => setIsEditing(false)}
                    onSuccess={handleSuccess}
                    existingTracks={handoverData?.handover_detail || []}
                    employeeEdit={true}
                    externalContacts={
                      handoverData?.handover_detail?.[0]?.external_contact || []
                    }
                  />
                ) : (
                  <div>
                    {resignation.status === "Handover" ? (
                      <>
                        {!resignation.is_handover ? (
                          <TrackHandover
                            resignationId={resignation.id}
                            onClose={onHide}
                            onSuccess={() => {
                              handleSuccess();
                              onHide();
                            }}
                          />
                        ) : (
                          <>
                            <ViewHandoverForm
                              separationId={resignation.id}
                              handoverData={handoverData}
                            />
                            <Button
                              type="primary"
                              onClick={handleEditClick}
                              style={{ marginTop: "16px" }}
                            >
                              Edit Handover Form
                            </Button>
                          </>
                        )}
                      </>
                    ) : (
                      <ViewHandoverForm
                        separationId={resignation.id}
                        handoverData={handoverData}
                      />
                    )}
                  </div>
                )}
              </TabPane>

              {(resignation?.status === "Handover Approval" ||
                resignation?.status === "Approved") && (
                <TabPane tab="Handover Checklist" key="3">
                  <Typography>
                    <Title level={5}>Checklist Items</Title>
                    <HandoverChecklist selectedResignation={resignation} />
                  </Typography>
                </TabPane>
              )}
            </Tabs>
          )}
        </BootstrapModal.Body>
      </BootstrapModal>
    );
  }
);

const ViewEmployeeResignation = () => {
  const [resignations, setResignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedResignation, setSelectedResignation] = useState(null);
  const [departmentID, setDepartmentID] = useState(null);
  const [handoverData, setHandoverData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
  });
  const [user, setUser] = useState(null);

  // Update the useEffect to fetch user details
  useEffect(() => {
    const fetchDepartmentID = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        const deptId = response.data.employment_details[0].department_detail.id;
        setDepartmentID(deptId);
        setUser(response.data.employment_details[0].id);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };

    fetchDepartmentID();
  }, []);

  // Update fetchResignations to include user filter
  const fetchResignations = async (page, pageSize) => {
    setLoading(true);
    try {
      const response = await axiosInstance.get(
        `${separation}?page=${page}&page_size=${pageSize}&user=${user}`
      );
      setResignations(response.data.results);
      setPagination({
        ...pagination,
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching resignations:", error);
      toast.error("Error fetching resignations");
    } finally {
      setLoading(false);
    }
  };

  // Update the useEffect for fetching resignations to depend on user
  useEffect(() => {
    if (departmentID && user) {
      fetchResignations(1, pagination.pageSize);
    }
  }, [departmentID, user]);

  const fetchHandoverData = useCallback(async (separationId) => {
    try {
      const response = await axiosInstance.get(
        `${handover}?separation=${separationId}`
      );
      setHandoverData(response.data.results[0]);
    } catch (error) {
      console.error("Error fetching handover data:", error);
      toast.error("Failed to fetch handover data");
    }
  }, []);

  useEffect(() => {
    if (selectedResignation) {
      fetchHandoverData(selectedResignation.id);
    }
  }, [selectedResignation, fetchHandoverData]);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchResignations(newPagination.current, newPagination.pageSize);
  };

  const handleView = useCallback((record) => {
    setSelectedResignation(record);
    setActiveTabKey("1");
    setViewModalVisible(true);
  }, []);

  const handleViewHandover = useCallback((record) => {
    setSelectedResignation(record);
    setActiveTabKey("2");
    setViewModalVisible(true);
  }, []);

  const handleHandoverClick = useCallback((record) => {
    setSelectedResignation(record);
    setActiveTabKey("2");
    setViewModalVisible(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setViewModalVisible(false);
    setIsEditing(false);
    setSelectedResignation(null);
    setHandoverData(null);
  }, []);

  const handleEditClick = useCallback(() => {
    setIsEditing(true);
  }, []);

  const handleSuccess = useCallback(async () => {
    try {
      // Refresh handover data
      const response = await axiosInstance.get(
        `${handover}?separation=${selectedResignation.id}`
      );
      setHandoverData(response.data.results[0]);

      // Refresh resignation list
      await fetchResignations(pagination.current, pagination.pageSize);

      setIsEditing(false);
      toast.success("Handover form updated successfully");
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data");
    }
  }, [selectedResignation, pagination.current, pagination.pageSize]);

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      title: "Submitted Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Last Working Date",
      dataIndex: "last_working_date",
      key: "last_working_date",
      render: (date) => (date ? moment(date).format("DD-MM-YYYY") : "N/A"),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => handleView(record)}>
            View
          </Button>
          {record.status === "Handover" && record.is_handover && (
            <Button
              type="primary"
              onClick={() => handleViewHandover(record)}
              style={{ backgroundColor: "#FF9F45" }}
            >
              View Handover
            </Button>
          )}
          {record.status === "Handover" && !record.is_handover && (
            <Button
              type="primary"
              onClick={() => handleHandoverClick(record)}
              style={{ backgroundColor: "#FF9F45" }}
            >
              Fill Handover Form
            </Button>
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Resignations"
          title="Dashboard"
          subtitle="Resignations"
        />

        <div className="row">
          <div className="col-md-12">
            <Table
              columns={columns}
              dataSource={resignations}
              loading={loading}
              rowKey="id"
              pagination={{
                ...pagination,
                showTotal: (total) => `Total ${total} items`,
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>

        <ViewModal
          show={viewModalVisible}
          onHide={handleModalClose}
          resignation={selectedResignation}
          activeTabKey={activeTabKey}
          setActiveTabKey={setActiveTabKey}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
          handleEditClick={handleEditClick}
          handleSuccess={handleSuccess}
          handoverData={handoverData}
        />
      </div>
    </div>
  );
};

export default ViewEmployeeResignation;
