import React from "react";
import ReactDOM from "react-dom/client";
import AppRouter from "./Routes/Approuter";

import { ToastContainer } from "react-toastify";
import { ConfigProvider } from "antd";

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/scss/main.scss";
import "./assets/css/material.css";
import "react-toastify/dist/ReactToastify.css";
import { store } from "./store";
import { Provider } from "react-redux";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ConfigProvider
      theme={{
        token: {
          // Seed Token
          colorPrimary: "#FD715C",
          //colorPrimaryHover: "#FD715C",
          // Alias Token
          colorBgContainer: "#fffdf7",
        },
      }}
    >
      <Provider store={store}>
        <AppRouter />
        <ToastContainer
          position="top-right"
          theme="colored"
          autoClose={3000}
          width={100}
        />
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
);
