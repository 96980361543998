import { Modal, Form, Input, Button, Row, Col, Select, Tag } from "antd";
import { useEffect } from "react";

const { TextArea } = Input;

const accountTypeOptions = [
  { value: "administrative_expenses", label: "Administrative Expenses" },
  { value: "auditing_expenses", label: "Auditing Expenses" },
  {
    value: "employees_medical_insurance",
    label: "Employees Medical Insurance",
  },
  {
    value: "employees_visas_permits_ids",
    label: "Employees Visa's, Permits & ID's",
  },
  {
    value: "expenses_recover_singhania_clinic",
    label: "Expenses to Recover From Singhania Clinic",
  },
  { value: "license_and_permits", label: "License and Permits" },
  {
    value: "marketing_advertising_expenses",
    label: "Marketing & Advertising Expenses",
  },
  {
    value: "offshore",
    label: "Offshore",
  },
  {
    value: "freezone",
    label: "Freezone",
  },
  {
    value: "office_rent",
    label: "Office Rent",
  },

  {
    value: "online_platform_website_expenses",
    label: "Online Platform/Website Expenses",
  },
  { value: "other_expenses", label: "Other Expenses" },
  { value: "postage", label: "Postage" },
  {
    value: "software_infrastructure_expenses",
    label: "Software Infrastructure Expenses",
  },
  { value: "telephone_expenses", label: "Telephone Expenses" },
  { value: "training_costs", label: "Training Costs" },
  { value: "vendor_outsourcee_expense", label: "Vendor Outsourcee Expense" },
];

const FREQUENCIES = [
  { value: "one_time", label: "One Time" },
  { value: "monthly", label: "Monthly" },
  { value: "annually", label: "Annually" },
  { value: "on_demand", label: "On Demand" },
];

const CURRENCIES = [
  { value: "AED", label: "AED" },
  { value: "BDT", label: "BDT" },
  { value: "INR", label: "INR" },
  { value: "USD", label: "USD" },
];

const VendorRequestviewmodal = ({
  isVisible,
  onClose,
  vendorDetails,
  approvalhandler,
  val,
  valhandler,
  setSelectedvendor,
  gacluser,
  rsuser,
}) => {
  const [form] = Form.useForm();
  const isEditing = vendorDetails?.modalType === "edit";

  const handleSubmit = () => {
    form.validateFields().then(() => {
      approvalhandler(isEditing ? "edit" : "accept", vendorDetails.id);
    });
  };
  const formatText = (text) => {
    if (!text) return "";
    // Handle special characters and multiple underscores
    return text
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ")
      .replace(/\s+/g, " ") // Remove extra spaces
      .trim();
  };

  const handleFieldChange = (field, value) => {
    if (isEditing) {
      setSelectedvendor((prev) => ({
        ...prev,
        [field]: value,
      }));
    }
  };

  useEffect(() => {
    if (vendorDetails) {
      form.setFieldsValue({
        ...vendorDetails,
        account_type: formatText(vendorDetails.account_type),
        subscription_frequency: formatText(
          vendorDetails.subscription_frequency
        ),
      });
    }
  }, [vendorDetails, form]);

  const renderField = (name, label, options = null) => {
    const commonProps = {
      readOnly: !isEditing,
      style: {
        backgroundColor: isEditing ? "white" : "#f5f5f5",
        height: 62,
      },
    };

    return (
      <Form.Item label={label} name={name}>
        {options ? (
          isEditing ? (
            <Select
              options={options}
              value={vendorDetails[name]}
              onChange={(value) => handleFieldChange(name, value)}
              style={{ height: 62 }}
            />
          ) : (
            <Input {...commonProps} />
          )
        ) : (
          <Input
            {...commonProps}
            value={vendorDetails[name]}
            onChange={(e) => handleFieldChange(name, e.target.value)}
          />
        )}
      </Form.Item>
    );
  };

  // In the renderCommentSection function, add GACL STO comments display
  const renderCommentSection = () => {
    if (vendorDetails?.modalType === "edit") {
      return null;
    }

    const isGaclPending = vendorDetails.gacl_status === "PENDING";
    const isGaclRejected = vendorDetails.gacl_status === "REJECTED";
    const isGaclApproved = vendorDetails.gacl_status === "APPROVED";

    const isRsPending = vendorDetails.rs_status === "PENDING";
    const isRsRejected = vendorDetails.rs_status === "REJECTED";
    const isRsApproved = vendorDetails.rs_status === "APPROVED";

    const isGaclUser = gacluser;
    const isRsUser = rsuser;

    let showCommentBox = false;
    let commentValue = val || "";
    let isReadOnly = false;

    if (isGaclUser) {
      showCommentBox = true;

      if (isGaclPending) {
        isReadOnly = false;
        commentValue = val || vendorDetails.gacl_comments || "";
      } else if (isGaclRejected || isGaclApproved) {
        commentValue = vendorDetails.gacl_comments || "N/A";
        isReadOnly = true;
      }
    } else if (isRsUser && isGaclApproved) {
      showCommentBox = true;

      if (isRsPending) {
        isReadOnly = false;
        commentValue = val || vendorDetails.rs_comments || "";
      } else if (isRsRejected || isRsApproved) {
        commentValue = vendorDetails.rs_comments || "N/A";
        isReadOnly = true;
      }
    }

    return (
      <>
        {isRsUser && (
          <Col span={24}>
            <Form.Item label="Vendor Sto Comment">
              <TextArea
                rows={4}
                value={
                  vendorDetails.gacl_comments ||
                  "No comments have been added by Vendor STO  yet"
                }
                readOnly
                style={{
                  backgroundColor: "#f5f5f5",
                  cursor: "not-allowed",
                  color: vendorDetails.gacl_comments ? "inherit" : "#999",
                }}
              />
            </Form.Item>
          </Col>
        )}

        {showCommentBox && (
          <Col span={24}>
            <Form.Item
              label="Comments"
              rules={[
                {
                  required: false,
                  message: "Please add a comment before proceeding",
                },
              ]}
            >
              <TextArea
                rows={4}
                placeholder={isReadOnly ? "" : "Enter your comments"}
                value={isReadOnly ? commentValue : val}
                onChange={valhandler}
                readOnly={isReadOnly}
                style={{
                  backgroundColor: isReadOnly ? "#f5f5f5" : "white",
                  cursor: isReadOnly ? "not-allowed" : "text",
                }}
              />
            </Form.Item>
          </Col>
        )}
      </>
    );
  };

  const renderModalFooter = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "8px",
        width: "100%",
      }}
    >
      {/* Only show Update button when editing */}
      {isEditing ? (
        <Button
          key="submit"
          type="primary"
          onClick={handleSubmit}
          style={{
            backgroundColor: "#FD705C",
            width: 99,
            height: 39,
          }}
        >
          Update
        </Button>
      ) : (
        <>
          {(() => {
            const isGaclApproved = vendorDetails.gacl_status === "APPROVED";

            const isGaclUser = gacluser;
            const isRsUser = rsuser;

            // GACL User workflow
            if (isGaclUser && !isGaclApproved) {
              return vendorDetails.gacl_status === "PENDING" ? (
                <div>
                  <Button
                    key="accept"
                    type="primary"
                    onClick={() => approvalhandler("accept", vendorDetails.id)}
                    style={{
                      backgroundColor: "#FD705C",
                      width: 99,
                      height: 39,
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#2A363D",
                      color: "white",
                      width: 99,
                      height: 39,
                    }}
                    key="reject"
                    onClick={() => approvalhandler("Reject", vendorDetails.id)}
                  >
                    Reject
                  </Button>
                </div>
              ) : null;
            }
            // RS User workflow
            else if (isRsUser && isGaclApproved) {
              return vendorDetails.rs_status === "PENDING" ? (
                <div>
                  <Button
                    key="accept"
                    type="primary"
                    onClick={() => approvalhandler("accept", vendorDetails.id)}
                    style={{
                      backgroundColor: "#FD705C",
                      width: 99,
                      height: 39,
                    }}
                  >
                    Accept
                  </Button>
                  <Button
                    style={{
                      backgroundColor: "#2A363D",
                      color: "white",
                      width: 99,
                      height: 39,
                    }}
                    key="reject"
                    onClick={() => approvalhandler("Reject", vendorDetails.id)}
                  >
                    Reject
                  </Button>
                </div>
              ) : null;
            }
            return null;
          })()}
        </>
      )}
    </div>
  );

  if (!vendorDetails) return null;

  return (
    <div className="action">
      <Modal
        open={isVisible}
        onCancel={onClose}
        width={800}
        footer={renderModalFooter()}
      >
        <div
          style={{
            backgroundColor: "#f5f5f5",
            padding: "16px",
            borderRadius: "8px",
            marginBottom: "20px",
          }}
        >
          <div style={{ display: "flex", gap: "32px", flexWrap: "wrap" }}>
            {gacluser && (
              <div>
                <strong>Approval status-: </strong>
                <Tag
                  color={
                    vendorDetails?.gacl_status === "APPROVED"
                      ? "success"
                      : vendorDetails?.gacl_status === "PENDING"
                      ? "warning"
                      : vendorDetails?.gacl_status === "REJECTED"
                      ? "error"
                      : "default"
                  }
                >
                  {vendorDetails?.gacl_status || "PENDING"}
                </Tag>
              </div>
            )}
            {rsuser && (
              <div>
                <strong>Approval status-: </strong>
                <Tag
                  color={
                    vendorDetails?.rs_status === "APPROVED"
                      ? "success"
                      : vendorDetails?.rs_status === "PENDING"
                      ? "warning"
                      : vendorDetails?.rs_status === "REJECTED"
                      ? "error"
                      : "default"
                  }
                >
                  {vendorDetails?.rs_status || "PENDING"}
                </Tag>
              </div>
            )}
          </div>
        </div>

        <Form form={form} layout="vertical">
          <Row gutter={[16, 16]}>
            {/* Form Fields */}
            <Col xs={24} sm={12}>
              <Form.Item name="id" label="Vendor ID" hidden>
                <Input disabled hidden />
              </Form.Item>
              {renderField("vendor_name", "Name of Vendor/Supplier")}
              {renderField("currency", "Exchange Currency", CURRENCIES)}
              {renderField(
                "account_type",
                "Vendor Account Type",
                accountTypeOptions
              )}
            </Col>
            <Col xs={24} sm={12}>
              {renderField("amount", "Amount")}
              {renderField(
                "subscription_frequency",
                "Subscription Frequency",
                FREQUENCIES
              )}
              <Form.Item label="Description" name="description">
                <TextArea
                  value={vendorDetails.description}
                  onChange={(e) =>
                    handleFieldChange("description", e.target.value)
                  }
                  readOnly={!isEditing}
                  style={{
                    backgroundColor: isEditing ? "white" : "#f5f5f5",
                    height: 62,
                  }}
                  rows={2}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <div
                className="request-type-span"
                style={{
                  display: "flex",
                  gap: 4,
                  alignItems: "center",
                  marginBottom: 20,
                }}
              >
                <label style={{ fontSize: 15 }}>Request Type-:</label>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: vendorDetails?.is_urgent ? "red" : "green",
                    padding: "8px",
                    borderRadius: "4px",
                    width: "fit-content",
                  }}
                >
                  {vendorDetails?.is_urgent ? "Urgent" : "Normal"}
                </div>
              </div>
            </Col>

            {/* Urgency Reason Section */}
            {vendorDetails.urgency_reason && !isEditing && (
              <Col span={24}>
                <Form.Item label="Reason for Urgency" name="urgency_reason">
                  <TextArea
                    readOnly
                    style={{ backgroundColor: "#f5f5f5", height: 62 }}
                    rows={2}
                  />
                </Form.Item>
              </Col>
            )}

            {renderCommentSection()}
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default VendorRequestviewmodal;
