import React, { useState } from "react";
import { Row, Col, Button, Table, Typography, Upload, message } from "antd";
import sections from "./data";
const { Title, Text } = Typography;

const EmployeeContract = () => {
  const [currentSection, setCurrentSection] = useState(0);
  const [uploadedFile, setUploadedFile] = useState(null);

  const handleUpload = (info) => {
    if (info.file.status === "done") {
      message.success(`${info.file.name} uploaded successfully`);
      setUploadedFile(info.file);
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid position-relative">
        <div style={{ padding: 20 }}>
          <Row justify="space-between" style={{ marginBottom: 20 }}>
            <Col>
              <Button style={{ background: "#1E2B32", color: "#fff" }}>
                Employee Contract
              </Button>
            </Col>
            <Col>
              <Button type="primary" danger>
                Download
              </Button>
            </Col>
          </Row>
          <Text strong>Signed Contract</Text>
          <br />
          {/* File Upload Component */}
          <Upload
            beforeUpload={() => false} // Prevents automatic upload
            onChange={handleUpload}
            showUploadList={false} // Hides file list UI
          >
            <Button type="primary" danger style={{ margin: "10px 0" }}>
              Upload
            </Button>
          </Upload>

          {uploadedFile && <p>Uploaded File: {uploadedFile.name}</p>}
          <br />
          <Title level={3} style={{ textAlign: "center" }}>
            Agreement For Remote Working With HabotConnect DMCC
          </Title>
          <p style={{ textAlign: "center" }}>Page - {currentSection + 1}/7</p>
          {currentSection == 0 ? (
            <Text>
              Dear Mr./Ms. Name,
              <br />
              You are hereby accepted to work remotely for the HabotConnect on
              the following terms and conditions agreed and signed by both
              parties.
            </Text>
          ) : (
            ""
          )}
          <style>
            {`
                .custom-row {
                background-color: white !important;
                 }
  
           `}
          </style>

          <Table
            columns={[
              ...(sections[currentSection].title
                ? [
                    {
                      title: sections[currentSection].title, // Dynamically set table title
                      children: [
                        {
                          // Left column header
                          dataIndex: "label",
                          key: "label",
                          width: "50%",
                          render: (text) => (
                            <strong
                              style={{
                                wordBreak: "break-word", // ✅ Words wrap honge overflow hone se pehle
                                whiteSpace: "pre-wrap", // ✅ Newlines preserve rahenge aur text wrap bhi hoga
                                display: "block",
                              }}
                            >
                              {text}
                            </strong>
                          ),
                        },
                        {
                          // Right column header
                          dataIndex: "value",
                          key: "value",
                          width: "50%",
                          render: (text) => (
                            <div
                              style={{
                                wordBreak: "break-word", // ✅ Words wrap honge overflow hone se pehle
                                whiteSpace: "pre-wrap", // ✅ Newlines preserve rahenge aur text wrap bhi hoga
                                display: "block",
                              }}
                            >
                              {text}
                            </div>
                          ),
                        },
                      ],
                    },
                  ]
                : [
                    {
                      // Default headers if no section title
                      dataIndex: "label",
                      key: "label",
                      width: "50%",
                      render: (text) => (
                        <strong
                          style={{
                            wordBreak: "break-word", // ✅ Words wrap honge overflow hone se pehle
                            whiteSpace: "pre-wrap", // ✅ Newlines preserve rahenge aur text wrap bhi hoga
                            display: "block",
                          }}
                        >
                          {text}
                        </strong>
                      ),
                    },
                    {
                      dataIndex: "value",
                      key: "value",
                      width: "50%",
                      render: (text) => (
                        <div
                          style={{
                            wordBreak: "break-word", // ✅ Words wrap honge overflow hone se pehle
                            whiteSpace: "pre-wrap", // ✅ Newlines preserve rahenge aur text wrap bhi hoga
                            display: "block",
                          }}
                        >
                          {text}
                        </div>
                      ),
                    },
                  ]),
            ]}
            dataSource={sections[currentSection].data}
            pagination={false}
            bordered
            rowClassName={() => "custom-row"}
            style={{
              marginTop: 20,
            }}
            tableLayout="fixed"
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "30px 10px",
            }}
          >
            <p>Remote Outsourcee Initials</p>
            <p>Group’s Authorised Signatory’s Initials</p>
          </div>

          <Row
            justify=""
            style={{
              marginTop: 20,
              float: "right",
              display: "flex",
              gap: "10px",
              padding: "0px 0px 20px 0px",
            }}
          >
            <Col>
              {currentSection > 0 && (
                <Button onClick={() => setCurrentSection(currentSection - 1)}>
                  Back
                </Button>
              )}
            </Col>
            <Col>
              {currentSection < sections.length - 1 ? (
                <Button
                  type="primary"
                  onClick={() => setCurrentSection(currentSection + 1)}
                >
                  Next
                </Button>
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default EmployeeContract;
