import React, { useState, useEffect, useCallback } from "react";
import {
  Table,
  Button,
  Tag,
  Modal,
  Input,
  Checkbox,
  Form,
  Row,
  Col,
  Pagination,
  Space,
  Tabs, // Added Tabs from antd
} from "antd";
import { Typography } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  handover,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import { FaExternalLinkAlt } from "react-icons/fa";
import HandoverChecklist from "../../HR/Resignation/checkListPT";
import ViewHandoverForm from "./ViewHandoverForm";

const { Title, Paragraph } = Typography;

const ViewAssignedHandovers = () => {
  const [handovers, setHandovers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedHandover, setSelectedHandover] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [confirmationChecked, setConfirmationChecked] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [form] = Form.useForm();
  console.log(selectedHandover);
  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);
      const empId = response.data.employment_details[0].id;
      setEmployeeId(empId);
      fetchHandovers(1, empId);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      toast.error("Error fetching employee details");
    }
  };

  const fetchHandovers = async (page, empId = employeeId) => {
    if (!empId) return;

    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${handover}?handover_person=${empId}&page=${page}&page_size=${pagination.pageSize}`
      );
      setHandovers(response.data.results);
      setPagination({
        ...pagination,
        current: page,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching handovers:", error);
      toast.error("Error fetching handovers");
    } finally {
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["separation_details", "user_details", "employee_name"],
      key: "employee_name",
    },
    {
      title: "Department",
      dataIndex: [
        "separation_details",
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
    },
    {
      title: "Last Working Date",
      dataIndex: ["separation_details", "last_working_date"],
      key: "last_working_date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <Tag
          color={record.confirm_by_taking_over_person ? "success" : "warning"}
        >
          {record.confirm_by_taking_over_person ? "Reviewed" : "Pending Review"}
        </Tag>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => showHandoverDetails(record)}
          icon={<FaExternalLinkAlt />}
        >
          View Details
        </Button>
      ),
    },
  ];

  const showHandoverDetails = (record) => {
    setSelectedHandover(record);
    setIsModalVisible(true);
    setConfirmationChecked(record.confirm_by_taking_over_person || false);
  };

  const handleCheckboxChange = (e) => {
    setConfirmationChecked(e.target.checked);
  };

  const handleModalOk = async () => {
    if (!selectedHandover) return;

    try {
      await axiosInstance.patch(`${handover}${selectedHandover.id}/`, {
        confirm_by_taking_over_person: confirmationChecked,
      });
      toast.success("Handover confirmation updated successfully");
      fetchHandovers(pagination.current);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error updating confirmation:", error);
      toast.error("Error updating confirmation");
      setConfirmationChecked(false);
    }
  };

  const renderPlatformInfo = (platforms) => {
    if (!platforms || !Array.isArray(platforms) || platforms.length === 0) {
      return "No platform information available";
    }

    return platforms.map((platform, index) => (
      <div key={index} className="platform-card">
        <strong>Platform Name:</strong> {platform.name}
        <br />
        <strong>Username:</strong> {platform.username}
        <br />
        <strong>Password:</strong> {platform.password}
      </div>
    ));
  };

  const renderDocumentLinks = (documents) => {
    if (!documents || !Array.isArray(documents) || documents.length === 0) {
      return "No documents available";
    }

    return (
      <div className="table-responsive">
        <table className="table table-bordered mt-2">
          <thead>
            <tr>
              <th>Document Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {documents.map((doc, index) => (
              <tr key={index}>
                <td>{doc.name}</td>
                <td>
                  <Button
                    type="link"
                    icon={<FaExternalLinkAlt />}
                    onClick={() => window.open(doc.url, "_blank")}
                  >
                    View Document
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderTaskDetails = (task) => (
    <div key={task.id} className="border p-3 mb-3">
      <Title level={5}>Task: {task.name}</Title>

      {/* Training Information */}
      <div className="mb-3">
        <strong>Training Required:</strong>{" "}
        {task.is_training ? (
          <>
            Yes
            {task.training_link && (
              <div className="mt-2">
                <strong>Training Link:</strong>{" "}
                <Button
                  type="link"
                  icon={<FaExternalLinkAlt />}
                  onClick={() => window.open(task.training_link, "_blank")}
                >
                  View Training Material
                </Button>
              </div>
            )}
            {task.course_list && task.course_list.length > 0 && (
              <div className="mt-2">
                <strong>Course List:</strong>
                <ul>
                  {task.course_list.map((course, index) => (
                    <li key={index}>{course}</li>
                  ))}
                </ul>
              </div>
            )}
          </>
        ) : (
          <>
            No
            {task.not_reason && (
              <div className="mt-2">
                <strong>Reason:</strong> {task.not_reason}
              </div>
            )}
          </>
        )}
      </div>

      {/* Platform Information */}
      {task.platform && task.platform.length > 0 && (
        <div className="mb-3">
          <strong>Platform Details:</strong>
          {task.platform.map((p, index) => (
            <div key={index} className="platform-card mt-2">
              <strong>Platform Name:</strong> {p.name}
              <br />
              <strong>Username:</strong> {p.username}
              <br />
              <strong>Password:</strong> {p.password}
            </div>
          ))}
        </div>
      )}

      {/* Document Links */}
      {task.document_links && task.document_links.length > 0 && (
        <div className="mb-3">
          <strong>Documents:</strong>
          {renderDocumentLinks(task.document_links)}
        </div>
      )}

      {/* Assignee Information */}
      {task.assignee_details && (
        <div className="mt-3">
          <strong className="me-2">Assigned to:</strong>
          {task.assignee_details.employee_name}
          {task.is_reviewed && (
            <Tag color="green" className="ms-2">
              Reviewed
            </Tag>
          )}
        </div>
      )}
    </div>
  );

  const renderExternalContacts = () => {
    const contacts =
      selectedHandover?.handover_detail?.[0]?.external_contact || [];

    return (
      <div className="mb-4">
        <Title level={5}>External Contacts</Title>
        {contacts.map((contact, index) => (
          <div key={index} className="contact-card">
            <Row gutter={24}>
              <Col span={12}>
                <div className="mb-3">
                  <strong>Name:</strong> {contact.name}
                </div>
                <div className="mb-3">
                  <strong>Email:</strong> {contact.email}
                </div>
                <div className="mb-3">
                  <strong>Phone:</strong> {contact.phone || "N/A"}
                </div>
              </Col>
              <Col span={12}>
                <div className="mb-3">
                  <strong>Position:</strong> {contact.position}
                </div>
                <div className="mb-3">
                  <strong>Detail:</strong> {contact.detail}
                </div>
                <div className="mb-3">
                  <strong>Induction Required:</strong>{" "}
                  <Tag color={contact.is_induction ? "blue" : "default"}>
                    {contact.is_induction ? "Yes" : "No"}
                  </Tag>
                </div>
                {contact.is_induction && contact.intro_date && (
                  <div className="mb-3">
                    <strong>Introduction Date:</strong>{" "}
                    {moment(contact.intro_date).format("DD-MM-YYYY")}
                  </div>
                )}
              </Col>
            </Row>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Assigned Handovers"
          title="Dashboard"
          subtitle="Handovers"
        />

        <div className="card">
          <div className="card-body">
            <Table
              columns={columns}
              dataSource={handovers}
              rowKey="id"
              loading={loading}
              pagination={{
                ...pagination,
                onChange: (page) => fetchHandovers(page),
                showSizeChanger: true,
                showTotal: (total) => `Total ${total} items`,
              }}
              className="table-striped"
              scroll={{ x: true }}
            />
          </div>
        </div>

        <Modal
          title="Handover Details"
          open={isModalVisible}
          onOk={handleModalOk}
          onCancel={() => {
            setIsModalVisible(false);
            // Optionally clear the selected handover if needed:
            setSelectedHandover(null);
          }}
          width={1200}
          zIndex={1200}
          destroyOnClose
          footer={[
            <Button key="cancel" onClick={() => setIsModalVisible(false)}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={handleModalOk}
              disabled={selectedHandover?.confirm_by_taking_over_person}
            >
              Submit
            </Button>,
          ]}
        >
          <Tabs defaultActiveKey="handover">
            <Tabs.TabPane tab="Handover" key="handover">
              <div className="responsive-container">
                {/* Basic Information */}
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item label="Employee Name" name="employeeName">
                      <Input
                        disabled
                        value={
                          selectedHandover?.separation_details?.user_details
                            ?.employee_name
                        }
                        defaultValue={
                          selectedHandover?.separation_details?.user_details
                            ?.employee_name
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Employee ID" name="employeeId">
                      <Input
                        disabled
                        value={
                          selectedHandover?.separation_details?.user_details
                            ?.employee_id
                        }
                        defaultValue={
                          selectedHandover?.separation_details?.user_details
                            ?.employee_id
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item label="Department" name="department">
                      <Input
                        disabled
                        value={
                          selectedHandover?.separation_details?.user_details
                            ?.employment_details[0]?.department_detail?.name
                        }
                        defaultValue={
                          selectedHandover?.separation_details?.user_details
                            ?.employment_details[0]?.department_detail?.name
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Designation" name="designation">
                      <Input
                        disabled
                        value={
                          selectedHandover?.separation_details?.user_details
                            ?.employment_details[0]?.designation_detail?.name
                        }
                        defaultValue={
                          selectedHandover?.separation_details?.user_details
                            ?.employment_details[0]?.designation_detail?.name
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item label="Last Working Date" name="lastWorkingDate">
                      <Input
                        disabled
                        value={
                          selectedHandover?.separation_details
                            ?.last_working_date
                        }
                        defaultValue={
                          selectedHandover?.separation_details
                            ?.last_working_date
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="Handover Date" name="handoverDate">
                      <Input
                        disabled
                        value={
                          selectedHandover?.separation_details?.handover_date
                        }
                        defaultValue={
                          selectedHandover?.separation_details?.handover_date
                        }
                      />
                    </Form.Item>
                  </Col>
                </Row>

                {/* Approval Status Section
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    padding: "16px",
                    borderRadius: "8px",
                    marginBottom: "20px",
                  }}
                >
                  <Title
                    level={5}
                    style={{ marginTop: 0, marginBottom: "16px" }}
                  >
                    Approval Status
                  </Title>
                  <div
                    style={{ display: "flex", gap: "32px", flexWrap: "wrap" }}
                  >
                    <div>
                      <strong>Team Lead:</strong>{" "}
                      <Tag
                        color={
                          selectedHandover?.is_tl_approved ? "green" : "yellow"
                        }
                      >
                        {selectedHandover?.is_tl_approved
                          ? "Approved"
                          : "Pending"}
                      </Tag>
                    </div>
                    <div>
                      <strong>HR:</strong>{" "}
                      <Tag
                        color={
                          selectedHandover?.is_hr_approved ? "green" : "yellow"
                        }
                      >
                        {selectedHandover?.is_hr_approved
                          ? "Approved"
                          : "Pending"}
                      </Tag>
                    </div>
                  </div>
                </div>

                {/* External Contacts */}
                {/* {renderExternalContacts()} */}

                {/* Tasks Section */}
                {/* <Title level={5}>Tasks</Title>
                <div className="tasks-container">
                  {selectedHandover?.handover_detail?.map((track) => (
                    <div key={track.id} className="track-section mb-4">
                      <Title level={5}>
                        Track: {track.track_name}{" "}
                        <Tag
                          color={
                            track.track_status === "Completed"
                              ? "green"
                              : "orange"
                          }
                        >
                          {track.track_status}
                        </Tag>
                      </Title>
                      {track.tasks?.map((task) => renderTaskDetails(task))}
                    </div>
                  ))}
                </div> */}
                <ViewHandoverForm
                  separationId={selectedHandover?.separation_details?.id}
                />

                {/* Confirmation Checkbox */}
                <Form.Item name="confirmation">
                  <Checkbox
                    onChange={handleCheckboxChange}
                    checked={confirmationChecked}
                    disabled={selectedHandover?.confirm_by_taking_over_person}
                  >
                    I confirm that I have reviewed and understood all the
                    handover details
                    {selectedHandover?.confirm_by_taking_over_person &&
                      " (Already reviewed)"}
                  </Checkbox>
                </Form.Item>
              </div>
            </Tabs.TabPane>
            {(selectedHandover?.separation_details?.status ===
              "Handover Approval" ||
              selectedHandover?.separation_details?.status === "Approved") && (
              <Tabs.TabPane tab="Handover Checklist" key="empty">
                <Typography>
                  <Title level={5}>Checklist Items</Title>
                  {/* Add checklist items here */}
                  <HandoverChecklist
                    selectedResignation={selectedHandover?.separation_details}
                  />
                </Typography>
              </Tabs.TabPane>
            )}
          </Tabs>
          <style jsx>{`
            .responsive-container {
              width: 100%;
              max-width: 100%;
              overflow-x: hidden;
            }

            .platform-card {
              background: #fff;
              padding: 12px;
              border-radius: 6px;
              border: 1px solid #f0f0f0;
              margin-bottom: 12px;
            }

            .contact-card {
              background: #fff;
              padding: 16px;
              border-radius: 8px;
              margin-bottom: 16px;
              box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            }

            .table-responsive {
              overflow-x: auto;
            }

            @media (max-width: 768px) {
              .contact-card {
                padding: 12px;
              }
            }

            @media (max-width: 576px) {
              .contact-card {
                padding: 8px;
              }
            }
          `}</style>
        </Modal>
      </div>
    </div>
  );
};

export default ViewAssignedHandovers;
