import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Select,
  message,
  Image,
  Spin,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { handoverCheckList } from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

const { Option } = Select;

const HandoverChecklist = ({ selectedResignation }) => {
  console.log(selectedResignation);
  const [handOverChecklistData, setHandoverCheckListData] = useState(null);
  const [loading, setLoading] = useState(true); // Loading for data fetch
  const [submitting, setSubmitting] = useState(false); // Loading for form submission
  const [form] = Form.useForm();

  // Fetch Existing Data
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
      .then((response) => {
        console.log("Fetched Checklist Data:", response.data);
        setHandoverCheckListData(response.data);
      })
      .catch((error) => {
        console.error("Error fetching checklist:", error);
      })
      .finally(() => setLoading(false));
  }, [selectedResignation]);

  // Populate Form Fields with Fetched Data
  useEffect(() => {
    if (handOverChecklistData?.results?.length > 0) {
      const checklist = handOverChecklistData.results[0];
      form.setFieldsValue({
        status1: checklist.status_1 ? "Yes" : "No",
        status2: checklist.status_2 ? "Yes" : "No",
        status3: checklist.status_3 ? "Yes" : "No",
        status4: checklist.status_4 ? "Yes" : "No",
        status5: checklist.status_5 ? "Yes" : "No",
        status6: checklist.status_6 ? "Yes" : "No",
        status7: checklist.status_7 ? "Yes" : "No",
        notes1: checklist.comments1 ?? "",
        notes2: checklist.comments2 ?? "",
        notes3: checklist.comments3 ?? "",
        notes4: checklist.comments4 ?? "",
        notes5: checklist.comments5 ?? "",
        notes6: checklist.comments6 ?? "",
        notes7: checklist.comments7 ?? "",
      });
    }
  }, [handOverChecklistData, form]);

  // Modified Submit Handler
  const handleSubmit = (values) => {
    const checklistId = handOverChecklistData?.results?.[0]?.id;
    if (!checklistId) {
      message.error("Checklist record not found.");
      return;
    }

    // For TL mode: patch tl_signature and set submitted_by_tl = true
    if (selectedResignation?.is_tl) {
      if (!values.tl_signature || !values.tl_signature.originFileObj) {
        message.error("Team Leader's Signature is required!");
        return;
      }
      setSubmitting(true);
      const formData = new FormData();
      formData.append("tl_signature", values.tl_signature.originFileObj);
      formData.append("submitted_by_tl", "true");
      formData.append("submitted_by_employee_resigning", "true");
      console.log("TL Form Data Before Submission:");
      for (let pair of formData.entries()) {
        console.log(
          `${pair[0]}:`,
          pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
        );
      }

      axiosInstance
        .patch(`${handoverCheckList}${checklistId}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          toast.success("Signature uploaded successfully!");
          console.log("Response:", response.data);
          // Re-fetch checklist data to update UI; note that HR signature (if available) will be shown.
          axiosInstance
            .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
            .then((res) => {
              setHandoverCheckListData(res.data);
            })
            .catch((err) =>
              console.error("Error re-fetching checklist data:", err)
            );
        })
        .catch((error) => {
          message.error("Failed to upload signature.");
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));
    } else {
      // Non-TL mode: keep the existing behavior
      if (!values.person_signature || !values.person_signature.originFileObj) {
        message.error("TL's Signature is required!");
        return;
      }
      setSubmitting(true);
      const formData = new FormData();
      formData.append(
        "person_signature",
        values.person_signature.originFileObj
      );
      formData.append("submitted_by_employee_resigning", "true");

      console.log("Non-TL Form Data Before Submission:");
      for (let pair of formData.entries()) {
        console.log(
          `${pair[0]}:`,
          pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
        );
      }

      axiosInstance
        .patch(`${handoverCheckList}${checklistId}/`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          toast.success("Signature uploaded successfully!");
          console.log("Response:", response.data);
          axiosInstance
            .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
            .then((res) => {
              setHandoverCheckListData(res.data);
            })
            .catch((err) =>
              console.error("Error re-fetching checklist data:", err)
            );
        })
        .catch((error) => {
          message.error("Failed to upload signature.");
          console.error("Error:", error);
        })
        .finally(() => setSubmitting(false));
    }
  };

  const dynamicQuestions = [
    {
      question:
        "Have all the tracks that the resigned Habotech was the owner of been explained and handed over to the Habotech taking over?",
      statusName: "status1",
      notesName: "notes1",
    },
    {
      question:
        "Has the resigned Habotech completed giving the required training and knowledge transfer for the Habotech taking over?",
      statusName: "status2",
      notesName: "notes2",
    },
    {
      question:
        "Have the user IDs and passwords of all the platforms been shared by the Habotech leaving with the Habotech taking over?",
      statusName: "status3",
      notesName: "notes3",
    },
    {
      question:
        "Have ongoing tasks/projects been handed over with clear instructions?",
      statusName: "status4",
      notesName: "notes4",
    },
    {
      question:
        "Have all relevant documents, files, and resources been shared with the Habotech taking over?",
      statusName: "status5",
      notesName: "notes5",
    },
    {
      question:
        "Has an introduction been arranged between the new joined/taking over habotech and key internal and external contacts related to the tasks?",
      statusName: "status6",
      notesName: "notes6",
    },
    {
      question:
        "Has the habotech taking over been briefed on all pending actions or next steps that need immediate attention?",
      statusName: "status7",
      notesName: "notes7",
    },
  ];

  // 4. Upload props (unchanged)
  const uploadProps = {
    beforeUpload: (file) => {
      const isValid = file.type === "image/png" || file.type === "image/jpeg";
      if (!isValid) {
        message.error("You can only upload PNG or JPEG file!");
      }
      return isValid;
    },
    onRemove: () => {
      toast.success("File removed successfully!");
    },
  };

  return (
    <div
      style={{
        margin: "auto",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
      }}
    >
      <h3
        style={{
          backgroundColor: "#374151",
          color: "#FFFFFF",
          padding: "10px",
          borderRadius: "4px",
          marginBottom: "20px",
        }}
      >
        Handover Checklist
      </h3>
      <p>
        This checklist needs to be completed and signed by the team
        leader/supervisor in order for the resigned employee to receive the pay
        for the month and the experience certificate.
      </p>

      {loading ? (
        <Spin
          size="large"
          style={{ display: "block", textAlign: "center", margin: "20px 0" }}
        />
      ) : (
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          style={{ marginTop: "20px" }}
        >
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Name"
                name="name"
                initialValue={selectedResignation?.user_details?.employee_name}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Department"
                name="department"
                initialValue={
                  selectedResignation?.user_details?.employment_details[0]
                    ?.department_detail?.name
                }
              >
                <Input readOnly />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label="Designation"
                name="designation"
                initialValue={
                  selectedResignation?.user_details?.employment_details[0]
                    ?.designation_detail?.name
                }
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label="Last Working Day"
                name="lastWorkingDay"
                initialValue={selectedResignation?.last_working_date}
              >
                <Input readOnly />
              </Form.Item>
            </Col>
          </Row>
          {dynamicQuestions.map((item, index) => (
            <div key={index}>
              <h6 style={{ marginTop: "20px" }}>{item.question}</h6>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item label="Status" name={item.statusName}>
                    <Input
                      value={
                        form.getFieldValue(item.statusName) || "Loading..."
                      }
                      readOnly
                    />
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item label="Notes/Comments" name={item.notesName}>
                    <Input.TextArea
                      value={form.getFieldValue(item.notesName) || "Loading..."}
                      autoSize
                      readOnly
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          ))}
          {selectedResignation?.is_tl ? (
            <>
              <div className="d-flex align-items-center justify-content-around">
                <div>
                  {handOverChecklistData?.results?.[0]?.id &&
                    handOverChecklistData?.results?.[0]
                      ?.submitted_by_employee_taking === true &&
                    handOverChecklistData?.results?.[0]
                      ?.submitted_by_employee_resigning === true && (
                      <h3 style={{ marginTop: "20px" }}>HR's Signature</h3>
                    )}
                  {handOverChecklistData?.results?.[0]?.id &&
                    handOverChecklistData?.results?.[0]
                      ?.submitted_by_employee_taking === true &&
                    handOverChecklistData?.results?.[0]
                      ?.submitted_by_employee_resigning === true &&
                    (handOverChecklistData?.results[0]?.hr_signature ? (
                      <Image
                        width={300}
                        src={handOverChecklistData?.results[0]?.hr_signature}
                        preview={{
                          zIndex: 3000,
                          getContainer: document.body,
                        }}
                      />
                    ) : (
                      "Not Approved "
                    ))}
                </div>
                <div>
                  {handOverChecklistData?.results[0]?.tl_signature && (
                    <h3 style={{ marginTop: "20px" }}>TL Leaving Signature</h3>
                  )}
                  {handOverChecklistData?.results[0]?.tl_signature ? (
                    <Image
                      width={300}
                      src={handOverChecklistData?.results[0]?.tl_signature}
                      preview={{
                        zIndex: 3000,
                        getContainer: document.body,
                      }}
                    />
                  ) : (
                    <Form.Item
                      label={
                        handOverChecklistData?.results[0]?.tl_signature
                          ? "Reupload Team Leader's Signature"
                          : "Team Leader's Signature"
                      }
                      name="tl_signature"
                      valuePropName="file"
                      getValueFromEvent={(e) =>
                        e && e.fileList.length > 0 ? e.fileList[0] : null
                      }
                    >
                      <Upload
                        {...uploadProps}
                        maxCount={1}
                        beforeUpload={() => false}
                      >
                        <Button
                          icon={<UploadOutlined />}
                          disabled={submitting}
                          style={{
                            backgroundColor: "#374151",
                            borderColor: "#374151",
                            borderRadius: "4px",
                            color: "#fff",
                          }}
                        >
                          Upload Signature
                        </Button>
                      </Upload>
                    </Form.Item>
                  )}
                </div>
              </div>
              {handOverChecklistData?.results[0]
                ?.taking_over_person_signature ? (
                <>
                  <h3 style={{ marginTop: "20px" }}>
                    Taking Over Person Signature
                  </h3>
                  <Image
                    width={300}
                    src={
                      handOverChecklistData?.results[0]
                        ?.taking_over_person_signature
                    }
                    preview={{
                      zIndex: 3000,
                      getContainer: document.body,
                    }}
                  />
                </>
              ) : (
                "Not Approved by Person taking Over"
              )}

              {handOverChecklistData?.results[0]?.tl_signature ? (
                ""
              ) : (
                <Button type="primary" htmlType="submit" disabled={submitting}>
                  {submitting ? <Spin /> : "Submit"}
                </Button>
              )}
            </>
          ) : (
            <>
              <div className="d-flex align-items-center justify-content-around">
                <div>
                  {handOverChecklistData?.results[0]?.tl_signature ? (
                    <>
                      <h3 style={{ marginTop: "20px" }}>TL Signature</h3>
                      <Image
                        width={300}
                        src={handOverChecklistData?.results[0]?.tl_signature}
                        preview={{
                          zIndex: 3000,
                          getContainer: document.body,
                        }}
                      />
                    </>
                  ) : (
                    "Not Approved by TL"
                  )}
                  {handOverChecklistData?.results[0]?.tl_signature && (
                    <h3 style={{ marginTop: "20px" }}>
                      Employee Leaving Signature
                    </h3>
                  )}
                  {handOverChecklistData?.results[0]
                    ?.submitted_by_employee_resigning === false
                    ? handOverChecklistData?.results[0]?.tl_signature && (
                        <Form.Item
                          label="Employee's Signature"
                          name="person_signature"
                          rules={[
                            {
                              required: true,
                              message: "Please upload employee's signature",
                            },
                          ]}
                          valuePropName="file"
                          getValueFromEvent={(e) =>
                            e && e.fileList.length > 0 ? e.fileList[0] : null
                          }
                        >
                          <Upload maxCount={1} beforeUpload={() => false}>
                            <Button
                              icon={<UploadOutlined />}
                              disabled={submitting}
                            >
                              Upload Signature
                            </Button>
                          </Upload>
                        </Form.Item>
                      )
                    : handOverChecklistData?.results[0]?.person_signature && (
                        <Image
                          width={300}
                          src={
                            handOverChecklistData?.results[0]?.person_signature
                          }
                          preview={{
                            zIndex: 3000,
                            getContainer: document.body,
                          }}
                        />
                      )}
                </div>
                <div>
                  {handOverChecklistData?.results[0]
                    ?.taking_over_person_signature ? (
                    <>
                      <h3 style={{ marginTop: "20px" }}>
                        Taking Over Person Signature
                      </h3>
                      <Image
                        width={300}
                        src={
                          handOverChecklistData?.results[0]
                            ?.taking_over_person_signature
                        }
                        preview={{
                          zIndex: 3000,
                          getContainer: document.body,
                        }}
                      />
                    </>
                  ) : (
                    "Not Approved by person taking over "
                  )}
                  {handOverChecklistData?.results[0]?.hr_signature ? (
                    <>
                      <h3 style={{ marginTop: "20px" }}>HR Signature</h3>
                      <Image
                        width={300}
                        src={handOverChecklistData?.results[0]?.hr_signature}
                        preview={{
                          zIndex: 3000,
                          getContainer: document.body,
                        }}
                      />
                    </>
                  ) : (
                    "Not Approved by HR"
                  )}
                </div>
              </div>
              {handOverChecklistData?.results[0]
                ?.submitted_by_employee_resigning === false &&
                handOverChecklistData?.results[0]?.tl_signature && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    disabled={submitting}
                  >
                    {submitting ? <Spin /> : "Submit"}
                  </Button>
                )}
            </>
          )}
        </Form>
      )}
    </div>
  );
};

export default HandoverChecklist;
