import React, { useEffect, useState } from "react";
import { Button, Modal, Spinner } from "react-bootstrap";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import { vendorMtoAssignment } from "../../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

function ModalViewVendorDetails({ show, onHide, vendorId }) {
  const [vendorDetails, setVendorDetails] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVendorDetails = async () => {
      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `${vendorMtoAssignment}?vendor_profile_id=${vendorId}`
        );

        setVendorDetails(response.data.results[0]);
        console.log(vendorDetails);
      } catch (error) {
        console.error("Error fetching vendor data:", error);
        toast.error("Failed to fetch vendor data");
      } finally {
        setLoading(false);
      }
    };

    if (show) {
      fetchVendorDetails();
    }
  }, [show, vendorId]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body>
        {loading ? (
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "200px" }}
          >
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          </div>
        ) : (
          <div className="row d-flex justify-content-center p-3">
            {vendorDetails?.assigned_to_info?.length > 0 ? (
              <>
                <div className="col-md-6">
                  <label className="col-form-label">MTO E-mail ID</label>
                  {vendorDetails.assigned_to_info.map((assigned) => (
                    <div className="input-block mb-3" key={assigned.id}>
                      <input
                        type="text"
                        className="form-control"
                        value={assigned.email}
                        readOnly
                      />
                    </div>
                  ))}
                </div>

                <div className="col-md-6">
                  <label className="col-form-label">Status</label>
                  {vendorDetails.assigned_to_info.map((assigned) => (
                    <div className="input-block mb-3" key={assigned.id}>
                      <input
                        type="text"
                        className="form-control"
                        value={assigned.mto_status}
                        readOnly
                      />
                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-center">
                <p className="text-muted">No MTO status available</p>
              </div>
            )}
          </div>
        )}
        <span className="d-flex justify-content-center">
          <Button
            variant="secondary"
            size="sm"
            className="px-5 mb-2"
            onClick={onHide}
            disabled={loading}
          >
            Close
          </Button>
        </span>
      </Modal.Body>
    </Modal>
  );
}

export default ModalViewVendorDetails;
