import { useState, useEffect } from "react";
import { Card, Table, Button, message, Modal, Tag } from "antd";
import {
  vendorRequest,
  vendordocuments,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import VendorDetailViewModal from "./VendorDetailViewModal/VendorDetailViewModal";
import { useSelector } from "react-redux";
import GaclSideBar from "../../../layout/GaclSidebar";

const Myvendor = () => {
  const [loading, setLoading] = useState(false);
  const [vendorData, setVendorData] = useState([]);
  const [vendorDocuments, setVendorDocuments] = useState({});
  const [tl, setTl] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const [isCommentModalVisible, setIsCommentModalVisible] = useState(false);
  const [selectedComment, setSelectedComment] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const userInfo = useSelector((state) => state.account.userInfo);
  const isTeamLeaderFromGACL =
    userInfo?.employeeData.employment_details?.[0]?.designation_detail?.name ===
      "Team Leader" &&
    userInfo?.employeeData.employment_details?.[0]?.department_detail?.name ===
      "GACL";

  const fetchEmployeeDetails = async () => {
    try {
      const response = await axiosInstance.get(employeeDetails);

      setTl(response.data.user.id);
    } catch (error) {
      console.error("Error fetching employee details:", error);
      message.error("Failed to fetch employee details");
    }
  };

  const fetchVendorDocuments = async (vendorId) => {
    try {
      const response = await axiosInstance.get(
        `${vendordocuments}?vendor_request_id=${vendorId}`
      );
      return Array.isArray(response.data)
        ? response.data
        : Array.isArray(response.data.results)
        ? response.data.results
        : [];
    } catch (error) {
      console.error(`Error fetching documents for vendor ${vendorId}:`, error);
      return [];
    }
  };

  const fetchVendorData = async (page, pageSize) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `${vendorRequest}?team_lead=${tl}&page=${page}&page_size=${pageSize}`
      );
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
      const vendors = response.data.results;

      // Fetch documents for each vendor
      const documentsPromises = vendors.map(async (vendor) => {
        const documents = await fetchVendorDocuments(vendor.id);
        return { vendorId: vendor.id, documents };
      });

      const documentsResults = await Promise.all(documentsPromises);

      const documentsLookup = documentsResults.reduce((acc, curr) => {
        acc[curr.vendorId] = curr.documents;
        return acc;
      }, {});

      setVendorDocuments(documentsLookup);
      setVendorData(vendors);
    } catch (error) {
      console.error("Error fetching vendor data:", error);
      message.error("Failed to fetch vendor data");
      setVendorData([]);
      setVendorDocuments({});
    } finally {
      setLoading(false);
    }
  };

  const handleViewDetails = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };

  useEffect(() => {
    fetchEmployeeDetails();
  }, []);

  const handleTableChange = (newPagination, filters, sorter) => {
    fetchVendorData(newPagination.current, newPagination.pageSize);
  };

  useEffect(() => {
    if (tl) {
      fetchVendorData(pagination.current, pagination.pageSize);
    }
  }, [tl]);
  const DocumentButton = ({ documents }) => {
    const getFileName = (url) => {
      if (!url) return "Unknown File";
      const urlParts = url.split("/");
      let fileName = urlParts[urlParts.length - 1];
      fileName = fileName.split("?")[0];
      try {
        fileName = decodeURIComponent(fileName);
      } catch (e) {
        console.error("Error decoding filename:", e);
      }
      return fileName;
    };

    // SVG Icons as components
    const FileIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "10px", color: "#1890ff" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
        />
      </svg>
    );

    const FolderIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "8px" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M3 7v10a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-6l-2-2H5a2 2 0 00-2 2z"
        />
      </svg>
    );

    const ClickIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="16"
        height="16"
        stroke="currentColor"
        fill="none"
        style={{ marginLeft: "10px", color: "#52c41a" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    );

    const InfoIcon = () => (
      <svg
        viewBox="0 0 24 24"
        width="14"
        height="14"
        stroke="currentColor"
        fill="none"
        style={{ marginRight: "5px", color: "#666" }}
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
        />
      </svg>
    );

    const showDocumentList = () => {
      Modal.info({
        title: (
          <div
            style={{
              borderBottom: "1px solid #f0f0f0",
              padding: "8px 0",
              marginBottom: "16px",
              fontSize: "18px",
              color: "#34444C",
            }}
          >
            Available Documents
          </div>
        ),
        content: (
          <div>
            {documents.map((doc, index) => (
              <div
                key={index}
                style={{
                  margin: "10px 0",
                  display: "flex",
                  alignItems: "center",
                  padding: "12px",
                  border: "1px solid #e8e8e8",
                  borderRadius: "8px",
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  backgroundColor: "#ffffff",
                  boxShadow: "0 1px 2px rgba(0,0,0,0.05)",
                  ":hover": {
                    transform: "translateY(-1px)",
                    boxShadow: "0 3px 6px rgba(0,0,0,0.1)",
                  },
                }}
                onClick={() => window.open(doc.document, "_blank")}
              >
                <FileIcon />
                <div
                  style={{
                    flex: 1,
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <span
                    style={{
                      color: "#1890ff",
                      fontSize: "14px",
                      fontWeight: 500,
                      marginBottom: "2px",
                    }}
                  >
                    {doc.fileName || getFileName(doc.document)}
                  </span>
                  <span
                    style={{
                      color: "#8c8c8c",
                      fontSize: "12px",
                    }}
                  >
                    Click to open in new tab
                  </span>
                </div>
                <ClickIcon />
              </div>
            ))}
            <div
              style={{
                marginTop: "20px",
                padding: "12px",
                backgroundColor: "#f6ffed",
                borderRadius: "8px",
                fontSize: "13px",
                color: "#52c41a",
                display: "flex",
                alignItems: "center",
                border: "1px solid #b7eb8f",
              }}
            >
              <InfoIcon />
              Click on any document card to open it in a new tab
            </div>
          </div>
        ),
        width: 500,
        className: "document-modal",
        icon: null,
        okText: "Close",
        okButtonProps: {
          style: {
            backgroundColor: "#34444C",
            borderColor: "#34444C",
          },
        },
      });
    };

    const handleDocumentClick = () => {
      if (documents.length === 1) {
        // For single document, open directly
        window.open(documents[0].document, "_blank");
      } else {
        // For multiple documents, show modal
        showDocumentList();
      }
    };

    return (
      <Button
        type="primary"
        onClick={handleDocumentClick}
        style={{
          backgroundColor: "#34444C",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "0 16px",
          height: "30px",
          borderRadius: "6px",
          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        }}
      >
        {documents.length === 1 ? <FileIcon /> : <FolderIcon />}
        <span style={{ marginLeft: "4px" }}>
          {documents.length > 1
            ? `View All (${documents.length})`
            : "View Document"}
        </span>
      </Button>
    );
  };

  const columns = [
    {
      title: "Vendor Name",
      dataIndex: "vendor_name",
      key: "vendor_name",
      render: (vendor_name) => <span>{vendor_name}</span>,
    },

    {
      title: "Request Type",
      dataIndex: "is_urgent",
      key: "is_urgent",
      render: (isUrgent) => (
        <span
          style={{
            color: isUrgent ? "#ff4d4f" : "#52c41a",
            padding: "4px 8px",
            borderRadius: "4px",
          }}
        >
          {isUrgent ? "Urgent" : "Normal"}
        </span>
      ),
    },

    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => (
        <span>
          {date
            ? new Date(date).toLocaleDateString("en-GB", {
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
              })
            : "-"}
        </span>
      ),
    },

    {
      title: "Vendor STO Approval Status",
      dataIndex: "gacl_status",
      key: "gacl_status",
      render: (status) => {
        let color = "";
        let text = status || "PENDING";

        switch (text.toUpperCase()) {
          case "APPROVED":
            color = "success";
            break;
          case "PENDING":
            color = "warning";
            break;
          case "REJECTED":
            color = "error";
            break;
          default:
            color = "default";
        }

        return (
          <Tag color={color} style={{ minWidth: "80px", textAlign: "center" }}>
            {text.toUpperCase()}
          </Tag>
        );
      },
    },
    {
      title: "RS Sir Approval Status",
      dataIndex: "rs_status",
      key: "rs_status",
      render: (rs_status, record) => {
        const gacl_status = record.gacl_status?.toUpperCase();

        if (gacl_status === "REJECTED") {
          return (
            <Tag
              color="default"
              style={{ minWidth: "80px", textAlign: "center" }}
            >
              N/A
            </Tag>
          );
        }

        if (gacl_status === "APPROVED") {
          let color = "";
          let text = rs_status || "PENDING";

          switch (text.toUpperCase()) {
            case "APPROVED":
              color = "success";
              break;
            case "PENDING":
              color = "warning";
              break;
            case "REJECTED":
              color = "error";
              break;
            default:
              color = "default";
          }

          return (
            <Tag
              color={color}
              style={{ minWidth: "80px", textAlign: "center" }}
            >
              {text.toUpperCase()}
            </Tag>
          );
        }

        return (
          <Tag
            color="default"
            style={{ minWidth: "80px", textAlign: "center" }}
          >
            AWAITING VENDOR STO REVIEW
          </Tag>
        );
      },
    },

    {
      title: "View Details",
      key: "View",
      render: (_, record) => (
        <Button
          type="primary"
          style={{ backgroundColor: "#34444C" }}
          onClick={() => handleViewDetails(record)}
        >
          View Details
        </Button>
      ),
    },
    {
      title: "Document",
      dataIndex: "id",
      key: "documents",
      render: (vendorId) => {
        const documents = vendorDocuments[vendorId];

        if (!documents?.length) {
          return <span>No documents</span>;
        }

        return (
          <div style={{ marginBottom: "10px" }}>
            <DocumentButton documents={documents} />
          </div>
        );
      },
    },
  ];

  return (
    <>
      {isTeamLeaderFromGACL && <GaclSideBar />}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Card>
            <Table
              loading={loading}
              dataSource={vendorData}
              columns={columns}
              rowKey="id"
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} items`,
                pageSizeOptions: ["10", "20", "30", "50"],
              }}
              onChange={handleTableChange}
              scroll={{ x: "max-content" }}
              style={{
                overflowX: "auto",
                width: "100%",
              }}
            />

            {/* Modal to view the comments */}
            <Modal
              title="Comments"
              open={isCommentModalVisible}
              onCancel={() => setIsCommentModalVisible(false)}
              footer={[
                <Button
                  key="close"
                  onClick={() => setIsCommentModalVisible(false)}
                >
                  Close
                </Button>,
              ]}
            >
              <p>{selectedComment}</p>
            </Modal>
          </Card>

          <VendorDetailViewModal
            visible={isModalVisible}
            record={selectedRecord}
            onClose={() => {
              setIsModalVisible(false);
              setSelectedRecord(null);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Myvendor;
