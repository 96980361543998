import React, { useState, useEffect } from "react";
import {
  Card,
  Table,
  Button,
  Typography,
  Spin,
  Form,
  Input,
  Select,
  Checkbox,
  Row,
  Col,
  Divider,
} from "antd";
import { Modal as AntModal } from "antd";
import { Modal } from "react-bootstrap";
import { EyeOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  employeeQPA,
  employeeDetails,
  nomination,
  QPAform,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";
import { useNavigate } from "react-router-dom";

const { Title, Text } = Typography;
const { Option } = Select;

const ratingLabels = {
  1: "Watch finances daily & act to cut losses without remorse.",
  2: "Keep 'Thinking'- jobs in-house & outsource all 'Doing' jobs.",
  3: "Reconstruct & standardize complex tasks and processes including outsourcing, into simple elements & then go accomplish them.",
  4: "Continuously look for money-making opportunities for our platform.",
  5: "Keep an open mind & embrace the diversity of people, ideas & beliefs.",
  6: "Encourage & promote passion and accountability.",
  7: "The understanding of Habot's Vision & Values must be very clear to the whole team.",
  8: "Even if you disagree, express it, and commit to Habot's course while you are a HabotTech",
};

const exampleLabels = {
  3: "Describe how the Assessee has taken responsibility for his or her own work.Explain in 100 words",
  4: "A. What superpowers does the Assessee possess? Please list and explain his/her superpowers and abilities- write in total of 100 words",
  5: "B. What challenges does the Assessee face and in which type of situations? Please list and explain where Assessee struggles - write in total of 100 words",
  6: "What specific superpowers should the Assesssee be helped out with or provided with? Write in total 100 words, please",
  7: "What are 2 specific contributions made by the Assessee to advance towards becoming a 'high-performing engine'? (Write 100 words after you have understood the concept of 'engine')",
  8: "What stands out about the assessee (Use about 30-50 words)",
  9: "What are the assessee's strengths? (Use about 30-50 words)",
  10: "Comments on superpowers as mentioned above (Use about 30-50 words)",
  11: "What can the Assessee do to develop and grow - Write 100 words",
};

const validateDecimal = (_, value) => {
  if (!value) {
    return Promise.reject("Please enter a rating");
  }
  const num = parseFloat(value);
  if (isNaN(num) || num < 1 || num > 5) {
    return Promise.reject("Please enter a number between 1 and 5");
  }
  return Promise.resolve();
};

const QPASupervisorReport = () => {
  const [qpaData, setQPAData] = useState([]);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedQPA, setSelectedQPA] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isFormModalVisible, setIsFormModalVisible] = useState(false);
  const [selectedPrinciples, setSelectedPrinciples] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [employeeName, setEmployeeName] = useState("");

  const quarterOptions = ["Quarter 1", "Quarter 2", "Quarter 3", "Quarter 4"];

  // Fetch current employee details
  useEffect(() => {
    const fetchCurrentEmployee = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setCurrentEmployee(response.data);
        // After getting employee details, fetch their QPA
        fetchQPAData(response.data.employment_details[0].id);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };
    fetchCurrentEmployee();
  }, []);

  const fetchQPAData = async (employeeId, page = 1, pageSize = 10) => {
    try {
      const response = await axiosInstance.get(
        `${employeeQPA}?supervisor=${employeeId}&page=${page}&page_size=${pageSize}`
      );
      setQPAData(response.data.results);
      setPagination({
        current: page,
        pageSize: pageSize,
        total: response.data.count,
      });
    } catch (error) {
      console.error("Error fetching QPA data:", error);
      toast.error("Failed to fetch QPA data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleTableChange = (newPagination) => {
    fetchQPAData(
      currentEmployee?.employment_details[0].id,
      newPagination.current,
      newPagination.pageSize
    );
  };

  const handleViewForm = (record) => {
    // Allow viewing if form is completed (regardless of type)
    if (record.is_done) {
      // Open in new tab using window.open
      window.open(`/qpa-form/${record.id}`, "_blank");
    }
  };

  const columns = [
    {
      title: "Sr.No",
      key: "sr_no",
      render: (_, __, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: "user_details",
      key: "employee_name",
      render: (details) => details?.employee_name,
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => new Date(date).toLocaleDateString(),
    },

    {
      title: "Department",
      dataIndex: "user_details",
      key: "department",
      render: (details) =>
        details?.employment_details[0]?.department_detail?.name,
    },

    // {
    //   title: "Nominees",
    //   dataIndex: "nominations",
    //   key: "nominees",
    //   render: (nominations) => (
    //     <div>
    //       {nominations.map((nom, index) => (
    //         <div key={nom.id}>
    //           {nom.nominee_details?.employee_name}
    //           {index < nominations.length - 1 && ", "}
    //         </div>
    //       ))}
    //     </div>
    //   ),
    // },
    {
      title: "Status",
      key: "status",
      render: (_, record) => {
        const allCompleted = record.nominations.every((nom) => nom.is_done);
        const allAccepted = record.nominations.every((nom) => nom.is_accepted);

        if (allCompleted)
          return <span style={{ color: "#52c41a" }}>Completed</span>;
        if (allAccepted)
          return <span style={{ color: "#1890ff" }}>Accepted</span>;
        return <span style={{ color: "#faad14" }}>Pending</span>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => {
            setSelectedQPA(record);
            setIsModalVisible(true);
          }}
        >
          View Details
        </Button>
      ),
    },
  ];

  // Modal columns for nominees
  const nomineeColumns = [
    {
      title: "Nominee Name",
      dataIndex: ["nominee_details", "employee_name"],
      key: "name",
    },
    {
      title: "Department",
      dataIndex: ["nominee_details", "employment_details"],
      key: "department",
      render: (details) => details[0]?.department_detail?.name,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      render: (type) => type || "Not Specified",
    },
    {
      title: "Status",
      key: "status",
      render: (_, record) => (
        <span
          style={{
            color: record.is_done
              ? "#52c41a"
              : record.is_accepted
              ? "#1890ff"
              : "#faad14",
          }}
        >
          {record.is_done
            ? "Completed"
            : record.is_accepted
            ? "Accepted"
            : "Pending"}
        </span>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => {
        if (record.type === "Boss") {
          // Get the current QPA record
          const currentQPA = qpaData.find((qpa) =>
            qpa.nominations.some((nom) => nom.id === record.id)
          );

          console.log("Current QPA:", currentQPA);

          if (!currentQPA) return null;

          // Get nominations only from the current QPA
          const selfNomination = currentQPA.nominations.find(
            (nom) => nom.type === "Self"
          );
          const colleagueNominations = currentQPA.nominations.filter(
            (nom) => nom.type === "Colleague"
          );

          console.log("Self Nomination:", selfNomination);
          console.log(
            "Colleague Nominations (current QPA only):",
            colleagueNominations
          );
          console.log(
            "Number of Colleague nominations:",
            colleagueNominations.length
          );
          console.log(
            "Colleague statuses:",
            colleagueNominations.map((nom) => ({
              name: nom.nominee_details?.employee_name,
              is_done: nom.is_done,
            }))
          );

          const selfFormCompleted = selfNomination?.is_done === true;
          const allColleagueFormsCompleted =
            colleagueNominations.length > 0 &&
            colleagueNominations.every((nom) => nom.is_done === true);

          const canAccept = selfFormCompleted && allColleagueFormsCompleted;

          console.log("Can Accept:", canAccept);
          console.log("Self Form Completed:", selfFormCompleted);
          console.log(
            "All Colleague Forms Completed:",
            allColleagueFormsCompleted
          );

          if (!record.is_accepted) {
            return (
              <Button
                type="primary"
                disabled={!canAccept}
                onClick={() => handleAcceptBossNomination(record)}
                title={
                  !canAccept
                    ? `Pending reviews: ${!selfFormCompleted ? "Self" : ""} 
                   ${
                     !allColleagueFormsCompleted ? "All Colleagues" : ""
                   }`.trim()
                    : "Accept nomination"
                }
              >
                Accept
              </Button>
            );
          } else if (!record.is_done) {
            return (
              <Button
                type="primary"
                onClick={() => {
                  setIsModalVisible(false);
                  setTimeout(() => {
                    const parentQPA = qpaData.find((qpa) =>
                      qpa.nominations.some((nom) => nom.id === record.id)
                    );
                    setSelectedQPA(parentQPA);
                    setIsFormModalVisible(true);
                  }, 300);
                }}
              >
                Fill Form
              </Button>
            );
          } else {
            return (
              <Button type="primary" onClick={() => handleViewForm(record)}>
                View Form
              </Button>
            );
          }
        }

        // For non-Boss types
        return (
          <Button
            type="primary"
            disabled={!record.is_done}
            onClick={() => handleViewForm(record)}
            title={!record.is_done ? "Form not yet completed" : ""}
          >
            View Form
          </Button>
        );
      },
    },
  ];

  const handleAcceptBossNomination = async (record) => {
    try {
      await axiosInstance.patch(`${nomination}${record.id}/`, {
        is_accepted: true,
      });

      // Close the modal
      setIsModalVisible(false);

      // Refresh the data
      await fetchQPAData(
        currentEmployee?.employment_details[0].id,
        pagination.current,
        pagination.pageSize
      );

      toast.success("Boss nomination accepted successfully");
    } catch (error) {
      console.error("Error accepting boss nomination:", error);
      toast.error("Failed to accept nomination");
    }
  };

  const handleFormSubmit = async (values) => {
    console.log("Selected QPA:", selectedQPA);
    setIsSubmitting(true);
    try {
      if (!selectedQPA?.user || !currentEmployee?.employment_details[0]?.id) {
        throw new Error("Missing required data");
      }

      // Include checkbox values explicitly
      const formData = {
        ...values,
        user: selectedQPA.user,
        nominee: currentEmployee.employment_details[0].id,
        nomination_qpa: selectedQPA.nominations.find(
          (nom) => nom.type === "Boss"
        ).id,
        checkbox_1: selectedPrinciples[0] || "",
        checkbox_2: selectedPrinciples[1] || "",
        type: "Boss",
      };

      await axiosInstance.post(QPAform, formData);

      const bossNomination = selectedQPA.nominations.find(
        (nom) =>
          nom.type === "Boss" &&
          nom.nominee === currentEmployee.employment_details[0].id
      );

      if (!bossNomination) {
        throw new Error("Boss nomination not found");
      }

      // Update is_done status
      await axiosInstance.patch(`${nomination}${bossNomination.id}/`, {
        is_done: true,
      });

      toast.success("QPA form submitted successfully");

      // Close both modals
      setIsFormModalVisible(false);
      setIsModalVisible(false);

      // Reset form and principles
      form.resetFields();
      setSelectedPrinciples([]);

      // Refresh the data with the correct employeeId
      if (currentEmployee?.employment_details[0]?.id) {
        await fetchQPAData(
          currentEmployee.employment_details[0].id,
          pagination.current,
          pagination.pageSize
        );
      } else {
        throw new Error("Employee ID not found");
      }
    } catch (error) {
      console.error("Error submitting QPA form:", error);
      toast.error(error.response?.data?.message || "Failed to submit QPA form");
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add this function to handle principle selection
  const handlePrincipleSelection = (checkedValues) => {
    if (checkedValues.length <= 2) {
      setSelectedPrinciples(checkedValues);
      form.setFieldsValue({
        checkbox_1: checkedValues[0] || "",
        checkbox_2: checkedValues[1] || "",
      });
    }
  };

  const renderFormItems = () => {
    return (
      <Form
        form={form}
        layout="vertical"
        initialValues={{
          declaration: false,
          checkbox_1: "",
          checkbox_2: "",
          type: "Boss",
        }}
        onFinish={handleFormSubmit}
      >
        <Form.Item
          name="quarter"
          label="Quarter"
          rules={[{ required: true, message: "Please select quarter" }]}
        >
          <Select placeholder="Select quarter">
            {quarterOptions.map((quarter) => (
              <Option key={quarter} value={quarter}>
                {quarter}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="instance_item"
          label="What 3 specific instances can you provide of how the Assessee contributed to enhancing the team's performance? Please write actual cases in 50 words each."
          rules={[{ required: true, message: "Please provide instances" }]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>

        {/* Leadership Principles Rating Table */}
        <div style={{ marginBottom: 24 }}>
          <Text strong>
            Rate the Assessee on each of the following Leadership Principles,
            using a scale from 1 to 5.
          </Text>
          <Text type="secondary" style={{ display: "block" }}>
            [5 means the Assesssee fits the principle perfectly and 1 means the
            Assessee does not fit the principle at all]
          </Text>
        </div>

        <Table
          dataSource={[1, 2, 3, 4, 5, 6, 7, 8].map((num) => ({
            key: num,
            sl: num,
            principle: ratingLabels[num],
            rating: (
              <Form.Item
                name={`rating_${num}`}
                noStyle
                rules={[{ validator: validateDecimal }]}
              >
                <Input
                  placeholder="Enter rating (1-5)"
                  style={{ width: "100%", minWidth: 80, maxWidth: 120 }}
                />
              </Form.Item>
            ),
          }))}
          columns={[
            {
              title: "Sl No.",
              dataIndex: "sl",
              width: 60,
              fixed: "left",
            },
            {
              title: "Leadership Principles",
              dataIndex: "principle",
              render: (text) => (
                <div
                  style={{
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    minWidth: 200,
                  }}
                >
                  {text}
                </div>
              ),
            },
            {
              title: "Ratings",
              dataIndex: "rating",
              width: 130,
              fixed: "right",
            },
          ]}
          pagination={false}
          bordered
          scroll={{
            x: "max-content",
            y: undefined,
          }}
          style={{
            width: "100%",
            overflowX: "auto",
          }}
        />

        {/* Leadership Principles Selection */}
        <div style={{ marginBottom: 24, marginTop: 24 }}>
          <Text strong>
            A - Which two specific LPs did the Assessee focus on and excel at
            during the quarter under review?
          </Text>
        </div>

        <Form.Item>
          <Checkbox.Group
            style={{ display: "flex", flexDirection: "column", gap: "12px" }}
            onChange={handlePrincipleSelection}
            value={selectedPrinciples}
          >
            {Object.entries(ratingLabels).map(([key, value]) => (
              <Checkbox
                key={key}
                value={value}
                disabled={
                  selectedPrinciples.length >= 2 &&
                  !selectedPrinciples.includes(value)
                }
              >
                {value}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </Form.Item>

        {/* Hidden fields to store selected values */}
        <Form.Item name="checkbox_1" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="checkbox_2" hidden>
          <Input />
        </Form.Item>

        {/* Dynamic Example Questions based on selection */}
        {selectedPrinciples.length > 0 && (
          <div style={{ marginBottom: 24 }}>
            <Text strong>
              3B - About the above question A, write 1 example for each of the
              LPs chosen. About 50 words each
            </Text>
          </div>
        )}

        {selectedPrinciples[0] && (
          <Form.Item
            name="example_1"
            label={selectedPrinciples[0]}
            rules={[
              {
                required: true,
                message:
                  "Please provide an example for the first selected principle",
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Write about 50 words describing an example of this principle..."
            />
          </Form.Item>
        )}

        {selectedPrinciples[1] && (
          <Form.Item
            name="example_2"
            label={selectedPrinciples[1]}
            rules={[
              {
                required: true,
                message:
                  "Please provide an example for the second selected principle",
              },
            ]}
          >
            <Input.TextArea
              rows={4}
              placeholder="Write about 50 words describing an example of this principle..."
            />
          </Form.Item>
        )}

        {/* Additional Questions */}
        {Object.entries(exampleLabels).map(([key, label]) => (
          <Form.Item
            key={`example_${key}`}
            name={`example_${key}`}
            label={<Text>{label}</Text>}
            rules={[
              {
                required: true,
                message: `Please provide input for "${label}"`,
              },
            ]}
          >
            <Input.TextArea rows={3} />
          </Form.Item>
        ))}

        {/* Declaration */}
        <Form.Item
          name="declaration"
          valuePropName="checked"
          rules={[
            {
              validator: (_, value) =>
                value
                  ? Promise.resolve()
                  : Promise.reject(
                      new Error(
                        "Declaration is mandatory. Please accept it to proceed."
                      )
                    ),
            },
          ]}
          style={{ marginTop: "24px" }}
        >
          <Checkbox>
            <Text strong type="danger">
              *{" "}
            </Text>
            <Text>
              I declare that all the information provided above is true to the
              best of my knowledge
            </Text>
          </Checkbox>
        </Form.Item>
      </Form>
    );
  };

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <Title level={2}>QPA Supervisor Report</Title>
              </div>
            </div>
          </div>

          <Card>
            <Table
              dataSource={qpaData}
              columns={columns}
              rowKey="id"
              pagination={{
                ...pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
              }}
              onChange={handleTableChange}
              loading={isLoading}
            />
          </Card>

          {/* Details Modal */}
          <AntModal
            title="QPA Nomination Details"
            open={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={[
              <Button key="close" onClick={() => setIsModalVisible(false)}>
                Close
              </Button>,
            ]}
            width={900}
          >
            {selectedQPA && (
              <div>
                <Text>
                  Created on:{" "}
                  {new Date(selectedQPA.created_at).toLocaleDateString()}
                </Text>
                <div style={{ marginTop: "20px" }}>
                  <Table
                    dataSource={selectedQPA.nominations}
                    columns={nomineeColumns}
                    pagination={false}
                  />
                </div>
              </div>
            )}
          </AntModal>

          {/* Form Modal */}
          <Modal
            show={isFormModalVisible}
            onHide={() => {
              setIsFormModalVisible(false);
              form.resetFields();
            }}
            size="xl"
            backdrop="static"
            keyboard={false}
            style={{ zIndex: 1056 }}
          >
            <Modal.Header closeButton>
              <Modal.Title>QPA Form</Modal.Title>
            </Modal.Header>

            <Modal.Body style={{ maxHeight: "80vh", overflow: "auto" }}>
              {selectedQPA && selectedQPA.user_details && (
                <div>
                  <Row gutter={24} style={{ marginBottom: "20px" }}>
                    <Col span={12}>
                      <Text strong>Nominated By: </Text>
                      <Text>
                        {selectedQPA.user_details.employee_name || "N/A"}
                      </Text>
                    </Col>
                    <Col span={12}>
                      <Text strong>Created on: </Text>
                      <Text>
                        {selectedQPA.created_at
                          ? new Date(
                              selectedQPA.created_at
                            ).toLocaleDateString()
                          : "N/A"}
                      </Text>
                    </Col>
                  </Row>
                  {renderFormItems()}
                </div>
              )}
            </Modal.Body>

            <Modal.Footer>
              <Button
                onClick={() => {
                  setIsFormModalVisible(false);
                  form.resetFields();
                }}
              >
                Cancel
              </Button>
              <Button
                type="primary"
                onClick={() => form.submit()}
                loading={isSubmitting}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default QPASupervisorReport;
