import { useState } from "react";
import Header1 from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import Modals from "./Modals";
import {
  Button,
  Input,
  Tag,
  Card,
  Space,
  Row,
  Col,
  Layout,
  Typography,
} from "antd";
import Navbar from "../Navbar";

const { Header, Content } = Layout;
const { Title, Text } = Typography;

const jobListings = [
  { title: "Developer" },
  { title: "Technical Writer" },
  { title: "Technical Writer" },
  { title: "Technical Writer" },
];

function JobSearchResult() {
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);

  return (
    <>
      <Header1 />
      <AdminSideBar />

      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <Navbar />
          <Row gutter={24}>
            <Col
              span={24}
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "20px 0px 10px 0px",
              }}
            >
              <Space className="mb-6 sm:flex ">
                {[
                  "All",
                  "Development",
                  "Design",
                  "Marketing",
                  "Finance",
                  "Operations",
                ].map((category) => (
                  <button
                    key={category}
                    onClick={() => setSelectedCategory(category)}
                    contentFontSize={24}
                    shape="round"
                    style={{
                      backgroundColor: ` ${
                        selectedCategory === category ? "#47617C" : ""
                      }`,
                      color: ` ${selectedCategory === category ? "white" : ""}`,
                      fontSize: "16px",
                      padding: "6px 50px",
                      borderRadius: "20px",
                      border: "1px solid #47617C ",
                      margin: "0px 18px",
                    }}
                  >
                    {category}
                  </button>
                ))}
              </Space>
            </Col>
            <Col span={24}>
              <Card className="mb-6">
                <Row justify="space-between" align="middle" className="mb-4">
                  <Col>
                    <h3>Filters</h3>
                  </Col>
                  <Col>
                    <Button type="link">Clear All Filters</Button>
                  </Col>
                </Row>
                <Space direction="vertical" className="w-full">
                  <Row justify="space-between" align="middle" className="mb-4">
                    <Col>
                      <p>Job Category</p>
                      <Input
                        placeholder="Job Category"
                        style={{ width: "400px", marginRight: "30px" }}
                      />
                      <p style={{ marginTop: "30px" }}>Location</p>
                      <Row gutter={8}>
                        {["On-Site", "Hybrid", "Remote"].map((loc) => (
                          <Col key={loc}>
                            <Button shape="round">{loc}</Button>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                    <Col>
                      <p>Job Title</p>
                      <Input
                        placeholder="Job Title"
                        style={{ width: "400px" }}
                      />
                      <p style={{ marginTop: "30px" }}>Employement Type</p>
                      <Row gutter={8}>
                        {["Full time", "Part time", "Internship"].map(
                          (type) => (
                            <Col key={type}>
                              <Button shape="round">{type}</Button>
                            </Col>
                          )
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <Button
                    style={{
                      backgroundColor: "#FD705C",
                      color: "white",
                      padding: "0px 30px",
                    }}
                  >
                    Search
                  </Button>
                </Space>
              </Card>
            </Col>
            {jobListings.map((job, index) => (
              <Col span={24} key={index}>
                <Card className="mb-4 border border-blue-500">
                  <Row justify="space-between" align="middle">
                    <Col>
                      <h3 className="text-lg font-semibold">{job.title}</h3>
                      <Space style={{ marginTop: "20px" }}>
                        <Tag shape="round">Full time</Tag>
                        <Tag>1-2yrs Exp.</Tag>
                        <Tag>₹20000 - ₹30000</Tag>
                      </Space>
                    </Col>
                    <Col>
                      <Button
                        style={{
                          backgroundColor: "#47617C",
                          color: "white",
                          padding: "0px 30px",
                        }}
                        shape="round"
                      >
                        View
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>
          <Modals
            isModalOpen={isModalOpen}
            setIsModalOpen={setIsModalOpen}
            isLoginModalOpen={isLoginModalOpen}
            setIsLoginModalOpen={setIsLoginModalOpen}
          />
        </div>
      </div>
    </>
  );
}

export default JobSearchResult;
