import React from "react";
import { Col, DatePicker, Select } from "antd";

const CandidateSignup = () => {
  return (
    <div style={{ maxWidth: "600px", margin: "auto", textAlign: "center" }}>
      <h2 style={{ fontWeight: "", marginBottom: "30px", fontSize: "20px" }}>
        Hi, welcome to our career page! Before signing up, make sure to add all
        the necessary details
      </h2>
      <form>
        <input type="email" placeholder="Email ID" style={inputStyle} />

        <div style={{ display: "flex", gap: "10px" }}>
          <input type="password" placeholder="Password" style={inputStyle} />
        </div>
        <input
          type="text"
          placeholder="Phone no."
          style={{ ...inputStyle, flex: 1 }}
        />

        <div style={{ display: "flex", gap: "10px" }}>
          <DatePicker
            placeholder="Date of Birth"
            style={{ ...inputStyle, flex: 1 }}
            format="DD-MM-YYYY"
          />
          <Select
            placeholder="Select Gender"
            style={{
              flex: 1,
              height: "45px",
              marginBottom: "10px",
            }}
            options={[
              { value: "male", label: "Male" },
              { value: "female", label: "Female" },
              { value: "other", label: "Other" },
            ]}
          />
        </div>

        {/* <div style={{ textAlign: "left", marginTop: "10px" }}>
          <label style={{ fontWeight: "bold" }}>Resume</label>
          <div>
            <button style={uploadButtonStyle}>Upload</button>
          </div>
        </div> */}

        <button style={signInButtonStyle}>Sign up</button>
        <p style={{ fontWeight: "bold", marginTop: "20px" }}>OR</p>
        <button style={loginButtonStyle}>
          Already having an account ?{" "}
          <span style={{ textDecoration: "underline" }}>Login</span>
        </button>
      </form>
    </div>
  );
};

const inputStyle = {
  width: "100%",
  padding: "12px",
  marginBottom: "10px",
  border: "1px solid #ddd",
  borderRadius: "4px",
  boxSizing: "border-box",
};

const uploadButtonStyle = {
  backgroundColor: "#4A6278",
  color: "white",
  padding: "10px 20px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  marginTop: "5px",
};

const signInButtonStyle = {
  width: "100%",
  backgroundColor: "#FF8C66",
  color: "white",
  padding: "12px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  marginTop: "20px",
};

const loginButtonStyle = {
  width: "100%",
  backgroundColor: "#FF6347",
  color: "white",
  padding: "12px",
  border: "none",
  borderRadius: "4px",
  cursor: "pointer",
  marginTop: "10px",
};

export default CandidateSignup;
