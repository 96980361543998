import React, { useState } from "react";
import HandOver from "./HandOver";
import TrackHandover from "./TrackHandOver";
import ResignationLetter from "./ResignationLetter";
import Checklist from "./Checklist";
import { Button, Form, Row, Col } from "react-bootstrap";
import { DatePicker, Space } from "antd";
import { CiEdit } from "react-icons/ci";
import { Table, Button as button, Tag, Modal, Typography } from "antd";

const { Title, Paragraph } = Typography;

const EmployeeResignationLetter = () => {
  const [signature, setSignature] = useState(null);
  const [dateEdit, setDateEdit] = useState(true);
  const [form, setForm] = useState(false);
  const [handOverForm, setHandOverForm] = useState(false);
  const [checklistSubmit, setChecklistSubmit] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpen2, setModalOpen2] = useState(false);
  const [modalOpen3, setModalOpen3] = useState(false);
  const [modalOpen4, setModalOpen4] = useState(false);

  const [currentStep, setCurrentStep] = useState(1);

  // Navigation for steps in the handover process
  const handleNext = () => {
    setCurrentStep(2);
  };

  const handlePrevious = () => {
    setCurrentStep(1);
  };

  // File input handler for uploading signature
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSignature(reader.result); // Set uploaded file as base64
      };
      reader.readAsDataURL(file);
    }
  };

  const dateChange = (date, dateString) => {
    setDateEdit(false); // Disable editing once date is selected
    console.log(dateString);
  };

  const applied = false;

  const columns = [
    {
      title: "Resignation Date",
      dataIndex: "ResignationDate",
      key: "ResignationDate",
    },
    {
      title: "Resignation Letter",
      dataIndex: "ResignationLetter",
      key: "ResignationLetter",
      render: (_, record) =>
        !form ? (
          <button
            className="py-1 px-4 bg-gray-800 text-white"
            style={{ borderRadius: "5px" }}
            onClick={() => setModalOpen2(true)}
          >
            View
          </button>
        ) : (
          <Button onClick={() => setModalOpen(true)}>Add</Button>
        ),
    },
    {
      title: "Handover Form",
      dataIndex: "HandoverForm",
      key: "HandoverForm",
      render: (_, record) =>
        handOverForm ? (
          <button
            className="py-1 px-4 bg-gray-800 text-white"
            style={{ borderRadius: "5px" }}
            onClick={() => setModalOpen3(true)}
          >
            View
          </button>
        ) : (
          <Button onClick={() => setModalOpen3(true)}>Add</Button>
        ),
    },
    {
      title: "Handover Checklist",
      dataIndex: "HandoverChecklist",
      key: "HandoverChecklist",
      render: (_, record) =>
        checklistSubmit ? (
          <button
            className="py-1 px-4 bg-gray-800 text-white"
            style={{ borderRadius: "5px" }}
            onClick={() => setModalOpen4(true)}
          >
            View
          </button>
        ) : (
          <Button onClick={() => setModalOpen4(true)}>Add</Button>
        ),
    },
    {
      title: "Approval Status",
      dataIndex: "ApprovalStatus",
      key: "ApprovalStatus",
      render: (_, record) => {
        const color = record.ApprovalStatus === "approved" ? "green" : "red"; // Dynamic color based on approval status
        return (
          <Tag style={{ fontSize: "14px", padding: "4px 10px" }} color={color}>
            {record.ApprovalStatus}
          </Tag>
        );
      },
    },
    {
      title: "Resignation Acceptance Letter",
      dataIndex: "ResignationAcceptanceLetter",
      key: "ResignationAcceptanceLetter",
      render: (_, record) => <Button>View</Button>,
    },
  ];

  // Table data definition
  const data = [
    {
      key: "1",
      ResignationDate: "2025-01-01",
      ResignationLetter: "Letter1",
      HandoverForm: "Form1",
      HandoverChecklist: "Checklist1",
      ApprovalStatus: "pending",
      ResignationAcceptanceLetter: "approved",
    },
  ];

  return (
    <div className="p-3">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Modal for adding resignation letter */}
          <Modal
            centered
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            width={800} // Modal size (width)
            footer={
              <Button variant="secondary" onClick={() => setModalOpen(false)}>
                Cancel
              </Button>
            }
          >
            <Form>
              <Form.Group className="mb-3" controlId="resignationLetter">
                <Form.Label>Resignation Letter</Form.Label>
                <Form.Control as="textarea" rows={3} />
              </Form.Group>

              {/* Signature upload */}
              <Form.Group className="mb-3" controlId="signature">
                <Form.Label>Signature</Form.Label>
                <Row>
                  <Col xs={9}>
                    <Form.Control
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                    />
                  </Col>
                </Row>
                <div
                  style={{
                    marginTop: "10px",
                    width: "50%",
                    height: "150px",
                    border: "1px solid #ccc",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {signature ? (
                    <img
                      src={signature}
                      alt="Uploaded Signature"
                      style={{ maxWidth: "100%", maxHeight: "100%" }}
                    />
                  ) : (
                    <p>No Signature Uploaded</p>
                  )}
                </div>
              </Form.Group>

              <Button variant="warning" type="submit">
                Submit
              </Button>
            </Form>
          </Modal>

          {/* Modal for viewing resignation letter */}
          <Modal
            centered
            open={modalOpen2}
            onCancel={() => setModalOpen2(false)}
            footer={null}
            title="Resignation Letter"
            width={800}
          >
            <ResignationLetter />
          </Modal>

          {/* Modal for viewing handover form */}
          <Modal
            centered
            open={modalOpen3}
            onCancel={() => setModalOpen3(false)}
            footer={null}
            title="Hand Over Form"
            width={900}
            style={{ top: 20 }}
            bodyStyle={{
              maxHeight: "70vh", // Limit modal height
              overflowY: "auto", // Enable scrolling within the modal
            }}
          >
            {currentStep === 1 && <HandOver onNext={handleNext} />}
            {currentStep === 2 && (
              <TrackHandover
                onPrevious={handlePrevious}
                onNext={() => console.log("Next Step")}
              />
            )}
          </Modal>

          {/* Modal for viewing checklist */}
          <Modal
            centered
            open={modalOpen4}
            onCancel={() => setModalOpen4(false)}
            footer={null}
            title="Resignation Letter"
            width={900}
            style={{ top: 20 }}
            bodyStyle={{
              maxHeight: "70vh",
              overflowY: "auto",
            }}
          >
            <Checklist />
          </Modal>

          {/* Table rendering resignation details */}
          <Table columns={columns} dataSource={data} pagination={false} />
        </div>
      </div>
    </div>
  );
};

export default EmployeeResignationLetter;
