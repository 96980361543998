import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Typography,
  Spin,
  Statistic,
  Table,
  Avatar,
  Select,
} from "antd";
import { UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addTaskGacl,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";
import GaclSideBar from "../../../layout/GaclSidebar";

const { Title } = Typography;

const ESRAMLDashboard = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [dashboardData, setDashboardData] = useState([]);
  const [currentMonth, setCurrentMonth] = useState({ start: null, end: null });
  const [isGacl, setIsGacl] = useState(false);
  const [selectedTaskType, setSelectedTaskType] = useState(null);
  const [taskTypes, setTaskTypes] = useState([]);

  const fetchDashboardData = async () => {
    try {
      const response = await axiosInstance.get(addTaskGacl);
      setDashboardData(response.data?.results || []);
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
      toast.error("Failed to fetch dashboard data");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardData();
    setCurrentMonth({
      start: moment().startOf("month").format("MMMM D, YYYY"),
      end: moment().endOf("month").format("MMMM D, YYYY"),
    });
  }, []);

  useEffect(() => {
    axiosInstance.get(`${employeeDetails}`).then((response) => {
      setIsGacl(
        response.data.employment_details[0].department_detail.name === "GACL"
      );
    });
  }, []);

  useEffect(() => {
    if (dashboardData.length > 0) {
      const uniqueTaskTypes = [
        ...new Set(dashboardData.map((item) => item.task)),
      ];
      setTaskTypes(
        uniqueTaskTypes.map((type) => ({
          label: type,
          value: type,
        }))
      );
    }
  }, [dashboardData]);

  const getFilteredData = () => {
    if (!selectedTaskType) return dashboardData;
    return dashboardData.filter((item) => item.task === selectedTaskType);
  };

  const calculateTimeMetrics = (tasks = []) => {
    if (!Array.isArray(tasks)) {
      console.warn("Invalid tasks data provided to calculateTimeMetrics");
      return {
        avgAcceptanceTime: 0,
        avgCompletionTime: 0,
        totalTasks: 0,
        completedTasks: 0,
        pendingTasks: 0,
      };
    }

    const completedTasks = tasks.filter((task) => task.is_done);

    // Calculate acceptance time
    const acceptanceTimes = completedTasks
      .filter((task) => task.accepted_time)
      .map((task) =>
        moment(task.accepted_time).diff(moment(task.created), "hours", true)
      );

    const avgAcceptanceTime =
      acceptanceTimes.length > 0
        ? (
            acceptanceTimes.reduce((a, b) => a + b, 0) / acceptanceTimes.length
          ).toFixed(2)
        : 0;

    // Calculate completion time
    const completionTimes = completedTasks
      .filter((task) => task.finished_time && task.accepted_time)
      .map((task) =>
        moment(task.finished_time).diff(
          moment(task.accepted_time),
          "hours",
          true
        )
      );

    const avgCompletionTime =
      completionTimes.length > 0
        ? (
            completionTimes.reduce((a, b) => a + b, 0) / completionTimes.length
          ).toFixed(2)
        : 0;

    return {
      avgAcceptanceTime,
      avgCompletionTime,
      totalTasks: tasks.length,
      completedTasks: completedTasks.length,
      pendingTasks: tasks.filter((task) => !task.is_done).length,
    };
  };

  const currentTaskColumns = [
    {
      title: "Task Type",
      dataIndex: "task",
      key: "task",
      width: "15%",
    },
    {
      title: "Assignee",
      dataIndex: "assignee_details",
      key: "assignee",
      width: "20%",
      render: (details) => details?.employee_name || "-",
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      render: (_, record) => {
        if (record.is_done) return "Completed";
        if (record.is_accepted) return "In Progress";
        return "Pending";
      },
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      width: "25%",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
  ];

  const demoCostStatus = {
    totalRevenue: 0,
    totalCostTrack: 0,
    costStation: 0,
    actualCostBudget: 0,
    actualRevenueBudget: 0,
  };

  const clockStatusColumns = [
    {
      title: "Task Type",
      dataIndex: "task",
      key: "task",
      width: "15%",
    },
    {
      title: "Assignee",
      dataIndex: "assignee_details",
      key: "assignee",
      width: "20%",
      render: (details) => details?.employee_name || "-",
    },
    {
      title: "Status",
      key: "status",
      width: "15%",
      render: (_, record) => {
        if (record.is_done) return "Completed";
        if (record.is_accepted) return "In Progress";
        return "Pending";
      },
    },
    {
      title: "Time Taken",
      key: "timeTaken",
      width: "25%",
      render: (_, record) => {
        if (!record.accepted_time) return "Not started";
        if (!record.finished_time) {
          return `In progress (${moment(record.accepted_time).fromNow()})`;
        }
        return moment
          .duration(
            moment(record.finished_time).diff(moment(record.accepted_time))
          )
          .humanize();
      },
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      key: "due_date",
      width: "25%",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
  ];

  const timelineStatusColumns = [
    {
      title: "Task Type",
      dataIndex: "task",
      key: "task",
      width: "30%",
    },
    {
      title: "Time to Accept",
      key: "timeToAccept",
      render: (_, record) => {
        if (!record.accepted_time) return "Not accepted";
        return `${moment(record.accepted_time)
          .diff(moment(record.created), "hours", true)
          .toFixed(2)} hours`;
      },
    },
    {
      title: "Time to Complete",
      key: "timeToComplete",
      render: (_, record) => {
        if (!record.finished_time || !record.accepted_time)
          return "Not completed";
        return `${moment(record.finished_time)
          .diff(moment(record.accepted_time), "hours", true)
          .toFixed(2)} hours`;
      },
    },
    {
      title: "Total Time",
      key: "totalTime",
      render: (_, record) => {
        if (!record.finished_time) return "-";
        return `${moment(record.finished_time)
          .diff(moment(record.created), "hours", true)
          .toFixed(2)} hours`;
      },
    },
  ];

  return (
    <>
      {isGacl ? (
        <>
          <Header />
          <GaclSideBar />
        </>
      ) : (
        <></>
      )}

      <div className="page-wrapper">
        <div className="content container-fluid">
          <Row
            gutter={[16, 16]}
            align="middle"
            justify="space-between"
            style={{ marginBottom: "16px" }}
          >
            <Col>
              <Title level={2} style={{ margin: 0 }}>
                GACL Dashboard
              </Title>
            </Col>
            <Col>
              <Select
                allowClear
                style={{ width: 200 }}
                placeholder="Filter by Task Type"
                onChange={(value) => setSelectedTaskType(value)}
                options={taskTypes}
              />
            </Col>
          </Row>

          {isLoading ? (
            <Spin size="large" />
          ) : (
            <>
              <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
                <Col span={24}>
                  <Card>
                    <Title level={4}>
                      Current Month: {currentMonth.start} - {currentMonth.end}
                    </Title>
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Card className="profile-card" style={{ height: "100%" }}>
                    <div className="text-center">
                      <Avatar
                        size={64}
                        icon={<UserOutlined />}
                        style={{ marginBottom: "16px" }}
                      />
                      <Title level={4}>Vranda Bansal</Title>
                      <p>SubTrack Owner</p>
                      <Title level={4} style={{ marginTop: "16px" }}>
                        Task Statistics
                      </Title>
                      <Row gutter={16}>
                        <Col span={8}>
                          <Statistic
                            title="Total Tasks"
                            value={
                              calculateTimeMetrics(getFilteredData()).totalTasks
                            }
                          />
                        </Col>
                        <Col span={8}>
                          <Statistic
                            title="Completed"
                            value={
                              calculateTimeMetrics(getFilteredData())
                                .completedTasks
                            }
                          />
                        </Col>
                        <Col span={8}>
                          <Statistic
                            title="Pending"
                            value={
                              calculateTimeMetrics(getFilteredData())
                                .pendingTasks
                            }
                          />
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </Col>
                <Col span={16}>
                  <Card title="Current Tasks" className="station-card">
                    <Table
                      columns={currentTaskColumns}
                      dataSource={getFilteredData()}
                      pagination={false}
                      rowKey="id"
                      scroll={{ y: 240 }}
                      style={{
                        overflowX: "auto",
                        maxHeight: "240px",
                      }}
                    />
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={18}>
                  <Card title="Clock Status" className="clock-status-card">
                    <Table
                      columns={clockStatusColumns}
                      dataSource={getFilteredData()}
                      pagination={false}
                      rowKey="id"
                      expandable={{
                        expandedRowRender: (record) => (
                          <p style={{ margin: 0 }}>
                            Created:{" "}
                            {moment(record.created).format("DD-MM-YYYY HH:mm")}
                            <br />
                            Accepted:{" "}
                            {record.accepted_time
                              ? moment(record.accepted_time).format(
                                  "DD-MM-YYYY HH:mm"
                                )
                              : "Not yet"}
                            <br />
                            Completed:{" "}
                            {record.finished_time
                              ? moment(record.finished_time).format(
                                  "DD-MM-YYYY HH:mm"
                                )
                              : "Not yet"}
                          </p>
                        ),
                      }}
                      scroll={{ y: 240, x: "max-content" }}
                    />
                  </Card>
                </Col>
                <Col span={6}>
                  <Card
                    title="Cost Status"
                    className="cost-status-card"
                    style={{ height: "100%" }}
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={12}>
                        <Statistic
                          title="Total Revenue"
                          value={demoCostStatus.totalRevenue}
                          prefix="AED"
                          valueStyle={{ color: "#3f8600", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Total Cost/Track"
                          value={demoCostStatus.totalCostTrack}
                          prefix="AED"
                          valueStyle={{ color: "#cf1322", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Cost/Station"
                          value={demoCostStatus.costStation}
                          prefix="AED"
                          valueStyle={{ color: "#1890ff", fontSize: "14px" }}
                        />
                      </Col>
                      <Col span={12}>
                        <Statistic
                          title="Actual Cost/Budget"
                          value={demoCostStatus.actualCostBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualCostBudget > 50
                                ? "#cf1322"
                                : "#3f8600",
                            fontSize: "14px",
                          }}
                        />
                      </Col>
                      <Col span={24}>
                        <Statistic
                          title="Actual Revenue/Budget"
                          value={demoCostStatus.actualRevenueBudget}
                          suffix="%"
                          valueStyle={{
                            color:
                              demoCostStatus.actualRevenueBudget > 50
                                ? "#3f8600"
                                : "#cf1322",
                            fontSize: "14px",
                          }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={[16, 16]} style={{ marginTop: "16px" }}>
                <Col span={18}>
                  <Card
                    title="Timeline Status"
                    className="timeline-status-card"
                    style={{ height: "100%" }}
                  >
                    <Table
                      columns={timelineStatusColumns}
                      dataSource={getFilteredData()}
                      pagination={false}
                      rowKey="id"
                      scroll={{ y: 240, x: "max-content" }}
                      style={{
                        overflowX: "auto",
                        maxHeight: "240px",
                      }}
                    />
                  </Card>
                </Col>
              </Row>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default ESRAMLDashboard;
