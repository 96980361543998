import React from "react";
import { Modal } from "antd";
import CandidateSignup from "./Signup";
import CandidateLogin from "./Login";
import JobApplicationModal from "./ApplicationModal";

const Modals = ({
  isModalOpen,
  setIsModalOpen,
  isLoginModalOpen,
  setIsLoginModalOpen,
  isApplyModalOpen,
  setIsApplyModalOpen,
}) => {
  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        width={700}
        footer={null}
      >
        <CandidateSignup />
      </Modal>

      <Modal
        open={isLoginModalOpen}
        onCancel={() => setIsLoginModalOpen(false)}
        width={700}
        footer={null}
      >
        <CandidateLogin />
      </Modal>

      <Modal
        open={isApplyModalOpen}
        onCancel={() => setIsApplyModalOpen(false)}
        width={800}
        footer={null}
      >
        <JobApplicationModal />
      </Modal>
    </>
  );
};

export default Modals;
