import React, { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Select,
  Button,
  Typography,
  Spin,
  Tag,
} from "antd";
import { CloseOutlined, UserOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  employeeList,
  employeeDetails,
  employeeQPA,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";

const { Title, Text } = Typography;

const NominationDetails = () => {
  const [employees, setEmployees] = useState([]);
  const [selectedNominees, setSelectedNominees] = useState([]);
  const [currentEmployeeId, setCurrentEmployeeId] = useState(null);
  const [currentEmployee, setCurrentEmployee] = useState(null);
  const [reportingTo, setReportingTo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [form] = Form.useForm();
  const [selectedQuarter, setSelectedQuarter] = useState(null);

  // Add this to track both loading states
  const isDataLoading = isLoading || !currentEmployee;

  // Fetch current employee details
  useEffect(() => {
    const fetchCurrentEmployee = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        setReportingTo(response.data.employment_details[0].reporting_to);
        setCurrentEmployeeId(response.data.employment_details[0].id);
        setCurrentEmployee(response.data);
      } catch (error) {
        console.error("Error fetching current employee:", error);
        toast.error("Failed to fetch employee details");
      }
    };
    fetchCurrentEmployee();
  }, []);

  // Fetch employee list
  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const response = await axiosInstance.get(employeeList);
        setEmployees(response.data);
      } catch (error) {
        console.error("Error fetching employees:", error);
        toast.error("Failed to fetch employees list");
      } finally {
        setIsLoading(false);
      }
    };
    fetchEmployees();
  }, []);

  const handleNomineesChange = (values) => {
    if (values.length <= 3) {
      setSelectedNominees(values);
    }
  };

  const handleSubmit = async () => {
    if (selectedNominees.length === 0) {
      toast.warning("Please select at least one nominee");
      return;
    }

    if (!currentEmployeeId) {
      toast.error("Employee ID not found");
      return;
    }

    if (!selectedQuarter) {
      toast.warning("Please select a quarter");
      return;
    }

    try {
      const nominations = [
        { nominee: currentEmployeeId, type: "Self" },
        { nominee: reportingTo, type: "Boss" },
        ...selectedNominees.map((nominee) => ({
          nominee: nominee,
          type: "Colleague",
        })),
      ];

      const payload = {
        user: currentEmployeeId,
        nominations: nominations,
        quarter: selectedQuarter,
      };

      await axiosInstance.post(employeeQPA, payload);
      toast.success("Nominations submitted successfully");
      form.resetFields();
      setSelectedNominees([]);
      setSelectedQuarter(null);
    } catch (error) {
      console.error("Error submitting nominations:", error);
      toast.error("Failed to submit nominations");
    }
  };

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {isDataLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                minHeight: "calc(100vh - 60px)",
              }}
            >
              <Spin size="large" />
            </div>
          ) : (
            <>
              <Title level={2}>QPA Nominations</Title>

              <Card>
                <Form form={form} onFinish={handleSubmit} layout="vertical">
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <Form.Item
                        label={
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              marginBottom: "8px",
                            }}
                          >
                            Select Quarter
                          </div>
                        }
                        name="quarter"
                        rules={[
                          {
                            required: true,
                            message: "Please select a quarter",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select a quarter"
                          onChange={(value) => setSelectedQuarter(value)}
                          value={selectedQuarter}
                          style={{ width: "100%" }}
                        >
                          <Select.Option value="Quarter 1">
                            Quarter 1
                          </Select.Option>
                          <Select.Option value="Quarter 2">
                            Quarter 2
                          </Select.Option>
                          <Select.Option value="Quarter 3">
                            Quarter 3
                          </Select.Option>
                          <Select.Option value="Quarter 4">
                            Quarter 4
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        label={
                          <div
                            style={{
                              fontSize: "16px",
                              fontWeight: 500,
                              marginBottom: "8px",
                            }}
                          >
                            Select Nominees (Maximum 3)
                          </div>
                        }
                        name="nominees"
                        rules={[
                          {
                            required: true,
                            message: "Please select at least one nominee",
                          },
                          {
                            validator: (_, value) => {
                              if (value && value.length > 3) {
                                return Promise.reject(
                                  "Maximum 3 nominees allowed"
                                );
                              }
                              return Promise.resolve();
                            },
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder={
                            selectedNominees.length >= 3
                              ? "Maximum limit reached"
                              : "Search and select nominees"
                          }
                          value={selectedNominees}
                          onChange={handleNomineesChange}
                          style={{ width: "100%" }}
                          maxTagCount="responsive"
                          showSearch={selectedNominees.length < 3}
                          disabled={selectedNominees.length >= 3}
                          optionFilterProp="searchValue"
                          filterOption={(input, option) =>
                            option?.searchValue
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={employees
                            .filter(
                              (emp) =>
                                emp.id !== currentEmployeeId &&
                                emp.id !== reportingTo
                            )
                            .map((employee) => ({
                              label: (
                                <div style={{ padding: "4px 0" }}>
                                  <div style={{ fontWeight: 500 }}>
                                    {employee.employee_name}
                                  </div>
                                  <div
                                    style={{ fontSize: "12px", color: "#666" }}
                                  >
                                    {
                                      employee.employment_details[0]
                                        ?.department_detail?.name
                                    }
                                  </div>
                                </div>
                              ),
                              value: employee.id,
                              searchValue: employee.employee_name,
                            }))}
                          tagRender={({ label, value, closable, onClose }) => (
                            <Tag
                              closeIcon={
                                <CloseOutlined style={{ fontSize: "12px" }} />
                              }
                              onClose={(e) => {
                                e.stopPropagation();
                                const newNominees = selectedNominees.filter(
                                  (id) => id !== value
                                );
                                setSelectedNominees(newNominees);
                                form.setFieldValue("nominees", newNominees);
                              }}
                              closable={true}
                              style={{
                                marginRight: 3,
                                padding: "4px 8px",
                                background: "#f0f5ff",
                                borderColor: "#d6e4ff",
                                display: "inline-flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <UserOutlined style={{ fontSize: "12px" }} />
                              {
                                employees.find((emp) => emp.id === value)
                                  ?.employee_name
                              }
                            </Tag>
                          )}
                        />
                      </Form.Item>
                    </Col>

                    {selectedNominees.length > 0 && (
                      <Col span={24}>
                        <div
                          style={{
                            background: "#f6f6f6",
                            padding: "16px",
                            borderRadius: "8px",
                            marginBottom: "20px",
                          }}
                        >
                          <Text strong>Selected Nominees:</Text>
                          <div style={{ marginTop: "8px" }}>
                            {selectedNominees.map((nomineeId) => {
                              const nominee = employees.find(
                                (emp) => emp.id === nomineeId
                              );
                              return (
                                <div
                                  key={nomineeId}
                                  style={{
                                    marginTop: "8px",
                                    background: "white",
                                    padding: "12px 16px",
                                    borderRadius: "6px",
                                    border: "1px solid #e8e8e8",
                                  }}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                      width: "100%",
                                    }}
                                  >
                                    <div>
                                      <Text strong>
                                        {nominee?.employee_name}
                                      </Text>
                                      <Text type="secondary">
                                        {" "}
                                        -{" "}
                                        {
                                          nominee?.employment_details[0]
                                            ?.department_detail?.name
                                        }
                                      </Text>
                                    </div>
                                    <Button
                                      type="link"
                                      style={{
                                        color: "#FF8C00",
                                        padding: "4px 0",
                                        height: "auto",
                                        fontSize: "14px",
                                        fontWeight: "normal",
                                      }}
                                      onClick={() => {
                                        const newNominees =
                                          selectedNominees.filter(
                                            (id) => id !== nomineeId
                                          );
                                        setSelectedNominees(newNominees);
                                        form.setFieldValue(
                                          "nominees",
                                          newNominees
                                        );
                                      }}
                                    >
                                      Remove
                                    </Button>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Col>
                    )}

                    <Col span={24}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        disabled={
                          selectedNominees.length < 3 || !selectedQuarter
                        }
                        size="large"
                        style={{ width: "200px" }}
                      >
                        Submit Nominations
                      </Button>
                      {selectedNominees.length < 3 && (
                        <Text type="secondary" style={{ marginLeft: "16px" }}>
                          Please select {3 - selectedNominees.length} more
                          nominee{selectedNominees.length === 2 ? "" : "s"}
                        </Text>
                      )}
                    </Col>
                  </Row>
                </Form>
              </Card>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default NominationDetails;
