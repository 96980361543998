import React, { useState, useEffect, useCallback } from "react";
import { Modal, Form, Button, Container, Row, Col } from "react-bootstrap";
import {
  Table,
  Tabs,
  Typography,
  Tag,
  Select,
  Spin,
  Upload,
  Space,
  DatePicker,
  Input,
} from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  separation,
  employeeList,
  employeeDetails,
  handover,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import moment from "moment";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ViewHandoverForm from "../../Employees/EmployeeResignation/ViewHandoverForm";
import EditTrackHandover from "../../Employees/EmployeeResignation/EditTrackHandover";
import TrackHandover from "../../Employees/EmployeeResignation/TrackHandOver";
import HandoverChecklist from "./checkListHR";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import { InboxOutlined } from "@ant-design/icons";
import { FaExternalLinkAlt } from "react-icons/fa";

const { Title, Paragraph } = Typography;
const { TabPane } = Tabs;

const getStatusColor = (status) => {
  switch (status) {
    case "Submission":
      return "orange"; // Pending state
    case "Reviewed":
      return "cyan"; // Initial review done
    case "Handover":
      return "blue"; // In handover process
    case "Handover Approval":
      return "purple"; // Waiting for final approval
    case "Approved":
      return "green"; // Final approved state
    case "Rejected":
      return "red"; // Rejected state
    default:
      return "default"; // Fallback color
  }
};

const ViewModal = React.memo(
  ({
    show,
    onHide,
    resignation,
    employees = [],
    onSubmit,
    onApproveHandover,
    onEmployeeSelect,
    onTeamLeaderSelect,
    selectedEmployee,
    selectedTeamLeader,
    activeTabKey,
    setActiveTabKey,
    isEditing,
    setIsEditing,
    handleEditClick,
    handleSuccess,
    handoverData,
    lastWorkingDate,
    setLastWorkingDate,
    submitting,
    refreshTable,
    remarks,
    setRemarks,
  }) => {
    const [acceptanceLetter, setAcceptanceLetter] = useState(null);
    const [experienceLetter, setExperienceLetter] = useState(null);
    const [uploadingLetter, setUploadingLetter] = useState(false);

    useEffect(() => {
      if (resignation?.last_working_date) {
        setLastWorkingDate(moment(resignation.last_working_date));
      } else {
        setLastWorkingDate(null);
      }
    }, [resignation, setLastWorkingDate]);

    const handleFileChange = (e, type) => {
      const file = e.target.files[0];
      if (file) {
        if (file.type === "application/pdf") {
          if (type === "acceptance") {
            setAcceptanceLetter(file);
          } else if (type === "experience") {
            setExperienceLetter(file);
          }
        } else {
          toast.error("Please upload a PDF file");
          e.target.value = null;
        }
      }
    };

    const handleAcceptanceLetterUpload = async (file) => {
      try {
        const formData = new FormData();
        formData.append("acceptance_letter", file);
        formData.append("status", "Handover");

        await axiosInstance.patch(`${separation}${resignation.id}/`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        toast.success("Acceptance letter uploaded successfully");
        onHide(); // Close modal after successful upload
      } catch (error) {
        console.error("Error uploading acceptance letter:", error);
        toast.error("Failed to upload acceptance letter");
      }
    };

    const handleExperienceLetterUpload = async () => {
      if (!experienceLetter) {
        toast.error("Please select a file to upload");
        return;
      }

      setUploadingLetter(true);
      try {
        const formData = new FormData();
        formData.append("experience_letter", experienceLetter);

        const config = {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        };

        await axiosInstance.patch(
          `${separation}${resignation.id}/`,
          formData,
          config
        );

        toast.success("Experience letter uploaded successfully");
        refreshTable();
        setExperienceLetter(null);
        onHide();
      } catch (error) {
        console.error("Error uploading experience letter:", error);
        toast.error("Failed to upload experience letter");
      } finally {
        setUploadingLetter(false);
      }
    };

    const handleDateChange = (date) => {
      if (date) {
        setLastWorkingDate(date); // Store the raw moment object
      } else {
        setLastWorkingDate(null);
      }
    };

    const handleSubmit = () => {
      if (!lastWorkingDate || !selectedEmployee) {
        toast.error("Please fill in all required fields");
        return;
      }

      const formData = new FormData();
      formData.append("handover_person", selectedEmployee);
      formData.append(
        "last_working_date",
        lastWorkingDate.format("YYYY-MM-DD")
      );
      formData.append("is_forward", true);
      formData.append("remarks", remarks);

      onSubmit(formData);
    };

    return (
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        centered
        onExited={() => setIsEditing(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Resignation Details
            {resignation && (
              <Tag
                color={getStatusColor(resignation.status)}
                style={{ marginLeft: "10px" }}
              >
                {resignation.status}
              </Tag>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {resignation && (
            <Tabs
              activeKey={activeTabKey}
              onChange={setActiveTabKey}
              style={{ marginTop: -15 }}
            >
              <TabPane tab="Resignation Letter" key="1">
                <Typography>
                  <Title level={4}>
                    {resignation.user_details.employee_name}'s Resignation
                  </Title>

                  <div
                    style={{
                      display: "flex",
                      gap: "24px",
                      marginBottom: "20px",
                      flexWrap: "wrap",
                    }}
                  >
                    <Paragraph style={{ margin: 0 }}>
                      <strong>Department:</strong>{" "}
                      {
                        resignation.user_details.employment_details[0]
                          .department_detail.name
                      }
                    </Paragraph>

                    <Paragraph style={{ margin: 0 }}>
                      <strong>Designation:</strong>{" "}
                      {
                        resignation.user_details.employment_details[0]
                          .designation_detail.name
                      }
                    </Paragraph>

                    <Paragraph style={{ margin: 0 }}>
                      <strong>Submitted Date:</strong>{" "}
                      {moment(resignation.created_at).format("DD-MM-YYYY")}
                    </Paragraph>
                    {resignation.last_working_date && (
                      <Paragraph>
                        <strong>Last Working Date:</strong>{" "}
                        {moment(resignation.last_working_date).format(
                          "DD-MM-YYYY"
                        )}
                      </Paragraph>
                    )}
                  </div>

                  <Title level={5}>Resignation Letter:</Title>
                  <Paragraph style={{ whiteSpace: "pre-line" }}>
                    {resignation.resignation}
                  </Paragraph>

                  {resignation.signature && (
                    <>
                      <Title level={5}>Signature:</Title>
                      <div
                        style={{
                          maxWidth: "200px",
                          border: "1px solid #f0f0f0",
                          padding: "10px",
                          marginTop: "10px",
                        }}
                      >
                        <img
                          src={resignation.signature}
                          alt="Signature"
                          style={{ width: "100%" }}
                        />
                      </div>
                    </>
                  )}

                  <Form.Group className="mt-4">
                    {resignation.is_reviewed || resignation.handover_person ? (
                      <>
                        <Form.Label>
                          <strong>Person Taking Over:</strong>
                        </Form.Label>
                        <div className="border rounded p-2">
                          <Paragraph>
                            <strong>Name:</strong>{" "}
                            {resignation.handover_details?.employee_name}
                          </Paragraph>
                          <Paragraph>
                            <strong>Department:</strong>{" "}
                            {
                              resignation.handover_details
                                ?.employment_details[0]?.department_detail?.name
                            }
                          </Paragraph>
                          <Paragraph>
                            <strong>Designation:</strong>{" "}
                            {
                              resignation.handover_details
                                ?.employment_details[0]?.designation_detail
                                ?.name
                            }
                          </Paragraph>
                        </div>
                        <div
                          className="mt-2"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "8px",
                          }}
                        >
                          <Title level={5} style={{ margin: 0 }}>
                            Team Leader Assigned:
                          </Title>
                          <Paragraph style={{ marginTop: 15 }}>
                            {resignation?.team_leader_details?.employee_name}
                          </Paragraph>
                        </div>
                        {resignation?.remarks && (
                          <Paragraph>
                            <strong>Remarks(if Any):</strong>{" "}
                            {resignation.remarks}
                          </Paragraph>
                        )}
                        {resignation?.comments && (
                          <Paragraph>
                            <strong>Comments(if Any):</strong>{" "}
                            {resignation.comments}
                          </Paragraph>
                        )}
                        {resignation?.acceptance_letter && (
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              window.open(
                                resignation.acceptance_letter,
                                "_blank"
                              )
                            }
                            className="d-flex align-items-center gap-2"
                          >
                            <FaExternalLinkAlt /> View Acceptance Letter
                          </Button>
                        )}
                      </>
                    ) : (
                      <>
                        <Form.Label>
                          <strong>Person Taking Over:</strong>
                        </Form.Label>
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Search and select employee for handover"
                          optionFilterProp="children"
                          onChange={onEmployeeSelect}
                          value={selectedEmployee}
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={
                            employees?.map((emp) => ({
                              value: emp.id,
                              label: `${emp.employee_name} - ${
                                emp.employment_details[0]?.department_detail
                                  ?.name || ""
                              } - ${
                                emp.employment_details[0]?.designation_detail
                                  ?.name || ""
                              }`,
                            })) || []
                          }
                        />
                        <Form.Label className="mt-3">
                          <strong>Team Leader:</strong>
                        </Form.Label>
                        <Select
                          showSearch
                          style={{ width: "100%" }}
                          placeholder="Search and select team leader"
                          optionFilterProp="children"
                          onChange={onTeamLeaderSelect}
                          value={selectedTeamLeader}
                          filterOption={(input, option) =>
                            option?.label
                              ?.toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          options={
                            employees?.map((emp) => ({
                              value: emp.id,
                              label: `${emp.employee_name} - ${
                                emp.employment_details[0]?.department_detail
                                  ?.name || ""
                              } - ${
                                emp.employment_details[0]?.designation_detail
                                  ?.name || ""
                              }`,
                            })) || []
                          }
                        />
                        <Form.Group className="mt-3">
                          <Form.Label>
                            <strong>Last Working Date</strong>
                          </Form.Label>
                          <DatePicker
                            style={{ width: "100%" }}
                            value={lastWorkingDate}
                            onChange={handleDateChange}
                            disabledDate={(current) =>
                              current && current < moment().endOf("day")
                            }
                            format="DD-MM-YYYY"
                            allowClear={false}
                          />
                        </Form.Group>
                        {/* //add section for Remarks*/}
                        <Form.Group className="mt-3">
                          <Form.Label>
                            <strong>Remarks(if Any)</strong>
                          </Form.Label>
                          <Input.TextArea
                            rows={4}
                            value={remarks}
                            onChange={(e) => setRemarks(e.target.value)}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Form.Group>

                  {resignation.status === "Reviewed" && (
                    <Form.Group className="mt-4">
                      <Form.Label>
                        <strong>Acceptance Letter</strong>
                      </Form.Label>
                      {resignation?.acceptance_letter ? (
                        <div className="border rounded p-3">
                          <a
                            href={resignation?.acceptance_letter}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="btn btn-primary"
                          >
                            View Acceptance Letter
                          </a>
                        </div>
                      ) : (
                        <Form.Group>
                          <Form.Control
                            type="file"
                            accept=".pdf"
                            onChange={(e) => handleFileChange(e, "acceptance")}
                          />
                          {acceptanceLetter && (
                            <Button
                              variant="primary"
                              className="mt-2"
                              onClick={() =>
                                handleAcceptanceLetterUpload(acceptanceLetter)
                              }
                            >
                              Upload Acceptance Letter
                            </Button>
                          )}
                        </Form.Group>
                      )}
                    </Form.Group>
                  )}

                  {resignation.status === "Approved" && (
                    <div className="mt-4">
                      <Title level={5}>Experience Letter:</Title>
                      {resignation.experience_letter && (
                        <div className="mb-3">
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              window.open(
                                resignation.experience_letter,
                                "_blank"
                              )
                            }
                            className="d-flex align-items-center gap-2"
                          >
                            <FaExternalLinkAlt /> View Experience Letter
                          </Button>
                        </div>
                      )}

                      <Form.Group>
                        <Form.Label>
                          <strong>
                            {resignation.experience_letter
                              ? "Re-upload Experience Letter"
                              : "Upload Experience Letter"}
                          </strong>
                        </Form.Label>
                        <div className="d-flex align-items-center gap-2">
                          <Form.Control
                            type="file"
                            accept=".pdf"
                            onChange={(e) => handleFileChange(e, "experience")}
                            className="me-2"
                          />
                          <Button
                            variant="primary"
                            onClick={handleExperienceLetterUpload}
                            disabled={!experienceLetter || uploadingLetter}
                            style={{ flexShrink: 0 }}
                          >
                            {uploadingLetter ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm me-2"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                Uploading...
                              </>
                            ) : resignation.experience_letter ? (
                              "Re-upload"
                            ) : (
                              "Upload"
                            )}
                          </Button>
                        </div>
                        <Form.Text className="text-muted">
                          Please upload experience letter in PDF format
                        </Form.Text>
                      </Form.Group>
                    </div>
                  )}
                </Typography>
              </TabPane>

              <TabPane tab="Handover Form" key="2">
                {isEditing ? (
                  <EditTrackHandover
                    key={`edit-${resignation.id}`}
                    resignationId={handoverData?.id}
                    onClose={() => setIsEditing(false)}
                    onSuccess={handleSuccess}
                    existingTracks={handoverData?.handover_detail || []}
                    employees={employees}
                    externalContacts={
                      handoverData?.handover_detail?.[0]?.external_contact || []
                    }
                  />
                ) : (
                  <div>
                    <ViewHandoverForm
                      key={`view-${resignation.id}`}
                      separationId={resignation.id}
                      handoverData={handoverData}
                      isTlforHR={true}
                    />
                    <div
                      style={{
                        justifyContent: "flex-start",
                      }}
                    >
                      {handoverData?.handover_detail?.length > 0 && (
                        <Button
                          type="primary"
                          onClick={handleEditClick}
                          style={{ marginTop: "16px", marginBottom: "16px" }}
                          disabled={!handoverData}
                        >
                          Assign Handover Task/ Edit
                        </Button>
                      )}
                      {resignation?.status === "Handover" &&
                        onApproveHandover()}
                    </div>
                  </div>
                )}
              </TabPane>

              {(resignation?.status === "Handover Approval" ||
                resignation?.status === "Approved") && (
                <TabPane tab="Handover Checklist" key="3">
                  <Typography>
                    <Title level={5}>Checklist Items</Title>
                    <HandoverChecklist selectedResignation={resignation} />
                  </Typography>
                </TabPane>
              )}
            </Tabs>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onHide}>
            Close
          </Button>
          {activeTabKey === "1" && (
            <>
              <Button
                type="success"
                onClick={handleSubmit}
                disabled={!lastWorkingDate || !selectedEmployee || submitting}
                loading={submitting}
              >
                Send for Approval
              </Button>
            </>
          )}
          {/* {activeTabKey === "2" && onApproveHandover()} */}
        </Modal.Footer>
      </Modal>
    );
  }
);

const ViewResignationHRforTL = () => {
  // State declarations
  const [resignations, setResignations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewModalVisible, setViewModalVisible] = useState(false);
  const [selectedResignation, setSelectedResignation] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [departmentID, setDepartmentID] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedTeamLeader, setSelectedTeamLeader] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [showHandoverModal, setShowHandoverModal] = useState(false);
  const [selectedHandover, setSelectedHandover] = useState(null);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [handoverFormKey, setHandoverFormKey] = useState(0);
  const [handoverData, setHandoverData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [lastWorkingDate, setLastWorkingDate] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100"],
  });

  // Update fetchResignations to remove pagination from dependencies
  const fetchResignations = useCallback(
    async (page = pagination.current, pageSize = pagination.pageSize) => {
      if (!departmentID) return;

      setLoading(true);
      try {
        const response = await axiosInstance.get(
          `${separation}?page=${page}&page_size=${pageSize}&is_tl=true`
        );
        console.log("Fetched resignations:", response.data); // Debug log
        setResignations(response.data.results);
        setPagination((prev) => ({
          ...prev,
          current: page,
          pageSize: pageSize,
          total: response.data.count,
        }));
      } catch (error) {
        console.error("Error fetching resignations:", error);
        toast.error("Error fetching resignations");
      } finally {
        setLoading(false);
      }
    },
    [departmentID] // Remove pagination from dependencies
  );

  // Update handleTableChange
  const handleTableChange = useCallback(
    (newPagination, filters, sorter) => {
      console.log("Pagination changed:", newPagination); // Debug log
      fetchResignations(newPagination.current, newPagination.pageSize);
    },
    [fetchResignations]
  );

  const handleSuccess = useCallback(async () => {
    try {
      setIsEditing(false);

      if (selectedResignation) {
        const handoverResponse = await axiosInstance.get(
          `${handover}?separation=${selectedResignation.id}`
        );
        setHandoverData(handoverResponse.data.results[0]);

        const resignationResponse = await axiosInstance.get(
          `${separation}${selectedResignation.id}/`
        );
        setSelectedResignation(resignationResponse.data);
      }

      await fetchResignations(pagination.current, pagination.pageSize);

      toast.success("Handover form updated successfully");
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data");
    }
  }, [
    selectedResignation,
    pagination.current,
    pagination.pageSize,
    fetchResignations,
  ]);

  const handleEditClick = useCallback(() => {
    if (!handoverData) {
      toast.error("No handover data available to edit");
      return;
    }
    setIsEditing(true);
  }, [handoverData]);

  // First fetch department ID
  useEffect(() => {
    const fetchDepartmentID = async () => {
      try {
        const response = await axiosInstance.get(employeeDetails);
        const deptId = response.data.employment_details[0].department_detail.id;
        setDepartmentID(deptId);
      } catch (error) {
        console.error("Error fetching employee details:", error);
        toast.error("Failed to fetch employee details");
      }
    };

    fetchDepartmentID();
  }, []);

  // Only fetch resignations when departmentID is available
  useEffect(() => {
    if (departmentID) {
      fetchResignations(1, pagination.pageSize);
      fetchEmployees();
    }
  }, [departmentID]);

  // Fetch handover data when a resignation is selected
  useEffect(() => {
    const fetchHandoverData = async () => {
      if (selectedResignation) {
        try {
          const response = await axiosInstance.get(
            `${handover}?separation=${selectedResignation.id}`
          );
          setHandoverData(response.data.results[0]);
        } catch (error) {
          console.error("Error fetching handover data:", error);
          toast.error("Failed to fetch handover data");
        }
      }
    };

    fetchHandoverData();
  }, [selectedResignation]);

  const handleView = (record) => {
    console.log("View button clicked", record);
    setSelectedResignation(record);
    setActiveTabKey("1");
    setViewModalVisible(true);
  };

  const handleViewHandover = (record) => {
    console.log("View handover clicked", record);
    setSelectedResignation(record);
    setActiveTabKey("2");
    setViewModalVisible(true);
  };

  const handleHandoverClick = (record) => {
    console.log("Fill handover clicked", record);
    setSelectedResignation(record);
    setActiveTabKey("2");
    setViewModalVisible(true);
  };

  const handleHandoverModalClose = () => {
    setShowHandoverModal(false);
    setSelectedHandover(null);
  };

  const handleModalClose = useCallback(async () => {
    setViewModalVisible(false);
    setSelectedEmployee(null);
    setIsEditing(false);

    // Refresh separation data
    try {
      await fetchResignations(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error refreshing resignation data:", error);
      toast.error("Failed to refresh resignation data");
    }
  }, [pagination.current, pagination.pageSize, fetchResignations]);

  const handleSubmitReview = useCallback(async () => {
    if (!selectedEmployee) {
      toast.error("Please select a handover person");
      return;
    }

    if (!lastWorkingDate) {
      toast.error("Please select last working date");
      return;
    }

    setSubmitting(true);

    const formData = new FormData();
    formData.append("handover_person", selectedEmployee);
    formData.append("team_leader", selectedTeamLeader);
    formData.append("last_working_date", lastWorkingDate.format("YYYY-MM-DD"));
    formData.append("is_forward", true);
    formData.append("remarks", remarks);
    try {
      await axiosInstance.patch(
        `${separation}${selectedResignation.id}/`,
        formData
      );

      toast.success("Resignation reviewed successfully");
      handleModalClose();
      fetchResignations(pagination.current, pagination.pageSize);
    } catch (error) {
      console.error("Error updating resignation:", error);
      toast.error("Error updating resignation");
    } finally {
      setSubmitting(false);
    }
  }, [
    selectedEmployee,
    lastWorkingDate,
    selectedResignation,
    pagination.current,
    pagination.pageSize,
    fetchResignations,
    handleModalClose,
    remarks,
  ]);

  const fetchHandoverData = useCallback(async (separationId) => {
    try {
      const response = await axiosInstance.get(
        `${handover}?separation=${separationId}`
      );
      setHandoverData(response.data.results[0]);
    } catch (error) {
      console.error("Error fetching handover data:", error);
      toast.error("Failed to fetch handover data");
    }
  }, []);

  const handleApproveHandover = useCallback(async () => {
    if (!handoverData) return;

    try {
      await axiosInstance.patch(`${handover}${handoverData.id}/`, {
        is_tl_approved: true,
        is_hr_approved: true,
      });

      await axiosInstance.patch(`${separation}${selectedResignation.id}/`, {
        status: "Handover Approval",
      });

      toast.success("Handover approved successfully");
      setHandoverFormKey((prev) => prev + 1);
      fetchResignations();
      setViewModalVisible(false);
    } catch (error) {
      console.error("Error approving handover:", error);
      toast.error("Failed to approve handover");
    }
  }, [handoverData, fetchResignations]);

  const renderApproveHandoverButton = () => {
    if (
      selectedResignation?.status === "Handover" &&
      handoverData?.is_tl_approved === false
    ) {
      return (
        <>
          <div className="bg-orange text-white py-2 ps-2 mb-3 br-5">
            Note - Please confirm that all the above forms have been accurately
            completed. Check the box below to acknowledge your confirmation
            before submitting for further processing.
          </div>
          <div
            className="text-white py-2 ps-2 mb-3"
            style={{
              backgroundColor: "#808080",
              borderRadius: 5,
            }}
          >
            Have the company credentials been shared by the Habotech leaving
            with the Habotech taking over? Also, has the two-step verification
            phone number for the company email ID been changed?
          </div>
          <Button
            type="primary"
            onClick={handleApproveHandover}
            style={{
              backgroundColor: "#52c41a",
              borderColor: "#52c41a",
              marginLeft: "10px",
              marginTop: "16px",
            }}
          >
            Confirm Handover
          </Button>
        </>
      );
    }
    return null;
  };

  useEffect(() => {
    if (selectedResignation) {
      fetchHandoverData(selectedResignation.id);
    }
  }, [selectedResignation, fetchHandoverData]);

  const handleEmployeeSelect = useCallback((value) => {
    setSelectedEmployee(value);
  }, []);

  const handleTeamLeaderSelect = useCallback((value) => {
    setSelectedTeamLeader(value);
  }, []);

  const columns = [
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
      responsive: ["md"],
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
      responsive: ["lg"],
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => <Tag color={getStatusColor(status)}>{status}</Tag>,
    },
    {
      title: "Submitted Date",
      dataIndex: "created_at",
      key: "created_at",
      render: (date) => moment(date).format("DD-MM-YYYY"),
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space>
          <Button type="primary" onClick={() => handleView(record)}>
            View
          </Button>
          {record.status === "Handover" && record.is_handover && (
            <Button
              variant="info"
              onClick={() => handleViewHandover(record)}
              style={{ backgroundColor: "#1890ff", color: "white" }}
            >
              View Handover
            </Button>
          )}
          {record.status === "Approved" &&
            record.experience_letter === null && (
              <Button
                type="primary"
                onClick={() => handleView(record)}
                style={{ backgroundColor: "#FF9F45" }}
              >
                Upload Experience Letter
              </Button>
            )}
        </Space>
      ),
    },
  ];

  console.log("Rendering ViewResignationLetterTL, isEditing:", isEditing);

  const refreshData = async () => {
    try {
      await fetchResignations(pagination.current, pagination.pageSize);
      if (selectedResignation) {
        const response = await axiosInstance.get(
          `${separation}${selectedResignation.id}/`
        );
        setSelectedResignation(response.data);
      }
    } catch (error) {
      console.error("Error refreshing data:", error);
      toast.error("Failed to refresh data");
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get(employeeList);
      const activeEmployees = response.data.filter(
        (emp) => emp.employment_details[0]?.employment_status === "Active"
      );

      setEmployees(activeEmployees);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Error fetching employees list");
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Review Resignations"
            title="Dashboard"
            subtitle="Resignations"
          />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={resignations}
                  loading={loading || !departmentID}
                  rowKey="id"
                  pagination={{
                    ...pagination,
                    showTotal: (total) => `Total ${total} items`,
                    showSizeChanger: true,
                    pageSizeOptions: ["10", "20", "50", "100"],
                    responsive: true,
                    position: ["bottom", "right"],
                    onChange: (page, pageSize) => {
                      handleTableChange({ current: page, pageSize });
                    },
                    onShowSizeChange: (current, size) => {
                      handleTableChange({ current: 1, pageSize: size });
                    },
                  }}
                  onChange={handleTableChange}
                  scroll={{ x: true }}
                />
              </div>
            </div>
          </div>

          <ViewModal
            show={viewModalVisible}
            onHide={handleModalClose}
            resignation={selectedResignation}
            employees={employees}
            onSubmit={handleSubmitReview}
            onApproveHandover={renderApproveHandoverButton}
            onEmployeeSelect={handleEmployeeSelect}
            onTeamLeaderSelect={handleTeamLeaderSelect}
            selectedEmployee={selectedEmployee}
            selectedTeamLeader={selectedTeamLeader}
            activeTabKey={activeTabKey}
            setActiveTabKey={setActiveTabKey}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            handleEditClick={handleEditClick}
            handleSuccess={handleSuccess}
            handoverData={handoverData}
            lastWorkingDate={lastWorkingDate}
            setLastWorkingDate={setLastWorkingDate}
            submitting={submitting}
            refreshTable={refreshData}
            remarks={remarks}
            setRemarks={setRemarks}
          />
        </div>

        <style jsx>{`
          .responsive-content {
            width: 100%;
            max-width: 100%;
            overflow-x: hidden;
            padding: 16px;
          }

          @media (max-width: 768px) {
            .responsive-content {
              padding: 12px;
            }
          }

          @media (max-width: 576px) {
            .responsive-content {
              padding: 8px;
            }
          }

          .table-responsive {
            overflow-x: auto;
            -webkit-overflow-scrolling: touch;
          }
        `}</style>
      </div>
    </>
  );
};

export default ViewResignationHRforTL;
