import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { employeeList } from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const HRAttendance = () => {
  const [employeesByDepartment, setEmployeesByDepartment] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const columns = [
    //sr no.
    {
      title: "Sr No.",
      dataIndex: "sr_no",
      key: "sr_no",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: "employee_name",
      key: "employee_name",
      sorter: (a, b) => a.employee_name.localeCompare(b.employee_name),
    },
    {
      title: "Designation",
      key: "designation",
      render: (record) =>
        record.employment_details[0]?.designation_detail?.name || "-",
      sorter: (a, b) => {
        const desigA = a.employment_details[0]?.designation_detail?.name || "";
        const desigB = b.employment_details[0]?.designation_detail?.name || "";
        return desigA.localeCompare(desigB);
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <Link
          to={`/hr-employee-attendance/${record.id}`}
          className="btn btn-primary btn-sm"
        >
          View Attendance
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const fetchEmployees = async () => {
      setIsLoading(true);
      try {
        const response = await axiosInstance.get(employeeList);

        // Filter out test department and inactive employees
        const activeEmployees = response.data.filter(
          (emp) =>
            emp.employment_details[0]?.employment_status === "Active" &&
            emp.employment_details[0]?.department_detail?.name.toLowerCase() !==
              "test department"
        );

        // Sort employees by department name
        const sortedByDept = activeEmployees.sort((a, b) => {
          const deptA = a.employment_details[0]?.department_detail?.name || "";
          const deptB = b.employment_details[0]?.department_detail?.name || "";
          return deptA.localeCompare(deptB);
        });

        // Group by department
        const grouped = sortedByDept.reduce((acc, emp) => {
          const deptName =
            emp.employment_details[0]?.department_detail?.name || "Other";
          if (!acc[deptName]) {
            acc[deptName] = [];
          }
          acc[deptName].push(emp);
          return acc;
        }, {});

        setEmployeesByDepartment(grouped);
      } catch (err) {
        console.error("Error fetching employees:", err);
        setError("Failed to fetch employee data");
      } finally {
        setIsLoading(false);
      }
    };

    fetchEmployees();
  }, []);

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Header Section */}
          <div className="page-header">
            <div className="row align-items-center">
              <div className="col">
                <h3 className="page-title">Employee Attendance</h3>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <a href="/dashboard">Dashboard</a>
                  </li>
                  <li className="breadcrumb-item active">Attendance</li>
                </ul>
              </div>
            </div>
          </div>

          {/* Error Message */}
          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}

          {/* Department-wise Tables */}
          {Object.entries(employeesByDepartment).map(
            ([department, employees]) => (
              <div key={department} className="card mb-4">
                <div className="card-header">
                  <h4 className="card-title mb-0">{department}</h4>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <Table
                      columns={columns}
                      dataSource={employees}
                      loading={isLoading}
                      rowKey="id"
                      pagination={false}
                      className="table-striped"
                    />
                  </div>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default HRAttendance;
