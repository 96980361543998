import React from "react";
import { Modal, Input, Button, Typography } from "antd";

const inputStyle = {
  width: "100%",
  height: "40px",
  marginBottom: "15px",
  borderRadius: "5px",
  margin: "10px 20px 10px 2px",
};

const loginButtonStyle = {
  width: "100%",
  backgroundColor: "#EB7F5E",
  color: "white",
  height: "40px",
  fontWeight: "bold",
  borderRadius: "5px",
  border: "none",
  cursor: "pointer",
  margin: "40px 0px 20px 0px",
};

const CandidateLogin = () => {
  return (
    <div style={{}}>
      <div style={{ textAlign: "center" }}>
        <h2 style={{ fontWeight: "", margin: "10px 0px 50px 0px" }}>
          Hi, welcome back!!
        </h2>
        <Input placeholder="Email ID" style={inputStyle} />
        <Input placeholder="Password" type="password" style={inputStyle} />
        <Button type="primary" style={loginButtonStyle}>
          Login
        </Button>
      </div>
    </div>
  );
};

export default CandidateLogin;
