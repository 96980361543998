import React from "react";
import { Typography, Divider, Space } from "antd";
import moment from "moment";

const { Title, Paragraph } = Typography;

const ResignationLetter = ({
  employeeName,
  employeeId,
  department,
  designation,
  submissionDate,
}) => {
  return (
    <div>
      <Typography>
        <Title level={3}>Resignation Acceptance Letter</Title>

        <Paragraph>Date: {moment().format("DD-MM-YYYY")}</Paragraph>

        <Paragraph>Dear {employeeName},</Paragraph>

        <Paragraph>
          This letter is to confirm the acceptance of your resignation from the
          position of {designation} in the {department} department, effective
          from {moment(submissionDate).format("DD-MM-YYYY")}.
        </Paragraph>

        <Paragraph>
          We acknowledge receipt of your resignation letter and would like to
          thank you for your valuable contributions during your tenure with us.
        </Paragraph>

        <Paragraph>
          Please ensure to complete the following before your last working day:
          <ul>
            <li>Complete all pending assignments</li>
            <li>Submit handover documents</li>
            <li>Return company assets</li>
            <li>Complete exit formalities</li>
          </ul>
        </Paragraph>

        <Paragraph>We wish you success in your future endeavors.</Paragraph>

        <Divider />

        <Paragraph>
          Best Regards,
          <br />
          HR Department
          <br />
          [Company Name]
        </Paragraph>
      </Typography>
    </div>
  );
};

export default ResignationLetter;
