import React, { useState } from "react";
import { Input, Row, Col, DatePicker, Button, Card } from "antd";

const PersonalDetails = () => {
  const [isEditing, setIsEditing] = useState(false);

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => setIsEditing(false);

  return (
    <Card
      title="Visa Details"
      extra={
        isEditing ? (
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        ) : (
          <Button onClick={handleEdit}>Edit</Button>
        )
      }
      style={{ padding: 20 }}
    >
      <Button
        style={{ background: "#1E2B32", color: "#fff", marginBottom: 16 }}
      >
        Visa details
      </Button>
      <Button
        style={{
          background: "#FD715C",
          color: "#fff",
          marginBottom: 18,
          marginLeft: "10px",
        }}
      >
        View
      </Button>
      <Row gutter={24} style={{ marginBottom: 18 }}>
        <Col xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}>Visa Number</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}>Issue Date</p>
          <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}>Expiry Date</p>
          <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
        </Col>
      </Row>
      <Button
        style={{ background: "#1E2B32", color: "#fff", marginBottom: 16 }}
      >
        Passport Details
      </Button>
      <Button
        style={{
          background: "#FD715C",
          color: "#fff",
          marginBottom: 18,
          marginLeft: "10px",
        }}
      >
        View
      </Button>
      <Row gutter={24}>
        <Col xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}>Passport Number</p>
          <Input disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}>Issue Date</p>
          <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}>Expiry Date</p>
          <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
        </Col>
        <Col xs={12} md={6}>
          <p style={{ fontWeight: "bold" }}>
            Country Of Passport or National ID
          </p>
          <Input disabled={!isEditing} />
        </Col>
      </Row>
    </Card>
  );
};

export default PersonalDetails;
