import { useState, useEffect } from "react";
import {
  Card,
  Button,
  Upload,
  Select,
  Input,
  message,
  Form,
  Spin,
  Tooltip,
  Row,
  Col,
  Typography,
  Tabs,
  Alert,
} from "antd";
import {
  InfoCircleOutlined,
  InboxOutlined,
  UploadOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import { useParams, useNavigate } from "react-router-dom";
import {
  vendordocumentsProfile,
  departmentList,
} from "../../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import GaclSideBar from "../../../../layout/GaclSidebar";
import { toast } from "react-toastify";

const { TabPane } = Tabs;
const { Option } = Select;
const { Title, Text } = Typography;
const { Dragger } = Upload;

const THEME_COLOR = "#FF902E";
const ERROR_COLOR = "#ff4d4f";

const VendorDocumentUpload = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState("new");
  const [submitloading, setSubmitLoading] = useState(false);
  const navigate = useNavigate();

  const accountTypeOptions = [
    { value: "administrative_expenses", label: "Administrative Expenses" },
    { value: "auditing_expenses", label: "Auditing Expenses" },
    {
      value: "employees_medical_insurance",
      label: "Employees Medical Insurance",
    },
    {
      value: "employees_visas_permits_ids",
      label: "Employees Visa's, Permits & ID's",
    },
    {
      value: "expenses_recover_singhania_clinic",
      label: "Expenses to Recover From Singhania Clinic",
    },
    { value: "license_and_permits", label: "License and Permits" },
    {
      value: "marketing_advertising_expenses",
      label: "Marketing & Advertising Expenses",
    },
    {
      value: "offshore",
      label: "Offshore",
    },
    {
      value: "freezone",
      label: "Freezone",
    },
    {
      value: "office_rent",
      label: "Office Rent",
    },

    {
      value: "online_platform_website_expenses",
      label: "Online Platform/Website Expenses",
    },
    { value: "other_expenses", label: "Other Expenses" },
    { value: "postage", label: "Postage" },
    {
      value: "software_infrastructure_expenses",
      label: "Software Infrastructure Expenses",
    },
    { value: "telephone_expenses", label: "Telephone Expenses" },
    { value: "training_costs", label: "Training Costs" },
    { value: "vendor_outsourcee_expense", label: "Vendor Outsourcee Expense" },
  ];

  useEffect(() => {
    const fetchDepartments = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await axiosInstance.get(departmentList);
        setDepartments(response.data);
      } catch (error) {
        const errorMessage =
          error.response?.data?.message || "Failed to fetch departments";
        setError(errorMessage);
        message.error(errorMessage);
      } finally {
        setLoading(false);
      }
    };

    fetchDepartments();
  }, []);

  const handleFileUpload = ({ file, fileList: newFileList }) => {
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
      message.error("File must be smaller than 5MB!");
      return;
    }

    const isValidType = [
      "application/pdf",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "image/jpeg",
      "image/png",
    ].includes(file.type);

    if (!isValidType) {
      message.error("You can only upload PDF, DOC, DOCX, JPG, or PNG files!");
      return;
    }

    setFileList(newFileList);
  };

  const handleSubmit = async (values) => {
    setError(null);
    try {
      setSubmitLoading(true);
      const formData = new FormData();

      formData.append("account_type", values.expenseHead);
      formData.append("department", values.department);

      if (fileList.length > 0) {
        formData.append("invoice_document", fileList[0].originFileObj);
      }

      await axiosInstance.post(vendordocumentsProfile, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      toast.success("Documents uploaded successfully!");
      form.resetFields();
      setFileList([]);
    } catch (error) {
      if (error.response?.data) {
        const errorData = error.response.data;
        const errorMessages = Object.entries(errorData).map(([key, value]) => ({
          field: key,
          message: Array.isArray(value) ? value.join(", ") : value,
        }));
        setError(errorMessages);
      } else {
        setError([{ field: "general", message: "Failed to upload documents" }]);
      }
    } finally {
      setSubmitLoading(false);
    }
  };

  const renderErrors = (errors) => {
    if (!errors) return null;

    return errors.map((error, index) => (
      <Alert
        key={index}
        description={<Text style={{ color: "#ff4d4f" }}>{error.message}</Text>}
        type="error"
        showIcon
        closable
        style={{
          marginBottom: 16,
          backgroundColor: "transparent",
          border: "none",
          padding: "0px",
        }}
      />
    ));
  };
  const uploadProps = {
    accept: ".pdf,.doc,.docx,.jpg,.jpeg,.png",
    multiple: false,
    onChange: handleFileUpload,
    fileList: fileList,
    maxCount: 1,
    beforeUpload: () => false,
  };

  const handleGoToVendors = () => {
    navigate("/all-vendors");
  };

  const showError = false;
  const renderForm = () => (
    <Form form={form} layout="vertical" onFinish={handleSubmit}>
      <Card
        style={{
          marginBottom: 24,
          borderRadius: 8,
          backgroundColor: "#fff",
          boxShadow: "none",
        }}
      >
        <Form.Item
          name="invoice"
          rules={[{ required: true, message: "Please upload an invoice" }]}
        >
          <Dragger
            {...uploadProps}
            disabled={showError}
            style={{
              padding: 24,
              background: showError ? "#f5f5f5" : "#ffffff",
              border: `2px dashed ${showError ? "#d9d9d9" : THEME_COLOR}`,
              borderRadius: 8,
              cursor: showError ? "not-allowed" : "pointer",
            }}
          >
            <p>
              <InboxOutlined
                style={{
                  color: showError ? "#d9d9d9" : THEME_COLOR,
                  fontSize: 48,
                }}
              />
            </p>
            <Title level={5} style={{ color: showError ? "#d9d9d9" : "gray" }}>
              Click or Drag File to Upload
            </Title>
            <Text
              type="secondary"
              style={{ color: showError ? "#d9d9d9" : undefined }}
            >
              Support for PDF, DOC, DOCX, JPG, JPEG, PNG files (Max: 5MB)
            </Text>
          </Dragger>
        </Form.Item>
      </Card>

      <Row gutter={24}>
        <Col xs={24} md={12}>
          <Form.Item
            label={
              <Text strong>
                Document Name
                <Tooltip title="Enter your document name">
                  <InfoCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
              </Text>
            }
            name="document"
            rules={[{ required: true, message: "Please enter document name" }]}
          >
            <Input
              placeholder="Enter document name"
              size="large"
              disabled={showError}
              style={{ width: "100%" }}
            />
          </Form.Item>
        </Col>
        <Col xs={24} md={12}>
          <Form.Item
            label={
              <Text strong>
                Expense Account Type
                <Tooltip title="Select the expense account head">
                  <InfoCircleOutlined style={{ marginLeft: 8 }} />
                </Tooltip>
              </Text>
            }
            name="expenseHead"
            rules={[{ required: true, message: "Please select expense head" }]}
          >
            <Select
              placeholder="Select expense head"
              showSearch
              optionFilterProp="children"
              size="large"
              disabled={showError}
              style={{
                width: "100%",
              }}
              dropdownStyle={{
                maxHeight: 400,
                overflow: "auto",
              }}
            >
              {accountTypeOptions.map((option) => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      {showError && (
        <div
          style={{ marginBottom: 24, display: "flex", flexDirection: "column" }}
        >
          <span
            style={{
              marginBottom: 16,
              borderRadius: 6,
              padding: "12px 24px",
              color: "red",
            }}
          >
            Please select a vendor before uploading documents
          </span>
          <Button
            type="primary"
            onClick={handleGoToVendors}
            size="large"
            style={{
              height: 45,
              borderRadius: 6,
              backgroundColor: THEME_COLOR,
              borderColor: THEME_COLOR,
              width: "fit-content",
              padding: "0 32px",
            }}
          >
            Go to Vendors List
          </Button>
        </div>
      )}

      {error && <div style={{ marginBottom: 24 }}>{renderErrors(error)}</div>}

      {!showError && (
        <Row justify="start" style={{ marginTop: 32 }}>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            disabled={submitloading || showError}
            size="large"
            icon={<UploadOutlined />}
            style={{
              padding: "0 40px",
              height: 45,
              borderRadius: 6,
              fontWeight: 500,
              backgroundColor: THEME_COLOR,
              borderColor: THEME_COLOR,
            }}
          >
            Submit
          </Button>
        </Row>
      )}
    </Form>
  );

  return (
    <>
      <GaclSideBar />
      <div
        style={{
          padding: 24,
          minHeight: "100vh",
          marginTop: 60,
        }}
        className="page-wrapper"
      >
        <Row>
          <Col xs={24}>
            <Card
              bordered={false}
              style={{
                borderRadius: 15,
              }}
            >
              <Button
                style={{
                  backgroundColor: "#FD705C",
                  width: "180px",
                  height: "44px",
                  backgroundColor: "#FD705C",
                  color: "white",
                  marginBottom: "30px",
                }}
              >
                Add Invoice
              </Button>

              {renderForm()}
            </Card>
          </Col>
        </Row>

        {loading && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              background: "rgba(255, 255, 255, 0.8)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 1000,
            }}
          >
            <Spin size="large" />
          </div>
        )}
      </div>
    </>
  );
};

export default VendorDocumentUpload;
