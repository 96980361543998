import React, { useState, useEffect, useCallback } from "react";
import Header from "../../../layout/Header";
import TechnicalSideBar from "../../../layout/TechnicalSideBar";
import { TrackInitProvider } from "../../../../contexts/TrackInitContext";
import TechnicalTrackManagement from "./TechnicalTrackManagement";
import { Card, Row, Col, Typography, Tag, Spin } from "antd";
import { useParams } from "react-router-dom";
import { RightOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { trackInitialisation } from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";

const { Title, Text } = Typography;

const TechTrackManagement = () => {
  const { trackId } = useParams();
  const [trackDetails, setTrackDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchTrackDetails = useCallback(async () => {
    try {
      const response = await axiosInstance.get(
        `${trackInitialisation}${trackId}/`
      );
      setTrackDetails(response.data);
      console.log(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching track details:", error);
      setIsLoading(false);
    }
  }, [trackId]);

  useEffect(() => {
    fetchTrackDetails();
  }, [fetchTrackDetails]);

  return (
    <>
      <Header />
      <TrackInitProvider>
        <TechnicalSideBar />
        <div className="page-wrapper">
          <div className="content container-fluid position-relative">
            {isLoading ? (
              <Spin size="large" />
            ) : (
              <>
                <TrackInfoCard trackDetails={trackDetails} />
                <TechnicalTrackManagement />
              </>
            )}
          </div>
        </div>
      </TrackInitProvider>
    </>
  );
};

const TrackInfoCard = ({ trackDetails }) => {
  if (!trackDetails) return null;

  const calculateTotalTime = () => {
    if (!trackDetails.is_done) return null;

    const start = moment(trackDetails.created_at);
    const end = moment(trackDetails.updated_at);
    const duration = moment.duration(end.diff(start));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();

    let totalTime = "";
    if (days > 0) totalTime += `${days}d `;
    if (hours > 0) totalTime += `${hours}h `;
    if (minutes > 0) totalTime += `${minutes}m`;

    return totalTime.trim() || "Less than a minute";
  };

  const totalTime = calculateTotalTime();
  return (
    <Card
      className="track-info-card mb-4"
      style={{ background: "#f0f5ff", borderRadius: "10px" }}
    >
      <Row align="middle" justify="space-between">
        <Col>
          <Title level={3} style={{ margin: 0 }}>
            Track: {trackDetails.name}
          </Title>
          <Text type="secondary" style={{ display: "block" }}>
            Created by: {trackDetails.created_details?.employee_name}
          </Text>
        </Col>
        <Col>
          <Row gutter={16} align="middle">
            <Col>
              <Text>Created: {trackDetails.created_at?.split("T")[0]}</Text>
            </Col>
            {trackDetails.is_done && (
              <>
                <Col>
                  <Text>Ends: {trackDetails.updated_at?.split("T")[0]}</Text>
                </Col>
                <Col>
                  <Text>Total Time: {totalTime}</Text>
                </Col>
              </>
            )}
            <Col>
              <Tag color={trackDetails.is_done ? "success" : "processing"}>
                {trackDetails.is_done ? "Completed" : "In Progress"}
              </Tag>
            </Col>
            <Col>
              <RightOutlined style={{ fontSize: "24px", color: "#1890ff" }} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

export default TechTrackManagement;
