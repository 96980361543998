import { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import Select from "react-select";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  vendorMtos,
  vendorMtoAssignment,
} from "../../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

function ModalAddVendorDetails({ show, onHide, vendorId, refreshlist }) {
  const [mtoDetails, setMtoDetails] = useState({ name: "", email: "" });
  const [mtoOptions, setMtoOptions] = useState([]);
  const [selectedMtos, setSelectedMtos] = useState([]);
  const [showMtoForm, setShowMtoForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    const fetchMTOs = async () => {
      try {
        const response = await axiosInstance.get(vendorMtos);
        const options = response.data.results.map((mto) => ({
          value: mto.id,
          label: `${mto.name} (${mto.email})`,
        }));
        setMtoOptions(options);
      } catch (error) {
        toast.error("Failed to fetch MTOs");
      }
    };

    if (show) {
      fetchMTOs();
    }
  }, [show]);

  const handleSelectChange = (selectedOptions) => {
    setSelectedMtos(selectedOptions);
  };

  const handleMtoChange = (field, value) => {
    setMtoDetails({ ...mtoDetails, [field]: value });
  };

  // create new mtos
  const handleSubmitMto = async () => {
    try {
      const response = await axiosInstance.post(vendorMtos, {
        name: mtoDetails.name,
        email: mtoDetails.email,
      });

      const newMto = {
        value: response.data.id,
        label: `${mtoDetails.name} (${mtoDetails.email})`,
      };

      setMtoOptions((prevOptions) => [...prevOptions, newMto]);

      toast.success("MTO added successfully");
      handleClose();
      setMtoDetails({ name: "", email: "" });
      setShowMtoForm(false);
    } catch (error) {
      console.error("Failed to add MTO:", error);
      toast.error("Failed to add MTO");
      handleClose();
    }
  };

  const customOption = (props) => {
    const { data, innerRef, innerProps } = props;
    const isSelected = selectedMtos.some((mto) => mto.value === data.value);

    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{ display: "flex", alignItems: "center", padding: "5px" }}
      >
        <input
          type="checkbox"
          checked={isSelected}
          readOnly
          style={{ marginRight: 10 }}
        />
        {data.label}
      </div>
    );
  };

  // task assign
  const handleSubmit = async () => {
    if (!vendorId || selectedMtos.length < 3) {
      toast.error("Please select at least 3 MTO email IDs before assigning .");
      return;
    }

    const payload = {
      vendor_profile: vendorId,
      assigned_to: selectedMtos.map((mto) => mto.value),
    };

    try {
      await axiosInstance.post(vendorMtoAssignment, payload);
      refreshlist({
        tc_status: "pending",
      });
      toast.success("Extraction Task assigned successfully");
      setErrorMessage("");
      handleClose();
      onHide();
    } catch (error) {
      console.error("Failed to assign MTO:", error);
      toast.error("Failed to assign MTO");
      if (
        error.response &&
        error.response.data &&
        error.response.data.non_field_errors
      ) {
        setErrorMessage(error.response.data.non_field_errors[0]);
      } else {
        setErrorMessage("Failed to assign MTO.");
      }
    }
  };

  const handleClose = () => {
    setSelectedMtos([]);
    setMtoDetails({ name: "", email: "" });
    setShowMtoForm(false);
    onHide();
  };
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body className="p-3">
        <p>MTO E-mail ID</p>

        <Select
          className="mb-2"
          placeholder="Select..."
          options={mtoOptions}
          isMulti
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          components={{ Option: customOption }}
          value={selectedMtos}
          onChange={handleSelectChange}
          filterOption={(option, inputValue) =>
            option.label.toLowerCase().includes(inputValue.toLowerCase())
          }
        />
        {errorMessage && <small className="text-danger">{errorMessage}</small>}
        <br />
        <Button
          variant="secondary"
          size="sm"
          className="px-4"
          onClick={() => setShowMtoForm(!showMtoForm)}
        >
          {showMtoForm ? "Hide Form" : "Add more"}
        </Button>

        {showMtoForm && (
          <>
            <hr />

            <input
              type="text"
              className="form-control"
              placeholder="Please add the new MTO below if required"
              disabled
            />

            <div className="row d-flex justify-content-center mt-2">
              <div className="col-md-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">MTO Name</label>
                  <input
                    type="text"
                    className="form-control"
                    value={mtoDetails.name}
                    onChange={(e) => handleMtoChange("name", e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">MTO E-mail ID</label>
                  <input
                    type="text"
                    className="form-control"
                    value={mtoDetails.email}
                    onChange={(e) => handleMtoChange("email", e.target.value)}
                  />
                </div>
              </div>
            </div>

            <div>
              <Button className="px-4" size="sm" onClick={handleSubmitMto}>
                Add
              </Button>
            </div>
          </>
        )}

        <span className="d-flex justify-content-center">
          <Button className="px-5 mb-2 mx-2" size="sm" onClick={handleSubmit}>
            Submit
          </Button>
        </span>
      </Modal.Body>
    </Modal>
  );
}

export default ModalAddVendorDetails;
