import React, { useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Typography,
  DatePicker,
  Card,
} from "antd";

const { Text } = Typography;

const EmployeeQualification = () => {
  const [form] = Form.useForm();
  const [isEditing, setIsEditing] = useState(false);
  const [qualifications, setQualifications] = useState([{}]);
  const [experiences, setExperiences] = useState([{}]);

  const handleEdit = () => setIsEditing(true);
  const handleSave = () => {
    form.validateFields().then(() => setIsEditing(false));
  };

  const addQualification = () => {
    setQualifications([...qualifications, {}]);
  };

  const removeQualification = (index) => {
    if (qualifications.length > 1) {
      setQualifications(qualifications.filter((_, i) => i !== index));
    }
  };

  const addExperience = () => {
    setExperiences([...experiences, {}]);
  };

  const removeExperience = (index) => {
    if (experiences.length > 1) {
      setExperiences(experiences.filter((_, i) => i !== index));
    }
  };

  return (
    <Card
      title="Employee Qualification & Experience"
      extra={
        isEditing ? (
          <Button type="primary" onClick={handleSave}>
            Save
          </Button>
        ) : (
          <Button onClick={handleEdit}>Edit</Button>
        )
      }
    >
      <Form
        form={form}
        layout="vertical"
        style={{ maxWidth: "90%", margin: "auto" }}
      >
        <Text
          strong
          style={{
            background: "#1D2B2D",
            color: "white",
            padding: "6px 12px",
            borderRadius: "4px",
            display: "inline-block",
            marginBottom: "12px",
          }}
        >
          Employee Qualification
        </Text>
        {qualifications.map((_, index) => (
          <Card key={index} style={{ margin: "10px 0px" }}>
            <Row gutter={24}>
              <Col xs={12} md={6}>
                <Form.Item label="Qualification Type">
                  <Input disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item label="Institution Name">
                  <Input disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item label="Certificate Title">
                  <Input disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item label="Field of Study">
                  <Input disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item label="From">
                  <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item label="To">
                  <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col span={16}>
                {qualifications.length > 1 && isEditing && (
                  <Button
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      marginBottom: "20px",
                    }}
                    onClick={() => removeQualification(index)}
                  >
                    Remove
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        ))}
        {isEditing && (
          <Button
            onClick={addQualification}
            style={{
              backgroundColor: "#FF6F40",
              color: "white",
              border: "none",
            }}
          >
            Add More
          </Button>
        )}
        <br />

        <Text
          strong
          style={{
            background: "#1D2B2D",
            color: "white",
            padding: "6px 12px",
            borderRadius: "4px",
            display: "inline-block",
            marginTop: "20px",
            marginBottom: "12px",
          }}
        >
          Work Experience
        </Text>
        {experiences.map((_, index) => (
          <Card key={index} style={{ margin: "10px 0px" }}>
            <Row gutter={24}>
              <Col xs={12} md={6}>
                <Form.Item label="Company Name">
                  <Input disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item label="Designation">
                  <Input disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item label="Start Date">
                  <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col xs={12} md={6}>
                <Form.Item label="End Date">
                  <DatePicker style={{ width: "100%" }} disabled={!isEditing} />
                </Form.Item>
              </Col>
              <Col span={16}>
                {experiences.length > 1 && isEditing && (
                  <Button
                    style={{
                      backgroundColor: "orange",
                      color: "white",
                      marginBottom: "20px",
                    }}
                    onClick={() => removeExperience(index)}
                  >
                    Remove
                  </Button>
                )}
              </Col>
            </Row>
          </Card>
        ))}
        {isEditing && (
          <Button
            onClick={addExperience}
            style={{
              backgroundColor: "#FF6F40",
              color: "white",
              border: "none",
            }}
          >
            Add More
          </Button>
        )}
      </Form>
    </Card>
  );
};

export default EmployeeQualification;
