import React, { useState, useEffect, useCallback } from "react";
import { Table, Spin, Tabs } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  addLeaves,
  departmentList,
} from "../../../../helpers/endpoints/api_endpoints";
import moment from "moment";
import { toast } from "react-toastify";
import Header from "../../../layout/Header";

const EmployeeOnLeaves = () => {
  const [employeeLeaves, setEmployeeLeaves] = useState([]);
  const [loading, setLoading] = useState(true);
  const [departments, setDepartments] = useState([]);
  const [activeTab, setActiveTab] = useState("All");

  // Fetch departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axiosInstance.get(departmentList);
        const filteredDepartments = response.data.filter(
          (dept) => dept.name.toLowerCase() !== "test department"
        );
        setDepartments(filteredDepartments);
      } catch (error) {
        console.error("Error fetching departments:", error);
        toast.error("Failed to fetch departments");
      }
    };
    fetchDepartments();
  }, []);

  const fetchEmployeeLeaves = useCallback(async () => {
    setLoading(true);
    try {
      const params = new URLSearchParams({
        status: "Approved",
        page: 1,
        page_size: 10,
      });

      if (activeTab !== "All") {
        params.append("department", activeTab);
      }

      const response = await axiosInstance.get(
        `${addLeaves}?${params.toString()}`
      );
      setEmployeeLeaves(response.data.results);
    } catch (error) {
      console.error("Error fetching employee leaves:", error);
      toast.error("Failed to fetch employee leaves.");
    }
    setLoading(false);
  }, [activeTab]);

  useEffect(() => {
    fetchEmployeeLeaves();
  }, [fetchEmployeeLeaves]);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Employee Name",
      dataIndex: ["user_details", "employee_name"],
      key: "employee_name",
    },
    {
      title: "Department",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "department_detail",
        "name",
      ],
      key: "department",
    },
    {
      title: "Designation",
      dataIndex: [
        "user_details",
        "employment_details",
        0,
        "designation_detail",
        "name",
      ],
      key: "designation",
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      key: "start_date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      key: "end_date",
      render: (date) => moment(date).format("DD-MM-YYYY"),
    },
    {
      title: "Contact",
      dataIndex: ["contact_details", "employee_name"],
      key: "contact_person",
      render: (text) => text || "N/A",
    },
  ];

  return (
    <>
      <Header />
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="page-header">
            <div className="content-page-header">
              <h5>Employees Currently on Leave</h5>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <Tabs
                    activeKey={activeTab}
                    onChange={setActiveTab}
                    defaultActiveKey="All"
                  >
                    <Tabs.TabPane tab="All Departments" key="All">
                      <Spin spinning={loading}>
                        <Table
                          dataSource={employeeLeaves}
                          columns={columns}
                          rowKey="id"
                          pagination={{
                            pageSize: 10,
                            showTotal: (total) => `Total ${total} items`,
                          }}
                          bordered
                          scroll={{ x: 1000 }}
                        />
                      </Spin>
                    </Tabs.TabPane>
                    {departments.map((dept) => (
                      <Tabs.TabPane tab={dept.name} key={dept.id}>
                        <Spin spinning={loading}>
                          <Table
                            dataSource={employeeLeaves}
                            columns={columns}
                            rowKey="id"
                            pagination={{
                              pageSize: 10,
                              showTotal: (total) => `Total ${total} items`,
                            }}
                            bordered
                            scroll={{ x: 1000 }}
                          />
                        </Spin>
                      </Tabs.TabPane>
                    ))}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EmployeeOnLeaves;
