import { Modal, Button, Descriptions, Tag, Input } from "antd";
import { useState } from "react";

const { TextArea } = Input;

const ExpenseDetailViewModal = ({ visible, record, onClose, type }) => {
  const [expandedFields, setExpandedFields] = useState({});

  if (!record) return null;

  const toggleExpand = (fieldName) => {
    setExpandedFields((prev) => ({
      ...prev,
      [fieldName]: !prev[fieldName],
    }));
  };

  const LongTextContent = ({ content, fieldName }) => {
    const isExpanded = expandedFields[fieldName];
    const shouldShowToggle = content && content.length > 100;

    return (
      <div>
        <TextArea
          value={content || "N/A"}
          readOnly
          autoSize={{ minRows: 2, maxRows: isExpanded ? 10 : 2 }}
          style={{
            width: "100%",
            backgroundColor: "#f5f5f5",
            cursor: "default",
            color: "rgba(0, 0, 0, 0.88)",
            border: "1px solid #d9d9d9",
          }}
        />
        {shouldShowToggle && (
          <Button
            type="link"
            onClick={() => toggleExpand(fieldName)}
            style={{ padding: "4px 0" }}
          >
            {isExpanded ? "Show Less" : "Show More"}
          </Button>
        )}
      </div>
    );
  };

  const getStatusTag = (status) => (
    <Tag
      color={
        status === "APPROVED"
          ? "green"
          : status === "PENDING"
          ? "orange"
          : status === "REJECTED"
          ? "red"
          : "default"
      }
    >
      {status || "-"}
    </Tag>
  );

  const getRsStatus = () => {
    if (record.gacl_status === "REJECTED") {
      return "N/A";
    }
    return getStatusTag(record.rs_status);
  };

  const fieldConfigs = [
    {
      label: "Entry Number",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Expense Date",
      value: record.account_type?.replace(/_/g, " "),
    },
    {
      label: "Expense Account",
      value: `${record.currency} ${record.amount}`,
    },
    {
      label: "Expense Amount",
      value: (
        <Tag color={record.is_urgent ? "red" : "green"}>
          {record.is_urgent ? "Urgent" : "Normal"}
        </Tag>
      ),
    },
    ...(record.is_urgent
      ? [
          {
            label: "Urgency Reason",
            value: (
              <LongTextContent
                content={record.urgency_reason}
                fieldName="urgency_reason"
              />
            ),
            span: 2,
          },
        ]
      : []),

    {
      label: "Paid Through",
      value: record.subscription_frequency?.replace(/_/g, " "),
    },
    {
      label: "Vendor",
      value: (
        <LongTextContent content={record.description} fieldName="description" />
      ),
      span: 2,
    },
    ...(type !== "gacl"
      ? [
          {
            label: "GACL STO Approval Status",
            value: getStatusTag(record.gacl_status),
          },
          {
            label: "RS sir Approval  Status",
            value: getRsStatus(record.rs_status),
          },
        ]
      : []),

    ...(type !== "gacl"
      ? [
          {
            label: "GACL Comments",
            value: (
              <LongTextContent
                content={record.gacl_comments}
                fieldName="gacl_comments"
              />
            ),
            span: 2,
          },
        ]
      : []),
    {
      label: "VAT Treatment",
      value: (
        <LongTextContent content={record.rs_comments} fieldName="rs_comments" />
      ),
      span: 2,
    },
    {
      label: "Place Of Supply",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Currency Code",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Exchange Rate",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Tax Registration Number",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Is Inclusive  Tax",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Tax Name",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Tax Percentage",
      value: record.vendor_name,
      span: 2,
    },
    {
      label: "Expense Description",
      value: record.vendor_name,
      span: 2,
    },
  ];

  return (
    <Modal
      title="Vendor Details"
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
      ]}
      width={800}
    >
      <Descriptions bordered column={1}>
        {fieldConfigs.map((field, index) => (
          <Descriptions.Item
            key={index}
            label={field.label}
            span={field.span || 1}
          >
            {field.value || "N/A"}
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Modal>
  );
};

export default ExpenseDetailViewModal;
