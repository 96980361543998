import React, { useEffect, useState, useCallback } from "react";
import { Table } from "antd";
import { Button, Modal, Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { axiosInstance } from "../../../../../helpers/axios/axios";
import {
  hrCourses,
  hrCourseById,
} from "../../../../../helpers/endpoints/api_endpoints";
import TrainingForm from "../../../Employees/EmployeeTraining/TrainingForm";

const TrainingCredentialList = () => {
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showAddCredentialsModal, setShowAddCredentialsModal] = useState(false);
  const [documentLists, setDocumentLists] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [formData, setFormData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDueDate, setSelectedDueDate] = useState(null);

  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
    due_date: null,
  });

  const fetchCourses = useCallback(() => {
    let endpoint = `${hrCourses}?is_read=false`;

    if (selectedDepartment !== null) {
      endpoint += `&department=${selectedDepartment}`;
    }

    if (searchTerm) {
      endpoint += `&title__icontains=${searchTerm}`;
    }

    if (selectedStatus) {
      endpoint += `&${selectedStatus}=true`;
    }

    if (selectedStatus === "is_approved") {
      endpoint += `&${selectedStatus}=true&is_publish=false`;
    }
    // if (selectedStatus === "is_read") {
    //   endpoint += `&${selectedStatus}=true`;
    // }

    if (selectedDueDate) {
      const formattedDate = selectedDueDate.toISOString().split("T")[0];
      endpoint += `&due_date=${formattedDate}`;
    }

    axiosInstance
      .get(endpoint)
      .then((response) => {
        setDocumentLists(response.data);
        setIsLoading(false);
        console.log("Hr Courses", response.data);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [selectedDepartment, searchTerm, selectedStatus, selectedDueDate]);

  useEffect(() => {
    fetchCourses();
  }, [
    shouldRefresh,
    selectedDepartment,
    searchTerm,
    selectedStatus,
    selectedDueDate,
    fetchCourses,
  ]);

  const handleShowDetails = (record) => {
    setSelectedRecord(record);
    setFormData({
      ...record,
      duration: record.duration,
    });
    setShowDetailsModal(true);
  };

  const handleCloseDetails = () => {
    setShowDetailsModal(false);
  };

  const handleShowAdd = () => {
    setShowAddModal(true);
  };

  const handleCloseAdd = () => {
    setShowAddModal(false);
    setShouldRefresh(!shouldRefresh); // Toggle the shouldRefresh state to trigger re-fetching
  };
  const formatDuration = (durationString) => {
    if (!durationString) return "";

    let totalHours = 0;
    let totalMinutes = 0;

    if (durationString.includes(" ")) {
      // Case when the string is like "1 01:00:00"
      const [daysPart, timePart] = durationString.split(" ");
      const days = parseInt(daysPart, 10);
      const [hours, minutes] = timePart.split(":").map(Number);

      totalHours = days * 24 + hours;
      totalMinutes = minutes;
    } else {
      // Case when the string is like "21:00:00"
      const [hours, minutes] = durationString.split(":").map(Number);

      totalHours = hours;
      totalMinutes = minutes;
    }

    return `${totalHours} hours ${totalMinutes} minutes`;
  };
  const handleShowAddCredentials = (record) => {
    setSelectedRecord(record);
    setShowAddCredentialsModal(true);
  };

  const handleCloseAddCredentials = () => {
    setShowAddCredentialsModal(false);
  };

  const handleDelete = (recordId) => {
    axiosInstance
      .delete(hrCourseById(recordId))
      .then(() => {
        const updatedList = documentLists.filter(
          (item) => item.id !== recordId
        );
        setDocumentLists(updatedList);
        toast.success("Record deleted successfully!");
        setShowDetailsModal(false); // Close the modal after deleting
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to delete the record.");
      });
  };

  const handleNotify = (recordId) => {
    const originalState = selectedRecord.is_approval;
    const updatedRecord = { ...selectedRecord, is_approval: true };
    setSelectedRecord(updatedRecord);

    axiosInstance
      .patch(hrCourseById(recordId), { is_approval: true })
      .then((response) => {
        const updatedList = documentLists.map((item) =>
          item.id === recordId ? { ...item, is_approval: true } : item
        );
        setDocumentLists(updatedList);
        toast.success("Notification sent successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to send notification.");
        setSelectedRecord({ ...selectedRecord, is_approval: originalState });
      });
  };

  const handlePublish = (recordId) => {
    axiosInstance
      .patch(hrCourseById(recordId), { is_publish: true })
      .then((response) => {
        const updatedList = documentLists.map((item) =>
          item.id === recordId ? { ...item, is_publish: true } : item
        );
        setDocumentLists(updatedList);
        toast.success("Course published successfully!");
        setSelectedRecord({ ...selectedRecord, is_publish: true });
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to publish the course.");
      });
  };

  const handleHide = (recordId) => {
    axiosInstance
      .patch(hrCourseById(recordId), { is_read: true })
      .then((response) => {
        const updatedList = documentLists.filter(
          (item) => item.id !== recordId
        );
        setDocumentLists(updatedList);
        toast.success("Course hidden successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to hide the course.");
      });
  };

  const handleFilter = (departmentId) => {
    setSelectedDepartment(departmentId);
  };

  const handleAddCredentials = () => {
    if (
      !credentials.username ||
      !credentials.password ||
      !credentials.due_date
    ) {
      toast.error("Please fill in all fields.");
      return;
    }

    const formattedDate = credentials.due_date.toISOString().split("T")[0];
    console.log(
      "Cred",
      credentials.username,
      credentials.password,
      formattedDate
    );
    axiosInstance
      .patch(hrCourseById(selectedRecord.id), {
        user_name: credentials.username,
        password: credentials.password,
        due_date: formattedDate,
      })
      .then((response) => {
        toast.success("Credentials added successfully!");
        setShouldRefresh(!shouldRefresh); // Trigger re-fetching
        handleCloseAddCredentials();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to add credentials.");
      });
  };

  const handleApprove = (recordId) => {
    const originalState = selectedRecord.is_approved;
    const updatedRecord = {
      ...selectedRecord,
      is_approved: true,
      is_approval: false,
    };
    setSelectedRecord(updatedRecord);

    axiosInstance
      .patch(hrCourseById(recordId), { is_approved: true, is_approval: false })
      .then((response) => {
        const updatedList = documentLists.map((item) =>
          item.id === recordId
            ? { ...item, is_approved: true, is_approval: false }
            : item
        );
        setDocumentLists(updatedList);
        toast.success("Course approved successfully!");
      })
      .catch((error) => {
        console.log(error);
        toast.error("Failed to approve course.");
        setSelectedRecord({ ...selectedRecord, is_approved: originalState });
      });
  };

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "id",
      key: "id",
      render: (text, record, index) => index + 1,
    },
    {
      title: "Course Name",
      dataIndex: "title",
      key: "title",
      render: (text) => (
        <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word" }}>
          {text}
        </div>
      ),
    },
    {
      title: "Requested By",
      dataIndex: ["user_details", "employee_name"],
      key: "user_details",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text) => <div>{text ? text.substring(0, 20) : ""}</div>,
    },
    {
      title: "Cost",
      dataIndex: "cost",
      key: "cost",
      render: (text) => <div className="table-avatar">{text}</div>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (record) => (
        <>
          <Button
            onClick={() => handleShowDetails(record)}
            className="btn btn-primary me-2"
          >
            Show Details
          </Button>
          {!record.is_approved && !record.is_read && (
            <Button
              onClick={() => handleHide(record.id)}
              className="btn btn-warning"
            >
              Hide
            </Button>
          )}
          {record.is_approved && !record.is_read && !record.is_publish && (
            <Button
              onClick={() => handleShowAddCredentials(record)}
              className="btn btn-info me-2"
            >
              Add Credentials
            </Button>
          )}
        </>
      ),
    },
    {
      title: "Status",
      key: "status",
      render: (record) => {
        // Default status if record is null or undefined
        if (!record) {
          return (
            <span
              style={{
                padding: "10px",
                color: "#fff",
                backgroundColor: "#6c757d",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              N/A
            </span>
          );
        }

        // Initialize default status
        let status = {
          text: "Send Request",
          color: "#ffc107", // yellow
        };

        try {
          // Safely check conditions with optional chaining
          if (record?.is_rejected === true) {
            status = {
              text: "Rejected",
              color: "#dc3545", // red
            };
          } else if (record?.is_approved && record?.is_publish) {
            status = {
              text: "Published",
              color: "#28a745", // green
            };
          } else if (record?.is_approved && !record?.is_publish) {
            status = {
              text: "Course Approved",
              color: "#80bfff", // light blue
            };
          } else if (record?.is_approval) {
            status = {
              text: "Requested for Approval",
              color: "#7b6b51", // brown
            };
          } else if (record?.is_read) {
            status = {
              text: "Not Approved",
              color: "#dc3545", // red
            };
          }

          return (
            <span
              style={{
                padding: "10px",
                color: "#fff",
                backgroundColor: status.color,
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              {status.text}
            </span>
          );
        } catch (error) {
          console.error("Error rendering status:", error, "Record:", record);
          return (
            <span
              style={{
                padding: "10px",
                color: "#fff",
                backgroundColor: "#6c757d",
                borderRadius: "4px",
                display: "inline-block",
              }}
            >
              Error
            </span>
          );
        }
      },
    },
  ];

  const departments = [
    { id: 1, name: "Human Resources" },
    { id: 2, name: "GACL" },
    { id: 3, name: "Technical" },
    { id: 4, name: "Operations" },
    { id: 5, name: "Marketing" },
    { id: 6, name: "Head Office" },
  ];

  return (
    <div className="row">
      <div className="row mb-10">
        <div className="col-12 pb-2 d-flex align-items-center">
          {departments.map((department) => (
            <button
              key={department.id}
              onClick={() => handleFilter(department.id)}
              className={
                selectedDepartment === department.id
                  ? "btn btn-success me-2"
                  : "btn btn-outline-success me-2"
              }
            >
              {department.name}
            </button>
          ))}
          <button
            onClick={() => handleFilter(null)}
            className={
              selectedDepartment === null
                ? "btn btn-success"
                : "btn btn-outline-success"
            }
          >
            All
          </button>
          <div className="ms-auto d-inline-block">
            <div className="input-block mb-0">
              <select
                className="form-select mt-1"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="is_approval">Sent for Approval</option>
                <option value="is_approved">Approved</option>
                <option value="is_publish">Published</option>
                <option value="is_read">Hidden Courses</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row mb-1">
        <div className="col-md-3">
          <div className="input-block mb-1 position-relative">
            <input
              type="text"
              className="form-control"
              placeholder="Search by Course Name"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <i
              className="fa fa-search position-absolute"
              style={{
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            ></i>
          </div>
        </div>
        <div className="col-md-3">
          <div className="input-block mb-1 position-relative">
            <DatePicker
              selected={selectedDueDate}
              onChange={(date) => setSelectedDueDate(date)}
              className="form-control"
              placeholderText="Select Due Date"
              dateFormat="yyyy/MM/dd"
            />
            <i
              className="fa fa-calendar position-absolute"
              style={{
                right: "10px",
                top: "50%",
                transform: "translateY(-50%)",
              }}
            ></i>
          </div>
        </div>
      </div>
      <div className="row mb-10">
        <div className="col-12">
          <div
            className="position-absolute"
            style={{ top: "30px", right: "20px" }}
          >
            <button
              type="button"
              className="btn btn-success"
              onClick={handleShowAdd}
            >
              Add Course
            </button>
          </div>
        </div>
      </div>
      <div className="col-md-12">
        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={documentLists}
            rowKey={(record) => record.id}
            loading={isLoading}
          />
        </div>
        <Modal show={showDetailsModal} onHide={handleCloseDetails} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedRecord && (
              <Form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Course Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="title"
                        value={formData.title || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Platform</label>
                      <input
                        type="text"
                        className="form-control"
                        name="platform"
                        value={formData.platform || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Employee Name</label>
                      <input
                        type="text"
                        className="form-control"
                        name="user_details"
                        value={formData.user_details?.employee_name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Description</label>
                      <textarea
                        className="form-control"
                        name="description"
                        value={formData.description || ""}
                        readOnly
                        style={{
                          minHeight: "70px",
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Cost</label>
                      <input
                        type="text"
                        className="form-control"
                        name="cost"
                        value={formData.cost || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-3">
                    <div className="input-block mb-3">
                      <Button
                        variant="primary"
                        onClick={() =>
                          window.open(formData.course_link, "_blank")
                        }
                        style={{ marginTop: "30px" }}
                      >
                        Redirect to Course
                      </Button>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Departments</label>
                      <input
                        type="text"
                        className="form-control"
                        name="departments"
                        value={selectedRecord.department_details
                          .map((dept) => dept.name)
                          .join(", ")}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Designation</label>
                      <textarea
                        className="form-control"
                        name="designations"
                        value={selectedRecord.designation_details
                          .map((designation) => designation.name)
                          .join(", ")}
                        readOnly
                        rows={1} // Set initial number of rows
                        style={{
                          overflow: "hidden",
                          resize: "none",
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                          minHeight: "70px",
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Duration</label>
                      <input
                        type="text"
                        className="form-control"
                        name="duration"
                        value={formatDuration(formData.duration)}
                        readOnly
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Username</label>
                      <input
                        type="text"
                        className="form-control"
                        name="user_name"
                        value={formData.user_name || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Password</label>
                      <input
                        type="text"
                        className="form-control"
                        name="password"
                        value={formData.password || ""}
                        readOnly
                      />
                    </div>
                  </div>
                  {selectedRecord.comment ? (
                    <div className="col-md-12">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Comment</label>
                        <input
                          type="text"
                          className="form-control"
                          name="comment"
                          value={formData.comment || ""}
                          readOnly
                        />
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Form>
            )}
          </Modal.Body>
          <Modal.Footer>
            <div
              style={{
                marginRight: "auto",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              {!selectedRecord?.is_rejected ? (
                <>
                  <Button
                    variant="success"
                    onClick={() => handleApprove(selectedRecord.id)}
                    disabled={selectedRecord?.is_approved}
                    style={{ marginRight: "auto" }}
                  >
                    {selectedRecord?.is_approved ? "Approved" : "Approve"}
                  </Button>
                  <small className="text-muted mt-1">
                    If Already approved. Click to approve.
                  </small>
                </>
              ) : (
                <></>
              )}
            </div>
            <Button variant="secondary" onClick={handleCloseDetails}>
              Close
            </Button>
            {!selectedRecord?.is_rejected && (
              <>
                {selectedRecord?.is_approved ? (
                  <Button
                    variant={selectedRecord.is_publish ? "success" : "primary"}
                    onClick={() => handlePublish(selectedRecord.id)}
                    disabled={!selectedRecord.due_date}
                  >
                    {selectedRecord.is_publish ? "Published" : "Publish"}
                  </Button>
                ) : (
                  <Button
                    variant={
                      selectedRecord?.is_approval ? "success" : "primary"
                    }
                    onClick={() => handleNotify(selectedRecord.id)}
                  >
                    {selectedRecord?.is_approval
                      ? "Sent for Approval"
                      : "Send for Approval"}
                  </Button>
                )}
                <Button
                  variant="danger"
                  onClick={() => handleDelete(selectedRecord.id)}
                >
                  Delete
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>
        <Modal show={showAddModal} onHide={handleCloseAdd} size="xl">
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <TrainingForm insideModal={true} />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAdd}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={showAddCredentialsModal}
          onHide={handleCloseAddCredentials}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Credentials</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <div className="row">
                <div className="col-md-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Username</label>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      value={credentials.username}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          username: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Password</label>
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      value={credentials.password}
                      onChange={(e) =>
                        setCredentials({
                          ...credentials,
                          password: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Due Date</label>
                    <DatePicker
                      selected={credentials.due_date}
                      onChange={(date) =>
                        setCredentials({ ...credentials, due_date: date })
                      }
                      className="form-control"
                      placeholderText="Select Due Date"
                      dateFormat="yyyy/MM/dd"
                    />
                  </div>
                </div>
              </div>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseAddCredentials}>
              Close
            </Button>
            <Button variant="primary" onClick={handleAddCredentials}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default TrainingCredentialList;
