export const OperationsideData = [
  {
    tittle: "MAIN",
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: "Operations Dashboard",
        hasSubRoute: false,
        showSubRoute: false,
        route: "/operations-dashboard",
        icon: "la la-dashcube",
      },
      {
        menuValue: "Tracks",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-briefcase",
        subMenus: [
          {
            menuValue: "Track Initialization",
            route: "/track-initialization",
          },
          // {
          //   menuValue: "Job Assigned",
          //   route: "/predecessor-assignee",
          // },
          // {
          //   menuValue: "End Document",
          //   route: "/end-document-list",
          // },
        ],
      },
      {
        menuValue: "Track Management",
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-folder-open",
        subMenus: [],
      },
      // {
      //   menuValue: "Summary",
      //   icon: "fa fa-chart-bar",
      //   hasSubRoute: true,
      //   showSubRoute: false,
      //   subMenus: [],
      // },
    ],
  },
];
// for vendor
export const kartikMenuItems = {
  menuValue: "Extraction Tasks",
  hasSubRoute: true,
  showSubRoute: false,
  route: "#",
  icon: "la la-tasks",
  subMenus: [
    {
      menuValue: "Vendor Extraction Task",
      route: "/vendor-detail-extraction-task",
    },
    // {
    //   menuValue: "Bill Extraction",
    //   route: "/bill-extraction",
    // },
  ],
};
