import { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Select, Button, Input, InputNumber, Checkbox } from "antd";
import {
  vendorRequest,
  vendordocuments,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import TextArea from "antd/es/input/TextArea";
import { useSelector } from "react-redux";
import GaclSideBar from "../../../layout/GaclSidebar";

const AddVendor = () => {
  const [form] = Form.useForm();
  const userInfo = useSelector((state) => state.account.userInfo);
  const [formData, setFormData] = useState({
    vendorName: "",
    accountType: "",
    subscriptionFrequency: "",
    currency: "",
    amount: "",
    description: "",
    documents: [{ name: "", file: null }],
    urgentRequest: false,
    reason: "",
  });
  const [loading, setLoading] = useState(false);
  const [Id, setId] = useState({
    teamleaderid: "",
    depatmentid: "",
  });

  const isTeamLeaderFromGACL =
    userInfo?.employeeData.employment_details?.[0]?.designation_detail?.name ===
      "Team Leader" &&
    userInfo?.employeeData.employment_details?.[0]?.department_detail?.name ===
      "GACL";

  useEffect(() => {
    axiosInstance
      .get(employeeDetails)
      .then((response) => {
        setId({
          teamleaderid: response.data.user.id,
          depatmentid: response.data.employment_details[0].department_detail.id,
        });
      })
      .catch((err) => console.error("Error fetching employee details:", err));
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (index, event) => {
    const file = event.target.files[0];

    if (file && file.size > 0) {
      const newDocuments = [...formData.documents];
      newDocuments[index] = {
        name: file.name,
        file: file,
      };
      setFormData((prevState) => ({
        ...prevState,
        documents: newDocuments,
      }));
    } else {
      toast.error("Cannot upload empty file");
    }
  };

  const addDocumentField = () => {
    setFormData((prevState) => ({
      ...prevState,
      documents: [...prevState.documents, { name: "", file: null }],
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);

      const requestPayload = {
        team_lead: Id.teamleaderid,
        vendor_name: formData.vendorName,
        account_type: formData.accountType
          .toLowerCase()
          .replace(/['\s&/,]/g, "_")
          .replace(/_+/g, "_")
          .replace(/^_|_$/g, ""),
        subscription_frequency: formData.subscriptionFrequency
          .toLowerCase()
          .replace(" ", "_"),
        currency: formData.currency,
        department: Id.depatmentid,
        amount: formData.amount.toString(),
        description: formData.description,
        is_urgent: formData.urgentRequest || false,
        urgency_reason: formData.urgentRequest ? formData.reason : null,
      };
      const vendorResponse = await axiosInstance.post(
        vendorRequest,
        requestPayload
      );

      if (formData.documents && formData.documents.length > 0) {
        const documentsToUpload = formData.documents.filter((doc) => doc.file);
        if (documentsToUpload.length > 0) {
          await uploadDocuments(vendorResponse.data.id, documentsToUpload);
        }
      }

      toast.success("Vendor request created successfully");
      form.resetFields();
      setFormData({
        vendorName: "",
        accountType: "",
        subscriptionFrequency: "",
        currency: "",
        amount: "",
        description: "",
        documents: [{ name: "", file: null }],
        urgentRequest: false,
        reason: "",
      });
    } catch (error) {
      if (error.response && error.response.data) {
        const errorData = error.response.data;

        Object.entries(errorData).forEach(([field, messages]) => {
          if (Array.isArray(messages)) {
            messages.forEach((message) => {
              toast.error(`${field}: ${message}`);
            });
          } else if (typeof messages === "string") {
            toast.error(`${field}: ${messages}`);
          }
        });
      }
    } finally {
      setLoading(false);
    }
  };
  const uploadDocuments = async (vendorRequestId, documents) => {
    try {
      const uploadPromises = documents.map(async (doc) => {
        if (!doc.file || doc.file.size === 0) return;

        const formData = new FormData();
        formData.append("vendor_request", vendorRequestId);
        formData.append("document", doc.file);

        return axiosInstance.post(vendordocuments, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      });

      await Promise.all(uploadPromises.filter(Boolean));
    } catch (error) {
      throw toast.error("Document upload failed");
    }
  };

  const accountTypeOptions = [
    { value: "administrative_expenses", label: "Administrative Expenses" },
    { value: "auditing_expenses", label: "Auditing Expenses" },
    {
      value: "employees_medical_insurance",
      label: "Employees Medical Insurance",
    },
    {
      value: "employees_visas_permits_ids",
      label: "Employees Visa's, Permits & ID's",
    },
    {
      value: "expenses_recover_singhania_clinic",
      label: "Expenses to Recover From Singhania Clinic",
    },
    { value: "license_and_permits", label: "License and Permits" },
    {
      value: "marketing_advertising_expenses",
      label: "Marketing & Advertising Expenses",
    },
    {
      value: "offshore",
      label: "Offshore",
    },
    {
      value: "freezone",
      label: "Freezone",
    },
    {
      value: "office_rent",
      label: "Office Rent",
    },

    {
      value: "online_platform_website_expenses",
      label: "Online Platform/Website Expenses",
    },
    { value: "other_expenses", label: "Other Expenses" },
    { value: "postage", label: "Postage" },
    {
      value: "software_infrastructure_expenses",
      label: "Software Infrastructure Expenses",
    },
    { value: "telephone_expenses", label: "Telephone Expenses" },
    { value: "training_costs", label: "Training Costs" },
    { value: "vendor_outsourcee_expense", label: "Vendor Outsourcee Expense" },
  ];

  const frequencyOptions = [
    { value: "One Time", label: "One Time" },
    { value: "Monthly", label: "Monthly" },
    { value: "Annually", label: "Annually" },
    { value: "On Demand", label: "On Demand" },
  ];

  const currencyOptions = [
    { value: "AED", label: "AED" },
    { value: "BDT", label: "BDT" },
    { value: "INR", label: "INR" },
    { value: "USD", label: "USD" },
  ];

  return (
    <>
      <style>
        {`@media (max-width:991px){

/* Section */
.page-wrapper div section{
flex-direction:column;
}

/* Section */
div .main-wrapper .page-wrapper div .ant-form-vertical section{
width:100% !important;
}

/* Division */
div .main-wrapper .page-wrapper div .ant-form-vertical div section div{
width:100% !important;
}

}`}
      </style>
      ; ,{isTeamLeaderFromGACL && <GaclSideBar />}
      <div style={styles.pageContainer} className="page-wrapper">
        <div style={styles.formContainer}>
          <Form form={form} onFinish={handleSubmit} layout="vertical">
            <section
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {/* Vendor Name */}
              <div
                style={{
                  width: "100%",
                }}
              >
                <Form.Item
                  label="Name of Vendor/Supplier"
                  name="vendorName"
                  rules={[
                    {
                      required: true,
                      message: "Please enter vendor name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter vendor name"
                    name="vendorName"
                    value={formData.vendorName}
                    onChange={handleChange}
                    style={{ width: "100%", height: 62 }}
                  />
                </Form.Item>
              </div>
              {/* Account Type */}
              <div
                style={{
                  width: "100%",
                }}
              >
                <Form.Item
                  label="Vendor Account Type"
                  name="accountType"
                  required
                  rules={[
                    { required: true, message: "Please select account type" },
                  ]}
                >
                  <Select
                    placeholder="Select Account Type"
                    options={accountTypeOptions}
                    style={{ width: "100%", height: 62 }}
                    onChange={(value) => {
                      setFormData((prev) => ({
                        ...prev,
                        accountType: value,
                      }));
                    }}
                  />
                </Form.Item>
              </div>
              {/* Subscription Frequency */}
              <div
                style={{
                  width: "100%",
                }}
              >
                <Form.Item
                  label={
                    <span
                      style={{
                        fontWeight: 500,
                        marginBottom: 3,
                      }}
                    >
                      Subscription Frequency
                    </span>
                  }
                  name="subscriptionFrequency"
                  required
                  rules={[
                    { required: true, message: "Please select frequency" },
                  ]}
                  style={{ width: "100%" }}
                >
                  <Select
                    placeholder="Select Frequency"
                    options={frequencyOptions}
                    style={{ width: "100%", height: 62 }}
                    onChange={(value) => {
                      setFormData((prev) => ({
                        ...prev,
                        subscriptionFrequency: value,
                      }));
                    }}
                  />
                </Form.Item>
              </div>
            </section>

            {/* Currency */}
            <section
              style={{
                display: "flex",
                width: "65%",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <div style={{ width: "100%" }}>
                <Form.Item
                  label="Select Currency"
                  name="currency"
                  required
                  rules={[
                    { required: true, message: "Please select currency" },
                  ]}
                >
                  <Select
                    placeholder="Select Currency"
                    options={currencyOptions}
                    style={{ width: "100%", height: 62 }}
                    onChange={(value) => {
                      setFormData((prev) => ({
                        ...prev,
                        currency: value,
                      }));
                    }}
                  />
                </Form.Item>
              </div>

              <div style={{ width: "100%" }}>
                <Form.Item
                  label="Amount"
                  name="amount"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please enter amount",
                    },
                  ]}
                >
                  <InputNumber
                    placeholder="Enter amount"
                    value={formData.amount}
                    onChange={(value) => {
                      setFormData((prev) => ({
                        ...prev,
                        amount: value,
                      }));
                    }}
                    style={{ width: "100%", height: 62 }}
                    formatter={(value) =>
                      `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }
                    parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
                  />
                </Form.Item>
              </div>
            </section>

            {/* Description */}
            <div style={styles.formSection}>
              <Form.Item
                label="Brief Description"
                name="description"
                rules={[
                  {
                    message: "Please enter description",
                  },
                ]}
              >
                <TextArea
                  placeholder="Enter description"
                  autoSize={{ minRows: 4, maxRows: 6 }}
                  value={formData.description}
                  onChange={handleChange}
                  className="custom-textarea"
                  name="description"
                />
              </Form.Item>
            </div>

            {/* Document Upload */}
            <Form.Item
              label="Upload Documents"
              name="documents"
              rules={[
                {
                  required: true,
                  message: "Please upload required documents",
                },
              ]}
            >
              <div style={{ marginBottom: 30 }}>
                <section
                  style={{
                    display: "flex",
                    gap: 15,
                    flexWrap: "wrap",
                  }}
                >
                  {formData.documents.map((doc, index) => (
                    <div
                      key={index}
                      style={styles.documentUpload}
                      onClick={() =>
                        document.getElementById(`fileInput${index}`).click()
                      }
                      onMouseEnter={(e) => {
                        e.target.style.backgroundColor = "#bee3f8";
                        e.target.style.borderColor = "#3182ce";
                      }}
                      onMouseLeave={(e) => {
                        e.target.style.backgroundColor = "#ebf8ff";
                        e.target.style.borderColor = "#4299e1";
                      }}
                    >
                      {!formData.documents[index].file && (
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="https://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 4.5V14.5M12 4.5C11.3 4.5 9.992 6.494 9.5 7M12 4.5C12.7 4.5 14.008 6.494 14.5 7M20 16.5C20 18.982 19.482 19.5 17 19.5H7C4.518 19.5 4 18.982 4 16.5"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      )}
                      <p>{doc.name || "Drag & Drop or Click to Upload"}</p>
                      <input
                        type="file"
                        id={`fileInput${index}`}
                        style={{ display: "none" }}
                        onChange={(e) => handleFileChange(index, e)}
                        accept=".pdf,.doc,.docx,.jpg,.png"
                      />
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addDocumentField}
                    style={styles.addDocumentButton}
                  >
                    <svg
                      width="45"
                      height="45"
                      viewBox="0 0 45 45"
                      fill="none"
                      xmlns="https://www.w3.org/2000/svg"
                    >
                      <path
                        d="M22.5 7.03125C22.5 6.65829 22.3518 6.3006 22.0881 6.03688C21.8244 5.77316 21.4667 5.625 21.0938 5.625C20.7208 5.625 20.3631 5.77316 20.0994 6.03688C19.8357 6.3006 19.6875 6.65829 19.6875 7.03125V19.6875H7.03125C6.65829 19.6875 6.3006 19.8357 6.03688 20.0994C5.77316 20.3631 5.625 20.7208 5.625 21.0938C5.625 21.4667 5.77316 21.8244 6.03688 22.0881C6.3006 22.3518 6.65829 22.5 7.03125 22.5H19.6875V35.1562C19.6875 35.5292 19.8357 35.8869 20.0994 36.1506C20.3631 36.4143 20.7208 36.5625 21.0938 36.5625C21.4667 36.5625 21.8244 36.4143 22.0881 36.1506C22.3518 35.8869 22.5 35.5292 22.5 35.1562V22.5H35.1562C35.5292 22.5 35.8869 22.3518 36.1506 22.0881C36.4143 21.8244 36.5625 21.4667 36.5625 21.0938C36.5625 20.7208 36.4143 20.3631 36.1506 20.0994C35.8869 19.8357 35.5292 19.6875 35.1562 19.6875H22.5V7.03125Z"
                        fill="#8E8E8E"
                      />
                    </svg>
                  </button>
                </section>
              </div>
            </Form.Item>

            {/* Urgent Request */}
            <Form.Item>
              <Checkbox
                checked={formData.urgentRequest}
                onChange={(e) =>
                  setFormData((prev) => ({
                    ...prev,
                    urgentRequest: e.target.checked,
                  }))
                }
                style={{ fontSize: "14px" }}
              >
                For Urgent Request
              </Checkbox>
            </Form.Item>

            {/* Reason for Urgent Request */}
            {formData.urgentRequest && (
              <div
                style={{
                  border: "1.5px solid rgb(226, 232, 240)",
                  padding: "1rem",
                  borderRadius: "0.5rem",
                  marginBottom: "1rem",
                }}
              >
                <label style={styles.label}>Reason </label>
                <textarea
                  name="reason"
                  value={formData.reason}
                  onChange={handleChange}
                  style={styles.textarea}
                  onFocus={(e) => {
                    e.target.style.borderColor = "#4299e1";
                    e.target.style.boxShadow =
                      "0 0 0 3px rgba(66, 153, 225, 0.15)";
                  }}
                  onBlur={(e) => {
                    e.target.style.borderColor = "#e2e8f0";
                    e.target.style.boxShadow = "none";
                  }}
                />
                <div
                  style={{
                    border: "1.5px solid rgb(226, 232, 240)",
                    padding: "1rem",
                    borderRadius: "0.5rem",
                    marginBottom: "1rem",
                    marginTop: 10,
                    width: "fit-content",
                  }}
                >
                  Note - Please write a reason for urgency of vendor
                </div>
              </div>
            )}

            {/* Submit Button */}
            <div style={styles.formSection}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  style={{
                    width: 184,
                    height: 39,
                    backgroundColor: "#FF902E",
                  }}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

// Styles object
const styles = {
  pageContainer: {
    animation: "fadeIn 0.6s ease-out",
  },
  formContainer: {
    backgroundColor: "white",
    borderRadius: "12px",
    padding: "2rem",
  },
  formSection: {
    width: "100%",
    marginBottom: "2rem",
    animation: "slideIn 0.5s ease-out",
  },
  label: {
    display: "block",
    marginBottom: 3,
    fontWeight: "500",
    color: "#2d3748",
  },
  input: {
    width: "100%",
    height: "62px",
    padding: "0.75rem",
    border: "1.5px solid #e2e8f0",
    borderRadius: "8px",
    fontSize: "1rem",
    transition: "all 0.3s ease",
  },
  select: {
    width: "100%",
    height: "62px",
    padding: "0.75rem",
    border: "1.5px solid #e2e8f0",
    borderRadius: "8px",
    fontSize: "1rem",

    transition: "all 0.3s ease",
    cursor: "pointer",
    appearance: "none",
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='https://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right 1rem center",
    backgroundSize: "1em",
  },
  textarea: {
    width: "100%",
    padding: "0.75rem",
    border: "1.5px solid #e2e8f0",
    borderRadius: "8px",
    fontSize: "1rem",
    minHeight: "120px",
    transition: "all 0.3s ease",
    resize: "vertical",
  },
  checkbox: {
    marginRight: "0.5rem",
  },
  checkboxLabel: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    userSelect: "none",
  },
  submitButton: {
    width: "fit-content",
    padding: "0.75rem 4rem",
    marginTop: 20,
    backgroundColor: "#FF902E",

    color: "white",
    border: "none",
    borderRadius: "8px",
    fontWeight: "600",
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  documentUpload: {
    border: "2px solid #4299e1",
    width: "30%",
    padding: "2.3rem",
    borderRadius: "8px",
    textAlign: "center",
    backgroundColor: "#ebf8ff",
    cursor: "pointer",
    transition: "all 0.3s ease",
  },
  addDocumentButton: {
    backgroundColor: "transparent",
    border: "none",
    border: "2px dashed #4299e1",
    color: "#4299e1",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    gap: "0.5rem",
    width: "9%",
    justifyContent: "center",
    transition: "all 0.3s ease",
  },
  heading: {
    color: "#2d3748",
    textAlign: "center",
    marginBottom: "1.5rem",
    fontSize: "1.875rem",
    fontWeight: "600",
  },
};

export default AddVendor;
