import { createSlice } from "@reduxjs/toolkit";

const hrSlice = createSlice({
  name: "hr",
  initialState: {
    ishrStatus: false,
  },
  reducers: {
    updateIshrStatus: (state, action) => {
      state.ishrStatus = action.payload;
    },
  },
});

export const { updateIshrStatus } = hrSlice.actions;
export default hrSlice.reducer;
