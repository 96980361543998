import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  employeeQPA,
  QPAform,
  employeeDetails,
} from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";
import { Spin } from "antd";

import {
  Form,
  Input,
  Button,
  Card,
  Select,
  Typography,
  Row,
  Col,
  DatePicker,
  Switch,
  Divider,
} from "antd";

const { Option } = Select;
const { Title, Text, Paragraph } = Typography;

const ratingLabels = {
  1: "Have patience and think it through.",
  2: "Cowardice will lead to misfortune.",
  3: "Over solicitude for our team members exposes us to worry and trouble.",
  4: "Must know when to fight and when to withdraw.",
  5: "Win in our own temple before engaging.",
  6: "Solve problems by leveraging a combination of Moral and Method.",
  7: "Harmonize any situation to release Vitality and Prosperity [VAP].",
  8: "Lead with absolute virtuous intentions:\n1. Be wise, sincere, benevolent, courageous, and strict\n2. Never lie to the team or boss.",
  9: "Fight without wanting to win accolades or retreat without fearing disgrace.",
  10: "Become adept at using Moral & Method to thread Shiva & Shakti factors to achieve VAP on both sides in any conflict situation.",
  11: "Must be present and accountable.",
  12: "Avoid dependence on praise, recognition and temptations.",
  13: "Be the master of his or her own reality.",
  14: "View reality without judgment.",
  15: "Identify the other side, and view the reality from a 3rd, neutral position.",
  16: "Have the ability to pick the right person for the right job.",
  17: "Keep strategy confidential from environment & team.",
  18: "Encourage team members who can distinguish autonomy and master their own reality and make them Engines.",
  19: "Be constant and consistent in applying discipline and APS.",
  20: "Continuously improve themselves.",
  21: "Be unassailable and defend against retaliation to our vulnerabilities.",
  22: "See and identify deception.",
  23: "Understand and use human nature.",
};
const exampleLabels = {
  3: "Describe how the Assessee has taken responsibility for his or her own work.",
  4: "A. What superpowers does the Assessee possess? Please list and explain his/her superpowers and abilities",
  5: "B. What challenges does the Assessee face and in which type of situations? Please list and explain where Assessee struggles",
  6: "What specific superpowers should the Assesssee be helped out with or provided with?",
  7: "What are 2 specific contributions made by the Assessee to advance towards becoming a 'high-performing engine'?",
  8: "What stands out about the assessee",
  9: "What are the assessee's strengths?",
  10: "Comments on superpowers as mentioned above",
  11: "What can the Assessee do to develop and grow?",
};

const FinalQPA = () => {
  const { id } = useParams();
  const [qpaData, setQPAData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [form] = Form.useForm();
  const [bossReviewExists, setBossReviewExists] = useState(false);
  const [employeeQPAData, setEmployeeQPAData] = useState(null);
  const [employeeData, setEmployeeData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  console.log(qpaData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const qpaResponse = await axiosInstance.get(`${QPAform}?qpa=${id}`);
        const qpaResults = qpaResponse.data.results;

        // Fetch employee QPA data for final review
        const employeeQPAResponse = await axiosInstance.get(
          `${employeeQPA}${id}/`
        );
        setEmployeeQPAData(employeeQPAResponse.data);

        // Check if boss review exists and set form values
        setBossReviewExists(!!employeeQPAResponse.data.remarks);
        if (employeeQPAResponse.data.remarks) {
          form.setFieldsValue({
            remarks: employeeQPAResponse.data.remarks,
            is_engine: employeeQPAResponse.data.is_engine,
            quarter: employeeQPAResponse.data.quarter || "N/A",
          });
        }

        // Organize QPA data
        const organizedData = {
          self: qpaResults.find((d) => d.type === "Self"),
          boss: qpaResults.find((d) => d.type === "Boss"),
          colleagues: qpaResults.filter((d) => d.type === "Colleague"),
        };

        // Calculate colleague averages
        const colleagueAverages = organizedData.colleagues.reduce(
          (acc, curr) => {
            for (let i = 1; i <= 8; i++) {
              const key = `rating_${i}`;
              acc[key] = (acc[key] || 0) + parseFloat(curr[key]);
            }
            return acc;
          },
          {}
        );

        // Divide by number of colleagues
        Object.keys(colleagueAverages).forEach((key) => {
          colleagueAverages[key] /= organizedData.colleagues.length;
        });

        setQPAData({ ...organizedData, colleagueAverages });

        // If we have self data with user_details, fetch employee details
        if (organizedData.self?.user_details?.id) {
          setIsLoading(true);
          const employeeResponse = await axiosInstance.get(
            `${employeeDetails}?employee_id=${organizedData.self.user_details.user}`
          );
          setEmployeeData(employeeResponse.data.employment_details[0]);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch data");
      } finally {
        setLoading(false);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [id, form]);

  const handleSubmit = async (values) => {
    try {
      console.log("Submitting values:", values);

      const payload = {
        remarks: values.remarks,
        is_engine: values.is_engine,
        is_boss_review: true,
        rating: values.rating,
      };

      console.log("Payload being sent:", payload);

      await axiosInstance.patch(`${employeeQPA}${id}/`, payload);

      setEmployeeQPAData((prev) => ({
        ...prev,
        remarks: values.remarks,
        is_engine: values.is_engine,
        is_boss_review: true,
        rating: values.rating,
      }));

      toast.success("Final evaluation submitted successfully");

      // Add page refresh after successful submission
      setTimeout(() => {
        window.location.reload();
      }, 1000); // 1 second delay to allow toast to be visible
    } catch (error) {
      console.error("Error submitting final evaluation:", error);
      toast.error("Failed to submit final evaluation");
    }
  };

  const renderGroupedQuestions = () => {
    return (
      <>
        {/* Specific Instances Section */}
        <div
          style={{
            background: "#333",
            color: "#fff",
            padding: "10px",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <Title level={4} style={{ color: "#fff", margin: 0 }}>
            What 3 specific instances can you provide of how the Assessee
            contributed to enhancing the team's performance? Please write actual
            cases in 50 words each.
          </Title>
        </div>
        <Card style={{ marginBottom: "20px" }}>
          {/* Self Assessment */}
          <div style={styles.section}>
            <Text strong>Self Assessment:</Text>
            <div style={styles.answer}>
              {qpaData?.self?.instance_item || "N/A"}
            </div>
          </div>

          {/* Boss Assessment */}
          <div style={styles.section}>
            <Text strong>Boss Assessment:</Text>
            <div style={styles.answer}>
              {qpaData?.boss?.instance_item || "N/A"}
            </div>
          </div>

          {/* SPIN Assessments */}
          {qpaData?.colleagues.map((colleague, index) => (
            <div key={index} style={styles.section}>
              <Text strong>Colleague {index + 1} Assessment:</Text>
              <div style={styles.answer}>
                {colleague?.instance_item || "N/A"}
              </div>
            </div>
          ))}
        </Card>

        {/* Selected Leadership Principles Section */}
        <div
          style={{
            background: "#333",
            color: "#fff",
            padding: "10px",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          <Title level={4} style={{ color: "#fff", margin: 0 }}>
            Selected Leadership Principles
          </Title>
        </div>
        <Card style={{ marginBottom: "20px" }}>
          {/* Self Assessment */}
          <div style={styles.section}>
            <Text strong>Self Assessment:</Text>
            <div style={styles.answer}>
              <div>1. {qpaData?.self?.checkbox_1 || "N/A"}</div>
              <div>2. {qpaData?.self?.checkbox_2 || "N/A"}</div>
              <div style={{ marginTop: "10px" }}>
                <div>For LP 1: {qpaData?.self?.example_1 || "N/A"}</div>
                <div>For LP 2: {qpaData?.self?.example_2 || "N/A"}</div>
              </div>
            </div>
          </div>

          {/* Boss Assessment */}
          <div style={styles.section}>
            <Text strong>Boss Assessment:</Text>
            <div style={styles.answer}>
              <div>1. {qpaData?.boss?.checkbox_1 || "N/A"}</div>
              <div>2. {qpaData?.boss?.checkbox_2 || "N/A"}</div>
              <div style={{ marginTop: "10px" }}>
                <div>For LP 1: {qpaData?.boss?.example_1 || "N/A"}</div>
                <div>For LP 2: {qpaData?.boss?.example_2 || "N/A"}</div>
              </div>
            </div>
          </div>

          {/* SPIN Assessments */}
          {qpaData?.colleagues.map((colleague, index) => (
            <div key={index} style={styles.section}>
              <Text strong>Colleague {index + 1} Assessment:</Text>
              <div style={styles.answer}>
                <div>1. {colleague?.checkbox_1 || "N/A"}</div>
                <div>2. {colleague?.checkbox_2 || "N/A"}</div>
                <div style={{ marginTop: "10px" }}>
                  <div>For LP 1: {colleague?.example_1 || "N/A"}</div>
                  <div>For LP 2: {colleague?.example_2 || "N/A"}</div>
                </div>
              </div>
            </div>
          ))}
        </Card>

        {/* Additional Questions Sections */}
        {Object.entries(exampleLabels).map(([key, label]) => (
          <React.Fragment key={key}>
            <div
              style={{
                background: "#333",
                color: "#fff",
                padding: "10px",
                marginTop: "30px",
                marginBottom: "20px",
              }}
            >
              <Title level={4} style={{ color: "#fff", margin: 0 }}>
                {label}
              </Title>
            </div>
            <Card style={{ marginBottom: "20px" }}>
              {/* Self Assessment */}
              <div style={styles.section}>
                <Text strong>Self Assessment:</Text>
                <div style={styles.answer}>
                  {qpaData?.self?.[`example_${key}`] || "N/A"}
                </div>
              </div>

              {/* Boss Assessment */}
              <div style={styles.section}>
                <Text strong>Boss Assessment:</Text>
                <div style={styles.answer}>
                  {qpaData?.boss?.[`example_${key}`] || "N/A"}
                </div>
              </div>

              {/* SPIN Assessments */}
              {qpaData?.colleagues.map((colleague, index) => (
                <div key={index} style={styles.section}>
                  <Text strong>Colleague {index + 1} Assessment:</Text>
                  <div style={styles.answer}>
                    {colleague?.[`example_${key}`] || "N/A"}
                  </div>
                </div>
              ))}
            </Card>
          </React.Fragment>
        ))}
      </>
    );
  };

  if (loading || isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "100%",
        }}
      >
        <Spin size="large" tip="Loading..." />
      </div>
    );
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div style={{ padding: 20 }}>
          <Card title="End Document - Quarterly Performance Appraisal">
            <div>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Name of Assesses">
                    <Input
                      value={qpaData?.self?.user_details?.employee_name}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Department">
                    <Input
                      value={
                        qpaData?.self?.user_details?.employment_details[0]
                          ?.department_detail.name
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Designation">
                    <Input
                      value={
                        qpaData?.self?.user_details?.employment_details[0]
                          ?.designation_detail.name
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={8}>
                  <Form.Item label="Name of Assessor">
                    <Input
                      value={
                        qpaData?.self?.user_details?.employment_details[0]
                          ?.reporting_details.employee_name
                      }
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label="Quarter:">
                    <Input value={employeeQPAData?.quarter} disabled />
                  </Form.Item>
                </Col>
              </Row>

              <Text strong style={{ marginTop: "20px", marginBottom: "50px" }}>
                The Assessor (the boss of the assessee) will fill out this form.
                Please study the data from the 5 independent assessments made
                earlier by the: Assessee 1 + Boss 1 + the 3 peers chosen by the
                Assessee
              </Text>

              <Row gutter={16} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <Card>
                    <Row>
                      <Col span={6}>
                        <Text strong>QPA Summary</Text>
                      </Col>
                      <Col span={18}>
                        <Text>
                          Our #1 Mission is 'meeting customer specifications
                          every time. As Habotechs, we must do everything in our
                          power to meet the changing customers' specifications
                          every day.
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
              <Row gutter={16} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <Card>
                    <Row align="middle" style={{ display: "flex" }}>
                      <Col span={6}>
                        <Text strong>Leadership Principles Basis</Text>
                      </Col>

                      {/* Vertical Line */}
                      <div
                        style={{
                          height: "100%",
                          width: "1px",
                          backgroundColor: "black",
                          margin: "0 10px",
                        }}
                      ></div>

                      <Col span={17}>
                        <Text>
                          Habot's Leadership Principles [LPs] help define what
                          is required of you to fulfill your role as a Habotech.
                          Adherence to them builds our culture and helps us to
                          meet customer expectations every time.
                          <br />
                          <br />
                          There are three Leadership Principles evaluation
                          categories:
                          <br />
                          1. Superpower [=5.0, 4.5]
                          <br />
                          2. Worthy of respect [=3.5, 3.0, 2.5]
                          <br />
                          3. Feeling challenged [=2.0, 1.5, 1.0, 0.5, 0.0]
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              {/* Combined Ratings Section */}
              <div style={{ marginTop: 30 }}>
                {/* Self LP Ratings */}
                <div
                  style={{
                    background: "#333",
                    color: "#fff",
                    padding: "10px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <Title level={4} style={{ color: "#fff", margin: 0 }}>
                    Self LP ratings
                  </Title>
                </div>
                <Row gutter={[16, 16]} style={{ marginBottom: 30 }}>
                  {Object.keys(ratingLabels).map((index) => (
                    <Col span={8} key={`self-${index}`}>
                      <Text>{ratingLabels[index]}</Text>
                      <Input
                        value={qpaData?.self?.[`rating_${index}`]}
                        disabled
                        style={{ marginTop: "5px" }}
                      />
                    </Col>
                  ))}
                </Row>
                {/* SPIN LP Ratings */}
                <div
                  style={{
                    background: "#333",
                    color: "#fff",
                    padding: "10px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <Title level={4} style={{ color: "#fff", margin: 0 }}>
                    SPIN LP ratings
                  </Title>
                </div>
                <Row gutter={[16, 16]} style={{ marginBottom: 30 }}>
                  {Object.keys(ratingLabels).map((index) => (
                    <Col span={8} key={`spin-${index}`}>
                      <Text>{ratingLabels[index]}</Text>
                      <Input
                        value={qpaData?.colleagueAverages?.[
                          `rating_${index}`
                        ]?.toFixed(2)}
                        disabled
                        style={{ marginTop: "5px" }}
                      />
                    </Col>
                  ))}
                </Row>

                {/* Boss LP Ratings */}
                <div
                  style={{
                    background: "#333",
                    color: "#fff",
                    padding: "10px",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  <Title level={4} style={{ color: "#fff", margin: 0 }}>
                    Assessor's LP ratings
                  </Title>
                </div>
                <Row gutter={[16, 16]} style={{ marginBottom: 30 }}>
                  {Object.keys(ratingLabels).map((index) => (
                    <Col span={8} key={`boss-${index}`}>
                      <Text>{ratingLabels[index]}</Text>
                      <Input
                        value={qpaData?.boss?.[`rating_${index}`]}
                        disabled
                        style={{ marginTop: "5px" }}
                      />
                    </Col>
                  ))}
                </Row>
              </div>

              {/* Grouped Questions */}
              {renderGroupedQuestions()}

              {/* Final Review Section */}
              <div
                style={{
                  background: "#333",
                  color: "#fff",
                  padding: "10px",
                  marginTop: "30px",
                  marginBottom: "20px",
                }}
              >
                <Title level={4} style={{ color: "#fff", margin: 0 }}>
                  Conclusive Remarks
                </Title>
              </div>

              <Row gutter={16} style={{ marginTop: 20 }}>
                <Col span={24}>
                  <Card>
                    <Row>
                      <Col span={6}>
                        <Text strong>Performance Basis</Text>
                      </Col>
                      <Col span={18}>
                        <Text>
                          The performance summary represents how you performed
                          compared to the expectations for your role and level.
                          There are 3 possible performance summary categories:
                          <br />
                          1. Gold Star - above expectations
                          <br />
                          2. Silver Star - meets expectations
                          <br />
                          3. Bronze Star - needs improvement
                          <br />
                          <br />
                          Your QPA summary is based on your weekly and quarterly
                          performance as determined by your Team Leader / Boss.
                          Also included are your self-evaluation, and feedback
                          received from the 3 peers selected by you.
                        </Text>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Card>
                <Form
                  form={form}
                  layout="vertical"
                  onFinish={handleSubmit}
                  initialValues={{
                    remarks: employeeQPAData?.remarks || "",
                    is_engine: employeeQPAData?.is_engine || false,
                    rating: employeeQPAData?.rating || undefined,
                  }}
                >
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        name="rating"
                        label="Category"
                        rules={[
                          {
                            required: true,
                            message: "Please select a category",
                          },
                        ]}
                      >
                        <Select
                          disabled={bossReviewExists}
                          placeholder="Select category"
                        >
                          <Select.Option value="Gold">
                            Gold Star - above expectations
                          </Select.Option>
                          <Select.Option value="Silver">
                            Silver Star - meets expectations
                          </Select.Option>
                          <Select.Option value="Bronze">
                            Bronze Star - needs improvement
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="remarks"
                        label="Remarks and Please explain in about 50 words why you think she/he belongs to that category."
                        rules={[
                          {
                            required: true,
                            message: "Please enter remarks",
                          },
                        ]}
                      >
                        <Input.TextArea
                          rows={4}
                          disabled={bossReviewExists}
                          placeholder="Enter your remarks here"
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="is_engine"
                        label="Has the Habotech turned into an Engine?"
                        valuePropName="checked"
                      >
                        <Switch
                          disabled={bossReviewExists}
                          checkedChildren="Yes"
                          unCheckedChildren="No"
                        />
                      </Form.Item>
                    </Col>

                    {bossReviewExists && (
                      <Col span={24}>
                        <div style={{ color: "#ff4d4f", marginBottom: 24 }}>
                          Boss review has already been submitted and cannot be
                          modified.
                        </div>
                      </Col>
                    )}

                    <Col span={24}>
                      <Form.Item className="text-end mb-0">
                        <Button
                          type="primary"
                          htmlType="submit"
                          disabled={bossReviewExists}
                        >
                          Submit Final Evaluation
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Card>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

const styles = {
  section: {
    marginBottom: "24px",
  },
  answer: {
    marginTop: "8px",
    paddingLeft: "20px",
  },
};

export default FinalQPA;
