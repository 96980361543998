import React, { useState, useEffect } from "react";
import { Form, Input, Button, Select, DatePicker, Row, Col, Card } from "antd";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { toast } from "react-toastify";
import {
  employeeList,
  departmentList,
  jobRequest,
} from "../../../../helpers/endpoints/api_endpoints";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import "../../../../assets/css/calendar.css";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";

const { Option } = Select;

function JobRequestForm() {
  const [form] = Form.useForm();
  const [employees, setEmployees] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [interviewType, setInterviewType] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Fetch employees and departments on component mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [empResponse, deptResponse] = await Promise.all([
          axiosInstance.get(employeeList),
          axiosInstance.get(departmentList),
        ]);
        setEmployees(empResponse.data);
        setDepartments(deptResponse.data);
      } catch (error) {
        console.error("Error fetching data:", error);
        toast.error("Failed to fetch required data");
      }
    };
    fetchData();
  }, []);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const formattedData = {
        ...values,
        status: "Complete",
        first_round_date: values.first_round_date?.format("YYYY-MM-DD"),
        second_round_date: values.second_round_date?.format("YYYY-MM-DD"),
        interview_type:
          values.interview_type === "Technical"
            ? "Technical Project Round"
            : "Project round",
        pay_scal_currency: values.currency,
        pay_scal_min: values.minPay,
        pay_scal_max: values.maxPay,
      };

      const response = await axiosInstance.post(jobRequest, formattedData);
      const jobRequestId = response.data.id;

      toast.success("Job request submitted successfully");
      form.resetFields();
      if (formattedData.interview_type === "Technical Project Round") {
        navigate(`/candidate-assessment-form/${jobRequestId}`);
      } else {
        navigate(`/hiring-project-form/${jobRequestId}`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      toast.error("Failed to submit job request");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <Card>
            <Form
              form={form}
              layout="vertical"
              style={{ margin: "0 20px" }}
              onFinish={handleSubmit}
            >
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="interview_type"
                    label={<strong>Interview Type</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select interview type",
                      },
                    ]}
                  >
                    <Select
                      placeholder="Select Interview Type"
                      onChange={(value) => setInterviewType(value)}
                    >
                      <Option value="Technical">
                        Technical + Project Round
                      </Option>
                      <Option value="Project">Project Round</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="shortlist_type"
                    label={<strong>Shortlisting Type</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select shortlisting type",
                      },
                    ]}
                  >
                    <Select placeholder="Select Shortlisting Type">
                      <Option value="Manual">Manual Shortlisting</Option>
                      <Option value="Automatic">Automatic Shortlisting</Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <h2
                className="text-white py-2 ps-2 mb-3 d-inline-block br-5 px-2"
                style={{
                  backgroundColor: "#202F36",
                }}
              >
                Job Request Form (JRF)
              </h2>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="requesting_person"
                    label={<strong>Name of person requesting</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select requesting person",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Employee"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {employees.map((emp) => (
                        <Option key={emp.id} value={emp.id}>
                          {emp.employee_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="position_title"
                    label={<strong>HabotConnect Position Title</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter position title",
                      },
                    ]}
                  >
                    <Input placeholder="Full Internal Designation" />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="designation"
                    label={<strong>Portal Designation</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter portal designation",
                      },
                    ]}
                  >
                    <Input placeholder="Job title for portals" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="first_round_date"
                    label={<strong>First Round Interview Date</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select first round date",
                      },
                    ]}
                  >
                    <DatePicker style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="first_panel_members"
                    label={<strong>Panel Members First Round</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select panel members",
                      },
                    ]}
                  >
                    <Select
                      mode="multiple"
                      placeholder="Select Panel Members"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {employees.map((emp) => (
                        <Option key={emp.id} value={emp.id}>
                          {emp.employee_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                {interviewType === "Technical" && (
                  <>
                    <Col span={12}>
                      <Form.Item
                        name="second_round_date"
                        label={<strong>Second Round Interview Date</strong>}
                        rules={[
                          {
                            required: true,
                            message: "Please select second round date",
                          },
                        ]}
                      >
                        <DatePicker style={{ width: "100%" }} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="second_panel_members"
                        label={<strong>Panel Members Second Round</strong>}
                        rules={[
                          {
                            required: true,
                            message: "Please select panel members",
                          },
                        ]}
                      >
                        <Select
                          mode="multiple"
                          placeholder="Select Panel Members"
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option?.children
                              ?.toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {employees.map((emp) => (
                            <Option key={emp.id} value={emp.id}>
                              {emp.employee_name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </>
                )}
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="team"
                    label={<strong>Team</strong>}
                    rules={[{ required: true, message: "Please select team" }]}
                  >
                    <Select placeholder="Select Team">
                      {departments.map((dept) => (
                        <Option key={dept.id} value={dept.id}>
                          {dept.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="reporty_to"
                    label={<strong>Reports to</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select reporting person",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select Reporting Person"
                      optionFilterProp="children"
                      filterOption={(input, option) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                    >
                      {employees.map((emp) => (
                        <Option key={emp.id} value={emp.id}>
                          {emp.employee_name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={<strong>Pay Scale</strong>} required>
                    <Input.Group compact>
                      <Form.Item
                        name="currency"
                        noStyle
                        rules={[
                          { required: true, message: "Please select currency" },
                        ]}
                      >
                        <Select style={{ width: "30%" }} placeholder="Currency">
                          <Option value="USD">USD</Option>
                          <Option value="AED">AED</Option>
                          <Option value="INR">INR</Option>
                        </Select>
                      </Form.Item>
                      <Form.Item
                        name="minPay"
                        noStyle
                        rules={[{ required: true, message: "Enter min pay" }]}
                      >
                        <Input
                          style={{ width: "35%" }}
                          placeholder="Min"
                          type="number"
                        />
                      </Form.Item>
                      <Form.Item
                        name="maxPay"
                        noStyle
                        rules={[{ required: true, message: "Enter max pay" }]}
                      >
                        <Input
                          style={{ width: "35%" }}
                          placeholder="Max"
                          type="number"
                        />
                      </Form.Item>
                    </Input.Group>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="hiring_number"
                    label={<strong>Number of hires</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select number of hires",
                      },
                    ]}
                  >
                    <Select placeholder="Select Number of Hires">
                      <Option value={1}>1</Option>
                      <Option value={2}>2</Option>
                      <Option value={3}>3</Option>
                      <Option value={4}>4</Option>
                      <Option value={5}>5</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="education"
                    label={<strong>Education</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter education requirements",
                      },
                    ]}
                  >
                    <Input maxLength={100} />
                  </Form.Item>
                </Col>
              </Row>

              <Form.Item
                name="job_description"
                label={<strong>Job Requirements</strong>}
                rules={[
                  { required: true, message: "Please enter job requirements" },
                ]}
              >
                <Input.TextArea placeholder="Add Job Description, key responsibilities..." />
              </Form.Item>

              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="experience_min"
                    label={<strong>Minimum experience</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select minimum experience",
                      },
                    ]}
                  >
                    <Select placeholder="Select Minimum Experience">
                      <Option value={0}>0 Years</Option>
                      <Option value={1}>1 Year</Option>
                      <Option value={2}>2 Years</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="experience_max"
                    label={<strong>Maximum experience</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please select maximum experience",
                      },
                    ]}
                  >
                    <Select placeholder="Select Maximum Experience">
                      <Option value={3}>3 Years</Option>
                      <Option value={5}>5 Years</Option>
                      <Option value={10}>10 Years</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    name="employee_type"
                    label={<strong>Employment Type</strong>}
                    rules={[
                      {
                        required: true,
                        message: "Please enter employment type",
                      },
                    ]}
                  >
                    <Input
                      placeholder="Full-time / Part-time"
                      maxLength={100}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <div style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Submit & Proceed
                </Button>
              </div>
            </Form>
          </Card>
        </div>
      </div>
    </>
  );
}

export default JobRequestForm;
