import React, { useState, useEffect } from "react";
import {
  Card,
  Tabs,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  InputNumber,
} from "antd";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../helpers/axios/axios";
import {
  subtracklist,
  subtrackAssignee,
  employeeList,
} from "../../../../helpers/endpoints/api_endpoints";

const { TabPane } = Tabs;

const TechTaskDashboardContent = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [subtracks, setSubtracks] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isJobModalVisible, setIsJobModalVisible] = useState(false);
  const [selectedSubtrack, setSelectedSubtrack] = useState(null);
  const [form] = Form.useForm();
  const [jobForm] = Form.useForm();

  useEffect(() => {
    fetchSubtracks();
    fetchEmployees();
    fetchAssignedJobs();
  }, []);

  const fetchSubtracks = async () => {
    setIsLoading(true);
    try {
      const response = await axiosInstance.get(subtracklist);
      setSubtracks(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching subtracks:", error);
      toast.error("Failed to fetch subtracks");
      setSubtracks([]);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axiosInstance.get(`${employeeList}?department=3`);
      setEmployees(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching employees:", error);
      toast.error("Failed to fetch employees");
      setEmployees([]);
    }
  };

  const fetchAssignedJobs = async () => {
    try {
      const response = await axiosInstance.get(subtrackAssignee);
      setJobs(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error("Error fetching assigned jobs:", error);
      toast.error("Failed to fetch assigned jobs");
      setJobs([]);
    }
  };

  const handleCreateSubtrack = async (values) => {
    try {
      await axiosInstance.post(subtracklist, values);
      toast.success("Subtrack created successfully");
      fetchSubtracks();
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error("Error creating subtrack:", error);
      toast.error("Failed to create subtrack");
    }
  };

  const handleCreateJob = async (values) => {
    try {
      await axiosInstance.post(subtrackAssignee, {
        ...values,
        subtrack: selectedSubtrack.id,
      });
      toast.success("Job assigned successfully");
      fetchAssignedJobs();
      setIsJobModalVisible(false);
      jobForm.resetFields();
      setSelectedSubtrack(null);
    } catch (error) {
      console.error("Error assigning job:", error);
      toast.error("Failed to assign job");
    }
  };

  const subtrackColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Button
          type="primary"
          onClick={() => {
            setSelectedSubtrack(record);
            setIsJobModalVisible(true);
          }}
        >
          Assign Job
        </Button>
      ),
    },
  ];

  const jobColumns = [
    {
      title: "Subtrack",
      dataIndex: ["subtrack_details", "name"],
      key: "subtrack",
      render: (text) => text || "N/A",
    },
    {
      title: "Assignee",
      dataIndex: ["assignee_details", "employee_name"],
      key: "assignee",
      render: (text) => text || "N/A",
    },
    {
      title: "Hours Allocated",
      dataIndex: "hours_allocated",
      key: "hours_allocated",
    },
    {
      title: "Order",
      dataIndex: "order",
      key: "order",
    },
  ];

  return (
    <Card>
      <Tabs activeKey={activeTab} onChange={setActiveTab}>
        <TabPane tab="Subtracks" key="1">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 16,
            }}
          >
            <Button type="primary" onClick={() => setIsModalVisible(true)}>
              Create Subtrack
            </Button>
          </div>
          <Table
            columns={subtrackColumns}
            dataSource={subtracks}
            loading={isLoading}
            rowKey="id"
          />
        </TabPane>
        <TabPane tab="Assigned Jobs" key="2">
          <Table
            columns={jobColumns}
            dataSource={jobs}
            loading={isLoading}
            rowKey="id"
          />
        </TabPane>
      </Tabs>

      <Modal
        title="Create Subtrack"
        open={isModalVisible}
        onCancel={() => {
          setIsModalVisible(false);
          form.resetFields();
        }}
        footer={null}
      >
        <Form form={form} onFinish={handleCreateSubtrack}>
          <Form.Item
            name="name"
            label="Name"
            rules={[{ required: true, message: "Please input subtrack name!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0, textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Create
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={`Assign Job - ${selectedSubtrack?.name || ""}`}
        open={isJobModalVisible}
        onCancel={() => {
          setIsJobModalVisible(false);
          setSelectedSubtrack(null);
          jobForm.resetFields();
        }}
        footer={null}
      >
        <Form form={jobForm} onFinish={handleCreateJob}>
          <Form.Item
            name="assignee"
            label="Assignee"
            rules={[{ required: true, message: "Please select an assignee!" }]}
          >
            <Select>
              {employees.map((employee) => (
                <Select.Option key={employee.id} value={employee.id}>
                  {employee.employee_name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="hours_allocated"
            label="Hours Allocated"
            rules={[{ required: true, message: "Please input hours!" }]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item
            name="order"
            label="Order"
            rules={[{ required: true, message: "Please input order!" }]}
          >
            <InputNumber min={1} style={{ width: "100%" }} />
          </Form.Item>
          <Form.Item style={{ marginBottom: 0, textAlign: "right" }}>
            <Button type="primary" htmlType="submit">
              Assign
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Card>
  );
};

export default TechTaskDashboardContent;
