import React, { useState, useEffect } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { planPhase } from "../../../../helpers/endpoints/api_endpoints";

function EmployeeCheckPhaseMeasure({ taskId, onUpdate }) {
  console.log(taskId);
  const [parameters, setParameters] = useState(taskId?.pdca_parameter || []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  console.log(parameters);
  const handleCurrentDataChange = (index, value) => {
    const currentData = value === "" ? "" : parseFloat(value) || 0;
    const updatedParameters = parameters.map((data, i) => {
      if (i === index) {
        const targetValue = parseFloat(data?.improvement_target) || 0;
        const targetDifference =
          currentData !== "" ? currentData - targetValue : 0;
        const status =
          currentData !== "" ? (targetDifference >= 0 ? "Met" : "Not Met") : "";

        return {
          ...data,
          current_data: currentData,
          difference: targetDifference,
          status: status,
        };
      }
      return data;
    });
    setParameters(updatedParameters);
  };

  const validateForm = () => {
    for (let param of parameters) {
      if (
        param.current_data === undefined ||
        param.current_data === null ||
        param.current_data === ""
      ) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateForm()) {
      toast.error("Please enter all current data values before submitting.");
      return;
    }

    setIsSubmitting(true);

    const formattedData = {
      pdca_parameter: parameters.map((data) => ({
        id: data.id,
        current_data: data.current_data,
        difference: data.difference,
      })),
    };
    console.log(formattedData);
    console.log(taskId?.id);

    try {
      const response = await axiosInstance.patch(
        `${planPhase}${taskId?.id}/`,
        formattedData
      );
      console.log(response.data);
      toast.success("Progress measured and submitted successfully!");
      onUpdate();
    } catch (error) {
      console.error("Error posting data:", error);
      toast.error("Failed to submit data. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    const fetchUpdatedData = async () => {
      try {
        const response = await axiosInstance.get(`${planPhase}${taskId.id}/`);
        setParameters(
          response.data.pdca_parameter.map((data) => ({
            ...data,
            status: data.difference >= 0 ? "Met" : "Not Met",
          }))
        );
      } catch (error) {
        console.error("Error fetching updated data:", error);
      }
    };

    fetchUpdatedData();
  }, [taskId]);

  return (
    <div className="p-3">
      <Form onSubmit={handleSubmit}>
        <div className="d-flex align-items-center justify-content-between">
          <Button>Measure and Compare Progress</Button>
          <Form.Label>Parameter Count: {parameters.length}</Form.Label>
        </div>
        <div>
          <Form.Label className="mt-4">Problem Statement</Form.Label>
          <Form.Control
            size="lg"
            type="text"
            value={taskId?.problem_statement || "Loading..."}
            readOnly
          />
        </div>

        {parameters.length > 0 ? (
          <Row>
            <Col>
              <Form.Label className="mt-4">Parameter</Form.Label>
              {parameters.map((data, index) => (
                <Form.Control
                  className="mt-1"
                  key={`parameter-${index}`}
                  size="lg"
                  type="text"
                  value={data?.parameter_name}
                  readOnly
                />
              ))}
            </Col>
            <Col>
              <Form.Label className="mt-4">Target Value</Form.Label>
              {parameters.map((data, index) => (
                <Form.Control
                  className="mt-1"
                  key={`target-${index}`}
                  size="lg"
                  type="text"
                  value={data?.improvement_target}
                  readOnly
                />
              ))}
            </Col>
            <Col>
              <Form.Label className="mt-4">Current Data</Form.Label>
              {parameters.map((data, index) => (
                <Form.Control
                  className="mt-1"
                  key={`current-${index}`}
                  size="lg"
                  type="number"
                  value={data.current_data === null ? 0 : data.current_data}
                  onChange={(e) =>
                    handleCurrentDataChange(index, e.target.value)
                  }
                />
              ))}
            </Col>
            <Col>
              <Form.Label className="mt-4">Actual vs Target</Form.Label>
              {parameters.map((data, index) => (
                <Form.Control
                  className="mt-1"
                  key={`difference-${index}`}
                  size="lg"
                  type="text"
                  value={data.difference !== undefined ? data.difference : 0}
                  readOnly
                />
              ))}
            </Col>
            <Col>
              <Form.Label className="mt-4">Status Check</Form.Label>
              {parameters.map((data, index) => (
                <Form.Control
                  className="mt-1"
                  key={`status-${index}`}
                  size="lg"
                  type="text"
                  value={data.status}
                  readOnly
                />
              ))}
            </Col>
          </Row>
        ) : (
          <Form.Label className="mt-4">No Parameters available</Form.Label>
        )}
        <Button
          variant="warning"
          type="submit"
          className="d-flex px-4 mt-4 mb-3"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Submit"}
        </Button>
      </Form>
    </div>
  );
}

export default EmployeeCheckPhaseMeasure;
