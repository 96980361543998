import { Row, Col, Button, Table, Typography } from "antd";
const { Title, Text } = Typography;

const sections = [
  {
    data: [
      {
        key: "1",
        label: "Full Name As On Passport or National Id",
        value: "John Doe",
      },
      { key: "2", label: "Email Id", value: "john.doe@example.com" },
      { key: "3", label: "Mobile Number", value: "+1234567890" },
      { key: "4", label: "Country Of Residence", value: "United States" },
      {
        key: "5",
        label: "Address In Country Of Residence",
        value: "123 Main Street, NY",
      },
      { key: "6", label: "Country Of Passport or National Id", value: "USA" },
      {
        key: "7",
        label: "Passport Number or National Id Number",
        value: "A12345678",
      },
      { key: "8", label: "Emergency Contact Person’s Name", value: "Jane Doe" },
      { key: "9", label: "Emergency Contact Number", value: "+0987654321" },
      { key: "10", label: "Starting Date Of Work", value: "01/01/2025" },
      { key: "11", label: "Period Of Agreement", value: "12 Months" },
      { key: "12", label: "Starting Designation", value: "Software Engineer" },
      {
        key: "13",
        label: "Monthly Compensation [ INR, AED. USD]",
        value: "$5000 USD",
      },
      {
        key: "14",
        label: "How Paid",
        value:
          "Into a bank account nominated by you via PayPal or other least cost methods",
      },
      {
        key: "15",
        label: "When Paid",
        value: "In the succeeding month",
      },
      {
        key: "16",
        label: "Other Monetary Benefits",
        value: "No other amount will be owed to you.",
      },
      {
        key: "17",
        label: "Other Benefits",
        value:
          "There will be no benefits paid by HabotConnect other than those listed above.",
      },
      {
        key: "18",
        label: "Probation Period",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
                fontWeight: "bold",
              }}
            >
              <Text>
                1. Your probation period will be for three months starting from
                the date of your joining. During probation, your performance
                will be evaluated.
              </Text>
              <br />
              <Text>
                2. HabotConnect may terminate your agreement or contract at any
                time during the probationary period if your performance is not
                satisfactory.
              </Text>
              <br />
              <Text>
                3. The performance indicates that you are unable to deliver the
                output required or if the department determines that the
                individual's behavior is unacceptable.
              </Text>
              <br />
              <Text>
                4. If your performance is satisfactory, you will be offered an
                agreement or contract with no fixed end date post completion of
                the probationary period.
              </Text>
            </div>
          </>
        ),
      },
    ],
  },

  //working requirements

  {
    title: "Working Requirements",
    data: [
      {
        key: "1",
        label: "Role Within The Business",
        value:
          "As required by your supervisors from time to time. You will be pushed to grow by learning and doing new assignments",
      },
      {
        key: "2",
        label: "Mode Of Work",
        value:
          "Online, Video calls, Phone calls, Various platforms from time to time",
      },
      {
        key: "3",
        label: "Relationship With HabotConnect",
        value: "Contract Outsourcee",
      },
      {
        key: "4",
        label: "Face-to-face Meetings",
        value: "Required to travel at short notice in case of work emergencies",
      },
      {
        key: "5",
        label: "Travel For HabotConnect",
        value:
          "You will be required to travel for business events at HabotConnect’s cost",
      },
      {
        key: "6",
        label: "Business Hours",
        value:
          "You are expected to be available online from 08:30 hrs to 18:30 hrs local time Monday to Friday, except on UAE holidays",
      },
      {
        key: "7",
        label: "Business Hours",
        value:
          "You are expected to be available online from 08:30 hrs to 18:30 hrs local time Monday to Friday, except on UAE holidays",
      },
      {
        key: "7",
        label: "Completion Of Work",
        value:
          "You are expected to complete your work for the day as specified from time to time.",
      },
      {
        key: "8",
        label: "Virtual Meetings",
        value:
          "You are required to be present in all the virtual meetings which you have been invited to attend.",
      },
      {
        key: "9",
        label: "Work Surroundings",
        value:
          "You need to ensure that you are in appropriate surroundings to conduct a meeting so that everything can be completed smoothly.",
      },
      {
        key: "10",
        label: "Technical Specifications for Internet & Laptop",
        value:
          "System requirements: Computer with minimum of 4 GB RAM, OS required: Windows / Mac / Google Chrome, Internet speed Specifications: 20 Mbps Download speed, 10Mbps Upload speed, Headphones or Earphones: Preferably with noise cancellation",
      },
    ],
  },

  //communication

  {
    title: "Communication",
    data: [
      {
        key: "1",
        label: "Your Availability",
        value:
          "During Business Hours specified earlier you are required to be available for communication on WhatsApp, Email, Phone, Google Chat & Zoho or any of the other platforms used by the business",
      },
      {
        key: "2",
        label: "Email Communication",
        value:
          "For business use, you will communicate only on the email address provided by  HabotConnect.",
      },
      {
        key: "3",
        label: "Progress Reporting",
        value:
          "You will be required to have at least one call per day with your supervisor or colleague on a video conferencing platform and at a time decided by him or her to report progress.",
      },
      {
        key: "4",
        label: "Reviews",
        value: "You will be required to join in online performance reviews",
      },
    ],
  },

  ///Data Security and Confidentiality

  {
    title: "Data Security and Confidentiality",
    data: [
      {
        key: "1",
        label: "Data And Messages",
        value:
          "All data and messages generated by your work are confidential and must be protected by you",
      },
      {
        key: "2",
        label: "Passwords & Login Details",
        value:
          "You will take due care to protect the passwords, data, login details and software from misuse, theft and corruption.",
      },
      {
        key: "3",
        label: "Data Back-up",
        value: "In case required you will take a backup of the data for safety",
      },
      {
        key: "4",
        label:
          "Disclosing Of Business & Confidential Information Will Lead To Disciplinary And Legal Action",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1. Only disclose information to other Contract Outsourced when
                it’s necessary and authorized.
              </Text>
              <br />
              <Text>
                2. You are not to use confidential information for any reason
                whatsoever unless authorized to do so in writing from your
                Supervisor.
              </Text>
              <br />
              <Text>
                3. You are not to disclose confidential information to anyone
                unless authorized to do so
              </Text>
              <br />
              <Text>
                4. You are not allowed to replicate confidential documents and
                files and store them on insecure devices or share them with
                unauthorized persons or institutions
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "5",
        label: "Non-Compete",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1. I accept that mine is a sensitive position and requires that
                I preserve full confidentiality of all information regarding my
                employers and customers.
              </Text>
              <br />
              <Text>
                2. I accept that I will not pursue on my own any customers of
                the HabotConnect Companies at any time
              </Text>
              <br />
              <Text>
                3. During my employment with HabotConnect and for a period of
                one year after leaving its employment, I shall not
                <p style={{}}>
                  Directly or indirectly For myself Or on the behalf of Or in
                  conjunction with Any other person, partnership or Corporation
                  Own, maintain, engage in, participate or have any interest in
                  The Operation of a competing business And or solicit any
                  customers of the HabotConnect
                </p>
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "6",
        label: "Nominee Services Duty Obligation",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bolder",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1. Act as a nominee or as a signatory in any position of a
                company, a trust, a foundation or a bank account belonging to a
                client or  HabotConnect group entity.
              </Text>
              <br />
              <Text>
                2.To discharge your duties by conforming to the directions given
                by the management and with due diligence and care. 
              </Text>
              <br />
              <Text>
                3. Perform a nominee function as part of your job and your
                compensation includes this work.
              </Text>
              <br />
              <Text>
                4. You consented to resign from nominee positions whenever
                required by the  HabotConnect management and complete the
                required procedure and resignation applicable to the nominee
                position.
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "7",
        label: "Business Hours",
        value:
          "You are expected to be available online from 08:30 hrs to 18:30 hrs local time Monday to Friday, except on UAE holidays",
      },
      {
        key: "8",
        label: "Completion Of Work",
        value:
          "You are expected to complete your work for the day as specified from time to time.",
      },
      {
        key: "9",
        label: "Virtual Meetings",
        value:
          "You are required to be present in all the virtual meetings which you have been invited to attend.",
      },
      {
        key: "10",
        label: "Work Surroundings",
        value:
          "You need to ensure that you are in appropriate surroundings to conduct a meeting so that everything can be completed smoothly.",
      },
      {
        key: "11",
        label: "Technical Specifications for Internet & Laptop",
        value:
          "System requirements: Computer with minimum of 4 GB RAM, OS required: Windows / Mac / Google Chrome, Internet speed Specifications: 20 Mbps Download speed, 10Mbps Upload speed, Headphones or Earphones: Preferably with noise cancellation",
      },
    ],
  },
  {
    title: "Data Security and Confidentiality",
    data: [
      {
        key: "12",
        label: "Intellectual Property Ownership",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.That all the work performed, delivered or accessed by you is
                intellectual property owned exclusively by HabotConnect and its
                group companies.
              </Text>
              <br />
              <Text>
                2. Intellectual property at HabotConnect includes: Software,
                codes, Lists of mailing, Checklists Templates, contacts, Cost
                Tables, systems and procedures, website content, legal opinions,
                symbols, marks, video and film production, audio production and
                files, used and not used and any other intangible asset that
                HabotConnect has in stock.
              </Text>
              <br />
              <Text>
                3.To use the intellectual property during your employment only
                for the benefit of  HabotConnect as directed by the 
                HabotConnect management 
              </Text>
              <br />
              <Text>
                4. You are under obligation to neither use nor access nor carry 
                HabotConnect ’s intellectual property after the period of
                employment is over.
              </Text>
              <Text>
                5.You will be under obligation to compensate  HabotConnect
                financially if you were to use or cause others to use the
                intellectual property owned by  HabotConnect for purposes other
                than in  HabotConnect’s interests
              </Text>
              <br />
              <Text>
                6. Above obligations apply to all intellectual property created
                by you or by any other employee or already existing in 
                HabotConnect. 
              </Text>
              <br />
              <Text>
                7.You hereby waive your rights in relation to any work in which
                copyright subsists.
              </Text>
              <br />
              <Text>
                8. You will use any work created by you prior to your employment
                with  HabotConnect only if it is free from copyright. You
                consent to use such work for  HabotConnect with acceptance that
                you are not entitled to any additional payment or charges.
              </Text>
              <br />
              <Text>
                9.  HabotConnect has full and unencumbered ownership rights to
                all the work created by you while at  HabotConnect or it’s group
                companies and may use the work for HabotConnect  own businesses
                in any manner as  HabotConnect  sees fit without any consent or
                permission from or intimation to you.
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "13",
        label: "Performance Appraisal",
        value:
          "Currently, the performance is evaluated weekly and quarterly. The parameters of appraisal are subject to change from time to time.",
      },
      {
        key: "14",
        label: "Any Increases In Contracted Compensation",
        value:
          "Will be based on your performance and needs of the company. There are no annual increments provided. Increments are solely based on rewards associated with various schemes.",
      },
      {
        key: "15",
        label: "Additional or Higher Responsibility",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.You can be given additional responsibilities based on your
                performance, vacancies available and your own plans
              </Text>
              <br />
              <Text>
                2. You can be given additional responsibilities based on your
                performance, vacancies available and your own plans This could
                be a lateral or upward movement and may lead to more money
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "16",
        label: "Gain Share",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.Gain share will be applicable as and when profits kick in and
                will be at the sole discretion of the company
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "17",
        label: "Notice of Separation",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.You may terminate the relationship at any time by giving one
                month’s notice in writing to companyservices1@habot.io with cc
                to ramapati@habot.io
              </Text>
              <br />
              <Text>
                2. HabotConnect  can do the same by a notice to your designated
                email ID mentioned earlier. 
              </Text>
              <br />
              <Text>
                3. HabotConnect  has complete rights to hold the salary of the
                outsourcee if he/she does not serve the notice period of (1) one
                month and we will not release the experience certificate.
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "18",
        label: "Payment of Outstanding Wages",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.Upon termination of your agreement, either voluntarily or
                otherwise, all remaining wages owed to you will be paid to you
                only after two weeks of the date of being relieved from work.
              </Text>
              <br />
              <Text>
                2.In case you terminate your job without the sufficient notice
                of one month, 100% of the previous month’s wage will be deducted
                from the wages payable.
              </Text>
            </div>
          </>
        ),
      },
    ],
  },

  //Leave & Absence From Work

  {
    title: "Leave & Absence From Work",
    data: [
      {
        key: "1",
        label: "Absence From Work",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.You are required to notify your supervisor as soon as possible
                if you are not able to work for a particular day, preferably the
                day before.
              </Text>
              <br />
              <Text>
                2. You may take leaves by informing your direct Supervisor in
                writing via email. You must also share in the appropriate
                company platform e.g. Google chat
              </Text>
              <br />
              <Text>
                3.If you remain absent from your work without prior approval or
                extend your leave without prior approval then this agreement can
                be terminated
              </Text>
            </div>
          </>
        ),
      },

      {
        key: "2",
        label: "Paid Leave",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>1.You will be given 30 days personal time per year</Text>
              <br />
              <Text>
                2. You will also be entitled to the Private Sector National
                Holidays as declared in the United Arab Emirates (UAE).
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "3",
        label: "Unpaid Leave",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>1. All other leaves are without pay</Text>
            </div>
          </>
        ),
      },
      {
        key: "4",
        label: "Accessibility While Away",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.You undertake to be accessible when you are away, by phone or
                email or Google Chat, Zoho or any other platforms being used by
                the Group. 
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "5",
        label: "Medical Leaves",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.Contract Outsourced who are unable to work due to illness or
                injury may be granted a leave of absence for the duration of the
                illness, not to exceed 2 days per month.
              </Text>
              <br />
              <Text>
                2.Upon returning, you will be required to submit a medical
                certificate stating your illness for the days taken off.
              </Text>
            </div>
          </>
        ),
      },
      {
        key: "6",
        label: "In Case Of Unexpected Events",
        value: (
          <>
            {" "}
            <div
              style={{
                fontSize: "6px",
                marginRight: "20px",
                fontWeight: "bold",
                display: "flex", // Changed from block to flex for 'gap' to work
                flexDirection: "column", // Ensures content stacks vertically
                gap: "5px",
              }}
            >
              <Text>
                1.In the event of war, riots, earthquakes, hurricanes,
                lightning, explosions, epidemics, pandemics, energy blackouts,
                unexpected legislation, lockouts, slowdowns, strikes, internet
                breakdown, software breakdown or any other event that disrupts
                business as usual the parties can renegotiate the deliverables
                and obligations on mutually agreed terms.
              </Text>
            </div>
          </>
        ),
      },
    ],
  },
  //Sign and Agreement
  {
    title: "",
    data: [
      {
        key: "1",
        label: "Agreed To By The Parties Signed Below",
        value:
          "By Signing Below We Agree To Abide By The Terms & Conditions Of This Agreement",
      },
      {
        key: "2",
        label: "Name Of Authorized Signatory",
        value: "Ramapati Singhania",
      },
      {
        key: "3",
        label: "Signature Of Authorized Signatory",
        value: "",
      },
      {
        key: "4",
        label: "Date [dd/mm/yyyy]",
        value: "",
      },
      {
        key: "5",
        label: "Name Of Contract Outsourcee",
        value: "",
      },
      {
        key: "6",
        label: "Signature Of Contract Outsourcee",
        value: "",
      },
      {
        key: "7",
        label: "Date  [dd/mm/yyyy]",
        value: "",
      },
    ],
  },
];

export default sections;
