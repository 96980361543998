import React from "react";
import Header from "../../../../layout/Header";
import AdminSideBar from "../../../MainPages/Dashboard/AdminDashboard/AdminSideBar";
import TrainingCredentialList from "./TrainingCredentialList";

const TrainingReport = () => {
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div className="content container-fluid position-relative">
          <div className="row mb-4">
            <div className="col-12">
              <h1 className="text-2xl font-bold">
                Training Course Approval List
              </h1>
            </div>
          </div>
          <TrainingCredentialList />
        </div>
      </div>
    </>
  );
};

export default TrainingReport;
