import React from "react";
import { Table, Button } from "antd";
import Header from "../../../layout/Header";
import AdminSideBar from "../../MainPages/Dashboard/AdminDashboard/AdminSideBar";

const columns = [
  { title: "Job Title", dataIndex: "jobTitle", key: "jobTitle" },
  {
    title: "No. of Applied Candidates",
    dataIndex: "appliedCandidates",
    key: "appliedCandidates",
  },
  {
    title: "Requested Teams",
    dataIndex: "requestedTeams",
    key: "requestedTeams",
  },
  { title: "No. of Hires", dataIndex: "hires", key: "hires" },
  {
    title: "View Candidates",
    key: "view",
    render: () => (
      <Button style={{ background: "#374151", color: "white", border: "none" }}>
        View
      </Button>
    ),
  },
];

const data = [
  {
    key: "1",
    jobTitle: "Frontend Developer",
    appliedCandidates: 25,
    requestedTeams: "UI/UX",
    hires: 3,
  },
  {
    key: "2",
    jobTitle: "Backend Developer",
    appliedCandidates: 18,
    requestedTeams: "Backend",
    hires: 2,
  },
  {
    key: "3",
    jobTitle: "Full Stack Developer",
    appliedCandidates: 30,
    requestedTeams: "Web",
    hires: 4,
  },
];

const ViewAllJobs = () => {
  return (
    <>
      <Header />
      <AdminSideBar />
      <div className="page-wrapper">
        <div style={{ maxWidth: "1200px", margin: "auto", padding: "0 10px" }}>
          <Button
            style={{
              background: "#FE8147",
              color: "white",
              border: "none",
              marginBottom: "16px",
              marginTop: "32px",
            }}
          >
            View All Jobs
          </Button>
          <div style={{ overflowX: "auto" }}>
            <Table
              columns={columns}
              dataSource={data}
              pagination={false}
              bordered
              scroll={{ x: "max-content" }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewAllJobs;
