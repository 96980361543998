import React, { useEffect, useState } from "react";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Upload,
  Select,
  message,
  Spin,
  Image,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { axiosInstance } from "../../../../helpers/axios/axios";
import { handoverCheckList } from "../../../../helpers/endpoints/api_endpoints";
import { toast } from "react-toastify";

const { Option } = Select;

const HandoverChecklist = ({ selectedResignation }) => {
  console.log("Selected Resignation:", selectedResignation);

  const [handOverChecklistData, setHandOverChecklistData] = useState(null);
  const [loading, setLoading] = useState(true); // For data fetching
  const [submitting, setSubmitting] = useState(false); // For form submission
  const [isEditing, setIsEditing] = useState(false); // Determine POST or PATCH
  const [form] = Form.useForm();

  // Dynamic questions (for statuses and comments)
  const dynamicQuestions = [
    {
      question:
        "Have all the tracks that the resigned Habotech was the owner of been explained and handed over to the Habotech taking over?",
      statusName: "status1",
      notesName: "notes1",
    },
    {
      question:
        "Has the resigned Habotech completed giving the required training and knowledge transfer for the Habotech taking over?",
      statusName: "status2",
      notesName: "notes2",
    },
    {
      question:
        "Have the user IDs and passwords of all the platforms been shared by the Habotech leaving with the Habotech taking over?",
      statusName: "status3",
      notesName: "notes3",
    },
    {
      question:
        "Have ongoing tasks/projects been handed over with clear instructions?",
      statusName: "status4",
      notesName: "notes4",
    },
    {
      question:
        "Have all relevant documents, files, and resources been shared with the Habotech taking over?",
      statusName: "status5",
      notesName: "notes5",
    },
    {
      question:
        "Has an introduction been arranged between the new joined/taking over habotech and key internal and external contacts related to the tasks?",
      statusName: "status6",
      notesName: "notes6",
    },
    {
      question:
        "Has the habotech taking over been briefed on all pending actions or next steps that need immediate attention?",
      statusName: "status7",
      notesName: "notes7",
    },
  ];

  // 1. Fetch existing data
  useEffect(() => {
    setLoading(true);
    axiosInstance
      .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
      .then((response) => {
        console.log("Fetched Checklist Data:", response.data);
        setHandOverChecklistData(response.data);

        // If record exists for this resignation, switch to Edit mode
        if (
          response.data.results.length > 0 &&
          response.data.results[0].separation === selectedResignation?.id
        ) {
          setIsEditing(true);
          const checklist = response.data.results[0];

          // Pre-fill the form with existing data
          form.setFieldsValue({
            status1: checklist.status_1 ? "Yes" : "No",
            status2: checklist.status_2 ? "Yes" : "No",
            status3: checklist.status_3 ? "Yes" : "No",
            status4: checklist.status_4 ? "Yes" : "No",
            status5: checklist.status_5 ? "Yes" : "No",
            status6: checklist.status_6 ? "Yes" : "No",
            status7: checklist.status_7 ? "Yes" : "No",
            notes1: checklist.comments1 ?? "",
            notes2: checklist.comments2 ?? "",
            notes3: checklist.comments3 ?? "",
            notes4: checklist.comments4 ?? "",
            notes5: checklist.comments5 ?? "",
            notes6: checklist.comments6 ?? "",
            notes7: checklist.comments7 ?? "",
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching checklist:", error);
      })
      .finally(() => setLoading(false));
  }, [selectedResignation, form]);

  // Helper: Build patch data (for editing)
  const buildPatchData = (oldRecord, newValues) => {
    const patchData = {};
    const toBool = (val) => (val === "Yes" ? true : false);

    // For each dynamic question, compare the new value with old value
    dynamicQuestions.forEach((item, index) => {
      const oldStatus = oldRecord[`status_${index + 1}`];
      const newStatus = toBool(newValues[item.statusName]);
      if (newValues[item.statusName] !== undefined && newStatus !== oldStatus) {
        patchData[`status_${index + 1}`] = newStatus;
      }
      const oldComment = oldRecord[`comments${index + 1}`] || "";
      const newComment = newValues[item.notesName] || "";
      if (
        newValues[item.notesName] !== undefined &&
        newComment !== oldComment
      ) {
        patchData[`comments${index + 1}`] = newComment;
      }
    });

    // Check signature update
    if (newValues.tl_signature && newValues.tl_signature.originFileObj) {
      patchData.tl_signature = newValues.tl_signature.originFileObj;
      patchData.submitted_by_tl = "true";
    }

    return patchData;
  };

  // 2. Handle form submission (POST or PATCH)
  const handleSubmit = (values) => {
    setSubmitting(true);

    let formData = new FormData();
    let request;
    if (!isEditing) {
      // POST: Build submittedData only with provided values
      let submittedData = { separation: selectedResignation?.id };

      // Set submitted_by_tl if signature exists
      submittedData.submitted_by_tl =
        values.tl_signature && values.tl_signature.originFileObj
          ? "true"
          : "false";

      // For each dynamic question, add only if the value is provided
      dynamicQuestions.forEach((item, index) => {
        if (values[item.statusName] !== undefined) {
          submittedData[`status_${index + 1}`] =
            values[item.statusName] === "Yes";
        }
        if (values[item.notesName] !== undefined) {
          submittedData[`comments${index + 1}`] = values[item.notesName];
        }
      });

      // Append all submittedData to formData
      Object.entries(submittedData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      // Append signature if uploaded
      if (values.tl_signature && values.tl_signature.originFileObj) {
        formData.append("tl_signature", values.tl_signature.originFileObj);
      }

      console.log("Form Data (POST) Before Submission:");
      for (let pair of formData.entries()) {
        console.log(
          `${pair[0]}:`,
          pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
        );
      }

      request = axiosInstance.post(`${handoverCheckList}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
    } else {
      // PATCH: Only send changed fields
      const oldRecord = handOverChecklistData.results[0];
      const patchData = buildPatchData(oldRecord, values);

      if (Object.keys(patchData).length === 0) {
        toast.info("No changes detected.");
        setSubmitting(false);
        return;
      }

      // Convert patchData into FormData
      Object.entries(patchData).forEach(([key, value]) => {
        formData.append(key, value);
      });

      console.log("Form Data (PATCH) Before Submission:");
      for (let pair of formData.entries()) {
        console.log(
          `${pair[0]}:`,
          pair[1] instanceof File ? `File -> ${pair[1].name}` : pair[1]
        );
      }

      request = axiosInstance.patch(
        `${handoverCheckList}${oldRecord.id}/`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } }
      );
    }

    // Make the request
    request
      .then((response) => {
        toast.success(
          `Form ${isEditing ? "updated" : "submitted"} successfully!`
        );
        console.log("Response:", response.data);

        // 3. Re-fetch the data to update UI in real time
        axiosInstance
          .get(`${handoverCheckList}?separation=${selectedResignation?.id}`)
          .then((res) => {
            setHandOverChecklistData(res.data);
            if (
              res.data.results.length > 0 &&
              res.data.results[0].separation === selectedResignation?.id
            ) {
              setIsEditing(true);
              const checklist = res.data.results[0];
              form.setFieldsValue({
                status1: checklist.status_1 ? "Yes" : "No",
                status2: checklist.status_2 ? "Yes" : "No",
                status3: checklist.status_3 ? "Yes" : "No",
                status4: checklist.status_4 ? "Yes" : "No",
                status5: checklist.status_5 ? "Yes" : "No",
                status6: checklist.status_6 ? "Yes" : "No",
                status7: checklist.status_7 ? "Yes" : "No",
                notes1: checklist.comments1 ?? "",
                notes2: checklist.comments2 ?? "",
                notes3: checklist.comments3 ?? "",
                notes4: checklist.comments4 ?? "",
                notes5: checklist.comments5 ?? "",
                notes6: checklist.comments6 ?? "",
                notes7: checklist.comments7 ?? "",
              });
            }
          })
          .catch((err) => {
            console.error("Error re-fetching after update:", err);
          });
      })
      .catch((error) => {
        message.error(`Failed to ${isEditing ? "update" : "submit"} the form.`);
        console.error("Error:", error);
      })
      .finally(() => setSubmitting(false));
  };

  // 4. Upload props (unchanged)
  const uploadProps = {
    beforeUpload: (file) => {
      const isValid = file.type === "image/png" || file.type === "image/jpeg";
      if (!isValid) {
        message.error("You can only upload PNG or JPEG file!");
      }
      return isValid;
    },
    onRemove: () => {
      toast.success("File removed successfully!");
    },
  };

  // 5. UI Layout (unchanged) with loader
  return (
    <div
      style={{
        margin: "auto",
        padding: "20px",
        backgroundColor: "#F9FAFB",
        borderRadius: "8px",
        border: "1px solid #E5E7EB",
      }}
    >
      {loading ? (
        <Spin
          size="large"
          style={{
            display: "block",
            margin: "50px auto",
            textAlign: "center",
          }}
        />
      ) : (
        <>
          <h3
            style={{
              backgroundColor: "#374151",
              color: "#FFFFFF",
              padding: "10px",
              borderRadius: "4px",
              marginBottom: "20px",
            }}
          >
            Handover Checklist
          </h3>
          <p>
            This checklist needs to be completed and signed by the team
            leader/supervisor in order for the resigned employee to receive the
            pay for the month and the experience certificate.
          </p>

          {/* Main Form */}
          <Form
            form={form}
            layout="vertical"
            onFinish={handleSubmit}
            style={{ marginTop: "20px" }}
          >
            {/* Basic Fields (Name, Dept, etc.) outside the Form */}
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Name"
                  name="name"
                  initialValue={
                    selectedResignation?.user_details?.employee_name ?? ""
                  }
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Department"
                  name="department"
                  initialValue={
                    selectedResignation?.user_details?.employment_details[0]
                      ?.department_detail?.name ?? ""
                  }
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label="Designation"
                  name="designation"
                  initialValue={
                    selectedResignation?.user_details?.employment_details[0]
                      ?.designation_detail?.name ?? ""
                  }
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={16}>
              <Col span={8}>
                <Form.Item
                  label="Last Working Day"
                  name="lastWorkingDay"
                  initialValue={selectedResignation?.last_working_date ?? ""}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>
            </Row>
            {dynamicQuestions.map((item, index) => (
              <div key={index}>
                <h6 style={{ marginTop: "20px" }}>{item.question}</h6>
                <Row gutter={16}>
                  <Col span={8}>
                    <Form.Item label="Status" name={item.statusName}>
                      <Select disabled={submitting} placeholder="Select">
                        <Option value="Yes">Yes</Option>
                        <Option value="No">No</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={16}>
                    <Form.Item label="Notes/Comments" name={item.notesName}>
                      <Input.TextArea
                        disabled={submitting}
                        placeholder="Notes/Comments"
                        autoSize
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            ))}
            {handOverChecklistData?.results[0]?.person_signature && (
              <>
                <h3 style={{ marginTop: "20px" }}>
                  Employee Leaving Signature
                </h3>
                <Image
                  width={200}
                  src={handOverChecklistData?.results[0]?.person_signature}
                  preview={{
                    zIndex: 3000,
                    getContainer: document.body,
                  }}
                />
              </>
            )}
            <h5 style={{ marginTop: "20px" }}>Team Leader's Signature</h5>
            <Image
              width={200}
              src={handOverChecklistData?.results[0]?.tl_signature}
              preview={{
                zIndex: 3000,
                getContainer: document.body,
              }}
            />
            {handOverChecklistData?.results[0]?.tl_signature ? (
              ""
            ) : (
              <h3 style={{ marginTop: "20px" }}>Team Leader's Signature</h3>
            )}
            <Form.Item
              label={
                handOverChecklistData?.results[0]?.tl_signature
                  ? "Reupload Team Leader's Signature"
                  : "Team Leader's Signature"
              }
              name="tl_signature"
              valuePropName="file"
              getValueFromEvent={(e) =>
                e && e.fileList.length > 0 ? e.fileList[0] : null
              }
            >
              <Upload {...uploadProps} maxCount={1} beforeUpload={() => false}>
                <Button
                  icon={<UploadOutlined />}
                  disabled={submitting}
                  style={{
                    backgroundColor: "#374151",
                    borderColor: "#374151",
                    borderRadius: "4px",
                    color: "#fff",
                  }}
                >
                  Upload Signature
                </Button>
              </Upload>
            </Form.Item>

            <div style={{ marginTop: "20px" }}>
              <Button
                type="primary"
                htmlType="submit"
                style={{
                  backgroundColor: "#374151",
                  borderColor: "#374151",
                  borderRadius: "4px",
                }}
                disabled={submitting}
              >
                {submitting ? <Spin /> : isEditing ? "Update" : "Submit"}
              </Button>
            </div>
          </Form>
        </>
      )}
    </div>
  );
};

export default HandoverChecklist;
